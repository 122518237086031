import React, { useState } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FeatureComparison } from './pricing/FeatureComparison';
import { 
  Zap,
  Check,
  Monitor,
  ArrowRight
} from 'lucide-react';

const SUBSCRIPTION_PLANS = [
  {
    name: 'Free',
    price: 0.00,
    annualPrice: 0.00,
    features: [
      'Basic Prism functionality',
      '5 prism responses per day',
      'Basic chart types only',
      'Ask about any stock, ever',
      'Community support'
    ],
    icon: Zap
  },
  {
    name: 'Pro',
    price: 9.99,
    annualPrice: 99,
    features: [
      'Unlimited Prism usage',
      'Early access to Canvas (Beta)',
      'All chart types & indicators',
      'Full historical data',
      'Unlimited saved chats',
      'Ask about any stock, ever',
      'Priority email support'
    ],
    popular: true,
    icon: Monitor
  }
];

export default () => {
  const { isDarkMode } = useTheme();
  const [isAnnual, setIsAnnual] = useState(false);

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      {/* Dynamic Background */}
      <div className="fixed inset-0 bg-[linear-gradient(to_right,#232323_1px,transparent_1px),linear-gradient(to_bottom,#232323_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_50%,#000_70%,transparent_100%)]" />
      
      {/* Gradient Orbs */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <motion.div 
          animate={{ 
            scale: [1, 1.2, 1],
            opacity: [0.3, 0.5, 0.3],
            transition: { 
              duration: 8,
              repeat: Number.POSITIVE_INFINITY,
              ease: "easeInOut" 
            }
          }}
          className="absolute top-1/4 right-1/4 w-96 h-96 bg-blue-500/10 rounded-full blur-3xl"
        />
        <motion.div 
          animate={{ 
            scale: [1.2, 1, 1.2],
            opacity: [0.4, 0.6, 0.4],
            transition: { 
              duration: 10,
              repeat: Number.POSITIVE_INFINITY,
              ease: "easeInOut" 
            }
          }}
          className="absolute bottom-1/4 left-1/4 w-96 h-96 bg-purple-500/10 rounded-full blur-3xl"
        />
      </div>

      {/* Pricing Header */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-24 relative">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center max-w-3xl mx-auto"
        >
          <h1 className={`text-4xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'} mb-6`}>
            Simple Pricing for All Your Trading Needs
          </h1>
          <p className={`text-xl ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} mb-12`}>
            Get started for free, upgrade when you need more power with our Pro plan.
          </p>

          {/* Billing Toggle */}
          <div className="flex justify-center mb-12">
            <div className="flex items-center space-x-2 bg-gray-800/30 p-1 rounded-full border border-gray-700/50 backdrop-blur-sm">
              <button
                onClick={() => setIsAnnual(false)}
                className={`px-4 py-2 rounded-full transition-all ${
                  !isAnnual 
                    ? 'bg-blue-500 text-white shadow-md shadow-blue-500/20' 
                    : isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}
              >
                Monthly
              </button>
              <button
                onClick={() => setIsAnnual(true)}
                className={`px-4 py-2 rounded-full transition-all ${
                  isAnnual 
                    ? 'bg-blue-500 text-white shadow-md shadow-blue-500/20' 
                    : isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}
              >
                Annual
                <span className="ml-2 text-xs bg-green-500/20 px-2 py-0.5 rounded-full text-green-400 font-medium">
                  Save 17%
                </span>
              </button>
            </div>
          </div>

          {/* Pricing Cards */}
          <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            {SUBSCRIPTION_PLANS.map((plan, index) => (
              <motion.div 
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 + 0.2 }}
                className={`relative rounded-2xl backdrop-blur-xl ${
                  plan.popular 
                    ? 'border-2 border-blue-500 shadow-lg shadow-blue-500/10'
                    : isDarkMode ? 'border border-gray-700/60' : 'border border-gray-200'
                  } ${
                    isDarkMode ? 'bg-gray-800/50' : 'bg-white/50'
                  } p-6`}
              >
                {plan.popular && (
                  <div className="absolute top-0 right-6 transform -translate-y-1/2 bg-blue-500 text-white px-3 py-1 rounded-full text-sm font-medium shadow-lg shadow-blue-500/20">
                    Popular
                  </div>
                )}
                
                <div className="flex items-center space-x-3">
                  <div className={`p-3 rounded-xl ${plan.popular ? 'bg-blue-500/20' : isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                    <plan.icon className={`w-6 h-6 ${plan.popular ? 'text-blue-500' : isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
                  </div>
                  <h3 className={`text-2xl font-bold ${plan.popular ? 'bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent' : isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {plan.name}
                  </h3>
                </div>
                
                <div className="mt-6 flex items-baseline">
                  <span className={`text-5xl font-extrabold tracking-tight ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    ${isAnnual ? (plan.annualPrice / 12).toFixed(2) : plan.price}
                  </span>
                  <span className={`ml-1 text-xl font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    /mo
                  </span>
                </div>
                
                {isAnnual && plan.price > 0 && (
                  <p className={`mt-1 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    Billed annually (${plan.annualPrice.toFixed(2)})
                  </p>
                )}
                
                <ul className="mt-6 space-y-4">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-start">
                      <div className="flex-shrink-0">
                        <Check className={`h-5 w-5 ${plan.popular ? 'text-blue-500' : 'text-green-500'}`} />
                      </div>
                      <p className={`ml-3 text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                        {feature}
                      </p>
                    </li>
                  ))}
                </ul>
                
                <div className="mt-8">
                

                <Link
                  to={plan.price > 0 ? `/subscribe?interval=${isAnnual ? 'annual' : 'monthly'}` : "/register"}
                  className={`w-full block text-center px-5 py-3 rounded-xl font-medium flex items-center justify-center ${
                    plan.price > 0
                      ? 'bg-blue-600 hover:bg-blue-700 text-white shadow-md shadow-blue-500/20'
                      : isDarkMode 
                        ? 'bg-gray-700 text-white hover:bg-gray-600' 
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  } transition-colors`}
                >
                  {plan.price > 0 ? 'Upgrade to Pro' : 'Get Started for Free'}
                  <ArrowRight className="ml-2 w-4 h-4" />
                </Link>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Feature Comparison */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <FeatureComparison />
        </motion.div>
      </div>
    </div>
  );
};