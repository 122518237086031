// src/services/activityService.ts
import { api } from './api';
import logger from '../config/logger';

export interface Activity {
  id: string;
  type: 'analysis' | 'insight' | 'chat';
  title: string;
  description?: string;
  metadata?: Record<string, any>;
  createdAt: Date;
}

class ActivityService {
  private static instance: ActivityService;

  private constructor() {}

  static getInstance(): ActivityService {
    if (!ActivityService.instance) {
      ActivityService.instance = new ActivityService();
    }
    return ActivityService.instance;
  }

  async getRecentActivities(limit: number = 10): Promise<Activity[]> {
    try {
      const { data } = await api.get<Activity[]>('/activities/recent', {
        params: { limit }
      });
      return data;
    } catch (error) {
      console.log('Failed to fetch recent activities:', error);
      return [];
    }
  }

  async createActivity(activity: Omit<Activity, 'id' | 'createdAt'>): Promise<Activity> {
    try {
      const { data } = await api.post<Activity>('/activities', activity);
      return data;
    } catch (error) {
      console.log('Failed to create activity:', error);
      throw error;
    }
  }

  async deleteActivity(id: string): Promise<void> {
    try {
      await api.delete(`/activities/${id}`);
    } catch (error) {
      console.log('Failed to delete activity:', error);
      throw error;
    }
  }

  async clearActivities(): Promise<void> {
    try {
      await api.delete('/activities');
    } catch (error) {
      console.log('Failed to clear activities:', error);
      throw error;
    }
  }
}

export const activityService = ActivityService.getInstance();