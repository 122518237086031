// src/pages/settings/components/CountrySelector.tsx
import React from 'react';
import { useTheme } from '../../../contexts/ThemeContext';

interface Country {
  code: string;
  name: string;
  dial_code: string;
  emoji: string;
}

const countries: Country[] = [
  { code: "US", name: "United States", dial_code: "+1", emoji: "🇺🇸" },
  { code: "GB", name: "United Kingdom", dial_code: "+44", emoji: "🇬🇧" },
  { code: "CA", name: "Canada", dial_code: "+1", emoji: "🇨🇦" },
  { code: "AU", name: "Australia", dial_code: "+61", emoji: "🇦🇺" },
  { code: "DE", name: "Germany", dial_code: "+49", emoji: "🇩🇪" },
  { code: "FR", name: "France", dial_code: "+33", emoji: "🇫🇷" },
  { code: "IT", name: "Italy", dial_code: "+39", emoji: "🇮🇹" },
  { code: "ES", name: "Spain", dial_code: "+34", emoji: "🇪🇸" },
  { code: "JP", name: "Japan", dial_code: "+81", emoji: "🇯🇵" },
  { code: "KR", name: "South Korea", dial_code: "+82", emoji: "🇰🇷" },
  { code: "IN", name: "India", dial_code: "+91", emoji: "🇮🇳" },
  { code: "BR", name: "Brazil", dial_code: "+55", emoji: "🇧🇷" },
];

interface CountrySelectorProps {
  selectedCountry: Country;
  onSelect: (country: Country) => void;
}

export const CountrySelector: React.FC<CountrySelectorProps> = ({
  selectedCountry,
  onSelect,
}) => {
  const { isDarkMode } = useTheme();
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`flex items-center space-x-2 px-3 py-2 rounded-lg border ${
          isDarkMode 
            ? 'bg-gray-800 border-gray-700 text-white hover:bg-gray-700' 
            : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50'
        } transition-colors`}
      >
        <span className="text-xl">{selectedCountry.emoji}</span>
        <span className="min-w-[48px]">{selectedCountry.dial_code}</span>
      </button>

      {isOpen && (
        <div className={`absolute z-50 mt-2 w-64 rounded-xl border shadow-lg ${
          isDarkMode 
            ? 'bg-gray-800 border-gray-700' 
            : 'bg-white border-gray-200'
        }`}>
          <div className="max-h-60 overflow-auto py-1">
            {countries.map((country) => (
              <button
                key={country.code}
                onClick={() => {
                  onSelect(country);
                  setIsOpen(false);
                }}
                className={`w-full flex items-center space-x-3 px-4 py-2 text-left ${
                  isDarkMode 
                    ? 'hover:bg-gray-700 text-gray-200' 
                    : 'hover:bg-gray-50 text-gray-900'
                } ${selectedCountry.code === country.code ? 'bg-blue-50 dark:bg-blue-900/20' : ''}`}
              >
                <span className="text-xl">{country.emoji}</span>
                <span>{country.name}</span>
                <span className="ml-auto text-sm text-gray-500">{country.dial_code}</span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export const defaultCountry = countries[0];
export type { Country };