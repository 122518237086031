// src/pages/market/MarketIntelligence.tsx

import React, { useState, useCallback } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { screeningService, ScreeningResult } from '../../services/screeningService';
import { LoadingSpinner } from '../../components/common/LoadingSpinner';
import debounce from 'lodash/debounce';
import {
  Brain,
  Search,
  TrendingUp,
  TrendingDown,
  Sparkles,
  ArrowRight,
  ChevronRight,
  AlertTriangle,
  BookOpen,
  Activity,
  RefreshCw,
  Zap,
  Target,
  CircleDot,
  CandlestickChart
} from 'lucide-react';

// Modern glass panel component
const GlassPanel: React.FC<{
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}> = ({ children, className = '', onClick }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <div 
      onClick={onClick}
      className={`
        rounded-2xl border backdrop-blur-xl transition-all
        ${isDarkMode 
          ? 'bg-gray-900/40 border-gray-800/50 shadow-xl shadow-black/20' 
          : 'bg-white/40 border-gray-200/50 shadow-xl shadow-black/5'}
        ${onClick ? 'cursor-pointer hover:border-blue-500/50' : ''}
        ${className}
      `}
    >
      {children}
    </div>
  );
};

const MarketIntelligence: React.FC = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  
  // State
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [results, setResults] = useState<ScreeningResult[]>([]);
  const [naturalLanguageInput, setNaturalLanguageInput] = useState('');
  const [lastUpdate, setLastUpdate] = useState<Date | null>(null);

  // Example strategies
  const strategies = [
    {
      id: 'value',
      name: 'Value Stocks',
      description: 'Find undervalued companies with strong fundamentals',
      query: 'Find stocks with P/E ratio below 15 and market cap above 1 billion',
      icon: Target,
      color: 'text-blue-500'
    },
    {
      id: 'momentum',
      name: 'Momentum Leaders',
      description: 'Stocks showing strong upward momentum',
      query: 'Show me stocks with high volume and price above 20-day moving average',
      icon: TrendingUp,
      color: 'text-green-500'
    },
    {
      id: 'growth',
      name: 'Growth Leaders',
      description: 'High-growth companies with market leadership',
      query: 'Find tech stocks with high revenue growth and strong market position',
      icon: Activity,
      color: 'text-purple-500'
    },
    {
      id: 'technical',
      name: 'Technical Breakouts',
      description: 'Stocks showing technical breakout patterns',
      query: 'Find stocks breaking above resistance with increasing volume',
      icon: CandlestickChart,
      color: 'text-amber-500'
    }
  ];

  // Debounced screening function
  const executeScreen = useCallback(
    debounce(async (query: string) => {
      setIsLoading(true);
      setError(null);
      
      try {
        const screeningResults = await screeningService.processNaturalLanguageQuery(query);
        setResults(screeningResults);
        setLastUpdate(new Date());
      } catch (error) {
        setError(error instanceof Error ? error.message : 'Screening failed');
        setResults([]);
      } finally {
        setIsLoading(false);
      }
    }, 500),
    []
  );

  // Format percentage change
  const formatChange = (change: number) => {
    const sign = change >= 0 ? '+' : '';
    return `${sign}${change.toFixed(2)}%`;
  };

  // Get color based on value
  const getChangeColor = (value: number) => {
    if (value > 0) return 'text-green-500';
    if (value < 0) return 'text-red-500';
    return isDarkMode ? 'text-gray-400' : 'text-gray-600';
  };

  return (
    <div className="min-h-screen">
      {/* Background */}
      <div className="fixed inset-0 bg-gradient-to-br from-gray-900 via-black to-gray-900">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#232323_1px,transparent_1px),linear-gradient(to_bottom,#232323_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_50%,#000_70%,transparent_100%)]" />
      </div>

      {/* Floating Gradient Orbs */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-20 -right-20 w-96 h-96 bg-blue-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-pulse" />
        <div className="absolute -bottom-20 -left-20 w-96 h-96 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-pulse delay-1000" />
      </div>

      {/* Main Content */}
      <div className="relative max-w-7xl mx-auto px-6 py-8">
        {/* Header */}
        <div className="flex items-center justify-between mb-8">
          <div>
            <div className="flex items-center space-x-3 mb-2">
              <div className="relative">
                <div className="w-2 h-2 bg-blue-500 rounded-full" />
                <div className="absolute inset-0 bg-blue-500 rounded-full animate-ping opacity-50" />
              </div>
              <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400">
                Market Intelligence
              </h1>
            </div>
            <p className="text-gray-400">
              AI-powered stock screening and market analysis
            </p>
          </div>
        </div>

        {/* Natural Language Search */}
        <GlassPanel className="mb-6">
          <div className="p-6">
            <div className="flex items-center space-x-4 mb-6">
              <Brain className="w-6 h-6 text-blue-500" />
              <div>
                <h2 className="text-xl font-semibold text-gray-200">
                  Natural Language Screening
                </h2>
                <p className="text-sm text-gray-400 mt-1">
                  Describe your ideal stocks in plain English
                </p>
              </div>
            </div>

            <div className="relative">
              <input
                type="text"
                value={naturalLanguageInput}
                onChange={(e) => setNaturalLanguageInput(e.target.value)}
                placeholder='Try "Find tech stocks with strong momentum and positive sentiment"'
                className={`w-full pl-12 pr-32 py-4 rounded-xl font-mono text-lg
                  ${isDarkMode 
                    ? 'bg-gray-900/50 text-white border-gray-700' 
                    : 'bg-white/50 text-gray-900 border-gray-200'
                  } border focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
              />
              <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
              
              <button
                onClick={() => executeScreen(naturalLanguageInput)}
                disabled={isLoading || !naturalLanguageInput.trim()}
                className="absolute right-3 top-1/2 -translate-y-1/2 px-4 py-2 
                  rounded-lg bg-blue-500 text-white hover:bg-blue-600 
                  disabled:opacity-50 disabled:cursor-not-allowed
                  flex items-center space-x-2"
              >
                {isLoading ? (
                  <LoadingSpinner size="sm" />
                ) : (
                  <>
                    <Zap className="w-4 h-4" />
                    <span>Analyze</span>
                  </>
                )}
              </button>
            </div>

            {/* Example queries */}
            <div className="mt-4 flex items-center space-x-2 text-sm">
              <BookOpen className="w-4 h-4 text-gray-400" />
              <span className="text-gray-400">Try:</span>
              <div className="flex items-center space-x-2">
                {strategies.map((strategy) => (
                  <button
                    key={strategy.id}
                    onClick={() => setNaturalLanguageInput(strategy.query)}
                    className={`px-3 py-1 rounded-lg transition-colors ${
                      isDarkMode 
                        ? 'hover:bg-gray-800 text-gray-300' 
                        : 'hover:bg-gray-100 text-gray-600'
                    }`}
                  >
                    {strategy.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </GlassPanel>

        {/* Strategy Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          {strategies.map((strategy) => (
            <GlassPanel
              key={strategy.id}
              onClick={() => executeScreen(strategy.query)}
              className={naturalLanguageInput === strategy.query ? 'ring-2 ring-blue-500' : ''}
            >
              <div className="p-4">
                <div className="flex items-center justify-between mb-4">
                  <div className={`p-3 rounded-xl bg-gray-800`}>
                    <strategy.icon className={`w-6 h-6 ${strategy.color}`} />
                  </div>
                  <ChevronRight className="w-5 h-5 text-gray-500" />
                </div>
                
                <h3 className="text-lg font-semibold text-gray-200 mb-2">
                  {strategy.name}
                </h3>
                
                <p className="text-sm text-gray-400">
                  {strategy.description}
                </p>
              </div>
            </GlassPanel>
          ))}
        </div>

        {/* Results Section */}
        {isLoading ? (
          <div className="flex flex-col items-center justify-center p-12">
            <LoadingSpinner size="lg" />
            <div className="mt-4 text-gray-400">Analyzing market data...</div>
          </div>
        ) : error ? (
          <GlassPanel className="p-6">
            <div className="flex items-center space-x-3 text-red-400">
              <AlertTriangle className="w-5 h-5" />
              <span>{error}</span>
            </div>
          </GlassPanel>
        ) : results.length > 0 ? (
          <GlassPanel className="mt-6">
            <div className="p-6">
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center space-x-3">
                  <div className="relative">
                    <Sparkles className="w-5 h-5 text-yellow-500" />
                    <div className="absolute inset-0 text-yellow-500 animate-ping opacity-30" />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-200">
                    Screening Results
                  </h3>
                  <div className="px-3 py-1 rounded-full bg-blue-500/10 text-blue-400 text-sm">
                    {results.length} matches
                  </div>
                </div>

                {lastUpdate && (
                  <div className="text-sm text-gray-400">
                    Updated {lastUpdate.toLocaleTimeString()}
                  </div>
                )}
              </div>

              {/* Results Grid */}
              <div className="space-y-4">
                {results.map((result, index) => (
                  <motion.div
                    key={result.ticker}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.05 }}
                    className={`p-4 rounded-xl border ${
                      isDarkMode 
                        ? 'bg-gray-800/50 border-gray-700 hover:border-blue-500' 
                        : 'bg-white/50 border-gray-200 hover:border-blue-500'
                    } transition-colors cursor-pointer`}
                    onClick={() => navigate(`/market/${result.ticker}`)}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-4">
                        <div className={`p-3 rounded-xl ${
                          isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                        }`}>
                          {result.score >= 0.8 ? (
                            <Sparkles className="w-5 h-5 text-yellow-500" />
                          ) : result.score >= 0.6 ? (
                            <Target className="w-5 h-5 text-blue-500" />
                          ) : (
                            <Activity className="w-5 h-5 text-purple-500" />
                          )}
                        </div>
                        
                        <div>
                          <div className="flex items-center space-x-2">
                            <h4 className="text-lg font-semibold text-gray-200">
                              {result.ticker}
                            </h4>
                            <span className="text-sm text-gray-400">
                              {result.name}
                            </span>
                          </div>
                          
                          <div className="flex items-center space-x-4 mt-1">
                            {result.matchedCriteria.map((criterion, i) => (
                              <div 
                                key={i}
                                className="flex items-center space-x-1 text-xs text-gray-400"
                              >
                                <CircleDot className="w-3 h-3" />
                                <span>{criterion}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center space-x-6">
                        {/* Price and Change */}
                        <div className="text-right">
                          <div className="text-lg font-semibold text-gray-200">
                            ${result.price.toFixed(2)}
                          </div>
                          <div className={`text-sm ${getChangeColor(result.change)}`}>
                            {formatChange(result.change)}
                          </div>
                        </div>

                        {/* Volume */}
                        <div className="text-right">
                          <div className="text-sm text-gray-400">Volume</div>
                          <div className="text-sm font-medium text-gray-200">
                            {(result.volume / 1000000).toFixed(1)}M
                          </div>
                        </div>

                        {/* Score */}
                        <div className="flex items-center space-x-2">
                          <div className="text-sm font-medium text-gray-200">
                            {(result.score * 100).toFixed(0)}%
                          </div>
                          <div className="w-20 h-2 bg-gray-700 rounded-full overflow-hidden">
                            <div 
                              className="h-full bg-blue-500 rounded-full"
                              style={{ width: `${result.score * 100}%` }}
                            />
                          </div>
                        </div>

                        <ChevronRight className="w-5 h-5 text-gray-500" />
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </GlassPanel>
        ) : null}
      </div>
    </div>
  );
};

export default MarketIntelligence;