import React, { useState, useEffect } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { marketDataService } from '../../services/marketDataService';
import { TimeRange, StockPrice, CompanyDetails } from '../../types/market';
import { motion, AnimatePresence } from 'framer-motion';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area, AreaChart, ReferenceLine, ReferenceArea } from 'recharts';
import LoadingSpinner from '../common/LoadingSpinner';
import { Alert } from '../ui/alert';
import { SaveItemButton } from '../common/SaveItemButton';
import StockSparkline from '../../pages/dashboard/StockSparkline';
import { 
  X, 
  TrendingUp, 
  TrendingDown, 
  Calendar, 
  Bookmark,
  DollarSign, 
  BarChart2, 
  Users,
  Building2, 
  Globe,
  ExternalLink,
  RefreshCw,
  MessageSquare,
  ChevronDown,
  ChevronUp,
  ArrowUp,
  ArrowDown,
  Clock,
  Star
} from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useWatchlists } from '../../hooks/useWatchlists';
import { BookmarkPlus } from 'lucide-react';

// Time range options for chart
const timeRanges: { label: string; value: TimeRange }[] = [
  { label: '24H', value: '1d' },
  { label: '3D', value: '5d' },
  { label: '1M', value: '1mo' },
  { label: '3M', value: '3mo' },
  { label: '6M', value: '6mo' },
  { label: '1Y', value: '1y' },
];

interface NewsItem {
  title: string;
  description?: string;
  url: string;
  source: string;
  favicon?: string;
  date: string;
  timestamp: string;
}

// Constants with clear ET designation
const MARKET_SESSIONS = {
  PRE_MARKET_START: 4, // 4:00 AM ET
  MARKET_OPEN: 9.5, // 9:30 AM ET
  MARKET_CLOSE: 16, // 4:00 PM ET
  AFTER_HOURS_END: 20, // 8:00 PM ET
};

// Map exchange codes to human-readable names
const EXCHANGE_MAP: Record<string, string> = {
  'XNAS': 'NASDAQ',
  'XNYS': 'NYSE',
  'XASE': 'AMEX',
  'ARCX': 'NYSE Arca',
  'XBOS': 'Boston',
  'XCIS': 'NSE',
  'XCHI': 'Chicago',
  'XPHL': 'Philadelphia',
  'BATS': 'BATS',
  'XCBO': 'CBOE',
  'IEXG': 'IEX',
};

// Helper function to get readable exchange name
const getReadableExchange = (exchangeCode: string): string => {
  return EXCHANGE_MAP[exchangeCode] || exchangeCode;
};

// Helper function to convert ET times to local times for display
const convertETtoLocal = (etHours: number): string => {
  const now = new Date();
  const etDate = new Date(now);
  
  // Set the date to ET time
  etDate.setHours(Math.floor(etHours));
  etDate.setMinutes((etHours % 1) * 60);
  etDate.setSeconds(0);
  
  // Format the date in the local timezone
  return etDate.toLocaleTimeString([], { 
    hour: '2-digit', 
    minute: '2-digit',
    timeZoneName: 'short' 
  });
};

// Modified getMarketSessionInfo to account for ET
const getMarketSessionInfo = (timestamp: number) => {
  const date = new Date(timestamp);
  
  // Convert local time to ET hours
  const etDate = new Date(timestamp);
  const etOptions: Intl.DateTimeFormatOptions = {
    timeZone: 'America/New_York',
    hour: 'numeric',
    minute: 'numeric',
  };
  const etTimeString = etDate.toLocaleString('en-US', etOptions);
  const [etTimeOnly, period] = etTimeString.split(' ');
  const [etHourStr, etMinStr] = etTimeOnly.split(':');
  let etHour = parseInt(etHourStr);
  const etMin = parseInt(etMinStr);
  
  // Convert to 24-hour format if needed
  if (period === 'PM' && etHour !== 12) etHour += 12;
  if (period === 'AM' && etHour === 12) etHour = 0;
  
  const etTotalHours = etHour + (etMin / 60);
  const day = etDate.getDay();
  
  // Weekend
  if (day === 0 || day === 6) {
    return { inSession: false, sessionType: 'weekend', label: 'Weekend' };
  }
  
  if (etTotalHours < MARKET_SESSIONS.PRE_MARKET_START) {
    return { inSession: false, sessionType: 'closed', label: 'Market Closed' };
  } else if (etTotalHours < MARKET_SESSIONS.MARKET_OPEN) {
    return { inSession: true, sessionType: 'pre', label: 'Pre-Market' };
  } else if (etTotalHours < MARKET_SESSIONS.MARKET_CLOSE) {
    return { inSession: true, sessionType: 'regular', label: 'Regular Hours' };
  } else if (etTotalHours < MARKET_SESSIONS.AFTER_HOURS_END) {
    return { inSession: true, sessionType: 'after', label: 'After Hours' };
  } else {
    return { inSession: false, sessionType: 'closed', label: 'Market Closed' };
  }
};

interface StockViewModalProps {
  isOpen: boolean;
  onClose: () => void;
  ticker: string;
  onAnalyze?: (prompt: string) => void;
  fullScreenMode?: boolean;
}

export const StockViewModal: React.FC<StockViewModalProps> = ({
  isOpen,
  onClose,
  ticker,
  onAnalyze,
  fullScreenMode = false
}) => {
  const { isDarkMode } = useTheme();
  const { user } = useAuth();
  const { isItemSaved } = useWatchlists();
  const [stockDetails, setStockDetails] = useState<CompanyDetails | null>(null);
  const [stockData, setStockData] = useState<StockPrice[]>([]);
  const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRange>('1d');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [news, setNews] = useState<NewsItem[]>([]);
  const [newsLoading, setNewsLoading] = useState(false);
  
  const [showNews, setShowNews] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [dayChange, setDayChange] = useState<{ change: number; changePercent: number }>({ change: 0, changePercent: 0 });

  // Format large numbers (like market cap) in a readable format
  const formatLargeNumber = (num: number): string => {
    if (!num) return '$0';
    if (num >= 1e12) return `$${(num / 1e12).toFixed(2)}T`;
    if (num >= 1e9) return `$${(num / 1e9).toFixed(2)}B`;
    if (num >= 1e6) return `$${(num / 1e6).toFixed(2)}M`;
    return `$${num.toLocaleString()}`;
  };

  // Format date for chart tooltip
  const formatDate = (timestamp: number): string => {
    const date = new Date(timestamp);
    
    if (selectedTimeRange === '1d') {
      // For 1d view, format as time only with AM/PM
      return date.toLocaleTimeString([], { 
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      });
    } 
    
    if (selectedTimeRange === '5d') {
      // For 5d view, show weekday and time for better context
      return date.toLocaleDateString([], {
        weekday: 'short'
      }) + ' ' + date.toLocaleTimeString([], { 
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      });
    } 
    
    if (selectedTimeRange === '1mo' || selectedTimeRange === '3mo') {
      // For 1-3 month views, show abbreviated month and day
      return date.toLocaleDateString([], {
        month: 'short',
        day: 'numeric'
      });
    }
    
    // For longer timeframes, show month/day/year
    return date.toLocaleDateString([], {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  // Enhanced time range change handler
  const handleTimeRangeChange = (range: TimeRange) => {
    setSelectedTimeRange(range);
    setIsLoading(true);
    
    const fetchData = async () => {
      try {
        const isAuthenticated = !!user;
        
        const stockDataPromise = isAuthenticated
          ? marketDataService.getStockData(ticker, range)
          : marketDataService.getPublicStockData(ticker, range);
          
        const data = await stockDataPromise;
        
        const validData = data.filter(point => 
          point.t && point.o && point.h && point.l && point.c && point.v
        );
        
        setStockData(validData);
        
        // Calculate both changes whenever data changes
        const changes = calculateChanges(validData);
        setDayChange(changes.dailyChange);
      } catch (err) {
        console.error('Failed to fetch data for timeframe change:', err);
        setError('Failed to update chart data');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  };

  // Calculate price change and percentage
  const calculateChange = () => {
    if (stockData.length === 0) return { change: 0, changePercent: 0 };
    
    const current = stockData[stockData.length - 1].c;
    let reference;
  
    if (selectedTimeRange === '1d') {
      // For 1d view, compare to the opening price
      reference = stockData[0].o;
    } else {
      // For other timeframes, compare to the first close in the data
      reference = stockData[0].c;
    }
    
    const change = current - reference;
    const changePercent = (change / reference) * 100;
    
    return { change, changePercent };
  };

  // Calculate price change for both daily and selected period
  const calculateChanges = (data: StockPrice[]) => {
    if (data.length === 0) return { 
      periodChange: { change: 0, changePercent: 0 },
      dailyChange: { change: 0, changePercent: 0 }
    };
    
    const latest = data[data.length - 1].c;
    
    // For period change
    const periodStart = data[0].c;
    const periodChange = latest - periodStart;
    const periodChangePercent = (periodChange / periodStart) * 100;
    
    // For daily change (always calculate regardless of timeframe)
    let dayReference;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Find the opening price for today or the previous close
    const todayData = data.find(point => {
      const pointDate = new Date(point.t);
      pointDate.setHours(0, 0, 0, 0);
      return pointDate.getTime() === today.getTime() && point.o;
    });
    
    if (todayData) {
      dayReference = todayData.o; // Today's opening price
    } else {
      // Use previous close if available
      const prevClose = data[0].prevClose || data[0].o;
      dayReference = prevClose;
    }
    
    const dayChangeValue = latest - dayReference;
    const dayChangePercent = (dayChangeValue / dayReference) * 100;
    
    return {
      periodChange: { change: periodChange, changePercent: periodChangePercent },
      dailyChange: { change: dayChangeValue, changePercent: dayChangePercent }
    };
  };

  // Determine market session for current price
  // Determine market session for current price
const getCurrentMarketSession = () => {
  const now = new Date();
  
  // Get ET hours and minutes
  const etOptions: Intl.DateTimeFormatOptions = {
    timeZone: 'America/New_York',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  };
  const etTime = now.toLocaleString('en-US', etOptions);
  const [etHours, etMinutes] = etTime.split(':').map(Number);
  const etTimeInMinutes = etHours * 60 + etMinutes;
  
  const day = now.getDay();
  
  if (day === 0 || day === 6) return { status: 'closed', label: 'Weekend' };
  
  if (etTimeInMinutes < 4 * 60) return { status: 'closed', label: 'After Hours' };
  if (etTimeInMinutes < 9 * 60 + 30) return { status: 'pre', label: 'Pre-Market' };
  if (etTimeInMinutes < 16 * 60) return { status: 'regular', label: 'Regular Hours' };
  if (etTimeInMinutes < 20 * 60) return { status: 'after', label: 'After Hours' };
  return { status: 'closed', label: 'Market Closed' };
};

  // Handle refresh click
  const handleRefresh = async () => {
    setRefreshing(true);
    try {
      const data = await marketDataService.getPublicStockData(ticker, selectedTimeRange);
      setStockData(data);
    } catch (err) {
      console.error('Failed to refresh data:', err);
    } finally {
      setRefreshing(false);
    }
  };
  
  // Handle analyze button click
  const handleAnalyzeClick = () => {
    if (onAnalyze && stockDetails) {
      onAnalyze(`Analyze ${ticker} (${stockDetails.name}): provide recent performance, key metrics, and outlook.`);
      onClose();
    }
  };

  // Fetch stock data when ticker or time range changes
  useEffect(() => {
  if (!isOpen || !ticker) return;

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      // Use either authenticated or public endpoints based on auth status
      const isAuthenticated = !!user;
      
      const stockDataPromise = isAuthenticated
        ? marketDataService.getStockData(ticker, selectedTimeRange)
        : marketDataService.getPublicStockData(ticker, selectedTimeRange);
      
      const detailsPromise = isAuthenticated
        ? marketDataService.getStockDetails(ticker)
        : marketDataService.getPublicCompanyDetails(ticker);
      
      // Add news fetch
      const newsPromise = marketDataService.getStockNews(ticker, 5);
      
      // Await all promises
      const [data, details, newsData] = await Promise.all([stockDataPromise, detailsPromise, newsPromise]);
      
      // Filter out invalid data points and ensure we have proper timestamps
      const validData = data.filter(point => 
        point.t && point.o && point.h && point.l && point.c && point.v
      );
      
      // If using 1d timeframe with too few points, add artificial points for better visualization
      if (selectedTimeRange === '1d' && validData.length < 5) {
        console.warn('Not enough data points for 1d view, using extended range');
        // Try fetching 5d data and filtering just the last day
        const extendedData = await (isAuthenticated
          ? marketDataService.getStockData(ticker, '5d')
          : marketDataService.getPublicStockData(ticker, '5d'));
          
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const todayTimestamp = today.getTime();
        
        const filteredData = extendedData.filter(point => 
          point.t >= todayTimestamp
        );
        
        if (filteredData.length > 0) {
          setStockData(filteredData);
          // Calculate both changes when setting filtered data
          const changes = calculateChanges(filteredData);
          setDayChange(changes.dailyChange);
        } else {
          setStockData(validData);
          // Calculate both changes when setting valid data
          const changes = calculateChanges(validData);
          setDayChange(changes.dailyChange);
        }
      } else {
        setStockData(validData);
        // Calculate both changes when setting valid data
        const changes = calculateChanges(validData);
        setDayChange(changes.dailyChange);
      }
      
      setStockDetails(details);
        
        // Fetch news - mock data for now
        setNews(newsData);
      } catch (err) {
        console.error('Failed to fetch stock data:', err);
        setError('Failed to load stock data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [isOpen, ticker, selectedTimeRange, user]);

  // Early return if not open
  if (!isOpen) return null;

  // Calculate change values
  const { change, changePercent } = calculateChange();
  const isPositive = change >= 0;
  
  // Better chart data formatter
  const chartData = stockData.map(dataPoint => {
    // Ensure timestamp is a number
    const timestamp = typeof dataPoint.t === 'number' 
      ? dataPoint.t 
      : new Date(dataPoint.t).getTime();
      
    return {
      timestamp,
      price: dataPoint.c,
      open: dataPoint.o,
      high: dataPoint.h,
      low: dataPoint.l,
      volume: dataPoint.v,
      // Add previous close if available
      prevClose: dataPoint.prevClose
    };
  });

  const modalClasses = fullScreenMode 
    ? "fixed inset-0 z-50 flex items-center justify-center"
    : "fixed inset-0 z-50 flex items-center justify-center p-4";

  const contentClasses = fullScreenMode
    ? `flex flex-col w-full h-full overflow-hidden ${isDarkMode ? 'bg-gray-900' : 'bg-white'}`
    : `relative flex flex-col w-full max-w-3xl max-h-[90vh] rounded-2xl shadow-2xl overflow-hidden border ${isDarkMode ? 'bg-gray-900/90 border-gray-700/40 backdrop-blur-xl' : 'bg-white/90 border-gray-200/40 backdrop-blur-xl'}`;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={modalClasses}
      >
        {/* Backdrop with blur */}
        <motion.div 
          className="absolute inset-0 bg-black/40 backdrop-blur-sm"
          onClick={onClose}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        />
        
        {/* Modal Content */}
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          className={contentClasses}
        >
          {/* Background gradients */}
          <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 via-purple-500/5 to-transparent opacity-50 pointer-events-none" />
          <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent pointer-events-none blur-3xl" />
          
          {/* Modern header */}
          <div className={`flex items-center justify-between p-5 border-b ${isDarkMode ? 'border-gray-800/50' : 'border-gray-200/50'}`}>
            <div className="flex items-center space-x-3">
            <div className={`w-12 h-12 rounded-xl flex items-center justify-center bg-gradient-to-br ${
  isPositive 
  ? 'from-green-500/20 to-emerald-500/10 border border-green-500/20' 
  : 'from-red-500/20 to-rose-500/10 border border-red-500/20'
} overflow-hidden`}>
  <img 
    src={`/api/market/company-logo/${ticker}`}
    alt={stockDetails?.name || ticker}
    className="w-12 h-12 object-contain rounded-md transition-all duration-200 hover:scale-105"
    onError={(e) => {
      const target = e.target as HTMLImageElement;
      // Instead of using an SVG, let's create a better fallback
      target.style.display = 'none';
      const parent = e.currentTarget.parentElement;
      if (parent) {
        // Create a nice fallback with the first letter of the ticker
        const fallback = document.createElement('div');
        fallback.className = `w-full h-full flex items-center justify-center ${isDarkMode ? 'text-white' : 'text-gray-800'} font-bold text-lg`;
        fallback.textContent = ticker.charAt(0);
        parent.appendChild(fallback);
      }
    }}
  />
</div>
              
              <div>
                <div className="flex items-center">
                  <h2 className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {ticker}
                  </h2>
                  {stockDetails && (
                    <span className={`ml-2 text-sm px-2 py-0.5 rounded-full ${
                      isDarkMode ? 'bg-gray-800/80 text-gray-400' : 'bg-gray-100/80 text-gray-600'
                    }`}>
                      {getReadableExchange(stockDetails.exchange)}
                    </span>
                  )}
                </div>
                {stockDetails && (
                  <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    {stockDetails.name}
                  </p>
                )}
              </div>
            </div>
            
            <div className="flex items-center space-x-2">
              {/* Save button */}
              <SaveItemButton
                type="STOCK"
                identifier={ticker}
                title={stockDetails?.name || ticker}
                metadata={{
                  sector: stockDetails?.sector,
                  industry: stockDetails?.industry,
                  addedFrom: 'stockModal'
                }}
                variant="button"
                className={`p-2 rounded-lg transition-colors ${
                  isDarkMode 
                    ? 'hover:bg-gray-800 text-gray-400' 
                    : 'hover:bg-gray-100 text-gray-600'
                }`}
              />
              
              {/* Close button */}
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={onClose}
                className={`p-2 rounded-lg transition-colors ${
                  isDarkMode 
                    ? 'hover:bg-gray-800/80 text-gray-400' 
                    : 'hover:bg-gray-100/80 text-gray-600'
                }`}
                aria-label="Close"
              >
                <X className="w-5 h-5" />
              </motion.button>
            </div>
          </div>
          
          {/* Content */}
          {isLoading ? (
            <div className="flex-1 flex items-center justify-center p-8">
              <div className="flex flex-col items-center">
                <LoadingSpinner size="lg" />
                <p className={`mt-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  Loading {ticker} data...
                </p>
              </div>
            </div>
          ) : error ? (
            <div className="flex-1 flex items-center justify-center p-8">
              <Alert variant="destructive">
                {error}
              </Alert>
            </div>
          ) : (
            <div className="flex-1 overflow-auto relative">
              {/* Price Info - Modern glass card */}
              <div className={`p-6 ${isDarkMode ? 'bg-gray-800/30' : 'bg-gray-50/50'} backdrop-blur-sm`}>
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <div className="flex items-baseline">
                      <h3 className={`text-3xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'} font-mono`}>
                        ${stockData.length > 0 ? stockData[stockData.length - 1].c.toFixed(2) : '0.00'}
                      </h3>
                      
                      {/* Market session indicator */}
                      {/* Market session indicator */}
{stockData.length > 0 && (
  <div className={`ml-3 px-2 py-0.5 rounded-full text-xs ${
    (() => {
      const session = getCurrentMarketSession();
      switch (session.status) {
        case 'pre': return isDarkMode ? 'bg-blue-500/20 text-blue-400' : 'bg-blue-100 text-blue-600';
        case 'regular': return isDarkMode ? 'bg-green-500/20 text-green-400' : 'bg-green-100 text-green-600';
        case 'after': return isDarkMode ? 'bg-purple-500/20 text-purple-400' : 'bg-purple-100 text-purple-600';
        default: return isDarkMode ? 'bg-gray-500/20 text-gray-400' : 'bg-gray-100 text-gray-600';
      }
    })()
  }`}>
    {getCurrentMarketSession().label} <span className="opacity-70">(ET)</span>
  </div>
)}
                    </div>
                    
                    {/* Price changes container */}
                    <div className="flex items-center space-x-4 mt-2">
                      {/* Day change - Always visible */}
                      <motion.div 
                        animate={{ 
                          scale: [1, 1.05, 1],
                          opacity: [1, 0.8, 1]
                        }}
                        transition={{
                          duration: 2,
                          repeat: Infinity,
                          repeatType: 'reverse'
                        }}
                        className={`flex items-center px-3 py-1 rounded-full ${
                          dayChange.change >= 0 
                            ? isDarkMode ? 'bg-green-500/20 text-green-400' : 'bg-green-100 text-green-600' 
                            : isDarkMode ? 'bg-red-500/20 text-red-400' : 'bg-red-100 text-red-600'
                        }`}
                      >
                        {dayChange.change >= 0 ? <ArrowUp className="w-3 h-3 mr-1" /> : <ArrowDown className="w-3 h-3 mr-1" />}
                        <span className="text-sm font-medium">
                          {dayChange.change >= 0 ? '+' : ''}{dayChange.change.toFixed(2)} ({dayChange.changePercent.toFixed(2)}%)
                        </span>
                        <span className="ml-1 text-xs opacity-80">Today</span>
                      </motion.div>
                      
                      {/* Period change - Only visible if different from day change and not on 1d timeframe */}
                      {selectedTimeRange !== '1d' && (
                        <motion.div 
                          animate={{ 
                            scale: [1, 1.05, 1],
                            opacity: [1, 0.8, 1]
                          }}
                          transition={{
                            duration: 2,
                            repeat: Infinity,
                            repeatType: 'reverse',
                            delay: 0.5
                          }}
                          className={`flex items-center px-3 py-1 rounded-full ${
                            change >= 0 
                              ? isDarkMode ? 'bg-blue-500/20 text-blue-400' : 'bg-blue-100 text-blue-600' 
                              : isDarkMode ? 'bg-orange-500/20 text-orange-400' : 'bg-orange-100 text-orange-600'
                          }`}
                        >
                          {change >= 0 ? <ArrowUp className="w-3 h-3 mr-1" /> : <ArrowDown className="w-3 h-3 mr-1" />}
                          <span className="text-sm font-medium">
                            {change >= 0 ? '+' : ''}{change.toFixed(2)} ({changePercent.toFixed(2)}%)
                          </span>
                          <span className="ml-1 text-xs opacity-80">
                            {timeRanges.find(r => r.value === selectedTimeRange)?.label || selectedTimeRange}
                          </span>
                        </motion.div>
                      )}
                    </div>
                    
                    <div className={`mt-1 text-xs flex items-center ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      <Clock className="w-3 h-3 mr-1" />
                      <span>
                        Last updated: {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </span>
                    </div>
                  </div>
                  
                  <motion.button 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleRefresh} 
                    disabled={refreshing}
                    className={`p-2 rounded-lg ${
                      isDarkMode 
                        ? 'bg-gray-700/50 hover:bg-gray-700 text-gray-300' 
                        : 'bg-gray-200/50 hover:bg-gray-200/80 text-gray-700'
                    } ${refreshing ? 'opacity-50' : ''}`}
                  >
                    <RefreshCw className={`w-4 h-4 ${refreshing ? 'animate-spin' : ''}`} />
                  </motion.button>
                </div>
                
                {/* Time Range Selector - Pills design */}
                {/* Time Range Selector with loading indicator */}
                <div className={`flex p-1 rounded-xl space-x-1 ${isDarkMode ? 'bg-gray-800/50' : 'bg-gray-200/30'} max-w-fit`}>
                  {timeRanges.map(range => (
                    <motion.button
                      key={range.value}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => handleTimeRangeChange(range.value)}
                      disabled={isLoading}
                      className={`px-3 py-1.5 text-xs font-medium rounded-lg transition-all ${
                        isLoading && selectedTimeRange === range.value 
                          ? 'opacity-50' 
                          : ''
                      } ${
                        selectedTimeRange === range.value
                          ? isDarkMode 
                            ? 'bg-blue-500/20 text-blue-400 border border-blue-500/30' 
                            : 'bg-blue-100 text-blue-600 border border-blue-200'
                          : isDarkMode 
                            ? 'text-gray-400 hover:text-gray-300 border border-transparent' 
                            : 'text-gray-600 hover:text-gray-800 border border-transparent'
                      }`}
                    >
                      {range.label}
                      {isLoading && selectedTimeRange === range.value && (
                        <span className="ml-1 inline-block animate-spin">⟳</span>
                      )}
                    </motion.button>
                  ))}
                </div>
              </div>

              {/* Chart - More modern styling with improved market sessions */}
                            {/* Chart - Professional Trading Style */}
              <div className={`h-[420px] w-full p-6 pt-8 ${isDarkMode ? 'bg-gray-900' : 'bg-white'} relative`}>
                {/* Chart Controls - Top right floating panel */}
                <div className="absolute top-3 right-3 z-10 px-3 py-2 rounded-lg bg-gray-900/80 backdrop-blur-sm shadow-lg border border-gray-700/50 flex items-center space-x-2">
                  <div className="flex flex-col space-y-2 text-xs mr-3">
                    <div className="text-gray-300 font-medium">Sessions</div>
                    <div className="flex items-center space-x-1">
                      <div className="w-3 h-3 rounded-full bg-blue-400" style={{ opacity: 0.4 }}></div>
                      <span className="text-gray-300">Pre</span>
                    </div>
                    <div className="flex items-center space-x-1">
                      <div className="w-3 h-3 rounded-full bg-green-400" style={{ opacity: 0.4 }}></div>
                      <span className="text-gray-300">Regular</span>
                    </div>
                    <div className="flex items-center space-x-1">
                      <div className="w-3 h-3 rounded-full bg-purple-400" style={{ opacity: 0.4 }}></div>
                      <span className="text-gray-300">After</span>
                    </div>
                  </div>
                  
                  <div className="h-14 w-px bg-gray-700"></div>
                  
                  {/* Chart Type Controls - Can be expanded with more functionality */}
                  <div className="flex flex-col items-center">
                    <div className="text-gray-300 text-xs font-medium mb-1">Chart</div>
                    <div className="flex space-x-1">
                      <button className="p-1.5 rounded bg-blue-500/20 text-blue-400">
                        <AreaChart className="w-3.5 h-3.5" />
                      </button>
                      <button className="p-1.5 rounded bg-gray-800 text-gray-400 hover:bg-gray-700/80 hover:text-gray-300">
                        <BarChart2 className="w-3.5 h-3.5" />
                      </button>
                    </div>
                  </div>
                </div>

                {/* Price Statistics Panel - Left side */}
                <div className="absolute top-3 left-3 z-10 px-3 py-2 rounded-lg bg-gray-900/80 backdrop-blur-sm shadow-lg border border-gray-700/50">
                  <div className="grid grid-cols-2 gap-x-4 gap-y-1.5 text-xs">
                    <div className="text-gray-400">O</div>
                    <div className="text-gray-300 font-mono">${chartData[0]?.open.toFixed(2) || '0.00'}</div>
                    <div className="text-gray-400">H</div>
                    <div className="text-gray-300 font-mono">${Math.max(...chartData.map(d => d.high)).toFixed(2)}</div>
                    <div className="text-gray-400">L</div>
                    <div className="text-gray-300 font-mono">${Math.min(...chartData.map(d => d.low)).toFixed(2)}</div>
                    <div className="text-gray-400">C</div>
                    <div className="text-gray-300 font-mono">${chartData[chartData.length - 1]?.price.toFixed(2) || '0.00'}</div>
                  </div>
                </div>

                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={chartData} margin={{ top: 20, right: 8, left: 8, bottom: 24 }}>
                    <defs>
                      <linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
                        <stop 
                          offset="5%" 
                          stopColor={isPositive ? "#10B981" : "#EF4444"} 
                          stopOpacity={0.3}
                        />
                        <stop 
                          offset="95%" 
                          stopColor={isPositive ? "#10B981" : "#EF4444"} 
                          stopOpacity={0}
                        />
                      </linearGradient>

                      {/* Improved patterns for better visibility */}
                      <pattern id="preMarketPattern" patternUnits="userSpaceOnUse" width="6" height="6" patternTransform="rotate(45)">
                        <rect width="6" height="6" fill="#3B82F6" fillOpacity="0.15" />
                        <line x1="0" y1="0" x2="0" y2="6" stroke="#3B82F6" strokeWidth="1" strokeOpacity="0.3" />
                      </pattern>
                      <pattern id="regularPattern" patternUnits="userSpaceOnUse" width="6" height="6">
                        <rect width="6" height="6" fill={isPositive ? "#10B981" : "#EF4444"} fillOpacity="0.08" />
                      </pattern>
                      <pattern id="afterHoursPattern" patternUnits="userSpaceOnUse" width="6" height="6" patternTransform="rotate(-45)">
                        <rect width="6" height="6" fill="#8B5CF6" fillOpacity="0.15" />
                        <line x1="0" y1="0" x2="0" y2="6" stroke="#8B5CF6" strokeWidth="1" strokeOpacity="0.3" />
                      </pattern>
                    </defs>

                    {/* Trading sessions with clearer visibility */}
                    {selectedTimeRange === '1d' && chartData.length > 0 && (
                      <>
                        {/* Pre-market session */}
                        <ReferenceArea
                          x1={chartData[0].timestamp}
                          x2={chartData.find(d => {
                            const date = new Date(d.timestamp);
                            const etOptions: Intl.DateTimeFormatOptions = {
                              timeZone: 'America/New_York',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: false
                            };
                            const etTime = date.toLocaleString('en-US', etOptions);
                            const [hours, minutes] = etTime.split(':').map(Number);
                            return hours === 9 && minutes >= 30;
                          })?.timestamp || chartData[0].timestamp}
                          strokeOpacity={0}
                          fill="url(#preMarketPattern)"
                        />
                        
                        {/* Regular trading hours */}
                        <ReferenceArea
                          x1={chartData.find(d => {
                            const date = new Date(d.timestamp);
                            const etOptions: Intl.DateTimeFormatOptions = {
                              timeZone: 'America/New_York',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: false
                            };
                            const etTime = date.toLocaleString('en-US', etOptions);
                            const [hours, minutes] = etTime.split(':').map(Number);
                            return hours === 9 && minutes >= 30;
                          })?.timestamp || chartData[0].timestamp}
                          x2={chartData.find(d => {
                            const date = new Date(d.timestamp);
                            const etOptions: Intl.DateTimeFormatOptions = {
                              timeZone: 'America/New_York',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: false
                            };
                            const etTime = date.toLocaleString('en-US', etOptions);
                            const [hours] = etTime.split(':').map(Number);
                            return hours >= 16;
                          })?.timestamp || chartData[chartData.length - 1].timestamp}
                          fill="url(#regularPattern)"
                        />
                        
                        {/* After-hours session */}
                        <ReferenceArea
                          x1={chartData.find(d => {
                            const date = new Date(d.timestamp);
                            const etOptions: Intl.DateTimeFormatOptions = {
                              timeZone: 'America/New_York',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: false
                            };
                            const etTime = date.toLocaleString('en-US', etOptions);
                            const [hours] = etTime.split(':').map(Number);
                            return hours >= 16;
                          })?.timestamp || chartData[chartData.length - 1].timestamp}
                          x2={chartData[chartData.length - 1].timestamp}
                          fill="url(#afterHoursPattern)"
                        />
                        
                        {/* Market session transition lines */}
                        <ReferenceLine
                          x={chartData.find(d => {
                            const date = new Date(d.timestamp);
                            const etOptions: Intl.DateTimeFormatOptions = {
                              timeZone: 'America/New_York',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: false
                            };
                            const etTime = date.toLocaleString('en-US', etOptions);
                            const [hours, minutes] = etTime.split(':').map(Number);
                            return hours === 9 && minutes >= 30;
                          })?.timestamp}
                          stroke="#10B981"
                          strokeWidth={1.5}
                          strokeDasharray="3 3"
                          label={{ 
                            value: '9:30 AM ET', 
                            position: 'insideBottomRight',
                            fill: '#10B981',
                            fontSize: 10,
                            offset: 10,
                          }}
                        />
                        
                        <ReferenceLine
                          x={chartData.find(d => {
                            const date = new Date(d.timestamp);
                            const etOptions: Intl.DateTimeFormatOptions = {
                              timeZone: 'America/New_York',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: false
                            };
                            const etTime = date.toLocaleString('en-US', etOptions);
                            const [hours] = etTime.split(':').map(Number);
                            return hours >= 16;
                          })?.timestamp}
                          stroke="#EF4444"
                          strokeWidth={1.5}
                          strokeDasharray="3 3"
                          label={{ 
                            value: '4:00 PM ET', 
                            position: 'insideTopRight',
                            fill: '#EF4444',
                            fontSize: 10,
                            offset: -10,
                          }}
                        />
                      </>
                    )}

                    {/* Grid lines styled for trading charts */}
                    <CartesianGrid 
                      strokeDasharray="3 3" 
                      stroke={isDarkMode ? 'rgba(55, 65, 81, 0.2)' : 'rgba(229, 231, 235, 0.8)'} 
                      horizontal={true}
                      vertical={true}
                    />
                    
                    {/* Previous day close reference line */}
                    {chartData.length > 0 && chartData[0].prevClose && (
                      <ReferenceLine 
                        y={chartData[0].prevClose} 
                        stroke={isDarkMode ? 'rgba(250, 200, 60, 0.6)' : 'rgba(217, 119, 6, 0.6)'} 
                        strokeWidth={1.5}
                        strokeDasharray="3 3"
                        label={{
                          value: `Prev Close: $${chartData[0].prevClose.toFixed(2)}`,
                          fill: isDarkMode ? 'rgba(250, 200, 60, 0.9)' : 'rgba(217, 119, 6, 0.9)',
                          position: 'insideBottomRight'
                        }}
                      />
                    )}
                    
                    {/* Enhanced tooltip with financial data */}
                    <Tooltip 
                      formatter={(value: number, name: string) => {
                        if (name === 'price') return [`$${value.toFixed(2)}`, 'Price'];
                        return [value, name];
                      }} 
                      labelFormatter={(timestamp) => {
                        const date = new Date(timestamp as number);
                        const localDateStr = formatDate(timestamp as number);
                        
                        // Get Eastern Time display
                        const etOptions: Intl.DateTimeFormatOptions = {
                          timeZone: 'America/New_York',
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: true
                        };
                        const etTimeStr = date.toLocaleString('en-US', etOptions);
                        
                        // Show relevant market session for 1D timeframe
                        if (selectedTimeRange === '1d') {
                          const session = getMarketSessionInfo(timestamp as number);
                          return (
                            <>
                              <div className="font-medium">{localDateStr}</div>
                              <div className="text-xs mt-1">{etTimeStr} ET</div>
                              <div className={`text-xs mt-1 font-medium ${
                                session.sessionType === 'regular' ? 'text-green-500' :
                                session.sessionType === 'pre' ? 'text-blue-500' :
                                session.sessionType === 'after' ? 'text-purple-500' :
                                'text-gray-500'
                              }`}>
                                {session.label}
                              </div>
                            </>
                          );
                        }
                        return (
                          <>
                            <div className="font-medium">{localDateStr}</div>
                            <div className="text-xs mt-1">{etTimeStr} ET</div>
                          </>
                        );
                      }}
                      contentStyle={{ 
                        backgroundColor: isDarkMode ? 'rgba(24, 24, 27, 0.95)' : 'rgba(255, 255, 255, 0.95)',
                        borderColor: isDarkMode ? 'rgba(63, 63, 70, 0.5)' : 'rgba(228, 228, 231, 0.8)',
                        color: isDarkMode ? 'white' : 'black',
                        borderRadius: '0.75rem',
                        boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.2), 0 4px 6px -2px rgba(0, 0, 0, 0.1)',
                        padding: '12px'
                      }}
                    />
                    
                    {/* Improved X/Y axes */}
                    <XAxis 
                      dataKey="timestamp" 
                      tickFormatter={formatDate} 
                      stroke={isDarkMode ? 'rgba(156, 163, 175, 0.6)' : 'rgba(107, 114, 128, 0.7)'} 
                      tick={{ fontSize: 11 }}
                      tickLine={false}
                      axisLine={{ stroke: isDarkMode ? 'rgba(55, 65, 81, 0.6)' : 'rgba(209, 213, 219, 0.8)' }}
                      minTickGap={40}
                      padding={{ left: 10, right: 10 }}
                    />
                    <YAxis 
                      domain={['auto', 'auto']}
                      stroke={isDarkMode ? 'rgba(156, 163, 175, 0.6)' : 'rgba(107, 114, 128, 0.7)'} 
                      tick={{ fontSize: 11 }}
                      tickLine={false}
                      axisLine={{ stroke: isDarkMode ? 'rgba(55, 65, 81, 0.6)' : 'rgba(209, 213, 219, 0.8)' }}
                      orientation="right"
                      tickFormatter={(value) => `$${value.toFixed(2)}`}
                      padding={{ top: 10, bottom: 10 }}
                      width={65}
                    />
                    
                    {/* The main price area with custom dots for sessions */}
                    <Area 
                      type="monotone" 
                      dataKey="price" 
                      stroke={isPositive ? '#10B981' : '#EF4444'} 
                      fill="url(#colorPrice)"
                      strokeWidth={2}
                      dot={false}
                      activeDot={(props: any) => {
                        // Customize dot appearance based on market session
                        let fillColor = isPositive ? '#10B981' : '#EF4444';
                        let size = 6;
                        
                        if (selectedTimeRange === '1d' && props.payload) {
                          const session = getMarketSessionInfo(props.payload.timestamp);
                          if (session.sessionType === 'pre') fillColor = '#3B82F6';
                          else if (session.sessionType === 'regular') fillColor = isPositive ? '#10B981' : '#EF4444';
                          else if (session.sessionType === 'after') fillColor = '#8B5CF6';
                          else fillColor = '#6B7280';
                        }
                        
                        return (
                          <g>
                            <circle 
                              cx={props.cx} 
                              cy={props.cy} 
                              r={size} 
                              fill={fillColor}
                              stroke={isDarkMode ? '#1F2937' : '#FFFFFF'}
                              strokeWidth={1.5}
                            />
                            <circle 
                              cx={props.cx} 
                              cy={props.cy} 
                              r={size + 4} 
                              fill="none"
                              stroke={fillColor}
                              strokeWidth={1}
                              strokeOpacity={0.4}
                            />
                          </g>
                        );
                      }}
                    />
                  </AreaChart>
                </ResponsiveContainer>
                
                {/* Volume histogram at the bottom */}
                <div className="absolute bottom-0 left-8 right-8 h-16 flex items-end">
                  {chartData.map((point, i) => {
                    const width = 100 / chartData.length;
                    const height = (point.volume / Math.max(...chartData.map(d => d.volume))) * 100;
                    const isUp = i > 0 ? point.price >= chartData[i-1].price : true;
                    
                    return (
                      <div 
                        key={i}
                        className={`flex-1 ${isUp ? 'bg-green-500/30' : 'bg-red-500/30'}`}
                        style={{ 
                          height: `${Math.max(5, height)}%`,
                          marginLeft: i === 0 ? 0 : '1px'
                        }}
                      />
                    );
                  })}
                </div>
                
                {/* Session indicator bar */}
                {selectedTimeRange === '1d' && chartData.length > 0 && (
                  <div className="absolute bottom-20 left-8 right-8 flex h-1.5 rounded-full overflow-hidden">
                    <div className="bg-blue-500/50 h-full" style={{ width: '23%' }}></div>
                    <div className="bg-green-500/50 h-full" style={{ width: '43%' }}></div>
                    <div className="bg-purple-500/50 h-full" style={{ width: '34%' }}></div>
                  </div>
                )}
              </div>
                
              
              {/* Key Stats - Card design */}
              <div className={`grid grid-cols-1 sm:grid-cols-2 gap-4 p-6 ${isDarkMode ? 'bg-gray-800/20' : 'bg-gray-50/50'} backdrop-blur-sm`}>
                {stockDetails?.marketCap && (
                  <div className={`p-4 rounded-xl border ${
                    isDarkMode ? 'bg-gray-800/50 border-gray-700/40' : 'bg-white/50 border-gray-200/40'
                  } backdrop-blur-sm`}>
                    <div className="flex items-center justify-between">
                      <div className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} flex items-center`}>
                        <DollarSign className="w-4 h-4 mr-2" /> Market Cap
                      </div>
                      <div className={`p-1.5 rounded-lg ${isDarkMode ? 'bg-gray-700/50' : 'bg-gray-100/80'}`}>
                        <DollarSign className={`w-4 h-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
                      </div>
                    </div>
                    <div className={`mt-2 text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'} font-mono`}>
                      {formatLargeNumber(stockDetails.marketCap)}
                    </div>
                  </div>
                )}
                
                {stockData.length > 0 && (
                  <div className={`p-4 rounded-xl border ${
                    isDarkMode ? 'bg-gray-800/50 border-gray-700/40' : 'bg-white/50 border-gray-200/40'
                  } backdrop-blur-sm`}>
                    <div className="flex items-center justify-between">
                      <div className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} flex items-center`}>
                        <BarChart2 className="w-4 h-4 mr-2" /> Volume
                      </div>
                      <div className={`p-1.5 rounded-lg ${isDarkMode ? 'bg-gray-700/50' : 'bg-gray-100/80'}`}>
                        <BarChart2 className={`w-4 h-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
                      </div>
                    </div>
                    <div className={`mt-2 text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'} font-mono`}>
                      {stockData[stockData.length - 1].v.toLocaleString()}
                    </div>
                  </div>
                )}
                
                {(stockDetails?.sector || stockDetails?.industry) && (
                  <div className={`p-4 rounded-xl border ${
                    isDarkMode ? 'bg-gray-800/50 border-gray-700/40' : 'bg-white/50 border-gray-200/40'
                  } backdrop-blur-sm sm:col-span-2`}>
                    <div className="flex items-center justify-between">
                      <div className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} flex items-center`}>
                        <Building2 className="w-4 h-4 mr-2" /> Classification
                      </div>
                      <div className={`p-1.5 rounded-lg ${isDarkMode ? 'bg-gray-700/50' : 'bg-gray-100/80'}`}>
                        <Building2 className={`w-4 h-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
                      </div>
                    </div>
                    <div className="mt-2 grid grid-cols-2 gap-4">
                      {stockDetails?.sector && (
                        <div>
                          <div className={`text-xs ${isDarkMode ? 'text-gray-500' : 'text-gray-500'}`}>
                            Sector
                          </div>
                          <div className={`text-sm font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                            {stockDetails.sector}
                          </div>
                        </div>
                      )}
                      
                      {stockDetails?.industry && (
                        <div>
                          <div className={`text-xs ${isDarkMode ? 'text-gray-500' : 'text-gray-500'}`}>
                            Industry
                          </div>
                          <div className={`text-sm font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                            {stockDetails.industry}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              
                          
                      {stockDetails?.description && (
                        <div className={`p-6 ${isDarkMode ? 'bg-gray-900' : 'bg-white'}`}>
                          <button
                            onClick={() => setShowMoreInfo(!showMoreInfo)}
                            className={`flex items-center justify-between w-full ${
                              isDarkMode ? 'text-white hover:text-blue-400' : 'text-gray-900 hover:text-blue-600'
                            } transition-colors duration-200`}
                          >
                            <div className="flex items-center">
                              <div className={`p-2 rounded-lg mr-3 ${
                                isDarkMode ? 'bg-gray-800/80 text-blue-400' : 'bg-blue-50 text-blue-500'
                              }`}>
                                <Building2 className="w-4 h-4" />
                              </div>
                              <h3 className="font-medium">About {stockDetails.name}</h3>
                            </div>
                            <div className={`transform transition-transform duration-200 ${showMoreInfo ? 'rotate-180' : ''}`}>
                              <ChevronDown className="w-5 h-5" />
                            </div>
                          </button>
                          
                          <AnimatePresence>
                            {showMoreInfo && (
                              <motion.div
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ height: 'auto', opacity: 1 }}
                                exit={{ height: 0, opacity: 0 }}
                                transition={{ duration: 0.3 }}
                                className="overflow-hidden"
                              >
                                <div className={`mt-4 p-4 rounded-xl border ${
                                  isDarkMode ? 'bg-gray-800/50 border-gray-700' : 'bg-white/80 border-gray-200'
                                } backdrop-blur-sm`}>
                                  <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'} leading-relaxed`}>
                                    {stockDetails.description}
                                  </p>
                                  
                                  {stockDetails.homepage && (
                                    <a 
                                      href={stockDetails.homepage}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className={`mt-4 inline-flex items-center text-sm ${
                                        isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-500'
                                      }`}
                                    >
                                      <Globe className="w-4 h-4 mr-2" />
                                      Visit Website
                                      <ExternalLink className="w-3 h-3 ml-1" />
                                    </a>
                                  )}
                                </div>
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </div>
                      )}
                      
                      {/* News Section - Modern cards with hover effects */}
                      <div className={`p-6 ${isDarkMode ? 'bg-gray-800/30' : 'bg-gray-50/50'} backdrop-blur-sm`}>
  <button
    onClick={() => setShowNews(!showNews)}
    className={`flex items-center justify-between w-full ${
      isDarkMode ? 'text-white hover:text-blue-400' : 'text-gray-900 hover:text-blue-600'
    } transition-colors duration-200`}
  >
    <div className="flex items-center">
      <div className={`p-2 rounded-lg mr-3 ${
        isDarkMode ? 'bg-purple-500/20 text-purple-400' : 'bg-purple-50 text-purple-500'
      }`}>
        <MessageSquare className="w-4 h-4" />
      </div>
      <h3 className="font-medium">Recent News</h3>
    </div>
    <div className={`transform transition-transform duration-200 ${showNews ? 'rotate-180' : ''}`}>
      <ChevronDown className="w-5 h-5" />
    </div>
  </button>
  
  <AnimatePresence>
    {showNews && (
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: 'auto', opacity: 1 }}
        exit={{ height: 0, opacity: 0 }}
        transition={{ duration: 0.3 }}
        className="overflow-hidden"
      >
        <div className="mt-4 space-y-3">
          {news.length > 0 ? (
            news.map((item, index) => (
              <motion.a
                key={index}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                className={`block p-4 rounded-xl border ${
                  isDarkMode 
                    ? 'bg-gray-800/50 border-gray-700 hover:bg-gray-700/50' 
                    : 'bg-white/80 border-gray-200 hover:bg-blue-50/50'
                } backdrop-blur-sm transition-colors`}
                whileHover={{ scale: 1.01 }}
                transition={{ duration: 0.2 }}
              >
                <div className="flex justify-between items-start">
                  <h4 className={`font-medium mb-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {item.title}
                  </h4>
                  <ExternalLink className={`w-4 h-4 flex-shrink-0 ml-2 ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`} />
                </div>
                {item.description && (
                  <p className={`text-sm mb-2 line-clamp-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    {item.description}
                  </p>
                )}
                <div className="flex items-center space-x-3 text-xs">
                  <div className="flex items-center">
                    {item.favicon ? (
                      <img 
                        src={item.favicon} 
                        alt={item.source} 
                        className="w-4 h-4 mr-1 rounded-full"
                        onError={(e) => {
                          (e.target as HTMLImageElement).style.display = 'none';
                        }} 
                      />
                    ) : null}
                    <span className={isDarkMode ? 'text-blue-400' : 'text-blue-600'}>
                      {item.source}
                    </span>
                  </div>
                  <span className={isDarkMode ? 'text-gray-500' : 'text-gray-400'}>
                    {item.date}
                  </span>
                </div>
              </motion.a>
            ))
          ) : (
            <div className={`text-center p-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              {newsLoading ? (
                <div className="flex flex-col items-center">
                  <LoadingSpinner size="sm" />
                  <p className="mt-2">Loading news...</p>
                </div>
              ) : (
                <p>No recent news found for {ticker}</p>
              )}
            </div>
          )}
        </div>
      </motion.div>
    )}
  </AnimatePresence>
</div>
                      
                      {/* Action Buttons */}
                      <div className={`p-6 ${isDarkMode ? 'bg-gray-900' : 'bg-white'} border-t ${
                        isDarkMode ? 'border-gray-800' : 'border-gray-200'
                      }`}>
                        <div className="flex flex-col sm:flex-row gap-3">
                          {/* Analyze with Prism button */}
                          {onAnalyze && (
                            <motion.button
                              whileHover={{ scale: 1.02 }}
                              whileTap={{ scale: 0.98 }}
                              onClick={handleAnalyzeClick}
                              className="flex-1 flex items-center justify-center px-4 py-2.5 rounded-xl bg-gradient-to-r from-blue-500 to-purple-500 text-white hover:from-blue-600 hover:to-purple-600 transition-all shadow-lg shadow-blue-500/20"
                            >
                              <div className="mr-2 p-1 rounded-md bg-white/20">
                                <TrendingUp className="w-4 h-4" />
                              </div>
                              Analyze with Prism
                            </motion.button>
                          )}
                          
                          {/* View Full Chart button */}
                          <motion.a
                            href={`https://finance.yahoo.com/quote/${ticker}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            className={`flex-1 flex items-center justify-center px-4 py-2.5 rounded-xl border ${
                              isDarkMode 
                                ? 'bg-gray-800 border-gray-700 text-white hover:bg-gray-750' 
                                : 'bg-gray-50 border-gray-200 text-gray-900 hover:bg-gray-100'
                            } transition-colors`}
                          >
                            <div className={`mr-2 p-1 rounded-md ${
                              isDarkMode ? 'bg-gray-700' : 'bg-gray-200/80'
                            }`}>
                              <BarChart2 className={`w-4 h-4 ${
                                isDarkMode ? 'text-blue-400' : 'text-blue-500'
                              }`} />
                            </div>
                            View Full Chart
                          </motion.a>
                        </div>
                      </div>
                    </div>
                  )}
                </motion.div>
              </motion.div>
            </AnimatePresence>
          );
        };