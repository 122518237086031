import { api } from './api';
import { TimeRange, StockData, CompanyInfo, MarketStatus, PopularStock, SearchResult as MarketSearchResult } from '../types/market';
import logger from '../config/logger';

import { StockPrice } from '../types/market';
import { 
  RawStockData,
  RawCompanyData 
} from '../types/market';
import { DataProcessor } from '../utils/dataProcessing';

import { useState, useEffect } from 'react';
export interface CompanyDetails {
  ticker: string;
  name: string;
  description: string;
  sector: string;
  industry: string;
  marketCap: number;
  employees: number;
  homepage: string;
  exchange: string;
  pe_ratio?: number;
  beta?: number;
}

export type SearchResult = MarketSearchResult;

interface PolygonBar {
  t: number;  // timestamp
  o: number;  // open
  h: number;  // high
  l: number;  // low
  c: number;  // close
  v: number;  // volume
}
interface WatchedStock {
  id: string;
  symbol: string;
  order: number;
}

interface WatchedStockWithData extends WatchedStock {
  price: number;
  change: number;
  changePercent: number;
}
interface MarketIndex {
  symbol: string;
  name: string;
  change: number;
  level: number;
}

interface MarketDataService {
  saveWatchedStock(stock: WatchedStock): Promise<void>;
  removeWatchedStock(stockId: string): Promise<void>;
  getWatchedStocks(): Promise<WatchedStockWithData[]>;
  getStockPrice(symbol: string): Promise<{
    price: number;
    change: number;
    changePercent: number;
  }>;
}



class MarketDataService {
  private static instance: MarketDataService;
  private cache: Map<string, { data: any; timestamp: number }> = new Map();
  private readonly CACHE_DURATION = 15 * 60 * 1000; // 15 minutes
  private readonly RATE_LIMIT_WINDOW = 60 * 1000; // 1 minute
  private requestCounts: Map<string, number> = new Map();

  private constructor() {}

  static getInstance(): MarketDataService {
    if (!MarketDataService.instance) {
      MarketDataService.instance = new MarketDataService();
    }
    return MarketDataService.instance;
  }

  private generateCacheKey(endpoint: string, params: any): string {
    return `${endpoint}:${JSON.stringify(params)}`;
  }

  

  private async fetchWithCache<T>(
    endpoint: string,
    params: any,
    fetcher: () => Promise<T>
  ): Promise<T> {
    const cacheKey = this.generateCacheKey(endpoint, params);
    const cached = this.cache.get(cacheKey);

    if (cached && Date.now() - cached.timestamp < this.CACHE_DURATION) {
      return cached.data as T;
    }

    

    try {
      const data = await fetcher();
      this.cache.set(cacheKey, { data, timestamp: Date.now() });
      return data;
    } catch (error) {
      console.log(`Market data fetch failed for ${endpoint}:`, error);
      throw error;
    }
  }

    // Add this method to your MarketDataService class
  async getStockNews(symbol: string, limit: number = 5): Promise<any[]> {
    try {
      console.log('Fetching news for:', symbol);
      
      const { data } = await api.get<any>(`/market/stock/${symbol}/news`, {
        params: { limit }
      });
  
      // Validate the response
      if (!data || !Array.isArray(data)) {
        console.log('Invalid news data structure:', data);
        return [];
      }
  
      return data;
    } catch (error) {
      console.error(`Failed to fetch news for ${symbol}:`, error);
      return [];
    }
  }

  async getMarketIndices(): Promise<MarketIndex[]> {
    try {
      const { data } = await api.get('/market/indices');
      return data;
    } catch (error) {
      console.error('Failed to fetch market indices:', error);
      throw error;
    }
  }

  async searchStocks(query: string): Promise<SearchResult[]> {
    try {
      const { data } = await api.get<SearchResult[]>('/market/search', {
        params: { q: query }
      });
      return data;
    } catch (error) {
      console.log('Stock search failed:', error);
      throw error;
    }
  }

    // Add these methods to the MarketDataService class
  
  async getEnhancedStockSearch(query: string): Promise<SearchResult[]> {
    try {
      const results = await this.searchStocks(query);
      
      // Enhance results with additional data in parallel
      const enhancedResults = await Promise.all(
        results.map(async (result) => {
          try {
            const [priceData, details] = await Promise.all([
              this.getStockData(result.ticker, '1d'),
              this.getStockDetails(result.ticker),
              this.getStockNews(result.ticker, 1)
            ]);
  
            const latestPrice = priceData[priceData.length - 1];
            return {
              ...result,
              price: latestPrice?.c,
              change: latestPrice?.c - latestPrice?.o,
              changePercent: ((latestPrice?.c - latestPrice?.o) / latestPrice?.o) * 100,
              volume: latestPrice?.v,
              sector: details.sector,
              marketCap: details.marketCap,
              pe_ratio: details.pe_ratio,
              beta: details.beta
            };
          } catch (error) {
            return result;
          }
        })
      );
  
      return enhancedResults;
    } catch (error) {
      console.error('Enhanced stock search failed:', error);
      throw error;
    }
  }


  async getPopularStocks() {
    try {
      const response = await api.get('/market/popular');
      return response.data;
    } catch (error) {
      console.log('Failed to fetch popular stocks:', error);
      throw new Error('Failed to fetch popular stocks');
    }
  }

  async getMarketStatus(): Promise<MarketStatus> {
    try {
      const { data } = await api.get<MarketStatus>('/market/status');
      return data;
    } catch (error) {
      console.log('Market status fetch failed:', error);
      throw error;
    }
  }

  private getTimeRangeDays(range: TimeRange): number {
    const ranges: Record<TimeRange, number> = {
      '1d': 1,
      '5d': 5,
      '1mo': 30,
      '3mo': 90,
      '6mo': 180,
      '1y': 365,
      '2y': 730,
      '5y': 1825
    };
    return ranges[range] || 30;
  }


  async getStockChartData(symbol: string, range: TimeRange = '1d'): Promise<StockPrice[]> {
    try {
      const response = await api.get(`/market/stock/${symbol}`, {
        params: { range }
      });

      return response.data.map((item: any) => ({
        ticker: symbol,
        t: new Date(item.t).getTime(),
        o: Number(item.o),
        h: Number(item.h),
        l: Number(item.l),
        c: Number(item.c),
        v: Number(item.v)
      }));
    } catch (error) {
      console.log('Failed to fetch stock data:', error);
      throw new Error('Failed to fetch stock data');
    }
  }

  
// client/src/services/marketDataService.ts

// marketDataService.ts

// Add these methods to your MarketDataService class


async getPublicCompanyDetails(symbol: string): Promise<CompanyDetails> {
  try {
    const { data } = await api.get<CompanyDetails>(`/public/market/company/${symbol}`);
    return data;
  } catch (error) {
    console.log('Public company details fetch failed:', error);
    throw new Error('Failed to fetch company details');
  }
}
async getStockData(symbol: string, timeRange: TimeRange = '1d'): Promise<StockPrice[]> {
  try {
    console.log('Fetching stock data with improved handler:', { symbol, timeRange });
    
    // Pass timeRange as a query parameter
    const { data } = await api.get<any>(`/market/stock/${symbol}`, {
      params: { timeframe: timeRange }
    });

    // Validate response structure
    if (!data || !Array.isArray(data.results)) {
      console.log('Invalid data structure:', data);
      throw new Error('Invalid stock data response');
    }

    // Transform the data into StockPrice format with robust null/undefined handling
    const stockPrices = data.results.map((bar: any) => ({
      t: bar.t,  // timestamp
      o: Number(bar.o ?? 0),  // open
      h: Number(bar.h ?? 0),  // high
      l: Number(bar.l ?? 0),  // low
      c: Number(bar.c ?? 0),  // close
      v: Number(bar.v ?? 0),   // volume
      // Include previous close if available
      prevClose: data.previousClose ? Number(data.previousClose) : undefined
    })).filter((point: StockPrice) => 
      // Filter out any data points with invalid values
      point.t && !isNaN(point.o) && !isNaN(point.h) && 
      !isNaN(point.l) && !isNaN(point.c) && point.c > 0
    );

    // Ensure the data is sorted by timestamp
    return stockPrices.sort((a: StockPrice, b: StockPrice) => a.t - b.t);
  } catch (error) {
    console.log('Stock data fetch failed:', {
      error,
      symbol,
      timeRange
    });
    throw error;
  }
}

async getPublicStockData(symbol: string, timeRange: TimeRange = '1d'): Promise<StockPrice[]> {
  try {
    console.log('Fetching public stock data with improved handler:', { symbol, timeRange });
    
    // Use 'range' as the parameter name to match server expectation
    const { data } = await api.get<any>(`/public/market/stocks/${symbol}`, {
      params: { range: timeRange }
    });

    // Add validation to check the response structure
    if (!data || !Array.isArray(data.results)) {
      console.log('Invalid data structure:', data);
      throw new Error('Invalid stock data response');
    }

    // Transform the data into StockPrice format, with robust null/undefined handling
    const stockPrices = data.results.map((bar: any) => ({
      t: bar.t,                 // timestamp
      o: Number(bar.o ?? 0),    // open
      h: Number(bar.h ?? 0),    // high
      l: Number(bar.l ?? 0),    // low
      c: Number(bar.c ?? 0),    // close
      v: Number(bar.v ?? 0),     // volume
      // Include previous close if available
      prevClose: data.previousClose ? Number(data.previousClose) : undefined
    })).filter((point: StockPrice) => 
      // Filter out invalid data points and ensure they have positive values
      point.t && !isNaN(point.o) && !isNaN(point.h) && 
      !isNaN(point.l) && !isNaN(point.c) && point.c > 0
    );
    
    // Sort by timestamp to ensure correct order
    return stockPrices.sort((a: StockPrice, b: StockPrice) => a.t - b.t);
  } catch (error) {
    console.log('Public stock data fetch failed:', error);
    throw new Error('Failed to fetch stock data');
  }
}

async getTechnicalIndicator(symbol: string, indicator: string, params: any = {}) {
  try {
    console.log('Fetching technical indicator:', {
      symbol,
      indicator,
      params
    });

    const { data } = await api.get(
      `/market/stock/${symbol}/indicators/${indicator}`,
      { params }
    );

    if (!data) {
      throw new Error('Invalid technical indicator response');
    }

    return data;
  } catch (error) {
    console.log('Technical indicator fetch failed:', {
      error,
      symbol,
      indicator
    });
    throw error;
  }
}


async getVolumeProfile(symbol: string, timeRange: TimeRange) {
  const cacheKey = `volume:${symbol}:${timeRange}`;
  const cached = this.getFromCache(cacheKey);
  if (cached) return cached;

  try {
    const { data } = await api.get(`/market/stock/${symbol}/volume-profile`, {
      params: { range: timeRange }
    });
    this.setCache(cacheKey, data);
    return data;
  } catch (error) {
    console.log('Volume profile fetch failed:', error);
    throw error;
  }
}




async getStockDetails(symbol: string): Promise<CompanyDetails> {
  const cacheKey = `details:${symbol}`;
  const cached = this.getFromCache(cacheKey);
  if (cached) return cached;

  try {
    const { data } = await api.get<RawCompanyData>(`/market/stock/${symbol}/details`);
    
    const processedData: CompanyDetails = {
      ticker: data.ticker,
      name: data.name,
      description: data.description,
      homepage: data.homepage || data.website || '',
      exchange: data.exchange || data.primaryExchange || '',
      marketCap: data.marketCap,
      employees: data.employees,
      sector: data.sector,
      industry: data.industry
    };

    this.setCache(cacheKey, processedData);
    return processedData;
  } catch (error) {
    console.log('Stock details fetch failed:', error);
    throw error;
  }
}

  async getSectorPerformance(symbol: string): Promise<any> {
    const cacheKey = `sector:${symbol}`;
    const cached = this.getFromCache(cacheKey);
    if (cached) return cached;

    try {
      const { data } = await api.get(`/market/sector-performance`, {
        params: { symbol }
      });
      this.setCache(cacheKey, data);
      return data;
    } catch (error) {
      console.log('Sector performance fetch failed:', error);
      throw error;
    }
  }

  
  

  // client/src/services/marketDataService.ts

  async getTechnicalIndicators(
    symbol: string,
    indicator: string,
    params: {
      period?: number;
      timeRange?: string;
    } = {}
  ): Promise<number[]> {
    try {
      const { data } = await api.get(`/market/stock/${symbol}/indicators/${indicator}`, {
        params: {
          period: params.period || 14
        }
      });
  
      // Convert the array of {timestamp, value} objects to just an array of values,
      // ordered by timestamp descending to match stock data
      if (Array.isArray(data)) {
        return data
          .sort((a, b) => a.timestamp - b.timestamp)
          .map(item => item.value);
      }
  
      throw new Error('Invalid indicator data format');
    } catch (error) {
      console.log('Technical indicator fetch failed:', error);
      throw new Error('Failed to fetch technical indicators');
    }
  }

  private getFromCache(key: string) {
    const cached = this.cache.get(key);
    if (cached && Date.now() - cached.timestamp < this.CACHE_DURATION) {
      return cached.data;
    }
    return null;
  }

  private setCache(key: string, data: any) {
    this.cache.set(key, {
      data,
      timestamp: Date.now()
    });
  }


  clearCache(): void {
    this.cache.clear();
  }
}

export const marketDataService = MarketDataService.getInstance();