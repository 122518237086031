import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../../contexts/ThemeContext';

const StreamingIndicator: React.FC = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className={`inline-flex items-center space-x-1 px-3 py-1.5 rounded-md ${
      isDarkMode ? 'bg-blue-900/20' : 'bg-blue-50'
    } mt-3`}>
      <motion.div
        className={`w-2 h-2 rounded-full ${isDarkMode ? 'bg-blue-400' : 'bg-blue-500'}`}
        animate={{ scale: [0.5, 1, 0.5] }}
        transition={{ duration: 1, repeat: Infinity, ease: "easeInOut" }}
      />
      <motion.div
        className={`w-2 h-2 rounded-full ${isDarkMode ? 'bg-blue-400' : 'bg-blue-500'}`}
        animate={{ scale: [0.5, 1, 0.5] }}
        transition={{ duration: 1, repeat: Infinity, ease: "easeInOut", delay: 0.2 }}
      />
      <motion.div
        className={`w-2 h-2 rounded-full ${isDarkMode ? 'bg-blue-400' : 'bg-blue-500'}`}
        animate={{ scale: [0.5, 1, 0.5] }}
        transition={{ duration: 1, repeat: Infinity, ease: "easeInOut", delay: 0.4 }}
      />
      <span className={`text-xs font-medium ml-1 ${isDarkMode ? 'text-blue-300' : 'text-blue-600'}`}>
        Prism is writing...
      </span>
    </div>
  );
};

export default StreamingIndicator;