import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';
import { SEO } from '../components/common/SEO';
import { usePrompt } from '../contexts/PromptContext';
import { StockViewProvider, useStockView } from '../contexts/StockViewContext';
import { cachedPromptService } from '../services/cachedPromptService';
import { useAuth } from '../contexts/AuthContext';
import { api } from '../services/api';
import { ResearchModeModal } from '../components/market/ResearchModeModal';
import { WatchlistSelectModal } from '../components/market/WatchlistSelectModal';
import {
  Brain,
  ArrowRight,
  ChevronRight,
  ChevronDown,
  ChevronUp,
  Database,
  GitBranch,
  Sparkles,
  Activity,
  LineChart,
  MessageSquare,
  BarChart2,
  MousePointer2,
  Hexagon,
  Box,
  Target,
  ChevronLeft,
  User,
  Plus
} from 'lucide-react';
import ClaudeBadge from './ClaudeBadge';
import { debounce } from 'lodash';
import StockSparkline from './dashboard/StockSparkline';
import { useLanguage } from '../contexts/LanguageContext';

const Spline = lazy(() => import('@splinetool/react-spline'));
const FloatingElements = lazy(() => import('./FloatingElements'));

const PromptChip: React.FC<{
  text: string;
  prefix: string;
  onRemove: () => void;
  isDarkMode: boolean;
}> = ({ text, prefix, onRemove, isDarkMode }) => {
  return (
    <div className={`inline-flex items-center gap-1 py-1 px-2 rounded-md text-sm ${
      isDarkMode ? 'bg-blue-900/40 text-blue-300 border border-blue-700/50' : 
                  'bg-blue-100 text-blue-800 border border-blue-200'
    }`}>
      <Box size={14} className={`mr-1 ${isDarkMode ? 'text-blue-300' : 'text-blue-600'}`} />
      
      {prefix && (
        <span className={`text-xs px-1.5 py-0.5 rounded ${
          isDarkMode ? 'bg-blue-800/70 text-blue-300' : 'bg-blue-200 text-blue-700'
        }`}>
          {prefix.replace(/[\[\]]/g, '')}
        </span>
      )}
      <span className="truncate max-w-[300px]">{text}</span>
      <button 
        onClick={(e) => {
          e.stopPropagation();
          onRemove();
        }}
        className={`ml-1 rounded-full p-0.5 ${
          isDarkMode ? 'hover:bg-blue-700/60' : 'hover:bg-blue-200'
        }`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" 
             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  );
};

const StockChip: React.FC<{
  ticker: string;
  companyName?: string;
  prefix: string;
  onRemove: () => void;
  isDarkMode: boolean;
}> = ({ ticker, companyName, prefix, onRemove, isDarkMode }) => {
  const [imageError, setImageError] = useState(false);
  
  return (
    <div className={`inline-flex items-center gap-1 py-1 px-2 rounded-md text-sm ${
      isDarkMode ? 'bg-green-900/40 text-green-300 border border-green-700/50' : 
                  'bg-green-100 text-green-800 border border-green-200'
    }`}>
      <div className={`overflow-hidden w-5 h-5 rounded-full flex items-center justify-center 
        ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} 
        border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} mr-1`}
      >
        {!imageError ? (
          <img
            src={`/api/market/company-logo/${ticker}`}
            alt={`${ticker} logo`}
            className="w-full h-full object-cover"
            onError={() => setImageError(true)}
          />
        ) : (
          <span className={`text-xs font-semibold ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}>
            {ticker[0]}
          </span>
        )}
      </div>
      
      {prefix && (
        <span className={`text-xs px-1.5 py-0.5 rounded ${
          isDarkMode ? 'bg-green-800/70 text-green-300' : 'bg-green-200 text-green-700'
        }`}>
          {prefix.replace(/[\[\]]/g, '')}
        </span>
      )}
      
      <span className="font-mono font-medium">{ticker}</span>
      {companyName && <span className="text-xs truncate max-w-[100px] opacity-70">{companyName}</span>}
      
      <button 
        onClick={(e) => {
          e.stopPropagation();
          onRemove();
        }}
        className={`ml-1 rounded-full p-0.5 ${
          isDarkMode ? 'hover:bg-green-700/60' : 'hover:bg-green-200'
        }`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" 
             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  );
};

const PrismExampleGallery: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  
  const examples = [
    {
      mode: {
        name: "Standard",
        icon: Brain,
        color: "text-violet-400",
        bgColor: "bg-violet-500/20"
      },
      prompt: "What's happening with NVDA stock and why is it down so much today?",
      response: "NVIDIA Stock Performance: NVIDIA's (NASDAQ: NVDA) stock price has declined by around 20% year-to-date as of March 12, 2025.\n\n• Broader Market Conditions: NVIDIA's decline is part of a broader Nasdaq correction with concerns over rising interest rates and recession fears\n\n• Competition in AI Chip Market: Increasing competition from rivals like AMD, Broadcom, and startups like Soundhound AI\n\n• Potential Demand Slowdown: Some customers potentially delaying investments amid economic uncertainty\n\n• Long-Term Opportunities: Despite near-term headwinds, company remains well-positioned in AI, data centers, and quantum computing",
      date: "March 12, 2025",
      svgChart: (
        <svg className="w-full h-32 mt-4" viewBox="0 0 400 100">
          <path d="M0,30 L50,35 L100,38 L150,32 L200,45 L250,60 L300,75 L350,85 L400,70" fill="none" stroke="#8b5cf6" strokeWidth="3"/>
          <path d="M0,50 L50,48 L100,52 L150,54 L200,56 L250,53 L300,58 L350,52 L400,55" fill="none" stroke="#94a3b8" strokeWidth="2" strokeDasharray="4"/>
          <line x1="250" y1="0" x2="250" y2="100" stroke="#475569" strokeWidth="1" strokeDasharray="3"/>
          <circle cx="250" cy="60" r="4" fill="#ef4444" />
          <text x="260" y="20" fill="#ef4444" fontSize="10">Current drop</text>
          <text x="380" y="70" fill="#8b5cf6" fontSize="10">NVDA</text>
          <text x="380" y="55" fill="#94a3b8" fontSize="10">NASDAQ</text>
        </svg>
      )
    },
    {
      mode: {
        name: "Deep Dive",
        icon: Database,
        color: "text-blue-400",
        bgColor: "bg-blue-500/20"
      },
      prompt: "Analyze Apple's latest earnings report. What are the key points investors should focus on?",
      response: "• iPhone and Services revenue growth: iPhone sales up 6% YoY with iPhone 16 performing better than previous model; Services revenue reached new record\n\n• New cash dividend and transparency: $0.25 per share dividend declared; live streaming of earnings call shows commitment to shareholder communication\n\n• New product introductions: iPhone 16 and other products showcase ongoing innovation pipeline\n\n• One-time tax charge: $10.2B tax charge in Europe impacted net income but not overall strong financial performance\n\n• Record installed base: New record of active devices demonstrates strength of ecosystem and customer loyalty",
      date: "March 10, 2025",
      svgChart: (
        <svg className="w-full h-32 mt-4" viewBox="0 0 400 100">
          <rect x="10" y="50" width="50" height="30" fill="#3b82f6" fillOpacity="0.7" />
          <rect x="65" y="30" width="50" height="50" fill="#3b82f6" fillOpacity="0.8" />
          <rect x="120" y="55" width="50" height="25" fill="#3b82f6" fillOpacity="0.7" />
          <rect x="175" y="20" width="50" height="60" fill="#10b981" fillOpacity="0.8" />
          <rect x="230" y="25" width="50" height="55" fill="#10b981" fillOpacity="0.7" />
          <rect x="285" y="15" width="50" height="65" fill="#10b981" fillOpacity="0.9" />
          <rect x="340" y="10" width="50" height="70" fill="#10b981" fillOpacity="1" />
          <line x1="0" y1="80" x2="400" y2="80" stroke="#64748b" strokeWidth="1" />
          <text x="35" y="95" fill="#94a3b8" fontSize="8" textAnchor="middle">Q1 '24</text>
          <text x="90" y="95" fill="#94a3b8" fontSize="8" textAnchor="middle">Q2 '24</text>
          <text x="145" y="95" fill="#94a3b8" fontSize="8" textAnchor="middle">Q3 '24</text>
          <text x="200" y="95" fill="#94a3b8" fontSize="8" textAnchor="middle">Q4 '24</text>
          <text x="255" y="95" fill="#94a3b8" fontSize="8" textAnchor="middle">Q1 '25</text>
          <text x="310" y="95" fill="#94a3b8" fontSize="8" textAnchor="middle">Q2 '25</text>
          <text x="365" y="95" fill="#94a3b8" fontSize="8" textAnchor="middle">Q3 '25</text>
          <text x="200" y="15" fill="#3b82f6" fontSize="10" textAnchor="middle">AAPL Revenue Growth by Quarter</text>
        </svg>
      )
    },
    {
      mode: {
        name: "Technical",
        icon: LineChart,
        color: "text-green-400",
        bgColor: "bg-green-500/20"
      },
      prompt: "Identify key technical patterns in the S&P 500 over the last month. Is there a breakdown forming?",
      response: "• Support level breach: S&P 500 has broken below key support level, potentially signaling directional change\n\n• Bearish moving averages: All key MAs (5, 10, 20, 50, 100, 200-day) showing sell signals over past month\n\n• Oversold oscillators: RSI fallen below 30 into oversold territory; MACD crossed below signal line\n\n• Weak performance metrics: Index down 7.70% since last month and 5.93% in past week\n\n• Seasonal weakness: Historical performance suggests potential for continued weakness in current period",
      date: "March 8, 2025",
      svgChart: (
        <svg className="w-full h-32 mt-4" viewBox="0 0 400 100">
          <path d="M0,40 L50,42 L100,35 L150,38 L200,30 L250,32 L300,45 L350,60 L400,70" fill="none" stroke="#22c55e" strokeWidth="2"/>
          <path d="M0,40 L400,40" stroke="#475569" strokeWidth="1" strokeDasharray="3"/>
          <path d="M200,0 L200,100" stroke="#ef4444" strokeWidth="1" strokeDasharray="2"/>
          <path d="M200,30 L400,30" stroke="#ef4444" strokeWidth="1.5" strokeDasharray="5"/>
          <path d="M200,30 L400,70" fill="none" stroke="#ef4444" strokeWidth="2"/>
          <circle cx="200" cy="30" r="4" fill="#ef4444" />
          <text x="170" y="20" fill="#ef4444" fontSize="10" textAnchor="end">Support breach</text>
          <text x="380" y="25" fill="#ef4444" fontSize="10">Resistance</text>
          <text x="380" y="85" fill="#ef4444" fontSize="10">Breakdown</text>
          <text x="200" y="95" fill="#475569" fontSize="8">One month ago</text>
        </svg>
      )
    },
    {
      mode: {
        name: "Contrarian",
        icon: Target,
        color: "text-red-400",
        bgColor: "bg-red-500/20"
      },
      prompt: "What contrarian view should investors consider about the current enthusiasm for AI stocks?",
      response: "• Potential speculative bubble: Goldman Sachs notes high market concentration in AI creates risk where \"collateral damage of stock-specific mistakes is likely to be particularly high\"\n\n• Extreme valuations: Nvidia trades at 74x free cash flow and 36x sales; C3.ai surged 157% but still 85% below all-time high\n\n• Financial sustainability concerns: High R&D burn rates leading to negative cash flows and unsustainable spending patterns\n\n• Market concentration risks: Scale of investment needed concentrates opportunities among few dominant players (Nvidia, Microsoft, Alphabet)\n\n• Diversification recommended: Spread investments across AI sector rather than concentrating in few high-flyers",
      date: "March 5, 2025",
      svgChart: (
        <svg className="w-full h-32 mt-4" viewBox="0 0 400 100">
          <rect x="20" y="10" width="50" height="70" fill="#ef4444" fillOpacity="0.1" />
          <rect x="75" y="10" width="50" height="70" fill="#ef4444" fillOpacity="0.15" />
          <rect x="130" y="10" width="50" height="70" fill="#ef4444" fillOpacity="0.2" />
          <rect x="185" y="10" width="50" height="70" fill="#ef4444" fillOpacity="0.25" />
          <rect x="240" y="10" width="50" height="70" fill="#ef4444" fillOpacity="0.3" />
          <rect x="295" y="10" width="50" height="70" fill="#ef4444" fillOpacity="0.35" />
          <line x1="20" y1="85" x2="345" y2="85" stroke="#64748b" strokeWidth="1" />
          <path d="M20,65 L70,45 L125,25 L180,15 L235,20 L290,28 L345,40" fill="none" stroke="#ef4444" strokeWidth="2.5"/>
          <path d="M20,75 L70,70 L125,60 L180,65 L235,55 L290,60 L345,50" fill="none" stroke="#94a3b8" strokeWidth="1.5" strokeDasharray="4"/>
          <text x="45" y="95" fill="#94a3b8" fontSize="8" textAnchor="middle">P/E</text>
          <text x="100" y="95" fill="#94a3b8" fontSize="8" textAnchor="middle">P/S</text>
          <text x="155" y="95" fill="#94a3b8" fontSize="8" textAnchor="middle">EV/EBITDA</text>
          <text x="215" y="95" fill="#94a3b8" fontSize="8" textAnchor="middle">AI Stocks</text>
          <text x="320" y="95" fill="#94a3b8" fontSize="8" textAnchor="middle">Historical Average</text>
          <text x="190" y="10" fill="#ef4444" fontSize="10">Valuation Metrics Comparison</text>
        </svg>
      )
    },
    {
      mode: {
        name: "Discovery",
        icon: Sparkles,
        color: "text-amber-400",
        bgColor: "bg-amber-500/20"
      },
      prompt: "Find emerging opportunities in small-cap biotech with promising cancer treatment pipelines",
      response: "• Immunocore (NASDAQ: IMCR): Novel T-cell receptor platform showing promising results in melanoma trials; market cap $3.2B with 42% institutional ownership\n\n• Sutro Biopharma (NASDAQ: STRO): Antibody-drug conjugate platform with multiple Phase 1/2 oncology trials; collaborations with Merck and Bristol Myers Squibb\n\n• Bicycle Therapeutics (NASDAQ: BCYC): Proprietary Bicycle® peptide platform targeting solid tumors; recent positive Phase 1 data for BT8009 in advanced cancers\n\n• Nuvalent (NASDAQ: NUVL): Precision medicine approach for resistant cancers; lead candidate NVL-520 showing promising early results in ROS1-positive lung cancer",
      date: "February 28, 2025",
      svgChart: (
        <svg className="w-full h-32 mt-4" viewBox="0 0 400 100">
          <circle cx="70" cy="30" r="15" fill="#fbbf24" fillOpacity="0.2" stroke="#fbbf24" strokeWidth="1" />
          <circle cx="170" cy="40" r="20" fill="#fbbf24" fillOpacity="0.3" stroke="#fbbf24" strokeWidth="1" />
          <circle cx="270" cy="25" r="25" fill="#fbbf24" fillOpacity="0.4" stroke="#fbbf24" strokeWidth="1" />
          <circle cx="350" cy="45" r="18" fill="#fbbf24" fillOpacity="0.3" stroke="#fbbf24" strokeWidth="1" />
          <line x1="0" y1="80" x2="400" y2="80" stroke="#64748b" strokeWidth="1" />
          <path d="M70,30 L170,40 L270,25 L350,45" fill="none" stroke="#fbbf24" strokeWidth="2" strokeDasharray="5"/>
          <text x="70" y="85" fill="#94a3b8" fontSize="8" textAnchor="middle">IMCR</text>
          <text x="170" y="85" fill="#94a3b8" fontSize="8" textAnchor="middle">STRO</text>
          <text x="270" y="85" fill="#94a3b8" fontSize="8" textAnchor="middle">BCYC</text>
          <text x="350" y="85" fill="#94a3b8" fontSize="8" textAnchor="middle">NUVL</text>
          <text x="20" y="20" fill="#fbbf24" fontSize="8">Market Cap</text>
          <text x="20" y="30" fill="#fbbf24" fontSize="8">vs Trial Progress</text>
        </svg>
      )
    }
  ];

  const nextExample = () => {
    setCurrentIndex((prev) => (prev + 1) % examples.length);
  };

  const prevExample = () => {
    setCurrentIndex((prev) => (prev - 1 + examples.length) % examples.length);
  };

  const currentExample = examples[currentIndex];

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-3">
          <div className={`p-2 rounded-lg ${currentExample.mode.bgColor}`}>
            <currentExample.mode.icon className={`w-5 h-5 ${currentExample.mode.color}`} />
          </div>
          <span className={`${currentExample.mode.color} font-heading tracking-tighter font-medium`}>
            Prism — {currentExample.mode.name} Mode
          </span>
        </div>
        <div className="flex items-center space-x-1 text-gray-400 text-sm">
          {examples.map((_, idx) => (
            <div 
              key={idx} 
              className={`w-2 h-2 rounded-full ${idx === currentIndex ? 'bg-violet-400' : 'bg-gray-700'}`}
            />
          ))}
        </div>
      </div>

      <div className="bg-gray-800/40 rounded-xl p-4 mb-4">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center">
            <User className="w-4 h-4 text-gray-400 mr-2" />
            <span className="text-sm text-gray-400">You asked:</span>
          </div>
          <span className="text-xs text-gray-500">{currentExample.date}</span>
        </div>
        <p className="text-lg text-white font-medium">{currentExample.prompt}</p>
      </div>

      <div className="flex-1 bg-gray-800/40 rounded-xl p-4 overflow-auto">
        <div className="flex items-center mb-3">
          <Sparkles className="w-4 h-4 text-violet-400 mr-2" />
          <span className="text-sm text-gray-400">Prism responded with real-time market data:</span>
        </div>
        <div className="prose prose-invert prose-sm max-w-none">
          <div className="whitespace-pre-line text-gray-200">{currentExample.response}</div>
        </div>
      </div>

      <div className="flex justify-between mt-6">
        <button 
          onClick={prevExample}
          className="p-2 rounded-lg bg-gray-800/60 hover:bg-gray-700/60 text-gray-300"
        >
          <ChevronLeft className="w-5 h-5" />
        </button>
        
        <button
          onClick={() => navigate('/register')}
          className="px-4 py-1 rounded-lg bg-violet-500/20 hover:bg-violet-500/30 text-violet-300 flex items-center space-x-2"
        >
          <span>Try it yourself</span>
          <ArrowRight className="w-4 h-4" />
        </button>
        
        <button 
          onClick={nextExample}
          className="p-2 rounded-lg bg-gray-800/60 hover:bg-gray-700/60 text-gray-300"
        >
          <ChevronRight className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

const researchModes = [
  {
    id: 'default',
    name: 'Standard',
    icon: Brain,
    description: 'Balanced research approach for general market inquiries',
    prefix: ''
  },
  {
   id: 'contrarian',
   name: 'Contrarian',
   icon: Target,
   description: 'Challenge market consensus and explore alternative views',
   prefix: '[CONTRARIAN ANALYSIS] '
 },
  {
    id: 'discovery',
    name: 'Discovery',
    icon: Sparkles,
    description: 'Explore new market opportunities and emerging trends',
    prefix: '[DISCOVERY MODE] '
  },
  {
    id: 'deep-dive',
    name: 'Deep Dive',
    icon: Database,
    description: 'Thorough analysis with comprehensive data examination',
    prefix: '[DEEP DIVE] '
  },
  
  {
    id: 'technical',
    name: 'Technical',
    icon: LineChart,
    description: 'Focus on technical indicators and chart patterns',
    prefix: '[TECHNICAL ANALYSIS] '
  }
];

interface PromptItem {
 prompt: string;
 isHeadline?: boolean;
 imageUrl?: string | null;
 imageQuery?: string | null;
 ticker?: string | null;
 tickerChangePercent?: number;
 tickerPriceLoaded?: boolean;
}

interface TrendingStockItem {
  ticker: string;
  name: string;
  reason: string;
  changePercent?: number;
  price?: number;
  priceLoaded?: boolean;
}

const features = [
  'Data for the entire universe of US stocks',
  'Predictive intelligence',
  'Real-time strategy formation',
  'Adaptive learning system'
];

interface CustomCursorProps {
  position: { x: number; y: number };
  activeSection: number;
}

const SplineFallback = () => (
  <div className="w-full h-full flex items-center justify-center bg-gray-900/50">
    <div className="animate-pulse text-gray-400">Loading 3D Scene...</div>
  </div>
);

interface OptimizedSplineProps {
  scene: string;
  className?: string;
  onLoad?: () => void;
  isMobile?: boolean;
}

const OptimizedSpline = ({ scene, className, onLoad, isMobile }: OptimizedSplineProps) => {
  const splineOptions = {
    rendererSettings: isMobile ? {
      powerPreference: "low-power",
      antialias: false,
      alpha: false,
      precision: "lowp",
    } : undefined
  };

  return (
    <Suspense fallback={<SplineFallback />}>
      <Spline 
        scene={scene} 
        className={className} 
        onLoad={onLoad}
        {...splineOptions}
      />
    </Suspense>
  );
};

const Background = ({ isLoaded, isMobile }: { isLoaded: boolean; isMobile: boolean }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="fixed inset-0 pointer-events-none overflow-hidden">
      <div className="absolute inset-0 w-full h-full overflow-hidden pointer-events-none">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 via-purple-500/5 to-transparent opacity-50" />
        
        <div className="absolute z-10 inset-0 bg-gradient-to-b from-transparent via-black/20 to-black/40" />
        <div className="absolute inset-0">
          <div className="absolute z-15 inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent blur-3xl" />
        </div>
        {isLoaded && !isMobile && (
          <Suspense fallback={null}>
            <FloatingElements />
          </Suspense>
        )}
      </div>
    </div>
  );
};

const NewsTicker: React.FC<{
  items: string[];
  isDarkMode: boolean;
  onItemClick: (item: string) => void;
  isLoading?: boolean;
}> = ({ items, isDarkMode, onItemClick, isLoading = false }) => {
  if (isLoading) {
    return (
      <div className={`w-full font-heading py-1.5 px-4 border-b overflow-hidden ${
        isDarkMode ? 'bg-gray-900 border-gray-800' : 'bg-gray-100 border-gray-200'
      } sticky top-0 z-40`}>
        <div className="flex items-center w-full overflow-hidden">
          <div className="ticker-container relative flex-1 overflow-hidden">
            <div className="flex items-center space-x-2">
              <div className="h-2 w-24 bg-gray-700 rounded-full animate-pulse"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!items?.length) {
    return (
      <div className={`w-full font-heading py-1.5 px-4 border-b overflow-hidden ${
        isDarkMode ? 'bg-gray-900 border-gray-800' : 'bg-gray-100 border-gray-200'
      } sticky top-0 z-40`}>
        <div className="flex items-center justify-center w-full">
          <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            No market data available
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={`w-full font-sans py-1.5 px-4 border-b overflow-hidden ${
      isDarkMode ? 'bg-gray-900/90 border-gray-800' : 'bg-gray-100 border-gray-200'
    } sticky top-0 z-40 backdrop-blur`}>
      <div className="flex items-center w-full overflow-hidden">
        <div className="ticker-container relative flex-1 overflow-hidden">
          <div className="ticker-track whitespace-nowrap">
            {items.map((item, i) => (
              <React.Fragment key={`ticker-item-${i}`}>
                <button
                  onClick={() => onItemClick(item)}
                  className={`inline-block px-3 py-0.5 rounded-md mx-1 text-sm font-medium transition-colors ${
                    isDarkMode 
                      ? 'hover:bg-gray-800 text-gray-200' 
                      : 'hover:bg-gray-200 text-gray-800'
                  }`}
                >
                  {item}
                </button>
                <span className={`inline-block mx-1 ${
                  isDarkMode ? 'text-gray-600' : 'text-gray-400'
                }`}>•</span>
              </React.Fragment>
            ))}
            <div className="ticker-content mt-1 inline-block">
              {items.map((item, i) => (
                <React.Fragment key={`ticker-item-duplicate-${i}`}>
                  <button
                    onClick={() => onItemClick(item)}
                    className={`inline-block px-3 py-0.5 rounded-md mx-1 text-sm font-medium transition-colors ${
                      isDarkMode 
                        ? 'hover:bg-gray-800 text-gray-200' 
                        : 'hover:bg-gray-200 text-gray-800'
                    }`}
                  >
                    {item}
                  </button>
                  <span className={`inline-block mx-1 ${
                    isDarkMode ? 'text-gray-600' : 'text-gray-400'
                  }`}>•</span>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const fetchPixabayImage = async (prompt: string, imageQuery?: string | null): Promise<string | null> => {
  const PIXABAY_API_KEY = '48933707-aca473cc491a4a26628d80a0a';
  
  if (!prompt) return null;
  
  try {
    const searchTerms = imageQuery || prompt.split(' ').filter(word => word.length > 4)[0] || 'finance';
    
    const response = await fetch(
      `https://pixabay.com/api/?key=${PIXABAY_API_KEY}&q=${encodeURIComponent(searchTerms)}&per_page=6&image_type=photo&orientation=horizontal&category=business`
    );
    
    const data = await response.json();
    const hits = data.hits || [];
    
    if (hits.length > 0) {
      const randomIndex = Math.floor(Math.random() * Math.min(hits.length, 6));
      return hits[randomIndex]?.webformatURL || null;
    }
    
    return null;
  } catch (error) {
    console.error('Image fetch error:', error);
    return null;
  }
};

export const Home = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();
  const [isResearchModalOpen, setIsResearchModalOpen] = useState(false); // Add this new state
  const { t } = useLanguage();
  const [activeSection, setActiveSection] = useState(0);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const heroRef = useRef(null);
  const contentRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    selectedPromptChip, setSelectedPromptChip,
    selectedStockChip, setSelectedStockChip,
    searchQuery, setSearchQuery
  } = usePrompt();
  const [selectedResearchMode, setSelectedResearchMode] = useState(researchModes[0]);
  const [showResearchModes, setShowResearchModes] = useState(false);
  const { openStockModal } = useStockView();
  
  const [tickerItems, setTickerItems] = useState<string[]>([]);
  const [tickerItemsLoading, setTickerItemsLoading] = useState(true);
  const [promptItems, setPromptItems] = useState<PromptItem[]>([]);
  const [isLoadingPrompts, setIsLoadingPrompts] = useState(true);
  const [trendingStocks, setTrendingStocks] = useState<TrendingStockItem[]>([
    { ticker: 'AAPL', name: 'Apple Inc.', reason: 'New iPhone announcement driving momentum' },
    { ticker: 'NVDA', name: 'NVIDIA Corporation', reason: 'AI chip demand continues to surge' },
    { ticker: 'TSLA', name: 'Tesla, Inc.', reason: 'Production numbers exceed expectations' }
  ]);
  const [containerSize, setContainerSize] = useState({ width: 1000, height: 800 });
  const [isWatchlistModalOpen, setIsWatchlistModalOpen] = useState(false);

  const loadingMessages = [
    "Initializing connections...",
    "Gathering market insights...",
    "AI Working...",
    "Analyzing global trends...",
    "Synthesizing market data..."
  ];
  const [loadingText, setLoadingText] = useState(loadingMessages[0]);

  // Add state for portal container
  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const updateContainerSize = () => {
      setContainerSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      updateContainerSize();
    };

    const debouncedResize = debounce(handleResize, 250);
    window.addEventListener('resize', debouncedResize);
    
    updateContainerSize();

    return () => {
      window.removeEventListener('resize', debouncedResize);
      debouncedResize.cancel();
    };
  }, []);

  const fetchTickerData = async (ticker: string): Promise<{ price: number, changePercent: number } | null> => {
    if (!ticker) return null;

    try {
      const today = new Date();
      const startDate = new Date();
      startDate.setDate(today.getDate() - 5);
      
      const fromDate = startDate.toISOString().split('T')[0];
      const toDate = today.toISOString().split('T')[0];
      
      const { data } = await api.get(`/market/stock/${ticker}/aggregates`, {
        params: {
          multiplier: 1,
          timespan: 'day',
          from: fromDate,
          to: toDate
        }
      });
      
      if (data && data.results && data.results.length > 0) {
        const latestBar = data.results[data.results.length - 1];
        
        let prevClose = latestBar.o;
        
        if (data.results.length > 1) {
          prevClose = data.results[data.results.length - 2].c;
        }
        
        const changePercent = ((latestBar.c - prevClose) / prevClose) * 100;
        return { price: latestBar.c, changePercent };
      }
      return null;
    } catch (error) {
      console.error(`Failed to fetch data for ${ticker}:`, error);
      return null;
    }
  };

  useEffect(() => {
    const fetchMarketData = async () => {
      setTickerItemsLoading(true);
      setIsLoadingPrompts(true);
      
      try {
        const [promptsData, stocksData] = await Promise.all([
          cachedPromptService.getCachedPrompts(),
          cachedPromptService.getCachedTrendingStocks()
        ]);
        
        let items: string[] = [];
        if (Array.isArray(promptsData)) {
          if (typeof promptsData[0] === 'string') {
            items = promptsData;
          } else if (typeof promptsData[0] === 'object' && 'text' in promptsData[0]) {
            items = promptsData.map(item => item.text);
          }
        }
        setTickerItems(items);
        setTickerItemsLoading(false);
        
        interface NormalizedPrompt {
          text: string;
          isHeadline: boolean;
          imageQuery?: string | null;
          ticker?: string | null;
        }
        
        let normalizedPrompts: NormalizedPrompt[] = [];
        if (Array.isArray(promptsData)) {
          if (typeof promptsData[0] === 'string') {
            normalizedPrompts = promptsData.map((text, index) => ({ 
              text, 
              isHeadline: index < 6
            }));
          } else {
            normalizedPrompts = promptsData.map(item => {
              if (typeof item === 'string') {
                return { text: item, isHeadline: false };
              } else {
                return { 
                  text: item.text || '', 
                  isHeadline: !!item.isHeadline,
                  imageQuery: item.imageQuery || null,
                  ticker: item.ticker || null
                };
              }
            });
          }
        }

        if (Array.isArray(stocksData) && stocksData.length > 0) {
          setTrendingStocks(stocksData);
          
          const updatedStocks = await Promise.all(
            stocksData.map(async (stock) => {
              try {
                const tickerData = await fetchTickerData(stock.ticker);
                
                if (tickerData) {
                  return {
                    ...stock,
                    price: tickerData.price,
                    changePercent: tickerData.changePercent,
                    priceLoaded: true
                  };
                }
                return stock;
              } catch (error) {
                console.error(`Failed to fetch data for ${stock.ticker}:`, error);
                return stock;
              }
            })
          );
          
          setTrendingStocks(updatedStocks);
        }
        
        const processedPrompts = await Promise.all(
          normalizedPrompts.map(async (item) => {
            const [imageUrl, tickerData] = await Promise.all([
              fetchPixabayImage(item.text, item.imageQuery),
              item.ticker ? fetchTickerData(item.ticker) : Promise.resolve(null)
            ]);
            
            return {
              prompt: item.text,
              isHeadline: item.isHeadline,
              imageUrl,
              imageQuery: item.imageQuery,
              ticker: item.ticker,
              tickerChangePercent: tickerData?.changePercent,
              tickerPriceLoaded: !!tickerData
            };
          })
        );
        
        setPromptItems(processedPrompts);
        setIsLoadingPrompts(false);
        
      } catch (error) {
        console.error('Failed to fetch market data:', error);
        setTickerItemsLoading(false);
        setIsLoadingPrompts(false);
        
        const fallbackPrompts = [
          "Analyzing tech sector performance",
          "What factors are driving market sentiment?",
          "Show biggest market movers today",
          "Explain recent volatility trends",
          "Interest rate impact on financials",
          "Emerging opportunities in healthcare",
          "AI's influence on market trends",
          "Crypto market correlation with tech",
          "Commodity price effects on inflation"
        ];
        
        setTickerItems(fallbackPrompts);
        
        setPromptItems(fallbackPrompts.map((text, index) => ({
          prompt: text,
          isHeadline: index < 3,
          imageUrl: null,
          ticker: index % 3 === 0 ? ['AAPL', 'MSFT', 'AMZN', 'GOOGL', 'TSLA'][index % 5] : undefined,
          tickerChangePercent: Math.random() * 6 - 3,
          tickerPriceLoaded: true
        })));
      }
    };
    
    let interval: NodeJS.Timeout | null = null;
    if (isLoadingPrompts) {
      interval = setInterval(() => {
        setLoadingText(current => {
          const currentIndex = loadingMessages.indexOf(current);
          return loadingMessages[(currentIndex + 1) % loadingMessages.length];
        });
      }, 2000);
    }
  
    fetchMarketData();
    
    return () => {
      if (interval) {
        clearTimeout(interval);
      }
    };
  }, []);

  useEffect(() => {
    const tickerElements = document.querySelectorAll('.ticker-track');
    tickerElements.forEach(element => {
      (element as HTMLElement).style.animation = `ticker 60s linear infinite`;
    });
  }, [tickerItems]);

  const handleTickerItemClick = (item: string) => {
    navigate('/register', { 
      state: { initialPrompt: item } 
    });
  };
  
  const handlePromptSubmit = () => {
    const targetPath = isAuthenticated ? '/dashboard/market-chat' : '/register';
    
    let promptText = '';
    let displayAsChip = false;
    
    if (selectedPromptChip) {
      promptText = selectedPromptChip.prefix + selectedPromptChip.text + (searchQuery ? ` ${searchQuery}` : '');
      displayAsChip = true;
    } else if (selectedStockChip) {
      promptText = selectedStockChip.prefix + 
        `Tell me about ${selectedStockChip.ticker} stock` + 
        (searchQuery.trim() ? `: ${searchQuery.trim()}` : '');
      displayAsChip = true;
    } else if (searchQuery.trim()) {
      promptText = selectedResearchMode.prefix + searchQuery;
    } else {
      return;
    }
    
    navigate(targetPath, { 
      state: { 
        initialPrompt: promptText,
        displayAsChip: displayAsChip
      } 
    });
  };

  const headlinePrompts = promptItems.filter(item => item.isHeadline);
  const regularPrompts = promptItems.filter(item => !item.isHeadline);

  useEffect(() => {
    if (showResearchModes) {
      const positionDropdown = () => {
        const button = document.querySelector('.research-mode-selector button');
        const dropdown = document.querySelector('div[class*="fixed w-80 rounded-xl"]') as HTMLElement;
        
        if (button && dropdown) {
          const rect = button.getBoundingClientRect();
          
          dropdown.style.zIndex = '9999';
          
          if (!isMobile) {
            dropdown.style.left = `${rect.right + 8}px`;
            dropdown.style.top = `${rect.bottom + 8}px`;
            
            const dropdownRect = dropdown.getBoundingClientRect();
            if (dropdownRect.right > window.innerWidth) {
              dropdown.style.left = `${window.innerWidth - dropdownRect.width - 16}px`;
            }
            
            if (dropdownRect.bottom > window.innerHeight) {
              dropdown.style.top = `${Math.max(8, rect.top - (dropdownRect.bottom - window.innerHeight))}px`;
            }
            
            if (dropdownRect.top < 8) {
              dropdown.style.top = '8px';
            }
          } else {
            const safeMargin = 60; // Account for possible top bar/header
            dropdown.style.position = 'absolute';
            dropdown.style.left = '50%';
            dropdown.style.top = `${rect.bottom + window.scrollY + 16}px`; // Position below button with margin
            dropdown.style.transform = 'translateX(-50%)'; // Center horizontally only
            dropdown.style.maxHeight = `${window.innerHeight - rect.bottom - safeMargin}px`; // Prevent overflow
            dropdown.style.overflowY = 'auto'; // Allow scrolling if content is too tall
          }
        }
      };
      
      positionDropdown();
      const initialTimer = setTimeout(positionDropdown, 100);
      const secondTimer = setTimeout(positionDropdown, 500);
      
      window.addEventListener('resize', positionDropdown);
      window.addEventListener('scroll', positionDropdown);
      
      return () => {
        clearTimeout(initialTimer);
        clearTimeout(secondTimer);
        window.removeEventListener('resize', positionDropdown);
        window.removeEventListener('scroll', positionDropdown);
      };
    }
  }, [showResearchModes, isMobile, isLoadingPrompts]);

  useEffect(() => {
    // Create a portal container if it doesn't exist
    if (!portalContainer && showResearchModes) {
      const container = document.createElement('div');
      container.id = 'research-modes-portal';
      container.style.position = 'fixed';
      container.style.top = '0';
      container.style.left = '0';
      container.style.width = '100%';
      container.style.height = '0';
      container.style.overflow = 'visible';
      container.style.zIndex = '9999';
      container.style.pointerEvents = 'none';
      document.body.appendChild(container);
      
      // Ensure pointer events work on children
      const innerDiv = document.createElement('div');
      innerDiv.style.pointerEvents = 'auto';
      container.appendChild(innerDiv);
      
      setPortalContainer(innerDiv);
    }
    
    return () => {
      if (portalContainer && !showResearchModes) {
        const parent = portalContainer.parentElement;
        if (parent && parent.parentElement) {
          parent.parentElement.removeChild(parent);
        }
        setPortalContainer(null);
      }
    };
  }, [showResearchModes, portalContainer]);

  return (
    <div 
    ref={contentRef} 
    className={`relative min-h-screen ${isDarkMode ? 'bg-black' : 'bg-white'}`}
  >
      <SEO 
        title="Newsroom | Next-Generation Market Intelligence"
        description="Experience the future of market analysis with AI-powered insights"
      />

      <Background isLoaded={isLoaded} isMobile={isMobile} />
       
      <NewsTicker 
        items={tickerItems}
        isDarkMode={isDarkMode} 
        onItemClick={handleTickerItemClick}
        isLoading={tickerItemsLoading}
      />

      <div className="relative min-h-screen flex flex-col items-center">
        <div className="mx-auto mt-6 md:mt-10 w-full max-w-7xl px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="mb-6 md:mb-12"
          >
            <div className="flex items-center justify-center mb-3 md:mb-4">
              <Box size={isMobile ? 22 : 36} className="text-white mr-2 md:mr-3" />
              <h1 className={`${isMobile ? 'text-xl' : 'text-3xl lg:text-4xl'} font-heading font-light text-white tracking-tight text-center`}>
                {t('marketQuestion')}
              </h1>
            </div>
            
            <p className="text-gray-300 text-center mb-6 max-w-xl mx-auto text-xs md:text-sm">
              {t('marketSubtitle')}
            </p>
          </motion.div>

          <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="relative mb-8 max-w-4xl mx-auto transform hover:scale-[1.01] transition-transform duration-300"
        >
          <div className="flex flex-col w-full gap-2">
            {/* Input Bar - Always at the top */}
            <div className="flex-1 relative w-full">
              <div className={`flex items-center flex-wrap gap-1.5 w-full py-2 px-3 md:px-5 
                rounded-lg md:rounded-xl border border-gray-700/40 bg-gray-900/80 text-white
                focus-within:ring-2 focus-within:ring-blue-500/70 focus-within:border-blue-500/70 min-h-[50px] md:min-h-[60px]
                ${selectedPromptChip || selectedStockChip ? 'pl-2' : 'pl-3 md:pl-5'} backdrop-blur-sm shadow-[0_0_15px_rgba(59,130,246,0.15)]`}>
                
                {selectedPromptChip && (
                  <div className="py-1">
                    <PromptChip 
                      text={selectedPromptChip.text}
                      prefix={selectedPromptChip.prefix}
                      onRemove={() => setSelectedPromptChip(null)}
                      isDarkMode={isDarkMode}
                    />
                  </div>
                )}
                
                {selectedStockChip && (
                  <div className="py-1">
                    <StockChip 
                      ticker={selectedStockChip.ticker}
                      companyName={selectedStockChip.companyName}
                      prefix={selectedStockChip.prefix}
                      onRemove={() => setSelectedStockChip(null)}
                      isDarkMode={isDarkMode}
                    />
                  </div>
                )}
                
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder={selectedPromptChip || selectedStockChip ? 
                    t('searchPlaceholderWithChip') : 
                    t('searchPlaceholder')}
                  className="flex-1 min-w-[150px] bg-transparent border-none focus:ring-0 text-white placeholder-gray-500 text-sm md:text-base py-2 focus:outline-none selection:bg-blue-500/30"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handlePromptSubmit();
                    }
                  }}
                />
                
                <motion.button 
                  className="p-2 md:p-2.5 rounded-lg bg-white hover:bg-blue-500 text-black hover:text-white transition-all duration-300 shadow-md"
                  whileHover={{ scale: 1.05, boxShadow: "0 5px 15px rgba(59,130,246,0.3)" }}
                  whileTap={{ scale: 0.97, boxShadow: "0 2px 5px rgba(59,130,246,0.2)" }}
                  transition={{ 
                    scale: { type: "spring", stiffness: 400, damping: 10 }
                  }}
                  onClick={handlePromptSubmit}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width={isMobile ? "14" : "16"} height={isMobile ? "14" : "16"} viewBox="0 0 24 24" fill="none" 
                      stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <line x1="22" y1="2" x2="11" y2="13"></line>
                    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                  </svg>
                </motion.button>
              </div>
            </div>

            {/* Mobile-only buttons row */}
            {isMobile && (
              <div className="flex gap-2">
                {/* Research Mode Selector - Mobile */}
                <button
                  onClick={() => setIsResearchModalOpen(true)}
                  className="flex-1 flex items-center justify-between px-3 py-2.5 rounded-lg transition-all
                    bg-gray-800/80 hover:bg-gray-750 text-gray-300 border border-gray-700/80
                    backdrop-blur-sm shadow-sm"
                >
                  <div className="flex items-center space-x-2">
                    <div className="p-1 rounded-md bg-blue-500/10">
                      <selectedResearchMode.icon className="w-3.5 h-3.5 text-blue-500" />
                    </div>
                    <span className="font-medium text-xs">
                      {t(selectedResearchMode.id === 'default' ? 'standardMode' : 
                        selectedResearchMode.id === 'contrarian' ? 'contrarianMode' :
                        selectedResearchMode.id === 'discovery' ? 'discoveryMode' :
                        selectedResearchMode.id === 'deep-dive' ? 'deepDiveMode' : 'technicalMode')}
                    </span>
                  </div>
                  <div className="w-4 h-4 flex items-center justify-center rounded-full text-gray-400">
                    <ChevronDown className="w-3 h-3" />
                  </div>
                </button>
                
                {/* Add Stock from Watchlist - Mobile */}
                <button
                  onClick={() => {
                    if (isAuthenticated) {
                      // Show watchlist selection modal
                      setIsWatchlistModalOpen(true);
                    } else {
                      // Redirect to signup page
                      navigate('/register');
                    }
                  }}
                  className="px-3 py-2.5 rounded-lg flex items-center justify-center
                    bg-gray-800/80 hover:bg-gray-750 text-gray-300 border border-gray-700/80
                    backdrop-blur-sm shadow-sm"
                >
                  <div className="p-1 rounded-md bg-green-500/10">
                    <Plus className="w-3.5 h-3.5 text-green-500" />
                  </div>
                </button>
              </div>
            )}
            
            {/* Desktop layout - Research mode selector on left */}
            {!isMobile && (
              <div className="relative research-mode-selector w-full md:w-auto" style={{ zIndex: 50 }}>
                <button
                  onClick={() => setIsResearchModalOpen(true)}
                  className="flex items-center justify-between px-3 py-4 w-full md:w-auto rounded-l-xl
                    transition-all duration-300 bg-gray-800/80 hover:bg-gray-750 text-gray-300 
                    border border-gray-700/80 backdrop-blur-sm shadow-sm hover:shadow-md 
                    transform hover:-translate-y-[1px] active:translate-y-[1px]"
                >
                  <div className="flex items-center space-x-2">
                    <div className="p-1 rounded-md bg-blue-500/10">
                      <selectedResearchMode.icon className="w-4 h-4 text-blue-500" />
                    </div>
                    <span className="font-medium text-sm">
                      {t(selectedResearchMode.id === 'default' ? 'standardMode' : 
                        selectedResearchMode.id === 'contrarian' ? 'contrarianMode' :
                        selectedResearchMode.id === 'discovery' ? 'discoveryMode' :
                        selectedResearchMode.id === 'deep-dive' ? 'deepDiveMode' : 'technicalMode')}
                    </span>
                  </div>
                  <div className="w-5 h-5 flex items-center justify-center rounded-full text-gray-400">
                    <ChevronDown className="w-3.5 h-3.5" />
                  </div>
                </button>
              </div>
            )}
          </div>
        </motion.div>

          {isLoadingPrompts ? (
            <div className="relative w-full h-64 overflow-hidden flex flex-col items-center justify-center">
              <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-indigo-900/20 via-gray-900/10 to-transparent" />
              
              <motion.div
                layoutId="central-sun"
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ 
                  scale: 1,
                  opacity: 1,
                }}
                className="w-16 h-16 rounded-full bg-gradient-to-br from-yellow-300 via-orange-400 to-red-500
                shadow-[0_0_50px_rgba(251,146,60,0.3)]"
              >
                <motion.div 
                  className="w-full h-full rounded-full bg-gradient-to-br from-yellow-200 to-orange-400 opacity-80"
                  animate={{ 
                    scale: [1, 1.1, 1],
                  }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                />
              </motion.div>
  
              <div className="mt-8 text-center">
                <div className="text-gray-200 font-medium mb-2">{t('loadingMarketPulse')}</div>
                <div className="text-gray-200 font-medium mb-2">{t('loadingFirstTime')}</div> 
                <div className="h-6 overflow-hidden">
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={loadingText}
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -20, opacity: 0 }}
                      transition={{ duration: 0.3, ease: "easeInOut" }}
                      className="text-gray-400 text-sm"
                    >
                      {loadingText}
                    </motion.div>
                  </AnimatePresence>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="mb-6 md:mb-8">
                <div className="flex items-center justify-between mb-3 md:mb-4">
                  <h3 className="text-xs md:text-sm font-medium text-blue-400 uppercase tracking-wider flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
                      stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2">
                      <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                      <polyline points="17 6 23 6 23 12"></polyline>
                    </svg>
                    {t('trendingTopics')}
                  </h3>
                  <p className="text-gray-400 text-xs">
                    {t('clickTopicBelow')}
                  </p>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-3">
                  {headlinePrompts.slice(0, isMobile ? 4 : 6).map((item, index) => (
                    <motion.button
                      key={index}
                      onClick={(e) => {
                        if (e.target === e.currentTarget || !(e.target as Element).closest('.quick-actions')) {
                          setSelectedPromptChip({
                            text: item.prompt,
                            prefix: selectedResearchMode.prefix
                          });
                          setSearchQuery('');
                        }
                      }}
                      className={`group relative ${isMobile ? 'h-40' : 'h-32'} overflow-hidden rounded-xl border border-gray-700/50 shadow-lg`}
                      whileHover={{ 
                        scale: 1.03, 
                        boxShadow: "0 10px 25px -5px rgba(0, 0, 0, 0.2), 0 8px 10px -6px rgba(0, 0, 0, 0.1)",
                        borderColor: "rgba(59, 130, 246, 0.5)"
                      }}
                      whileTap={{ scale: 0.98 }}
                      transition={{ 
                        type: "spring",
                        stiffness: 400,
                        damping: 15
                      }}
                    >
                      {item.imageUrl ? (
                        <div 
                          className="absolute inset-0 bg-cover bg-center"
                          style={{ backgroundImage: `url(${item.imageUrl})` }}
                        >
                          <div className="absolute inset-0 bg-gradient-to-t from-black via-black/60 to-transparent" />
                        </div>
                      ) : (
                        <div className="absolute inset-0 bg-gradient-to-br from-blue-900/40 to-gray-900">
                          <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent" />
                        </div>
                      )}
                      
                      {item.ticker && (
                        <div className="absolute top-2 right-2 px-2 py-0.5 bg-black/80 rounded-md backdrop-blur-sm z-10 flex items-center">
                          <span className="text-xs font-bold text-white">${item.ticker}</span>
                          {item.tickerPriceLoaded && (
                            <span className={`ml-1 text-xs ${(item.tickerChangePercent ?? 0) >= 0 ? 'text-green-300' : 'text-red-300'}`}>
                              {(item.tickerChangePercent ?? 0) >= 0 ? '▲' : '▼'}
                              {Math.abs(item.tickerChangePercent ?? 0).toFixed(1)}%
                            </span>
                          )}
                        </div>
                      )}
                      
                      <div className="absolute z-50 top-0 left-0 right-0 flex justify-center opacity-0 group-hover:opacity-100 transition-opacity transform translate-y-2 group-hover:translate-y-3 duration-300 quick-actions">
                        <div className="flex space-x-1 p-1 backdrop-blur-md bg-black/40 rounded-full shadow-lg">
                          {item.ticker && (
                            <motion.button
                              onClick={(e) => {
                                e.stopPropagation();
                                if (isAuthenticated) {
                                  openStockModal(item.ticker!);
                                } else {
                                  navigate('/register');
                                }
                              }}
                              className="p-1.5 bg-gray-800/90 hover:bg-gray-700 rounded-full transition-colors"
                              title="View Chart"
                              whileHover={{ scale: 1.15 }}
                              whileTap={{ scale: 0.9 }}
                              transition={{ type: "spring", stiffness: 500, damping: 15 }}
                            >
                              <LineChart className="w-3 h-3 text-gray-300" />
                            </motion.button>
                          )}
                          
                          <motion.button
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedPromptChip({
                                text: item.prompt,
                                prefix: selectedResearchMode.prefix
                              });
                              setSearchQuery('');
                            }}
                            className="p-1.5 bg-gray-800/90 hover:bg-gray-700 rounded-full transition-colors"
                            title="Add to Chat"
                            whileHover={{ scale: 1.15 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 500, damping: 15 }}
                          >
                            <Box className="w-3 h-3 text-gray-300" />
                          </motion.button>
                  
                          {item.ticker && (
                            <motion.button
                              onClick={(e) => {
                                e.stopPropagation();
                                if (!isAuthenticated) {
                                  navigate('/register');
                                } else if (item.ticker) {
                                  navigate('/dashboard');
                                }
                              }}
                              className="p-1.5 bg-gray-800/90 hover:bg-gray-700 rounded-full transition-colors"
                              title="Save to Watchlist"
                              whileHover={{ scale: 1.15 }}
                              whileTap={{ scale: 0.9 }}
                              transition={{ type: "spring", stiffness: 500, damping: 15 }}
                            >
                              <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="12" 
                                height="12" 
                                viewBox="0 0 24 24" 
                                fill="none"
                                stroke="currentColor" 
                                strokeWidth="2" 
                                strokeLinecap="round" 
                                strokeLinejoin="round"
                                className="text-gray-300"
                              >
                                <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                              </svg>
                            </motion.button>
                          )}
                        </div>
                      </div>
                      
                      <div className="absolute top-2 left-2 p-1 rounded-md backdrop-blur-sm 
                                    shadow-lg opacity-60 group-hover:opacity-100 transition-opacity z-10">
                        <Box size={24} className="text-white" />
                      </div>
                      
                      <div className="relative h-full flex flex-col justify-end p-3">
                        <div className="flex items-center mb-0.25">
                          <div className="w-2 h-2 bg-blue-500 rounded-full mr-2"></div>
                          <span className="text-xs text-blue-300 font-medium">{t('trending')}</span>
                        </div>
                        <p className="text-white text-left text-base font-medium line-clamp-2 group-hover:text-blue-200 transition-colors">
                          {item.prompt}
                        </p>
                      </div>
                    </motion.button>
                  ))}
                </div>
              </div>
              
              <div className="mb-8 max-w-7xl w-full">
                <div className="flex items-center justify-between mb-4 px-4">
                  <h3 className="text-sm font-medium text-green-400 uppercase tracking-wider flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
                        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2">
                      <path d="M12 20V10"></path>
                      <path d="M18 20V4"></path>
                      <path d="M6 20v-4"></path>
                    </svg>
                    {t('trendingStocks')}
                  </h3>
                  <p className="text-gray-400 text-xs">
                    {t('marketMoversToday')}
                  </p>
                </div>
                
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-3">
                  {trendingStocks.slice(0, isMobile ? 4 : 3).map((stock, index) => (
                    <motion.button
                      key={`stock-${index}`}
                      onClick={() => {
                        setSelectedStockChip({
                          ticker: stock.ticker,
                          companyName: stock.name,
                          prefix: selectedResearchMode.prefix
                        });
                        setSearchQuery('');
                      }}
                      className={`group relative ${isMobile ? 'h-40' : 'h-32'} overflow-hidden rounded-xl border border-gray-700/50 shadow-lg`}
                      whileHover={{ 
                        scale: 1.03, 
                        boxShadow: "0 10px 25px -5px rgba(0, 0, 0, 0.2), 0 8px 10px -6px rgba(0, 0, 0, 0.1)",
                        borderColor: "rgba(46, 204, 113, 0.5)"
                      }}
                      whileTap={{ scale: 0.98 }}
                      transition={{ 
                        type: "spring",
                        stiffness: 400,
                        damping: 15
                      }}
                    >
                      <div className="absolute inset-0 bg-gradient-to-br from-green-900/30 to-gray-900">
                        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent" />
                      </div>
                      
                      <div className="absolute top-2 right-2 px-2 py-0.5 bg-black/80 rounded-md backdrop-blur-sm flex items-center">
                        <span className="text-xs font-bold text-white">${stock.ticker}</span>
                        {stock.priceLoaded && (
                          <span className={`ml-1 text-xs ${(stock.changePercent ?? 0) >= 0 ? 'text-green-300' : 'text-red-300'}`}>
                            {(stock.changePercent ?? 0) >= 0 ? '▲' : '▼'}
                            {Math.abs(stock.changePercent ?? 0).toFixed(1)}%
                          </span>
                        )}
                      </div>
                      
                      <div className="absolute left-0 right-0 top-1/2 -translate-y-1/2 h-12 px-3 opacity-30">
                        <StockSparkline
                          ticker={stock.ticker}
                          timeframe="1d"
                          width={containerSize.width / 3 - 20}
                          height={50}
                        />
                      </div>
                      
                      <div className="absolute top-0 left-0 right-0 flex justify-center opacity-0 group-hover:opacity-100 transition-opacity transform translate-y-2 group-hover:translate-y-3 duration-300">
                        <div className="flex space-x-1 p-1 backdrop-blur-md bg-black/40 rounded-full shadow-lg">
                          <motion.button
                            onClick={(e) => {
                              e.stopPropagation();
                              if (isAuthenticated) {
                                openStockModal(stock.ticker);
                              } else {
                                navigate('/register');
                              }
                            }}
                            className="p-1.5 bg-gray-800/90 hover:bg-gray-700 rounded-full transition-colors"
                            title="View Chart"
                            whileHover={{ scale: 1.15 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 500, damping: 15 }}
                          >
                            <LineChart className="w-3 h-3 text-gray-300" />
                          </motion.button>
                          
                          <motion.button
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedStockChip({
                                ticker: stock.ticker,
                                companyName: stock.name,
                                prefix: selectedResearchMode.prefix
                              });
                              setSearchQuery('');
                            }}
                            className="p-1.5 bg-gray-800/90 hover:bg-gray-700 rounded-full transition-colors"
                            title="Add to Chat"
                            whileHover={{ scale: 1.15 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 500, damping: 15 }}
                          >
                            <Box className="w-3 h-3 text-gray-300" />
                          </motion.button>
                          
                          <motion.button
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!isAuthenticated) {
                                navigate('/register');
                              } else {
                                navigate('/register');
                              }
                            }}
                            className="p-1.5 bg-gray-800/90 hover:bg-gray-700 rounded-full transition-colors"
                            title="Save to Watchlist"
                            whileHover={{ scale: 1.15 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 500, damping: 15 }}
                          >
                            <svg 
                              xmlns="http://www.w3.org/2000/svg" 
                              width="12" 
                              height="12" 
                              viewBox="0 0 24 24" 
                              fill="none"
                              stroke="currentColor" 
                              strokeWidth="2" 
                              strokeLinecap="round" 
                              strokeLinejoin="round"
                              className="text-gray-300"
                            >
                              <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                            </svg>
                          </motion.button>
                        </div>
                      </div>
                      
                      <div className="absolute bottom-0 left-0 right-0 p-3">
                        <div className="flex items-center justify-between mb-1">
                          <span className="text-xs text-gray-300 font-medium text-left">{stock.name}</span>
                          {stock.priceLoaded && (
                            <span className="text-xs text-white font-mono">${stock.price?.toFixed(2)}</span>
                          )}
                        </div>
                        
                        <p className="text-white text-left text-sm font-medium line-clamp-2 group-hover:text-green-200 transition-colors">
                          {stock.reason}
                        </p>
                      </div>
                    </motion.button>
                  ))}
                </div>
              </div>
  
              <div className="mb-8 max-w-7xl w-full">
                <div className="flex items-center justify-between mb-4 px-4">
                  <h3 className="text-sm font-medium text-purple-400 uppercase tracking-wider flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
                        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2">
                      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                    </svg>
                    {t('moreTopicsToExplore')}
                  </h3>
                </div>
                
                <div className="bg-gray-800/40 backdrop-blur-sm border border-gray-700/50 rounded-xl p-4 mx-4">
                  <div className="flex flex-wrap gap-2">
                    {regularPrompts.slice(0, isMobile ? 8 : 12).map((item, index) => (
                      <motion.button
                        key={`item-${index}`}
                        onClick={() => {
                          setSelectedPromptChip({
                            text: item.prompt,
                            prefix: selectedResearchMode.prefix
                          });
                          setSearchQuery('');
                        }}
                        className="px-3 py-2 bg-gray-700/50 hover:bg-blue-600/30 border border-gray-600/50 hover:border-blue-500/50 rounded-lg text-sm text-gray-300 hover:text-blue-300 transition-all duration-200 flex items-center"
                        whileHover={{ scale: 1.05 }}
                        transition={{ duration: 0.2 }}
                      >
                        <span>{item.prompt}</span>
                        {item.ticker && (
                          <span className="ml-2 bg-blue-500/20 px-1.5 py-0.5 rounded text-xs text-blue-300">{item.ticker}</span>
                        )}
                      </motion.button>
                    ))}
                  </div>
                </div>
              </div>
  
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
                className="w-full max-w-xl mx-auto text-center mb-16"
              >
                <div className="bg-blue-500/10 border border-blue-500/20 backdrop-blur-sm rounded-xl p-6 md:p-8 shadow-lg shadow-blue-500/5">
                  <h3 className="text-xl md:text-2xl font-medium text-white mb-3">{t('readyToDiveDeeper')}</h3>
                  <p className="text-gray-300 mb-6">
                    {t('signUpForFree')}
                  </p>
                  <Link
                    to="/register"
                    className="group relative inline-flex items-center justify-center px-6 sm:px-8 py-3 sm:py-4 rounded-xl sm:rounded-2xl bg-[#FFF100] text-black overflow-hidden shadow-xl shadow-yellow-500/10"
                  >
                    <span className="relative font-medium flex items-center">
                      {t('getStartedForFree')}
                      <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
                    </span>
                  </Link>
                </div>
              </motion.div>
            </>
          )}
        </div>

        <section className="py-16 sm:py-32 relative max-w-7xl mx-auto w-full" data-section="2">
          <div className="mx-auto px-4 sm:px-6">
            <div className={`grid lg:grid-cols-2 gap-8 sm:gap-16 items-center ${isMobile ? 'mt-8' : ''}`}>
                
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                className={`order-2 lg:order-1`}
              >
                {!isMobile && (
                  <OptimizedSpline
                    scene="https://prod.spline.design/gbLicgVx8Sd1v57I/scene.splinecode"
                    className="mb-[-0.5vw]"
                    onLoad={() => setIsLoaded(true)}
                    isMobile={false}
                  />
                )}
        
                <h2 className="text-4xl sm:text-5xl md:text-6xl font-bold font-heading tracking-tighter">
                  <span className="text-white">{t('meetYourMarketOracle')}</span>
                  <span className="block mt-1.5 bg-gradient-to-r from-violet-400 via-fuchsia-400 to-purple-400 bg-clip-text text-transparent">
                    {t('marketOracle')}
                  </span>
                </h2>
        
                <p className="text-xl mt-1.5 font-heading tracking-tighter text-gray-400">
                  {t('experienceNewDimension')}
                </p>
        
                <div className="grid mt-6 gap-3">
                  {features.map((feature, i) => (
                    <motion.div
                      key={i}
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: i * 0.1 }}
                      className="flex font-heading tracking-tighter items-center space-x-3"
                    >
                                    <div className="w-8 h-8 rounded-xl bg-white flex items-center justify-center">
                                      <Hexagon strokeWidth={1.5} className="w-5 h-5 text-black" />
                                    </div>
                                    <span className="text-gray-300">{feature}</span>
                                  </motion.div>
                                ))}
                              </div>
                            </motion.div>
                           
                            <motion.div
                              initial={{ opacity: 0, scale: 0.95 }}
                              animate={{ opacity: 1, scale: 1 }}
                              className={`${isMobile ? 'order-1' : 'order-1 lg:order-2'}`}
                            >
                              <div className={`${isMobile ? 'aspect-auto h-[500px]' : 'aspect-square'} w-full max-w-2xl mx-auto rounded-3xl border border-violet-500/20 bg-gray-900/80 backdrop-blur-xl p-4 sm:p-8 overflow-hidden relative`}>
                                <PrismExampleGallery />
                              </div>
                            </motion.div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <ResearchModeModal
                    isOpen={isResearchModalOpen}
                    onClose={() => setIsResearchModalOpen(false)}
                    onSelect={(mode) => {
                      setSelectedResearchMode(mode);
                      setIsResearchModalOpen(false);
                    }}
                    selectedMode={selectedResearchMode}
                    isDarkMode={isDarkMode}
                    style={{
                      position: 'fixed',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 9999,
                      maxWidth: '90vw',
                      width: '450px'
                    }}
                  />
                  <AnimatePresence>
                    {isWatchlistModalOpen && (
                      <WatchlistSelectModal
                        isOpen={isWatchlistModalOpen}
                        onClose={() => setIsWatchlistModalOpen(false)}
                        onSelectStock={(ticker, companyName, prefix) => {
                          setSelectedStockChip({
                            ticker,
                            companyName,
                            prefix: selectedResearchMode.prefix
                          });
                          setSearchQuery('');
                          setIsWatchlistModalOpen(false);
                        }}
                        selectedResearchModePrefix={selectedResearchMode.prefix}
                      />
                    )}
                  </AnimatePresence>
                </div>
              );
           };