import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { DocumentationLayout } from '../../components/layout/DocumentationLayout';
import { 
  LayoutDashboard, 
  LineChart, 
  Newspaper, 
  TrendingUp,
  MessageSquare,
  Users,
  Search,
  Bell,
  Settings
} from 'lucide-react';
import { Link } from 'react-router-dom';

const DashboardDoc = () => {
  const { isDarkMode } = useTheme();

  return (
    <DocumentationLayout
      title="Dashboard Overview"
      icon={LayoutDashboard}
      description="Navigate and understand the Newsroom dashboard interface"
    >
      <div className={`prose max-w-none ${isDarkMode ? 'prose-invert' : ''}`}>
        <h2>Dashboard Layout</h2>
        <p>
          The Newsroom dashboard is your central hub for market research, analysis, and AI-powered insights. 
          The interface is designed to provide quick access to all platform features while keeping you updated 
          on market developments.
        </p>

        <div className={`p-6 rounded-xl border my-8 ${
          isDarkMode ? 'bg-gray-800/80 border-gray-700' : 'bg-gray-50 border-gray-200'
        }`}>
          <h3 className="mt-0">Key Dashboard Components</h3>
          <ul>
            <li><strong>Navigation Sidebar:</strong> Quick access to all platform features</li>
            <li><strong>Trending Topics:</strong> See what news and stocks are trending</li>
            <li><strong>News Ticker:</strong> Real-time updates on market news and events</li>
            <li><strong>Quick Actions:</strong> Access your chats, ticker information, and saved items</li>
            <li><strong>Market Status:</strong> View current market status and upcoming market hours</li>
          </ul>
        </div>

        <h2>Navigation</h2>
        <p>
          The main navigation sidebar provides access to all major features:
        </p>

        <h3>Primary Navigation</h3>
        <ul>
          <li><strong>Overview:</strong> Your personalized dashboard with market summary</li>
          <li><strong>Prism:</strong> AI-powered market research assistant</li>
          <li><strong>Search:</strong> Find stocks, news, and research topics</li>
          <li><strong>Saved:</strong> Access to your watchlists and saved research</li>
          <li><strong>Pro:</strong> Subscription management and account information</li>
        </ul>

        <h3>Secondary Navigation</h3>
        <ul>
          <li><strong>Settings:</strong> Account and platform configuration</li>
          <li><strong>Light/Dark Mode Toggle:</strong> Switch between light and dark themes</li>
          <li><strong>Log Out:</strong> Sign out of your Newsroom account</li>
        </ul>

        <h2>News Ticker</h2>
        <p>
          The scrolling news ticker at the top of the dashboard displays:
        </p>
        <ul>
          <li><strong>Breaking Market News:</strong> Current market events and developments</li>
          <li><strong>Rate Updates:</strong> Information on CD rates and financial indicators</li>
          <li><strong>Market Trends:</strong> Updates on sector movements and investor interest</li>
          <li><strong>Policy Developments:</strong> Market-impacting regulatory changes</li>
        </ul>

        <h2>Quick Actions Bar</h2>
        <p>
          The quick actions section provides easy access to:
        </p>
        <ul>
          <li><strong>Your Chats:</strong> Previous conversations with Prism AI assistant</li>
          <li><strong>Your Ticker:</strong> Personalized stock ticker information</li>
          <li><strong>Your Saved:</strong> Saved stocks, topics, and research</li>
        </ul>

        <h2>Market Status Panel</h2>
        <p>
          The market status panel displays:
        </p>
        <ul>
          <li><strong>Current Market Status:</strong> Whether markets are open, closed, or in pre/post-market hours</li>
          <li><strong>Current Time:</strong> Local and market time with timezone information</li>
          <li><strong>Trading Hours:</strong> Visual timeline of trading periods (pre-market, regular hours, after-hours)</li>
          <li><strong>Next Market Event:</strong> Countdown to the next market opening or closing</li>
        </ul>

        <h2>Trending Topics</h2>
        <p>
          The trending topics section highlights:
        </p>
        <ul>
          <li><strong>News Cards:</strong> Major market stories with visual indicators</li>
          <li><strong>Stock Performance:</strong> Notable stocks with percentage changes</li>
          <li><strong>Industry Developments:</strong> Sector-specific news and trends</li>
          <li><strong>AI-Powered Insights:</strong> Click any topic for in-depth AI analysis</li>
        </ul>

        <h2>Trending Stocks</h2>
        <p>
          The trending stocks section displays:
        </p>
        <ul>
          <li><strong>Stock Cards:</strong> Company name, ticker symbol, and current price</li>
          <li><strong>Performance Indicators:</strong> Percentage changes with color-coded arrows</li>
          <li><strong>Price Charts:</strong> Miniature trend lines showing recent performance</li>
          <li><strong>Key Developments:</strong> Brief summaries of company news and catalysts</li>
        </ul>

        <h2>Market News Feed</h2>
        <p>
          The market news feed provides:
        </p>
        <ul>
          <li><strong>Economic Updates:</strong> News on market challenges and opportunities</li>
          <li><strong>Company News:</strong> Developments for specific companies</li>
          <li><strong>Industry Trends:</strong> Sector-wide movements and patterns</li>
          <li><strong>Global Markets:</strong> International economic factors affecting markets</li>
          <li><strong>Policy News:</strong> Regulatory changes impacting financial markets</li>
        </ul>

        <h2>Prism AI Assistant</h2>
        <p>
          The central Prism AI feature allows you to:
        </p>
        <ul>
          <li><strong>Ask Market Questions:</strong> Get real-time answers about any market topic</li>
          <li><strong>Conduct Research:</strong> Perform in-depth analysis on companies and trends</li>
          <li><strong>Access Financial Data:</strong> Review live market data and statistics</li>
          <li><strong>Explore Topics:</strong> Click suggested topics for instant insights</li>
        </ul>
      </div>
    </DocumentationLayout>
  );
};

export default DashboardDoc;