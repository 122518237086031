import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { api } from '../services/api';
import { useAuth } from './AuthContext';
import { PRISM_CHAT_COST } from '../types/credits';

interface ChatLimitState {
  dailyChatsUsed: number;
  dailyChatsLimit: number;
  nextResetTime: Date | null;
  isLimitReached: boolean;
  remainingChats: number;
  isPro: boolean;
  lastChecked?: number; // Add timestamp of last check
}

interface ChatLimitContextType {
  limitState: ChatLimitState;
  checkChatAvailability: () => Promise<boolean>;
  recordChatUsage: () => Promise<boolean>;
  resetLimitState: () => void;
}

const ChatLimitContext = createContext<ChatLimitContextType | undefined>(undefined);

// Add a debounce time constant to avoid frequent checks
const CHECK_DEBOUNCE_MS = 30000; // 30 seconds between checks

export const ChatLimitProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const [limitState, setLimitState] = useState<ChatLimitState>({
    dailyChatsUsed: 0,
    dailyChatsLimit: 5, // Default limit for free users
    nextResetTime: null,
    isLimitReached: false,
    remainingChats: 5,
    isPro: false,
    lastChecked: 0
  });
  
  // Use a ref to track pending requests
  const pendingCheckRef = useRef<Promise<boolean> | null>(null);

  // Load chat limit data
  const loadChatLimits = async () => {
    if (!user) return;
    
    try {
      const { data } = await api.get('/user/chat-limits/status');
      
      // Calculate when the limit will reset (midnight in user's timezone)
      const nextReset = new Date();
      nextReset.setHours(24, 0, 0, 0); // Set to next midnight
      
      const remainingChats = Math.max(0, data.dailyChatsLimit - data.dailyChatsUsed);
      
      setLimitState({
        dailyChatsUsed: data.dailyChatsUsed || 0,
        dailyChatsLimit: data.dailyChatsLimit || 5,
        nextResetTime: nextReset,
        isLimitReached: remainingChats <= 0 && !data.isPro,
        remainingChats,
        isPro: data.isPro || false,
        lastChecked: Date.now()
      });
    } catch (error) {
      console.error('Failed to load chat limits:', error);
    }
  };

  // Check if the user can create a new chat
  const checkChatAvailability = async (): Promise<boolean> => {
    if (!user) return false;
    
    // If we checked recently, return the cached result
    const now = Date.now();
    if (now - (limitState.lastChecked || 0) < CHECK_DEBOUNCE_MS) {
      return !limitState.isLimitReached;
    }
    
    // If there's already a pending check, return that promise
    if (pendingCheckRef.current) {
      return pendingCheckRef.current;
    }
    
    // Create a new check promise
    pendingCheckRef.current = (async () => {
      try {
        const { data } = await api.get('/user/chat-limits/check');
        
        // Update state
        const nextReset = new Date();
        nextReset.setHours(24, 0, 0, 0); // Set to next midnight
        
        const remainingChats = Math.max(0, data.dailyChatsLimit - data.dailyChatsUsed);
        
        setLimitState({
          dailyChatsUsed: data.dailyChatsUsed || 0,
          dailyChatsLimit: data.dailyChatsLimit || 5,
          nextResetTime: nextReset,
          isLimitReached: !data.isAvailable,
          remainingChats,
          isPro: data.isPro || false,
          lastChecked: Date.now()
        });
        
        return data.isAvailable;
      } catch (error) {
        console.error('Failed to check chat availability:', error);
        return false;
      } finally {
        pendingCheckRef.current = null;
      }
    })();
    
    return pendingCheckRef.current;
  };

  // Record chat usage
  const recordChatUsage = async (): Promise<boolean> => {
    if (!user) return false;
    
    try {
      const { data } = await api.post('/user/chat-limits/record', {
        creditCost: PRISM_CHAT_COST
      });
      
      const remainingChats = Math.max(0, data.dailyChatsLimit - data.dailyChatsUsed);
      
      setLimitState(prevState => ({
        ...prevState,
        dailyChatsUsed: data.dailyChatsUsed,
        dailyChatsLimit: data.dailyChatsLimit,
        isLimitReached: remainingChats <= 0 && !data.isPro,
        remainingChats,
        isPro: data.isPro || false,
        lastChecked: Date.now()
      }));
      
      return true;
    } catch (error) {
      console.error('Failed to record chat usage:', error);
      return false;
    }
  };

  // Reset limit state (useful when user upgrades to Pro)
  const resetLimitState = () => {
    setLimitState(prevState => ({
      ...prevState,
      dailyChatsUsed: 0,
      isLimitReached: false,
      remainingChats: prevState.dailyChatsLimit,
      lastChecked: Date.now()
    }));
  };

  // Load limits on mount and when user changes
  useEffect(() => {
    if (user) {
      loadChatLimits();
    }
  }, [user]);

  return (
    <ChatLimitContext.Provider value={{ limitState, checkChatAvailability, recordChatUsage, resetLimitState }}>
      {children}
    </ChatLimitContext.Provider>
  );
};

export const useChatLimit = () => {
  const context = useContext(ChatLimitContext);
  if (!context) {
    throw new Error('useChatLimit must be used within a ChatLimitProvider');
  }
  return context;
};