import React from 'react';
import { Scale, ChevronRight, Shield, Database, MessageSquare, LineChart, Brain, UserCircle, CreditCard, Globe } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';

const termsData = [
  {
    title: 'Acceptance of Terms',
    icon: Scale,
    content: `By accessing and using Newsroom's services, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our services.`
  },
  {
    title: 'Service Description',
    icon: Brain,
    content: `Newsroom provides an AI-powered market research platform with features including Prism AI market analysis, real-time financial data, trade idea generation, and automated research capabilities. We reserve the right to modify, suspend, or discontinue any aspect of our services at any time.`
  },
  {
    title: 'User Accounts',
    icon: UserCircle,
    content: `• You must be at least 18 years old to use our services
• You are responsible for maintaining the confidentiality of your account credentials
• You agree to provide accurate and complete information during registration
• You are responsible for all activities under your account
• You may delete your account at any time through the Settings page
• We offer phone verification for enhanced account security`
  },
  {
    title: 'Data and Privacy',
    icon: Database,
    content: `• Chat sessions are automatically saved to your account
• You can export your complete data in JSON format at any time
• You control your privacy settings including analytics sharing and email preferences
• We implement industry-standard security measures to protect your information
• We do not sell your personal information to third parties
• You can delete all your account data as described in our Privacy Policy`
  },
  {
    title: 'Payment Terms',
    icon: CreditCard,
    content: `• Payment is required for premium features and subscription plans
• All fees are non-refundable unless otherwise stated
• We may modify pricing with 30 days notice to subscribers
• Subscriptions auto-renew until canceled
• You can cancel your subscription at any time through the Settings page
• Credits expire after 12 months of inactivity`
  },
  {
    title: 'Trade Ideas & Market Data',
    icon: LineChart,
    content: `• Market data is provided for informational purposes only
• Trade ideas and analysis are generated by AI and should not be considered financial advice
• You can save and customize your trade idea preferences
• We do not guarantee the accuracy of market data or generated trade ideas
• You assume full responsibility for your trading and investment decisions
• Historical market data may be limited by your subscription plan`
  },
  {
    title: 'Communication',
    icon: MessageSquare,
    content: `• We may send you essential account notifications and security alerts
• Email frequency and marketing communications can be controlled in your Privacy Settings
• All user-to-AI conversations are stored for service improvement
• You can export or delete your conversation history
• We may occasionally send product updates and announcements`
  },
  {
    title: 'Intellectual Property',
    icon: Shield,
    content: `• You retain ownership of your personal data and uploaded content
• We own all rights to our platform, AI models, and technology
• You grant us a license to use your content to provide and improve our services
• You may not copy, distribute or reverse engineer our technology
• All trade ideas you create are private to your account unless you choose to share them`
  },
  {
    title: 'Limitation of Liability',
    icon: Globe,
    content: `• Our services are provided "as is" without warranty of any kind
• We are not liable for trading decisions or financial losses resulting from the use of our services
• Our maximum liability is limited to fees paid in the last 12 months
• Some jurisdictions may not allow limitations on liability, so some of these limitations may not apply to you`
  }
];

const TermsOfService = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
          <div className="text-center">
            <Scale className={`w-12 h-12 mx-auto mb-6 ${
              isDarkMode ? 'text-blue-400' : 'text-blue-600'
            }`} />
            <h1 className={`text-4xl md:text-5xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Terms of Service
            </h1>
            <p className={`text-xl max-w-3xl mx-auto ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              Last updated: June 15, 2024
            </p>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Introduction */}
        <div className={`mb-12 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
          <p className="text-lg">
            Please read these Terms of Service ("Terms") carefully before using
            the Newsroom platform and services. These terms constitute a legally binding agreement
            between you and Newsroom.
          </p>
        </div>

        {/* Quick Navigation */}
        <div className={`mb-12 p-8 rounded-2xl ${
          isDarkMode ? 'bg-gray-800/80 backdrop-blur-xl border border-gray-700/50' : 'bg-white shadow-xl'
        }`}>
          <h2 className={`text-xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Table of Contents
          </h2>
          <div className="grid md:grid-cols-2 gap-2">
            {termsData.map((section, index) => (
              <a
                key={index}
                href={`#${section.title.toLowerCase().replace(/\s+/g, '-')}`}
                className={`flex items-center justify-between p-4 rounded-xl transition-colors ${
                  isDarkMode
                    ? 'hover:bg-gray-700/70 text-gray-300 bg-gray-800/50'
                    : 'hover:bg-gray-50 text-gray-600 bg-gray-50/50'
                }`}
              >
                <div className="flex items-center">
                  {section.icon && <section.icon className={`w-5 h-5 mr-3 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />}
                  <span>{section.title}</span>
                </div>
                <ChevronRight className="w-4 h-4" />
              </a>
            ))}
          </div>
        </div>

        {/* Sections */}
        <div className="space-y-16">
          {termsData.map((section, index) => (
            <section
              key={index}
              id={section.title.toLowerCase().replace(/\s+/g, '-')}
              className={`scroll-mt-24 ${
                index !== termsData.length - 1 ? 'pb-16 border-b' : ''
              } ${isDarkMode ? 'border-gray-700/50' : 'border-gray-200'}`}
            >
              <div className="flex items-center mb-6">
                {section.icon && <section.icon className={`w-8 h-8 mr-4 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />}
                <h2 className={`text-2xl font-bold ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {section.title}
                </h2>
              </div>
              <div className={`prose max-w-none ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                {section.content.split('\n').map((paragraph, i) => (
                  <p key={i} className="whitespace-pre-line mb-4 text-lg">
                    {paragraph}
                  </p>
                ))}
              </div>
            </section>
          ))}
        </div>

        {/* Contact Information */}
        <div className={`mt-16 p-8 rounded-2xl ${
          isDarkMode ? 'bg-gray-800/80 backdrop-blur-xl border border-gray-700/50' : 'bg-white shadow-xl'
        }`}>
          <h2 className={`text-xl font-bold mb-4 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Contact Us
          </h2>
          <p className={`mb-4 text-lg ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            If you have any questions about these Terms, please contact our legal team at:
          </p>
          <a
            href="mailto:hello@news-room.ca"
            className="text-blue-500 hover:text-blue-400 text-lg font-medium inline-flex items-center"
          >
            hello@news-room.ca <ChevronRight className="ml-1 w-4 h-4" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;