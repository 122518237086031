// src/types/superChart.ts

export interface ChartDataPoint {
    timestamp: number;
    price: number;
    volume: number;
    ema20?: number;
    ema50?: number;
    rsi?: number;
    macd?: number;
    signal?: number;

    
  }

  export type ChartInterval = 
  | '1min' 
  | '5min'
  | '15min'
  | '30min'
  | '1hour'
  | '4hour'
  | 'day'
  | 'week'
  | 'month';

  export type ChartTimeframe = 
  | '1H'    // Last hour (60 points at 1min)
  | '4H'    // Last 4 hours (48 points at 5min)
  | '1D'    // Last day (78 points at 5min during market hours)
  | '1W'    // Last week (5 days x ~78 points at 5min)
  | '1M'    // Last month (~22 trading days at 1hour)
  | '3M'    // Last 3 months (~66 trading days at 4hour)
  | '6M'    // Last 6 months (~132 trading days at day)
  | '1Y'    // Last year (~252 trading days at day)
  | 'YTD'   // Year to date
  | 'MAX';  // Maximum available data

  
  export type PatternType = 
  | 'double_top' 
  | 'double_bottom' 
  | 'breakout'
  | 'head_and_shoulders'
  | 'ascending_triangle'
  | 'descending_triangle'
  | 'bull_flag'
  | 'bear_flag';

  
  export interface TechnicalPattern {
    type: PatternType;
    startIndex: number;
    endIndex: number;
    confidence: number;
    explanation: string;
    direction?: 'up' | 'down';
    price?: number;
    verticalOffset?: number;
  }
  
  
  export interface PriceLevel {
    price: number;
    type: 'support' | 'resistance';
    strength: number;  // 1-10
    touches: number;
  }
  
  export interface IndicatorCrossover {
    timestamp: number;
    type: 'ema_cross' | 'macd_cross';
    direction: 'bullish' | 'bearish';
    significance: number;  // 1-10
  }
  
  
  export interface SuperChartAnalysis {
    patterns: TechnicalPattern[];
    levels: PriceLevel[];
    crossovers: IndicatorCrossover[];
  }

  // src/types/superChart.ts

  export interface ChartDataPoint {
    timestamp: number;
    price: number;
    volume: number;
    ema20?: number;
    ema50?: number;
    rsi?: number;
    macd?: number;
    signal?: number;
  }
  
  
  export interface ChartTimeConfig {
    interval: ChartInterval;
    points: number;
    description: string;
  }

  export interface ChartDataPoint {
    timestamp: number;     // Unix timestamp
    price: number;        // Current price
    open: number;        // Open price
    high: number;        // High price
    low: number;         // Low price
    close: number;       // Close price
    volume: number;      // Volume
    ema20?: number;      // Optional technical indicators
    ema50?: number;
    rsi?: number;
    macd?: number;
    signal?: number;
  }

  export interface ChartViewport {
    start: number;        // Start timestamp
    end: number;          // End timestamp
    interval: ChartInterval;
    dataPoints: number;   // Number of points to display
  }

  export const TIME_CONFIGS: Record<ChartTimeframe, ChartTimeConfig> = {
    '1H': { interval: '1min', points: 60, description: 'Last Hour' },
    '4H': { interval: '5min', points: 48, description: 'Last 4 Hours' },
    '1D': { interval: '5min', points: 78, description: 'Today' },
    '1W': { interval: '15min', points: 130, description: 'Last Week' },
    '1M': { interval: '1hour', points: 168, description: 'Last Month' },
    '3M': { interval: '4hour', points: 180, description: '3 Months' },
    '6M': { interval: 'day', points: 132, description: '6 Months' },
    '1Y': { interval: 'day', points: 252, description: '1 Year' },
    'YTD': { interval: 'day', points: 252, description: 'Year to Date' },
    'MAX': { interval: 'week', points: 520, description: 'Max Available' }
  };
  
  
  
  export interface PriceLevel {
    price: number;
    type: 'support' | 'resistance';
    strength: number;  // 1-10
    touches: number;
  }
  
  export interface IndicatorCrossover {
    timestamp: number;
    type: 'ema_cross' | 'macd_cross';
    direction: 'bullish' | 'bearish';
    significance: number;  // 1-10
  }
  
  export interface SuperChartAnalysis {
    patterns: TechnicalPattern[];
    levels: PriceLevel[];
    crossovers: IndicatorCrossover[];
  }

  // Add these types to your types/superChart.ts

export interface DrawingObject {
  id: string;
  type: string;
  points: { x: number; y: number }[];
  color: string;
  text?: string;
}

export interface DrawingState {
  drawings: DrawingObject[];
  selectedTool: string;
  selectedColor: string;
  history: DrawingObject[][];
  historyIndex: number;
}

export const initialDrawingState: DrawingState = {
  drawings: [],
  selectedTool: 'select',
  selectedColor: '#3B82F6', // Default blue color
  history: [[]],
  historyIndex: 0
};

export function createDrawingReducer() {
  return function drawingReducer(state: DrawingState, action: any): DrawingState {
    switch (action.type) {
      case 'ADD_DRAWING':
        const newDrawings = [...state.drawings, action.drawing];
        return {
          ...state,
          drawings: newDrawings,
          history: [...state.history.slice(0, state.historyIndex + 1), newDrawings],
          historyIndex: state.historyIndex + 1
        };
        
      case 'SELECT_TOOL':
        return {
          ...state,
          selectedTool: action.tool
        };
        
      case 'SELECT_COLOR':
        return {
          ...state,
          selectedColor: action.color
        };
        
      case 'UNDO':
        if (state.historyIndex > 0) {
          return {
            ...state,
            drawings: state.history[state.historyIndex - 1],
            historyIndex: state.historyIndex - 1
          };
        }
        return state;
        
      case 'REDO':
        if (state.historyIndex < state.history.length - 1) {
          return {
            ...state,
            drawings: state.history[state.historyIndex + 1],
            historyIndex: state.historyIndex + 1
          };
        }
        return state;
        
      case 'CLEAR_DRAWINGS':
        return {
          ...state,
          drawings: [],
          history: [[]],
          historyIndex: 0
        };
        
      default:
        return state;
    }
  };
}