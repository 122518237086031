// src/services/polygonService.ts
import { api } from './api';
import logger from '../config/logger';
import { TimeRange, CompanyDetails, StockPrice } from '../types/market';

export interface NewsArticle {
  title: string;
  publisher: {
    name: string;
  };
  article_url: string;
  published_utc: string;
  tickers?: string[];
}

interface TickerDetails {
  ticker: string;
  name: string;
  description: string;
  homepage_url: string;
  market: string;
  primary_exchange: string;
  market_cap: number;
  total_employees: number;
  sector: string;
  industry: string;
}

class PolygonService {
  private static instance: PolygonService;
  private axiosInstance = api;
  private apiKey: string = '';
  private baseUrl: string = '';

  private constructor() {
    this.apiKey = process.env.REACT_APP_POLYGON_API_KEY || '';
    this.baseUrl = 'https://api.polygon.io';
  }

  static getInstance(): PolygonService {
    if (!PolygonService.instance) {
      PolygonService.instance = new PolygonService();
    }
    return PolygonService.instance;
  }

  async getLatestNews(): Promise<NewsArticle[]> {
    try {
      // Using our backend API route instead of direct Polygon access
      const { data } = await api.get('/market/news');
      return data;
    } catch (error) {
      console.log('Latest market news fetch failed:', error);
      return []; // Return empty array instead of throwing
    }
  }

  async searchNews(query: string): Promise<NewsArticle[]> {
    try {
      const { data } = await api.get('/market/news/search', { 
        params: { query } 
      });
      return data;
    } catch (error) {
      console.log('News search failed:', error);
      return [];
    }
  }

  async getTechnicalIndicator(symbol: string, indicator: string, params: any = {}) {
    try {
      const { data } = await api.get(`/market/stock/${symbol}/indicators/${indicator}`, {
        params
      });
      return data;
    } catch (error) {
      console.log('Failed to fetch technical indicator:', error);
      throw error;
    }
  }

  async getAggregates(
    symbol: string,
    multiplier: number,
    timespan: string,
    from: string,
    to: string,
    options: {
      adjusted?: boolean;
      sort?: 'asc' | 'desc';
      limit?: number;
    } = {}
  ) {
    try {
      const { adjusted = true, sort = 'asc', limit = 5000 } = options;
      
      // Validate inputs
      if (!symbol) throw new Error('Symbol is required');
      if (!multiplier || multiplier <= 0) throw new Error('Invalid multiplier');
      if (!['minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'].includes(timespan)) {
        throw new Error('Invalid timespan');
      }
      
      // Ensure dates are in YYYY-MM-DD format
      const fromDate = from ? new Date(from).toISOString().split('T')[0] : '';
      const toDate = to ? new Date(to).toISOString().split('T')[0] : '';
      
      if (!fromDate || !toDate) throw new Error('Invalid date range');
      
      // For 1-day view with minute data, we need to include timestamp with hours
      let fromTimestamp = fromDate;
      let toTimestamp = toDate;
      
      if (timespan === 'minute') {
        const fromDateTime = new Date(from);
        const toDateTime = new Date(to);
        
        // Format as ISO string but keep only the needed parts
        fromTimestamp = `${fromDate}T${fromDateTime.getHours().toString().padStart(2, '0')}:${
          fromDateTime.getMinutes().toString().padStart(2, '0')}:00Z`;
        
        toTimestamp = `${toDate}T${toDateTime.getHours().toString().padStart(2, '0')}:${
          toDateTime.getMinutes().toString().padStart(2, '0')}:00Z`;
      }
      
      // Construct URL with query parameters
      const queryParams = new URLSearchParams({
        adjusted: adjusted.toString(),
        sort,
        limit: limit.toString(),
        apiKey: this.apiKey
      });
  
      // Use correct endpoint based on the timespan
      let url;
      if (timespan === 'minute') {
        url = `${this.baseUrl}/v2/aggs/ticker/${symbol}/range/${multiplier}/${timespan}/${fromTimestamp}/${toTimestamp}?${queryParams}`;
      } else {
        url = `${this.baseUrl}/v2/aggs/ticker/${symbol}/range/${multiplier}/${timespan}/${fromDate}/${toDate}?${queryParams}`;
      }
      
      logger.debug(`Making Polygon API request: ${url.replace(this.apiKey, '[REDACTED]')}`);
  
      const response = await fetch(url);
      
      if (!response.ok) {
        const errorText = await response.text();
        logger.error(`Polygon API error: ${response.status} - ${errorText}`);
        throw new Error(`Polygon API error: ${response.status} - ${errorText}`);
      }
  
      const data = await response.json() as { results?: any[], queryCount?: number, resultsCount?: number, status?: string };
  
      // Handle API errors
      if (data.status === 'ERROR') {
        logger.error(`Polygon API returned error status: ${JSON.stringify(data)}`);
        throw new Error(`Polygon API error: ${data.status}`);
      }
  
      // Handle empty results
      if (!data.results || data.results.length === 0) {
        logger.warn(`No data returned for ${symbol} from ${fromDate} to ${toDate}`);
        return {
          results: [],
          queryCount: 0,
          resultsCount: 0,
          adjusted
        };
      }
  
      // Post-process the data - ensure all fields are present and convert Unix timestamps to proper format
      const processedResults = data.results.map((bar: any) => {
        // Ensure all fields are present
        const result = {
          t: bar.t,
          o: bar.o ?? 0,
          h: bar.h ?? 0,
          l: bar.l ?? 0,
          c: bar.c ?? 0,
          v: bar.v ?? 0
        };
        
        // For daily data, ensure the timestamp is set to market opening time (9:30 AM ET)
        if (timespan === 'day') {
          const date = new Date(result.t);
          date.setHours(9, 30, 0, 0);
          result.t = date.getTime();
        }
        
        return result;
      });
  
      // Validate and return data
      return {
        results: processedResults,
        queryCount: data.queryCount || 0,
        resultsCount: data.resultsCount || processedResults.length,
        adjusted
      };
    } catch (error) {
      logger.error(`Polygon aggregates fetch failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
      throw error;
    }
  }

  async getTickerDetails(ticker: string): Promise<TickerDetails> {
    try {
      const { data } = await this.axiosInstance.get(`/market/stock/${ticker}/details`);
      return data;
    } catch (error) {
      console.log('Failed to fetch ticker details:', error);
      throw error;
    }
  }

  async getTickerNewsOld(ticker: string): Promise<{ results: NewsArticle[] }> {
    try {
      const { data } = await this.axiosInstance.get('/market/news/search', {
        params: { query: ticker }
      });
      return data;
    } catch (error) {
      console.log('Failed to fetch ticker news:', error);
      return { results: [] };
    }
  }

    // Update your existing getTickerNews method or add it if it doesn't exist
  async getTickerNews(ticker: string, limit: number = 5): Promise<NewsArticle[]> {
    try {
      const { data } = await this.axiosInstance.get(`/market/stock/${ticker}/news`, {
        params: { limit }
      });
      return data;
    } catch (error) {
      console.log('Failed to fetch ticker news:', error);
      return [];
    }
  }

  async getStockData(symbol: string, timeframe: string): Promise<StockPrice[]> {
    try {
      const { data } = await this.axiosInstance.get(`/market/stock/${symbol}/aggregates`, {
        params: { timeframe }
      });
      return data.results;
    } catch (error) {
      console.log('Failed to fetch stock data:', error);
      throw error;
    }
  }

  async getCompanyDetails(symbol: string): Promise<CompanyDetails> {
    try {
      const details = await this.getTickerDetails(symbol);
      return {
        ticker: details.ticker,
        name: details.name,
        description: details.description,
        sector: details.sector,
        industry: details.industry,
        marketCap: details.market_cap,
        employees: details.total_employees,
        exchange: details.primary_exchange,
        homepage: details.homepage_url
      };
    } catch (error) {
      console.log('Failed to fetch company details:', error);
      throw error;
    }
  }
}

export const polygonService = PolygonService.getInstance();