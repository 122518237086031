// src/components/common/SaveItemButton.tsx

import React, { useState, useEffect } from 'react';
import { Bookmark, Plus, List, FolderPlus, X } from 'lucide-react';
import { useWatchlists, WatchList } from '../../hooks/useWatchlists';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';

interface SaveItemButtonProps {
  type: 'STOCK' | 'ARTICLE' | 'COMPANY' | 'RESEARCH' | 'CHAT_CONTEXT';
  identifier: string;
  title: string;
  metadata?: Record<string, any>;
  variant?: 'icon' | 'button';
  className?: string;
}

interface SaveOptionsPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onSaveIndividual: () => Promise<void>;
  watchlists: WatchList[];
  onCreateList: (name: string) => Promise<void>;
  onAddToList: (listId: string) => Promise<void>;
  isDarkMode: boolean;
}

// Helper function for navigation that works both in and outside React Router context
const safeNavigate = (path: string): void => {
  window.location.href = path;
};

const SaveOptionsPopup: React.FC<SaveOptionsPopupProps> = ({
  isOpen,
  onClose,
  onSaveIndividual,
  watchlists,
  onCreateList,
  onAddToList,
  isDarkMode
}) => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newListName, setNewListName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateList = async () => {
    if (!newListName.trim()) return;
    setIsLoading(true);
    try {
      await onCreateList(newListName);
      setShowCreateForm(false);
      setNewListName('');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <div 
            className="fixed inset-0 bg-black/20 backdrop-blur-sm z-[99]"
            onClick={onClose}
          />
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className={`fixed left-[19%] top-1/4 -translate-x-1/6 -translate-y-1/4
              w-full max-w-sm z-[100] rounded-xl shadow-lg overflow-hidden
              ${isDarkMode ? 'bg-gray-900 border-gray-800' : 'bg-white border-gray-200'}
              border`}
          >
            {/* Header */}
            <div className="flex items-center justify-between p-4 border-b border-gray-800">
              <h3 className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Save Item
              </h3>
              <button onClick={onClose} className="text-gray-500 hover:text-gray-400">
                <X className="w-5 h-5" />
              </button>
            </div>

            {/* Content */}
            <div className="p-4">
              {/* Individual Save Option */}
              <button
                onClick={onSaveIndividual}
                className={`w-full flex items-center space-x-3 p-3 rounded-lg mb-4
                  ${isDarkMode 
                    ? 'bg-blue-500/10 text-blue-400 hover:bg-blue-500/20' 
                    : 'bg-blue-50 text-blue-600 hover:bg-blue-100'}`}
              >
                <Bookmark className="w-4 h-4" />
                <span>Save as Individual Item</span>
              </button>

              {/* Watchlists Section */}
              <div className="mb-4">
                <h4 className={`text-sm font-medium mb-2 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  Add to Watchlist
                </h4>
                <div className="space-y-2">
                  {watchlists.map(list => (
                    <button
                      key={list.id}
                      onClick={() => onAddToList(list.id)}
                      className={`w-full flex items-center justify-between p-3 rounded-lg
                        ${isDarkMode 
                          ? 'hover:bg-gray-800 text-gray-300' 
                          : 'hover:bg-gray-100 text-gray-700'}`}
                    >
                      <div className="flex items-center space-x-3">
                        <List className="w-4 h-4 text-gray-400" />
                        <span>{list.name}</span>
                      </div>
                      <span className="text-xs text-gray-500">
                        {list.items?.length || 0} items
                      </span>
                    </button>
                  ))}
                </div>
              </div>

              {/* Create New List Section */}
              {showCreateForm ? (
                <div className="space-y-2">
                  <input
                    type="text"
                    value={newListName}
                    onChange={(e) => setNewListName(e.target.value)}
                    placeholder="Enter list name..."
                    className={`w-full px-3 py-2 rounded-lg border ${
                      isDarkMode 
                        ? 'bg-gray-800 border-gray-700 text-white' 
                        : 'bg-white border-gray-300 text-gray-900'
                    } focus:ring-2 focus:ring-blue-500`}
                  />
                  <div className="flex space-x-2">
                    <button
                      onClick={() => setShowCreateForm(false)}
                      className={`flex-1 py-2 rounded-lg ${
                        isDarkMode 
                          ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' 
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleCreateList}
                      disabled={!newListName.trim() || isLoading}
                      className="flex-1 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600
                        disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isLoading ? 'Creating...' : 'Create'}
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => setShowCreateForm(true)}
                  className={`w-full flex items-center justify-center space-x-2 p-3 rounded-lg
                    ${isDarkMode 
                      ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' 
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
                >
                  <FolderPlus className="w-4 h-4" />
                  <span>Create New List</span>
                </button>
              )}
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export const SaveItemButton: React.FC<SaveItemButtonProps> = ({
  type,
  identifier,
  title,
  metadata = {},
  variant = 'icon',
  className = ''
}) => {
  const { isDarkMode } = useTheme();
  const { user } = useAuth();
  const { saveItem, watchlists, createList, addToList } = useWatchlists();
  const [showOptions, setShowOptions] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleButtonClick = () => {
    if (!user) {
      // Redirect to signup page if not logged in
      safeNavigate('/signup');
      return;
    }
    setShowOptions(true);
  };

  const handleSaveIndividual = async () => {
    if (!user) {
      safeNavigate('/signup');
      return;
    }
    
    setIsSaving(true);
    try {
      await saveItem({
        type,
        identifier,
        title,
        metadata
      });
      setShowOptions(false);
      setIsSaving(false);
    } catch (error) {
      console.error('Failed to save item:', error);
      setIsSaving(false);
    }
  };

  const handleCreateList = async (name: string) => {
    if (!user) {
      safeNavigate('/signup');
      return;
    }
    
    try {
      const newList = await createList({ name });
      if (newList?.id) {
        await addToList(identifier, newList.id);
      }
      setShowOptions(false);
    } catch (error) {
      console.error('Failed to create list:', error);
    }
  };

  const handleAddToList = async (listId: string) => {
    if (!user) {
      safeNavigate('/signup');
      return;
    }
    
    try {
      await addToList(identifier, listId);
      setShowOptions(false);
    } catch (error) {
      console.error('Failed to add to list:', error);
    }
  };

  if (variant === 'icon') {
    return (
      <>
        <button
          onClick={handleButtonClick}
          className={`rounded-lg p-1 transition-colors ${
            isDarkMode
              ? 'hover:bg-gray-800 text-gray-400 hover:text-gray-300'
              : 'hover:bg-gray-100 text-gray-600 hover:text-gray-900'
          } ${className}`}
        >
          <Bookmark className="w-3 h-3" />
        </button>
        {user && (
          <SaveOptionsPopup 
            isOpen={showOptions}
            onClose={() => setShowOptions(false)}
            onSaveIndividual={handleSaveIndividual}
            watchlists={watchlists}
            onCreateList={handleCreateList}
            onAddToList={handleAddToList}
            isDarkMode={isDarkMode}
          />
        )}
      </>
    );
  }

  return (
    <>
      <button
        onClick={handleButtonClick}
        className={`transition-colors ${
          isDarkMode 
            ? 'bg-blue-500/10 text-blue-400 hover:bg-blue-500/20' 
            : 'bg-blue-50 text-blue-600 hover:bg-blue-100'
        } px-2 py-1 rounded-lg flex items-center space-x-1 ${className}`}
      >
        {isSaving ? (
          <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            Saving...
          </span>
        ) : (
          <>
            <Plus className="w-3 h-3" />
            <span className="text-xs">Add</span>
          </>
        )}
      </button>
      {user && (
        <SaveOptionsPopup 
          isOpen={showOptions}
          onClose={() => setShowOptions(false)}
          onSaveIndividual={handleSaveIndividual}
          watchlists={watchlists}
          onCreateList={handleCreateList}
          onAddToList={handleAddToList}
          isDarkMode={isDarkMode}
        />
      )}
    </>
  );
};