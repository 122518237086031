// client/src/services/newsletterService.ts
import { api } from './api';
import { AxiosError } from 'axios';

interface NewsletterResponse {
  message: string;
}

interface ApiErrorResponse {
  message: string;
}

export class NewsletterService {
  private static instance: NewsletterService;

  private constructor() {}

  static getInstance(): NewsletterService {
    if (!NewsletterService.instance) {
      NewsletterService.instance = new NewsletterService();
    }
    return NewsletterService.instance;
  }

  async subscribe(email: string): Promise<NewsletterResponse> {
    try {
      const { data } = await api.post<NewsletterResponse>('/newsletter/subscribe', { email });
      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data?.message) {
        throw new Error(error.response.data.message);
      }
      throw new Error("Hmm... Looks like you're already subscribed! 🤔");
    }
  }

  async unsubscribe(email: string): Promise<NewsletterResponse> {
    try {
      const { data } = await api.post<NewsletterResponse>('/newsletter/unsubscribe', { email });
      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data?.message) {
        throw new Error(error.response.data.message);
      }
      throw new Error('Failed to unsubscribe from newsletter');
    }
  }
}

export const newsletterService = NewsletterService.getInstance();