import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Search, 
  Building2, 
  ArrowUpRight, 
  Box, 
  History, 
  X, 
  Tag, 
  TrendingUp,
  ChevronRight,
  Sparkles,
  Brain,
  Database,
  LineChart,
  Target,
  Filter,
  Grid,
  List,
  Plus,
  TrendingDown
} from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import { marketDataService } from '../../services/marketDataService';
import { motion, AnimatePresence } from 'framer-motion';
import debounce from 'lodash/debounce';
import { useStockView } from '../../contexts/StockViewContext';
import { SaveItemButton } from '../../components/common/SaveItemButton';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import { cachedPromptService } from '../../services/cachedPromptService';
import { api } from '../../services/api';
import StockSparkline from '../../pages/dashboard/StockSparkline';
import { SearchResult } from '../../types/market';

interface TrendingStockItem {
  ticker: string;
  name: string;
  reason: string;
  changePercent?: number;
  price?: number;
  priceLoaded?: boolean;
}

// Research modes for filter options
const researchModes = [
  {
    id: 'default',
    name: 'All Stocks',
    icon: Search,
    description: 'View all stocks'
  },
  {
    id: 'tech',
    name: 'Technology',
    icon: Brain,
    description: 'Tech and software companies'
  },
  {
    id: 'finance',
    name: 'Financial',
    icon: Database,
    description: 'Banks and financial institutions'
  },
  {
    id: 'healthcare',
    name: 'Healthcare',
    icon: Target,
    description: 'Medical and pharmaceutical companies'
  },
  {
    id: 'energy',
    name: 'Energy',
    icon: LineChart,
    description: 'Oil, gas and renewable energy'
  }
];

// View modes
enum ViewMode {
  Grid = 'grid',
  List = 'list'
}

const StockSearchPage: React.FC = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const { openStockModal } = useStockView();
  
  // State
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const [trendingStocks, setTrendingStocks] = useState<TrendingStockItem[]>([]); 
  const [selectedMode, setSelectedMode] = useState(researchModes[0]);
  const [showFilters, setShowFilters] = useState(false);
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Grid);
  
  const inputRef = useRef<HTMLInputElement>(null);

  // Fetch ticker data
  const fetchTickerData = async (ticker: string): Promise<{ changePercent: number } | null> => {
    if (!ticker) return null;

    try {
      const today = new Date();
      const startDate = new Date();
      startDate.setDate(today.getDate() - 5);
      
      const fromDate = startDate.toISOString().split('T')[0];
      const toDate = today.toISOString().split('T')[0];
      
      const { data } = await api.get(`/market/stock/${ticker}/aggregates`, {
        params: {
          multiplier: 1,
          timespan: 'day',
          from: fromDate,
          to: toDate
        }
      });
      
      if (data && data.results && data.results.length > 0) {
        const latestBar = data.results[data.results.length - 1];
        let prevClose = latestBar.o;
        
        if (data.results.length > 1) {
          prevClose = data.results[data.results.length - 2].c;
        }
        
        const changePercent = ((latestBar.c - prevClose) / prevClose) * 100;
        return { changePercent };
      }
      return null;
    } catch (error) {
      console.error(`Failed to fetch data for ${ticker}:`, error);
      return null;
    }
  };

  // Fetch trending stocks
  const fetchTrendingStocks = async () => {
    try {
      const data = await cachedPromptService.getCachedTrendingStocks();
      if (Array.isArray(data) && data.length > 0) {
        setTrendingStocks(data);
        
        const updatedStocks = await Promise.all(
          data.map(async (stock) => {
            try {
              const tickerData = await fetchTickerData(stock.ticker);
              
              const today = new Date();
              const startDate = new Date();
              startDate.setDate(today.getDate() - 5);
              
              const fromDate = startDate.toISOString().split('T')[0];
              const toDate = today.toISOString().split('T')[0];
              
              const { data } = await api.get(`/market/stock/${stock.ticker}/aggregates`, {
                params: {
                  multiplier: 1,
                  timespan: 'day',
                  from: fromDate,
                  to: toDate
                }
              });
              
              let price = 0;
              if (data && data.results && data.results.length > 0) {
                price = data.results[data.results.length - 1].c;
              }
              
              return {
                ...stock,
                price,
                changePercent: tickerData?.changePercent || 0,
                priceLoaded: true
              };
            } catch (error) {
              console.error(`Failed to fetch data for ${stock.ticker}:`, error);
              return stock;
            }
          })
        );
        
        setTrendingStocks(updatedStocks);
      }
    } catch (error) {
      console.error('Failed to fetch trending stocks:', error);
    }
  };

  // Load initial data
  useEffect(() => {
    const saved = localStorage.getItem('recent-searches');
    if (saved) {
      try {
        setRecentSearches(JSON.parse(saved).slice(0, 10));
      } catch (e) {
        console.error('Failed to parse recent searches', e);
      }
    }
    
    if (inputRef.current) {
      inputRef.current.focus();
    }

    fetchTrendingStocks();
  }, []);

  // Debounced search function
  const handleSearchChange = useCallback(
    debounce(async (searchQuery: string) => {
      if (!searchQuery.trim()) {
        setResults([]);
        return;
      }

      setIsLoading(true);
      try {
        const data = await marketDataService.getEnhancedStockSearch(searchQuery);
        setResults(data);
      } catch (error) {
        console.error('Search failed:', error);
        setResults([]);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    handleSearchChange(query);
  }, [query, handleSearchChange]);

  const addToRecentSearches = (term: string) => {
    const updated = [term, ...recentSearches.filter(s => s !== term)].slice(0, 10);
    setRecentSearches(updated);
    localStorage.setItem('recent-searches', JSON.stringify(updated));
  };

  const handleResultClick = (ticker: string) => {
    addToRecentSearches(ticker);
    openStockModal(ticker);
  };

  const clearRecentSearches = () => {
    setRecentSearches([]);
    localStorage.removeItem('recent-searches');
  };

  // Render stock card for grid view
  const renderStockCard = (result: SearchResult) => (
    <motion.div 
      whileHover={{ y: -4, boxShadow: isDarkMode ? '0 10px 25px -5px rgba(0, 0, 0, 0.3)' : '0 10px 25px -5px rgba(0, 0, 0, 0.1)' }}
      transition={{ type: "spring", stiffness: 400, damping: 15 }}
      className={`rounded-xl overflow-hidden border ${
        isDarkMode ? 'bg-gray-800/90 border-gray-700/60 hover:border-blue-500/40' : 'bg-white border-gray-200/60 hover:border-blue-500/40'
      }`}
    >
      {/* Header section with improved spacing */}
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          {/* Logo and basic info with better alignment */}
          <div className="flex items-center">
            <div className={`w-10 h-10 rounded-lg flex items-center justify-center overflow-hidden 
              ${isDarkMode ? 'bg-gray-700/70' : 'bg-gray-100'} border ${isDarkMode ? 'border-gray-600/40' : 'border-gray-200/60'}`}
            >
              <img
                src={`/api/market/company-logo/${result.ticker}`}
                alt=""
                className="w-8 h-8 object-contain"
                onError={(e) => {
                  e.currentTarget.style.display = 'none';
                  if (e.currentTarget.parentElement) {
                    e.currentTarget.parentElement.innerHTML = `
                      <div class="text-lg font-semibold ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}">
                        ${result.ticker.substring(0, 2)}
                      </div>
                    `;
                  }
                }}
              />
            </div>
            <div className="ml-3">
              <div className="flex items-center">
                <span className={`font-mono font-bold text-sm ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>{result.ticker}</span>
                {result.market && (
                  <span className={`ml-2 text-xs px-1.5 py-0.5 rounded-full ${
                    isDarkMode ? 'bg-gray-700/80 text-gray-400' : 'bg-gray-100 text-gray-600'
                  }`}>{result.market}</span>
                )}
              </div>
              <div className={`text-xs mt-0.5 truncate max-w-[160px] ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                {result.name}
              </div>
            </div>
          </div>

          {/* Price info with better visual treatment */}
          {result.price && (
            <div className="text-right">
              <div className={`text-base font-mono font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                ${result.price.toFixed(2)}
              </div>
              {result.changePercent && (
                <div className={`text-xs flex items-center justify-end ${
                  result.changePercent >= 0 ? 'text-green-500' : 'text-red-500'
                }`}>
                  {result.changePercent >= 0 ? (
                    <TrendingUp className="w-3.5 h-3.5 mr-0.5" />
                  ) : (
                    <TrendingDown className="w-3.5 h-3.5 mr-0.5" />
                  )}
                  <span>{Math.abs(result.changePercent).toFixed(2)}%</span>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Sparkline with better sizing and inner shadow */}
        <div className="w-full h-16 mt-1 mb-4 relative rounded-lg overflow-hidden">
          <div className={`absolute inset-0 ${isDarkMode ? 'bg-gray-700/30' : 'bg-gray-100/70'} rounded-lg`}></div>
          <StockSparkline
            ticker={result.ticker}
            timeframe="1d"
            height={60}
            width={300}
          />
        </div>

        {/* Key metrics with improved visual design */}
        <div className="grid grid-cols-2 gap-2 mb-4">
          {result.volume && (
            <div className={`p-2 rounded-lg ${isDarkMode ? 'bg-gray-700/30' : 'bg-gray-100/70'}`}>
              <div className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mb-0.5`}>Volume</div>
              <div className={`text-sm font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                {formatNumber(result.volume)}
              </div>
            </div>
          )}
          {result.marketCap && (
            <div className={`p-2 rounded-lg ${isDarkMode ? 'bg-gray-700/30' : 'bg-gray-100/70'}`}>
              <div className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mb-0.5`}>Market Cap</div>
              <div className={`text-sm font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                ${formatMarketCap(result.marketCap)}
              </div>
            </div>
          )}
        </div>

        {/* Sector tag with smaller size and better visual style */}
        {result.sector && (
          <div className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs
            ${isDarkMode ? 'bg-blue-500/10 text-blue-400 border border-blue-500/20' : 'bg-blue-50 text-blue-600 border border-blue-200/60'}`}
          >
            <Tag className="w-2.5 h-2.5 mr-1" />
            {result.sector}
          </div>
        )}
      </div>

      {/* Action buttons with improved visual hierarchy and interaction */}
      <div className={`grid grid-cols-3 divide-x ${
        isDarkMode ? 'divide-gray-700/70 border-t border-gray-700/70' : 'divide-gray-200 border-t border-gray-200'
      }`}>
        <motion.button 
          whileHover={{ backgroundColor: isDarkMode ? 'rgba(55, 65, 81, 0.3)' : 'rgba(243, 244, 246, 0.8)' }}
          onClick={() => handleResultClick(result.ticker)}
          className={`py-2 flex items-center justify-center space-x-1 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}
          title="View Details"
        >
          <ArrowUpRight className={`w-3.5 h-3.5 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`} />
          <span className="text-xs">Details</span>
        </motion.button>
        
        <motion.button 
          whileHover={{ backgroundColor: isDarkMode ? 'rgba(55, 65, 81, 0.3)' : 'rgba(243, 244, 246, 0.8)' }}
          onClick={() => navigate('/dashboard/market-chat', { 
            state: { initialPrompt: `Analyze ${result.ticker} stock` }
          })}
          className={`py-2 flex items-center justify-center space-x-1 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}
          title="Analyze in Chat"
        >
          <Brain className={`w-3.5 h-3.5 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`} />
          <span className="text-xs">Analyze</span>
        </motion.button>
        
        <motion.div 
          whileHover={{ backgroundColor: isDarkMode ? 'rgba(55, 65, 81, 0.3)' : 'rgba(243, 244, 246, 0.8)' }}
          className="py-2 flex items-center justify-center"
        >
          <SaveItemButton
            type="STOCK"
            identifier={result.ticker}
            title={result.name}
            variant="icon"
          />
        </motion.div>
      </div>
    </motion.div>
  );

  // Render stock row for list view
  const renderStockRow = (result: SearchResult) => (
    <motion.div 
      whileHover={{ 
        backgroundColor: isDarkMode ? 'rgba(59, 130, 246, 0.05)' : 'rgba(59, 130, 246, 0.03)',
        borderColor: 'rgba(59, 130, 246, 0.3)',
      }}
      className={`w-full p-3.5 flex items-center justify-between border-b transition-colors ${
        isDarkMode ? 'border-gray-800 hover:border-blue-500/20' : 'border-gray-200 hover:border-blue-500/20'
      }`}
    >
      <div className="flex items-center flex-1">
        <div className={`w-10 h-10 rounded-lg flex items-center justify-center overflow-hidden mr-3
          ${isDarkMode ? 'bg-gray-700/70' : 'bg-gray-100'} border ${isDarkMode ? 'border-gray-600/40' : 'border-gray-200/60'}`}
        >
          <img
            src={`/api/market/company-logo/${result.ticker}`}
            alt=""
            className="w-8 h-8 object-contain"
            onError={(e) => {
              e.currentTarget.style.display = 'none';
              if (e.currentTarget.parentElement) {
                e.currentTarget.parentElement.innerHTML = `
                  <div class="text-base font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}">
                    ${result.ticker.substring(0, 2)}
                  </div>
                `;
              }
            }}
          />
        </div>
        <div className="flex-1">
          <div className="flex items-center">
            <span className={`font-mono font-bold text-sm ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>{result.ticker}</span>
            {result.market && (
              <span className={`ml-2 text-xs px-1.5 py-0.5 rounded-full ${
                isDarkMode ? 'bg-gray-700/80 text-gray-400' : 'bg-gray-100 text-gray-600'
              }`}>{result.market}</span>
            )}
            
            {/* Add sector tag next to ticker in list view */}
            {result.sector && (
              <span className={`ml-2 text-xs px-1.5 py-0.5 rounded-full flex items-center
                ${isDarkMode ? 'bg-blue-500/10 text-blue-400' : 'bg-blue-50 text-blue-600'}`}
              >
                <Tag className="w-2.5 h-2.5 mr-1" />
                {result.sector}
              </span>
            )}
          </div>
          <div className={`text-xs mt-0.5 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            {result.name}
          </div>
        </div>
      </div>
      
      {/* Add Sparkline in the middle with background and rounded corners */}
      <div className="w-36 h-10 mx-4 hidden md:block relative rounded-md overflow-hidden">
        <div className={`absolute inset-0 ${isDarkMode ? 'bg-gray-700/30' : 'bg-gray-100/70'} rounded-md`}></div>
        <StockSparkline
          ticker={result.ticker}
          timeframe="1d"
          height={40}
          width={144}
        />
      </div>
      
      <div className="flex items-center space-x-4">
        {result.price && (
          <div className={`text-right px-3 py-1.5 rounded-md ${isDarkMode ? 'bg-gray-700/30' : 'bg-gray-100/70'}`}>
            <div className={`font-mono font-medium text-sm ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              ${result.price.toFixed(2)}
            </div>
            {result.changePercent && (
              <div className={`text-xs flex items-center justify-end ${result.changePercent >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                {result.changePercent >= 0 ? (
                  <TrendingUp className="w-3 h-3 mr-0.5" />
                ) : (
                  <TrendingDown className="w-3 h-3 mr-0.5" />
                )}
                <span>{Math.abs(result.changePercent).toFixed(2)}%</span>
              </div>
            )}
          </div>
        )}
        
        <div className="flex items-center">
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleResultClick(result.ticker)}
            className={`p-1.5 rounded-lg ${
              isDarkMode ? 'hover:bg-gray-700 text-gray-300' : 'hover:bg-gray-100 text-gray-600'
            }`}
            title="View Details"
          >
            <ArrowUpRight className="w-4 h-4" />
          </motion.button>
          
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate('/dashboard/market-chat', { 
              state: { initialPrompt: `Analyze ${result.ticker} stock` }
            })}
            className={`p-1.5 rounded-lg ${
              isDarkMode ? 'hover:bg-gray-700 text-gray-300' : 'hover:bg-gray-100 text-gray-600'
            }`}
            title="Analyze in Chat"
          >
            <Box className="w-4 h-4" />
          </motion.button>
          
          <SaveItemButton
            type="STOCK"
            identifier={result.ticker}
            title={result.name}
            variant="icon"
          />
        </div>
      </div>
    </motion.div>
  );

  return (
    <div className={`h-screen flex flex-col ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      {/* Top Header */}
      <div className={`py-3 px-4 border-b flex items-center ${
        isDarkMode ? 'bg-gray-900/95 border-gray-800' : 'bg-white/95 border-gray-200'
      } backdrop-blur-md sticky top-0 z-50`}>
        
        {/* Search Bar */}
        <div className="flex-1 max-w-3xl">
          <div className={`flex items-center rounded-lg transition-all duration-200 ${
            isDarkMode 
              ? 'bg-gray-800 border border-gray-700/60' 
              : 'bg-gray-100 border border-gray-200/60'
            }`}
          >
            <div className="flex items-center pl-3 pr-2">
              <Search className={`w-4 h-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
            </div>
            <input
              ref={inputRef}
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search by ticker, company name, or industry..."
              className={`w-full py-2 px-2 text-sm rounded-lg ${
                isDarkMode ? 'bg-transparent text-white placeholder-gray-400' : 'bg-transparent text-gray-800 placeholder-gray-400'
              } focus:outline-none focus:ring-0`}
            />
            
            {(query || isLoading) && (
              <div className="flex items-center pr-3">
                {isLoading ? (
                  <LoadingSpinner size="sm" />
                ) : (
                  <motion.button 
                    onClick={() => setQuery('')}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    className={`p-1 rounded-full ${
                      isDarkMode ? 'hover:bg-gray-700 text-gray-400' : 'hover:bg-gray-200 text-gray-500'
                    }`}
                  >
                    <X className="w-4 h-4" />
                  </motion.button>
                )}
              </div>
            )}
          </div>
        </div>
        
        {/* Quick Action Buttons - NEW */}
        <div className="hidden md:flex items-center space-x-2 mx-4">
          <motion.button
            whileHover={{ y: -2 }}
            whileTap={{ y: 0 }}
            onClick={() => navigate('/dashboard/market-chat')}
            className={`py-1.5 px-3 rounded-lg border flex items-center space-x-1.5 ${
              isDarkMode 
                ? 'bg-gray-800 hover:bg-gray-700 border-gray-700 text-gray-300' 
                : 'bg-white hover:bg-gray-50 border-gray-200 text-gray-700'
            }`}
          >
            <Box className={`w-4 h-4 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            <span className="text-sm">Prism</span>
          </motion.button>
          
          <motion.button
            whileHover={{ y: -2 }}
            whileTap={{ y: 0 }}
            onClick={() => navigate('/dashboard/watchlists')}
            className={`py-1.5 px-3 rounded-lg border flex items-center space-x-1.5 ${
              isDarkMode 
                ? 'bg-gray-800 hover:bg-gray-700 border-gray-700 text-gray-300' 
                : 'bg-white hover:bg-gray-50 border-gray-200 text-gray-700'
            }`}
          >
            <Plus className={`w-4 h-4 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
            <span className="text-sm">New Watchlist</span>
          </motion.button>
        </div>
        
        {/* Filter & View Controls */}
        <div className="flex items-center space-x-2">
          <motion.button 
            onClick={() => setShowFilters(!showFilters)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={`p-2 rounded-lg ${
              isDarkMode 
                ? 'hover:bg-gray-800 text-gray-300' 
                : 'hover:bg-gray-100 text-gray-600'
              } ${showFilters ? 'bg-blue-500/20 text-blue-400' : ''}`}
          >
            <Filter className="w-5 h-5" />
          </motion.button>
          
          <div className={`flex items-center rounded-md overflow-hidden ${
            isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
          }`}>
            <motion.button 
              onClick={() => setViewMode(ViewMode.Grid)}
              whileHover={{ scale: viewMode === ViewMode.Grid ? 1 : 1.05 }}
              whileTap={{ scale: viewMode === ViewMode.Grid ? 1 : 0.95 }}
              className={`p-2 ${
                viewMode === ViewMode.Grid 
                  ? isDarkMode 
                    ? 'bg-gray-700 text-white' 
                    : 'bg-gray-200 text-gray-800'
                  : isDarkMode 
                    ? 'text-gray-400' 
                    : 'text-gray-500'
              }`}
            >
              <Grid className="w-4 h-4" />
            </motion.button>
            <motion.button 
              onClick={() => setViewMode(ViewMode.List)}
              whileHover={{ scale: viewMode === ViewMode.List ? 1 : 1.05 }}
              whileTap={{ scale: viewMode === ViewMode.List ? 1 : 0.95 }}
              className={`p-2 ${
                viewMode === ViewMode.List 
                  ? isDarkMode 
                    ? 'bg-gray-700 text-white' 
                    : 'bg-gray-200 text-gray-800'
                  : isDarkMode 
                    ? 'text-gray-400' 
                    : 'text-gray-500'
              }`}
            >
              <List className="w-4 h-4" />
            </motion.button>
          </div>
        </div>
      </div>

      {/* Mobile Quick Action Buttons - For small screens */}
      

      {/* Filters Panel */}
      <AnimatePresence>
        {showFilters && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className={`border-b overflow-hidden ${
              isDarkMode ? 'bg-gray-800/90 border-gray-700' : 'bg-white/90 border-gray-200'
            } backdrop-blur-sm`}
          >
            <div className="p-3">
              <div className="flex items-center justify-between mb-2">
                <h3 className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  Filter by Category
                </h3>
              </div>
              <div className="flex flex-wrap gap-2">
                {researchModes.map(mode => (
                  <motion.button
                    key={mode.id}
                    onClick={() => setSelectedMode(mode)}
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.97 }}
                    className={`flex items-center space-x-2 px-3 py-1.5 rounded-lg text-sm ${
                      selectedMode.id === mode.id
                        ? isDarkMode 
                          ? 'bg-blue-500/20 text-blue-400 border border-blue-500/30' 
                          : 'bg-blue-50 text-blue-600 border border-blue-200'
                        : isDarkMode
                          ? 'bg-gray-700/50 text-gray-300 border border-gray-700' 
                          : 'bg-gray-100/50 text-gray-700 border border-gray-200'
                    }`}
                  >
                    <mode.icon className="w-3.5 h-3.5" />
                    <span>{mode.name}</span>
                  </motion.button>
                ))}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Main Content Area */}
      <div className="flex-1 overflow-auto p-4">
        {/* Search Results */}
        <AnimatePresence mode="wait">
          {query && results.length > 0 && (
            <motion.div
              key={`search-results-${viewMode}`} // Add unique key based on view mode
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3 }}
              className="mb-6"
            >
              <div className="flex items-center justify-between mb-4">
                <h2 className={`text-lg font-medium flex items-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  <Sparkles className={`w-5 h-5 mr-2 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
                  Results
                  <span className={`ml-2 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    {results.length} matches found
                  </span>
                </h2>
              </div>

              {viewMode === ViewMode.Grid ? (
                <motion.div 
                  key="grid-view"
                  className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  {results.map(result => (
                    <motion.div key={result.ticker}>
                      {renderStockCard(result)}
                    </motion.div>
                  ))}
                </motion.div>
              ) : (
                <motion.div 
                  key="list-view"
                  className={`rounded-xl border overflow-hidden ${
                    isDarkMode ? 'bg-gray-900/50 border-gray-800' : 'bg-white/50 border-gray-200'
                  }`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  {results.map(result => (
                    <motion.div key={result.ticker}>
                      {renderStockRow(result)}
                    </motion.div>
                  ))}
                </motion.div>
              )}
            </motion.div>
          )}
        </AnimatePresence>

        {/* No Results Message */}
        <AnimatePresence>
          {query && results.length === 0 && !isLoading && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3 }}
              className={`p-8 text-center rounded-xl border mb-6 ${
                isDarkMode ? 'bg-gray-900/50 border-gray-800' : 'bg-white/50 border-gray-200'
              }`}
            >
              <p className={`text-lg mb-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                No results found for "{query}"
              </p>
              <p className="text-sm opacity-70">
                Try searching for a different ticker symbol or company name
              </p>
            </motion.div>
          )}
        </AnimatePresence>

        {/* When no search query, show recent and trending */}
        <AnimatePresence>
          {!query && (
            <div className="space-y-8">
              {/* Trending Stocks - Now full width */}
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className={`w-full rounded-xl border overflow-hidden ${
                  isDarkMode ? 'bg-gray-900/50 border-gray-800' : 'bg-white/50 border-gray-200'
                }`}
              >
                <div className={`flex items-center justify-between px-4 py-3 border-b
                  ${isDarkMode ? 'border-gray-800' : 'border-gray-200'}`}>
                  <div className="flex items-center">
                    <TrendingUp className={`w-5 h-5 mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                    <h2 className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      Trending Stocks
                    </h2>
                  </div>
                  <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Market movers catching attention today
                  </span>
                </div>

                <div className="p-4">
                  {trendingStocks.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                      {trendingStocks.map((stock, index) => (
                        <motion.div
                          key={index}
                          whileHover={{ 
                            y: -4, 
                            boxShadow: isDarkMode 
                              ? '0 10px 25px -5px rgba(0, 0, 0, 0.3)' 
                              : '0 10px 25px -5px rgba(0, 0, 0, 0.1)' 
                          }}
                          transition={{ type: "spring", stiffness: 400, damping: 15 }}
                          onClick={() => handleResultClick(stock.ticker)}
                          className={`cursor-pointer rounded-xl overflow-hidden border flex flex-col ${
                            isDarkMode 
                              ? 'bg-gray-800 border-gray-700/60 hover:border-green-500/40' 
                              : 'bg-white border-gray-200/60 hover:border-green-500/40'
                          }`}
                        >
                          <div className="p-4 flex-1 flex flex-col">
                            {/* Header with logo and basic info */}
                            <div className="flex items-center mb-3">
                              <div className={`w-12 h-12 rounded-full flex items-center justify-center overflow-hidden
                                ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'} border ${
                                  isDarkMode ? 'border-gray-600/40' : 'border-gray-200/60'}`}
                              >
                                <img
                                  src={`/api/market/company-logo/${stock.ticker}`}
                                  alt=""
                                  className="w-12 h-12 object-contain"
                                  onError={(e) => {
                                    e.currentTarget.style.display = 'none';
                                    if (e.currentTarget.parentElement) {
                                      e.currentTarget.parentElement.innerHTML = `
                                        <div class="text-xl font-bold ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}">
                                          ${stock.ticker.substring(0, 2)}
                                        </div>
                                      `;
                                    }
                                  }}
                                />
                              </div>
                              <div className="ml-3 flex-1">
                                <div className="flex items-center justify-between">
                                  <span className={`font-mono font-bold text-base ${
                                    isDarkMode ? 'text-white' : 'text-gray-900'
                                  }`}>{stock.ticker}</span>
                                  {stock.priceLoaded && (
                                    <div className={`px-2 py-1 rounded-lg flex items-center ${
                                      (stock.changePercent ?? 0) >= 0 
                                        ? isDarkMode ? 'bg-green-500/10 text-green-400' : 'bg-green-50 text-green-600'
                                        : isDarkMode ? 'bg-red-500/10 text-red-400' : 'bg-red-50 text-red-600'
                                    }`}>
                                      {(stock.changePercent ?? 0) >= 0 
                                        ? <TrendingUp className="w-3 h-3 mr-1" />
                                        : <TrendingDown className="w-3 h-3 mr-1" />
                                      }
                                      <span className="text-xs font-medium">
                                        {Math.abs(stock.changePercent ?? 0).toFixed(2)}%
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className={`text-sm truncate ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                                  {stock.name}
                                </div>
                              </div>
                            </div>

                            {/* Sparkline with background */}
                            <div className="w-full h-24 my-3 relative rounded-lg overflow-hidden">
                              <div className={`absolute inset-0 ${
                                isDarkMode ? 'bg-gray-700/30' : 'bg-gray-100/70'
                              } rounded-lg`}></div>
                              <StockSparkline
                                ticker={stock.ticker}
                                timeframe="1d"
                                height={96}
                                width={400}
                              />
                            </div>

                            {/* Price info */}
                            {stock.priceLoaded && (
                              <div className="flex justify-between items-center mb-3">
                                <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>Current Price</span>
                                <span className={`font-mono text-lg font-medium ${
                                  isDarkMode ? 'text-white' : 'text-gray-900'
                                }`}>${stock.price?.toFixed(2)}</span>
                              </div>
                            )}

                            {/* Reason why it's trending */}
                            <div className="mt-auto">
                              <h4 className={`text-xs uppercase font-medium mb-1 ${
                                isDarkMode ? 'text-gray-400' : 'text-gray-500'
                              }`}>Why it's trending</h4>
                              <p className={`text-sm line-clamp-2 min-h-[40px] ${
                                isDarkMode ? 'text-gray-200' : 'text-gray-800'
                              }`}>
                                {stock.reason}
                              </p>
                            </div>
                          </div>

                          {/* Action buttons */}
                          <div className={`mt-auto grid grid-cols-3 divide-x ${
                            isDarkMode ? 'divide-gray-700 border-t border-gray-700' : 'divide-gray-200 border-t border-gray-200'
                          }`}>
                            <motion.button 
                              whileHover={{ backgroundColor: isDarkMode ? 'rgba(55, 65, 81, 0.3)' : 'rgba(243, 244, 246, 0.8)' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleResultClick(stock.ticker);
                              }}
                              className={`py-2.5 flex items-center justify-center space-x-1 ${
                                isDarkMode ? 'text-gray-300' : 'text-gray-700'
                              }`}
                              title="View Details"
                            >
                              <ArrowUpRight className={`w-4 h-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`} />
                              <span className="text-xs">Details</span>
                            </motion.button>
                            
                            <motion.button 
                              whileHover={{ backgroundColor: isDarkMode ? 'rgba(55, 65, 81, 0.3)' : 'rgba(243, 244, 246, 0.8)' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate('/dashboard/market-chat', { 
                                  state: { initialPrompt: `Analyze ${stock.ticker} stock` }
                                });
                              }}
                              className={`py-2.5 flex items-center justify-center space-x-1 ${
                                isDarkMode ? 'text-gray-300' : 'text-gray-700'
                              }`}
                              title="Analyze in Chat"
                            >
                              <Brain className={`w-4 h-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`} />
                              <span className="text-xs">Analyze</span>
                            </motion.button>
                            
                            <div onClick={(e) => e.stopPropagation()} className="flex items-center justify-center">
                              <SaveItemButton
                                type="STOCK"
                                identifier={stock.ticker}
                                title={stock.name}
                                variant="icon"
                              />
                            </div>
                          </div>
                        </motion.div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center py-8">
                      <p className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        <LoadingSpinner size="md" />
                        <span className="ml-2">Loading trending stocks...</span>
                      </p>
                    </div>
                  )}
                </div>
              </motion.div>

              {/* Recent Searches - Now below trending and full width */}
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.2 }}
                className={`w-full rounded-xl border overflow-hidden ${
                  isDarkMode ? 'bg-gray-900/50 border-gray-800' : 'bg-white/50 border-gray-200'
                }`}
              >
                <div className="flex items-center justify-between px-4 py-3 border-b
                  ${isDarkMode ? 'border-gray-800' : 'border-gray-200'}">
                  <div className="flex items-center">
                    <History className={`w-5 h-5 mr-2 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
                    <h2 className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      Recent Searches
                    </h2>
                  </div>
                  {recentSearches.length > 0 && (
                    <motion.button 
                      onClick={clearRecentSearches}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className={`px-2 py-1 rounded-lg text-xs ${
                        isDarkMode 
                          ? 'text-gray-400 hover:text-gray-300 hover:bg-gray-800' 
                          : 'text-gray-600 hover:text-gray-800 hover:bg-gray-100'
                      }`}
                    >
                      Clear History
                    </motion.button>
                  )}
                </div>
                <div className="p-4">
                  {recentSearches.length > 0 ? (
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-3">
                      {recentSearches.map((ticker, index) => (
                        <motion.button
                          key={index}
                          whileHover={{ 
                            y: -2,
                            backgroundColor: isDarkMode ? 'rgba(55, 65, 81, 0.5)' : 'rgba(243, 244, 246, 0.8)',
                          }}
                          onClick={() => handleResultClick(ticker)}
                          className={`flex flex-col items-center py-3 px-4 rounded-lg transition-all ${
                            isDarkMode ? 'bg-gray-800 hover:border-blue-500/30' : 'bg-gray-50 hover:border-blue-500/30'
                          } border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}
                        >
                          <div className={`w-10 h-10 rounded-full flex items-center justify-center mb-2 ${
                            isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
                          }`}>
                            <Building2 className={`w-5 h-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                          </div>
                          <span className={`font-mono text-sm font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
                            {ticker}
                          </span>
                        </motion.button>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center py-8">
                      <p className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        Your recent searches will appear here
                      </p>
                    </div>
                  )}
                </div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

// Add these functions to StockSearchPage.tsx
const formatNumber = (num: number): string => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1) + 'B';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  }
  return num.toString();
};

const formatMarketCap = (marketCap: number): string => {
  if (marketCap >= 1000000000000) {
    return (marketCap / 1000000000000).toFixed(1) + 'T';
  }
  if (marketCap >= 1000000000) {
    return (marketCap / 1000000000).toFixed(1) + 'B';
  }
  if (marketCap >= 1000000) {
    return (marketCap / 1000000).toFixed(1) + 'M';
  }
  return marketCap.toString();
};

export default StockSearchPage;

