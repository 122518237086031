import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { DocumentationLayout } from '../../components/layout/DocumentationLayout';
import { 
  Download, 
  FileText, 
  Share2, 
  Mail,
  Printer,
  Image,
  BookOpen,
  Database
} from 'lucide-react';
import { Link } from 'react-router-dom';

const ExportResearch = () => {
  const { isDarkMode } = useTheme();

  return (
    <DocumentationLayout
      title="Exporting Research Results"
      icon={Download}
      description="Learn how to save, export, and share your Prism AI research findings"
    >
      <div className={`prose max-w-none ${isDarkMode ? 'prose-invert' : ''}`}>
        <h2>Exporting Your Research</h2>
        <p>
          Newsroom makes it easy to export your research conversations, charts, and findings 
          in multiple formats for reference, sharing, or record-keeping.
        </p>

        <div className={`p-6 rounded-xl border my-8 ${
          isDarkMode ? 'bg-gray-800/80 border-gray-700' : 'bg-gray-50 border-gray-200'
        }`}>
          <h3 className="mt-0">Available Export Options</h3>
          <ul>
            <li><strong>PDF Reports:</strong> Comprehensive formatted documents with all research content</li>
            <li><strong>Image Exports:</strong> High-resolution images of charts and visualizations</li>
            <li><strong>Data Files:</strong> Raw data in CSV or JSON formats for further analysis</li>
            <li><strong>Text Export:</strong> Plain text versions of conversation transcripts</li>
          </ul>
        </div>

        <h2>Exporting Chat Conversations</h2>
        <p>
          To export a complete chat conversation:
        </p>
        <ol>
          <li>Navigate to the chat conversation you want to export</li>
          <li>Click the <strong>Download</strong> button in the top-right of the chat header</li>
          <li>Select "Export to PDF" from the dropdown menu</li>
          <li>A formatted PDF with your entire conversation will be generated and downloaded</li>
        </ol>

        <h3>PDF Content</h3>
        <p>
          The PDF export includes:
        </p>
        <ul>
          <li>Complete conversation transcript with timestamps</li>
          <li>All charts and visualizations in high resolution</li>
          <li>List of research sources with links (when available)</li>
          <li>Summary of key findings (for conversations with research summaries)</li>
        </ul>

        <h2>Exporting Visualizations</h2>
        <p>
          To export individual charts or visualizations:
        </p>
        <ol>
          <li>Hover over any chart in your conversation</li>
          <li>Click the <strong>Export</strong> icon that appears in the top-right corner</li>
          <li>Choose your preferred format (PNG, SVG, or CSV data)</li>
        </ol>

        <h2>Data Exports</h2>
        <p>
          For advanced analysis, you can export underlying data:
        </p>
        <ul>
          <li><strong>Chart Data:</strong> Export the data behind any visualization in CSV format</li>
          <li><strong>Research Sources:</strong> Export a complete list of sources with metadata as JSON</li>
          <li><strong>Market Data:</strong> Export time series data used in your research</li>
        </ul>

        <h2>Canvas Exports</h2>
        <p>
          When working with the Canvas feature, you have additional export options:
        </p>
        <ul>
          <li><strong>Full Canvas:</strong> Export the entire canvas as a high-resolution image</li>
          <li><strong>Selected Area:</strong> Export only a portion of your canvas</li>
          <li><strong>Interactive HTML:</strong> Export an interactive version of your canvas (Pro users)</li>
        </ul>

        <h2>Sharing Your Research</h2>
        <p>
          Newsroom provides several ways to share your research with others:
        </p>
        <ul>
          <li><strong>Direct Link:</strong> Generate a shareable link to your conversation (Pro users)</li>
          <li><strong>Email:</strong> Send your research directly via email with optional comments</li>
          <li><strong>Team Sharing:</strong> Share with team members in your organization</li>
        </ul>

        <h2>Account Data Export</h2>
        <p>
          You can also export your complete account data:
        </p>
        <ol>
          <li>Go to <strong>Settings</strong> &gt; <strong>Privacy</strong></li>
          <li>Scroll to the <strong>Export Data</strong> section</li>
          <li>Click "Export Data" to download a complete archive of your conversations and saved items</li>
        </ol>

        <p>
          Your comprehensive data export includes all conversations, saved watchlists, custom settings, 
          and preferences in JSON format.
        </p>
      </div>
    </DocumentationLayout>
  );
};

export default ExportResearch;