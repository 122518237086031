import React, { useState, useEffect } from 'react';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTheme } from '../../contexts/ThemeContext';
import { api } from '../../services/api';
import { subscriptionService } from '../../services/subscriptionService';
import { AlertCircle, CheckCircle2, Loader2 } from 'lucide-react';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

interface PaymentFormProps {
  clientSecret: string;
  onSuccess: () => void;
  onError: (error: string) => void;
}

// Update the props interface
interface StripePaymentProps {
  planId?: string; // For subscriptions
  packageId?: string; // For one-time purchases
  amount: number;
  onSuccess: () => void;
  onError: (error: string) => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ clientSecret, onSuccess, onError }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const { isDarkMode } = useTheme();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required'
      });

      if (error) {
        onError(error.message || 'Payment failed');
      } else if (paymentIntent.status === 'succeeded') {
        onSuccess();
      }
    } catch (err) {
      onError('An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        type="submit"
        disabled={!stripe || isLoading}
        className={`mt-4 w-full flex items-center justify-center px-4 py-2 rounded-lg ${
          isDarkMode 
            ? 'bg-blue-600 text-white hover:bg-blue-700' 
            : 'bg-blue-600 text-white hover:bg-blue-700'
        } disabled:opacity-50 disabled:cursor-not-allowed transition-colors`}
      >
        {isLoading ? (
          <>
            <Loader2 className="w-4 h-4 mr-2 animate-spin" />
            Processing...
          </>
        ) : (
          'Pay now'
        )}
      </button>
    </form>
  );
};

// Update the component implementation to handle both cases
export const StripePayment: React.FC<StripePaymentProps> = ({
  planId,
  packageId,
  amount,
  onSuccess,
  onError
}) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        let response;
        if (planId) {
          // Handle subscription
          response = await api.post('/stripe/create-payment-intent', {
            planId
          });
        } else if (packageId) {
          // Handle one-time credit package purchase
          response = await api.post('/stripe/create-payment-intent', {
            packageId
          });
        } else {
          throw new Error('Either planId or packageId must be provided');
        }
        
        setClientSecret(response.data.clientSecret);
      } catch (err) {
        onError('Failed to initialize payment');
      }
    };

    createPaymentIntent();
  }, [planId, packageId, onError]);

  if (!clientSecret) {
    return (
      <div className="flex justify-center p-4">
        <Loader2 className="w-6 h-6 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <div className={`p-6 rounded-xl ${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      }`}>
        <h3 className={`text-lg font-medium mb-4 ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Complete Purchase
        </h3>
        <PaymentForm
          clientSecret={clientSecret}
          onSuccess={onSuccess}
          onError={onError}
        />
      </div>
    </Elements>
  );
};

export const SubscriptionPayment: React.FC<{
  interval: 'month' | 'year';
  onSuccess: () => void;
  onError: (error: string) => void;
}> = ({ interval, onSuccess, onError }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const createSubscription = async () => {
      try {
        setIsLoading(true);
        const response = await subscriptionService.createCheckoutSession(interval);
        
        // Redirect to the Stripe checkout URL
         if (response.url) {
      window.location.href = response.url;
    } else {
      onError('Unable to start checkout process');
    }
  } catch (err) {
    onError('Failed to initialize subscription, please log in or sign up.');
    setIsLoading(false);
  }
};

    createSubscription();
  }, [interval, onError]);

  return (
    <div className="flex flex-col items-center justify-center p-4">
      {isLoading && (
        <>
          <Loader2 className="w-8 h-8 animate-spin text-blue-500 mb-4" />
          <p className={`text-center ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            Preparing your checkout session...
          </p>
        </>
      )}
    </div>
  );
};