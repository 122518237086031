import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import { 
  ArrowLeft, 
  Lock, 
  Eye, 
  EyeOff, 
  CheckCircle2, 
  AlertCircle, 
  Loader2, 
  CheckCircle 
} from 'lucide-react';
import { authService } from '../../services/authService';

// Password strength type
interface PasswordStrength {
  score: number;
  hasLength: boolean;
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasNumber: boolean;
  hasSpecial: boolean;
}

// Password strength initial state
const initialStrength: PasswordStrength = {
  score: 0,
  hasLength: false,
  hasUpperCase: false,
  hasLowerCase: false,
  hasNumber: false,
  hasSpecial: false
};

// Header component
const AuthHeader: React.FC = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <nav className="container mx-auto px-6 py-4">
      <div className="flex items-center mt-16 justify-between">
        <Link to="/" className="flex items-center space-x-2">
          <ArrowLeft className={isDarkMode ? 'text-white' : 'text-gray-900'} size={20} />
          <p className={`text-sm font-bold ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Back to home
          </p>
        </Link>
      </div>
    </nav>
  );
};

export const ResetPassword: React.FC = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);
  const [tokenChecked, setTokenChecked] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [strength, setStrength] = useState<PasswordStrength>(initialStrength);
  
  // Validate the token when component mounts
  useEffect(() => {
    const validateToken = async () => {
      try {
        if (!token) {
          setIsTokenValid(false);
          return;
        }
        
        const isValid = await authService.validateResetToken(token);
        setIsTokenValid(isValid);
      } catch (err) {
        setIsTokenValid(false);
      } finally {
        setTokenChecked(true);
      }
    };
    
    validateToken();
  }, [token]);
  
  // Password strength validator
  const validatePassword = (password: string): boolean => {
    const newStrength = {
      score: 0,
      hasLength: password.length >= 8,
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasNumber: /\d/.test(password),
      hasSpecial: /[!@#$%^&*(),.?":{}|<>]/.test(password)
    };
    
    let score = 0;
    if (newStrength.hasLength) score++;
    if (newStrength.hasUpperCase) score++;
    if (newStrength.hasLowerCase) score++;
    if (newStrength.hasNumber) score++;
    if (newStrength.hasSpecial) score++;
    
    newStrength.score = score;
    setStrength(newStrength);
    
    return score >= 4;
  };
  
  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validatePassword(password)) {
      setError('Password does not meet requirements');
      return;
    }
    
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    
    setIsSubmitting(true);
    setError(null);
    
    try {
      if (!token) throw new Error('Reset token is missing');
      await authService.resetPassword(token, password);
      setSuccess(true);
      
      // Redirect to login after 3 seconds
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to reset password');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  // Password strength indicator component
  const renderPasswordStrength = () => (
    <div className="mt-2 space-y-2">
      <div className="flex gap-2">
        {[1, 2, 3, 4, 5].map((index) => (
          <div
            key={index}
            className={`h-2 w-full rounded ${
              index <= strength.score
                ? strength.score >= 4
                  ? 'bg-green-500'
                  : strength.score >= 3
                  ? 'bg-yellow-500'
                  : 'bg-red-500'
                : isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
            }`}
          />
        ))}
      </div>
      <div className="grid grid-cols-2 gap-2 text-sm">
        <div className="flex items-center gap-1">
          <CheckCircle2 className={`h-4 w-4 ${strength.hasLength ? 'text-green-500' : 'text-gray-400'}`} />
          <span className={strength.hasLength ? 'text-green-500' : isDarkMode ? 'text-gray-500' : 'text-gray-500'}>8+ characters</span>
        </div>
        <div className="flex items-center gap-1">
          <CheckCircle2 className={`h-4 w-4 ${strength.hasUpperCase ? 'text-green-500' : 'text-gray-400'}`} />
          <span className={strength.hasUpperCase ? 'text-green-500' : isDarkMode ? 'text-gray-500' : 'text-gray-500'}>Uppercase</span>
        </div>
        <div className="flex items-center gap-1">
          <CheckCircle2 className={`h-4 w-4 ${strength.hasLowerCase ? 'text-green-500' : 'text-gray-400'}`} />
          <span className={strength.hasLowerCase ? 'text-green-500' : isDarkMode ? 'text-gray-500' : 'text-gray-500'}>Lowercase</span>
        </div>
        <div className="flex items-center gap-1">
          <CheckCircle2 className={`h-4 w-4 ${strength.hasNumber ? 'text-green-500' : 'text-gray-400'}`} />
          <span className={strength.hasNumber ? 'text-green-500' : isDarkMode ? 'text-gray-500' : 'text-gray-500'}>Number</span>
        </div>
      </div>
    </div>
  );
  
  // Show loading state until token is checked
  if (!tokenChecked) {
    return (
      <div className={isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}>
        <AuthHeader />
        <div className="flex min-h-screen">
          <div className="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:px-20">
            <div className="w-full max-w-md mx-auto text-center">
              <Loader2 className="h-8 w-8 animate-spin mx-auto mb-4 text-blue-500" />
              <p>Validating your reset token...</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  // Show error for invalid token
  if (!isTokenValid) {
    return (
      <div className={isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}>
        <AuthHeader />
        <div className="flex min-h-screen">
          <div className="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:px-20">
            <div className="w-full max-w-md mx-auto">
              <div className="p-4 rounded-lg border bg-red-50 border-red-100">
                <div className="flex items-center space-x-3 text-red-700">
                  <AlertCircle className="h-5 w-5" />
                  <div>
                    <p className="font-medium">Invalid or expired reset link</p>
                    <p className="text-sm mt-1">Please request a new password reset link.</p>
                  </div>
                </div>
              </div>
              <div className="mt-6 text-center">
                <Link 
                  to="/forgot-password" 
                  className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-600"
                >
                  Request New Link
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className={isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}>
      <AuthHeader />
      
      <div className="flex min-h-screen">
        <div className="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:px-20">
          <div className="w-full max-w-md mx-auto">
            <div className="text-center">
              <div className="w-[150px] h-[150px] mb-[1rem] mx-auto">
                <img 
                  src={isDarkMode ? '/logo-light.png' : '/logo-dark.png'}
                  alt="Newsroom Logo"
                  className="w-full h-full object-contain" 
                />
              </div>
              <h2 className={`text-2xl font-bold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Create a new password
              </h2>
              <p className={`text-center text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              } mt-2`}>
                Please create a strong password for your account
              </p>
            </div>

            {success ? (
              <div className="mt-8 p-4 rounded-lg border bg-green-50 border-green-100">
                <div className="flex items-center space-x-3 text-green-700">
                  <CheckCircle className="h-5 w-5" />
                  <div>
                    <p className="font-medium">Password reset successful!</p>
                    <p className="text-sm mt-1">Redirecting you to login page...</p>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {error && (
                  <div className="mt-6 p-3 rounded-lg bg-red-50 border border-red-200 text-red-600 flex items-center space-x-2">
                    <AlertCircle className="w-5 h-5" />
                    <span className="text-sm">{error}</span>
                  </div>
                )}

                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label className={`block text-sm font-medium mb-1.5 ${
                      isDarkMode ? 'text-gray-200' : 'text-gray-700'
                    }`}>
                      New Password
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Lock className={`h-5 w-5 ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-500'
                        }`} />
                      </div>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          validatePassword(e.target.value);
                        }}
                        className={`block w-full pl-10 pr-10 py-2 rounded-lg border ${
                          isDarkMode
                            ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                            : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                        } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                        placeholder="••••••••"
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center"
                      >
                        {showPassword ? (
                          <EyeOff className="h-5 w-5 text-gray-400" />
                        ) : (
                          <Eye className="h-5 w-5 text-gray-400" />
                        )}
                      </button>
                    </div>
                    {renderPasswordStrength()}
                  </div>

                  <div>
                    <label className={`block text-sm font-medium mb-1.5 ${
                      isDarkMode ? 'text-gray-200' : 'text-gray-700'
                    }`}>
                      Confirm Password
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Lock className={`h-5 w-5 ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-500'
                        }`} />
                      </div>
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className={`block w-full pl-10 pr-10 py-2 rounded-lg border ${
                          isDarkMode
                            ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                            : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                        } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                        placeholder="••••••••"
                      />
                      <button
                        type="button"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center"
                      >
                        {showConfirmPassword ? (
                          <EyeOff className="h-5 w-5 text-gray-400" />
                        ) : (
                          <Eye className="h-5 w-5 text-gray-400" />
                        )}
                      </button>
                    </div>
                    {password && confirmPassword && password !== confirmPassword && (
                      <p className="mt-1.5 text-sm text-red-500">Passwords do not match</p>
                    )}
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full flex justify-center py-3 px-4 rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                  >
                    {isSubmitting ? (
                      <div className="flex items-center">
                        <Loader2 className="w-5 h-5 animate-spin mr-2" />
                        <span>Resetting...</span>
                      </div>
                    ) : (
                      'Reset Password'
                    )}
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;