import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Upload, 
  FileText, 
  CheckCircle, 
  AlertTriangle, 
  Clock,
  Lock,
  FileUp,
  FileX,
  PieChart,
  Lightbulb,
  BarChart4
} from 'lucide-react';

const FileUploads = () => {
  const { isDarkMode } = useTheme();

  const supportedFileTypes = [
    {
      type: 'PDF',
      description: 'Research reports, financial statements, prospectuses',
      maxSize: '10MB',
      icon: FileText
    },
    {
      type: 'CSV',
      description: 'Financial data, stock prices, metrics',
      maxSize: '5MB',
      icon: PieChart
    },
    {
      type: 'Excel (.xlsx)',
      description: 'Spreadsheets with financial models, DCF analyses',
      maxSize: '10MB',
      icon: BarChart4
    }
  ];

  const uploadSteps = [
    'Click the paperclip icon in the chat input area',
    'Select one or more files from your device',
    'Wait for the upload confirmation',
    'Ask questions about your uploaded document'
  ];

  return (
    <MainLayout>
      <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Upload className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                File Uploads
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Analyze financial documents, research reports, and data files with Prism AI
              </p>
            </div>
          </div>
        </div>

        {/* Introduction */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className={`p-8 rounded-xl border ${
            isDarkMode ? 'bg-gray-800/50 border-gray-700' : 'bg-white border-gray-200'
          } mb-12`}>
            <h2 className={`text-2xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Understanding File Uploads
            </h2>
            <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Prism AI can analyze and extract insights from your financial documents, research reports, and data files. Upload your documents directly to a chat session, and Prism will process the content, allowing you to ask specific questions about the information contained within.
            </p>
            <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              This feature is particularly useful for analyzing quarterly reports, broker research, financial models, or any other document containing market information that you'd like to understand better.
            </p>
          </div>

          {/* Supported File Types */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Supported File Types
          </h2>
          <div className="grid md:grid-cols-3 gap-6 mb-16">
            {supportedFileTypes.map((fileType, index) => {
              const Icon = fileType.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-3 rounded-lg ${
                      isDarkMode ? 'bg-blue-900/20' : 'bg-blue-50'
                    } mr-4`}>
                      <Icon className={`w-6 h-6 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <h3 className={`text-xl font-bold ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {fileType.type}
                    </h3>
                  </div>
                  <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    {fileType.description}
                  </p>
                  <div className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    Max size: {fileType.maxSize}
                  </div>
                </div>
              );
            })}
          </div>

          {/* How to Upload Files */}
          <div className={`p-8 rounded-xl border ${
            isDarkMode ? 'bg-gray-800/50 border-gray-700' : 'bg-white border-gray-200'
          } mb-12`}>
            <h2 className={`text-2xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              How to Upload Files
            </h2>
            <div className="grid md:grid-cols-2 gap-8">
              <div>
                <ol className="space-y-6">
                  {uploadSteps.map((step, index) => (
                    <li key={index} className="flex items-start">
                      <div className={`flex-shrink-0 w-8 h-8 rounded-full ${
                        isDarkMode ? 'bg-blue-900/30 text-blue-400' : 'bg-blue-100 text-blue-600'
                      } flex items-center justify-center mr-4 font-bold`}>
                        {index + 1}
                      </div>
                      <p className={`pt-1 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                        {step}
                      </p>
                    </li>
                  ))}
                </ol>
                <div className={`mt-8 p-4 rounded-lg ${
                  isDarkMode ? 'bg-blue-900/20 border border-blue-800/30' : 'bg-blue-50 border border-blue-100'
                }`}>
                  <Lightbulb className={`w-6 h-6 mb-2 ${
                    isDarkMode ? 'text-blue-400' : 'text-blue-600'
                  }`} />
                  <p className={`text-sm ${isDarkMode ? 'text-blue-300' : 'text-blue-700'}`}>
                    <strong>Pro Tip:</strong> For best results, ask specific questions about your document. For example, instead of "What's in this file?", try "What were the key growth drivers mentioned in Q3 results?" or "Summarize the cash flow projections from this spreadsheet."
                  </p>
                </div>
              </div>
              <div className={`p-6 rounded-xl ${
                isDarkMode ? 'bg-gray-900' : 'bg-gray-100'
              }`}>
                <h3 className={`text-lg font-bold mb-4 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Example Questions to Ask
                </h3>
                <ul className="space-y-3">
                  <li className={`p-2 rounded ${
                    isDarkMode ? 'bg-gray-800' : 'bg-white'
                  } ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    "What are the key risks mentioned in this annual report?"
                  </li>
                  <li className={`p-2 rounded ${
                    isDarkMode ? 'bg-gray-800' : 'bg-white'
                  } ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    "Extract the revenue growth figures for the last 5 quarters from this spreadsheet."
                  </li>
                  <li className={`p-2 rounded ${
                    isDarkMode ? 'bg-gray-800' : 'bg-white'
                  } ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    "Compare the valuation metrics in this report to current market averages."
                  </li>
                  <li className={`p-2 rounded ${
                    isDarkMode ? 'bg-gray-800' : 'bg-white'
                  } ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    "Explain the methodology used in this DCF analysis."
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Privacy & Security */}
          <div className={`p-8 rounded-xl border ${
            isDarkMode ? 'bg-gray-800/50 border-gray-700' : 'bg-white border-gray-200'
          } mb-12`}>
            <div className="flex items-start mb-6">
              <Lock className={`w-8 h-8 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-green-400' : 'text-green-600'
              }`} />
              <div>
                <h2 className={`text-2xl font-bold mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Privacy & Security
                </h2>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  Your uploaded files are handled with the utmost security.
                </p>
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-6">
              <div className={`p-4 rounded-lg ${
                isDarkMode ? 'bg-gray-900/70' : 'bg-gray-100'
              }`}>
                <h3 className={`font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  File Processing
                </h3>
                <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  Files are processed securely on our servers. Content is extracted and analyzed only for the purpose of answering your questions.
                </p>
              </div>
              <div className={`p-4 rounded-lg ${
                isDarkMode ? 'bg-gray-900/70' : 'bg-gray-100'
              }`}>
                <h3 className={`font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Data Retention
                </h3>
                <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  Uploaded files are retained only for the duration of your session and are automatically deleted after 24 hours. Pro users can manage uploaded files in their account settings.
                </p>
              </div>
            </div>
          </div>

          {/* Troubleshooting */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Troubleshooting
          </h2>
          <div className="space-y-6 mb-8">
            <div className={`p-6 rounded-xl border ${
              isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
            }`}>
              <div className="flex items-center mb-4">
                <FileX className={`w-6 h-6 mr-3 ${
                  isDarkMode ? 'text-red-400' : 'text-red-600'
                }`} />
                <h3 className={`font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  File Upload Failed
                </h3>
              </div>
              <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                If your file upload fails, check:
              </p>
              <ul className="list-disc pl-5 space-y-2">
                <li className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  File size is under the limit
                </li>
                <li className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  File type is supported
                </li>
                <li className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  File is not password-protected or encrypted
                </li>
                <li className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  Your internet connection is stable
                </li>
              </ul>
            </div>
            <div className={`p-6 rounded-xl border ${
              isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
            }`}>
              <div className="flex items-center mb-4">
                <AlertTriangle className={`w-6 h-6 mr-3 ${
                  isDarkMode ? 'text-yellow-400' : 'text-yellow-600'
                }`} />
                <h3 className={`font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Analysis Issues
                </h3>
              </div>
              <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                If Prism has trouble analyzing your document:
              </p>
              <ul className="list-disc pl-5 space-y-2">
                <li className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  Ensure the document contains text that can be extracted (not just images)
                </li>
                <li className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  Try asking more specific questions about the document content
                </li>
                <li className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  For complex financial models, specify which sheets or sections to analyze
                </li>
              </ul>
            </div>
          </div>

          {/* Pro Feature Note */}
          <div className={`p-6 rounded-xl border ${
            isDarkMode ? 'bg-purple-900/20 border-purple-800/30' : 'bg-purple-50 border-purple-100'
          }`}>
            <h3 className={`text-lg font-medium mb-2 ${
              isDarkMode ? 'text-purple-300' : 'text-purple-700'
            }`}>
              Pro Feature
            </h3>
            <p className={`${isDarkMode ? 'text-purple-200' : 'text-purple-700'}`}>
              File uploading and analysis is available to all users, but Pro subscribers enjoy higher file size limits (up to 25MB), support for additional file formats, and the ability to save analyzed documents for future reference.
            </p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default FileUploads;