// src/contexts/ChatContext.tsx
import React, { createContext, useContext, useReducer, useEffect, useCallback } from 'react';
import { ChatMessage, ChatSession, ChatContextType, ChatHistoryState } from '../types/chat';
import api from '../services/api';
import logger from '../config/logger';
import { chatService } from '../services/chatService';
import toast from 'react-hot-toast';

const ChatContext = createContext<ChatContextType | null>(null);

type ChatAction = 
  | { type: 'SET_SESSIONS'; payload: ChatSession[] }
  | { type: 'SET_CURRENT_SESSION'; payload: string | null }
  | { type: 'UPDATE_SESSION'; payload: { id: string; messages: ChatMessage[] } }
  | { type: 'DELETE_SESSION'; payload: string }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_ERROR'; payload: string | null };

const chatReducer = (state: ChatHistoryState, action: ChatAction): ChatHistoryState => {
  switch (action.type) {
    case 'SET_SESSIONS':
      return { ...state, sessions: action.payload };
    case 'SET_CURRENT_SESSION':
      return { ...state, currentSessionId: action.payload };
    case 'UPDATE_SESSION':
      return {
        ...state,
        sessions: state.sessions.map(session =>
          session.id === action.payload.id
            ? { ...session, messages: action.payload.messages }
            : session
        )
      };
    case 'DELETE_SESSION':
      return {
        ...state,
        sessions: state.sessions.filter(session => session.id !== action.payload)
      };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(chatReducer, {
    sessions: [],
    currentSessionId: null,
    isLoading: false,
    error: null
  });

  // Remove local storage loading and replace with API call
  useEffect(() => {
    loadSessions();
  }, []);

  // Update to use API
  const loadSessions = async () => {
    const token = localStorage.getItem('token');
  if (!token) {
    console.log('No token found');
    return;
  }
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const data = await api.get<ChatSession[]>('/market-chat/sessions');
      dispatch({ type: 'SET_SESSIONS', payload: data });
    } catch (error) {
      console.error('Failed to load sessions:', error);
      dispatch({ type: 'SET_ERROR', payload: 'Failed to load chat sessions' });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const deleteAllSessions = async () => {
    try {
      await api.delete('/market-chat/sessions');
      dispatch({ type: 'SET_SESSIONS', payload: [] });
      dispatch({ type: 'SET_CURRENT_SESSION', payload: null });
    } catch (error) {
      console.log('Failed to delete all sessions:', error);
      dispatch({ 
        type: 'SET_ERROR', 
        payload: 'Failed to delete all sessions' 
      });
    }
  };

  // Enhance createNewSession for better reliability
  const createNewSession = async (): Promise<ChatSession> => {
  try {
    dispatch({ type: 'SET_LOADING', payload: true });
    
    // Create session in backend
    const session = await chatService.createSession();
    
    if (!session || !session.id) {
      throw new Error('Failed to create session: Invalid response');
    }
    
    // Update local state with new session
    const updatedSessions = [session, ...state.sessions];
    
    // Update state
    dispatch({ type: 'SET_SESSIONS', payload: updatedSessions });
    dispatch({ type: 'SET_CURRENT_SESSION', payload: session.id });
    
    // Return the session for immediate use
    return session;
  } catch (error) {
    console.error('Failed to create session:', error);
    dispatch({ type: 'SET_ERROR', payload: 'Failed to create new chat' });
    throw error; // Re-throw for proper error handling upstream
  } finally {
    dispatch({ type: 'SET_LOADING', payload: false });
  }
};

  const generateSessionTitle = useCallback(async (messages: ChatMessage[]): Promise<string> => {
    // Find the first user message
    const firstUserMessage = messages.find(m => m.role === 'user');
    if (!firstUserMessage) return 'New Chat';
  
    try {
      // Generate title using Claude
      const { response } = await api.post<{ response: string }>('/market-chat/generate-title', {
        messages: messages.slice(0, 2) // Send first exchange only
      });
      
      return response || 'New Chat';
    } catch (error) {
      // Fallback title generation
      const title = firstUserMessage.content
        .split('\n')[0]
        .slice(0, 40);
      return title.length < firstUserMessage.content.length 
        ? `${title}...` 
        : title;
    }
  }, []);
  
  // Improve the updateCurrentSession function
const updateCurrentSession = async (messages: ChatMessage[], sessionId?: string | null, title?: string) => {
  try {
    // Use provided sessionId or fall back to state
    const targetSessionId = sessionId || state.currentSessionId;
    
    // Ensure we have a session ID to work with
    if (!targetSessionId) {
      console.error("No session ID provided to update");
      return;
    }
    
    // Ensure messages is an array
    if (!Array.isArray(messages)) {
      console.error("Invalid messages format:", messages);
      return;
    }
    
    // Deep clone the messages array to preserve chips
    const messagesWithChips = JSON.parse(JSON.stringify(messages));
    
    // Log message state for debugging
    console.log(`Updating session ${targetSessionId} with ${messagesWithChips.length} messages`);
    
    // Update backend
    await chatService.updateSession(targetSessionId, messagesWithChips);
    
    // Update local state
    dispatch({ 
      type: 'UPDATE_SESSION', 
      payload: { 
        id: targetSessionId, 
        messages: messagesWithChips
      } 
    });
    
    // Update title if provided
    if (title && title.trim()) {
      await contextValue.renameSession(targetSessionId, title);
    }
  } catch (error) {
    console.error("Failed to update session:", error);
  }
};

  const extractTickers = (messages: ChatMessage[]): string[] => {
    const tickerRegex = /\b[A-Z]{1,5}\b/g;
    const tickers = new Set<string>();
    
    messages.forEach(message => {
      const matches = message.content.match(tickerRegex);
      if (matches) {
        matches.forEach(ticker => tickers.add(ticker));
      }
    });

    return Array.from(tickers);
  };

  const starSession = async (id: string, isStarred: boolean) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      // Optimistically update UI first for better user experience
      const updatedSessions = state.sessions.map(session =>
        session.id === id ? { ...session, isStarred } : session
      );
      dispatch({ type: 'SET_SESSIONS', payload: updatedSessions });
      
      // Then make the API call
      await api.put(`/market-chat/sessions/${id}/star`, { isStarred });
      
      // No need to dispatch again if successful since we already updated
    } catch (error) {
      console.error('Failed to star/unstar session:', error);
      
      // Revert the optimistic update if the API call failed
      const originalSessions = state.sessions.map(session =>
        session.id === id ? { ...session, isStarred: !isStarred } : session
      );
      dispatch({ type: 'SET_SESSIONS', payload: originalSessions });
      dispatch({ type: 'SET_ERROR', payload: 'Failed to update chat' });
      
      // Show toast notification
      if (typeof window !== 'undefined' && 'toast' in window) {
        toast.error('Failed to update session.');
      }
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const contextValue: ChatContextType = {
    state,
    createNewSession,
    switchSession: async (id) => {
      dispatch({ type: 'SET_CURRENT_SESSION', payload: id });
    },
    updateCurrentSession, // This now accepts an optional sessionId parameter
    deleteSession: async (id: string) => {
      try {
        await api.delete(`/market-chat/sessions/${id}`);
        dispatch({ type: 'DELETE_SESSION', payload: id });
      } catch (error) {
        console.error('Failed to delete session:', error);
        dispatch({ 
          type: 'SET_ERROR', 
          payload: 'Failed to delete session' 
        });
      }
    },
    deleteAllSessions: async () => {
      try {
        await api.delete('/market-chat/sessions');
        dispatch({ type: 'SET_SESSIONS', payload: [] });
        dispatch({ type: 'SET_CURRENT_SESSION', payload: null });
      } catch (error) {
        console.error('Failed to delete all sessions:', error);
        dispatch({ 
          type: 'SET_ERROR', 
          payload: 'Failed to delete all sessions' 
        });
      }
    },
    renameSession: async (id, title) => {
      try {
        await api.put(`/market-chat/sessions/${id}/title`, { title });
        const updatedSessions = state.sessions.map(session =>
          session.id === id ? { ...session, title } : session
        );
        dispatch({ type: 'SET_SESSIONS', payload: updatedSessions });
      } catch (error) {
        console.error('Failed to rename session:', error);
        dispatch({ type: 'SET_ERROR', payload: 'Failed to rename chat' });
      }
    },
    loadSessions,
    starSession
  };

  return (
    <ChatContext.Provider value={contextValue}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
};