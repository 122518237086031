import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { DocumentationLayout } from '../../components/layout/DocumentationLayout';
import { Brain, Zap, Database, LineChart, Target } from 'lucide-react';

const Introduction = () => {
  const { isDarkMode } = useTheme();

  return (
    <DocumentationLayout
      title="Introduction to Prism AI"
      icon={Brain}
      description="Discover how Prism AI transforms market research with real-time data and intelligent analysis"
    >
      <div className={`prose max-w-none ${isDarkMode ? 'prose-invert' : ''}`}>
        <h2>What is Prism AI?</h2>
        <p>
          Prism AI is an intelligent market research assistant that combines the power of advanced AI with real-time financial data to provide
          comprehensive market analysis and insights. Unlike traditional research tools, Prism processes and synthesizes information from
          multiple sources simultaneously, giving you a complete picture of market conditions in seconds.
        </p>

        <div className={`p-6 rounded-xl border my-8 ${
          isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
        }`}>
          <h3 className="mt-0">Key Capabilities</h3>
          <ul>
            <li>Real-time financial data analysis from trusted sources</li>
            <li>Multi-stage research process with transparent progress tracking</li>
            <li>Comprehensive stock analysis including technical and fundamental factors</li>
            <li>Natural language interaction through conversational interface</li>
            <li>Customizable research modes for specialized inquiries</li>
            <li>Exportable research results and visualizations</li>
          </ul>
        </div>

        <h2>How Prism AI Works</h2>
        <p>
          When you ask Prism a question about the markets, it initiates a sophisticated research pipeline:
        </p>

        <ol>
          <li>
            <strong>Query Analysis:</strong> Prism analyzes your question to understand your intent and information needs.
          </li>
          <li>
            <strong>Research Planning:</strong> Based on your query, Prism determines the optimal research strategy and sources.
          </li>
          <li>
            <strong>Data Collection:</strong> Prism gathers real-time data from financial APIs, news sources, and web resources.
          </li>
          <li>
            <strong>Analysis:</strong> All collected information is processed, synthesized, and analyzed for relevance and accuracy.
          </li>
          <li>
            <strong>Response Generation:</strong> Prism creates a comprehensive answer that includes relevant data, insights, and visualizations.
          </li>
        </ol>

        <div className="my-8">
          <h2>Getting the Most from Prism AI</h2>
          <p>
            For optimal results with Prism, consider these best practices:
          </p>

          <div className="grid sm:grid-cols-2 gap-6 mt-6">
            <div className={`p-5 rounded-lg border ${
              isDarkMode ? 'bg-gray-800/50 border-gray-700' : 'bg-gray-50 border-gray-200'
            }`}>
              <div className="flex items-center mb-3">
                <Target className={`w-5 h-5 mr-2 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                <h4 className="m-0">Be Specific</h4>
              </div>
              <p className="m-0 text-sm">
                Targeted queries yield more precise results. Include specific stocks, time periods, or market aspects you're interested in.
              </p>
            </div>

            <div className={`p-5 rounded-lg border ${
              isDarkMode ? 'bg-gray-800/50 border-gray-700' : 'bg-gray-50 border-gray-200'
            }`}>
              <div className="flex items-center mb-3">
                <Zap className={`w-5 h-5 mr-2 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                <h4 className="m-0">Use Research Modes</h4>
              </div>
              <p className="m-0 text-sm">
                Select the appropriate research mode to focus your inquiry on technical analysis, fundamentals, or news.
              </p>
            </div>

            <div className={`p-5 rounded-lg border ${
              isDarkMode ? 'bg-gray-800/50 border-gray-700' : 'bg-gray-50 border-gray-200'
            }`}>
              <div className="flex items-center mb-3">
                <Database className={`w-5 h-5 mr-2 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                <h4 className="m-0">Review Sources</h4>
              </div>
              <p className="m-0 text-sm">
                Check the sources Prism used to understand where information came from and evaluate its reliability.
              </p>
            </div>

            <div className={`p-5 rounded-lg border ${
              isDarkMode ? 'bg-gray-800/50 border-gray-700' : 'bg-gray-50 border-gray-200'
            }`}>
              <div className="flex items-center mb-3">
                <LineChart className={`w-5 h-5 mr-2 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                <h4 className="m-0">Iterate & Refine</h4>
              </div>
              <p className="m-0 text-sm">
                Use follow-up questions to drill deeper into topics or clarify specific aspects of Prism's responses.
              </p>
            </div>
          </div>
        </div>

        <h2>Next Steps</h2>
        <p>
          Now that you understand the basics of Prism AI, explore these resources to make the most of your market research:
        </p>
        <ul>
          <li><a href="/docs/starting-chats">Starting Conversations with Prism</a></li>
          <li><a href="/docs/research-process">Understanding the Research Process</a></li>
          <li><a href="/docs/research-modes">Using Different Research Modes</a></li>
          <li><a href="/docs/chat-chips">Enhanced Queries with Chat Chips</a></li>
        </ul>
      </div>
    </DocumentationLayout>
  );
};

export default Introduction;