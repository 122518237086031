import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  ChevronRight,
  LineChart,
  Eye,
  BarChart2,
  Signal,
  
  FileText,
  SplitSquareVertical,
  Layout,
  Layers,
  AlertCircle
} from 'lucide-react';

const StockView = () => {
  const { isDarkMode } = useTheme();

  const viewOptions = [
    {
      title: 'Basic View',
      description: 'The standard view for quick stock reference and information.',
      features: [
        'Price and change percentage at a glance',
        'Company name and key identifiers',
        'Daily volume indicator',
        'Market cap classification',
        'Simple sparkline price chart'
      ],
      icon: Eye
    },
    {
      title: 'Technical View',
      description: 'Focus on charts and technical indicators for price analysis.',
      features: [
        'Multiple timeframe options (1D, 1W, 1M, YTD, 1Y, 5Y)',
        'Moving averages overlay (50-day, 200-day)',
        'RSI and MACD indicators',
        'Volume analysis bars',
        'Support and resistance level indicators'
      ],
      icon: LineChart
    },
    {
      title: 'Fundamental View',
      description: 'Detailed financial metrics and company fundamentals.',
      features: [
        'P/E ratio, EPS, and growth rates',
        'Dividend information and yield',
        'Revenue and profit margins',
        'Upcoming earnings date indicator',
        'Industry comparisons'
      ],
      icon: BarChart2
    },
    {
      title: 'News View',
      description: 'Latest news, events, and sentiment affecting the stock.',
      features: [
        'Recent company-specific news articles',
        'AI-generated sentiment analysis',
        'Upcoming relevant events',
        'Social media sentiment indicators',
        'Analyst rating changes'
      ],
      icon: FileText
    },
  ];

  const customizationOptions = [
    {
      title: 'Layout Options',
      description: 'Adjust how stock information is arranged on your screen',
      options: [
        'Card view with minimal information',
        'Detailed view with comprehensive metrics',
        'Table view for comparing multiple stocks',
        'Split view combining different information types'
      ],
      icon: Layout
    },
    {
      title: 'Information Density',
      description: 'Control how much information is displayed at once',
      options: [
        'Compact view for maximum stocks in view',
        'Expanded view with more detailed information',
        'Custom field selection for personalized view',
        'Quick-glance mode for essential metrics only'
      ],
      icon: Layers
    },
    {
      title: 'Visual Customization',
      description: 'Adjust the appearance of charts and indicators',
      options: [
        'Custom color schemes for charts and trends',
        'Theme-matched or contrast color options',
        'Adjustable chart size and resolution',
        'Grid options for technical analysis'
      ],
      icon: SplitSquareVertical
    },
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Eye className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Stock View Options
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Customize how you view and analyze your saved stocks
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              Prism offers multiple ways to view and analyze your saved stocks, giving you the flexibility to
              focus on the information that matters most to your investment strategy. Whether you're a technical
              trader, fundamental analyst, or news-driven investor, you can customize your stock view
              to show exactly what you need.
            </p>
          </div>

          {/* View Types */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Available View Types
          </h2>

          <div className="space-y-8 mb-16">
            {viewOptions.map((option, index) => {
              const Icon = option.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    } mr-4`}>
                      <Icon className={`w-6 h-6 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <div>
                      <h3 className={`text-xl font-bold ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {option.title}
                      </h3>
                    </div>
                  </div>
                  
                  <p className={`mb-6 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {option.description}
                  </p>
                  
                  <div className="grid md:grid-cols-2 gap-8">
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Features:</h4>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {option.features.map((feature, i) => (
                          <li key={i} className="flex items-start">
                            <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div className={`rounded-lg overflow-hidden border ${
                      isDarkMode ? 'border-gray-700' : 'border-gray-200'
                    }`}>
                      <div className={`h-48 flex items-center justify-center ${
                        isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                      }`}>
                        <p className={`text-sm ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-500'
                        }`}>
                          Illustration of {option.title.toLowerCase()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Customization Options */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Customization Options
          </h2>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
            {customizationOptions.map((option, index) => {
              const Icon = option.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg mr-4 ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    }`}>
                      <Icon className={`w-5 h-5 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <h3 className={`font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {option.title}
                    </h3>
                  </div>
                  
                  <p className={`mb-4 text-sm ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {option.description}
                  </p>
                  
                  <ul className={`space-y-2 text-sm ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {option.options.map((opt, i) => (
                      <li key={i} className="flex items-start">
                        <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                        <span>{opt}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>

          {/* Tips Section */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="flex items-start">
              <AlertCircle className={`w-6 h-6 mt-1 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Pro Tips for Stock Views</h3>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  Get the most out of your stock viewing experience with these professional tips:
                </p>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Configure different views for different investment strategies or timeframes</span>
                  </li>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Combine technical and fundamental views for a comprehensive analysis</span>
                  </li>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Save custom view configurations for quick switching between analysis methods</span>
                  </li>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Use the news view before market open to prepare for the trading day</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Related Documentation */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Related Documentation
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <a 
              href="/docs/sparklines"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Sparkline Charts
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Learn about the compact price charts in stock views
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
            
            <a
              href="/docs/saved-stocks"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Managing Saved Stocks
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Organizing and working with your stock watchlists
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default StockView;