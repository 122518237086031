import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  ChevronRight,
  PencilRuler,
  Layers,
  Maximize,
  Share2,
  LineChart,
  Zap,
  ArrowUpRight,
  AlertCircle,
  MousePointer,
  Asterisk,
  Move,
  Bookmark,
  Save
} from 'lucide-react';

const CanvasIntro = () => {
  const { isDarkMode } = useTheme();

  const canvasFeatures = [
    {
      title: 'Visual Conversation History',
      description: 'Transform your chat interactions into visual knowledge maps.',
      features: [
        'Automatic organization of conversation threads',
        'Visual linking between related topics',
        'Color coding by topic category',
        'Expandable nodes for detailed information',
        'Search and filter across your entire visual history'
      ],
      icon: Layers
    },
    {
      title: 'Interactive Chart Workspace',
      description: 'A flexible environment for analyzing and annotating market data.',
      features: [
        'Multiple chart types (candlestick, line, bar, etc.)',
        'Drag and drop indicator placement',
        'Customizable timeframes and data views',
        'Multi-chart layouts for comparison analysis',
        'AI-assisted chart pattern recognition'
      ],
      icon: LineChart
    },
    {
      title: 'Collaborative Tools',
      description: 'Share insights and work together with team members.',
      features: [
        'Real-time collaboration with multiple users',
        'Comment threads on specific chart points',
        'Shareable canvas links and exports',
        'Version history and change tracking',
        'Role-based permissions for team access'
      ],
      icon: Share2
    },
    {
      title: 'AI Integration',
      description: 'Leverage AI capabilities directly within your visual workspace.',
      features: [
        'Natural language to visualization conversion',
        'Automated insights from your canvas content',
        'Chart pattern suggestions and identification',
        'Predictive trend analysis visualization',
        'Custom AI analysis of selected data regions'
      ],
      icon: Zap
    }
  ];

  const basicControls = [
    {
      action: 'Pan Canvas',
      description: 'Navigate around the canvas workspace',
      method: 'Click and drag empty canvas space',
      icon: Move
    },
    {
      action: 'Zoom In/Out',
      description: 'Adjust the zoom level of the canvas',
      method: 'Mouse wheel or pinch gesture',
      icon: Maximize
    },
    {
      action: 'Select Items',
      description: 'Choose nodes or elements to modify',
      method: 'Click on any item or drag selection box',
      icon: MousePointer
    },
    {
      action: 'Create Connection',
      description: 'Link related items and create relationships',
      method: 'Drag from connection point to another item',
      icon: Asterisk
    },
    {
      action: 'Save Layout',
      description: 'Preserve your current canvas arrangement',
      method: 'Click the save icon or use Ctrl+S / Cmd+S',
      icon: Save
    },
    {
      action: 'Add to Favorites',
      description: 'Bookmark important canvas layouts',
      method: 'Click the bookmark icon in the canvas toolbar',
      icon: Bookmark
    }
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <PencilRuler className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Canvas Introduction
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Visualize conversations and market data in a flexible workspace
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              The Prism Canvas is a powerful visual workspace that transforms text-based market information 
              and conversations into interactive, visual representations. It enables you to organize research,
              annotate charts, identify patterns, and collaborate with team members in a flexible environment
              designed for financial analysis.
            </p>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              Whether you're analyzing price movements, documenting trading strategies, or sharing insights
              with colleagues, Canvas provides the tools to transform complex financial data into clear,
              actionable visualizations.
            </p>
          </div>

          {/* Key Features */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Key Canvas Features
          </h2>

          <div className="space-y-8 mb-16">
            {canvasFeatures.map((feature, index) => {
              const Icon = feature.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    } mr-4`}>
                      <Icon className={`w-6 h-6 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <div>
                      <h3 className={`text-xl font-bold ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {feature.title}
                      </h3>
                    </div>
                  </div>
                  
                  <p className={`mb-6 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {feature.description}
                  </p>
                  
                  <div className="grid md:grid-cols-2 gap-8">
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Features:</h4>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {feature.features.map((item, i) => (
                          <li key={i} className="flex items-start">
                            <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                            <span>{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div className={`rounded-lg overflow-hidden border ${
                      isDarkMode ? 'border-gray-700' : 'border-gray-200'
                    }`}>
                      <div className={`h-48 flex items-center justify-center ${
                        isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                      }`}>
                        <p className={`text-sm ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-500'
                        }`}>
                          Illustration of {feature.title.toLowerCase()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Basic Controls */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Basic Canvas Controls
          </h2>

          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="grid md:grid-cols-2 gap-6">
              {basicControls.map((control, index) => {
                const Icon = control.icon;
                return (
                  <div key={index} className="flex items-start">
                    <div className={`p-2 rounded-lg ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    } mr-4 mt-1 flex-shrink-0`}>
                      <Icon className={`w-4 h-4 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <div>
                      <h3 className={`text-base font-medium mb-1 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {control.action}
                      </h3>
                      <p className={`text-sm mb-1 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {control.description}
                      </p>
                      <p className={`text-xs ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        <strong>Method:</strong> {control.method}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Getting Started Section */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Getting Started with Canvas
          </h2>

          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <ol className={`space-y-4 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white font-medium mr-3 mt-0.5">1</div>
                <div>
                  <p className="font-medium mb-1">Access the Canvas</p>
                  <p className="text-sm">Open Canvas by clicking the Canvas icon in the main navigation or by selecting "Visualize" from any chat conversation.</p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white font-medium mr-3 mt-0.5">2</div>
                <div>
                  <p className="font-medium mb-1">Choose a Template or Start Blank</p>
                  <p className="text-sm">Select from pre-made templates for different analysis types or begin with an empty canvas.</p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white font-medium mr-3 mt-0.5">3</div>
                <div>
                  <p className="font-medium mb-1">Add Content</p>
                  <p className="text-sm">Drag and drop elements from the toolbar, import conversation elements, or add charts and indicators.</p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white font-medium mr-3 mt-0.5">4</div>
                <div>
                  <p className="font-medium mb-1">Organize and Connect</p>
                  <p className="text-sm">Arrange items spatially and create connections between related elements.</p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white font-medium mr-3 mt-0.5">5</div>
                <div>
                  <p className="font-medium mb-1">Save and Share</p>
                  <p className="text-sm">Save your canvas with a custom name and share it with teammates if needed.</p>
                </div>
              </li>
            </ol>
          </div>

          {/* Pro Tips */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="flex items-start">
              <AlertCircle className={`w-6 h-6 mt-1 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Pro Tips for Canvas</h3>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  Get the most out of your Canvas experience with these expert recommendations:
                </p>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Use keyboard shortcuts (press ? on Canvas to see all available shortcuts)</span>
                  </li>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Create separate canvases for different analysis types or market sectors</span>
                  </li>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Utilize the "Ask AI about this" feature by selecting canvas elements and right-clicking</span>
                  </li>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Group related items to keep your canvas organized as it grows</span>
                  </li>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Save frequent layouts as templates for faster future analysis</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Related Documentation */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Related Documentation
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <a 
              href="/docs/annotations"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Chart Annotations
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Learn how to add notes and markings to your charts
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
            
            <a
              href="/docs/patterns"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Pattern Recognition
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Using AI to identify chart patterns automatically
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default CanvasIntro;