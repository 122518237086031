import React, { useState, useEffect } from 'react';
import { useTheme } from '../../../contexts/ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  TrendingUp, 
  TrendingDown,
  DollarSign,
  BarChart2,
  Clock,
  Building2,
  CircleDot,
  Moon,
  ChevronDown,
  ChevronUp
} from 'lucide-react';

interface SelectedStockInfoProps {
  stock: {
    ticker: string;
    name: string;
    description?: string;
    price: number;
    change: number;
    changePercent: number;
    volume: number;
    marketCap?: number;
    exchange: string;
    dayRange?: { low: number; high: number };
    avgVolume?: number;
    previousClose: number;
    extendedHours?: {
      price: number;
      change: number;
      changePercent: number;
      type: 'pre' | 'after';
      timestamp: string;
    };
    afterHours?: {
      price: number;
      change: number;
      changePercent: number;
      timestamp: string;
    };
  };
}

export const SelectedStockInfo: React.FC<SelectedStockInfoProps> = ({ stock }) => {
  const { isDarkMode } = useTheme();
  const [imageError, setImageError] = useState(false);
  const [marketStatus, setMarketStatus] = useState<'pre' | 'open' | 'after' | 'closed'>('open');
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isExpanded, setIsExpanded] = useState(false);

  // Update current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
      updateMarketStatus();
    }, 60000);
    
    updateMarketStatus();
    return () => clearInterval(timer);
  }, []);

  const updateMarketStatus = () => {
    const now = new Date();
    const hour = now.getHours();
    const minutes = now.getMinutes();
    const day = now.getDay();
    
    if (day === 0 || day === 6) {
      setMarketStatus('closed');
      return;
    }

    const timeInMinutes = hour * 60 + minutes;
    
    if (timeInMinutes < 9 * 60 + 30) {
      setMarketStatus('pre');
    } else if (timeInMinutes < 16 * 60) {
      setMarketStatus('open');
    } else if (timeInMinutes < 20 * 60) {
      setMarketStatus('after');
    } else {
      setMarketStatus('closed');
    }
  };

  const formatLargeNumber = (num: number) => {
    if (num >= 1e12) return `$${(num / 1e12).toFixed(2)}T`;
    if (num >= 1e9) return `$${(num / 1e9).toFixed(2)}B`;
    if (num >= 1e6) return `$${(num / 1e6).toFixed(2)}M`;
    return `$${num.toLocaleString()}`;
  };

  const getChangeColor = (value: number) => {
    if (value > 0) return 'text-green-500';
    if (value < 0) return 'text-red-500';
    return isDarkMode ? 'text-gray-400' : 'text-gray-600';
  };

  const formatChange = (change: number, changePercent: number) => {
    const sign = change >= 0 ? '+' : '';
    return `${sign}${change.toFixed(2)} (${sign}${changePercent.toFixed(2)}%)`;
  };

  const getMarketStatusInfo = () => {
    switch (marketStatus) {
      case 'pre':
        return {
          icon: <Moon className="w-3 h-3 text-blue-400" />,
          text: 'Pre-market',
          time: '9:30 AM ET'
        };
      case 'open':
        return {
          icon: <CircleDot className="w-3 h-3 text-green-500" />,
          text: 'Market Open',
          time: '4:00 PM ET'
        };
      case 'after':
        return {
          icon: <Moon className="w-3 h-3 text-purple-400" />,
          text: 'After Hours',
          time: '8:00 PM ET'
        };
      case 'closed':
        return {
          icon: <CircleDot className="w-3 h-3 text-red-500" />,
          text: 'Market Closed',
          time: '9:30 AM ET Mon'
        };
    }
  };

  const calculateDayChange = () => {
    const change = stock.price - stock.previousClose;
    const changePercent = (change / stock.previousClose) * 100;
    return { change, changePercent };
  };

  const metrics = [
    {
      label: 'Previous Close',
      value: `${stock.previousClose.toFixed(2)}`,
      icon: Clock
    },
    {
      label: "Today's Range",
      value: stock.dayRange 
        ? `${Math.min(stock.dayRange.low, stock.price).toFixed(2)} - ${Math.max(stock.dayRange.high, stock.price).toFixed(2)}`
        : `${stock.previousClose.toFixed(2)} - ${stock.price.toFixed(2)}`,
      icon: BarChart2
    },
    {
      label: 'Volume',
      value: formatLargeNumber(stock.volume),
      icon: BarChart2,
      subtext: stock.avgVolume ? 
        `Avg: ${formatLargeNumber(stock.avgVolume)}` : 
        undefined
    },
    {
      label: 'Market Cap',
      value: stock.marketCap ? formatLargeNumber(stock.marketCap) : '-',
      icon: DollarSign
    }
  ];

  const statusInfo = getMarketStatusInfo();

  return (
    <div className={`relative mt-6 rounded-xl overflow-hidden border ${
      isDarkMode ? 'bg-gray-800/50 border-gray-700' : 'bg-white/50 border-gray-200'
    } backdrop-blur-sm`}>
      {/* Header Section */}
      <div className="flex items-center p-4 border-b border-gray-700/50">
        <div className={`w-12 h-12 rounded-lg ${
          isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
        } flex items-center justify-center mr-4`}>
          {!imageError ? (
            <img
              src={`/api/market/company-logo/${stock.ticker}`}
              alt={`${stock.name} logo`}
              className="w-12 h-12 object-contain"
              onError={() => setImageError(true)}
            />
          ) : (
            <Building2 className="w-8 h-8 text-blue-500" />
          )}
        </div>

        <div className="flex-1">
          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-center mb-1">
                <h2 className={`text-xl font-bold ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {stock.ticker}
                </h2>
                <div className="flex items-center ml-3 px-2 py-1 rounded-full bg-gray-700/50">
                  {statusInfo.icon}
                  <span className="text-xs ml-1.5 text-gray-300">
                    {statusInfo.text}
                  </span>
                </div>
              </div>
              <div className="flex items-center">
                <span className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  {stock.name}
                </span>
                <span className={`mx-2 text-sm ${
                  isDarkMode ? 'text-gray-500' : 'text-gray-400'
                }`}>•</span>
                <span className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  {stock.exchange}
                </span>
              </div>
            </div>

            <div className="text-right">
              <div className={`text-xl font-bold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                ${stock.price.toFixed(2)}
              </div>
              <div className={`flex items-center justify-end space-x-1 ${
                getChangeColor(calculateDayChange().changePercent)
              }`}>
                {calculateDayChange().change >= 0 ? (
                  <TrendingUp className="w-4 h-4" />
                ) : (
                  <TrendingDown className="w-4 h-4" />
                )}
                <span className="text-sm">
                  {formatChange(calculateDayChange().change, calculateDayChange().changePercent)}
                </span>
              </div>
              {stock.extendedHours && (marketStatus === 'after' || marketStatus === 'pre') && (
                <div className={`mt-1 text-xs ${getChangeColor(stock.extendedHours.changePercent)}`}>
                  {stock.extendedHours.type === 'pre' ? 'Pre-market' : 'After Hours'}: {
                    formatChange(stock.extendedHours.change, stock.extendedHours.changePercent)
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Market Hours Info */}
      <motion.div 
        onClick={() => setIsExpanded(!isExpanded)}
        className={`px-4 py-2 text-xs cursor-pointer ${
          isDarkMode ? 'bg-gray-700/50' : 'bg-gray-100/50'
        }`}
        whileHover={{ scale: 1.01 }}
        whileTap={{ scale: 0.99 }}
      >
        <div className="flex items-center justify-between">
          {/* Left side - Market status */}
          <span className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
            Market {marketStatus === 'open' ? 'Closes' : 'Opens'}: {statusInfo.time}
          </span>

          {/* Middle - Expand/Collapse icon */}
          {isExpanded ? (
            <ChevronUp className={`w-5 h-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
          ) : (
            <ChevronDown className={`w-5 h-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
          )}

          {/* Right side - Current time */}
          <span className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
            {currentTime.toLocaleTimeString([], { 
              hour: '2-digit', 
              minute: '2-digit',
              timeZoneName: 'short' 
            })}
          </span>
        </div>
      </motion.div>

      {/* Collapsible Content */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 30,
            }}
            className="overflow-hidden"
          >
            {/* Metrics Grid */}
            <div className="grid grid-cols-2 gap-4 p-4">
              {metrics.map((metric, index) => {
                const Icon = metric.icon;
                return (
                  <div key={index} className={`p-3 rounded-lg ${
                    isDarkMode ? 'bg-gray-700/50' : 'bg-gray-50'
                  }`}>
                    <div className="flex items-center mb-1">
                      <Icon className={`w-4 h-4 mr-2 ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`} />
                      <span className={`text-sm ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-600'
                      }`}>
                        {metric.label}
                      </span>
                    </div>
                    <div className={`font-mono text-base ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {metric.value}
                    </div>
                    {metric.subtext && (
                      <div className={`text-xs mt-1 ${
                        isDarkMode ? 'text-gray-500' : 'text-gray-400'
                      }`}>
                        {metric.subtext}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};