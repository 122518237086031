type LogLevel = 'info' | 'warn' | 'error' | 'debug';

export class Logger {
  private isDev = process.env.NODE_ENV === 'development';

  private formatError(error: unknown): object {
    if (error instanceof Error) {
      return {
        name: error.name,
        message: error.message,
        stack: this.isDev ? error.stack : undefined,
        cause: error.cause
      };
    }
    
    if (error && typeof error === 'object') {
      return { ...error };
    }
    
    return { message: String(error) };
  }

  error(message: string, error?: unknown) {
    const formattedError = error ? this.formatError(error) : null;
    console.error(this.formatMessage('error', message, formattedError));
  }

  private formatMessage(level: LogLevel, message: string, data?: any): string {
    const timestamp = new Date().toISOString();
    return `[${timestamp}] [${level.toUpperCase()}] ${message}${data ? '\n' + JSON.stringify(data, null, 2) : ''}`;
  }

  info(message: string, data?: any) {
    if (this.isDev) {
      console.info(this.formatMessage('info', message, data));
    }
  }

  warn(message: string, data?: any) {
    console.warn(this.formatMessage('warn', message, data));
  }

  

  debug(message: string, data?: any) {
    if (this.isDev) {
      console.debug(this.formatMessage('debug', message, data));
    }
  }
}

export const logger = new Logger();
export default logger;