// client/src/services/claudeService.ts
import { api } from './api';
import Anthropic from '@anthropic-ai/sdk';



import logger from '../config/logger';
import { ScreeningCriteria } from './screeningService';
import { Message } from '../types/chat';
import { ChatResponse } from '../types/chat';
import { marketDataService } from './marketDataService';
import { searchService } from './searchService';
import { visualizationParser } from './visualizationParser';
import { StockPrice, TimeRange } from '../types/market';
import { ParsedVisualization } from '../types/visualization';
interface ScreeningResponse {
  criteria: ScreeningCriteria[];
  explanation: string;
}

interface DataRequirement {
  type: 'stock_price' | 'company_info' | 'technical_indicators' | 'fundamentals' | 'news' ;
  symbol: string;
  timeRange?: string;
  indicators?: string[];
}

interface GenerateCodeParams {
  systemPrompt: string;
  userPrompt: string;
}

interface VisualizationRequest {
  message: string;
  stockData: Array<{
    symbol: string;
    data: Array<{
      price: number;
      timestamp: number;
    }>;
  }>;
  analysis: string;
}

interface VisualizationResponse {
  visualizations: Array<{
    type: 'text' | 'shape' | 'measurement';
    content: string;
    position: { x: number; y: number };
    style?: {
      color?: string;
      backgroundColor?: string;
      borderColor?: string;
      fontSize?: number;
      opacity?: number;
    };
  }>;
  insights: string[];
  keyLevels: number[];
}

const VISUALIZATION_SYSTEM_PROMPT = `RETURN JUST THE JSON, NO OTHER TEXT! Analyze the market data and return a visualization specification as a JSON object.
You can do:
1. Text annotations
2. Technical patterns (trendlines, channels.)
3. Volume heatmaps
4. Event markers (based on news) (only use these when there is news that took place during the time period that is selected)

The JSON must follow this exact format:
Return only json
For text annotations:
- Use timeframe as a percentage (0-100) to indicate position in the chart
- 0 = start of time period, 100 = end of time period
- Use actual price values for priceLevel
- Keep annotations concise and meaningful
{
  "visualizations": [
    {
      "type": "text",
      "content": "string",
      "position": {
        "timeframe": number, // 0-100 representing percentage position in time series
        "priceLevel": number // actual price value
      },
      "style": {
        "color": string,
        "backgroundColor": string,
        "fontSize": number
      }
    }
  ]
}
And then for other visualuzations, we use different formats for the position and style objects:
{
  "visualizations": [
    {
      "type": "technical",
      "pattern": {
        "type": "trendline",
        "startPoint": { "x": 0, "y": 100 },
        "endPoint": { "x": 100, "y": 150 },
        "style": {
          "stroke": "#FF0000",
          "strokeWidth": 2
        }
      }
    },
    {
      "type": "event",
      "eventType": "earnings",
      "timestamp": 1234567890,
      "price": 150.25,
      "title": "Q4 Earnings Beat",
      "description": "EPS $2.15 vs $1.95 expected",
      "impact": "positive"
    }
  ]
}

Guidelines:

- Use colors to indicate sentiment (green=bullish, red=bearish)`;

class ClaudeService {
  private static instance: ClaudeService;
  private visualizationParserInitialized = false;
  private anthropic: Anthropic;
  private activeRequests: Map<string, Promise<any>> = new Map();
  private constructor() {
    const apiKey = "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";
    if (!apiKey) {
      throw new Error('ANTHROPIC_API_KEY is required');
    }
    
    this.anthropic = new Anthropic({
      apiKey,
      dangerouslyAllowBrowser: true
    });
  }

  static getInstance(): ClaudeService {
    if (!ClaudeService.instance) {
      ClaudeService.instance = new ClaudeService();
    }
    
    // Move visualization parser initialization outside of instance creation
    if (!ClaudeService.instance.visualizationParserInitialized) {
      import('./visualizationParser').then(({ visualizationParser }) => {
        visualizationParser.setClaudeService(claudeService);
        ClaudeService.instance.visualizationParserInitialized = true;
      });
    }
    
    return ClaudeService.instance;
  }

  

  


  async generateCode(params: {systemPrompt: string, userPrompt: string}): Promise<string> {
    try {
      const response = await this.anthropic.messages.create({
        model: 'claude-3-haiku-20240307',
        max_tokens: 2048,
        messages: [{
          role: 'assistant',
          content: `${params.systemPrompt}\nYou must ONLY return valid JSON without any explanatory text.`
        }, {
          role: 'user',
          content: params.userPrompt
        }]
      });

      if (!response.content?.[0] || response.content[0].type !== 'text') {
        throw new Error('Expected text response from Claude');
      }

      const text = response.content[0].text;
      
      // Clean up the response
      let cleanedContent = text
        .replace(/```(?:json|javascript|typescript)?\s*/g, '')
        .replace(/```$/gm, '')
        .replace(/\/\*[\s\S]*?\*\//g, '')
        .replace(/\/\/.*/g, '')
        .trim();

      // Find the first { and last }
      const start = cleanedContent.indexOf('{');
      const end = cleanedContent.lastIndexOf('}');
      
      if (start === -1 || end === -1) {
        throw new Error('No JSON object found in response');
      }

      // Extract just the JSON object
      cleanedContent = cleanedContent.substring(start, end + 1);

      // Validate JSON
      try {
        JSON.parse(cleanedContent); // Validate
        return cleanedContent;
      } catch (parseError) {
        console.error('Invalid JSON:', cleanedContent);
        throw new Error('Failed to parse Claude response as JSON');
      }
    } catch (error) {
      console.error('Code generation failed:', error);
      throw this.normalizeError(error);
    }
  }

  

  async analyzeDataRequirements(query: string, context: Message[] = []): Promise<DataRequirement[]> {
    try {
      // Format context for the API
      const contextString = context
        .map(msg => `${msg.role}: ${msg.content}`)
        .join('\n');

      const { data } = await api.post<{ requirements: DataRequirement[] }>(
        '/market-chat/analyze', 
        { 
          query,
          context: contextString 
        }
      );
      return data.requirements;
    } catch (error) {
      console.log('Failed to analyze data requirements:', error);
      throw error;
    }
  }
  // src/services/claudeService.ts

  async generateVisualizations(request: VisualizationRequest): Promise<string> {
    const requestKey = `viz_${JSON.stringify(request.stockData.map(s => s.symbol))}`;
    
    // Check if there's an active request for the same data
    const existingRequest = this.activeRequests.get(requestKey);
    if (existingRequest) {
      return existingRequest;
    }

    const newRequest = (async () => {
      try {
        const completion = await this.anthropic.messages.create({
          model: 'claude-3-haiku-20240307',
          max_tokens: 1000,
          system: VISUALIZATION_SYSTEM_PROMPT,
          messages: [{
            role: 'user',
            content: `Generate visualization annotations for this analysis:
              ${request.analysis}
              
              Stock data summary: ${JSON.stringify({
                symbols: request.stockData.map(s => s.symbol),
                timeframe: {
                  start: request.stockData[0].data[0].timestamp,
                  end: request.stockData[0].data[request.stockData[0].data.length - 1].timestamp
                },
                priceRanges: request.stockData.map(s => ({
                  symbol: s.symbol,
                  min: Math.min(...s.data.map(d => d.price)),
                  max: Math.max(...s.data.map(d => d.price))
                }))
              })}`
          }]
        });

        if (completion.content?.[0]?.type !== 'text') {
          throw new Error('Invalid response format');
        }

        return completion.content[0].text;
      } finally {
        // Remove the request from tracking once completed
        this.activeRequests.delete(requestKey);
      }
    })();

    // Track the new request
    this.activeRequests.set(requestKey, newRequest);
    return newRequest;
  }

  async generateResponse(
    query: string,
    requirements: DataRequirement[],
    context: Message[] = [],
    data: {
      marketData?: StockPrice[][];
      fundamentalData?: any;
      newsData?: any[];
    }
  ): Promise<ChatResponse> {
    try {
      if (data) {
        // Use provided data instead of fetching
        const { marketData, fundamentalData, newsData } = data;
        
        // Format context for the API
        const contextString = context
          .map(msg => `${msg.role}: ${msg.content}`)
          .join('\n');
  
        const { data: response } = await api.post<ChatResponse>(
          '/market-chat/response',
          {
            query,
            marketData,
            fundamentalData,
            newsData,
            context: contextString
          }
        );
  
        return response;
      }
  
      // If no data provided, fetch it (existing code)
      const dataPromises = requirements.map(async (req) => {
        switch (req.type) {
          case 'stock_price':
            return {
              type: 'stock_price',
              data: await marketDataService.getStockData(req.symbol, req.timeRange as TimeRange)
            };
          
          case 'fundamentals':
            return {
              type: 'fundamentals',
              data: await searchService.getFundamentalData(req.symbol)
            };
          
          // In chatService.ts, in generateResponse function

        case 'news':
          console.log("Fetching news data for", req.symbol);
          const newsData = await searchService.searchNews(req.symbol);
          console.log("Fetched news data:", newsData);
          return {
            type: 'news',
            data: newsData
          };
          
          default:
            return null;
        }
      });
  
      const results = await Promise.allSettled(dataPromises);
  
      // Process results and return response
      const processedMarketData = results
        .filter((r, i) => requirements[i].type === 'stock_price' && r.status === 'fulfilled')
        .map(r => (r as PromiseFulfilledResult<any>).value.data);
  
      const processedFundamentalData = results
        .filter((r, i) => requirements[i].type === 'fundamentals' && r.status === 'fulfilled')
        .map(r => (r as PromiseFulfilledResult<any>).value.data)[0];
  
      const processedNewsData = results
        .filter((r, i) => requirements[i].type === 'news' && r.status === 'fulfilled')
        .map(r => (r as PromiseFulfilledResult<any>).value.data)
        .flat();
  
      const { data: response } = await api.post<ChatResponse>(
        '/market-chat/response',
        {
          query,
          marketData: processedMarketData.length > 0 ? processedMarketData : undefined,
          fundamentalData: processedFundamentalData,
          newsData: processedNewsData.length > 0 ? processedNewsData : undefined,
          context: context.map(msg => `${msg.role}: ${msg.content}`).join('\n')
        }
      );
  
      return response;
    } catch (error) {
      console.log('Failed to generate response:', error);
      throw error;
    }
  }



  async analyzeScreeningQuery(query: string): Promise<ScreeningResponse> {
    try {
      const systemPrompt = `You are a stock screening expert. Convert natural language queries into technical screening criteria.
      Focus on specific, measurable metrics and return criteria in JSON format.
      RETURN JUST THE JSON, NO OTHER TEXT!
      Each criterion must have:
      - type: "fundamental", "technical", or "sentiment"
      - field: specific metric to analyze
      - operator: "gt" (greater than), "lt" (less than), "eq" (equals)
      - value: numeric value

      Common fields:
      - Fundamental: pe_ratio, market_cap, revenue_growth, profit_margin
      - Technical: price, volume, rsi, macd
      - Sentiment: news_sentiment, social_sentiment

      Example Query: "Find tech stocks under $50 with high volume"
      Example Response:
      {
        "criteria": [
          {
            "type": "technical",
            "field": "price",
            "operator": "lt",
            "value": 50
          },
          {
            "type": "technical",
            "field": "volume",
            "operator": "gt",
            "value": 1000000
          },
          {
            "type": "fundamental",
            "field": "sector",
            "operator": "eq",
            "value": "technology"
          }
        ],
        "explanation": "Looking for technology sector stocks trading below $50 with daily volume above 1 million shares"
      }`;

      const response = await api.post('/claude/generate', {
        messages: [
          { role: 'system', content: systemPrompt },
          { role: 'user', content: query }
        ]
      });

      if (!response.data?.content) {
        throw new Error('Invalid response format');
      }

      // Parse the JSON response from Claude
      const responseText = response.data.content;
      const parsedResponse = JSON.parse(responseText);

      // Validate response structure
      if (!parsedResponse.criteria || !Array.isArray(parsedResponse.criteria)) {
        throw new Error('Invalid criteria format');
      }

      return {
        criteria: parsedResponse.criteria.map((criterion: any) => ({
          type: this.validateType(criterion.type),
          field: this.validateField(criterion.field),
          operator: this.validateOperator(criterion.operator),
          value: this.validateValue(criterion.value)
        })),
        explanation: parsedResponse.explanation || 'No explanation provided'
      };
    } catch (error) {
      console.log('Screening analysis failed:', error);
      
      // Development fallback
      if (process.env.NODE_ENV === 'development') {
        return {
          criteria: [
            {
              type: "technical",
              field: "price",
              operator: "gt",
              value: 50
            },
            {
              type: "fundamental",
              field: "market_cap",
              operator: "gt",
              value: 1000000000
            }
          ],
          explanation: "Looking for stocks above $50 with market cap above $1B"
        };
      }
      
      throw new Error('Failed to analyze screening query');
    }
  }

  private validateType(type: string): 'fundamental' | 'technical' | 'sentiment' {
    const validTypes = ['fundamental', 'technical', 'sentiment'];
    return validTypes.includes(type) ? type as any : 'technical';
  }

  private validateField(field: string): string {
    return field?.toLowerCase() || 'price';
  }

  private validateOperator(operator: string): 'gt' | 'lt' | 'eq' {
    const validOperators = ['gt', 'lt', 'eq'];
    return validOperators.includes(operator) ? operator as any : 'gt';
  }

  private validateValue(value: any): number {
    const num = Number(value);
    return isNaN(num) ? 0 : num;
  }

  async analyzeSentiment(text: string): Promise<number> {
    try {
      const response = await api.post('/claude/generate', {
        messages: [
          {
            role: 'system',
            content: 'Return ONLY a number between 0 and 1 representing sentiment (0 = very negative, 1 = very positive)'
          },
          { role: 'user', content: text }
        ]
      });

      if (!response.data?.content?.[0]?.text) {
        return 0.5; // Neutral fallback
      }

      const sentiment = parseFloat(response.data.content[0].text);
      return isNaN(sentiment) ? 0.5 : Math.max(0, Math.min(1, sentiment));
    } catch (error) {
      console.log('Sentiment analysis failed:', error);
      return 0.5; // Neutral fallback
    }
  }

  // Modified claudeService.ts
  

  

  

  private normalizeError(error: unknown): Error {
    if (error instanceof Error) return error;
    return new Error('Code generation failed');
  }
}

export const claudeService = ClaudeService.getInstance();

// src/utils/dataTransformer.ts

export const prepareDataForClaude = (chartData: any[], analysis: string) => {
  // Get min/max timestamps
  const timestamps = chartData.map(d => d.timestamp);
  const minTimestamp = Math.min(...timestamps);
  const maxTimestamp = Math.max(...timestamps);
  
  // Create simplified data structure
  const simplifiedData = chartData.map(point => ({
    price: point.price,
    relativeTime: ((point.timestamp - minTimestamp) / (maxTimestamp - minTimestamp)) * 100
  }));

  return {
    data: simplifiedData.filter((_, i) => i % 5 === 0), // Sample every 5th point
    analysis: analysis.substring(0, 1000), // Limit analysis length
    timeframe: {
      start: minTimestamp,
      end: maxTimestamp
    }
  };
};