import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import { marketDataService } from '../../services/marketDataService';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { Search, Building2, ArrowUpRight } from 'lucide-react';
import debounce from 'lodash/debounce';
import { SearchResult } from '../../types/market';

export function StockSearch() {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const searchStocks = debounce(async (searchQuery) => {
    if (!searchQuery.trim()) {
      setResults([]);
      return;
    }

    setIsLoading(true);
    try {
      const data = await marketDataService.searchStocks(searchQuery);
      setResults(data);
      setIsOpen(true);
    } catch (error) {
      console.error('Search failed:', error);
      setResults([]);
    } finally {
      setIsLoading(false);
    }
  }, 300);

  useEffect(() => {
    searchStocks(query);
  }, [query]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={wrapperRef}>
      <div className="max-w-2xl mx-auto">
        <div className="relative">
          <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400 pointer-events-none" />
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value.toUpperCase())}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder="Search stocks by symbol or company name..."
            className={`w-full pl-12 pr-4 py-3 rounded-lg font-mono ${
              isDarkMode 
                ? 'bg-gray-800 text-white border-gray-700' 
                : 'bg-white text-gray-900 border-gray-200'
            } border focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
          />
          {isLoading && (
            <div className="absolute right-4 top-1/2 -translate-y-1/2">
              <LoadingSpinner size="sm" />
            </div>
          )}
        </div>
      </div>

      {/* Results Dropdown */}
      {isOpen && results.length > 0 && (
        <div className="fixed left-1/2 -translate-x-1/2 w-full max-w-2xl z-[60]">
          <div className={`mt-2 rounded-lg shadow-lg ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          } border overflow-hidden backdrop-blur-lg`}>
            {results.map((result, index) => (
              <button
                key={result.ticker}
                onClick={() => {
                  navigate(`/dashboard/market/${result.ticker}`);
                  setIsOpen(false);
                  setQuery('');
                }}
                className={`w-full px-4 py-3 flex items-center justify-between hover:bg-blue-500 hover:text-white transition-colors ${
                  isDarkMode ? 'text-gray-200' : 'text-gray-900'
                } ${index > 0 ? `border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}` : ''}`}
              >
                <div className="flex items-center space-x-4">
                  <Building2 className={isDarkMode ? 'text-gray-400' : 'text-gray-500'} />
                  <div className="text-left">
                    <div className="font-mono font-bold">{result.ticker}</div>
                    <div className={`text-sm ${
                      isDarkMode ? 'text-gray-400' : 'text-gray-600'
                    }`}>
                      {result.name}
                    </div>
                  </div>
                </div>
                <div className={`flex flex-col items-end text-xs ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  <div className="font-mono">{result.market}</div>
                  <div className="flex items-center mt-1">
                    <span className="mr-1">View</span>
                    <ArrowUpRight className="w-3 h-3" />
                    </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Search Tips */}
      {isFocused && query === '' && (
        <div className="fixed left-1/2 -translate-x-1/2 w-full max-w-2xl z-[60]">
          <div className={`mt-2 rounded-lg p-4 text-xs ${
            isDarkMode ? 'bg-gray-800/90 text-gray-400' : 'bg-white/90 text-gray-600'
          } backdrop-blur-sm border ${
            isDarkMode ? 'border-gray-700' : 'border-gray-200'
          }`}>
            <div className="font-mono">Search tips:</div>
            <div className="mt-2 grid grid-cols-2 gap-2">
              <div>• Enter ticker symbol (e.g., AAPL)</div>
              <div>• Search by company name</div>
              <div>• Use $ prefix for exact match</div>
              <div>• Press Enter to search</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}