import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Lightbulb,
  ChevronRight,
  TrendingUp,
  Filter,
  Cpu,
  Check,
  AlertTriangle,
  BarChart2,
  Clock,
  Info,
  Zap
} from 'lucide-react';

const TradeIdeas = () => {
  const { isDarkMode } = useTheme();

  const ideaTypes = [
    {
      title: 'Momentum Ideas',
      description: 'Stock ideas based on price momentum and technical breakouts.',
      icon: TrendingUp,
      features: [
        'Identification of stocks with strong upward momentum',
        'Breakout detection from key technical levels',
        'Volume confirmation analysis',
        'Relative strength comparison',
        'Momentum oscillator signals'
      ],
      examples: [
        'Breakout above resistance with volume confirmation',
        'Bullish MACD crossover with rising RSI',
        'New 52-week highs with increasing volume'
      ]
    },
    {
      title: 'AI-Driven Insights',
      description: 'Algorithmic pattern recognition to identify potential trading opportunities.',
      icon: Cpu,
      features: [
        'Machine learning pattern recognition',
        'Sentiment analysis of market news',
        'Statistical anomaly detection',
        'Correlation analysis with market leaders',
        'Sector rotation identification'
      ],
      examples: [
        'Unusual options activity detection',
        'Positive news sentiment with technical confirmation',
        'Statistical deviation from sector performance'
      ]
    },
    {
      title: 'Event-Based Ideas',
      description: 'Opportunities based on market events and fundamental catalysts.',
      icon: Zap,
      features: [
        'Earnings announcement strategies',
        'FDA approval trades',
        'Product launch impact analysis',
        'Merger and acquisition speculation',
        'Conference and event calendars'
      ],
      examples: [
        'Pre-earnings momentum plays',
        'Post-event mean reversion strategies',
        'Catalyst-driven breakout opportunities'
      ]
    }
  ];

  const filters = [
    {
      name: 'Time Horizon',
      options: ['Intraday', 'Swing (1-5 days)', 'Position (1-4 weeks)', 'Long-term (1+ months)']
    },
    {
      name: 'Risk Level',
      options: ['Conservative', 'Moderate', 'Aggressive', 'Speculative']
    },
    {
      name: 'Idea Source',
      options: ['Technical Analysis', 'Fundamental', 'AI-Generated', 'Sentiment-Based']
    },
    {
      name: 'Market Cap',
      options: ['Mega Cap', 'Large Cap', 'Mid Cap', 'Small Cap', 'Micro Cap']
    }
  ];

  const bestPractices = [
    'Always perform your own due diligence before acting on trade ideas',
    'Consider the idea within the context of the broader market',
    'Use appropriate position sizing based on risk tolerance',
    'Set clear entry, exit, and stop loss levels',
    'Track idea performance to refine your selection criteria'
  ];

  const commonMistakes = [
    'Following trade ideas without understanding the rationale',
    'Ignoring broader market conditions when implementing ideas',
    'Taking positions too large for your risk tolerance',
    'Holding losing trades too long based on a compelling narrative',
    'FOMO-based trading on ideas that have already played out'
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Lightbulb className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Trade Ideas Generation
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                How to discover and filter actionable trading opportunities using Prism AI
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              Prism's Trade Ideas feature combines AI analysis with market data to identify potential 
              trading opportunities across various timeframes and strategies. By analyzing technical patterns, 
              fundamental catalysts, and market sentiment, Prism generates actionable ideas that align with 
              your trading preferences and risk tolerance. This guide explains how to use the Trade Ideas 
              feature effectively and integrate it into your overall trading strategy.
            </p>
          </div>

          {/* Idea Types */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Types of Trade Ideas
          </h2>

          <div className="space-y-12 mb-16">
            {ideaTypes.map((type, index) => {
              const Icon = type.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    } mr-4`}>
                      <Icon className={`w-6 h-6 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <div>
                      <h3 className={`text-xl font-bold ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {type.title}
                      </h3>
                    </div>
                  </div>
                  
                  <p className={`mb-6 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {type.description}
                  </p>
                  
                  <div className="grid md:grid-cols-2 gap-8">
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Features:</h4>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {type.features.map((feature, i) => (
                          <li key={i} className="flex items-start">
                            <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>

                      <h4 className={`text-sm font-medium mb-3 mt-6 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Examples:</h4>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {type.examples.map((example, i) => (
                          <li key={i} className="flex items-start">
                            <span className="w-1.5 h-1.5 rounded-full bg-green-500 mt-1.5 mr-2 flex-shrink-0" />
                            <span>{example}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div className={`rounded-lg overflow-hidden border ${
                      isDarkMode ? 'border-gray-700' : 'border-gray-200'
                    }`}>
                      <div className={`h-60 flex items-center justify-center ${
                        isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                      }`}>
                        <p className={`text-sm ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-500'
                        }`}>
                          {type.title} illustration
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Filtering Options */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Filtering Trade Ideas
          </h2>

          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="flex items-center mb-6">
              <div className={`p-2 rounded-lg ${
                isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
              } mr-4`}>
                <Filter className={`w-6 h-6 ${
                  isDarkMode ? 'text-blue-400' : 'text-blue-600'
                }`} />
              </div>
              <h3 className={`text-xl font-bold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Available Filters
              </h3>
            </div>

            <p className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Customize your trade idea feed by applying filters that match your trading style, 
              risk tolerance, and market preferences. Combined filters help you narrow down 
              opportunities to those most relevant to your strategy.
            </p>

            <div className="grid md:grid-cols-2 gap-6">
              {filters.map((filter, index) => (
                <div key={index} className={`p-4 rounded-lg ${
                  isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                }`}>
                  <h4 className={`text-sm font-medium mb-3 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>{filter.name}:</h4>
                  <div className="flex flex-wrap gap-2">
                    {filter.options.map((option, i) => (
                      <div key={i} className={`px-3 py-1 rounded-full text-sm ${
                        isDarkMode
                          ? 'bg-gray-800 border border-gray-600 text-gray-300'
                          : 'bg-white border border-gray-300 text-gray-700'
                      }`}>
                        {option}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Using Trade Ideas */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            How to Use Trade Ideas
          </h2>

          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="grid md:grid-cols-2 gap-6 mb-8">
              <div>
                <h3 className={`text-lg font-bold mb-4 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Accessing Trade Ideas
                </h3>
                <ol className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">1</div>
                    <span>Navigate to the Dashboard and select "Trade Ideas" from the sidebar</span>
                  </li>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">2</div>
                    <span>Review the available ideas displayed in the feed</span>
                  </li>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">3</div>
                    <span>Apply filters to narrow down ideas to your preferences</span>
                  </li>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">4</div>
                    <span>Click on an idea card to view detailed analysis</span>
                  </li>
                </ol>
              </div>

              <div>
                <h3 className={`text-lg font-bold mb-4 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Analyzing Idea Details
                </h3>
                <ol className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">1</div>
                    <span>Review the rationale and supporting evidence</span>
                  </li>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">2</div>
                    <span>Examine technical charts and indicators provided</span>
                  </li>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">3</div>
                    <span>Check suggested entry, exit, and stop levels</span>
                  </li>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">4</div>
                    <span>Use the chat feature to ask questions about the idea</span>
                  </li>
                </ol>
              </div>
            </div>

            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h3 className={`text-lg font-bold mb-4 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Taking Action
                </h3>
                <ol className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">1</div>
                    <span>Save ideas to your watchlist for further consideration</span>
                  </li>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">2</div>
                    <span>Export ideas to your preferred trading platform</span>
                  </li>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">3</div>
                    <span>Set alerts for price levels or conditions</span>
                  </li>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">4</div>
                    <span>Share ideas with collaborators via link or export</span>
                  </li>
                </ol>
              </div>

              <div>
                <h3 className={`text-lg font-bold mb-4 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Tracking Performance
                </h3>
                <ol className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">1</div>
                    <span>View historical performance of ideas you've interacted with</span>
                  </li>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">2</div>
                    <span>Review success rates by idea type and timeframe</span>
                  </li>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">3</div>
                    <span>Analyze hit/miss metrics for various market conditions</span>
                  </li>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">4</div>
                    <span>Export performance data for detailed analysis</span>
                  </li>
                </ol>
              </div>
            </div>
          </div>

          {/* Best Practices */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <h3 className={`text-xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Best Practices for Trade Ideas
            </h3>
            
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h4 className={`font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Do:</h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {bestPractices.map((practice, i) => (
                    <li key={i} className="flex items-start">
                      <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                      <span>{practice}</span>
                    </li>
                  ))}
                </ul>
              </div>
              
              <div>
                <h4 className={`font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Avoid:</h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {commonMistakes.map((mistake, i) => (
                    <li key={i} className="flex items-start">
                      <AlertTriangle className="w-4 h-4 text-yellow-500 mr-2 mt-0.5 flex-shrink-0" />
                      <span>{mistake}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Important Note */}
          <div className={`p-6 rounded-xl border ${
            isDarkMode ? 'bg-amber-900/20 border-amber-800/30' : 'bg-amber-50 border-amber-100'
          } mb-12`}>
            <div className="flex items-start">
              <Info className={`w-6 h-6 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-amber-400' : 'text-amber-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Important Disclaimer</h3>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  Trade Ideas provided by Prism AI are for informational purposes only and should not be considered 
                  as financial advice or investment recommendations. Always conduct your own research and due diligence 
                  before making investment decisions. Past performance of trade ideas is not indicative of future results.
                </p>
              </div>
            </div>
          </div>

          {/* Related Documentation */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Related Documentation
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <a 
              href="/docs/advanced-analytics"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Advanced Analytics
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Deeper analysis capabilities for trade opportunities
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
            
            <a 
              href="/docs/patterns"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Pattern Recognition
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Understanding chart patterns in trade ideas
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default TradeIdeas;