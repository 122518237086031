// src/services/searchService.ts
import axios, { AxiosResponse } from 'axios';
import logger from '../config/logger';
import { polygonService } from './polygonService';
import api from './api';

interface SearchResult {
  title: string;
  snippet: string;
  url: string;
  source: string;
  date?: string;
  sentiment_score?: number;
  sentiment_label?: string;
}

export interface RawNewsArticle {
  title: string;
  description?: string;
  publisher?: {
    name: string;
  };
  article_url?: string;
  published_utc?: string;
  tickers?: string[];
}

export interface NewsArticle {
  title: string;
  snippet: string;
  source: string;
  url: string;
  date: string;
  tickers: string[];
  importance?: number;
}



interface FundamentalData {
  revenue?: number;
  netIncome?: number;
  eps?: number;
  peRatio?: number;
  marketCap?: number;
  sector?: string;
  industry?: string;
  description?: string;
  employees?: number;
  quarterlyGrowth?: number;
}

class SearchService {
  private static instance: SearchService;
  private cache: Map<string, { data: any; timestamp: number }> = new Map();
  private readonly CACHE_DURATION = 30 * 60 * 1000; // 30 minutes
  private readonly CORS_PROXY = 'https://api.allorigins.win/raw?url=';

  private constructor() {}

  static getInstance(): SearchService {
    if (!SearchService.instance) {
      SearchService.instance = new SearchService();
    }
    return SearchService.instance;
  }
  

  private async fetchWithProxy(url: string): Promise<string> {
    const response = await fetch(`${this.CORS_PROXY}${encodeURIComponent(url)}`);
    if (!response.ok) {
      throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
    }
    return response.text();
  }

  private parseDateSafely(dateStr: string): string | undefined {
    try {
      // Alpha Vantage format: YYYYMMDDTHHMMSS
      const year = dateStr.substring(0, 4);
      const month = dateStr.substring(4, 6);
      const day = dateStr.substring(6, 8);
      const hour = dateStr.substring(9, 11);
      const minute = dateStr.substring(11, 13);
      const second = dateStr.substring(13, 15);
      
      const date = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}Z`);
      return date.toISOString();
    } catch (error) {
      logger.warn(`Failed to parse date: ${dateStr}`, error);
      return undefined;
    }
  }

  // In searchService.ts, update the searchNews function
 


  async searchNews(query: string): Promise<SearchResult[]> {
    console.log('Fetching news for', query);
    try {
      // Use Polygon News API
      const response = await fetch(
        `https://api.polygon.io/v2/reference/news?ticker=${query}&apiKey=SMa4ETj_h__CYNjQVof33gNSWEWKhK4B`
      );
  
      if (!response.ok) {
        throw new Error(`News API returned status: ${response.status}`);
      }
  
      const data = await response.json();
      
      // Transform and return the news results
      if (data.results && Array.isArray(data.results)) {
        return data.results
          .slice(0, 10) // Limit to most recent 10 articles
          .map((item: any) => ({
            title: item.title,
            snippet: item.description,
            url: item.article_url,
            source: item.publisher.name,
            date: item.published_utc,
            sentiment_score: 0.5, // Polygon doesn't provide sentiment, use neutral
            sentiment_label: 'neutral'
          }));
      }
  
      return [];
    } catch (error) {
      console.log('News search failed:', error);
      return [];
    }
  }
  
  // Add this helper function to get fallback news when rate limited
  private getFallbackNews(query: string): SearchResult[] {
    const currentDate = new Date();
    return [
      {
        title: `${query} Market Analysis`,
        snippet: `Latest market developments and analysis for ${query}. Data temporarily unavailable.`,
        url: '#',
        source: 'Market Summary',
        date: currentDate.toISOString(),
        sentiment_score: 0.5,
        sentiment_label: 'neutral'
      }
    ];
  }

  async getLatestMarketNews(): Promise<NewsArticle[]> {
    try {
      const response = await api.get<RawNewsArticle[]>('/market/news');
      const data = response;
      
      if (!Array.isArray(data)) {
        console.error('Invalid news data format:', data);
        return [];
      }

      // Transform the data to match our interface
      return data.map(article => ({
        title: article.title,
        snippet: article.description || '',
        source: article.publisher?.name || 'Unknown',
        url: article.article_url || '',
        date: article.published_utc || new Date().toISOString(),
        tickers: article.tickers || []
      }));
        
    } catch (error) {
      console.error('Latest market news fetch failed:', error);
      return [];
    }
  }

  async searchStocks(query: string): Promise<any> {
    try {
      const response = await api.get<any>('/market/search', {
        params: { q: query }
      });
      return response.data;
    } catch (error) {
      console.log('Stock search failed:', error);
      throw error;
    }
  }

  
  
  
  async getFundamentalData(ticker: string): Promise<FundamentalData> {
    const cacheKey = `fundamentals:${ticker}`;
    const cached = this.cache.get(cacheKey);
    
    if (cached && Date.now() - cached.timestamp < this.CACHE_DURATION) {
      return cached.data;
    }
    console.log('Fetching fundamental data for', ticker);

    try {
      // Use Alpha Vantage Overview Endpoint
      const response = await fetch(
        `https://www.alphavantage.co/query?function=OVERVIEW&symbol=${ticker}&apikey=A4A1P1XKJF06CBHQ`
      );
      
      const data = await response.json();
      
      const fundamentals: FundamentalData = {
        marketCap: parseFloat(data.MarketCapitalization),
        peRatio: parseFloat(data.PERatio),
        eps: parseFloat(data.EPS),
        revenue: parseFloat(data.RevenueTTM),
        sector: data.Sector,
        industry: data.Industry,
        description: data.Description,
        employees: parseInt(data.FullTimeEmployees),
        quarterlyGrowth: parseFloat(data.QuarterlyEarningsGrowthYOY)
      };

      this.cache.set(cacheKey, {
        data: fundamentals,
        timestamp: Date.now()
      });

      return fundamentals;
    } catch (error) {
      console.log('Failed to fetch fundamental data:', error);
      return {};
    }
  }

  private parseMarketCap(value: string): number {
    const multipliers: Record<string, number> = {
      'T': 1e12,
      'B': 1e9,
      'M': 1e6,
      'K': 1e3
    };

    const match = value.match(/([0-9.]+)([TBMK])/);
    if (!match) return 0;

    const [, num, unit] = match;
    return parseFloat(num) * (multipliers[unit] || 1);
  }
}

export const searchService = SearchService.getInstance();