import React, { useState, useEffect } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Card, CardContent } from '../../components/ui/card';
import { motion } from 'framer-motion';
import { 
  HelpCircle, 
  Search, 
  MessageCircle, 
  Mail,
  ChevronRight,
  ArrowRight,
  FileText,
  Users,
  Settings,
  TrendingUp,
  Brain,
  RefreshCw,
  Sparkles,
  Zap,
  Lightbulb
} from 'lucide-react';

interface HelpCategory {
  id: string;
  title: string;
  description: string;
  icon: React.FC<{ className?: string }>;
  articles: Array<{
    id: string;
    title: string;
    excerpt: string;
    content?: string;
  }>;
}

const helpCategories: HelpCategory[] = [
  {
    id: 'market-research',
    title: 'Market Research',
    description: 'Master Prism AI market research tools',
    icon: Brain,
    articles: [
      {
        id: 'prism-basics',
        title: 'Getting Started with Prism AI',
        excerpt: 'Learn how to use Prism AI for effective market research',
        content: `
## Getting Started with Prism AI

Prism AI is Newsroom's powerful market research assistant that helps you analyze stocks, understand market trends, and make data-driven decisions.

### Key Features
- Natural language market research
- Real-time data analysis
- Multi-source information integration
- Custom research modes

### Basic Usage
1. Navigate to the Prism tab in your dashboard
2. Type your market research question in the chat box
3. Review the AI-generated analysis with data visualizations
4. Save important insights for future reference

### Research Modes
- **Web-First**: Prioritizes real-time web data
- **Data-First**: Focuses on financial metrics and technical analysis
- **Balanced**: Combines web information with structured data

### Pro Tips
- Be specific with your questions
- Use ticker symbols for more precise stock analysis
- Try adding research parameters like time periods
- Follow up with clarifying questions
`
      },
      {
        id: 'effective-queries',
        title: 'Crafting Effective Research Queries',
        excerpt: 'How to phrase questions to get the most useful market insights',
        content: `
## Crafting Effective Research Queries

The quality of insights you receive depends largely on how you phrase your questions to Prism AI.

### Best Practices
- **Be specific**: "What is AAPL's current P/E ratio compared to industry average?" instead of "Tell me about Apple"
- **Include timeframes**: "How has NVIDIA performed over the last 3 months?"
- **Specify metrics**: "What is Tesla's revenue growth rate year-over-year?"
- **Compare entities**: "Compare AMD and Intel's market share in data center chips"

### Sample Effective Queries
- "What are the key technical indicators for Bitcoin in the last 7 days?"
- "Analyze recent insider trading activity for Microsoft and explain its significance"
- "What is the impact of rising interest rates on REITs like SPG and O?"
- "Identify potential breakout stocks in the semiconductor sector with unusual volume"

### Using Chat Chips
Chat chips allow you to maintain context across multiple questions:
1. Start with a general query about a company
2. Click on the generated chip with the ticker symbol
3. Ask follow-up questions without repeating the company name
`
      },
      {
        id: 'trade-ideas',
        title: 'Generating Custom Trade Ideas',
        excerpt: 'Configure the trade ideas feed for personalized trading opportunities',
        content: `
## Generating Custom Trade Ideas

The Trade Ideas feature helps you discover potential trading opportunities based on your preferences and trading style.

### Setting Up Preferences
1. Navigate to the Trade Ideas tab
2. Click "Set Up Your Preferences"
3. Select your preferred:
   - Market sectors (Technology, Healthcare, etc.)
   - Trading strategies (Momentum, Value, etc.)
   - Risk tolerance (Conservative to Aggressive)
   - Time horizons (Day trading to Long-term)

### Using Custom Prompts
For more tailored ideas:
1. Enable "Use only this prompt" option
2. Describe exactly what you're looking for
3. Example: "Find oversold tech stocks with upcoming earnings and positive analyst revisions"

### Refreshing Ideas
- Pro users can manually refresh ideas and clear the cache
- Regular users receive new ideas on a fixed schedule

### Saving Ideas
Click the bookmark icon on any trade idea to save it to your watchlist for future reference
`
      }
    ]
  },
  {
    id: 'market-data',
    title: 'Market Data',
    description: 'Access and analyze real-time market information',
    icon: TrendingUp,
    articles: [
      {
        id: 'data-dashboard',
        title: 'Navigating the Market Dashboard',
        excerpt: 'Understanding all the components of your personalized dashboard',
        content: `
## Navigating the Market Dashboard

Your Newsroom dashboard provides a comprehensive overview of market conditions and personalized insights.

### Dashboard Components
- **Welcome State**: Personalized market topics and trending stocks
- **Market Status Timeline**: Visual indicator of current market hours
- **Trending Topics**: Hot financial topics with sentiment analysis
- **Quick Links**: Access to your saved items and recent searches

### Personalization Options
- Click the settings icon to customize your dashboard
- Set auto-refresh intervals for real-time data
- Choose default categories and sectors to follow
- Configure dark/light mode preferences

### Interactive Elements
- Click on any topic to start a Prism AI chat about that subject
- Hover over stocks to see quick price information
- Use the search bar for quick access to stocks, news, or topics

### Organization
Create custom categories to organize your saved content:
1. Click "+" icon next to Saved Categories
2. Name your category (e.g., "Tech Stocks", "Earnings Plays")
3. Add a description for future reference
4. Add stocks or topics to your custom category
`
      },
      {
        id: 'stock-charts',
        title: 'Reading Stock Charts and Indicators',
        excerpt: 'How to interpret technical data in Newsroom chart displays',
        content: `
## Reading Stock Charts and Indicators

Newsroom provides professional-grade charts with technical indicators to help you analyze price movements.

### Chart Types
- **Candlestick**: Shows open, high, low, and close prices
- **Line**: Simplified view of closing prices
- **OHLC**: Traditional open-high-low-close bars
- **Area**: Emphasizes volume beneath price line

### Common Indicators
- **Moving Averages**: 50-day, 200-day for trend identification
- **MACD**: Momentum and trend direction
- **RSI**: Overbought or oversold conditions
- **Bollinger Bands**: Volatility and potential price targets

### Customizing Charts
1. Click the settings icon on any chart
2. Select your preferred timeframe (1D to 5Y)
3. Add technical indicators from the dropdown
4. Save custom chart layouts

### Chart Interactions
- Zoom: Use mouse wheel or pinch gesture
- Pan: Click and drag the chart
- Crosshair: Hover for precise price/time data
- Compare: Add multiple symbols for comparison
`
      }
    ]
  },
  {
    id: 'account',
    title: 'Account & Billing',
    description: 'Manage your subscription and settings',
    icon: Users,
    articles: [
      {
        id: 'pro-features',
        title: 'Newsroom Pro Features',
        excerpt: 'Learn about all the benefits of upgrading to Newsroom Pro',
        content: `
## Newsroom Pro Features

Upgrade to Newsroom Pro to unlock advanced features and capabilities.

### Pro Advantages
- **Unlimited Prism AI queries**: No daily chat limits
- **Advanced research modes**: Including data-driven analysis
- **Real-time data**: Faster refresh rates on all market data
- **Trade ideas refresh**: Force refresh trade ideas anytime
- **Priority support**: Faster response times
- **Extended data history**: Access to 10+ years of financial data
- **Advanced charts**: More technical indicators and drawing tools
- **API access**: Programmatic data retrieval

### Subscription Management
1. Navigate to Account Settings > Subscription
2. Choose between monthly or annual billing
3. Enter payment information
4. Manage or cancel subscription anytime

### Usage Analytics
Pro members get detailed analytics on:
- Query usage
- Data consumption
- Most used features
- Time saved through AI assistance

### Enterprise Options
For team or company-wide access, contact sales for custom enterprise plans with additional features like:
- Team collaboration tools
- Custom data integrations
- Dedicated account manager
- Training and onboarding
`
      },
      {
        id: 'credits-system',
        title: 'Understanding the Credits System',
        excerpt: 'How credits work and best practices for efficient usage',
        content: `
## Understanding the Credits System

Newsroom uses a credit system to manage usage of AI-powered features.

### Credit Allocation
- **Free tier**: 50 credits daily
- **Pro tier**: 500 credits daily
- **Enterprise**: Custom allocation

### Credit Costs
- **Basic query**: 1 credit
- **Standard research**: 3-5 credits
- **Deep analysis**: 8-10 credits
- **Trade idea generation**: 15 credits
- **Chart generation**: 5 credits

### Credit Efficiency Tips
- Use chat history to build on previous research
- Save frequently used queries as templates
- Use chat chips to maintain context
- Upload relevant documents when available
- Be specific with your questions

### Credit Refresh
Credits automatically refresh at:
- 12:00 AM in your local timezone
- Unused credits do not roll over
- View your remaining credits in the account menu
`
      }
    ]
  },
  {
    id: 'troubleshooting',
    title: 'Troubleshooting',
    description: 'Solve common issues and optimize performance',
    icon: Settings,
    articles: [
      {
        id: 'data-refresh',
        title: 'Fixing Data Refresh Issues',
        excerpt: "What to do when market data isn't updating properly",
        content: `
## Fixing Data Refresh Issues

If you're experiencing problems with market data not updating, try these solutions.

### Common Issues and Solutions

#### Stale Market Data
1. Check your auto-refresh interval settings
2. Click the refresh button on the specific widget
3. Clear your browser cache and reload
4. Verify market hours - data may not update when markets are closed

#### Trade Ideas Not Refreshing
- **Free users**: Limited to scheduled refreshes
- **Pro users**: Click "Clear Cache" button, then "Refresh Ideas"
- Ensure your preferences aren't too restrictive

#### Delayed Price Data
- Check your connection speed
- Verify your subscription level (real-time vs. delayed quotes)
- Some exchanges have inherent 15-minute delays for non-professional users

### Performance Optimization
- Close unused tabs and applications
- Reduce the number of widgets on your dashboard
- Set reasonable auto-refresh intervals (30 seconds minimum recommended)
- Use the mobile app for on-the-go access with lower bandwidth requirements
`
      },
      {
        id: 'browser-compat',
        title: 'Browser Compatibility',
        excerpt: 'Recommended browsers and settings for optimal performance',
        content: `
## Browser Compatibility

Newsroom works best with modern browsers and specific settings.

### Recommended Browsers
- **Chrome**: Version 90 or higher (best performance)
- **Firefox**: Version 88 or higher
- **Edge**: Version 90 or higher
- **Safari**: Version 14 or higher

### Optimal Settings
- Enable JavaScript
- Allow cookies for session persistence
- Allow local storage for saved preferences
- Disable aggressive ad blockers that might interfere with data feeds

### Mobile Experience
- Responsive design works on most devices
- Native apps available for iOS and Android
- Some advanced charting features limited on smaller screens

### Common Browser Issues
- **Charts not rendering**: Try disabling hardware acceleration
- **Slow performance**: Clear browser cache and cookies
- **Login problems**: Check for cookie blocking
- **Data not loading**: Verify network connection and try incognito mode
`
      }
    ]
  }
];

const commonQuestions = [
  {
    question: 'How do I create a custom watchlist?',
    answer: 'Navigate to the Dashboard, click the "+" button in the Watchlists section, name your list, and add stocks by searching or browsing sectors.'
  },
  {
    question: 'What data sources does Prism AI use?',
    answer: 'Prism AI combines real-time market data, SEC filings, news articles, analyst reports, social sentiment, and historical patterns from multiple premium data providers.'
  },
  {
    question: 'How often are trade ideas updated?',
    answer: 'Free users receive updated trade ideas every 24 hours. Pro users can manually refresh ideas anytime and benefit from automatic updates every 4 hours.'
  },
  {
    question: 'Can I export data for my own analysis?',
    answer: 'Yes, Pro users can export market data, charts, and research findings in CSV, Excel, or PDF formats from the export button in each module.'
  }
];

const selfServiceOptions = [
  {
    icon: RefreshCw,
    title: 'Reset and Refresh',
    description: 'Clear cache and reload application data',
    action: 'Reset Now',
    color: 'bg-amber-600',
    onClick: () => {
      // Implementation would clear localStorage and reload the page
      localStorage.clear();
      window.location.reload();
    }
  },
  {
    icon: Mail,
    title: 'Email Support',
    description: 'Get help via email',
    action: 'Send Email',
    color: 'bg-green-600',
    onClick: () => {
      window.location.href = 'mailto:hello@news-room.ca';
    }
  },
  {
    icon: MessageCircle,
    title: 'Live Chat',
    description: 'Chat with our support team',
    action: 'Start Chat',
    color: 'bg-blue-600',
    onClick: () => {
      // Implementation would open chat widget
      console.log('Opening chat widget');
      alert('Chat support will be available soon!');
    }
  }
];

const HelpCenter = () => {
  const { isDarkMode } = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedArticle, setSelectedArticle] = useState<string | null>(null);
  const [articleContent, setArticleContent] = useState<string | null>(null);

  useEffect(() => {
    // Reset article selection when category changes
    setSelectedArticle(null);
    setArticleContent(null);
  }, [selectedCategory]);

  useEffect(() => {
    // Find and set article content when an article is selected
    if (selectedCategory && selectedArticle) {
      const category = helpCategories.find(cat => cat.id === selectedCategory);
      if (category) {
        const article = category.articles.find(art => art.id === selectedArticle);
        if (article && article.content) {
          setArticleContent(article.content);
        }
      }
    }
  }, [selectedCategory, selectedArticle]);

  const filteredCategories = helpCategories.filter(category =>
    !searchQuery || 
    category.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    category.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
    category.articles.some(article => 
      article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      article.excerpt.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (article.content && article.content.toLowerCase().includes(searchQuery.toLowerCase()))
    )
  );

  // Convert markdown to HTML (simplified version)
  const renderMarkdown = (content: string) => {
    if (!content) return '';
    
    // Handle headings
    content = content.replace(/## (.*)\n/g, '<h2 class="text-xl font-bold mt-6 mb-3">$1</h2>');
    content = content.replace(/### (.*)\n/g, '<h3 class="text-lg font-semibold mt-5 mb-2">$1</h3>');
    
    // Handle lists
    content = content.replace(/- (.*)\n/g, '<li class="ml-4 mb-1">$1</li>');
    content = content.replace(/<li/g, '<li class="flex items-start"><span class="inline-block h-1.5 w-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0"></span><span');
    content = content.replace(/<\/li>/g, '</span></li>');
    
    // Handle numbered lists
    content = content.replace(/(\d+)\. (.*)\n/g, '<li class="ml-4 mb-1 flex items-start"><span class="font-medium mr-2">$1.</span>$2</li>');
    
    // Handle paragraphs
    const paragraphs = content.split('\n\n');
    content = paragraphs.map(p => {
      if (!p.startsWith('<h') && !p.startsWith('<li') && p.trim()) {
        return `<p class="mb-4">${p}</p>`;
      }
      return p;
    }).join('');
    
    return content;
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.1
      }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      {/* Hero Section with AI-inspired design */}
      <div className="relative overflow-hidden">
        {/* Dynamic Background */}
        <div className={`absolute inset-0 ${
          isDarkMode 
            ? 'bg-[linear-gradient(to_right,#232323_1px,transparent_1px),linear-gradient(to_bottom,#232323_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_50%,#000_70%,transparent_100%)]' 
            : 'bg-[linear-gradient(to_right,#e5e5e5_1px,transparent_1px),linear-gradient(to_bottom,#e5e5e5_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_50%,#fff_70%,transparent_100%)]'
        }`} />
        
        {/* Gradient Orbs */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className={`absolute -left-20 -top-20 w-72 h-72 rounded-full ${
            isDarkMode ? 'bg-blue-600/20' : 'bg-blue-400/10'
          } blur-3xl`} />
          <div className={`absolute right-0 bottom-0 w-96 h-96 rounded-full ${
            isDarkMode ? 'bg-purple-600/20' : 'bg-purple-400/10'
          } blur-3xl`} />
        </div>
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-20 relative">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center relative z-10"
          >
            <div className={`w-16 h-16 mx-auto mb-6 rounded-2xl flex items-center justify-center ${
              isDarkMode ? 'bg-blue-600/20 backdrop-blur-xl border border-blue-600/30' : 'bg-blue-50 border border-blue-100'
            }`}>
              <HelpCircle className={`w-8 h-8 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
            </div>
            
            <h1 className={`text-4xl md:text-5xl font-bold mb-6 tracking-tight ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              How can we help?
            </h1>
            
            <div className="max-w-2xl mx-auto">
              <div className="relative">
                <motion.div
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.2, duration: 0.4 }}
                >
                  <input
                    type="text"
                    placeholder="Search for help with trading, data, research..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className={`w-full px-4 py-3.5 pl-12 rounded-xl ${
                      isDarkMode
                        ? 'bg-gray-800/80 border border-gray-700/80 text-white placeholder-gray-400 backdrop-blur-sm'
                        : 'bg-white/90 border border-gray-200 text-gray-900 placeholder-gray-500 backdrop-blur-sm'
                    } focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-lg`}
                  />
                  <Search className={`absolute left-4 top-3.5 w-5 h-5 ${
                    isDarkMode ? 'text-blue-400' : 'text-blue-500'
                  }`} />
                </motion.div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Help Categories or Article Content */}
        {!selectedArticle ? (
          <>
            {/* Help Categories */}
            <motion.div 
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mb-16"
            >
              {filteredCategories.map((category) => {
                const Icon = category.icon;
                return (
                  <motion.div key={category.id} variants={itemVariants}>
                    <Card
                      className={`group cursor-pointer transition-all duration-300 h-full ${
                        isDarkMode 
                          ? 'bg-gray-800/70 border-gray-700/70 hover:bg-gray-800 backdrop-blur-sm' 
                          : 'bg-white/90 border-gray-200/90 hover:bg-white'
                      } ${selectedCategory === category.id ? 'ring-2 ring-blue-500' : ''} hover:shadow-xl`}
                      onClick={() => setSelectedCategory(category.id)}
                    >
                      <CardContent className="p-6">
                        <div className={`w-14 h-14 rounded-2xl flex items-center justify-center mb-5 ${
                          isDarkMode ? 'bg-gradient-to-br from-blue-600/20 to-purple-600/20 border border-blue-500/20' : 'bg-blue-50 border border-blue-100'
                        }`}>
                          <Icon className={`w-7 h-7 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                        </div>
                        <h3 className={`text-lg font-bold mb-2 group-hover:text-blue-500 transition-colors ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>
                          {category.title}
                        </h3>
                        <p className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'} line-clamp-2`}>
                          {category.description}
                        </p>
                        <div className={`mt-4 flex items-center text-sm ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-500'
                        }`}>
                          <span className="flex items-center bg-blue-500/10 rounded-full px-2 py-0.5">
                            <Sparkles className="w-3 h-3 mr-1 text-blue-500" />
                            <span className="text-xs">{category.articles.length} articles</span>
                          </span>
                          <ChevronRight className="w-4 h-4 ml-auto group-hover:translate-x-1 transition-transform" />
                        </div>
                      </CardContent>
                    </Card>
                  </motion.div>
                );
              })}
            </motion.div>

            {/* Articles List (if category is selected) */}
            {selectedCategory && (
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4 }}
                className="mb-16"
              >
                <div className="flex items-center mb-8">
                  <button 
                    onClick={() => setSelectedCategory(null)}
                    className={`mr-3 p-2 rounded-lg ${
                      isDarkMode ? 'hover:bg-gray-800 text-gray-400' : 'hover:bg-gray-100 text-gray-600'
                    } transition-colors`}
                  >
                    <ChevronRight className="w-5 h-5 transform rotate-180" />
                  </button>
                  <h2 className={`text-2xl font-bold ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {helpCategories.find(c => c.id === selectedCategory)?.title}
                  </h2>
                </div>
                
                <div className="grid md:grid-cols-2 gap-6">
                  {helpCategories
                    .find(c => c.id === selectedCategory)
                    ?.articles.map((article, index) => (
                      <motion.div
                        key={article.id}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.1, duration: 0.4 }}
                      >
                        <div
                          onClick={() => setSelectedArticle(article.id)}
                          className={`p-6 rounded-xl cursor-pointer transition-all duration-300 border ${
                            isDarkMode 
                              ? 'bg-gray-800/70 hover:bg-gray-800 border-gray-700/80 backdrop-blur-sm' 
                              : 'bg-white/90 hover:bg-white border-gray-200/80'
                          } hover:shadow-lg group`}
                        >
                          <div className="flex items-start">
                            <div className={`flex-shrink-0 p-2 rounded-lg ${
                              isDarkMode ? 'bg-blue-600/20' : 'bg-blue-50'
                            } mr-4`}>
                              <FileText className={`w-5 h-5 ${
                                isDarkMode ? 'text-blue-400' : 'text-blue-600'
                              }`} />
                            </div>
                            <div className="flex-1">
                              <h3 className={`text-lg font-medium mb-2 group-hover:text-blue-500 transition-colors ${
                                isDarkMode ? 'text-white' : 'text-gray-900'
                              }`}>
                                {article.title}
                              </h3>
                              <p className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'} line-clamp-2`}>
                                {article.excerpt}
                              </p>
                              <div className={`mt-4 flex items-center text-sm font-medium ${
                                isDarkMode ? 'text-blue-400' : 'text-blue-600'
                              } group-hover:opacity-80`}>
                                Read article
                                <ArrowRight className="w-4 h-4 ml-1 group-hover:translate-x-1 transition-transform" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                </div>
              </motion.div>
            )}
          </>
        ) : (
          /* Article Content with modern styling */
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            className="max-w-4xl mx-auto"
          >
            <div className="flex items-center mb-8">
              <button 
                onClick={() => setSelectedArticle(null)}
                className={`mr-3 p-2 rounded-lg ${
                  isDarkMode ? 'hover:bg-gray-800 text-gray-400' : 'hover:bg-gray-100 text-gray-600'
                } transition-colors`}
              >
                <ChevronRight className="w-5 h-5 transform rotate-180" />
              </button>
              <h2 className={`text-2xl font-bold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {helpCategories
                  .find(c => c.id === selectedCategory)
                  ?.articles.find(a => a.id === selectedArticle)?.title}
              </h2>
            </div>
            
            <div className={`p-8 rounded-xl ${
              isDarkMode 
                ? 'bg-gray-800/70 border border-gray-700/80 backdrop-blur-sm' 
                : 'bg-white/90 border border-gray-200/80'
            } shadow-lg`}>
              <div 
                className={`prose max-w-none ${isDarkMode ? 'text-gray-300 prose-headings:text-white prose-strong:text-white' : 'text-gray-700'}`}
                dangerouslySetInnerHTML={{ __html: renderMarkdown(articleContent || '') }} 
              />
              
              <div className="mt-10 pt-6 border-t border-gray-200/20 flex justify-between items-center">
                <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  Was this article helpful?
                </p>
                <div className="flex space-x-3">
                  <button className={`px-4 py-2 rounded-lg transition-colors ${
                    isDarkMode 
                      ? 'bg-gray-700 hover:bg-gray-600 text-white' 
                      : 'bg-gray-100 hover:bg-gray-200 text-gray-800'
                  }`}>
                    No
                  </button>
                  <button className="px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white transition-colors">
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        )}
        
        {/* Common Questions with modern AI styling */}
        {!selectedArticle && !selectedCategory && (
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.4 }}
            className="mb-16"
          >
            <h2 className={`text-2xl font-bold mb-8 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              <span className="flex items-center">
                <Lightbulb className="w-6 h-6 mr-2 text-blue-500" />
                Frequently Asked Questions
              </span>
            </h2>
            <div className="grid md:grid-cols-2 gap-6">
              {commonQuestions.map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 + index * 0.1, duration: 0.4 }}
                >
                  <div className={`p-6 rounded-xl border ${
                    isDarkMode 
                      ? 'bg-gray-800/70 border-gray-700/50 backdrop-blur-sm' 
                      : 'bg-white/90 border-gray-200/50'
                  } hover:shadow-lg transition-all duration-300`}>
                    <h3 className={`text-lg font-medium mb-3 ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {item.question}
                    </h3>
                    <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                      {item.answer}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        )}

        {/* Self-Service Support Options with AI-inspired design */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.4 }}
        >
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            <span className="flex items-center">
              <Zap className="w-6 h-6 mr-2 text-blue-500" />
              Quick Actions
            </span>
          </h2>
          <div className="grid md:grid-cols-3 gap-6">
            {selfServiceOptions.map((option, index) => {
              const Icon = option.icon;
              return (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 + index * 0.1, duration: 0.4 }}
                >
                  <Card
                    className={`group cursor-pointer hover:shadow-lg transition-all duration-300 h-full ${
                      isDarkMode 
                        ? 'bg-gray-800/70 border-gray-700/50 hover:bg-gray-800 backdrop-blur-sm' 
                        : 'bg-white/90 border-gray-200/50 hover:bg-white'
                    }`}
                    onClick={option.onClick}
                  >
                    <CardContent className="p-6">
                      <div className={`w-14 h-14 rounded-2xl flex items-center justify-center mb-4 ${option.color} bg-opacity-20`}>
                        <Icon className="w-7 h-7 text-white" />
                      </div>
                      <h3 className={`text-lg font-bold mb-3 group-hover:text-blue-500 transition-colors ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {option.title}
                      </h3>
                      <p className={`mb-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        {option.description}
                      </p>
                      <button className={`flex items-center text-sm font-medium px-3 py-1.5 rounded-lg bg-blue-500/10 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      } group-hover:bg-blue-500/20 transition-all`}>
                        {option.action}
                        <ArrowRight className="w-4 h-4 ml-2 group-hover:translate-x-1 transition-transform" />
                      </button>
                    </CardContent>
                  </Card>
                </motion.div>
              );
            })}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default HelpCenter;