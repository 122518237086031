import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Type } from 'lucide-react';

interface Point {
  x: number;
  y: number;
}

interface DrawingObject {
  id: string;
  type: string;
  points: Point[];
  color: string;
  text?: string;
}

interface DrawingLayerProps {
  width: number;
  height: number;
  selectedTool: string;
  selectedColor: string;
  scale?: { x: number; y: number };
  offset?: { x: number; y: number };
  onDrawingComplete?: (drawing: DrawingObject) => void;
}

const ChartDrawingLayer: React.FC<DrawingLayerProps> = ({
  width,
  height,
  selectedTool,
  selectedColor,
  scale = { x: 1, y: 1 },
  offset = { x: 0, y: 0 },
  onDrawingComplete
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [currentDrawing, setCurrentDrawing] = useState<DrawingObject | null>(null);
  const [drawings, setDrawings] = useState<DrawingObject[]>([]);
  const [startPoint, setStartPoint] = useState<Point | null>(null);
  const [isAdding, setIsAdding] = useState(false);

  // Handle pointer interactions only when a drawing tool is selected
  const shouldHandleInteractions = selectedTool !== 'select' && selectedTool !== '';

  const getPointerPosition = useCallback((e: React.PointerEvent<HTMLCanvasElement>): Point => {
    const canvas = canvasRef.current;
    if (!canvas) return { x: 0, y: 0 };

    const rect = canvas.getBoundingClientRect();
    return {
      x: (e.clientX - rect.left),
      y: (e.clientY - rect.top)
    };
  }, []);

  const handlePointerDown = useCallback((e: React.PointerEvent<HTMLCanvasElement>) => {
    if (!shouldHandleInteractions) return;

    const point = getPointerPosition(e);
    setStartPoint(point);
    setIsDrawing(true);

    // Initialize new drawing
    setCurrentDrawing({
      id: Date.now().toString(),
      type: selectedTool,
      points: [point],
      color: selectedColor
    });

    // If it's a text tool, show input
    if (selectedTool === 'text' || selectedTool === 'note') {
      setIsAdding(true);
    }
  }, [selectedTool, selectedColor, shouldHandleInteractions, getPointerPosition]);

  const handlePointerMove = useCallback((e: React.PointerEvent<HTMLCanvasElement>) => {
    if (!isDrawing || !currentDrawing || !startPoint) return;

    const point = getPointerPosition(e);

    // Update current drawing based on tool type
    switch (selectedTool) {
      case 'rectangle':
      case 'circle':
        setCurrentDrawing(prev => ({
          ...prev!,
          points: [startPoint, point]
        }));
        break;
      default:
        setCurrentDrawing(prev => ({
          ...prev!,
          points: [...prev!.points, point]
        }));
    }

    drawOnCanvas();
  }, [isDrawing, currentDrawing, startPoint, selectedTool, getPointerPosition]);

  const handlePointerUp = useCallback(() => {
    if (!currentDrawing || !shouldHandleInteractions) return;

    if (selectedTool !== 'text' && selectedTool !== 'note') {
      setDrawings(prev => [...prev, currentDrawing]);
      if (onDrawingComplete) {
        onDrawingComplete(currentDrawing);
      }
    }

    setIsDrawing(false);
    setStartPoint(null);
  }, [currentDrawing, selectedTool, shouldHandleInteractions, onDrawingComplete]);

  const drawOnCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!ctx || !canvas) return;

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Set default styles
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.lineWidth = 2;

    // Draw all completed drawings
    drawings.forEach(drawing => drawObject(ctx, drawing));

    // Draw current drawing
    if (currentDrawing) {
      drawObject(ctx, currentDrawing);
    }
  }, [drawings, currentDrawing]);

  const drawObject = (ctx: CanvasRenderingContext2D, obj: DrawingObject) => {
    ctx.strokeStyle = obj.color;
    ctx.fillStyle = obj.color;

    switch (obj.type) {
      case 'rectangle':
        drawRectangle(ctx, obj.points);
        break;
      case 'circle':
        drawCircle(ctx, obj.points);
        break;
      case 'text':
      case 'note':
        drawText(ctx, obj.points[0], obj.text || '');
        break;
      case 'long':
      case 'short':
        drawPositionMarker(ctx, obj.points, obj.type);
        break;
      default:
        drawFreehand(ctx, obj.points);
    }
  };

  const drawFreehand = (ctx: CanvasRenderingContext2D, points: Point[]) => {
    if (points.length < 2) return;
    
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    
    for (let i = 1; i < points.length; i++) {
      const xc = (points[i].x + points[i - 1].x) / 2;
      const yc = (points[i].y + points[i - 1].y) / 2;
      ctx.quadraticCurveTo(points[i - 1].x, points[i - 1].y, xc, yc);
    }
    
    ctx.stroke();
  };

  const drawRectangle = (ctx: CanvasRenderingContext2D, points: Point[]) => {
    if (points.length < 2) return;
    
    const [start, end] = points;
    const width = end.x - start.x;
    const height = end.y - start.y;

    ctx.beginPath();
    ctx.strokeRect(start.x, start.y, width, height);
    ctx.stroke();
  };

  const drawCircle = (ctx: CanvasRenderingContext2D, points: Point[]) => {
    if (points.length < 2) return;
    
    const [start, end] = points;
    const radius = Math.sqrt(
      Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2)
    );

    ctx.beginPath();
    ctx.arc(start.x, start.y, radius, 0, Math.PI * 2);
    ctx.stroke();
  };

  const drawText = (ctx: CanvasRenderingContext2D, point: Point, text: string) => {
    if (!text) return;

    ctx.font = '14px Inter';
    ctx.fillStyle = ctx.strokeStyle;
    
    // Add background for better visibility
    const metrics = ctx.measureText(text);
    const padding = 4;
    ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';
    ctx.fillRect(
      point.x - padding,
      point.y - 14 - padding,
      metrics.width + padding * 2,
      18 + padding * 2
    );
    
    ctx.fillStyle = selectedColor;
    ctx.fillText(text, point.x, point.y);
  };

  const drawPositionMarker = (ctx: CanvasRenderingContext2D, points: Point[], type: string) => {
    if (points.length < 2) return;
    
    const [start, end] = points;
    const isLong = type === 'long';
    
    // Draw line
    ctx.beginPath();
    ctx.moveTo(start.x, start.y);
    ctx.lineTo(end.x, end.y);
    ctx.stroke();

    // Draw arrow
    const angle = Math.atan2(end.y - start.y, end.x - start.x);
    const arrowLength = 15;
    const arrowWidth = Math.PI / 6;

    ctx.beginPath();
    ctx.moveTo(end.x, end.y);
    ctx.lineTo(
      end.x - arrowLength * Math.cos(angle - arrowWidth),
      end.y - arrowLength * Math.sin(angle - arrowWidth)
    );
    ctx.moveTo(end.x, end.y);
    ctx.lineTo(
      end.x - arrowLength * Math.cos(angle + arrowWidth),
      end.y - arrowLength * Math.sin(angle + arrowWidth)
    );
    ctx.stroke();

    // Draw label
    ctx.font = 'bold 12px Inter';
    ctx.fillStyle = isLong ? '#10B981' : '#EF4444';
    const label = isLong ? 'LONG' : 'SHORT';
    ctx.fillText(label, end.x + 10, end.y);
  };

  // Handle text input
  const handleTextComplete = useCallback((text: string) => {
    if (!currentDrawing || !text.trim()) return;

    const completedDrawing = {
      ...currentDrawing,
      text: text.trim()
    };

    setDrawings(prev => [...prev, completedDrawing]);
    if (onDrawingComplete) {
      onDrawingComplete(completedDrawing);
    }

    setCurrentDrawing(null);
    setIsAdding(false);
  }, [currentDrawing, onDrawingComplete]);

  // Redraw on changes
  useEffect(() => {
    drawOnCanvas();
  }, [drawOnCanvas, drawings]);

  return (
    <div 
      className="absolute inset-0" 
      style={{ 
        pointerEvents: shouldHandleInteractions ? 'auto' : 'none',
        // This ensures hover events pass through when not drawing
        touchAction: shouldHandleInteractions ? 'none' : 'auto',
        cursor: shouldHandleInteractions ? 'crosshair' : 'inherit'
      }}
    >
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        className="absolute inset-0"
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        onPointerLeave={handlePointerUp}
      />
      
      {isAdding && currentDrawing && (selectedTool === 'text' || selectedTool === 'note') && (
        <div 
          className="absolute bg-gray-900/90 p-2 rounded-lg border border-gray-700"
          style={{ 
            left: currentDrawing.points[0].x,
            top: currentDrawing.points[0].y 
          }}
        >
          <input
            type="text"
            autoFocus
            className="bg-transparent border-none text-white outline-none"
            placeholder={selectedTool === 'note' ? 'Add note...' : 'Add text...'}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleTextComplete(e.currentTarget.value);
              }
              if (e.key === 'Escape') {
                setIsAdding(false);
                setCurrentDrawing(null);
              }
            }}
            onBlur={(e) => handleTextComplete(e.target.value)}
          />
        </div>
      )}
    </div>
  );
};

export default ChartDrawingLayer;