import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Bookmark,
  Plus,
  Folder,
  Star,
  Edit,
  Trash,
  Check,
  Clock,
  Bell,
  ChevronRight
} from 'lucide-react';

const CreateWatchlist = () => {
  const { isDarkMode } = useTheme();

  const steps = [
    {
      title: 'Creating a Watchlist',
      description: 'Begin by creating a new watchlist to organize your stocks and market interests.',
      items: [
        'Click the "+ New Watchlist" button in the Watchlists view',
        'Enter a descriptive name for your watchlist',
        'Optionally add a description to remind yourself of the watchlist purpose',
        'Choose a color tag for visual organization (optional)',
        'Click "Create" to finalize your new watchlist'
      ],
      image: '/images/docs/create-watchlist.png'
    },
    {
      title: 'Adding Stocks to Watchlists',
      description: 'Multiple ways to populate your watchlists with stocks and other market instruments.',
      items: [
        'Search for stocks directly in the watchlist view and click the "+" button',
        'Use the "Save" button when viewing any stock detail page',
        'Add directly from Prism chat when stocks are mentioned',
        'Add from research results by clicking the bookmark icon',
        'Import stocks from a CSV file (Pro feature)'
      ],
      image: '/images/docs/add-to-watchlist.png'
    },
    {
      title: 'Organizing Watchlists',
      description: 'Keep your watchlists organized and relevant to your investment strategy.',
      items: [
        'Drag and drop to reorder stocks within a watchlist',
        'Move stocks between watchlists using the context menu',
        'Create folders to group related watchlists (Pro feature)',
        'Rename or edit watchlist details as your strategy evolves',
        'Archive watchlists you no longer need but want to keep for reference'
      ],
      image: '/images/docs/organize-watchlist.png'
    }
  ];

  const watchlistTypes = [
    {
      name: 'Sector-based',
      description: 'Group stocks by industry sectors (Tech, Healthcare, Energy, etc.)',
      icon: Folder
    },
    {
      name: 'Strategy-based',
      description: 'Organize by investment strategy (Growth, Value, Dividend, etc.)',
      icon: Star
    },
    {
      name: 'Time-based',
      description: 'Group by investment timeline (Short-term, Long-term, etc.)',
      icon: Clock
    },
    {
      name: 'Alert-focused',
      description: 'Watchlists specifically for price and event monitoring',
      icon: Bell
    }
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Bookmark className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Creating and Managing Watchlists
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Learn how to create, organize, and get the most out of Prism's watchlist feature
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              Watchlists are one of the most powerful organization tools in Prism, allowing you to 
              track and monitor stocks, sectors, and market instruments that interest you. 
              This guide will show you how to create effective watchlists, add stocks in various ways, 
              and organize them to enhance your investment research workflow.
            </p>
          </div>

          {/* Steps */}
          <div className="space-y-16 mb-16">
            {steps.map((step, index) => (
              <div key={index} className={`p-6 rounded-xl border ${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
              }`}>
                <h2 className={`text-2xl font-bold mb-4 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {step.title}
                </h2>
                
                <p className={`mb-6 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {step.description}
                </p>
                
                <div className="grid md:grid-cols-2 gap-8">
                  <div>
                    <h3 className={`text-sm font-medium mb-3 ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>How to:</h3>
                    <ul className={`space-y-2 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-600'
                    }`}>
                      {step.items.map((item, i) => (
                        <li key={i} className="flex items-start">
                          <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  
                  <div className={`rounded-lg overflow-hidden border ${
                    isDarkMode ? 'border-gray-700' : 'border-gray-200'
                  }`}>
                    <div className={`h-48 flex items-center justify-center ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                    }`}>
                      <p className={`text-sm ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        Illustration for {step.title.toLowerCase()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Watchlist Types */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Recommended Watchlist Types
          </h2>
          
          <div className="grid grid-cols-2 gap-6 mb-16">
            {watchlistTypes.map((type, index) => {
              const Icon = type.icon;
              return (
                <div 
                  key={index}
                  className={`p-6 rounded-xl border ${
                    isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                  }`}
                >
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg mr-4 ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    }`}>
                      <Icon className={`w-5 h-5 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <h3 className={`font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {type.name} Watchlists
                    </h3>
                  </div>
                  
                  <p className={`${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {type.description}
                  </p>
                </div>
              );
            })}
          </div>

          {/* Tips Section */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <h3 className={`text-xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Pro Tips
            </h3>
            
            <ul className={`space-y-3 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              <li className="flex items-start">
                <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                <span>Keep watchlists focused and specific rather than creating one large list</span>
              </li>
              <li className="flex items-start">
                <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                <span>Use the notes feature to record your investment thesis for each stock</span>
              </li>
              <li className="flex items-start">
                <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                <span>Regularly audit your watchlists to remove stocks that no longer fit your strategy</span>
              </li>
              <li className="flex items-start">
                <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                <span>Set up price alerts for key stocks in your watchlists to stay informed</span>
              </li>
            </ul>
          </div>

          {/* Related Documentation */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Related Documentation
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <a 
              href="/docs/saved-stocks"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Managing Saved Stocks
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Learn how to manage and organize stocks in your watchlists
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
            
            <a
              href="/docs/sort-filter"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Sorting & Filtering
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  How to sort and filter your watchlists for better analysis
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default CreateWatchlist;