import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { SEO } from '../../components/common/SEO';
import { motion } from 'framer-motion';

import {
  Newspaper,
  Download,
  ExternalLink,
  Mail,
  Phone,
  ArrowRight,
  Brain,
  LineChart,
  Zap,
  Globe,
  Target,
  Users
} from 'lucide-react';

interface PressRelease {
  id: string;
  date: string;
  title: string;
  category: string;
  excerpt: string;
  fullText: string;
  link: string;
}

const pressRelease: PressRelease = {
  id: 'beta-launch',
  date: 'March 28, 2024',
  title: 'Newsroom Launches Public Beta of AI-Powered Market Research Platform',
  category: 'Product Launch',
  excerpt: 'Revolutionary platform combines Claude 3.5 AI with real-time market data to democratize institutional-grade financial research and trading technology.',
  fullText: `VICTORIA, BC, March 28, 2024 -- Newsroom (news-room.ca) today announced the public beta launch of its groundbreaking AI-powered market research platform, designed to democratize access to institutional-grade financial analysis tools. The platform leverages Claude 3.5 AI technology to transform complex market data into actionable trading insights and custom widget generation.

"Today marks a significant milestone in our mission to level the playing field between individual and institutional investors," said Nik Zral, CEO and founder of Newsroom. "By combining advanced AI capabilities with real-time market data, we're making sophisticated financial analysis accessible to everyone."

Key Features of the Newsroom Platform:

• Prism AI Research Assistant: Powered by Claude 3.5, Prism analyzes terabytes of market data in real-time to provide comprehensive insights across stocks, sectors, and market trends.

• Instant Widget Generation: Users can describe their ideal trading widget in plain English and watch it materialize in seconds, complete with live data integration.

• Multi-Mode Analysis: Specialized research modes for technical analysis, fundamental research, and market sentiment provide targeted insights for different trading strategies.

• Real-Time Market Intelligence: Integration with premium data providers ensures users have access to the latest market movements, news, and analyst reports.

The public beta launch follows a successful private beta period that saw over 10,000 widgets generated and more than 100,000 market research queries processed. Early users have reported significant improvements in their trading research efficiency, with the platform reducing analysis time by up to 140x compared to traditional methods.

"Newsroom represents the future of market research," noted Marcus Rodriguez, CTO. "Our platform not only democratizes access to advanced trading technology but also helps traders make more informed decisions through AI-powered analysis of vast amounts of market data."

Availability and Access:
The public beta is now available at news-room.ca. Users can request access through the platform's website and receive their beta invitation within 24 hours.

For more information about Newsroom and its AI-powered market research platform, visit news-room.ca or contact hello@news-room.ca.

About Newsroom:
Founded in 2021, Newsroom is revolutionizing financial market research through AI technology. The platform combines advanced artificial intelligence with real-time market data to provide institutional-grade analysis tools for traders of all levels.`,
  link: '/press/beta-launch'
};

const Press = () => {
  const { isDarkMode } = useTheme();
  
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.1,
        delayChildren: 0.2
      } 
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
      <SEO 
        title="Newsroom | Public Beta Launch Press Release"
        description="Newsroom announces public beta launch of its AI-powered market research platform, democratizing access to institutional-grade financial analysis tools."
      />

      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 via-purple-600/10 to-blue-600/5" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
          <motion.div 
            className="text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex justify-center items-center mb-6">
              <div className="p-3 rounded-full bg-blue-500/10 backdrop-blur-sm">
                <Newspaper className={`w-10 h-10 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
              </div>
            </div>
            <h1 className={`text-4xl font-bold mb-4 font-heading tracking-tighter ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Press Room
            </h1>
            <p className={`text-xl max-w-3xl mx-auto mb-8 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Latest news and announcements from Newsroom
            </p>
            <div className="flex flex-wrap justify-center gap-4">
              <a
                href="/press-kit.zip"
                className="inline-flex items-center px-6 py-3 rounded-xl bg-blue-600 text-white hover:bg-blue-700 transition-all shadow-sm hover:shadow-md"
              >
                <Download className="w-5 h-5 mr-2" />
                Download Press Kit
              </a>
              <a
                href="mailto:hello@news-room.ca"
                className={`inline-flex items-center px-6 py-3 rounded-xl ${
                  isDarkMode
                    ? 'bg-gray-800 text-white hover:bg-gray-700 border border-gray-700'
                    : 'bg-white text-gray-900 hover:bg-gray-50 border border-gray-200'
                } transition-all shadow-sm hover:shadow-md`}
              >
                <Mail className="w-5 h-5 mr-2" />
                Contact Press Team
              </a>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Press Release Content */}
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <motion.article
          className={`rounded-2xl border ${
            isDarkMode 
              ? 'bg-gray-800/70 border-gray-700/50' 
              : 'bg-white border-gray-200'
          } overflow-hidden`}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="p-8">
            <div className="flex items-center mb-6">
              <span className={`text-sm px-3 py-1 rounded-full ${
                isDarkMode ? 'bg-blue-900/40 text-blue-300' : 'bg-blue-100 text-blue-800'
              }`}>
                {pressRelease.category}
              </span>
              <span className={`text-sm ml-auto ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`}>
                {pressRelease.date}
              </span>
            </div>
            
            <h2 className={`text-2xl md:text-3xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              {pressRelease.title}
            </h2>

            <div className={`prose max-w-none ${
              isDarkMode ? 'prose-invert' : ''
            } ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              {pressRelease.fullText.split('\n\n').map((paragraph, index) => (
                <p key={index} className="mb-4">
                  {paragraph}
                </p>
              ))}
            </div>
          </div>
        </motion.article>
      </div>

      {/* Press Contact */}
      <div className={`${isDarkMode ? 'bg-gray-800/80' : 'bg-gray-50/80'} py-16 backdrop-blur-sm`}>
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            className={`p-8 rounded-2xl border ${
              isDarkMode 
                ? 'bg-gray-900/70 border-gray-700/50' 
                : 'bg-white/80 border-gray-200'
            } text-center shadow-lg backdrop-blur-sm`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h2 className={`text-2xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Media Contact
            </h2>
            
            <p className={`mb-8 max-w-2xl mx-auto ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              For press inquiries, interview requests, or additional information about Newsroom's public beta launch,
              please contact:
            </p>
            
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-10">
              <a
                href="mailto:hello@news-room.ca"
                className="inline-flex items-center justify-center group"
              >
                <Mail className={`w-5 h-5 mr-3 ${
                  isDarkMode ? 'text-blue-400 group-hover:text-blue-300' : 'text-blue-500 group-hover:text-blue-600'
                } transition-colors`} />
                <span className={`${
                  isDarkMode 
                    ? 'text-gray-200 group-hover:text-white' 
                    : 'text-gray-700 group-hover:text-gray-900'
                } transition-colors`}>
                  hello@news-room.ca
                </span>
              </a>
              
              <a
                href="tel:+17787510983"
                className="inline-flex items-center justify-center group"
              >
                <Phone className={`w-5 h-5 mr-3 ${
                  isDarkMode ? 'text-blue-400 group-hover:text-blue-300' : 'text-blue-500 group-hover:text-blue-600'
                } transition-colors`} />
                <span className={`${
                  isDarkMode 
                    ? 'text-gray-200 group-hover:text-white' 
                    : 'text-gray-700 group-hover:text-gray-900'
                } transition-colors`}>
                  +1 (778) 751-0983
                </span>
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Press;