// src/pages/MarketChat.tsx

import React from 'react';
import { SEO } from '../components/common/SEO';
import MarketChatComponent from '../components/market/MarketChat';

const MarketChatPage: React.FC = () => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "Newsroom Market Chat",
    "description": "AI-powered market analysis chat with real-time data integration",
    "applicationCategory": "FinanceApplication",
    "operatingSystem": "Any"
  };

  return (
    <>
      <SEO
        title="Market Intelligence Chat | Newsroom"
        description="Chat with our AI market analyst powered by real-time financial data and advanced market intelligence."
        jsonLd={jsonLd}
      />
      <MarketChatComponent />
    </>
  );
};

export default MarketChatPage;