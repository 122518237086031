// src/contexts/WalkthroughContext.tsx
import React, { createContext, useContext, useState } from 'react';
import { WelcomeLayout, ChartsLayout, AIAssistantLayout, DashboardLayout } from '../components/walkthrough/WalkthroughLayouts';

interface WalkthroughContextType {
  isWalkthroughOpen: boolean;
  isWalkthroughActive: boolean;
  openWalkthrough: () => void;
  closeWalkthrough: () => void;
  startWalkthrough: () => void;
  completeWalkthrough: () => void;
  skipWalkthrough: () => void;
  canShowWalkthrough: (path: string) => boolean;
  walkthroughSteps: any[];
}

const WalkthroughContext = createContext<WalkthroughContextType | undefined>(undefined);

export const WalkthroughProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isWalkthroughOpen, setIsWalkthroughOpen] = useState(false);
  const [isWalkthroughActive, setIsWalkthroughActive] = useState(false);

  // Define which paths can show the walkthrough
  const canShowWalkthrough = (path: string) => {
    return path.startsWith('/dashboard');
  };

  const walkthroughSteps = [
    {
      id: 'welcome',
      title: 'Welcome to Newsroom',
      description: `Let's take a quick tour of the platform's features.`,
      customLayout: <WelcomeLayout />
    },
    {
      id: 'dashboard',
      title: 'Your Dashboard',
      description: 'This is your central hub for market analysis, trade ideas, and real-time news.',
      customLayout: <DashboardLayout />
    },
    {
      id: 'charts',
      title: 'Interactive Charts',
      description: 'Analyze market data with our powerful charting tools.',
      customLayout: <ChartsLayout />
    },
    {
      id: 'ai-assistant',
      title: 'AI Market Assistant',
      description: 'Get instant insights and ask questions about market data.',
      customLayout: <AIAssistantLayout />
    },
    {
      id: 'watchlists',
      title: 'Manage Watchlists',
      description: 'Create custom watchlists to track your favorite assets.',
      targetElement: 'watchlists-section',
    },
    {
      id: 'settings',
      title: 'Personalize Your Experience',
      description: 'Customize your preferences and account settings.',
      targetElement: 'settings-link',
    },
  ];

  return (
    <WalkthroughContext.Provider 
      value={{
        isWalkthroughOpen,
        isWalkthroughActive,
        openWalkthrough: () => setIsWalkthroughOpen(true),
        closeWalkthrough: () => setIsWalkthroughOpen(false),
        startWalkthrough: () => setIsWalkthroughActive(true),
        completeWalkthrough: () => setIsWalkthroughActive(false),
        skipWalkthrough: () => setIsWalkthroughActive(false),
        canShowWalkthrough,
        walkthroughSteps
      }}
    >
      {children}
    </WalkthroughContext.Provider>
  );
};

export const useWalkthrough = () => {
  const context = useContext(WalkthroughContext);
  if (context === undefined) {
    throw new Error('useWalkthrough must be used within a WalkthroughProvider');
  }
  return context;
};