import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import { marketDataService } from '../../services/marketDataService';
import { searchService, NewsArticle } from '../../services/searchService';
import { motion, AnimatePresence } from 'framer-motion';
import { useIdleDetection } from '../../hooks/useIdleDetection';
import IdleDialog from '../../components/common/IdleDialog';
import { cachedPromptService } from '../../services/cachedPromptService';
import {
  Newspaper,
  TrendingUp,
  ArrowUpRight,
  Brain,
  ExternalLink,
  ChevronRight,
  MessageSquare,
  Clock,
  Search,
  Filter,
  Pin,
  BookOpen,
  BarChart2,
  TrendingDown,
  Flame,
  Zap,
  Eye,
  Share2,
  BookmarkPlus,
  ArrowDownRight,
  Hash,
  X,
  History,
  Tag,
  Building2,
  Plus,
  Maximize2,
  Settings,
  RefreshCcw,
  MinusIcon,
  Layers,
  Trash2,
  Box,
  Lightbulb,
  Folder,
  LineChart,
  Bell,
  Check
} from 'lucide-react';
import StockSparkline from './StockSparkline';
import FeaturedNews from './FeaturedNews';
import { useAuth } from '../../contexts/AuthContext';
import { SaveItemButton } from '../../components/common/SaveItemButton';
import { useWatchlists } from '../../hooks/useWatchlists';
import WelcomeStatePreview, { WelcomeStatePreviewRef } from '../../components/market/WelcomeStatePreview';
import api from '../../services/api';
import { DragDropContext, Droppable, Draggable, DroppableProvided, DraggableProvided } from 'react-beautiful-dnd';
import { useStockSearch } from '../../hooks/useStockSearch';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import TrendingMarketFeed from '../../components/market/TrendingMarketFeed';
import { trendingFeedService } from '../../services/trendingFeedService';
import { marketSentimentService } from '../../services/marketSentimentService';
import TradeIdeasFeed, { TradeIdeasFeedRef } from './TradeIdeasFeed';
import HelpMenu from '../../components/ui/HelpMenu';
import { useCallback } from 'react';
import debounce from 'lodash/debounce';
import { createPortal } from 'react-dom';
import { useStockView } from '../../contexts/StockViewContext';
import MobileWarning from '../../components/MobileWarning';

// Add this type or ensure it's imported
interface SearchResult {
  ticker: string;
  name: string;
  market?: string;
  price?: number;
  change?: number;
  changePercent?: number;
}

// Define the ResearchMode interface
interface ResearchMode {
  id: string;
  name: string;
  prefix: string;
  description: string;
}
const SaveStockModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  stock: { ticker: string; name: string } | null;
  isDarkMode: boolean;
}> = ({ isOpen, onClose, stock, isDarkMode }) => {
  const { watchlists, createList, saveItem, addToList } = useWatchlists();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newListName, setNewListName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSaveIndividual = async () => {
    if (!stock) return;
    
    setIsLoading(true);
    try {
      await saveItem({
        type: 'STOCK',
        identifier: stock.ticker,
        title: stock.name,
        metadata: {
          addedFrom: 'welcomeState'
        }
      });
      onClose();
    } catch (error) {
      console.error('Failed to save stock:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateList = async () => {
    if (!newListName.trim() || !stock) return;
    
    setIsLoading(true);
    try {
      const newList = await createList({ name: newListName });
      if (newList?.id) {
        await addToList(stock.ticker, newList.id);
      }
      onClose();
    } catch (error) {
      console.error('Failed to create list:', error);
    } finally {
      setIsLoading(false);
      setShowCreateForm(false);
    }
  };

  const handleAddToList = async (listId: string) => {
    if (!stock) return;
    
    setIsLoading(true);
    try {
      await addToList(stock.ticker, listId);
      onClose();
    } catch (error) {
      console.error('Failed to add to list:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && stock && (
        <>
          <motion.div 
            className="fixed inset-0 bg-black/20 backdrop-blur-sm z-[99]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
          />
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
              w-full max-w-sm z-[100] rounded-xl shadow-lg overflow-hidden
              ${isDarkMode ? 'bg-gray-900 border-gray-800' : 'bg-white border-gray-200'}
              border`}
          >
            {/* Header */}
            <div className="flex items-center justify-between p-4 border-b border-gray-800">
              <h3 className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Save {stock.ticker}
              </h3>
              <button onClick={onClose} className="text-gray-500 hover:text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" 
                    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>

            {/* Content */}
            <div className="p-4">
              {/* Individual Save Option */}
              <button
                onClick={handleSaveIndividual}
                className={`w-full flex items-center space-x-3 p-3 rounded-lg mb-4
                  ${isDarkMode 
                    ? 'bg-blue-500/10 text-blue-400 hover:bg-blue-500/20' 
                    : 'bg-blue-50 text-blue-600 hover:bg-blue-100'}`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
                    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" 
                    className="mr-2">
                  <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                </svg>
                <span>Save as Individual Item</span>
              </button>

              {/* Watchlists Section */}
              <div className="mb-4">
                <h4 className={`text-sm font-medium mb-2 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  Add to Watchlist
                </h4>
                <div className="space-y-2 max-h-48 overflow-y-auto">
                  {watchlists.map(list => (
                    <button
                      key={list.id}
                      onClick={() => handleAddToList(list.id)}
                      className={`w-full flex items-center justify-between p-3 rounded-lg
                        ${isDarkMode 
                          ? 'hover:bg-gray-800 text-gray-300' 
                          : 'hover:bg-gray-100 text-gray-700'}`}
                    >
                      <div className="flex items-center space-x-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" 
                            className="text-gray-400">
                          <line x1="8" y1="6" x2="21" y2="6"></line>
                          <line x1="8" y1="12" x2="21" y2="12"></line>
                          <line x1="8" y1="18" x2="21" y2="18"></line>
                          <line x1="3" y1="6" x2="3.01" y2="6"></line>
                          <line x1="3" y1="12" x2="3.01" y2="12"></line>
                          <line x1="3" y1="18" x2="3.01" y2="18"></line>
                        </svg>
                        <span>{list.name}</span>
                      </div>
                      <span className="text-xs text-gray-500">
                        {list.items?.length || 0} items
                      </span>
                    </button>
                  ))}
                </div>
              </div>

              {/* Create New List Section */}
              {showCreateForm ? (
                <div className="space-y-2">
                  <input
                    type="text"
                    value={newListName}
                    onChange={(e) => setNewListName(e.target.value)}
                    placeholder="Enter list name..."
                    className={`w-full px-3 py-2 rounded-lg border ${
                      isDarkMode 
                        ? 'bg-gray-800 border-gray-700 text-white' 
                        : 'bg-white border-gray-300 text-gray-900'
                    } focus:ring-2 focus:ring-blue-500`}
                  />
                  <div className="flex space-x-2">
                    <button
                      onClick={() => setShowCreateForm(false)}
                      className={`flex-1 py-2 rounded-lg ${
                        isDarkMode 
                          ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' 
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleCreateList}
                      disabled={!newListName.trim() || isLoading}
                      className="flex-1 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600
                        disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isLoading ? 'Creating...' : 'Create'}
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => setShowCreateForm(true)}
                  className={`w-full flex items-center justify-center space-x-2 p-3 rounded-lg
                    ${isDarkMode 
                      ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' 
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
                      stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" 
                      className="mr-2">
                    <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
                    <line x1="12" y1="11" x2="12" y2="17"></line>
                    <line x1="9" y1="14" x2="15" y2="14"></line>
                  </svg>
                  <span>Create New List</span>
                </button>
              )}
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};
// Define the research modes array
const researchModes: ResearchMode[] = [
  { id: 'financial', name: 'Financial Analysis', prefix: 'ticker', description: 'Financial data and analysis' },
  { id: 'technical', name: 'Technical Analysis', prefix: 'tech', description: 'Chart patterns and technical indicators' },
  { id: 'news', name: 'News Analysis', prefix: 'news', description: 'News and sentiment analysis' },
  { id: 'macro', name: 'Macro Economics', prefix: 'macro', description: 'Economic trends and indicators' }
];
interface NewsCategory {
  id: string;
  name: string;
  icon: React.ElementType;
}

interface WatchedStock {
  id: string;
  symbol: string;
}

interface WatchedStockWithData extends WatchedStock {
  price: number;
  change: number;
  changePercent: number;
}

interface StockPreviewProps {
  stocks: WatchedStock[];
  isDarkMode: boolean;
  onEditStocks: () => void;
}

interface TrendingTerm {
  term: string;
  mentions: number;
  sentiment: 'positive' | 'negative' | 'neutral';
}

const SearchBar: React.FC = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [results, setResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });
  const [isFocused, setIsFocused] = useState(false);
  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);
  const { openStockModal } = useStockView(); // Add this line to access the stock modal
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if click is outside both the search bar and the dropdown
      if (
        searchRef.current && 
        !searchRef.current.contains(event.target as Node) &&
        // Make sure we're not clicking inside the portal
        !event.composedPath().some(el => 
          el instanceof HTMLElement && 
          el.closest('#search-dropdown-portal > div')
        )
      ) {
        setIsOpen(false);
        setIsFocused(false);
      }
    };

    // Add event listener when dropdown is open
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    
    // Cleanup the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  // Add this effect to create a portal container
  useEffect(() => {
    // Create portal container if it doesn't exist
    let container = document.getElementById('search-dropdown-portal');
    if (!container) {
      container = document.createElement('div');
      container.id = 'search-dropdown-portal';
      document.body.appendChild(container);
    }
    setPortalContainer(container);
    
    return () => {
      // Cleanup only if we created it
      if (container && container.id === 'search-dropdown-portal') {
        document.body.removeChild(container);
      }
    };
  }, []);

  

  // Handle search query changes with debounce
  const handleSearchChange = useCallback(
    debounce(async (searchQuery: string) => {
      if (!searchQuery.trim()) {
        setResults([]);
        return;
      }

      setIsLoading(true);
      try {
        const data = await marketDataService.searchStocks(searchQuery);
        setResults(data);
      } catch (error) {
        console.error('Search failed:', error);
        setResults([]);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    handleSearchChange(query);
  }, [query, handleSearchChange]);

  useEffect(() => {
    // Load recent searches from localStorage
    const saved = localStorage.getItem('recent-searches');
    if (saved) {
      try {
        setRecentSearches(JSON.parse(saved).slice(0, 5));
      } catch (e) {
        console.error('Failed to parse recent searches', e);
      }
    }
  }, []);

  // Update dropdown position when the input is focused or window is resized
  useEffect(() => {
    const updatePosition = () => {
      if (searchRef.current) {
        const rect = searchRef.current.getBoundingClientRect();
        
        setDropdownPosition({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX,
          width: rect.width
        });
      }
    };

    // Update position immediately when opening
    if (isOpen) {
      updatePosition();
    }

    window.addEventListener('resize', updatePosition);
    document.addEventListener('scroll', updatePosition, true);
    
    return () => {
      window.removeEventListener('resize', updatePosition);
      document.removeEventListener('scroll', updatePosition, true);
    };
  }, [isOpen]);

  const addToRecentSearches = (term: string) => {
    const updated = [term, ...recentSearches.filter(s => s !== term)].slice(0, 5);
    setRecentSearches(updated);
    localStorage.setItem('recent-searches', JSON.stringify(updated));
  };

  const handleResultClick = (ticker: string) => {
    addToRecentSearches(ticker);
    // Replace this line:
    // navigate(`/market/${ticker}`);
    // With this:
    openStockModal(ticker);
    setIsOpen(false);
    setQuery('');
    setIsFocused(false);
  };

  return (
    <div ref={searchRef} className="relative">
      {/* Search input */}
      
      <div className={`relative flex items-center ${
        isFocused 
          ? isDarkMode 
            ? 'ring-2 ring-blue-500/50' 
            : 'ring-2 ring-blue-400/50'
          : ''
      }`}>
        <div className={`relative flex items-center w-[30vw] rounded-lg transition-all duration-200
          ${isDarkMode 
            ? 'bg-gray-800/80 hover:bg-gray-800 border-gray-700/80' 
            : 'bg-white/90 hover:bg-white border-gray-200/90'
          } border shadow-sm ${isFocused ? 'shadow-md' : ''}`}
        >
          <div className="flex items-center pl-3 pr-2">
            <Search className={`w-4 h-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
          </div>
          <input
            ref={inputRef}
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onFocus={() => {
              setIsOpen(true);
              setIsFocused(true);
            }}
            placeholder="Search stocks or topics..."
            className={`w-full py-2.5 px-0 text-sm border-none outline-none ${
              isDarkMode ? 'bg-transparent text-white placeholder-gray-500' : 'bg-transparent text-gray-800 placeholder-gray-400'
            } focus:outline-none focus:ring-0`}
          />
          {(query || isLoading) && (
            <div className="flex items-center pr-3">
              {isLoading ? (
                <div className="animate-spin h-4 w-4 border-2 rounded-full border-t-transparent border-blue-500"></div>
              ) : (
                <button 
                  onClick={() => {
                    setQuery('');
                    inputRef.current?.focus();
                  }}
                  className={`p-0.5 rounded-full hover:bg-gray-200/20 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}
                >
                  <X className="w-3.5 h-3.5" />
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Render the dropdown using a portal */}
      {isOpen && portalContainer && createPortal(
        <div 
          className={`fixed mt-1 p-2 rounded-lg shadow-lg z-[9999] border ${
            isDarkMode ? 'bg-gray-800/95 border-gray-700' : 'bg-white/95 border-gray-200'
          } backdrop-blur-sm overflow-y-auto`}
          style={{ 
            top: `${dropdownPosition.top}px`, 
            left: `${dropdownPosition.left}px`, 
            width: `${dropdownPosition.width}px`,
            maxHeight: '350px'
          }}
        >
          {/* Search hints */}
          {!query && (
            <div className="px-3 py-2">
              <p className={`text-xs mb-2 font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                SEARCH TIPS
              </p>
              <ul className={`text-xs space-y-1.5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                <li className="flex items-center">
                  <span className="h-1.5 w-1.5 rounded-full bg-blue-500 mr-2"></span>
                  Enter ticker symbol (e.g., AAPL, MSFT)
                </li>
                <li className="flex items-center">
                  <span className="h-1.5 w-1.5 rounded-full bg-blue-500 mr-2"></span>
                  Search by company name (e.g., Apple, Microsoft)
                </li>
                <li className="flex items-center">
                  <span className="h-1.5 w-1.5 rounded-full bg-blue-500 mr-2"></span>
                  Search market topics (e.g., Tech Stocks, Earnings)
                </li>
              </ul>
            </div>
          )}

          {/* Recent searches */}
          {!query && recentSearches.length > 0 && (
            <div className={`mt-2 border-t border-b py-2 px-3 ${isDarkMode ? 'border-gray-700/30' : 'border-gray-200/50'}`}>
              <p className={`text-xs mb-2 font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                RECENT SEARCHES
              </p>
              <div className="flex flex-wrap gap-2">
                {recentSearches.map((search, index) => (
                  <button
                    key={index}
                    onClick={() => handleResultClick(search)}
                    className={`px-2 py-1 text-xs rounded-md flex items-center transition-colors ${
                      isDarkMode 
                        ? 'bg-gray-700 text-gray-300 hover:bg-gray-600 hover:text-white' 
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200 hover:text-gray-900'
                    }`}
                  >
                    <Clock className="w-3 h-3 mr-1.5 opacity-70" />
                    {search}
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Search results */}
          {query && results.length > 0 && (
            <div>
              <p className={`text-xs px-3 py-2 font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                RESULTS
              </p>
              <div className="space-y-0.5">
                {results.map((result) => (
                  <div
                    key={result.ticker}
                    className={`w-full px-3 py-2.5 flex items-center justify-between transition-all duration-150 rounded-md ${
                      isDarkMode 
                        ? 'hover:bg-blue-500/10' 
                        : 'hover:bg-blue-50'
                    } group`}
                  >
                    <button
                      onClick={() => handleResultClick(result.ticker)}
                      className="flex flex-1 items-center"
                    >
                      <div className={`w-8 h-8 rounded-md flex items-center justify-center mr-3 overflow-hidden ${
                        isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                      }`}>
                        {/* Show stock logo or fallback */}
                        <img
                          src={`/api/market/company-logo/${result.ticker}`}
                          alt=""
                          className="w-8 h-8 object-contain"
                          onError={(e) => {
                            e.currentTarget.style.display = 'none';
                            if (e.currentTarget.parentElement) {
                              e.currentTarget.parentElement.innerHTML = `
                                <div class="text-sm font-bold ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}">
                                  ${result.ticker.substring(0, 2)}
                                </div>
                              `;
                            }
                          }}
                        />
                      </div>
                      <div className="text-left">
                        <div className="flex items-center">
                          <span className={`font-mono font-bold text-sm ${
                            isDarkMode ? 'text-white' : 'text-gray-900'
                          }`}>{result.ticker}</span>
                          {result.market && (
                            <span className={`ml-2 text-xs px-1.5 py-0.5 rounded ${
                              isDarkMode ? 'bg-gray-700 text-gray-400' : 'bg-gray-100 text-gray-600'
                            }`}>{result.market}</span>
                          )}
                        </div>
                        <div className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                          {result.name}
                        </div>
                      </div>
                    </button>
                    
                    <div className="flex items-center space-x-2">
                      {/* Price info if available */}
                      {result.price && (
                        <div className="text-right mr-3 hidden sm:block">
                          <div className={`text-sm font-mono ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                            ${result.price.toFixed(2)}
                          </div>
                          {result.changePercent && (
                            <div className={`text-xs ${result.changePercent >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                              {result.changePercent >= 0 ? '+' : ''}{result.changePercent.toFixed(2)}%
                            </div>
                          )}
                        </div>
                      )}
                      
                      {/* Add to chat button - With Box icon */}
                      <button 
                        onClick={() => navigate('/dashboard/market-chat', { 
                          state: { initialPrompt: `Analyze ${result.ticker} stock` }
                        })}
                        className={`p-1.5 rounded-lg ${
                          isDarkMode ? 'hover:bg-gray-700 text-gray-300' : 'hover:bg-gray-100 text-gray-600'
                        }`}
                        title="Analyze in Chat"
                      >
                        <Box className="w-4 h-4" />
                      </button>
                      
                      {/* Save to watchlist button - Always visible */}
                      <SaveItemButton
                        type="STOCK"
                        identifier={result.ticker}
                        title={result.name}
                        variant="icon"
                      />
                      
                      {/* View details button - Always visible */}
                      <button 
                      onClick={() => {
                        openStockModal(result.ticker);
                        setIsOpen(false);
                      }}
                      className={`p-1.5 rounded-lg ${
                        isDarkMode ? 'hover:bg-gray-700 text-gray-300' : 'hover:bg-gray-100 text-gray-600'
                      }`}
                      title="View Details"
                    >
                      <ArrowUpRight className="w-4 h-4" />
                    </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* No results found */}
          {query && results.length === 0 && !isLoading && (
            <div className={`p-4 text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              <p>No results found for "{query}"</p>
              <p className="text-xs mt-1 opacity-70">Try another search term</p>
            </div>
          )}
        </div>,
        portalContainer
      )}
    </div>
  );
};

const TickerEditModal: React.FC<{ 
  isOpen: boolean; 
  onClose: () => void; 
  isDarkMode: boolean;
}> = ({ isOpen, onClose, isDarkMode }) => {
  const [activeTab, setActiveTab] = useState<'default' | 'custom'>('default');
  // Add local state for ticker speed that loads from the global state
  const [localTickerSpeed, setLocalTickerSpeed] = useState(60); // Default to 60 seconds, or use the same default as tickerSpeed
  const [tickerSpeed, setTickerSpeed] = useState<number>(60); // Default 60s animation duration

  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center"
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        className={`w-full max-w-md p-6 rounded-2xl shadow-xl ${
          isDarkMode ? 'bg-gray-900' : 'bg-white'
        }`}
      >
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <h3 className={`text-lg font-semibold ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>Customize News Ticker</h3>
          <button
            onClick={onClose}
            className={`p-2 rounded-lg transition-colors ${
              isDarkMode 
                ? 'hover:bg-gray-800 text-gray-400' 
                : 'hover:bg-gray-100 text-gray-600'
            }`}
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Ticker Type Selector */}
        <div className="flex space-x-2 mb-6">
          {/* Default ticker button */}
          <button
            className={`flex-1 p-3 rounded-xl border transition-all ${
              activeTab === 'default' 
                ? isDarkMode 
                  ? 'bg-blue-500/20 border-blue-500 text-blue-400' 
                  : 'bg-blue-50 border-blue-200 text-blue-600'
                : isDarkMode
                  ? 'bg-gray-800 border-gray-700 text-gray-400'
                  : 'bg-gray-100 border-gray-200 text-gray-600'
            }`}
            onClick={() => setActiveTab('default')}
          >
            <div className="flex flex-col items-center space-y-3">
              {/* SVG for default ticker (existing code) */}
              <svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/* Frame with deep dark blue background */}
                <rect x="0" y="0" width="40" height="24" rx="3" fill="#1a2744" />
                
                {/* Top and bottom lines/borders */}
                <rect x="2" y="7" width="36" height="1" rx="0.5" fill="#4a5568" />
                <rect x="2" y="16" width="36" height="1" rx="0.5" fill="#4a5568" />
                
                {/* Animated ticker text elements */}
                <rect x="26" y="9.5" width="8" height="5" rx="1" fill="#ffc107" />
                <rect x="18" y="9.5" width="5" height="5" rx="1" fill="white" />
                <rect x="11" y="9.5" width="4" height="5" rx="1" fill="white" opacity="0.7" />
                <rect x="6" y="9.5" width="2" height="5" rx="1" fill="white" opacity="0.4" />
                
                {/* Subtle ticker movement indicators */}
                <path d="M36 12L38 10V14L36 12Z" fill="white" opacity="0.6" />
                <path d="M4 12L2 10V14L4 12Z" fill="white" opacity="0.6" />
              </svg>
              <span className="text-sm font-medium">Default Ticker</span>
            </div>
          </button>

          {/* Custom ticker button */}
          <button
            className={`flex-1 p-3 rounded-xl border transition-all ${
              activeTab === 'custom' 
                ? isDarkMode 
                  ? 'bg-blue-500/20 border-blue-500 text-blue-400' 
                  : 'bg-blue-50 border-blue-200 text-blue-600'
                : isDarkMode
                  ? 'bg-gray-800 border-gray-700 text-gray-400'
                  : 'bg-gray-100 border-gray-200 text-gray-600'
            }`}
            onClick={() => setActiveTab('custom')}
          >
            <div className="flex flex-col items-center space-y-3">
              {/* SVG for custom ticker (existing code) */}
              <svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/* Frame with deep dark blue background */}
                <rect x="0" y="0" width="40" height="24" rx="3" fill="#1a2744" />
                
                {/* Top and bottom lines/borders */}
                <rect x="2" y="7" width="36" height="1" rx="0.5" fill="#4a5568" />
                <rect x="2" y="16" width="36" height="1" rx="0.5" fill="#4a5568" />
                
                {/* Define Rainbow gradient */}
                <defs>
                  <linearGradient id="rainbow" x1="6" y1="12" x2="34" y2="12" gradientUnits="userSpaceOnUse">
                    <stop offset="0%" stopColor="#ff0000" />
                    <stop offset="16.6%" stopColor="#ff8000" />
                    <stop offset="33.3%" stopColor="#ffff00" />
                    <stop offset="50%" stopColor="#00ff00" />
                    <stop offset="66.6%" stopColor="#00ffff" />
                    <stop offset="83.3%" stopColor="#0080ff" />
                    <stop offset="100%" stopColor="#8000ff" />
                  </linearGradient>
                </defs>
                
                {/* Animated ticker text elements with rainbow gradient fill */}
                <rect x="26" y="9.5" width="8" height="5" rx="1" fill="url(#rainbow)" />
                <rect x="18" y="9.5" width="5" height="5" rx="1" fill="url(#rainbow)" />
                <rect x="11" y="9.5" width="4" height="5" rx="1" fill="url(#rainbow)" opacity="0.7" />
                <rect x="6" y="9.5" width="2" height="5" rx="1" fill="url(#rainbow)" opacity="0.4" />
                
                {/* Subtle ticker movement indicators */}
                <path d="M36 12L38 10V14L36 12Z" fill="white" opacity="0.6" />
                <path d="M4 12L2 10V14L4 12Z" fill="white" opacity="0.6" />
              </svg>
              <span className="text-sm font-medium">Custom Ticker</span>
            </div>
          </button>
        </div>

        {/* Ticker Speed Control - Add this section above content */}
        <div className={`mb-6 p-4 rounded-lg ${
          isDarkMode ? 'bg-gray-800/50' : 'bg-gray-50'
        }`}>
          <h4 className={`text-sm font-medium mb-3 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Ticker Speed
          </h4>
          
          <div className="flex items-center space-x-3">
            <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              Faster
            </span>
            <input
              type="range"
              min="20"
              max="120"
              value={tickerSpeed}
              onChange={(e) => setTickerSpeed(parseInt(e.target.value))}
              className="flex-1"
            />
            <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              Slower
            </span>
          </div>
          
          <div className={`mt-2 text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            Current: {localTickerSpeed}s per cycle
          </div>
        </div>

        {/* Content based on active tab */}
        {activeTab === 'default' ? (
          <div className={`space-y-4 p-4 rounded-lg ${
            isDarkMode ? 'bg-gray-800/50' : 'bg-gray-50'
          }`}>
            <div className="flex items-center space-x-3">
              <svg className="w-5 h-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" 
                   viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" 
                   strokeLinecap="round" strokeLinejoin="round">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
              <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                Using default market news ticker
              </p>
            </div>
            <p className={`text-xs ${isDarkMode ? 'text-gray-500' : 'text-gray-600'}`}>
              The default ticker displays trending market topics, latest headlines, and important market updates.
            </p>
          </div>
        ) : (
          <div className="space-y-4">
            <div className={`p-6 rounded-lg flex flex-col items-center justify-center ${
              isDarkMode ? 'bg-gray-800/70 border border-gray-700' : 'bg-gray-50 border border-gray-200'
            }`}>
              <div className={`w-16 h-16 rounded-full flex items-center justify-center ${
                isDarkMode ? 'bg-blue-900/30' : 'bg-blue-50'
              } mb-3`}>
                <Lightbulb className={`w-8 h-8 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
              </div>
              <h4 className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Coming Soon
              </h4>
              <p className={`text-sm text-center mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                Create your own custom news ticker with your preferred topics, sources, and update frequency.
              </p>
              
              <div className={`mt-4 p-3 rounded-lg w-full ${isDarkMode ? 'bg-gray-900/80' : 'bg-white'}`}>
                <div className="flex flex-col space-y-2">
                  <div className="flex items-center space-x-2">
                    <Check className="w-4 h-4 text-blue-500" />
                    <span className={`text-xs ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                      Filter by specific tickers and industries
                    </span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Check className="w-4 h-4 text-blue-500" />
                    <span className={`text-xs ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                      Select preferred news sources
                    </span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Check className="w-4 h-4 text-blue-500" />
                    <span className={`text-xs ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                      Customize appearance and speed
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Footer with action buttons */}
        <div className="flex justify-end space-x-3 mt-6">
          <button
            onClick={onClose}
            className={`px-4 py-2 rounded-lg text-sm font-medium ${
              isDarkMode 
                ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' 
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              // Apply the settings from local state to app state
              setTickerSpeed(localTickerSpeed);
              // Save to localStorage for persistence
              localStorage.setItem('newsroom-ticker-speed', localTickerSpeed.toString());
              onClose();
            }}
            className={`px-4 py-2 rounded-lg text-sm font-medium
              bg-blue-500 text-white hover:bg-blue-600`}
          >
            Save
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

const NewsTicker: React.FC<{
  items: string[];
  isDarkMode: boolean;
  onItemClick: (item: string) => void;
  isLoading?: boolean;
}> = ({ items, isDarkMode, onItemClick, isLoading = false }) => {
  const [showEditButton, setShowEditButton] = useState(false);
  const [showTickerModal, setShowTickerModal] = useState(false);

  // If loading, show loading indicator
  if (isLoading) {
    return (
      <div className={`w-full font-sans py-1.5 px-4 border-b overflow-hidden ${
        isDarkMode ? 'bg-gray-900 border-gray-800' : 'bg-gray-100 border-gray-200'
      } sticky top-0 z-40`}>
        <div className="flex items-center w-full overflow-hidden">
          <div className="ticker-container relative flex-1 overflow-hidden">
            <div className="flex items-center space-x-2">
              <div className="animate-pulse h-2 w-20 bg-gray-400/20 rounded"></div>
              <span className={isDarkMode ? 'text-gray-600' : 'text-gray-400'}>•</span>
              <div className="animate-pulse h-2 w-32 bg-gray-400/20 rounded"></div>
              <span className={isDarkMode ? 'text-gray-600' : 'text-gray-400'}>•</span>
              <div className="animate-pulse h-2 w-24 bg-gray-400/20 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // If no items and not loading, show empty state
  if (!items?.length) {
    return (
      <div className={`w-full font-sans py-1.5 px-4 border-b overflow-hidden ${
        isDarkMode ? 'bg-gray-900 border-gray-800' : 'bg-gray-100 border-gray-200'
      } sticky top-0 z-40`}>
        <div className="flex items-center justify-center w-full">
          <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            No market data available
          </p>
        </div>
      </div>
    );
  }

  return (
    <div 
      className={`w-full font-sans py-1.5 px-4 border-b overflow-hidden relative ${
        isDarkMode ? 'bg-gray-900 border-gray-800' : 'bg-gray-100 border-gray-200'
      } sticky top-0 z-40`}
      onMouseEnter={() => setShowEditButton(true)}
      onMouseLeave={() => setShowEditButton(false)}
    >
      <div className="flex items-center w-full overflow-hidden">
        <div className="ticker-container relative flex-1 overflow-hidden">
          <div className="ticker-track whitespace-nowrap">
            {/* First copy of items */}
            <div className="ticker-content inline-block">
              {items.map((item, index) => (
                <React.Fragment key={`ticker-item-1-${index}`}>
                  <button
                    onClick={() => onItemClick(item)}
                    className={`inline-block font-medium text-sm uppercase ${
                      isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-700 hover:text-black'
                    } transition-colors cursor-pointer`}
                  >
                    {item}
                    
                  </button>
                  
                  <span className={`inline-block mx-4 ${
                    isDarkMode ? 'text-gray-600' : 'text-gray-400'
                  }`}>•</span>
                </React.Fragment>
              ))}
            </div>
            {/* Duplicate copy for seamless looping */}
            <div className="ticker-content inline-block">
              {items.map((item, index) => (
                <React.Fragment key={`ticker-item-2-${index}`}>
                  <button
                    onClick={() => onItemClick(item)}
                    className={`inline-block font-medium text-sm uppercase ${
                      isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-700 hover:text-black'
                    } transition-colors cursor-pointer`}
                  >
                    {item}
                  </button>
                  <span className={`inline-block mx-4 ${
                    isDarkMode ? 'text-gray-600' : 'text-gray-400'
                  }`}>•</span>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Edit button that appears on hover */}
      <AnimatePresence>
        {showEditButton && (
          <motion.button
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 10 }}
            className={`absolute right-4 top-1/2 transform -translate-y-1/2 p-1.5 mt-[-13px] rounded-full ${
              isDarkMode ? 'bg-gray-800 hover:bg-gray-700 text-gray-300' : 'bg-white hover:bg-gray-100 text-gray-700'
            } shadow-lg border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}
            onClick={() => setShowTickerModal(true)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" 
                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M12 20h9"></path>
              <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
            </svg>
          </motion.button>
        )}
      </AnimatePresence>

      {/* News Ticker Edit Modal */}
      <TickerEditModal isOpen={showTickerModal} onClose={() => setShowTickerModal(false)} isDarkMode={isDarkMode} />
    </div>
  );
};
// At the top of DashboardOverview.tsx, add:
const containerStyles = {
  welcomePreview: (isDarkMode: boolean) => `
    relative w-full h-full overflow-hidden rounded-xl border
    ${isDarkMode ? 'bg-gray-900/50 border-gray-800' : 'bg-white/50 border-gray-200'}
    backdrop-blur-sm
    before:content-[''] before:absolute before:inset-0
    before:bg-gradient-to-r before:from-transparent before:via-blue-500/5 before:to-transparent
    before:animate-pulse before:duration-3000
  `
};

const welcomePreviewVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};

const expandButtonVariants = {
  initial: { scale: 0, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  hover: { scale: 1.1 },
  tap: { scale: 0.95 },
  exit: { scale: 0, opacity: 0 }
};

interface SectorPerformance {
  sector: string;
  change: number;
  trending: string[];
}

interface SearchSuggestion {
  type: 'ticker' | 'company' | 'topic';
  text: string;
  subtext?: string;
  icon?: React.ElementType;
}

interface CreateCategoryModal {
  isOpen: boolean;
  title: string;
  description: string;
}


// Replace the getMarketPulse function with a My Account link
const getMarketPulse = () => {
  return (
    <div className="flex items-center">
      <span>Your Account</span>
      <ChevronRight className="w-4 h-4 ml-1" />
    </div>
  );
};

const DashboardOverview: React.FC = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const { user } = useAuth(); // Add this import and hook
  const [tickerItems, setTickerItems] = useState<string[]>([]);
    const [tickerItemsLoading, setTickerItemsLoading] = useState(true);
    const [showTickerModal, setShowTickerModal] = useState(false);
    const { savedItems, saveItem, isItemSaved } = useWatchlists();
    const [watchedStocks, setWatchedStocks] = useState<WatchedStockWithData[]>([]);
const [isEditingStocks, setIsEditingStocks] = useState(false);
const { query, setQuery, results, isLoading: isStockSearchLoading, error: stockSearchError } = useStockSearch();
const welcomeStateRef = useRef<WelcomeStatePreviewRef>(null);
const [stockOrder, setStockOrder] = useState<string[]>([]);
const { isIdle, resumeActivity } = useIdleDetection();
const [showSettings, setShowSettings] = useState(false);
const [tickerSpeed, setTickerSpeed] = useState<number>(60); // Default 60s animation duration
const [saveStockModalOpen, setSaveStockModalOpen] = useState(false);
const [stockToSave, setStockToSave] = useState<{ticker: string, name: string} | null>(null);
const [autoRefreshInterval, setAutoRefreshInterval] = useState(60); // 60 seconds
const [notificationsEnabled, setNotificationsEnabled] = useState(true);
const [isMobileDevice, setIsMobileDevice] = useState(false);
const [showMobileWarning, setShowMobileWarning] = useState(false);

useEffect(() => {
  const checkMobile = () => {
    const isMobile = window.innerWidth < 768;
    setIsMobileDevice(isMobile);
    setShowMobileWarning(isMobile);
  };
  
  checkMobile();
  window.addEventListener('resize', checkMobile);
  return () => window.removeEventListener('resize', checkMobile);
}, []);

const handleDragEnd = (result: any) => {
  if (!result.destination) return;

  const items = Array.from(stockOrder);
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);

  setStockOrder(items);
  // Update watched stocks order based on new arrangement
  setWatchedStocks(prev => {
    const newStocks = [...prev];
    const stockMap = Object.fromEntries(newStocks.map(s => [s.id, s]));
    return items.map(id => stockMap[id]);
  });
};
useEffect(() => {
  if (isIdle ) {
    console.log('User idle');
    
    
  }
}, [isIdle]);
useEffect(() => {
  const fetchTickerItems = async () => {
    setTickerItemsLoading(true);
    try {
      // Use cachedPromptService instead of direct API call
      const promptsData = await cachedPromptService.getCachedPrompts();
      
      // Process data similar to how you do in Home2.tsx
      let items: string[] = [];
      if (Array.isArray(promptsData)) {
        if (typeof promptsData[0] === 'string') {
          items = promptsData;
        } else if (typeof promptsData[0] === 'object' && 'text' in promptsData[0]) {
          items = promptsData.map(item => item.text);
        }
      }
      
      setTickerItems(items);
    } catch (error) {
      console.error('Failed to fetch ticker items:', error);
    } finally {
      setTickerItemsLoading(false);
    }
  };
  
  fetchTickerItems();
}, []);

// Add this component before the main DashboardOverview component

const MobileMarketStatus: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [marketStatus, setMarketStatus] = useState<'pre-market'|'market-open'|'post-market'|'closed'>('closed');
  
  useEffect(() => {
    // Update time every minute
    const timer = setInterval(() => {
      const now = new Date();
      setCurrentTime(now);
      updateMarketStatus(now);
    }, 60000);
    
    // Initial update
    updateMarketStatus(new Date());
    
    return () => clearInterval(timer);
  }, []);
  
  const updateMarketStatus = (localNow: Date) => {
    // Get Eastern Time
    const options: Intl.DateTimeFormatOptions = { 
      timeZone: 'America/New_York',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    };
    
    const etTime = localNow.toLocaleString('en-US', options);
    const [hourStr, minuteStr] = etTime.split(':');
    const hour = parseInt(hourStr);
    const minute = parseInt(minuteStr);
    const day = localNow.getDay(); // 0 = Sunday, 6 = Saturday
    
    // Check for weekends
    if (day === 0 || day === 6) {
      setMarketStatus('closed');
      return;
    }
    
    // Simplified market hours check
    const timeInMinutes = hour * 60 + minute;
    
    if (timeInMinutes < 4 * 60) {
      setMarketStatus('closed');
    } else if (timeInMinutes < 9 * 60 + 30) {
      setMarketStatus('pre-market');
    } else if (timeInMinutes < 16 * 60) {
      setMarketStatus('market-open');
    } else if (timeInMinutes < 20 * 60) {
      setMarketStatus('post-market');
    } else {
      setMarketStatus('closed');
    }
  };
  
  // Get status colors
  const getStatusColor = () => {
    switch (marketStatus) {
      case 'pre-market': 
        return {
          bg: isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100',
          text: isDarkMode ? 'text-blue-300' : 'text-blue-600'
        };
      case 'market-open': 
        return {
          bg: isDarkMode ? 'bg-green-500/20' : 'bg-green-100',
          text: isDarkMode ? 'text-green-300' : 'text-green-600'
        };
      case 'post-market': 
        return {
          bg: isDarkMode ? 'bg-purple-500/20' : 'bg-purple-100',
          text: isDarkMode ? 'text-purple-300' : 'text-purple-600'
        };
      default: 
        return {
          bg: isDarkMode ? 'bg-gray-500/20' : 'bg-gray-100',
          text: isDarkMode ? 'text-gray-300' : 'text-gray-600'
        };
    }
  };
  
  const statusColors = getStatusColor();
  
  return (
    <div className={`rounded-xl border ${
      isDarkMode ? 'bg-gray-900/70 border-gray-800' : 'bg-white/80 border-gray-200'
    } overflow-hidden`}>
      {/* Collapsed view - always visible */}
      <div
        className="p-4 flex items-center justify-between cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center">
          <div className="relative mr-3">
            <div className={`h-3 w-3 rounded-full ${marketStatus === 'market-open' ? 'bg-green-500' : marketStatus === 'pre-market' ? 'bg-blue-500' : marketStatus === 'post-market' ? 'bg-purple-500' : 'bg-gray-500'}`}></div>
            <div className={`absolute inset-0 rounded-full ${marketStatus === 'market-open' ? 'bg-green-500' : marketStatus === 'pre-market' ? 'bg-blue-500' : marketStatus === 'post-market' ? 'bg-purple-500' : 'bg-gray-500'} animate-ping opacity-75`} style={{animationDuration: '3s'}}></div>
          </div>
          <div>
            <div className={`text-sm font-medium ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              {marketStatus === 'pre-market' ? 'Pre-Market' : 
               marketStatus === 'market-open' ? 'Market Open' :
               marketStatus === 'post-market' ? 'After Hours' : 'Markets Closed'}
            </div>
            <div className="text-xs text-gray-500">
              {currentTime.toLocaleTimeString('en-US', { 
                hour: '2-digit', 
                minute: '2-digit',
                timeZone: 'America/New_York' 
              })} ET
            </div>
          </div>
        </div>
        <div className={`${
          isExpanded ? 'transform rotate-180 transition-transform' : 'transition-transform'
        }`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" 
              stroke={isDarkMode ? '#6b7280' : '#4b5563'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </div>
      </div>

      {/* Expanded view - conditional rendering */}
      {isExpanded && (
        <div className={`p-4 border-t ${
          isDarkMode ? 'border-gray-800' : 'border-gray-100'
        }`}>
          {/* Timeline visualization - simplified for mobile */}
          <div className="mb-4">
            <div className="flex justify-between text-xs text-gray-500 mb-1">
              <span>4:00 AM</span>
              <span>9:30 AM</span>
              <span>4:00 PM</span>
              <span>8:00 PM</span>
            </div>
            <div className="relative h-2 w-full rounded-full overflow-hidden bg-gray-200/20">
              {/* Pre-market segment */}
              <div className={`absolute left-0 h-full w-[25%] ${
                marketStatus === 'pre-market' ? 'bg-blue-500' : 'bg-gray-500/30'
              } ${marketStatus === 'pre-market' ? 'opacity-100' : 'opacity-30'}`}></div>
              
              {/* Regular market segment */}
              <div className={`absolute left-[25%] h-full w-[37.5%] ${
                marketStatus === 'market-open' ? 'bg-green-500' : 'bg-gray-500/30'
              } ${marketStatus === 'market-open' ? 'opacity-100' : 'opacity-30'}`}></div>
              
              {/* After-hours segment */}
              <div className={`absolute left-[62.5%] h-full w-[37.5%] ${
                marketStatus === 'post-market' ? 'bg-purple-500' : 'bg-gray-500/30'
              } ${marketStatus === 'post-market' ? 'opacity-100' : 'opacity-30'}`}></div>
            </div>
          </div>
          
          {/* Current market status details */}
          <div className={`p-3 rounded-lg ${statusColors.bg} mb-3`}>
            <div className={`text-sm font-medium ${statusColors.text}`}>
              {marketStatus === 'pre-market' 
                ? 'Pre-market trading is active (4:00AM-9:30AM ET)'
                : marketStatus === 'market-open'
                ? 'Regular trading hours (9:30AM-4:00PM ET)'
                : marketStatus === 'post-market'
                ? 'Extended-hours trading (4:00PM-8:00PM ET)'
                : 'Markets are currently closed'
              }
            </div>
          </div>
          
          {/* Time zone info */}
          <div className="flex justify-between text-xs text-gray-500">
            <div>
              <span className="block mb-1">Eastern Time (ET)</span>
              <span className="font-mono">
                {currentTime.toLocaleTimeString('en-US', { 
                  hour: '2-digit', 
                  minute: '2-digit',
                  second: '2-digit',
                  timeZone: 'America/New_York' 
                })}
              </span>
            </div>
            <div>
              <span className="block mb-1">Local Time</span>
              <span className="font-mono">
                {currentTime.toLocaleTimeString('en-US', { 
                  hour: '2-digit', 
                  minute: '2-digit',
                  second: '2-digit'
                })}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const handleSaveStock = (stock: {ticker: string, name: string}) => {
  setStockToSave(stock);
  setSaveStockModalOpen(true);
};

const SettingsModal = () => {
  if (!showSettings) return null;
  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center"
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        className={`w-full max-w-md p-6 rounded-2xl shadow-xl ${
          isDarkMode ? 'bg-gray-900' : 'bg-white'
        }`}
      >
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <h3 className={`text-lg font-semibold ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>Dashboard Settings</h3>
          <button
            onClick={() => setShowSettings(false)}
            className={`p-2 rounded-lg transition-colors ${
              isDarkMode 
                ? 'hover:bg-gray-800 text-gray-400' 
                : 'hover:bg-gray-100 text-gray-600'
            }`}
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        
        {/* Settings Content */}
        <div className="space-y-6">
          {/* News Ticker Speed */}
          
          {/* Auto Refresh Interval */}
          <div>
            <label className={`block text-sm font-medium mb-2 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-700'
            }`}>
              Auto Refresh Interval
            </label>
            <select
              value={autoRefreshInterval}
              onChange={(e) => setAutoRefreshInterval(parseInt(e.target.value))}
              className={`w-full p-2 rounded-lg border ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 text-white' 
                  : 'bg-white border-gray-300 text-gray-900'
              }`}
            >
              <option value={30}>30 seconds</option>
              <option value={60}>1 minute</option>
              <option value={300}>5 minutes</option>
              <option value={600}>10 minutes</option>
              <option value={0}>Never (Manual only)</option>
            </select>
            <div className={`mt-1 text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              How often to automatically refresh market data
            </div>
          </div>
          
        </div>
        
        {/* Save Button */}
        <div className="mt-8 flex justify-end">
          <button
            onClick={() => {
              // Here you could save settings to localStorage or API
              localStorage.setItem('newsroom-ticker-speed', tickerSpeed.toString());
              localStorage.setItem('newsroom-refresh-interval', autoRefreshInterval.toString());
              localStorage.setItem('newsroom-notifications', notificationsEnabled.toString());
              setShowSettings(false);
            }}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            Save Settings
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};


// Add this handler for ticker item clicks
const handleTickerItemClick = (item: string) => {
  // Check if we want to add it as a chip or navigate
  if (welcomeStateRef.current) {
    // Add as a chip with the current research mode prefix
    welcomeStateRef.current.addPromptChip(
      item, 
      selectedResearchMode?.prefix || ''
    );
  } else {
    // Fallback to navigation if the ref isn't available
    navigate('/dashboard/market-chat', { 
      state: { initialPrompt: item }
    });
  }
};

useEffect(() => {
  const tickerElements = document.querySelectorAll('.ticker-track');
  tickerElements.forEach(element => {
    (element as HTMLElement).style.animation = `ticker ${tickerSpeed}s linear infinite`;
  });
}, [tickerSpeed]);

useEffect(() => {
  const savedTickerSpeed = localStorage.getItem('newsroom-ticker-speed');
  const savedRefreshInterval = localStorage.getItem('newsroom-refresh-interval');
  const savedNotifications = localStorage.getItem('newsroom-notifications');
  
  if (savedTickerSpeed) setTickerSpeed(parseInt(savedTickerSpeed));
  if (savedRefreshInterval) setAutoRefreshInterval(parseInt(savedRefreshInterval));
  if (savedNotifications) setNotificationsEnabled(savedNotifications === 'true');
}, []);

useEffect(() => {
  if (isIdle) {
    console.log('User idle');
    // When user is idle, we'll pause updates in child components
    // The actual pausing will be handled by passing isIdle prop to components
  }
}, [isIdle]);


// Add this component after your MarketSentimentBadge component
const MarketStatusTimeline: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [marketStatus, setMarketStatus] = useState<'pre-market'|'market-open'|'post-market'|'closed'>('closed');
  const [nextTransition, setNextTransition] = useState('');
  const [nextTransitionTime, setNextTransitionTime] = useState('');
  const [timeUntilNext, setTimeUntilNext] = useState('');
  
  useEffect(() => {
    // Update time every second to keep countdown accurate
    const timer = setInterval(() => {
      const now = new Date();
      setCurrentTime(now);
      updateMarketStatus(now);
    }, 1000);
    
    // Initial update
    updateMarketStatus(new Date());
    
    return () => clearInterval(timer);
  }, []);
  
  // Get Eastern Time (ET) date directly using the timeZone option
  const getETTime = (localTime: Date): Date => {
    const options: Intl.DateTimeFormatOptions = { 
      timeZone: 'America/New_York',
      year: 'numeric', 
      month: 'numeric', 
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false
    };
    
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const parts = formatter.formatToParts(localTime);
    
    const dateObj: Record<string, string> = {};
    parts.forEach(part => {
      if (part.type !== 'literal') {
        dateObj[part.type] = part.value;
      }
    });
    
    // Create an ET date object
    const etDate = new Date(
      parseInt(dateObj.year),
      parseInt(dateObj.month) - 1, // Month is 0-indexed
      parseInt(dateObj.day),
      parseInt(dateObj.hour),
      parseInt(dateObj.minute),
      parseInt(dateObj.second)
    );
    
    return etDate;
  };
  
  const isMarketHoliday = (date: Date): boolean => {
    // Implement holiday check for major US market holidays
    // This is a simplified version - in production you would want a complete holiday calendar
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    
    // New Year's Day (Jan 1 or observed)
    const newYearsDay = new Date(year, 0, 1);
    // If Jan 1 falls on weekend, observe on closest weekday
    if (newYearsDay.getDay() === 0) { // Sunday
      newYearsDay.setDate(2); // Observed Monday Jan 2
    } else if (newYearsDay.getDay() === 6) { // Saturday
      newYearsDay.setDate(0); // Observed Friday Dec 31 prev year
    }
    
    // MLK Day (3rd Monday of January)
    const mlkDay = new Date(year, 0, 1);
    mlkDay.setDate(1 + (15 - mlkDay.getDay()) % 7 + 7);
    
    // Presidents Day (3rd Monday of February)
    const presidentsDay = new Date(year, 1, 1);
    presidentsDay.setDate(1 + (15 - presidentsDay.getDay()) % 7 + 7);
    
    // Good Friday (this is simplified, actual calculation is more complex)
    // Would need a proper Easter calculator
    
    // Memorial Day (last Monday of May)
    const memorialDay = new Date(year, 5, 0);
    while (memorialDay.getDay() !== 1) {
      memorialDay.setDate(memorialDay.getDate() - 1);
    }
    
    // Juneteenth (June 19 or observed)
    const juneteenth = new Date(year, 5, 19);
    if (juneteenth.getDay() === 0) { // Sunday
      juneteenth.setDate(20); // Observed Monday
    } else if (juneteenth.getDay() === 6) { // Saturday
      juneteenth.setDate(18); // Observed Friday
    }
    
    // Independence Day (July 4 or observed)
    const independenceDay = new Date(year, 6, 4);
    if (independenceDay.getDay() === 0) { // Sunday
      independenceDay.setDate(5); // Observed Monday
    } else if (independenceDay.getDay() === 6) { // Saturday
      independenceDay.setDate(3); // Observed Friday
    }
    
    // Labor Day (1st Monday of September)
    const laborDay = new Date(year, 8, 1);
    while (laborDay.getDay() !== 1) {
      laborDay.setDate(laborDay.getDate() + 1);
    }
    
    // Thanksgiving (4th Thursday of November)
    const thanksgiving = new Date(year, 10, 1);
    thanksgiving.setDate(1 + (22 - thanksgiving.getDay()) % 7 + 21);
    
    // Christmas (December 25 or observed)
    const christmas = new Date(year, 11, 25);
    if (christmas.getDay() === 0) { // Sunday
      christmas.setDate(26); // Observed Monday
    } else if (christmas.getDay() === 6) { // Saturday
      christmas.setDate(24); // Observed Friday
    }
    
    // Check if date matches any holiday
    const isHoliday = [
      newYearsDay, mlkDay, presidentsDay, memorialDay, juneteenth, 
      independenceDay, laborDay, thanksgiving, christmas
    ].some(holiday => 
      holiday.getFullYear() === year && 
      holiday.getMonth() === month && 
      holiday.getDate() === day
    );
    
    return isHoliday;
  };
  
  const updateMarketStatus = (localNow: Date) => {
    // Get the current time in Eastern Time for market calculations
    const etDate = getETTime(localNow);
    const day = etDate.getDay(); // 0 = Sunday, 6 = Saturday
    const hour = etDate.getHours();
    const minute = etDate.getMinutes();
    const timeInMinutes = hour * 60 + minute;
    
    // Check for weekends
    if (day === 0 || day === 6) {
      setMarketStatus('closed');
      setNextTransition('Pre-Market');
      
      // Calculate next market open (Monday 4:00 AM ET for pre-market)
      const daysToAdd = day === 0 ? 1 : 2; // If Sunday, add 1 day, if Saturday, add 2 days
      
      const nextOpen = new Date(etDate);
      nextOpen.setDate(etDate.getDate() + daysToAdd);
      nextOpen.setHours(4, 0, 0, 0);
      
      // Convert next open time back to local for display
      const nextOpenLocal = new Date(nextOpen.toLocaleString('en-US', { timeZone: 'America/New_York' }));
      
      setNextTransitionTime(formatTime(nextOpenLocal, true));
      setTimeUntilNext(formatTimeUntil(nextOpen, etDate));
      return;
    }
    
    // Check for market holidays
    if (isMarketHoliday(etDate)) {
      setMarketStatus('closed');
      setNextTransition('Market Holiday');
      
      // Calculate next business day
      const nextBusinessDay = new Date(etDate);
      let daysToAdd = 1;
      
      // Keep adding days until we find a business day
      do {
        nextBusinessDay.setDate(nextBusinessDay.getDate() + 1);
        // If it's weekend, add more days to get to Monday
        if (nextBusinessDay.getDay() === 0) { // Sunday
          daysToAdd = 1;
        } else if (nextBusinessDay.getDay() === 6) { // Saturday
          daysToAdd = 2;
        } else {
          daysToAdd = 0;
        }
        nextBusinessDay.setDate(nextBusinessDay.getDate() + daysToAdd);
      } while (isMarketHoliday(nextBusinessDay));
      
      // Set to 4:00 AM pre-market on next business day
      nextBusinessDay.setHours(4, 0, 0, 0);
      
      setNextTransitionTime(formatTime(nextBusinessDay, true));
      setTimeUntilNext(formatTimeUntil(nextBusinessDay, etDate));
      return;
    }
    
    // Weekday logic with correct market hours
    // Pre-market: 4:00 AM to 9:30 AM ET
    // Regular hours: 9:30 AM to 4:00 PM ET
    // After hours: 4:00 PM to 8:00 PM ET
    
    if (timeInMinutes < 4 * 60) {
      // Before pre-market
      setMarketStatus('closed');
      setNextTransition('Pre-Market');
      
      const preMarketOpen = new Date(etDate);
      preMarketOpen.setHours(4, 0, 0, 0);
      
      setNextTransitionTime(formatTime(preMarketOpen, true));
      setTimeUntilNext(formatTimeUntil(preMarketOpen, etDate));
    } else if (timeInMinutes < 9 * 60 + 30) {
      // During pre-market
      setMarketStatus('pre-market');
      setNextTransition('Market Open');
      
      const marketOpen = new Date(etDate);
      marketOpen.setHours(9, 30, 0, 0);
      
      setNextTransitionTime(formatTime(marketOpen, true));
      setTimeUntilNext(formatTimeUntil(marketOpen, etDate));
    } else if (timeInMinutes < 16 * 60) {
      // Regular market hours
      setMarketStatus('market-open');
      setNextTransition('Market Close');
      
      const marketClose = new Date(etDate);
      marketClose.setHours(16, 0, 0, 0);
      
      setNextTransitionTime(formatTime(marketClose, true));
      setTimeUntilNext(formatTimeUntil(marketClose, etDate));
    } else if (timeInMinutes < 20 * 60) {
      // After hours
      setMarketStatus('post-market');
      setNextTransition('Post-Market Close');
      
      const afterHoursClose = new Date(etDate);
      afterHoursClose.setHours(20, 0, 0, 0);
      
      setNextTransitionTime(formatTime(afterHoursClose, true));
      setTimeUntilNext(formatTimeUntil(afterHoursClose, etDate));
    } else {
      // After post-market, prepare for next day
      setMarketStatus('closed');
      
      // Calculate next pre-market (tomorrow or Monday if Friday)
      const nextDay = day === 5 ? 3 : 1; // If Friday, next is Monday (+3 days)
      
      const nextPreMarket = new Date(etDate);
      nextPreMarket.setDate(etDate.getDate() + nextDay);
      nextPreMarket.setHours(4, 0, 0, 0);
      
      // Check if the next day is a holiday
      if (isMarketHoliday(nextPreMarket)) {
        // Find the next non-holiday business day
        let nextBusinessDay = new Date(nextPreMarket);
        do {
          nextBusinessDay.setDate(nextBusinessDay.getDate() + 1);
          // Skip weekends
          if (nextBusinessDay.getDay() === 0) { // Sunday
            nextBusinessDay.setDate(nextBusinessDay.getDate() + 1);
          } else if (nextBusinessDay.getDay() === 6) { // Saturday
            nextBusinessDay.setDate(nextBusinessDay.getDate() + 2);
          }
        } while (isMarketHoliday(nextBusinessDay));
        
        nextBusinessDay.setHours(4, 0, 0, 0);
        setNextTransition('Pre-Market (after holiday)');
        setNextTransitionTime(formatTime(nextBusinessDay, true));
        setTimeUntilNext(formatTimeUntil(nextBusinessDay, etDate));
      } else {
        setNextTransition('Pre-Market');
        setNextTransitionTime(formatTime(nextPreMarket, true));
        setTimeUntilNext(formatTimeUntil(nextPreMarket, etDate));
      }
    }
  };
  
  // Format time in a consistent way
  const formatTime = (date: Date, isET = false): string => {
    if (isET) {
      return date.toLocaleTimeString('en-US', { 
        hour: "2-digit", 
        minute: "2-digit",
        timeZoneName: "short",
        timeZone: "America/New_York"
      });
    }
    
    return date.toLocaleTimeString('en-US', { 
      hour: "2-digit", 
      minute: "2-digit",
      timeZoneName: "short"
    });
  };
  
  // Calculate time until next transition with improved accuracy
  const formatTimeUntil = (target: Date, current: Date): string => {
    const diffMs = target.getTime() - current.getTime();
    if (diffMs <= 0) return "Now";
    
    const diffSecs = Math.floor(diffMs / 1000);
    const diffMins = Math.floor(diffSecs / 60);
    const diffHours = Math.floor(diffMins / 60);
    const diffDays = Math.floor(diffHours / 24);
    
    const remainingHours = diffHours % 24;
    const remainingMins = diffMins % 60;
    
    if (diffDays > 0) {
      return `${diffDays}d ${remainingHours}h`;
    } else if (diffHours > 0) {
      return `${diffHours}h ${remainingMins}m`;
    } else if (diffMins > 0) {
      const remainingSecs = diffSecs % 60;
      return diffMins >= 10 ? `${diffMins}m` : `${diffMins}m ${remainingSecs}s`;
    } else {
      return `${diffSecs}s`;
    }
  };
  
  // Get status colors with enhanced aesthetics
  const getStatusColor = () => {
    switch (marketStatus) {
      case 'pre-market': 
        return {
          bg: isDarkMode ? 'bg-blue-500' : 'bg-blue-400',
          text: isDarkMode ? 'text-blue-300' : 'text-blue-600',
          glow: 'shadow-blue-500/20',
          gradient: 'from-blue-500/50 to-cyan-400/50'
        };
      case 'market-open': 
        return {
          bg: isDarkMode ? 'bg-green-500' : 'bg-green-500',
          text: isDarkMode ? 'text-green-300' : 'text-green-600',
          glow: 'shadow-green-500/20',
          gradient: 'from-green-500/50 to-emerald-400/50'
        };
      case 'post-market': 
        return {
          bg: isDarkMode ? 'bg-purple-500' : 'bg-purple-400',
          text: isDarkMode ? 'text-purple-300' : 'text-purple-600',
          glow: 'shadow-purple-500/20',
          gradient: 'from-purple-500/50 to-violet-400/50'
        };
      default: 
        return {
          bg: isDarkMode ? 'bg-gray-500' : 'bg-gray-300',
          text: isDarkMode ? 'text-gray-300' : 'text-gray-600',
          glow: 'shadow-gray-500/20',
          gradient: 'from-gray-500/50 to-gray-400/50'
        };
    };
  };
  
  // Format current time in both local and ET timezones with improved accuracy
  const formatTimeWithZone = (date: Date) => {
    const localOptions: Intl.DateTimeFormatOptions = { 
      hour: "2-digit", 
      minute: "2-digit",
      second: "2-digit"
    };
    
    const localTime = date.toLocaleTimeString([], localOptions);
    
    // Get Eastern Time
    const etOptions: Intl.DateTimeFormatOptions = { 
      hour: "2-digit", 
      minute: "2-digit",
      second: "2-digit",
      timeZone: "America/New_York" 
    };
    
    const etTime = date.toLocaleTimeString('en-US', etOptions);
    
    return { localTime, etTime };
  };
  
  // Convert ET time string to local time
  const formatETToLocal = (etTimeStr: string): string => {
    if (!etTimeStr) return '';
    
    // Parse the ET time string (format: "HH:MM AM/PM ET")
    const timeParts = etTimeStr.split(' ');
    if (timeParts.length < 3) return '';
    
    const timeComponent = timeParts[0];
    const ampm = timeParts[1];
    
    const [hourStr, minuteStr] = timeComponent.split(':');
    let hour = parseInt(hourStr);
    const minute = parseInt(minuteStr);
    
    // Convert to 24-hour format
    if (ampm === 'PM' && hour < 12) hour += 12;
    if (ampm === 'AM' && hour === 12) hour = 0;
    
    // Create date object with Eastern Time
    const now = new Date();
    const etDate = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    
    // Set the hours and minutes from our parsed time
    etDate.setHours(hour, minute, 0, 0);
    
    // Convert from ET to Local time
    const localDate = new Date(etDate.getTime() + (now.getTime() - new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' })).getTime()));
    
    // Format the local time
    return localDate.toLocaleTimeString([], { 
      hour: '2-digit', 
      minute: '2-digit'
    });
  };
  
  // Get current times in both zones
  const { localTime, etTime } = formatTimeWithZone(currentTime);
  const statusColors = getStatusColor();
  
  // Calculate timeline progress percentage more accurately
  const getTimelineProgress = () => {
    const etNow = getETTime(currentTime);
    const hour = etNow.getHours();
    const minute = etNow.getMinutes();
    const second = etNow.getSeconds();
    const timeInMinutes = (hour * 60) + minute + (second / 60);
    
    switch (marketStatus) {
      case 'pre-market':
        // 4:00 AM - 9:30 AM (5.5 hours or 330 minutes)
        const preMarketStart = 4 * 60; // 4:00 AM in minutes
        const preMarketEnd = 9 * 60 + 30; // 9:30 AM in minutes
        return Math.min(100, Math.max(0, ((timeInMinutes - preMarketStart) / (preMarketEnd - preMarketStart)) * 100));
      
      case 'market-open':
        // 9:30 AM - 4:00 PM (6.5 hours or 390 minutes)
        const marketStart = 9 * 60 + 30; // 9:30 AM in minutes
        const marketEnd = 16 * 60; // 4:00 PM in minutes
        return Math.min(100, Math.max(0, ((timeInMinutes - marketStart) / (marketEnd - marketStart)) * 100));
      
      case 'post-market':
        // 4:00 PM - 8:00 PM (4 hours or 240 minutes)
        const postStart = 16 * 60; // 4:00 PM in minutes
        const postEnd = 20 * 60; // 8:00 PM in minutes
        return Math.min(100, Math.max(0, ((timeInMinutes - postStart) / (postEnd - postStart)) * 100));
      
      default:
        return 0;
    }
  };

  return (
    <motion.div 
      className="flex flex-col w-full"
      initial={{ opacity: 0, y: 5 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      {/* Enhanced status container with modern aesthetics */}
      <div className={`font-sans relative overflow-hidden ${
        isDarkMode ? 'bg-gray-800/60' : 'bg-white/60'
      } rounded-xl border ${
        isDarkMode ? 'border-gray-700' : 'border-gray-200'
      } shadow-lg backdrop-blur-sm p-3`}>
        {/* Subtle background gradient based on market status */}
        <div className={`absolute inset-0 bg-gradient-to-r ${statusColors.gradient} opacity-20`}></div>
        
        {/* Status Badge and Current Time */}
        <div className="flex items-center justify-between mb-1">
          <div className="flex items-center space-x-2">
            <div className="relative">
              <div className={`h-3 w-3 rounded-full ${statusColors.bg}`}></div>
              <motion.div 
                className={`absolute inset-0 rounded-full ${statusColors.bg} opacity-50`}
                animate={{ scale: [1, 1.5, 1] }}
                transition={{ duration: 2, repeat: Infinity }}
              />
            </div>
            <div>
              <div className="flex items-center">
                <span className={`font-medium ${statusColors.text}`}>
                  {marketStatus === 'pre-market' ? 'Pre-Market' : 
                   marketStatus === 'market-open' ? 'Market Open' :
                   marketStatus === 'post-market' ? 'After Hours' : 'Market Closed'}
                </span>
              </div>
              <div 
                className={`text-xs text-gray-500 truncate max-w-[200px]`} // Truncate long text
                title={marketStatus === 'pre-market' ? 'Early trading (4:00AM-9:30AM ET)' : 
                       marketStatus === 'market-open' ? 'Regular trading (9:30AM-4:00PM ET)' :
                       marketStatus === 'post-market' ? 'Extended hours (4:00PM-8:00PM ET)' : 'Trading closed'}
              >
                {marketStatus === 'pre-market' ? 'Early trading (4:00-9:30 ET)' : 
                 marketStatus === 'market-open' ? 'Regular trading (9:30-4 ET)' :
                 marketStatus === 'post-market' ? 'Extended hours (4:00-8 ET)' : 'Trading closed'}
              </div>
            </div>
          </div>
          
          <div className="flex flex-col items-end">
            <div className="flex items-center space-x-1">
              <span className={`text-sm font-medium ${
                isDarkMode ? 'text-gray-200' : 'text-gray-700'
              }`}>
                {etTime}
              </span>
              <span className="text-xs text-gray-500">ET</span>
            </div>
            <div className="flex items-center space-x-1 text-xs text-gray-500">
              <span>Local: {localTime}</span>
            </div>
          </div>
        </div>
        
        {/* Enhanced Timeline Visualization */}
        <div className="mb-1 space-y-1">
          <div className="flex items-center justify-between text-xs">
            <span className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
              4:00 AM
            </span>
            <span className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
              9:30 AM
            </span>
            <span className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
              4:00 PM
            </span>
            <span className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
              8:00 PM
            </span>
          </div>
          
          <div className="relative h-2 w-full rounded-full overflow-hidden bg-gray-200/20">
            {/* Pre-market segment */}
            <div className={`absolute left-0 h-full w-[25%] ${
              marketStatus === 'pre-market' ? statusColors.bg : 'bg-gray-500/30'
            } ${marketStatus === 'pre-market' ? 'opacity-100' : 'opacity-30'}`}></div>
            
            {/* Regular market segment */}
            <div className={`absolute left-[25%] h-full w-[37.5%] ${
              marketStatus === 'market-open' ? statusColors.bg : 'bg-gray-500/30'
            } ${marketStatus === 'market-open' ? 'opacity-100' : 'opacity-30'}`}></div>
            
            {/* After-hours segment */}
            <div className={`absolute left-[62.5%] h-full w-[37.5%] ${
              marketStatus === 'post-market' ? statusColors.bg : 'bg-gray-500/30'
            } ${marketStatus === 'post-market' ? 'opacity-100' : 'opacity-30'}`}></div>
            
            {/* Progress indicator */}
            {marketStatus !== 'closed' && (
              <motion.div 
                className={`absolute h-full ${statusColors.bg} shadow-lg`}
                style={{ 
                  left: marketStatus === 'pre-market' ? '0%' : 
                         marketStatus === 'market-open' ? '25%' : '62.5%',
                  width: marketStatus === 'pre-market' ? '25%' : 
                          marketStatus === 'market-open' ? '37.5%' : '37.5%'
                }}
                initial={{ scaleX: 0 }}
                animate={{ scaleX: getTimelineProgress() / 100 }}
                transition={{ duration: 1, ease: "easeInOut" }}
              />
            )}
            
            {/* Current time marker */}
            {marketStatus !== 'closed' && (
              <motion.div 
                className={`absolute top-0 bottom-0 w-1 ${statusColors.bg} shadow-lg z-10`}
                style={{ 
                  left: `${
                    marketStatus === 'pre-market' ? 
                      getTimelineProgress() * 0.25 : 
                    marketStatus === 'market-open' ? 
                      25 + (getTimelineProgress() * 0.375) : 
                      62.5 + (getTimelineProgress() * 0.375)
                  }%`,
                  transform: 'translateX(-50%)'
                }}
                animate={{ opacity: [0.7, 1, 0.7] }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                <div className="absolute -top-1 -left-1.5 w-3 h-3 rounded-full bg-white shadow-md"></div>
              </motion.div>
            )}
          </div>
        </div>
        
        {/* Next Transition Info with countdown */}
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <span className={`text-xs truncate max-w-[150px] ${
              isDarkMode ? 'text-gray-400' : 'text-gray-500'
            }`}>
              Next: {nextTransition}
            </span>
            <span className={`text-sm font-medium ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
              {nextTransitionTime}
            </span>
            {/* Add local time conversion */}
            <span className={`text-xs ${isDarkMode ? 'text-gray-500' : 'text-gray-500'}`}>
              Local: {formatETToLocal(nextTransitionTime)}
            </span>
          </div>
          <div className={`px-3 py-1 rounded-full ${
            isDarkMode ? 'bg-gray-700/50' : 'bg-gray-100'
          } border ${
            isDarkMode ? 'border-gray-600' : 'border-gray-200'
          }`}>
            <motion.span 
              className={`text-sm font-medium ${statusColors.text}`}
              key={timeUntilNext} // Force animation refresh when time changes
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
            >
              {timeUntilNext}
            </motion.span>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const [isSearchLayout, setIsSearchLayout] = useState(true);

// Add this with your other state declarations
const [selectedResearchMode, setSelectedResearchMode] = useState<ResearchMode | null>(
  researchModes[0] || null
);

const welcomePreviewContent = useMemo(() => (
  <div className={containerStyles.welcomePreview(isDarkMode)}>
    <WelcomeStatePreview 
      ref={welcomeStateRef}
      isDarkMode={isDarkMode}
      isSearchLayout={isSearchLayout}
      selectedResearchMode={selectedResearchMode?.id || 'default'} 
      onResearchModeChange={(mode) => {
        const found = researchModes.find(m => m.id === mode);
        if (found) {
          setSelectedResearchMode(found);
        }
      }}
      onSaveStock={handleSaveStock} // Add this prop
      onPromptSelect={(prompt, researchModePrefix, displayAsChip) => {
  // Ensure we're using the correct research mode ID
  const researchModeId = researchModePrefix ? 
    researchModes.find(m => m.prefix === researchModePrefix)?.id : 
    selectedResearchMode?.id;
  
  // Make sure we have a valid prompt
  if (!prompt || prompt.trim() === '') {
    console.error("Empty prompt provided to onPromptSelect");
    return;
  }
  
  // Add a timestamp to force state update
  navigate('/dashboard/market-chat', { 
    state: { 
      initialPrompt: prompt,
      researchModeId: researchModeId || 'default',
      displayAsChip: displayAsChip === true, // Ensure boolean
      submitImmediately: true,
      timestamp: Date.now() // Force state update
    },
    replace: true 
  });
}}
    />
    {/* Rest of the welcome preview content */}
  </div>
), [isDarkMode, navigate, isSearchLayout, selectedResearchMode]);

  // Add this helper function to get time-based greeting
  const getTimeBasedGreeting = () => {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) return 'Hello';
    if (hour >= 12 && hour < 17) return 'Hello';
    if (hour >= 17 && hour < 21) return 'Hello';;
    return 'Hello';
  };

  // Add this helper function to get time-based gradient
  const getTimeBasedGradient = () => {
    const hour = new Date().getHours();
    
    // Early morning / Dawn (5-7)
    if (hour >= 5 && hour < 7) {
      return 'from-indigo-900 via-purple-600 to-orange-400';
    }
    // Sunrise (7-9)
    if (hour >= 7 && hour < 9) {
      return 'from-orange-500 via-amber-400 to-blue-400';
    }
    // Day time (9-16)
    if (hour >= 9 && hour < 16) {
      return 'from-blue-400 via-sky-400 to-cyan-300';
    }
    // Sunset (16-19)
    if (hour >= 16 && hour < 19) {
      return 'from-orange-500 via-pink-500 to-purple-700';
    }
    // Evening (19-21)
    if (hour >= 19 && hour < 21) {
      return 'from-purple-800 via-violet-700 to-indigo-800';
    }
    // Night (21-5)
    return 'from-gray-900 via-purple-900 to-indigo-900';
  };

  const [movingStocks, setMovingStocks] = useState<StockWithTimeframe[]>([]);
  
  // Initialize indices with an empty array as fallback
  const [indices, setIndices] = useState<MarketIndex[]>(() => [
    { symbol: 'SPX', name: 'S&P 500', change: 0, level: 0 },
    { symbol: 'NDX', name: 'NASDAQ', change: 0, level: 0 },
    { symbol: 'DJI', name: 'Dow Jones', change: 0, level: 0 },
    { symbol: 'RUT', name: 'Russell 2000', change: 0, level: 0 },
    { symbol: 'VIX', name: 'VIX', change: 0, level: 0 }
  ]);
  const [newsArticles, setNewsArticles] = useState<NewsArticle[]>([]);
  // Add at the top of the file with other imports
type TimeRange = '1d' | '5d' | '1mo' | '3mo' | '6mo' | '1y' | '2y' | '5y';

interface StockData {
  ticker: string;
  name: string;
  prevClose: {
    c: number;
    o: number;
    v: number;
  };
}
interface MarketIndex {
  symbol: string;
  name: string;
  change: number;
  level: number;
}

interface StockWithTimeframe extends StockData {
  selectedTimeframe: TimeRange;
}

const DEFAULT_TIMEFRAME: TimeRange = '1d';

// Update the state declaration

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState('market');
  const [searchQuery, setSearchQuery] = useState('');
  const [pinnedArticles, setPinnedArticles] = useState<string[]>([]);
  const [showNewsSearch, setShowNewsSearch] = useState(false);
  const [showStockSearch, setShowStockSearch] = useState(false);
  const [trendingSearches, setTrendingSearches] = useState<TrendingTerm[]>([
    { term: 'AI Stocks', mentions: 1250, sentiment: 'positive' },
    { term: 'Interest Rates', mentions: 890, sentiment: 'negative' },
    { term: 'Tech Layoffs', mentions: 750, sentiment: 'negative' },
    { term: 'Semiconductor', mentions: 620, sentiment: 'positive' },
  ]);
  

  const [watchlist, setWatchlist] = useState<Set<string>>(new Set());

  const [createCategoryModal, setCreateCategoryModal] = useState<CreateCategoryModal>({
    isOpen: false,
    title: '',
    description: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        const [popularStocks, indexData] = await Promise.all([
          marketDataService.getPopularStocks(),
          marketDataService.getMarketIndices()
        ]);

        setMovingStocks((popularStocks || []).map((stock: StockData) => ({
          ...stock,
          selectedTimeframe: '1d'
        })));

        // Only update indices if we get valid array data
        if (Array.isArray(indexData)) {
          setIndices(indexData);
        }
        
        // Fetch news data
        console.log('Fetching news data...');
        const latestNews = await searchService.getLatestMarketNews();
        console.log('Received news data:', latestNews?.length);
        
        if (Array.isArray(latestNews)) {
          setNewsArticles(latestNews);
        } else {
          console.error('Invalid news data format');
          setNewsArticles([]);
        }
      } catch (error) {
        console.error('Data fetch error:', error);
        setError('Failed to load data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    
    // Only set up polling if not idle
    // Only set up polling if not idle and autoRefreshInterval > 0
  let interval: number | null = null;
  if (!isIdle && autoRefreshInterval > 0) {
    interval = window.setInterval(fetchData, autoRefreshInterval * 1000);
  }
  
  return () => {
    if (interval !== null) {
      clearInterval(interval);
    }
  };
}, [selectedCategory, isIdle, autoRefreshInterval]); // Add autoRefreshInterval as dependency

  useEffect(() => {
    let rafId: number;

    const handleResize = () => {
      cancelAnimationFrame(rafId);
      rafId = requestAnimationFrame(() => {
        // Update layout based on window size
        const isMobile = window.innerWidth < 768;
        const isTablet = window.innerWidth >= 768 && window.innerWidth < 1024;
        
        // Adjust news articles per row
        const newsContainer = document.querySelector('.news-grid');
        if (newsContainer) {
          newsContainer.className = `news-grid grid gap-4 ${
            isMobile ? 'grid-cols-1' : isTablet ? 'grid-cols-2' : 'grid-cols-3'
          }`;
        }
      });
    };

    window.addEventListener('resize', handleResize, { passive: true });
    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(rafId);
    };
  }, []);

useEffect(() => {
  let resizeTimer: ReturnType<typeof setTimeout>;

  const handleResize = () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      // Update layout based on window size
      const isMobile = window.innerWidth < 768;
      const isTablet = window.innerWidth >= 768 && window.innerWidth < 1024;
      
      // Adjust news articles per row
      const newsContainer = document.querySelector('.news-grid');
      if (newsContainer) {
        newsContainer.className = `news-grid grid gap-4 ${
          isMobile ? 'grid-cols-1' : isTablet ? 'grid-cols-2' : 'grid-cols-3'
        }`;
      }
    }, 100);
  };

  window.addEventListener('resize', handleResize, { passive: true });
  return () => {
    window.removeEventListener('resize', handleResize);
    clearTimeout(resizeTimer);
  };
}, []);

  const filteredArticles = useMemo(() => {
    return newsArticles.filter(article => {
      const matchesSearch = !searchQuery || 
        article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        article.snippet.toLowerCase().includes(searchQuery.toLowerCase());
      
      return matchesSearch;
    });
  }, [newsArticles, searchQuery]);

  const CardWrapper: React.FC<{
    title: string;
    icon: React.ReactNode;
    children: React.ReactNode;
    actions?: React.ReactNode;
  }> = ({ title, icon, children, actions }) => (
    <div className={`h-[750px] rounded-xl border overflow-visible ${
      isDarkMode ? 'bg-gray-900/50 border-gray-800' : 'bg-white/50 border-gray-200'
    } backdrop-blur-sm`}> {/* Changed overflow-hidden to overflow-visible */}
      <div className="p-4 border-b border-gray-800">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            {icon}
            <h2 className={`font-semibold ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              {title}
            </h2>
          </div>
          <div className="flex items-center space-x-4">
            {actions}
            <div className="flex items-center space-x-2 text-xs text-gray-500">
              <Clock className="w-3 h-3" />
              <span>Live</span>
            </div>
          </div>
        </div>
      </div>
      {children}
    </div>
  );

  const SearchOverlay: React.FC<{
    query: string;
    onQueryChange: (query: string) => void;
    onClose: () => void;
    isDarkMode: boolean;
  }> = ({ query, onQueryChange, onClose, isDarkMode }) => {
    const [recentSearches] = useState<SearchSuggestion[]>([
      { type: 'ticker', text: 'AAPL', subtext: 'Apple Inc.', icon: Building2 },
      { type: 'topic', text: 'Earnings Reports', icon: Tag },
      { type: 'company', text: 'Microsoft', subtext: 'MSFT', icon: Building2 },
    ]);
  
    const [suggestions, setSuggestions] = useState<SearchSuggestion[]>([
      { type: 'topic', text: 'Market Analysis', icon: Tag },
      { type: 'topic', text: 'Trading Volume', icon: Tag },
      { type: 'topic', text: 'Price Movement', icon: Tag },
      { type: 'company', text: 'Tesla', subtext: 'TSLA', icon: Building2 },
    ]);
  
    const filterResults = (searchQuery: string) => {
      // Implement your filtering logic here
      return suggestions.filter(suggestion => 
        suggestion.text.toLowerCase().includes(searchQuery.toLowerCase()) ||
        suggestion.subtext?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    };
  
    return (
      <div className="p-4 border-b border-gray-800">
        {/* Search Header */}
        <div className="flex items-center space-x-4 mb-4">
          <div className="relative flex-1">
            <Search className={`absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 
              ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} 
            />
            <input
              type="text"
              value={query}
              onChange={(e) => onQueryChange(e.target.value)}
              placeholder="Search by ticker, company name, or article content..."
              className={`w-full pl-10 pr-4 py-3 rounded-lg ${
                isDarkMode 
                  ? 'bg-gray-800 text-white border-gray-700' 
                  : 'bg-white text-gray-900 border-gray-200'
              } border focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
            />
          </div>
          <button
            onClick={onClose}
            className={`p-2 rounded-lg transition-colors ${
              isDarkMode 
                ? 'hover:bg-gray-800 text-gray-400' 
                : 'hover:bg-gray-100 text-gray-600'
            }`}
          >
            <X className="w-5 h-5" />
          </button>
        </div>
  
        {/* Search Filters */}
        <div className="flex items-center space-x-2 mb-4">
          <button className={`px-3 py-1.5 rounded-lg text-sm flex items-center space-x-2
            ${isDarkMode ? 'bg-gray-800 text-gray-300' : 'bg-gray-100 text-gray-700'}`}>
            <Filter className="w-4 h-4" />
            <span>All Sources</span>
          </button>
          <button className={`px-3 py-1.5 rounded-lg text-sm flex items-center space-x-2
            ${isDarkMode ? 'bg-gray-800 text-gray-300' : 'bg-gray-100 text-gray-700'}`}>
            <Tag className="w-4 h-4" />
            <span>Topics</span>
          </button>
        </div>
  
        {/* Recent Searches */}
        {query === '' && (
          <div className="mb-6">
            <h3 className={`text-sm font-medium mb-3 ${
              isDarkMode ? 'text-gray-400' : 'text-gray-600'
            }`}>
              Recent Searches
            </h3>
            <div className="grid grid-cols-2 gap-2">
              {recentSearches.map((item, index) => (
                <button
                  key={index}
                  onClick={() => onQueryChange(item.text)}
                  className={`flex items-center space-x-3 p-3 rounded-lg transition-colors ${
                    isDarkMode 
                      ? 'bg-gray-800 hover:bg-gray-700' 
                      : 'bg-gray-100 hover:bg-gray-200'
                  }`}
                >
                  <History className="w-4 h-4 text-gray-400" />
                  <div className="text-left">
                    <div className={isDarkMode ? 'text-gray-200' : 'text-gray-700'}>
                      {item.text}
                    </div>
                    {item.subtext && (
                      <div className="text-xs text-gray-500">{item.subtext}</div>
                    )}
                  </div>
                </button>
              ))}
            </div>
          </div>
        )}
  
        {/* Search Suggestions */}
        {query !== '' && (
          <div className="space-y-2">
            {filterResults(query).map((suggestion, index) => (
              <button
                key={index}
                onClick={() => onQueryChange(suggestion.text)}
                className={`w-full flex items-center space-x-3 p-3 rounded-lg transition-colors ${
                  isDarkMode 
                    ? 'bg-gray-800 hover:bg-gray-700' 
                    : 'bg-gray-100 hover:bg-gray-200'
                }`}
              >
                {suggestion.icon && (
                  <suggestion.icon className="w-4 h-4 text-gray-400" />
                )}
                <div className="text-left">
                  <div className={isDarkMode ? 'text-gray-200' : 'text-gray-700'}>
                    {suggestion.text}
                  </div>
                  {suggestion.subtext && (
                    <div className="text-xs text-gray-500">{suggestion.subtext}</div>
                  )}
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };

  const openwithprism = (context: string) => {
    navigate('/market-chat', { 
      state: { initialPrompt: context }
    });
  };

  const togglePinArticle = (articleTitle: string) => {
    setPinnedArticles(prev => 
      prev.includes(articleTitle)
        ? prev.filter(title => title !== articleTitle)
        : [...prev, articleTitle]
    );
  };

  const toggleWatchlist = async (ticker: string, name: string) => {
    try {
      if (!isItemSaved('STOCK', ticker)) {
        await saveItem({
          type: 'STOCK',
          identifier: ticker,
          title: name,
          metadata: {
            addedFrom: 'dashboard'
          }
        });
      }
    } catch (err) {
      console.error('Failed to save stock:', err);
    }
  };

  const formatTimeAgo = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInMinutes = Math.floor((now.getTime() - date.getTime()) / 60000);
    
    if (diffInMinutes < 60) return `${diffInMinutes}m ago`;
    if (diffInMinutes < 1440) return `${Math.floor(diffInMinutes / 60)}h ago`;
    return `${Math.floor(diffInMinutes / 1440)}d ago`;
  };

  const SentimentIndicator: React.FC<{ sentiment: 'positive' | 'negative' | 'neutral' }> = ({ sentiment }) => {
    const colors = {
      positive: 'text-green-500',
      negative: 'text-red-500',
      neutral: 'text-gray-500'
    };
    
    return (
      <div className={`flex items-center space-x-1 ${colors[sentiment]}`}>
        {sentiment === 'positive' && <TrendingUp className="w-3 h-3" />}
        {sentiment === 'negative' && <TrendingDown className="w-3 h-3" />}
        <span className="text-xs">{sentiment}</span>
      </div>
    );
  };

  const CreateCategoryPopup = () => {
    if (!createCategoryModal.isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
        <div className={`w-full max-w-md p-6 rounded-xl shadow-lg ${
          isDarkMode ? 'bg-gray-900' : 'bg-white'
        }`}>
          <div className="flex justify-between items-center mb-4">
            <h3 className={`text-lg font-semibold ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>Create News Category</h3>
            <button
              onClick={() => setCreateCategoryModal(prev => ({ ...prev, isOpen: false }))}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
  
          <div className="space-y-4">
            <div>
              <label className={`block text-sm font-medium mb-1 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                Category Name
              </label>
              <input
                type="text"
                value={createCategoryModal.title}
                onChange={(e) => setCreateCategoryModal(prev => ({
                  ...prev,
                  title: e.target.value
                }))}
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode 
                    ? 'bg-gray-800 border-gray-700 text-white' 
                    : 'bg-white border-gray-300 text-gray-900'
                } focus:ring-2 focus:ring-blue-500`}
                placeholder="e.g., Semiconductors"
              />
            </div>
  
            <div>
              <label className={`block text-sm font-medium mb-1 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                Description
              </label>
              <textarea
                value={createCategoryModal.description}
                onChange={(e) => setCreateCategoryModal(prev => ({
                  ...prev,
                  description: e.target.value
                }))}
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode 
                    ? 'bg-gray-800 border-gray-700 text-white' 
                    : 'bg-white border-gray-300 text-gray-900'
                } focus:ring-2 focus:ring-blue-500`}
                placeholder="What kind of news should appear in this category?"
                rows={3}
              />
            </div>
  
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setCreateCategoryModal(prev => ({ ...prev, isOpen: false }))}
                className={`px-4 py-2 rounded-lg ${
                  isDarkMode 
                    ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' 
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  // This would normally save the category
                  setCreateCategoryModal(prev => ({ ...prev, isOpen: false }));
                }}
                className="px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600"
              >
                Create Category
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const scaleUpAnimation = `
    @keyframes scaleUp {
      from {
        transform: scale(1);
        opacity: 1;
      }
      to {
        transform: scale(1.05);
        opacity: 0;
      }
    }
    .scale-up-animation {
      animation: scaleUp 0.3s ease-in-out forwards;
    }
  `;

  useEffect(() => {
    const styleTag = document.createElement('style');
    styleTag.textContent = scaleUpAnimation;
    document.head.appendChild(styleTag);
    
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  const handleAddStock = async (stock: any) => {
    if (watchedStocks.length >= 6) return;
    
    try {
      const newStock = {
        id: crypto.randomUUID(),
        symbol: stock.symbol,
        order: watchedStocks.length // This will place it at the end
      };
      
      // Add API call here to save to database
      await marketDataService.saveWatchedStock(newStock);
      
      // Update local state
      setWatchedStocks(prev => [...prev, {
        ...newStock,
        price: stock.price || 0,
        change: stock.change || 0,
        changePercent: stock.changePercent || 0
      }]);

      // Add to order tracking
      setStockOrder(prev => [...prev, newStock.id]);
      
    } catch (error) {
      console.error('Failed to add stock:', error);
    }
  };
  
  const handleRemoveStock = async (stockId: string) => {
    try {
      // Add API call here to remove from database
      await marketDataService.removeWatchedStock(stockId);
      
      // Update local state
      setWatchedStocks(prev => prev.filter(s => s.id !== stockId));
    } catch (error) {
      console.error('Failed to remove stock:', error);
    }
  };
  
  // Add effect to fetch watched stocks data
  useEffect(() => {
    const fetchWatchedStocks = async () => {
      try {
        const stocks = await marketDataService.getWatchedStocks();
        setWatchedStocks(stocks);
      } catch (error) {
        console.error('Failed to fetch watched stocks:', error);
      }
    };
  
    fetchWatchedStocks();
  }, []);
  
  // Add effect to update stock prices
  useEffect(() => {
    if (watchedStocks.length === 0 || isIdle) return;

    const updatePrices = async () => {
      try {
        const updates = await Promise.all(
          watchedStocks.map(stock => 
            marketDataService.getStockPrice(stock.symbol)
          )
        );
        
        setWatchedStocks(prev => prev.map((stock, i) => ({
          ...stock,
          ...updates[i]
        })));
      } catch (error) {
        console.error('Failed to update stock prices:', error);
      }
    };
  
    updatePrices();
    const interval = setInterval(updatePrices, 15000);
    return () => clearInterval(interval);
  }, [watchedStocks, isIdle]); // Add isIdle as dependency

  useEffect(() => {
    // Create a portal element for fullscreen components if it doesn't exist
    if (!document.getElementById('fullscreen-portal')) {
      const portalElement = document.createElement('div');
      portalElement.id = 'fullscreen-portal';
      portalElement.className = 'fullscreen-portal';
      document.body.appendChild(portalElement);
    }
    
    return () => {
      // Clean up portal on component unmount
      const portal = document.getElementById('fullscreen-portal');
      if (portal) {
        document.body.removeChild(portal);
      }
    };
  }, []);

  const tradeIdeasRef = useRef<TradeIdeasFeedRef>(null);

  return (
    <>
    <NewsTicker 
      items={tickerItems}
      isDarkMode={isDarkMode} 
      onItemClick={handleTickerItemClick}
      isLoading={tickerItemsLoading}
    />
    
    {/* Desktop view with full layout */}
    {!isMobileDevice && (
      <div className="max-w-7xl font-sans mx-auto px-4 py-4">
        {/* Keep desktop layout exactly as it is */}
        <div className="flex gap-6 mb-4">
          <div className={`flex-1 rounded-2xl overflow-hidden ${
            isDarkMode ? 'bg-black/40' : 'bg-white/80'
          } backdrop-blur-md border ${
            isDarkMode ? 'border-gray-800/40' : 'border-gray-200/40'
          }`}>
            {/* Current user header section */}
            <div className="p-6 relative">
              {/* Header content unchanged */}
              <div className="flex items-center justify-between mb-6">
                {/* Time and date */}
                <div className="flex items-center">
                  <div className="mr-6">
                    <div className={`font-mono text-xl tabular-nums font-light tracking-tight ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {new Date().toLocaleTimeString('en-US', { 
                        hour: '2-digit', 
                        minute: '2-digit',
                        hour12: true
                      })}
                    </div>
                    <div className={`text-xs ${isDarkMode ? 'text-gray-500' : 'text-gray-500'}`}>
                      {new Date().toLocaleDateString('en-US', {
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </div>
                  </div>
                  
                  {/* Greeting and status */}
                  <div>
                    <h1 className={`text-xl font-heading font-light ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {getTimeBasedGreeting()}, {user?.name?.split(' ')[0]}
                    </h1>
                    <p 
                      onClick={() => navigate('/dashboard/settings')} 
                      className={`text-sm ${isDarkMode ? 'text-blue-400' : 'text-blue-600'} flex items-center cursor-pointer hover:underline`}
                    >
                      {getMarketPulse()}
                    </p>
                  </div>
                </div>
                
                {/* Search and settings */}
                <div className="flex items-center">
                  <SearchBar />
                  <button
                    onClick={() => setShowSettings(true)}
                    className={`ml-4 p-2 rounded-full transition-all ${
                      isDarkMode 
                        ? 'hover:bg-gray-800/70 text-gray-400' 
                        : 'hover:bg-gray-100/70 text-gray-600'
                    }`}
                    aria-label="Settings"
                  >
                    <Settings className="w-5 h-5" />
                  </button>
                </div>
              </div>

              {/* Quick links grid */}
              <div className="grid grid-cols-3 gap-3 mt-[-0.75vh] mb-[-2vh]">
                {/* Quick links content unchanged */}
                {/* My Chats */}
                <div 
                  onClick={() => navigate('/dashboard/market-chat')}
                  className={`py-3 px-4 rounded-xl border cursor-pointer transition-all hover:scale-105 ${
                    isDarkMode ? 'bg-gray-900/70 border-gray-800/80 hover:bg-gray-900' : 'bg-white/80 border-gray-200/80 hover:bg-white'
                  }`}
                >
                  <div className="flex items-center space-x-3">
                    {/* SVG Icon */}
                    <svg width="24" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0" y="0" width="40" height="24" rx="3" fill={isDarkMode ? "#1a2744" : "#e5efff"} />
                      <rect x="4" y="6" width="18" height="12" rx="2" 
                        fill={isDarkMode ? "#3B82F6" : "#2563EB"} opacity="0.2" />
                      <rect x="7" y="9" width="12" height="2" rx="1" 
                        fill={isDarkMode ? "#60A5FA" : "#3B82F6"} opacity="0.9" />
                      <rect x="7" y="13" width="8" height="2" rx="1" 
                        fill={isDarkMode ? "#60A5FA" : "#3B82F6"} opacity="0.6" />
                      <rect x="20" y="8" width="16" height="10" rx="2" 
                        fill={isDarkMode ? "#ffffff" : "#2563EB"} opacity="0.8" />
                      <rect x="23" y="12" width="10" height="2" rx="1" 
                        fill={isDarkMode ? "#1a2744" : "#ffffff"} opacity="0.9" />
                      <circle cx="34" cy="7" r="1" fill={isDarkMode ? "#60A5FA" : "#3B82F6"} opacity="0.5" />
                      <circle cx="6" cy="18" r="1" fill={isDarkMode ? "#60A5FA" : "#3B82F6"} opacity="0.3" />
                    </svg>
                    <span className={`text-sm font-medium ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}>Your Chats</span>
                  </div>
                </div>

                {/* My Ticker */}
                <div 
                  onClick={() => setShowTickerModal(true)}
                  className={`py-3 px-4 rounded-xl border cursor-pointer transition-all hover:scale-105 ${
                    isDarkMode ? 'bg-gray-900/70 border-gray-800/80 hover:bg-gray-900' : 'bg-white/80 border-gray-200/80 hover:bg-white'
                  }`}
                >
                  <div className="flex items-center space-x-3">
                    <svg width="24" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0" y="0" width="40" height="24" rx="3" fill={isDarkMode ? "#1a2744" : "#e5efff"} />
                      <rect x="2" y="7" width="36" height="1" rx="0.5" fill={isDarkMode ? "#4a5568" : "#94a3b8"} />
                      <rect x="2" y="16" width="36" height="1" rx="0.5" fill={isDarkMode ? "#4a5568" : "#94a3b8"} />
                      <rect x="26" y="9.5" width="8" height="5" rx="1" fill={isDarkMode ? "#ffc107" : "#f59e0b"} />
                      <rect x="18" y="9.5" width="5" height="5" rx="1" fill={isDarkMode ? "#ffffff" : "#2563eb"} />
                      <rect x="11" y="9.5" width="4" height="5" rx="1" fill={isDarkMode ? "#ffffff" : "#2563eb"} opacity="0.7" />
                      <rect x="6" y="9.5" width="2" height="5" rx="1" fill={isDarkMode ? "#ffffff" : "#2563eb"} opacity="0.4" />
                      <path d="M36 12L38 10V14L36 12Z" fill={isDarkMode ? "#ffffff" : "#2563eb"} opacity="0.6" />
                      <path d="M4 12L2 10V14L4 12Z" fill={isDarkMode ? "#ffffff" : "#2563eb"} opacity="0.6" />
                    </svg>
                    <span className={`text-sm font-medium ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}>Your Ticker</span>
                  </div>
                </div>

                {/* Watchlists */}
                <div 
                  onClick={() => navigate('/dashboard/watchlists')}
                  className={`py-3 px-4 rounded-xl border cursor-pointer transition-all hover:scale-105 ${
                    isDarkMode ? 'bg-gray-900/70 border-gray-800/80 hover:bg-gray-900' : 'bg-white/80 border-gray-200/80 hover:bg-white'
                  }`}
                >
                  <div className="flex items-center space-x-3">
                    <svg width="24" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0" y="0" width="40" height="24" rx="3" fill={isDarkMode ? "#1a2744" : "#e5efff"} />
                      <rect x="6" y="14" width="3" height="4" rx="1.5" 
                        fill={isDarkMode ? "#60A5FA" : "#3B82F6"} opacity="0.4" />
                      <rect x="12" y="11" width="3" height="7" rx="1.5" 
                        fill={isDarkMode ? "#60A5FA" : "#3B82F6"} opacity="0.6" />
                      <rect x="18" y="8" width="3" height="10" rx="1.5" 
                        fill={isDarkMode ? "#60A5FA" : "#3B82F6"} opacity="0.8" />
                      <rect x="24" y="5" width="3" height="13" rx="1.5" 
                        fill={isDarkMode ? "#60A5FA" : "#3B82F6"} opacity="1" />
                      <rect x="6" y="7" width="28" height="0.5" rx="0.25" 
                        fill={isDarkMode ? "#4a5568" : "#94a3b8"} opacity="0.3" />
                      <rect x="6" y="11" width="28" height="0.5" rx="0.25" 
                        fill={isDarkMode ? "#4a5568" : "#94a3b8"} opacity="0.3" />
                      <rect x="6" y="15" width="28" height="0.5" rx="0.25" 
                        fill={isDarkMode ? "#4a5568" : "#94a3b8"} opacity="0.3" />
                      <circle cx="31" cy="8" r="1" fill={isDarkMode ? "#60A5FA" : "#3B82F6"} opacity="0.5" />
                      <circle cx="34" cy="12" r="1" fill={isDarkMode ? "#60A5FA" : "#3B82F6"} opacity="0.3" />
                    </svg>
                    <span className={`text-sm font-medium ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}>Your Saved</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Market status component (right column) */}
          <div className="w-80">
            <MarketStatusTimeline />
          </div>
        </div>

        {/* Welcome preview section - Desktop */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={welcomePreviewVariants}
          transition={{ duration: 0.5 }}
          className="mb-6 min-h-[905px] rounded-2xl welcome-preview-container"
        >
          {welcomePreviewContent}
        </motion.div>
      </div>
    )}

    {/* Mobile view with restructured layout */}
    {isMobileDevice && (
      <div className="max-w-7xl font-sans mx-auto px-3 py-3">
        {/* Mobile greeting header */}
        <div className={`rounded-xl overflow-hidden mb-4 ${
          isDarkMode ? 'bg-black/40' : 'bg-white/80'
        } backdrop-blur-md border ${
          isDarkMode ? 'border-gray-800/40' : 'border-gray-200/40'
        }`}>
          <div className="p-4">
            {/* Simplified header with just greeting and search icon */}
            <div className="flex items-center justify-between">
              <div>
                <h1 className={`text-lg font-heading font-light ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {getTimeBasedGreeting()}, {user?.name?.split(' ')[0]}
                </h1>
                <div className="flex items-center">
                  <div className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mr-3`}>
                    {new Date().toLocaleDateString('en-US', {
                      weekday: 'short',
                      month: 'short',
                      day: 'numeric'
                    })}
                  </div>
                  <div className={`font-mono text-sm tabular-nums font-light ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-600'
                  }`}>
                    {new Date().toLocaleTimeString('en-US', { 
                      hour: '2-digit', 
                      minute: '2-digit',
                      hour12: true
                    })}
                  </div>
                </div>
              </div>
              
              <div className="flex space-x-2">
                <button
                  onClick={() => navigate('/dashboard/search')}
                  className={`p-2.5 rounded-full ${
                    isDarkMode ? 'bg-gray-800 text-gray-300' : 'bg-gray-100 text-gray-700'
                  }`}
                >
                  <Search className="w-4.5 h-4.5" />
                </button>
                <button
                  onClick={() => setShowSettings(true)}
                  className={`p-2.5 rounded-full ${
                    isDarkMode ? 'bg-gray-800 text-gray-300' : 'bg-gray-100 text-gray-700'
                  }`}
                >
                  <Settings className="w-4.5 h-4.5" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile compressed market status */}
        <div className="mb-4">
          <MobileMarketStatus />
        </div>
        
        {/* Welcome preview section - Mobile */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={welcomePreviewVariants}
          transition={{ duration: 0.5 }}
          className="mb-4 rounded-xl overflow-hidden"
        >
          {welcomePreviewContent}
        </motion.div>
        
        {/* Quick links row instead of grid */}
        <div className="flex justify-between gap-2 mx-1 mb-4">
          <button
            onClick={() => navigate('/dashboard/market-chat')}
            className={`flex-1 py-2 rounded-lg flex flex-col items-center justify-center ${
              isDarkMode ? 'bg-gray-900/70 border-gray-800' : 'bg-white/80 border-gray-200/80'
            } border`}
          >
            <MessageSquare className={`w-5 h-5 mb-1 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            <span className={`text-xs font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Chat</span>
          </button>
          
          <button
            onClick={() => setShowTickerModal(true)}
            className={`flex-1 py-2 rounded-lg flex flex-col items-center justify-center ${
              isDarkMode ? 'bg-gray-900/70 border-gray-800' : 'bg-white/80 border-gray-200/80'
            } border`}
          >
            <TrendingUp className={`w-5 h-5 mb-1 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            <span className={`text-xs font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Ticker</span>
          </button>
          
          <button
            onClick={() => navigate('/dashboard/watchlists')}
            className={`flex-1 py-2 rounded-lg flex flex-col items-center justify-center ${
              isDarkMode ? 'bg-gray-900/70 border-gray-800' : 'bg-white/80 border-gray-200/80'
            } border`}
          >
            <Folder className={`w-5 h-5 mb-1 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            <span className={`text-xs font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Saved</span>
          </button>
        </div>
      </div>
    )}
    
    {/* Common sections regardless of device type */}
    <CreateCategoryPopup />
    {isEditingStocks && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center"
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          className={`w-full max-w-2xl p-6 rounded-2xl shadow-xl ${
            isDarkMode ? 'bg-gray-900' : 'bg-white'
          }`}
        >
          {/* Header */}
          <div className="flex items-center justify-between mb-6">
            <div>
              <h3 className={`text-lg font-semibold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>Edit Watched Stocks</h3>
              <p className={`text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`}>Select up to 6 stocks to watch</p>
            </div>
            <button
              onClick={() => setIsEditingStocks(false)}
              className={`p-2 rounded-lg transition-colors ${
                isDarkMode 
                  ? 'hover:bg-gray-800 text-gray-400' 
                  : 'hover:bg-gray-100 text-gray-600'
              }`}
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          {/* Search Input */}
          <div className="mb-6">
            <div className="relative">
              <Search className={`absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 
                ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} 
              />
              <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search stocks by symbol or company name..."
                className={`w-full pl-10 pr-4 py-3 rounded-lg ${
                  isDarkMode 
                    ? 'bg-gray-800 text-white border-gray-700' 
                    : 'bg-white text-gray-900 border-gray-200'
                } border focus:ring-2 focus:ring-blue-500`}
              />
            </div>
          </div>

          {/* Preview Grid */}
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="stocks" direction="horizontal">
              {(provided: DroppableProvided) => (
                <div 
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="grid grid-cols-6 gap-4"
                >
                  {watchedStocks.map((stock, index) => (
                    <Draggable key={stock.id} draggableId={stock.id} index={index}>
                      {(provided: DraggableProvided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`relative p-3 rounded-lg border ${
                            isDarkMode ? 'border-gray-800 bg-gray-800/50' : 'border-gray-200 bg-gray-50'
                          }`}
                        >
                          <button
                            onClick={() => handleRemoveStock(stock.id)}
                            className="absolute -top-2 -right-2 p-1 rounded-full bg-red-500 text-white 
                              transform transition-transform hover:scale-110"
                          >
                            <X className="w-3 h-3" />
                          </button>
                          <div className="flex flex-col items-center justify-center h-[72px] space-y-2">
                            <span className={`text-lg font-medium ${
                              isDarkMode ? 'text-white' : 'text-gray-900'
                            }`}>{stock.symbol}</span>
                            <span className={`text-xs ${
                              isDarkMode ? 'text-gray-400' : 'text-gray-500'
                            }`}>${stock.price?.toFixed(2) || '0.00'}</span>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  {Array(6 - watchedStocks.length).fill(0).map((_, i) => (
                    <div
                      key={`empty-${i}`}
                      className={`p-3 rounded-lg border-2 border-dashed ${
                        isDarkMode ? 'border-gray-800/50' : 'border-gray-200/50'
                      }`}
                    >
                      <div className={`flex flex-col items-center justify-center h-[72px] space-y-2 
                        ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`}>
                        <Plus className="w-5 h-5" />
                        <span className="text-xs">Add Stock</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {/* Search Results */}
          {results.length > 0 && (
            <div className={`max-h-60 overflow-y-auto rounded-lg border ${
              isDarkMode ? 'border-gray-800' : 'border-gray-200'
            }`}>
              {results.map((result) => (
                <motion.button
                  key={result.ticker}  // Changed from result.symbol
                  whileHover={{ backgroundColor: isDarkMode ? 'rgba(31,41,55,0.5)' : 'rgba(243,244,246,0.5)' }}
                  onClick={() => handleAddStock(result)}
                  disabled={watchedStocks.length >= 6}
                  className={`w-full p-3 flex items-center justify-between ${
                    isDarkMode 
                      ? 'border-b border-gray-800' 
                      : 'border-b border-gray-200'
                  } ${watchedStocks.length >= 6 ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  <div className="flex items-center space-x-3">
                    <span className={`font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>{result.ticker}</span>
                    <span className={
                      isDarkMode ? 'text-gray-400' : 'text-gray-600'
                    }>{result.name}</span>
                  </div>
                  <Plus className="w-4 h-4" />
                </motion.button>
              ))}
            </div>
          )}

          {/* Add loading state handling */}
          {isStockSearchLoading && (
            <div className="flex justify-center p-4">
              <LoadingSpinner size="sm" />
            </div>
          )}

          {/* Add error state handling */}
          {stockSearchError && (
            <div className={`p-4 text-sm text-red-500 ${
              isDarkMode ? 'bg-red-500/10' : 'bg-red-50'
            } rounded-lg mt-2`}>
              {stockSearchError.message}
            </div>
          )}
        </motion.div>
      </motion.div>
    )}
    <SaveStockModal
      isOpen={saveStockModalOpen}
      onClose={() => setSaveStockModalOpen(false)}
      stock={stockToSave}
      isDarkMode={isDarkMode}
    />
    <SettingsModal />
    <IdleDialog isOpen={isIdle} onResume={resumeActivity} />
  </>
);
};

export default DashboardOverview;

