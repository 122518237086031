import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { DocumentationLayout } from '../../components/layout/DocumentationLayout';
import { 
  Brain, 
  Search, 
  Globe, 
  LineChart, 
  Filter, 
  MessageSquare,
  BarChart3,
  ChevronRight
} from 'lucide-react';
import { Link } from 'react-router-dom';

const ResearchProcess = () => {
  const { isDarkMode } = useTheme();

  const researchStages = [
    {
      id: 'query-analysis',
      icon: Brain,
      title: 'Query Analysis',
      description: 'Prism analyzes your question to understand intent and context',
      details: `In this initial stage, Prism parses your query to identify the core information need, relevant financial entities (like stocks, sectors, or market indices), time frames, and analytical perspectives you're interested in.`
    },
    {
      id: 'ticker-discovery',
      icon: Search,
      title: 'Ticker Discovery',
      description: 'Identifying relevant stocks and financial instruments',
      details: `Prism identifies stock symbols, cryptocurrencies, ETFs, or other financial instruments relevant to your query. If you didn't specify any tickers, Prism will determine which ones are most appropriate for your question.`
    },
    {
      id: 'web-research',
      icon: Globe,
      title: 'Web Research',
      description: 'Gathering information from financial websites and news sources',
      details: 'Using specialized search techniques, Prism gathers current information from reputable financial websites, news sources, and databases. This ensures you receive the most recent and relevant information.'
    },
    {
      id: 'market-data',
      icon: LineChart,
      title: 'Market Data Analysis',
      description: 'Processing real-time and historical market data',
      details: 'Prism retrieves and analyzes market data including price history, volume, technical indicators, and fundamentals for the relevant securities.'
    },
    {
      id: 'info-synthesis',
      icon: Filter,
      title: 'Information Synthesis',
      description: 'Consolidating and prioritizing gathered information',
      details: 'All collected information is filtered for relevance, de-duplicated, and synthesized into a cohesive understanding of the topic. Prism weighs more authoritative and recent sources more heavily.'
    },
    {
      id: 'response-gen',
      icon: MessageSquare,
      title: 'Response Generation',
      description: 'Creating a comprehensive, insightful response',
      details: 'Finally, Prism generates a response that addresses your specific question, incorporating the most relevant insights, data points, and supporting evidence from the research process.'
    }
  ];

  return (
    <DocumentationLayout 
      title="Prism's Research Process"
      icon={BarChart3}
      description="Understand how Prism conducts real-time market research to deliver comprehensive insights"
    >
      <div className={`prose max-w-none ${isDarkMode ? 'prose-invert' : ''}`}>
        <h2>Overview of Prism's Research Methodology</h2>
        <p>
          When you ask Prism a question about the markets, it initiates a comprehensive multi-stage research process 
          designed to gather, analyze, and synthesize information from diverse sources. Each research query follows a 
          consistent workflow to ensure thorough, accurate, and timely responses.
        </p>
        
        <p>
          Unlike static information retrieval systems, Prism conducts active research in real-time, 
          similar to how a financial analyst would approach a research task - but at machine speed and scale.
        </p>

        <div className={`p-6 rounded-xl border my-8 ${
          isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
        }`}>
          <h3 className="mt-0">Research Process Transparency</h3>
          <p className="mb-0">
            Prism shows you its research progress in real-time, allowing you to:
          </p>
          <ul className="mb-0">
            <li>See which stage of research is currently active</li>
            <li>Track overall progress toward a complete answer</li>
            <li>View sources as they're discovered</li>
            <li>Understand the rationale behind Prism's research approach</li>
          </ul>
        </div>

        <h2>The Research Pipeline</h2>
        <p>
          Prism's research follows a systematic pipeline consisting of six main stages:
        </p>
      </div>

      {/* Research stages visualization */}
      <div className="my-12 space-y-6">
        {researchStages.map((stage, index) => {
          const Icon = stage.icon;
          return (
            <div 
              key={stage.id}
              className={`p-6 rounded-xl border ${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
              }`}
            >
              <div className="flex items-start">
                <div className={`p-3 rounded-lg mr-4 flex-shrink-0 ${
                  isDarkMode ? 'bg-blue-500/20' : 'bg-blue-50'
                }`}>
                  <Icon className={`w-6 h-6 ${
                    isDarkMode ? 'text-blue-400' : 'text-blue-600'
                  }`} />
                </div>
                <div>
                  <div className="flex items-center">
                    <span className={`text-sm font-medium px-2 py-1 rounded-full mr-3 ${
                      isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700'
                    }`}>
                      Stage {index + 1}
                    </span>
                    <h3 className={`text-lg font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {stage.title}
                    </h3>
                  </div>
                  <p className={`mt-1 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {stage.description}
                  </p>
                  <p className={`mt-3 text-sm ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`}>
                    {stage.details}
                  </p>
                </div>
              </div>
            </div>
          )
        })}
      </div>

      <div className={`prose max-w-none ${isDarkMode ? 'prose-invert' : ''}`}>
        <h2>Research Sources</h2>
        <p>
          Prism leverages a diverse array of data sources to provide comprehensive market insights:
        </p>
        
        <ul>
          <li><strong>Financial Data APIs:</strong> Real-time and historical market data including prices, volumes, and other metrics</li>
          <li><strong>News Sources:</strong> Recent financial news from reputable publications</li>
          <li><strong>SEC Filings:</strong> Official company disclosures and financial statements</li>
          <li><strong>Financial Websites:</strong> Analysis and information from established financial resources</li>
          <li><strong>Earnings Reports:</strong> Latest quarterly and annual financial results</li>
        </ul>

        <div className={`p-6 rounded-xl border my-8 ${
          isDarkMode ? 'bg-gray-800/80 border-gray-700' : 'bg-gray-50 border-gray-200'
        }`}>
          <h3 className="mt-0">Source Quality & Transparency</h3>
          <p>
            All sources used by Prism are clearly cited in the research results. You can review these 
            sources, evaluate their credibility, and explore them further. Pro subscribers receive 
            additional information about source quality ratings and comprehensive source lists.
          </p>
        </div>

        <h2>Viewing Research Progress</h2>
        <p>
          During research, Prism displays a live progress tracker that shows:
        </p>

        <ul>
          <li>Current research stage and overall progress</li>
          <li>Recently discovered sources</li>
          <li>Stocks and entities being researched</li>
          <li>Research context and rationale</li>
        </ul>

        <p>
          This transparency allows you to understand how Prism is approaching your question and 
          gives you insight into the research process as it unfolds.
        </p>

        <h2>Next Steps</h2>
        <p>
          To learn more about Prism's research capabilities:
        </p>

        <div className="grid sm:grid-cols-2 gap-6 mt-6 not-prose">
          <Link 
            to="/docs/research-stages" 
            className={`flex items-center justify-between p-4 rounded-lg transition-colors ${
              isDarkMode 
                ? 'bg-gray-800 hover:bg-gray-700 text-white border border-gray-700' 
                : 'bg-white hover:bg-gray-50 text-gray-900 border border-gray-200'
            }`}
          >
            <div className="flex items-center">
              <Filter className={`w-5 h-5 mr-3 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
              <span>Understanding Research Stages</span>
            </div>
            <ChevronRight className="w-5 h-5" />
          </Link>
          
          <Link 
            to="/docs/source-types" 
            className={`flex items-center justify-between p-4 rounded-lg transition-colors ${
              isDarkMode 
                ? 'bg-gray-800 hover:bg-gray-700 text-white border border-gray-700' 
                : 'bg-white hover:bg-gray-50 text-gray-900 border border-gray-200'
            }`}
          >
            <div className="flex items-center">
              <Globe className={`w-5 h-5 mr-3 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
              <span>Source Types & Quality</span>
            </div>
            <ChevronRight className="w-5 h-5" />
          </Link>
        </div>
      </div>
    </DocumentationLayout>
  );
};

export default ResearchProcess;