import React from 'react';
import { Link } from 'react-router-dom';
import { Shield, Crown, Sparkles } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import { useSubscription } from '../../contexts/SubscriptionProvider';
import { motion } from 'framer-motion';

interface SubscriptionDisplayProps {
  showUpgradeButton?: boolean;
  showBuyButton?: boolean;
  className?: string;
}

export const SubscriptionDisplay: React.FC<SubscriptionDisplayProps> = ({ 
  showUpgradeButton = true,
  showBuyButton = true,
  className = '' 
}) => {
  const { isDarkMode } = useTheme();
  const { subscription, hasActiveSubscription, isSubscriptionLoading } = useSubscription();

  if (isSubscriptionLoading) {
    return (
      <div className={`flex items-center gap-2 ${
        isDarkMode ? 'text-gray-300' : 'text-gray-700'
      } px-4 py-1.5 rounded-full bg-gray-500/10 backdrop-blur-sm border border-gray-500/20 shadow-sm ${className}`}>
        <div className="w-4 h-4 border-2 border-gray-300 border-t-transparent rounded-full animate-spin"></div>
        <span className="text-sm ml-1 font-medium">Loading...</span>
      </div>
    );
  }

  const isPro = hasActiveSubscription || 
    (subscription?.status?.toLowerCase() === 'active' || 
     subscription?.status?.toLowerCase() === 'trialing') ||
    (subscription?.tier?.toLowerCase() === 'pro');

  return (
    <div className={`flex items-center gap-3 ${className}`}>
      <motion.div 
        whileHover={{ scale: 1.03 }}
        className={`flex items-center gap-2 ${
          isDarkMode ? 'text-gray-200' : 'text-gray-700'
        } px-4 py-1.5 rounded-full shadow-sm ${
          isPro 
            ? `bg-gradient-to-r from-blue-500/20 to-purple-500/20 backdrop-blur-sm 
               border border-blue-400/30 hover:border-blue-400/50` 
            : `bg-gradient-to-r from-gray-500/10 to-gray-600/10 backdrop-blur-sm
               border border-gray-500/20 hover:border-gray-400/30`
        } transition-all duration-200`}
      >
        {isPro ? (
          <>
            <div className="relative">
              <Crown className="w-5 h-5 text-blue-400" />
              <motion.div 
                className="absolute -top-1 -right-1 w-2 h-2 bg-blue-400 rounded-full"
                animate={{ 
                  scale: [1, 1.5, 1],
                  opacity: [0.7, 1, 0.7],
                }}
                transition={{ duration: 2, repeat: Infinity }}
              />
            </div>
            <span className="text-sm font-semibold bg-gradient-to-r from-blue-400 to-purple-400 text-transparent bg-clip-text">Pro</span>
          </>
        ) : (
          <>
            <Shield className="w-5 h-5 text-gray-400" />
            <span className="text-sm font-medium">Free</span>
          </>
        )}
      </motion.div>
      
      {/* Only show if both flags are true and user doesn't have active subscription */}
      {(showUpgradeButton || showBuyButton) && !isPro && (
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.98 }}
        >
          <Link
            to="/pricing"
            className="text-xs px-2 py-1.5 rounded-full bg-gradient-to-r from-blue-500 to-blue-600 
                     text-white hover:from-blue-600 hover:to-blue-700 transition-all duration-200
                     shadow-sm hover:shadow flex items-center gap-1.5"
          >
            <Sparkles className="w-3.5 h-3.5" />
            <span className="font-medium">Upgrade</span>
          </Link>
        </motion.div>
      )}
    </div>
  );
};

// For backwards compatibility
export const CreditDisplay = SubscriptionDisplay;