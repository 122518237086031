// src/hooks/useWatchlists.ts

import { useState, useEffect } from 'react';
import { api } from '../services/api';
import { useAuth } from '../contexts/AuthContext';


export type SavedItemType = 'article' | 'stock' | 'crypto' | 'other';

export interface SavedItem {
    id: string;
    userId: string;
    type: SavedItemType;
    title: string;
    identifier: string;
    metadata: Record<string, any>;
    notes?: string;
    lists: WatchList[];
    createdAt: Date;
    updatedAt: Date;
}

export interface WatchList {
    id: string;
    userId: string;
    name: string;
    description?: string;
    icon?: string;
    color?: string;
    items: SavedItem[];
    createdAt: Date;
    updatedAt: Date;
}

export interface WatchlistActions {
  addToList: (itemId: string, listId: string) => Promise<void>;
  removeFromList: (itemId: string, listId: string) => Promise<void>;
  deleteList: (listId: string) => Promise<void>;
  updateList: (listId: string, updates: Partial<WatchList>) => Promise<void>;
}

export const useWatchlists = () => {
  const { isAuthenticated } = useAuth(); // Import useAuth hook
  const [watchlists, setWatchlists] = useState<WatchList[]>([]);
  const [savedItems, setSavedItems] = useState<SavedItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);


  const fetchWatchlists = async () => {
    // Skip fetching if not authenticated
    if (!isAuthenticated) {
      setWatchlists([]);
      setSavedItems([]);
      return;
    }
    
    setIsLoading(true);
    try {
      const { data } = await api.get('/market/watchlists');
      setWatchlists(data.watchlists);
      setSavedItems(data.savedItems);
      setError(null);
    } catch (error) {
      console.error('Failed to fetch watchlists:', error);
      setError('Failed to load watchlists');
      setWatchlists([]);
      setSavedItems([]);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // Only fetch if authenticated
    if (isAuthenticated) {
      fetchWatchlists();
    }
  }, [isAuthenticated]);

  // Modify saveItem to not store price data
  const saveItem = async (item: {
    type: string;
    identifier: string;
    title: string;
    metadata?: Record<string, any>;
  }) => {
    // Remove any price-related data from metadata
    const { price, change, ...otherMetadata } = item.metadata || {};
    
    try {
      const { data } = await api.post('/market/watchlists/items', {
        ...item,
        metadata: otherMetadata // Store only non-price metadata
      });
      setSavedItems(prev => [...prev, { ...data, lists: [] }]);
      return data;
    } catch (error) {
      console.error('Failed to save item:', error);
      throw error;
    }
  };

  const createList = async (list: {
    name: string;
    description?: string;
    icon?: string;
    color?: string;
  }) => {
    try {
      const { data } = await api.post('/market/watchlists', list);
      // Ensure items array exists even if empty
      const newList = {
        ...data,
        items: data.items || []
      };
      setWatchlists(prev => [...prev, newList]);
      return newList;
    } catch (error) {
      console.error('Failed to create watchlist:', error);
      return null;
    }
  };

  const isItemSaved = (type: string, identifier: string) => {
    // Add null check before calling .some()
    return savedItems?.some(
      item => item.type === type && item.identifier === identifier
    ) ?? false;
  };

  const addToList = async (itemId: string, listId: string) => {
    try {
      // Remove /api prefix from URL
      await api.post(`/market/watchlists/${listId}/items`, {
        identifier: itemId,
        type: 'STOCK'
      });
      await fetchWatchlists(); // Refresh data
      return true;
    } catch (error) {
      console.error('Failed to add item to list:', error);
      return false;
    }
  };

  const removeFromList = async (itemId: string, listId: string) => {
    try {
      // Delete the connection between item and list
      await api.delete(`/market/watchlists/${listId}/items/${itemId}`);
      
      // Update local state after successful API call
      setWatchlists(prev => prev.map(list => {
        if (list.id === listId) {
          return {
            ...list,
            items: list.items.filter(item => item.identifier !== itemId)
          };
        }
        return list;
      }));
    } catch (error) {
      console.error('Failed to remove item from list:', error);
      throw error;
    }
  };

  const deleteList = async (listId: string) => {
    try {
      await api.delete(`/market/watchlists/${listId}`);
      
      // Update both watchlists and savedItems state
      setWatchlists(prev => prev.filter(list => list.id !== listId));
      setSavedItems(prev => prev.map(item => ({
        ...item,
        lists: item.lists.filter(list => list.id !== listId)
      })));
    } catch (error) {
      console.error('Failed to delete list:', error);
      throw error;
    }
  };

  const updateList = async (listId: string, updates: Partial<WatchList>) => {
    try {
      const { data } = await api.patch(`/market/watchlists/${listId}`, updates);
      setWatchlists(prev => prev.map(list => 
        list.id === listId ? { ...list, ...data } : list
      ));
    } catch (error) {
      console.error('Failed to update list:', error);
      throw error;
    }
  };

  const deleteItem = async (identifier: string) => {
    try {
      await api.delete(`/market/saved-items/${identifier}`);
      setSavedItems(prev => prev.filter(item => item.identifier !== identifier));
    } catch (error) {
      console.error('Failed to delete saved item:', error);
      throw error;
    }
  };

  useEffect(() => {
    fetchWatchlists();
  }, []);

  return {
    watchlists,
    savedItems,
    isLoading,
    error,
    saveItem,
    createList,
    isItemSaved,
    addToList,
    removeFromList,
    deleteList,
    updateList,
    deleteItem // Add this
  };
};

