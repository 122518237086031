import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { api } from '../../services/api';
import { ResearchModeModal } from './ResearchModeModal';
import { Box, Brain, ChevronDown, ChevronUp, Database, LineChart, Sparkles, Target, Search, Paperclip, Plus, ListFilter, ChevronRight, Send } from 'lucide-react';
import StockSparkline from '../../pages/dashboard/StockSparkline';
import { cachedPromptService } from '../../services/cachedPromptService';
import { useStockView } from '../../contexts/StockViewContext';
import { useWatchlists } from '../../hooks/useWatchlists';
import ReactDOM from 'react-dom';
import { useAuth } from '../../contexts/AuthContext';
import { usePrompt } from '../../contexts/PromptContext';
import { WatchlistSelectModal } from './WatchlistSelectModal';
const PIXABAY_API_KEY = '48933707-aca473cc491a4a26628d80a0a';
const CENTRAL_SUN_LAYOUT_ID = "central-sun";

interface WelcomeStateProps {
  isDarkMode: boolean;
  onPromptSelect: (prompt: string, researchModePrefix?: string, displayAsChip?: boolean) => void;
  isSearchLayout?: boolean;
  selectedResearchMode?: string;
  onResearchModeChange?: (mode: string) => void;
  onSaveStock?: (stock: { ticker: string; name: string }) => void;
}

interface TrendingStockItem {
  ticker: string;
  name: string;
  reason: string;
  changePercent?: number;
  price?: number;
  priceLoaded?: boolean;
}

const researchModes = [
  {
    id: 'default',
    name: 'Standard',
    icon: Brain,
    description: 'Balanced research approach for general market inquiries',
    prefix: ''
  },
  {
    id: 'discovery',
    name: 'Discovery',
    icon: Sparkles,
    description: 'Explore new market opportunities and emerging trends',
    prefix: '[DISCOVERY MODE] '
  },
  {
    id: 'deep-dive',
    name: 'Deep Dive',
    icon: Database,
    description: 'Thorough analysis with comprehensive data examination',
    prefix: '[DEEP DIVE] '
  },
  {
    id: 'contrarian',
    name: 'Contrarian',
    icon: Target,
    description: 'Challenge market consensus and explore alternative views',
    prefix: '[CONTRARIAN ANALYSIS] '
  },
  {
    id: 'technical',
    name: 'Technical',
    icon: LineChart,
    description: 'Focus on technical indicators and chart patterns',
    prefix: '[TECHNICAL ANALYSIS] '
  }
];

interface PromptItem {
  prompt: string;
  isHeadline?: boolean;
  x: number;
  y: number;
  delay: number;
  imageUrl?: string | null;
  imageQuery?: string | null;
  ticker?: string;
  isImageLoading?: boolean;
  rotationDuration: number;
  ringIndex: number;
  angleOffset: number;
  tickerChangePercent?: number;
  tickerPriceLoaded?: boolean;
}

const ImageFallback: React.FC<{ prompt: string }> = ({ prompt }) => {
  return (
    <div className="w-16 h-16 flex items-center justify-center bg-gradient-to-br from-gray-800 to-gray-900 text-gray-400">
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        className="w-6 h-6"
        viewBox="0 0 24 24" 
        fill="none" 
        stroke="currentColor" 
        strokeWidth="2"
      >
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
        <circle cx="8.5" cy="8.5" r="1.5" />
        <path d="M21 15l-5-5L5 21" />
      </svg>
    </div>
  );
};

const PromptItem: React.FC<{item: PromptItem}> = ({ item }) => {
  const [hasImageError, setHasImageError] = useState(false);
  
  return (
    <motion.div
      key={item.prompt}
      className={`absolute cursor-pointer bg-black/50 rounded-lg backdrop-blur-sm border border-gray-700/20 overflow-hidden z-20`}
      style={{
        transformOrigin: 'center center',
      }}
      initial={{ opacity: 0, scale: 0 }}
      animate={{ 
        opacity: 1,
        scale: 1,
        x: item.x,
        y: item.y,
      }}
      transition={{
        duration: 0.8,
        delay: 0.5 + item.delay,
        ease: [0.23, 1.12, 0.38, 1]
      }}
    >
      {!hasImageError && item.imageUrl ? (
        <img
          src={item.imageUrl}
          alt=""
          className="w-16 h-16 object-cover"
          loading="lazy"
          onError={() => setHasImageError(true)}
        />
      ) : (
        <ImageFallback prompt={item.prompt} />
      )}
      {item.ticker && (
        <div className="absolute top-2 right-2 px-2 py-0.5 bg-blue-500/80 rounded-md backdrop-blur-sm z-10 flex items-center">
          <span className="text-xs font-mono font-bold text-white">${item.ticker}</span>
          {item.tickerPriceLoaded && (
            <span className={`ml-1 text-xs ${(item.tickerChangePercent ?? 0) >= 0 ? 'text-green-300' : 'text-red-300'}`}>
              {(item.tickerChangePercent ?? 0) >= 0 ? '▲' : '▼'}
              {Math.abs(item.tickerChangePercent ?? 0).toFixed(1)}%
            </span>
          )}
        </div>
      )}
    </motion.div>
  );
};

const imageCache = new Map<string, string>();

const generateImageSearchTerms = (prompt: string): string => {
  const fillerWords = [
    'what', 'how', 'when', 'where', 'why', 'who', 'which', 'analyze',
    'the', 'and', 'or', 'but', 'in', 'to', 'market', 'stock', 'stocks',
    'performance', 'trend', 'trends', 'analysis', 'impact', 'sector',
    'sectors', 'explain', 'show', 'current', 'recent', 'latest',
    'outlook', 'forecast', 'prediction', 'evaluate', 'compare'
  ];
  
  let words = prompt
    .toLowerCase()
    .replace(/[^\w\s]/g, '')
    .split(/\s+/)
    .filter(word => word.length > 3)
    .filter(word => !fillerWords.includes(word));
  
  if (prompt.match(/tech|technology|software|ai|artificial intelligence|digital/i)) {
    return "technology";
  } else if (prompt.match(/crypto|bitcoin|blockchain|ethereum|nft/i)) {
    return "cryptocurrency";
  } else if (prompt.match(/fed|interest rate|federal reserve|central bank/i)) {
    return "fedreserve";
  } else if (prompt.match(/volatility|uncertain|risk|unstable/i)) {
    return "volatility";
  } else if (prompt.match(/earning|profit|revenue|income/i)) {
    return "earnings";
  } else if (prompt.match(/grow|bull|increase|rise|rally/i)) {
    return "bullmarket";
  } else if (prompt.match(/down|bear|decrease|fall|decline|crash/i)) {
    return "bearmarket";
  } else if (prompt.match(/oil|energy|gas|petroleum/i)) {
    return "petroleum";
  } else if (prompt.match(/gold|silver|precious metal|commodity/i)) {
    return "gold";
  } else if (prompt.match(/real estate|property|housing|home/i)) {
    return "realestate";
  } else if (prompt.match(/recession|depression|economic downturn/i)) {
    return "recession";
  } else if (prompt.match(/inflation|price|cost|consumer/i)) {
    return "inflation";
  } else if (prompt.match(/trade|tariff|export|import|global/i)) {
    return "trade";
  } else if (prompt.match(/health|pharmaceutical|medical|drug/i)) {
    return "pharmaceutical";
  }
  
  if (words.length === 0) {
    const randomSeed = Date.now() % 5;
    const genericTerms = ["finance", "stocks", "chart", "business", "economy"];
    return genericTerms[randomSeed];
  }
  
  return words[0];
}

const fetchPixabayImage = async (prompt: string, imageQuery?: string | null): Promise<string | null> => {
  if (!prompt) {
    return null;
  }

  const cacheKey = prompt.substring(0, 30);

  if (imageCache.has(cacheKey)) {
    return imageCache.get(cacheKey) || null;
  }

  try {
    const searchTerms = imageQuery || generateImageSearchTerms(prompt);

    const response = await fetch(
      `https://pixabay.com/api/?key=${PIXABAY_API_KEY}&q=${encodeURIComponent(searchTerms)}&per_page=5&image_type=photo&orientation=horizontal&category=business`
    );
    
    const data = await response.json();
    
    const hits = data.hits || [];
    let imageUrl = null;
    
    if (hits.length > 0) {
      const randomIndex = Math.floor(Math.random() * Math.min(hits.length, 3));
      imageUrl = hits[randomIndex]?.webformatURL || null;
    }
    
    imageCache.set(cacheKey, imageUrl || '');
    
    return imageUrl;
  } catch (error) {
    console.error('Image fetch error:', error);
    return null;
  }
};

const fetchTickerData = async (ticker: string): Promise<{ changePercent: number } | null> => {
  if (!ticker) return null;

  try {
    const today = new Date();
    const startDate = new Date();
    startDate.setDate(today.getDate() - 5);
    
    const fromDate = startDate.toISOString().split('T')[0];
    const toDate = today.toISOString().split('T')[0];
    
    const { data } = await api.get(`/market/stock/${ticker}/aggregates`, {
      params: {
        multiplier: 1,
        timespan: 'day',
        from: fromDate,
        to: toDate
      }
    });
    
    if (data && data.results && data.results.length > 0) {
      const latestBar = data.results[data.results.length - 1];
      
      let prevClose = latestBar.o;
      
      if (data.results.length > 1) {
        prevClose = data.results[data.results.length - 2].c;
      }
      
      const changePercent = ((latestBar.c - prevClose) / prevClose) * 100;
      return { changePercent };
    }
    return null;
  } catch (error) {
    console.error(`Failed to fetch data for ${ticker}:`, error);
    return null;
  }
};

export const StockChip: React.FC<{
  ticker: string;
  companyName?: string;
  prefix: string;
  onRemove: () => void;
  isDarkMode: boolean;
  readOnly?: boolean;
}> = ({ ticker, companyName, prefix, onRemove, isDarkMode, readOnly = false }) => {
  const [imageError, setImageError] = useState(false);
  
  return (
    <div className={`inline-flex items-center gap-1 py-1 px-2 rounded-md text-sm ${
      isDarkMode ? 'bg-green-900/40 text-green-300 border border-green-700/50' : 
                  'bg-green-100 text-green-800 border border-green-200'
    }`}>
      <div className={`overflow-hidden w-5 h-5 rounded-full flex items-center justify-center 
        ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} 
        border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} mr-1`}
      >
        {!imageError ? (
          <img
            src={`/api/market/company-logo/${ticker}`}
            alt={`${ticker} logo`}
            className="w-full h-full object-cover"
            onError={() => setImageError(true)}
          />
        ) : (
          <span className={`text-xs font-semibold ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}>
            {ticker[0]}
          </span>
        )}
      </div>
      
      {prefix && (
        <span className={`text-xs px-1.5 py-0.5 rounded ${
          isDarkMode ? 'bg-green-800/70 text-green-300' : 'bg-green-200 text-green-700'
        }`}>
          {prefix.replace(/[\[\]]/g, '')}
        </span>
      )}
      
      <span className="font-mono font-medium">{ticker}</span>
      {companyName && <span className="text-xs truncate max-w-[100px] opacity-70">{companyName}</span>}
      
      {!readOnly && (
        <button 
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
          className={`ml-1 rounded-full p-0.5 ${
            isDarkMode ? 'hover:bg-green-700/60' : 'hover:bg-green-200'
          }`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" 
               stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      )}
    </div>
  );
};

export const PromptChip: React.FC<{
  text: string;
  prefix: string;
  onRemove: () => void;
  isDarkMode: boolean;
}> = ({ text, prefix, onRemove, isDarkMode }) => {
  return (
    <div className={`inline-flex items-center gap-1 py-1 px-2 rounded-md text-sm ${
      isDarkMode ? 'bg-blue-900/40 text-blue-300 border border-blue-700/50' : 
                  'bg-blue-100 text-blue-800 border border-blue-200'
    }`}>
      <Box size={14} className={`mr-1 ${isDarkMode ? 'text-blue-300' : 'text-blue-600'}`} />
      
      {prefix && (
        <span className={`text-xs px-1.5 py-0.5 rounded ${
          isDarkMode ? 'bg-blue-800/70 text-blue-300' : 'bg-blue-200 text-blue-700'
        }`}>
          {prefix.replace(/[\[\]]/g, '')}
        </span>
      )}
      <span className="truncate max-w-[300px]">{text}</span>
      <button 
        onClick={(e) => {
          e.stopPropagation();
          onRemove();
        }}
        className={`ml-1 rounded-full p-0.5 ${
          isDarkMode ? 'hover:bg-blue-700/60' : 'hover:bg-blue-200'
        }`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" 
             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  );
};

export const FileChip: React.FC<{
  file: File;
  onRemove: () => void;
  isDarkMode: boolean;
}> = ({ file, onRemove, isDarkMode }) => {
  const extension = file.name.split('.').pop()?.toLowerCase() || '';
  
  const formatFileSize = (bytes: number): string => {
    if (bytes < 1024) return `${bytes} B`;
    if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(1)} KB`;
    return `${(bytes / (1024 * 1024)).toFixed(1)} MB`;
  };
  
  const getFileTypeInfo = (ext: string): { type: string, icon: React.ReactNode } => {
    switch (ext) {
      case 'pdf':
        return {
          type: 'PDF Document',
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
              <polyline points="14 2 14 8 20 8"></polyline>
              <path d="M9 15v-4"></path>
              <path d="M12 15v-6"></path>
              <path d="M15 15v-2"></path>
            </svg>
          )
        };
      case 'doc':
      case 'docx':
        return {
          type: 'Word Document',
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
              <polyline points="14 2 14 8 20 8"></polyline>
              <line x1="16" y1="13" x2="8" y2="13"></line>
              <line x1="16" y1="17" x2="8" y2="17"></line>
              <polyline points="10 9 9 9 8 9"></polyline>
            </svg>
          )
        };
      case 'csv':
      case 'xls':
      case 'xlsx':
        return {
          type: 'Spreadsheet',
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
              <line x1="3" y1="9" x2="21" y2="9"></line>
              <line x1="3" y1="15" x2="21" y2="15"></line>
              <line x1="9" y1="3" x2="9" y2="21"></line>
              <line x1="15" y1="3" x2="15" y2="21"></line>
            </svg>
          )
        };
      case 'txt':
        return {
          type: 'Text Document',
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="21" y1="6" x2="3" y2="6"></line>
              <line x1="15" y1="12" x2="3" y2="12"></line>
              <line x1="17" y1="18" x2="3" y2="18"></line>
            </svg>
          )
        };
      default:
        return {
          type: 'Document',
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
              <polyline points="14 2 14 8 20 8"></polyline>
            </svg>
          )
        };
    }
  };
  
  const { type, icon } = getFileTypeInfo(extension);
  
  return (
    <div className={`inline-flex items-center gap-1 py-1 px-2 rounded-md text-sm ${
      isDarkMode ? 'bg-purple-900/40 text-purple-300 border border-purple-700/50' : 
                  'bg-purple-100 text-purple-800 border border-purple-200'
    }`}>
      <div className={`text-${isDarkMode ? 'purple-300' : 'purple-600'} mr-1`}>
        {icon}
      </div>
      
      <div className="flex flex-col">
        <div className="flex items-center">
          <span className="font-medium truncate max-w-[120px]">{file.name}</span>
          <span className={`ml-1 text-xs ${isDarkMode ? 'text-purple-400/70' : 'text-purple-700/70'}`}>
            ({extension.toUpperCase()})
          </span>
        </div>
        <div className={`text-xs ${isDarkMode ? 'text-purple-400/70' : 'text-purple-700/70'}`}>
          {type} • {formatFileSize(file.size)}
        </div>
      </div>
      
      <button 
        onClick={(e) => {
          e.stopPropagation();
          onRemove();
        }}
        className={`ml-1 rounded-full p-0.5 ${
          isDarkMode ? 'hover:bg-purple-700/60' : 'hover:bg-purple-200'
        }`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" 
             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  );
};

const WelcomeStatePreview = ({ isDarkMode, onPromptSelect, ...props }: WelcomeStateProps, ref: React.ForwardedRef<WelcomeStatePreviewRef>) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [prompts, setPrompts] = useState<string[]>([]);
  const [isResearchModalOpen, setIsResearchModalOpen] = useState(false);
  const {
    selectedPromptChip, setSelectedPromptChip,
    selectedStockChip, setSelectedStockChip,
    selectedFiles, setSelectedFiles,
    searchQuery, setSearchQuery
  } = usePrompt();
const MAX_FILES = 5;
  const [promptItems, setPromptItems] = useState<PromptItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { openStockModal } = useStockView();
  const [containerSize, setContainerSize] = useState({ width: 1000, height: 800 });
  
  const [selectedResearchMode, setSelectedResearchMode] = useState(researchModes[0]);
  const [showResearchModes, setShowResearchModes] = useState(false);
  const portalRef = useRef<HTMLElement | null>(null);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
const [stockToSave, setStockToSave] = useState<{ticker: string, name: string} | null>(null);
const { user } = useAuth();
const [showWatchlistMenu, setShowWatchlistMenu] = useState(false);
const [trendingStocks, setTrendingStocks] = useState<TrendingStockItem[]>([]);
  const loadingMessages = [
    "Initializing connections...",
    "Gathering market insights...",
    "AI Working...",
    "Analyzing global trends...",
    "Synthesizing market data..."
  ];
  const [loadingText, setLoadingText] = useState(loadingMessages[0]);

useEffect(() => {
  let portalContainer = document.getElementById('welcome-dropdown-portal');
  
  if (!portalContainer) {
    portalContainer = document.createElement('div');
    portalContainer.id = 'welcome-dropdown-portal';
    portalContainer.style.position = 'fixed';
    portalContainer.style.left = '0';
    portalContainer.style.top = '0';
    portalContainer.style.width = '100%';
    portalContainer.style.height = '100%';
    portalContainer.style.overflow = 'visible';
    portalContainer.style.zIndex = '99999'; 
    portalContainer.style.pointerEvents = 'none';
    document.body.appendChild(portalContainer);
  }
  
  portalRef.current = portalContainer as HTMLElement;
  
  return () => {
    if (portalContainer && !document.querySelector('[data-keeps-portal="true"]')) {
      document.body.removeChild(portalContainer);
    }
  };
}, []);

useEffect(() => {
  if (showResearchModes || showWatchlistMenu) {
    const updatePositions = () => {
      if (showResearchModes && portalRef.current) {
        const button = document.querySelector('.research-mode-selector button');
        const dropdown = document.querySelector('.research-modes-dropdown') as HTMLElement;
        
        if (button && dropdown) {
          const rect = button.getBoundingClientRect();
          dropdown.style.position = 'fixed';
          dropdown.style.top = `${rect.bottom + 8}px`; // Position below the button with small gap
          dropdown.style.left = `${rect.left}px`;
          dropdown.style.setProperty('display', 'block', 'important');
          dropdown.style.setProperty('visibility', 'visible', 'important');
          dropdown.style.setProperty('opacity', '1', 'important');
          dropdown.style.setProperty('pointer-events', 'auto', 'important');
          dropdown.style.setProperty('z-index', '99999', 'important');
        }
      }
      
      if (showWatchlistMenu && portalRef.current) {
        const button = watchlistMenuRef.current;
        const dropdown = portalRef.current.querySelector('.watchlist-dropdown') as HTMLElement;
        
        if (button && dropdown) {
          const rect = button.getBoundingClientRect();
          dropdown.style.position = 'fixed';
          dropdown.style.top = `${rect.bottom + 8}px`; // Position below the button with small gap
          dropdown.style.left = `${rect.left}px`;
          dropdown.style.setProperty('display', 'block', 'important');
          dropdown.style.setProperty('visibility', 'visible', 'important');
          dropdown.style.setProperty('opacity', '1', 'important');
          dropdown.style.setProperty('pointer-events', 'auto', 'important');
          dropdown.style.setProperty('z-index', '99999', 'important');
        }
      }
    };
    
    updatePositions();
    const timer = setTimeout(updatePositions, 50);
    
    window.addEventListener('resize', updatePositions);
    window.addEventListener('scroll', updatePositions, true);
    
    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', updatePositions);
      window.removeEventListener('scroll', updatePositions, true);
    };
  }
}, [showResearchModes, showWatchlistMenu]);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setLoadingText(current => {
          const currentIndex = loadingMessages.indexOf(current);
          return loadingMessages[(currentIndex + 1) % loadingMessages.length];
        });
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [isLoading]);

  const handleSaveStock = (e: React.MouseEvent, stock: TrendingStockItem) => {
    e.stopPropagation();
    
    if (!user) {
      window.location.href = '/signup';
      return;
    }
    
    if (props.onSaveStock) {
      props.onSaveStock({
        ticker: stock.ticker,
        name: stock.name
      });
    }
  };

  const fetchTrendingStocks = async () => {
    try {
      const data = await cachedPromptService.getCachedTrendingStocks();
      if (Array.isArray(data) && data.length > 0) {
        setTrendingStocks(data);
        
        const updatedStocks = await Promise.all(
          data.map(async (stock) => {
            try {
              const tickerData = await fetchTickerData(stock.ticker);
              
              const today = new Date();
              const startDate = new Date();
              startDate.setDate(today.getDate() - 5);
              
              const fromDate = startDate.toISOString().split('T')[0];
              const toDate = today.toISOString().split('T')[0];
              
              const { data } = await api.get(`/market/stock/${stock.ticker}/aggregates`, {
                params: {
                  multiplier: 1,
                  timespan: 'day',
                  from: fromDate,
                  to: toDate
                }
              });
              
              let price = 0;
              if (data && data.results && data.results.length > 0) {
                price = data.results[data.results.length - 1].c;
              }
              
              return {
                ...stock,
                price,
                changePercent: tickerData?.changePercent || 0,
                priceLoaded: true
              };
            } catch (error) {
              console.error(`Failed to fetch data for ${stock.ticker}:`, error);
              return stock;
            }
          })
        );
        
        setTrendingStocks(updatedStocks);
      }
    } catch (error) {
      console.error('Failed to fetch trending stocks:', error);
    }
  };

  interface NormalizedPrompt {
    text: string;
    isHeadline: boolean;
    imageQuery?: string | null;
    ticker?: string;
  }

  useEffect(() => {
    const fetchPrompts = async () => {
      setIsLoading(true);
      try {
        const data = await cachedPromptService.getCachedPrompts();
        
        await fetchTrendingStocks();
        
        let normalizedPrompts: NormalizedPrompt[];
        
        if (Array.isArray(data)) {
          if (typeof data[0] === 'string') {
            normalizedPrompts = data.map(text => ({ 
              text, 
              isHeadline: false 
            }));
            
            if (normalizedPrompts.length >= 4) {
              for (let i = 0; i < 4; i++) {
                normalizedPrompts[i].isHeadline = true;
              }
            }
          } else {
            normalizedPrompts = data.map(item => {
              if (typeof item === 'string') {
                return { text: item, isHeadline: false };
              } else {
                return { 
                  text: item.text || '', 
                  isHeadline: !!item.isHeadline,
                  imageQuery: item.imageQuery || null,
                  ticker: item.ticker || null
                };
              }
            });
          }
        } else {
          normalizedPrompts = [];
        }
        
        setPrompts(normalizedPrompts.map(p => p.text));
        
        const processedItems: PromptItem[] = normalizedPrompts.map((item, index) => ({
          prompt: item.text,
          isHeadline: item.isHeadline,
          imageQuery: item.imageQuery,
          ticker: item.ticker || "",
          x: 0,
          y: 0,
          delay: index * 0.1,
          rotationDuration: 0,
          ringIndex: 0,
          angleOffset: 0,
        }));
        
        const updatedItems = await Promise.all(
          processedItems.map(async (item) => {
            const [imageUrl, tickerData] = await Promise.all([
              fetchPixabayImage(item.prompt, item.imageQuery),
              item.ticker ? fetchTickerData(item.ticker) : Promise.resolve(null)
            ]);
            
            return {
              ...item,
              imageUrl,
              tickerChangePercent: tickerData?.changePercent,
              tickerPriceLoaded: !!tickerData
            };
          })
        );
        
        setPromptItems(updatedItems);
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch prompts:', error);
        setIsLoading(false);
      }
    };
  
    fetchPrompts();
    updateContainerSize();
    window.addEventListener('resize', updateContainerSize);
    return () => window.removeEventListener('resize', updateContainerSize);
  }, []);

  

  const refreshPrompts = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get('/market/prompts/market');
      
      let normalizedPrompts: NormalizedPrompt[];
      if (Array.isArray(data)) {
        if (typeof data[0] === 'string') {
          normalizedPrompts = data.map(text => ({ text, isHeadline: false }));
          if (normalizedPrompts.length >= 4) {
            for (let i = 0; i < 4; i++) {
              normalizedPrompts[i].isHeadline = true;
            }
          }
        } else {
          normalizedPrompts = data.map(item => {
            if (typeof item === 'string') {
              return { text: item, isHeadline: false };
            } else {
              return { 
                text: item.text || '', 
                isHeadline: !!item.isHeadline,
                imageQuery: item.imageQuery || null,
                ticker: item.ticker || null
              };
            }
          });
        }
      } else {
        normalizedPrompts = [];
      }
      
      setPrompts(normalizedPrompts.map(p => p.text));
      
      const processedItems: PromptItem[] = normalizedPrompts.map((item, index) => ({
        prompt: item.text,
        isHeadline: item.isHeadline,
        imageQuery: item.imageQuery,
        ticker: item.ticker || "",
        x: 0,
        y: 0,
        delay: index * 0.1,
        rotationDuration: 0,
        ringIndex: 0,
        angleOffset: 0,
      }));
      
      const updatedItems = await Promise.all(
        processedItems.map(async (item) => {
          const [imageUrl, tickerData] = await Promise.all([
            fetchPixabayImage(item.prompt, item.imageQuery),
            item.ticker ? fetchTickerData(item.ticker) : Promise.resolve(null)
          ]);
          
          return {
            ...item,
            imageUrl,
            tickerChangePercent: tickerData?.changePercent,
            tickerPriceLoaded: !!tickerData
          };
        })
      );
      
      setPromptItems(updatedItems);
      
      await fetchTrendingStocks();
    } catch (error) {
      console.error('Failed to refresh prompts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    refreshPrompts,
    addPromptChip: (text: string, prefix: string) => {
      setSelectedPromptChip({ text, prefix });
      setSearchQuery('');
    }
  }));

  const updateContainerSize = () => {
    const container = document.getElementById('welcome-container');
    if (container) {
      setContainerSize({
        width: container.offsetWidth,
        height: container.offsetHeight
      });
    }
  };

  useEffect(() => {
    if (showResearchModes) {
      const updatePosition = () => {
        const button = document.querySelector('.research-mode-selector button');
        const dropdown = document.querySelector('.research-modes-dropdown') as HTMLElement;
        
        if (button && dropdown) {
          const rect = button.getBoundingClientRect();
          dropdown.style.top = `${rect.bottom}px`;
          dropdown.style.left = `${rect.left}px`;
        }
      };
      
      updatePosition();
      window.addEventListener('resize', updatePosition);
      window.addEventListener('scroll', updatePosition);
      
      return () => {
        window.removeEventListener('resize', updatePosition);
        window.removeEventListener('scroll', updatePosition);
      };
    }
  }, [showResearchModes]);

const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
  const files = e.target.files;
  if (!files) return;
  
  const newFiles = Array.from(files);
  
  const availableSlots = MAX_FILES - selectedFiles.length;
  if (availableSlots <= 0) {
    alert(`Maximum of ${MAX_FILES} files allowed.`);
    return;
  }
  
  const filesToAdd = newFiles.slice(0, availableSlots);
  setSelectedFiles([...selectedFiles, ...filesToAdd]);
  
  e.target.value = '';
};

const removeFile = (index: number) => {
  if (Array.isArray(selectedFiles)) {
    const newFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(newFiles);
  }
};

const handleFileButtonClick = () => {
  fileInputRef.current?.click();
};

useEffect(() => {
  if (props.selectedResearchMode) {
    const externalMode = researchModes.find(m => m.id === props.selectedResearchMode);
    if (externalMode) {
      setSelectedResearchMode(externalMode);
    }
  }
}, [props.selectedResearchMode]);

const handleResearchModeChange = (mode: typeof researchModes[0]) => {
  setSelectedResearchMode(mode);
  if (props.onResearchModeChange) {
    props.onResearchModeChange(mode.id);
  }
};

const { watchlists, savedItems } = useWatchlists();

const watchlistMenuRef = useRef<HTMLDivElement>(null);

useEffect(() => {
  const handleClickOutside = (event: MouseEvent) => {
    if (watchlistMenuRef.current && !watchlistMenuRef.current.contains(event.target as Node)) {
      setShowWatchlistMenu(false);
    }
  };

  document.addEventListener('mousedown', handleClickOutside);
  return () => document.removeEventListener('mousedown', handleClickOutside);
}, []);

const [isWatchlistModalOpen, setIsWatchlistModalOpen] = useState(false);

const [isMobileDevice, setIsMobileDevice] = useState(false);

useEffect(() => {
  const checkMobile = () => {
    setIsMobileDevice(window.innerWidth < 768);
  };

  checkMobile();
  window.addEventListener('resize', checkMobile);
  return () => window.removeEventListener('resize', checkMobile);
}, []);

  if (isLoading) {
    return (
      <div className="relative w-full h-full overflow-hidden flex flex-col items-center justify-center bg-black/20">
        <div className="absolute mt-10 mb-10 inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-indigo-900/20 via-gray-900/10 to-transparent" />
        
        <motion.div
          layoutId={CENTRAL_SUN_LAYOUT_ID}
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ 
            scale: 1,
            opacity: 1,
          }}
          className="w-16 h-16 rounded-full bg-gradient-to-br from-yellow-300 via-orange-400 to-red-500
          shadow-[0_0_50px_rgba(251,146,60,0.3)]"
        >
          <motion.div 
            className="w-full h-full rounded-full bg-gradient-to-br from-yellow-200 to-orange-400 opacity-80"
            animate={{ 
              scale: [1, 1.1, 1],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />
        </motion.div>

        <div className="mt-8 text-center">
          <div className="text-gray-200 font-medium mb-2">Loading Market Pulse</div>
          <div className="h-6 overflow-hidden">
            <AnimatePresence mode="popLayout">
              <motion.div
                key={loadingText}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                className="text-gray-400 text-sm"
              >
                {loadingText}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    );
  }

  const headlinePrompts = promptItems.filter(item => item.isHeadline);
  const regularPrompts = promptItems.filter(item => !item.isHeadline);

  return (
    <div className={`w-full h-full flex flex-col ${
      isDarkMode 
        ? 'bg-gradient-to-br from-gray-900 via-gray-800 to-black' 
        : 'bg-gradient-to-br from-gray-50 via-gray-100 to-white'
      } overflow-auto`} id="welcome-container">
      <div className={`absolute inset-0 ${
        isDarkMode
          ? 'bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-900/15 via-gray-900/10 to-transparent' 
          : 'bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-400/10 via-gray-100/10 to-transparent'
        } animate-pulse-slow`} />
      <div className={`absolute inset-0 ${
        isDarkMode 
          ? 'bg-grid-dark opacity-10' 
          : 'bg-grid-light opacity-20'
        }`} />
      
      <div className="max-w-7xl w-full px-6 py-12 mx-auto flex-grow flex flex-col">
        <div className="flex flex-col items-center mt-[-2vh] justify-center pt-4 mb-4 px-4">
          <Box size={isMobileDevice ? 28 : 36} className={`${isDarkMode ? 'text-white' : 'text-gray-800'} mr-3 mb-2 md:mb-0 md:mr-3`} />
          <h1 className={`text-xl md:text-3xl lg:text-4xl font-heading text-center ${
            isDarkMode ? 'text-white' : 'text-gray-800'
          }`}>
            What do you want to know about the markets?
          </h1>
        </div>
        
        <p className={`text-center mb-6 md:mb-8 max-w-xl mx-auto text-sm px-4 ${
          isDarkMode ? 'text-gray-300' : 'text-gray-600'
        }`}>
          Ask anything and watch as Prism conducts real-time market research with live financial data.
        </p>

        <div className="relative mb-6 md:mb-8 px-3 md:px-0 transform hover:scale-[1.01] transition-transform duration-300">
          <form onSubmit={(e) => {
              e.preventDefault();
              
              let prompt = '';
              let modePrefix = selectedResearchMode.prefix;
              let displayAsChip = false;
              
              if (selectedPromptChip) {
                prompt = selectedPromptChip.prefix + selectedPromptChip.text + 
                          (searchQuery.trim() ? ` ${searchQuery.trim()}` : '');
                modePrefix = selectedPromptChip.prefix;
                displayAsChip = true;
              } else if (selectedStockChip) {
                prompt = selectedStockChip.prefix + 
                          `Tell me about ${selectedStockChip.ticker} stock` + 
                          (searchQuery.trim() ? `: ${searchQuery.trim()}` : '');
                modePrefix = selectedStockChip.prefix;
                displayAsChip = true;
              } else if (searchQuery.trim()) {
                prompt = selectedResearchMode.prefix + searchQuery.trim();
              } else if (selectedFiles.length > 0) {
                prompt = selectedResearchMode.prefix + "Analyze these files";
              }
              
              if (prompt) {
                console.log("Submitting prompt from WelcomeStatePreview:", prompt);
                onPromptSelect(prompt, modePrefix, displayAsChip);
                
                setSelectedPromptChip(null);
                setSelectedStockChip(null);
                setSearchQuery('');
                setSelectedFiles([]);
              }
          }} className="flex flex-col md:flex-row w-full">
            {isMobileDevice && (
              <div className="flex-1 relative mb-2">
                <div className={`flex items-center flex-wrap w-full py-2 px-3 rounded-xl border border-gray-700/40 bg-gray-900/80 text-white
                  focus-within:ring-2 focus-within:ring-blue-500/70 focus-within:border-blue-500/70 min-h-[60px]
                  ${(selectedPromptChip || selectedStockChip || selectedFiles.length > 0) ? 'pl-2' : 'pl-3'} backdrop-blur-sm shadow-[0_0_15px_rgba(59,130,246,0.15)]`}>
                  
                  {selectedPromptChip && (
                    <div className="py-1 pr-2">
                      <PromptChip 
                        text={selectedPromptChip.text}
                        prefix={selectedPromptChip.prefix}
                        onRemove={() => setSelectedPromptChip(null)}
                        isDarkMode={isDarkMode}
                      />
                    </div>
                  )}
                  
                  {selectedStockChip && (
                    <div className="py-1 pr-2">
                      <StockChip
                        ticker={selectedStockChip.ticker}
                        companyName={selectedStockChip.companyName}
                        prefix={selectedStockChip.prefix}
                        onRemove={() => setSelectedStockChip(null)}
                        isDarkMode={isDarkMode}
                      />
                    </div>
                  )}
                  
                  {selectedFiles.map((file, index) => (
                    <div key={`file-${index}`} className="py-1 pr-2">
                      <FileChip
                        file={file}
                        onRemove={() => removeFile(index)}
                        isDarkMode={isDarkMode}
                      />
                    </div>
                  ))}
                  
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder={selectedPromptChip || selectedStockChip || selectedFiles.length > 0 ? 
                      "Add details or press Enter..." : 
                      "Ask anything about markets..."}
                    className="flex-1 bg-transparent border-none focus:ring-0 text-white placeholder-gray-500 text-base py-2"
                  />
                  
                  <div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center gap-2">
                    <button
                      type="button"
                      onClick={handleFileButtonClick}
                      className={`p-2 rounded-lg transition-colors ${
                        isDarkMode 
                          ? 'hover:bg-gray-700 text-gray-400' 
                          : 'hover:bg-gray-200 text-gray-500'
                      }`}
                    >
                      <Paperclip className="w-4 h-4" />
                    </button>

                    <motion.button
                      type="submit"
                      disabled={!searchQuery.trim() && !selectedPromptChip && !selectedStockChip && selectedFiles.length === 0}
                      className={`p-2 rounded-lg transition-colors ${
                        !searchQuery.trim() && !selectedPromptChip && !selectedStockChip && selectedFiles.length === 0
                          ? 'bg-gray-700 text-gray-400'
                          : 'bg-blue-500 hover:bg-blue-600 text-white shadow-md shadow-blue-500/20'
                      }`}
                      whileHover={{ 
                        scale: 1.05, 
                        boxShadow: "0 5px 15px rgba(59,130,246,0.3)" 
                      }}
                      whileTap={{ 
                        scale: 0.97, 
                        boxShadow: "0 2px 5px rgba(59,130,246,0.2)" 
                      }}
                    >
                      <Send className="w-4 h-4" />
                    </motion.button>
                  </div>
                </div>
              </div>
            )}

            {isMobileDevice && (
              <div className="flex w-full gap-2">
                <div className="relative z-10 research-mode-selector flex-1" style={{ position: "relative", zIndex: 40 }}>
                  <button
                    onClick={() => setIsResearchModalOpen(true)}
                    className={`flex items-center justify-center space-x-2 px-3 py-3 rounded-lg w-full transition-all duration-300 ${
                      isDarkMode
                        ? 'bg-gray-800/80 hover:bg-gray-750 text-gray-300 border border-gray-700/80' 
                        : 'bg-gray-50 hover:bg-gray-100 text-gray-700 border border-gray-200/80'
                    } whitespace-nowrap backdrop-blur-sm shadow-sm hover:shadow-md transform hover:-translate-y-[1px] active:translate-y-[1px]`}
                  >
                    <div className={`p-1 rounded-md ${
                      isDarkMode ? 'bg-blue-500/10' : 'bg-blue-500/10'
                    }`}>
                      <selectedResearchMode.icon className="w-4 h-4 text-blue-500" />
                    </div>
                    <span className="font-medium text-sm">
                      {selectedResearchMode.name}
                    </span>
                  </button>
                </div>

                <div className="relative flex-shrink-0" style={{ position: "relative", zIndex: 40 }}>
                  <button
                    onClick={() => setIsWatchlistModalOpen(true)}
                    className={`flex items-center justify-center px-4 py-3 rounded-lg border transition-all duration-200 ${
                      isDarkMode
                        ? 'bg-gray-800/80 hover:bg-gray-750 text-gray-300 border-gray-700/80' 
                        : 'bg-gray-50 hover:bg-gray-100 text-gray-700 border-gray-200/80'
                    } whitespace-nowrap backdrop-blur-sm hover:shadow-md transform hover:-translate-y-[1px] active:translate-y-[1px]`}
                    title="Add stock from watchlist"
                  >
                    <div className={`p-1 rounded-md ${
                      isDarkMode ? 'bg-green-500/10' : 'bg-green-500/10'
                    }`}>
                      <Plus className="w-4 h-4 text-green-500" />
                    </div>
                  </button>
                </div>
              </div>
            )}

            {!isMobileDevice && (
              <>
                <div className="relative z-10 research-mode-selector" style={{ position: "relative", zIndex: 40 }}>
                  <button
                    onClick={() => setIsResearchModalOpen(true)}
                    className={`flex items-center space-x-2 px-3 py-4 rounded-l-xl transition-all duration-300 ${
                      isDarkMode
                        ? 'bg-gray-800/80 hover:bg-gray-750 text-gray-300 border-y border-l border-gray-700/80' 
                        : 'bg-gray-50 hover:bg-gray-100 text-gray-700 border-y border-l border-gray-200/80'
                    } whitespace-nowrap backdrop-blur-sm shadow-sm hover:shadow-md transform hover:-translate-y-[1px] active:translate-y-[1px]`}
                  >
                    <div className={`p-1 rounded-md ${
                      isDarkMode ? 'bg-blue-500/10' : 'bg-blue-500/10'
                    }`}>
                      <selectedResearchMode.icon className="w-4 h-4 text-blue-500" />
                    </div>
                    <span className="font-medium text-sm">
                      {selectedResearchMode.name}
                    </span>
                    <div className={`w-5 h-5 flex items-center justify-center rounded-full ml-1 ${
                      showResearchModes ? 
                        (isDarkMode ? 'bg-gray-700' : 'bg-gray-200') : 
                        'text-gray-400'
                    }`}>
                      {showResearchModes ? (
                        <ChevronUp className="w-3.5 h-3.5 text-gray-400" />
                      ) : (
                        <ChevronDown className="w-3.5 h-3.5 text-gray-400" />
                      )}
                    </div>
                  </button>
                </div>

                <div className="relative" style={{ position: "relative", zIndex: 40 }}>
                  <button
                    onClick={() => setIsWatchlistModalOpen(true)}
                    className={`watchlist-menu-button flex items-center justify-center px-3 py-4 border-y border-gray-700/80 transition-all duration-200 ${
                      isDarkMode
                        ? 'bg-gray-800/80 hover:bg-gray-750 text-gray-300' 
                        : 'bg-gray-50 hover:bg-gray-100 text-gray-700 border-y border-gray-200/80'
                    } whitespace-nowrap backdrop-blur-sm hover:shadow-md transform hover:-translate-y-[1px] active:translate-y-[1px]`}
                    title="Add stock from watchlist"
                  >
                    <div className={`p-1 rounded-md ${
                      isDarkMode ? 'bg-green-500/10' : 'bg-green-500/10'
                    }`}>
                      <Plus className="w-4 h-4 text-green-500" />
                    </div>
                  </button>
                </div>

                <div className="flex-1 relative">
                  <div className={`flex items-center flex-wrap w-full py-2 pl-5 pr-16 rounded-r-xl border border-gray-700/40 bg-gray-900/80 text-white
                    focus-within:ring-2 focus-within:ring-blue-500/70 focus-within:border-blue-500/70 min-h-[60px]
                    ${(selectedPromptChip || selectedStockChip || selectedFiles.length > 0) ? 'pl-2' : 'pl-5'} backdrop-blur-sm shadow-[0_0_15px_rgba(59,130,246,0.15)]`}>
                    
                    {selectedPromptChip && (
                      <div className="py-1 pr-2">
                        <PromptChip 
                          text={selectedPromptChip.text}
                          prefix={selectedPromptChip.prefix}
                          onRemove={() => setSelectedPromptChip(null)}
                          isDarkMode={isDarkMode}
                        />
                      </div>
                    )}
                    
                    {selectedStockChip && (
                      <div className="py-1 pr-2">
                        <StockChip
                          ticker={selectedStockChip.ticker}
                          companyName={selectedStockChip.companyName}
                          prefix={selectedStockChip.prefix}
                          onRemove={() => setSelectedStockChip(null)}
                          isDarkMode={isDarkMode}
                        />
                      </div>
                    )}
                    
                    {selectedFiles.map((file, index) => (
                      <div key={`file-${index}`} className="py-1 pr-2">
                        <FileChip
                          file={file}
                          onRemove={() => removeFile(index)}
                          isDarkMode={isDarkMode}
                        />
                      </div>
                    ))}
                    
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder={selectedPromptChip || selectedStockChip || selectedFiles.length > 0 ? 
                          "Add details or press Enter..." : 
                          "Ask anything about markets and investing..."}
                        className="flex-1 bg-transparent border-none focus:ring-0 text-white placeholder-gray-500 text-base py-2"
                      />
                  </div>
                  
                  <div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center gap-2">
                    <button
                      type="button"
                      onClick={handleFileButtonClick}
                      className={`p-2 rounded-lg transition-colors ${
                        isDarkMode 
                          ? 'hover:bg-gray-700 text-gray-400' 
                          : 'hover:bg-gray-200 text-gray-500'
                      }`}
                    >
                      <Paperclip className="w-4 h-4" />
                    </button>

                    <motion.button
                      type="submit"
                      disabled={!searchQuery.trim() && !selectedPromptChip && !selectedStockChip && selectedFiles.length === 0}
                      className={`p-2 rounded-lg transition-colors ${
                        !searchQuery.trim() && !selectedPromptChip && !selectedStockChip && selectedFiles.length === 0
                          ? 'bg-gray-700 text-gray-400'
                          : 'bg-blue-500 hover:bg-blue-600 text-white shadow-md shadow-blue-500/20'
                      }`}
                      whileHover={{ 
                        scale: 1.05, 
                        boxShadow: "0 5px 15px rgba(59,130,246,0.3)" 
                      }}
                      whileTap={{ 
                        scale: 0.97, 
                        boxShadow: "0 2px 5px rgba(59,130,246,0.2)" 
                      }}
                      animate={{
                        boxShadow: searchQuery.trim() || selectedFiles.length > 0 || selectedPromptChip || selectedStockChip ?
                          ["0 0 0 rgba(59,130,246,0.5)", "0 0 15px rgba(59,130,246,0.5)", "0 0 0 rgba(59,130,246,0.5)"] :
                          "0 0 0 rgba(59,130,246,0.5)"
                      }}
                      transition={{ 
                        boxShadow: { duration: 2, repeat: Infinity },
                        scale: { type: "spring", stiffness: 400, damping: 10 }
                      }}
                    >
                      <Send className="w-4 h-4" />
                    </motion.button>
                  </div>
                </div>
              </>
            )}

            <input
              ref={fileInputRef}
              type="file"
              onChange={handleFileSelect}
              className="hidden"
              accept=".pdf,.doc,.docx,.txt,.csv"
              multiple
            />
          </form>
        </div>

        <div className="mb-8">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-sm font-medium text-blue-400 uppercase tracking-wider flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
               stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2">
          <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
          <polyline points="17 6 23 6 23 12"></polyline>
              </svg>
              Trending Topics
            </h3>
            <p className="text-gray-400 text-xs">
              Click any topic below to get AI-powered market insights
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
         
          {headlinePrompts.slice(0, 6).map((item, index) => (
  <motion.button
    key={index}
    onClick={(e) => {
      if (e.target === e.currentTarget || !(e.target as Element).closest('.quick-actions')) {
        setSelectedPromptChip({
          text: item.prompt,
          prefix: selectedResearchMode.prefix
        });
        setSearchQuery('');
      }
    }}
    className="group relative h-32 overflow-hidden rounded-xl border border-gray-700/50 shadow-lg"
    whileHover={{ 
      scale: 1.03, 
      boxShadow: "0 10px 25px -5px rgba(0, 0, 0, 0.2), 0 8px 10px -6px rgba(0, 0, 0, 0.1)",
      borderColor: "rgba(59, 130, 246, 0.5)"
    }}
    whileTap={{ scale: 0.98 }}
    transition={{ 
      type: "spring",
      stiffness: 400,
      damping: 15
    }}
  >
    {item.imageUrl ? (
      <div 
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${item.imageUrl})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-t from-black via-black/60 to-transparent" />
      </div>
    ) : (
      <div className="absolute inset-0 bg-gradient-to-br from-blue-900/40 to-gray-900">
        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent" />
      </div>
    )}
    
    {item.ticker && (
      <div className="absolute top-2 right-2 px-2 py-0.5 bg-black/80 rounded-md backdrop-blur-sm z-[20] flex items-center">
        <span className="text-xs font-bold text-white">{item.ticker}</span>
        {item.tickerPriceLoaded && (
          <span className={`ml-1 text-xs ${(item.tickerChangePercent ?? 0) >= 0 ? 'text-green-300' : 'text-red-300'}`}>
            {(item.tickerChangePercent ?? 0) >= 0 ? '▲' : '▼'}
            {Math.abs(item.tickerChangePercent ?? 0).toFixed(1)}%
          </span>
        )}
      </div>
    )}
    
    <div className="absolute z-50 top-0 left-0 right-0 flex justify-center opacity-0 group-hover:opacity-100 transition-opacity transform translate-y-2 group-hover:translate-y-3 duration-300 quick-actions">
      <div className="flex space-x-1 p-1 backdrop-blur-md bg-black/40 rounded-full shadow-lg">
        {item.ticker && (
          <motion.button
            onClick={(e) => {
              e.stopPropagation();
              openStockModal(item.ticker!);
            }}
            className="p-1.5 bg-gray-800/90 hover:bg-gray-700 rounded-full transition-colors"
            title="View Chart"
            whileHover={{ scale: 1.15 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 500, damping: 15 }}
          >
            <LineChart className="w-3 h-3 text-gray-300" />
          </motion.button>
        )}
        
        <motion.button
          onClick={(e) => {
            e.stopPropagation();
            setSelectedPromptChip({
              text: item.prompt,
              prefix: selectedResearchMode.prefix
            });
            setSearchQuery('');
          }}
          className="p-1.5 bg-gray-800/90 hover:bg-gray-700 rounded-full transition-colors"
          title="Add to Chat"
          whileHover={{ scale: 1.15 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", stiffness: 500, damping: 15 }}
        >
          <Box className="w-3 h-3 text-gray-300" />
        </motion.button>

        {item.ticker && props.onSaveStock && (
          <motion.button
            onClick={(e) => {
              e.stopPropagation();
              if (props.onSaveStock) {
                props.onSaveStock({
                  ticker: item.ticker || "",
                  name: item.prompt
                });
              }
            }}
            className="p-1.5 bg-gray-800/90 hover:bg-gray-700 rounded-full transition-colors"
            title="Save to Watchlist"
            whileHover={{ scale: 1.15 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 500, damping: 15 }}
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="12" 
              height="12" 
              viewBox="0 0 24 24" 
              fill="none"
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
              className="text-gray-300"
            >
              <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
            </svg>
          </motion.button>
        )}
      </div>
    </div>

    <div className="relative h-full flex flex-col justify-end p-3">
      <div className="flex items-center mb-0.25">
        <div className="w-2 h-2 bg-blue-500 rounded-full mr-2"></div>
        <span className="text-xs text-blue-300 font-medium">Trending</span>
      </div>
      <p className="text-white text-left text-base font-medium line-clamp-2 group-hover:text-blue-200 transition-colors">
        {item.prompt}
      </p>
    </div>
  </motion.button>
))}
          </div>
        </div>

        <div className="mb-8">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-sm font-medium text-green-400 uppercase tracking-wider flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
               stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2">
            <path d="M12 20V10"></path>
            <path d="M18 20V4"></path>
            <path d="M6 20v-4"></path>
          </svg>
          Trending Stocks
        </h3>
        <p className="text-gray-400 text-xs">
          Market movers catching attention today
        </p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        {trendingStocks.map((stock, index) => (
          <motion.button
            key={`stock-${index}`}
            onClick={() => {
              setSelectedStockChip({
                ticker: stock.ticker,
                companyName: stock.name,
                prefix: selectedResearchMode.prefix
              });
              setSearchQuery('');
            }}
            className="group relative h-28 overflow-hidden rounded-xl border border-gray-700/50 shadow-lg"
            whileHover={{ 
              scale: 1.03, 
              boxShadow: "0 10px 25px -5px rgba(0, 0, 0, 0.2), 0 8px 10px -6px rgba(0, 0, 0, 0.1)",
              borderColor: "rgba(46, 204, 113, 0.5)"
            }}
            whileTap={{ scale: 0.98 }}
            transition={{ 
              type: "spring",
              stiffness: 400,
              damping: 15
            }}
          >
        <div className="absolute inset-0 bg-gradient-to-br from-green-900/30 to-gray-900">
          <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent" />
        </div>
        
        <div className="absolute top-2 right-2 px-2 py-0.5 bg-black/80 rounded-md backdrop-blur-sm z-99 flex items-center">
          <span className="text-xs font-bold text-white">{stock.ticker}</span>
          {stock.priceLoaded && (
            <span className={`ml-1 text-xs ${(stock.changePercent ?? 0) >= 0 ? 'text-green-300' : 'text-red-300'}`}>
              {(stock.changePercent ?? 0) >= 0 ? '▲' : '▼'}
              {Math.abs(stock.changePercent ?? 0).toFixed(1)}%
            </span>
          )}
        </div>
        
        <div className="absolute left-0 right-0 top-1/2 -translate-y-1/2 h-12 px-3 opacity-30">
          <div className="w-full h-full">
<div className="absolute left-0 right-0 top-1/2 -translate-y-1/2 h-12 px-3 opacity-60">
  <StockSparkline
      ticker={stock.ticker}
      timeframe="1d"
      height={24}
      width={containerSize.width * 0.25}
    />
</div>
          </div>
        </div>
        
        <div className="absolute bottom-0 left-0 right-0 p-3">
  <div className="flex items-center justify-between mb-1">
    <span className="text-xs text-gray-300 font-medium">{stock.name}</span>
    {stock.priceLoaded && (
      <span className="text-xs text-white font-mono">${stock.price?.toFixed(2)}</span>
    )}
  </div>
  
  <p className="text-white text-left text-sm font-medium line-clamp-2 group-hover:text-green-200 transition-colors">
    {stock.reason}
  </p>
</div>
<div className="absolute top-0 left-0 right-0 flex justify-center opacity-0 group-hover:opacity-100 transition-opacity transform translate-y-2 group-hover:translate-y-3 duration-300">
  <div className="flex space-x-1 p-1 backdrop-blur-md bg-black/40 rounded-full shadow-lg">
    <motion.button
      onClick={(e) => {
        e.stopPropagation();
        openStockModal(stock.ticker);
      }}
      className="p-1.5 bg-gray-800/90 hover:bg-gray-700 rounded-full transition-colors"
      title="View Chart"
      whileHover={{ scale: 1.15 }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", stiffness: 500, damping: 15 }}
    >
      <LineChart className="w-3 h-3 text-gray-300" />
    </motion.button>
    
    <motion.button
      onClick={(e) => {
        e.stopPropagation();
        setSelectedStockChip({
          ticker: stock.ticker,
          companyName: stock.name,
          prefix: selectedResearchMode.prefix
        });
        setSearchQuery('');
      }}
      className="p-1.5 bg-gray-800/90 hover:bg-gray-700 rounded-full transition-colors"
      title="Add to Chat"
      whileHover={{ scale: 1.15 }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", stiffness: 500, damping: 15 }}
    >
      <Box className="w-3 h-3 text-gray-300" />
    </motion.button>
    
    <motion.button
      onClick={(e) => handleSaveStock(e, stock)}
      className="p-1.5 bg-gray-800/90 hover:bg-gray-700 rounded-full transition-colors"
      title="Save to Watchlist"
      whileHover={{ scale: 1.15 }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", stiffness: 500, damping: 15 }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" 
          stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" 
          className="text-gray-300">
        <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
      </svg>
    </motion.button>
  </div>
</div>
      </motion.button>
    ))}
  </div>
</div>

        <div className="mb-8">
          
          
          <div className="bg-gray-800/40 backdrop-blur-sm border border-gray-700/50 rounded-xl p-4">
            <div className="flex flex-wrap gap-2">
                                                   {regularPrompts.slice(0, 48).map((item, index) => (
                            <motion.div
                              key={`item-${index}`}
                              className="relative group flex items-center"
                              layout
                              transition={{ type: "spring", stiffness: 500, damping: 30 }}
                            >
                              <motion.button
                                onClick={() => {
                                  setSelectedPromptChip({
                                    text: item.prompt, 
                                    prefix: selectedResearchMode.prefix
                                  });
                                  setSearchQuery('');
                                }}
                                className="px-3 py-2 bg-gray-700/50 hover:bg-blue-600/30 border border-gray-600/50 hover:border-blue-500/50 rounded-lg text-sm text-gray-300 hover:text-blue-300 transition-all duration-200 flex items-center"
                                whileHover={{ 
                                  scale: 1.02,
                                  boxShadow: "0 4px 10px -2px rgba(59, 130, 246, 0.15)"
                                }}
                                layout
                              >
                                <span>{item.prompt}</span>
                                {item.ticker && (
                                  <div className="ml-2 px-2 py-0.5 bg-gray-900/80 rounded-md backdrop-blur-sm z-10 flex items-center">
                                    <span className="text-xs font-bold text-white">{item.ticker}</span>
                                    {item.tickerPriceLoaded && (
                                      <span className={`ml-1 text-xs ${(item.tickerChangePercent ?? 0) >= 0 ? 'text-green-300' : 'text-red-300'}`}>
                                        {(item.tickerChangePercent ?? 0) >= 0 ? '▲' : '▼'}
                                        {Math.abs(item.tickerChangePercent ?? 0).toFixed(1)}%
                                      </span>
                                    )}
                                  </div>
                                )}
                              </motion.button>
                          
                              <motion.div
                                initial="hidden"
                                whileHover="visible"
                                animate="hidden"
                                variants={{
                                  hidden: {
                                    width: 0,
                                    opacity: 0,
                                    x: -20,
                                    transition: {
                                      duration: 0.2,
                                      ease: "easeInOut"
                                    }
                                  },
                                  visible: {
                                    width: "auto",
                                    opacity: 1,
                                    x: 8,
                                    transition: {
                                      type: "spring",
                                      stiffness: 500,
                                      damping: 25,
                                      mass: 0.8
                                    }
                                  }
                                }}
                                className="absolute left-full top-1/2 -translate-y-1/2 flex items-center pl-2 overflow-hidden"
                              >
                                <motion.div 
                                  className="flex items-center gap-1.5 bg-gray-800/90 backdrop-blur-sm rounded-full p-1.5 border border-gray-700/50 shadow-lg"
                                  variants={{
                                    hidden: { scale: 0.8, opacity: 0 },
                                    visible: { 
                                      scale: 1, 
                                      opacity: 1,
                                      transition: {
                                        type: "spring",
                                        stiffness: 500,
                                        damping: 25,
                                        mass: 0.8,
                                        staggerChildren: 0.05,
                                        delayChildren: 0.1
                                      }
                                    }
                                  }}
                                >
                                  {item.ticker && (
                                    <motion.button
                                      variants={{
                                        hidden: { scale: 0, opacity: 0 },
                                        visible: { scale: 1, opacity: 1 }
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        openStockModal(item.ticker!);
                                      }}
                                      className="p-1.5 hover:bg-gray-700/80 rounded-full transition-colors"
                                      whileHover={{ scale: 1.15 }}
                                      whileTap={{ scale: 0.9 }}
                                    >
                                      <LineChart className="w-3.5 h-3.5 text-gray-300" />
                                    </motion.button>
                                  )}
                          
                                  <motion.button
                                    variants={{
                                      hidden: { scale: 0, opacity: 0 },
                                      visible: { scale: 1, opacity: 1 }
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelectedPromptChip({
                                        text: item.prompt,
                                        prefix: selectedResearchMode.prefix
                                      });
                                    }}
                                    className="p-1.5 hover:bg-gray-700/80 rounded-full transition-colors"
                                    whileHover={{ scale: 1.15 }}
                                    whileTap={{ scale: 0.9 }}
                                  >
                                    <Box className="w-3.5 h-3.5 text-gray-300" />
                                  </motion.button>
                          
                                  {item.ticker && props.onSaveStock && (
                                    <motion.button
                                      variants={{
                                        hidden: { scale: 0, opacity: 0 },
                                        visible: { scale: 1, opacity: 1 }
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (props.onSaveStock) {
                                          props.onSaveStock({
                                            ticker: item.ticker || "",
                                            name: item.prompt
                                          });
                                        }
                                      }}
                                      className="p-1.5 hover:bg-gray-700/80 rounded-full transition-colors"
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" 
                                           fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" 
                                           strokeLinejoin="round" className="text-gray-300">
                                        <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                                      </svg>
                                    </motion.button>
                                  )}
                                </motion.div>
                              </motion.div>
                            </motion.div>
                          ))}
            </div>
          </div>
        </div>

        <motion.div 
          className="mt-8 text-center text-gray-400 text-xs"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.7 }}
          transition={{ delay: 0.5 }}
        >
          Try asking about specific companies, market sectors, economic indicators, or trading strategies
        </motion.div>
        <ResearchModeModal
  isOpen={isResearchModalOpen}
  onClose={() => setIsResearchModalOpen(false)}
  onSelect={(mode) => {
    handleResearchModeChange(mode);
    setIsResearchModalOpen(false);
  }}
  selectedMode={selectedResearchMode}
  isDarkMode={isDarkMode}
/>
      </div>
      
      <AnimatePresence>
  {isWatchlistModalOpen && (
    <WatchlistSelectModal
      isOpen={isWatchlistModalOpen}
      onClose={() => setIsWatchlistModalOpen(false)}
      onSelectStock={(ticker, companyName, prefix) => {
        setSelectedStockChip({
          ticker,
          companyName,
          prefix: selectedResearchMode.prefix
        });
        setSearchQuery('');
      }}
      selectedResearchModePrefix={selectedResearchMode.prefix}
    />
  )}
</AnimatePresence>
    </div>
  );
};

export type WelcomeStatePreviewRef = {
  refreshPrompts: () => Promise<void>;
  addPromptChip: (text: string, prefix: string) => void;
};

export default forwardRef<WelcomeStatePreviewRef, WelcomeStateProps>(WelcomeStatePreview);