import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Shield,
  Lock,
  Key,
  Smartphone,
  Eye,
  AlertTriangle,
  UserCheck,
  RefreshCw,
  ChevronRight,
  Check,
  Info,
  LogOut,
  Clock
} from 'lucide-react';

const AccountProtection = () => {
  const { isDarkMode } = useTheme();

  const securityFeatures = [
    {
      title: 'Multi-Factor Authentication',
      description: 'Add an extra layer of security beyond your password',
      icon: Smartphone,
      features: [
        'SMS verification codes',
        'Authenticator app support (Google, Microsoft, Authy)',
        'Hardware security key compatibility (FIDO2, YubiKey)',
        'Biometric options on supported devices',
        'Backup recovery codes for emergency access'
      ],
      recommendedFor: 'All users, especially those with financial data'
    },
    {
      title: 'Strong Password Practices',
      description: 'Create and manage secure, unique passwords',
      icon: Key,
      features: [
        'Password strength requirements (12+ characters)',
        'Password manager compatibility',
        'Regular password change reminders',
        'No password reuse across sites',
        'Secure password reset process'
      ],
      recommendedFor: 'Everyone - the foundation of account security'
    },
    {
      title: 'Session Management',
      description: 'Control and monitor active account sessions',
      icon: LogOut,
      features: [
        'View all active sessions across devices',
        'One-click log out from all devices',
        'Automatic session expiration after inactivity',
        'Location and device tracking for logins',
        'Suspicious activity alerts'
      ],
      recommendedFor: 'Users who access the platform from multiple devices'
    },
    {
      title: 'Activity Monitoring',
      description: 'Stay informed about account activity and changes',
      icon: Eye,
      features: [
        'Account change notifications (email/SMS)',
        'Login alerts for new devices/locations',
        'Detailed activity logs viewable in account',
        'Critical action verification',
        'Unusual activity detection'
      ],
      recommendedFor: 'All users, critical for financial accounts'
    }
  ];

  const securityChecklist = [
    {
      title: 'Essential Security Steps',
      recommendations: [
        'Enable multi-factor authentication',
        'Use a strong, unique password',
        'Keep your email account secure',
        'Update contact information',
        'Review active sessions regularly'
      ]
    },
    {
      title: 'Regular Maintenance',
      recommendations: [
        'Change password every 90 days',
        'Review third-party app connections',
        'Check login activity monthly',
        'Update recovery options',
        'Verify notification settings'
      ]
    },
    {
      title: 'Advanced Protection',
      recommendations: [
        'Use a dedicated email address',
        'Consider a hardware security key',
        'Enable login notifications',
        'Separate personal and financial accounts',
        'Review security settings quarterly'
      ]
    }
  ];

  const commonThreats = [
    {
      title: 'Phishing Attacks',
      description: 'Attempts to trick you into revealing login credentials',
      signs: [
        'Emails claiming account issues requiring immediate action',
        'Messages with suspicious links or attachments',
        'Communications with poor grammar or spelling',
        'Requests for personal information or passwords',
        'Emails from slightly misspelled domains'
      ],
      prevention: 'Never click suspicious links. Always access Prism directly through your bookmarks or by typing the URL.'
    },
    {
      title: 'Credential Theft',
      description: 'Stealing or guessing your account passwords',
      signs: [
        'Unexpected account lockouts',
        "Login notifications you don't recognize",
        "Account changes you didn't make",
        "Password reset emails you didn't request",
        'Unusual activity in your account history'
      ],
      prevention: 'Use a unique, complex password and enable two-factor authentication for your Prism account.'
    },
    {
      title: 'Account Takeover',
      description: 'Unauthorized access and control of your account',
      signs: [
        'Changed account settings',
        'New contact information added',
        'Missing or altered content',
        'Unusual transactions or activity',
        'Unable to access your account'
      ],
      prevention: 'Regularly monitor account activity and immediately report any suspicious events to our support team.'
    }
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Shield className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Account Protection
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Best practices and tools to keep your Prism account secure
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              Securing your Prism account is essential to protect your financial data and research. While we implement 
              robust security measures on our end, many security breaches occur through individual accounts. This guide outlines 
              the security features available to you and provides best practices to protect your account from unauthorized access.
            </p>
          </div>

          {/* Security Features */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Account Security Features
          </h2>

          <div className="space-y-12 mb-16">
            {securityFeatures.map((feature, index) => {
              const Icon = feature.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    } mr-4`}>
                      <Icon className={`w-6 h-6 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <div>
                      <h3 className={`text-xl font-bold ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {feature.title}
                      </h3>
                    </div>
                  </div>
                  
                  <p className={`mb-6 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {feature.description}
                  </p>
                  
                  <div className="grid md:grid-cols-2 gap-8">
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Features:</h4>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {feature.features.map((item, i) => (
                          <li key={i} className="flex items-start">
                            <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                            <span>{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div className={`rounded-lg p-4 ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                    }`}>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Recommended For:</h4>
                      <p className={`${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {feature.recommendedFor}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Security Checklist */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Security Checklist
          </h2>

          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="grid md:grid-cols-3 gap-6">
              {securityChecklist.map((section, index) => (
                <div key={index}>
                  <h3 className={`text-lg font-medium mb-4 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {section.title}
                  </h3>
                  <ul className={`space-y-2 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {section.recommendations.map((rec, i) => (
                      <li key={i} className="flex items-start">
                        <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                        <span>{rec}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          {/* Common Threats */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Recognizing Common Threats
          </h2>

          <div className="space-y-8 mb-16">
            {commonThreats.map((threat, index) => (
              <div key={index} className={`p-6 rounded-xl border ${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
              }`}>
                <div className="flex items-center mb-4">
                  <div className={`p-2 rounded-lg ${
                    isDarkMode ? 'bg-red-500/20' : 'bg-red-100'
                  } mr-4`}>
                    <AlertTriangle className={`w-6 h-6 ${
                      isDarkMode ? 'text-red-400' : 'text-red-600'
                    }`} />
                  </div>
                  <h3 className={`text-xl font-bold ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {threat.title}
                  </h3>
                </div>
                
                <p className={`mb-6 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {threat.description}
                </p>
                
                <div className="grid md:grid-cols-2 gap-8">
                  <div>
                    <h4 className={`text-sm font-medium mb-3 ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>Warning Signs:</h4>
                    <ul className={`space-y-2 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-600'
                    }`}>
                      {threat.signs.map((sign, i) => (
                        <li key={i} className="flex items-start">
                          <div className="w-1.5 h-1.5 rounded-full bg-red-500 mt-1.5 mr-2 flex-shrink-0" />
                          <span>{sign}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  
                  <div className={`rounded-lg p-4 ${
                    isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                  }`}>
                    <h4 className={`text-sm font-medium mb-3 ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>Prevention:</h4>
                    <p className={`${
                      isDarkMode ? 'text-gray-300' : 'text-gray-600'
                    }`}>
                      {threat.prevention}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Security Alerts */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Setting Up Security Alerts
          </h2>

          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="flex items-start mb-6">
              <Clock className={`w-6 h-6 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Configuring Real-time Alerts</h3>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  Set up real-time security alerts to be notified of important account activities:
                </p>
                <ul className={`space-y-2 mb-4 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Login notifications for new devices or locations</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Profile or security setting changes</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Password reset requests</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>API key generation or usage</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Data exports or significant downloads</span>
                  </li>
                </ul>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  To configure your alert preferences, navigate to Settings → Security → Alert Preferences.
                </p>
              </div>
            </div>
          </div>

          {/* Emergency Response */}
          <div className={`p-6 rounded-xl border ${
            isDarkMode ? 'bg-amber-900/20 border-amber-800/30' : 'bg-amber-50 border-amber-100'
          } mb-12`}>
            <div className="flex items-start">
              <AlertTriangle className={`w-6 h-6 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-amber-400' : 'text-amber-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>If You Suspect Account Compromise</h3>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  If you believe your account has been compromised, take these immediate steps:
                </p>
                <ol className={`space-y-2 list-decimal pl-5 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li>Change your password immediately</li>
                  <li>Log out of all sessions from the security settings</li>
                  <li>Check for any unauthorized changes to your account</li>
                  <li>Enable MFA if not already activated</li>
                  <li>Contact support at security@newsroom-platform.com</li>
                </ol>
              </div>
            </div>
          </div>

          {/* Related Documentation */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Related Documentation
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <a 
              href="/docs/security"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Security Overview
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Learn about our platform-wide security measures
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
            
            <a 
              href="/docs/privacy"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Privacy & Data Protection
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Understanding how we protect your personal data
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default AccountProtection;