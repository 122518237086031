// client/src/App.tsx
import React, { useEffect } from 'react';
import { AppRouter } from './router/AppRouter';
import { AuthProvider } from './contexts/AuthContext';
import { HelmetProvider } from 'react-helmet-async';
import { WalkthroughProvider } from './contexts/WalkthroughContext';
import ThemeProvider from './contexts/ThemeContext';
import { ChatProvider } from './contexts/ChatContext';
import { CanvasProvider } from './contexts/CanvasContext';
import { ErrorBoundary } from './components/common/ErrorBoundary';
import { StockViewProvider } from './contexts/StockViewContext';
import { SubscriptionProvider } from './contexts/SubscriptionProvider';
import { LanguageProvider } from './contexts/LanguageContext';
import { BrowserRouter } from 'react-router-dom';
import { ScrollToTop } from './components/common/ScrollToTop';
import { PromptProvider } from './contexts/PromptContext';
import { ChatLimitProvider } from './contexts/ChatLimitContext';
if (typeof window !== 'undefined') {
  const OriginalResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class extends OriginalResizeObserver {
    constructor(callback: ResizeObserverCallback) {
      super((entries, observer) => {
        try {
          callback(entries, observer);
        } catch {
          // swallow errors
        }
      });
    }
  };

  // Add error overlay removal
  if (process.env.NODE_ENV === 'development') {
    const removeErrorOverlay = setInterval(() => {
      const rootElement = document.getElementById('root');
      const overlay = rootElement?.querySelector('iframe');
      if (overlay && overlay.contentDocument?.body?.textContent?.includes('ResizeObserver')) {
        overlay.remove();
      }
    }, 100);
  }
}

function App() {
  const handleAnalyzePrompt = (prompt: string) => {
    // Handle the analysis prompt, e.g., send to chat or AI service
    console.log('Analyzing:', prompt);
  };
  
  useEffect(() => {
    // Create a more robust error handler
    const originalError = console.error;
    console.error = (...args) => {
      const resizeObserverErrors = [
        'ResizeObserver loop completed with undelivered notifications.',
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed'
      ];
      
      if (!resizeObserverErrors.some(error => 
        args.some(arg => typeof arg === 'string' && arg.includes(error))
      )) {
        originalError.apply(console, args);
      }
    };

    return () => {
      console.error = originalError;
    };
  }, []);
  
  return (
    <HelmetProvider>
      <ThemeProvider>
        <BrowserRouter>
          <ScrollToTop /> 
          <LanguageProvider>
            <WalkthroughProvider>
              <AuthProvider> {/* Move AuthProvider higher in the hierarchy */}
                <ChatLimitProvider> {/* Now ChatLimitProvider can access AuthProvider */}
                  <ChatProvider>
                    <PromptProvider>
                      <SubscriptionProvider>
                        <StockViewProvider onAnalyze={handleAnalyzePrompt}>
                          <CanvasProvider>
                            <ErrorBoundary>
                              <AppRouter />
                            </ErrorBoundary>
                          </CanvasProvider>
                        </StockViewProvider>
                      </SubscriptionProvider>
                    </PromptProvider>
                  </ChatProvider>
                </ChatLimitProvider>
              </AuthProvider>
            </WalkthroughProvider>
          </LanguageProvider>
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
