import React, { useRef, useState, useEffect } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import TradeIdeasFeed, { TradeIdeasFeedRef } from './dashboard/TradeIdeasFeed';
import { ArrowLeft } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';

const TradeIdeasPage: React.FC = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const tradeIdeasRef = useRef<TradeIdeasFeedRef>(null);
  
  // Get sidebar state from location state or default to collapsed
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(() => {
    // If location state has sidebarCollapsed info, use it, otherwise default to true
    return location.state?.sidebarCollapsed ?? true;
  });

  useEffect(() => {
    // Function to check for sidebar element and determine if it's collapsed
    const checkSidebarState = () => {
      const sidebarElement = document.querySelector('[data-sidebar-state]');
      if (sidebarElement) {
        const state = sidebarElement.getAttribute('data-sidebar-state');
        setSidebarCollapsed(state === 'collapsed');
      }
    };
    
    // Check when component mounts
    checkSidebarState();
    
    // Optional: set up a mutation observer to detect sidebar changes
    const observer = new MutationObserver(checkSidebarState);
    const sidebarTarget = document.querySelector('.dashboard-layout');
    
    if (sidebarTarget) {
      observer.observe(sidebarTarget, { 
        attributes: true, 
        attributeFilter: ['data-sidebar-state'],
        subtree: true 
      });
    }
    
    // Clean up
    return () => {
      observer.disconnect();
    };
  }, []);

  const handleRefresh = () => {
    console.log('Trade ideas refreshed');
  };

  return (
    <div className={`min-h-screen w-full ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      {/* Main content - adjust width based on sidebar state */}
      <div 
        className={`w-full transition-all duration-300`}
        style={{
          // No need to adjust width - let the container fill the available space
          marginLeft: 0 // No margin needed as the parent layouts handle this
        }}
      >
        <div className={`overflow-hidden shadow-sm h-[calc(100vh-64px)] 
          transition-all duration-300 
          ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
          <TradeIdeasFeed
            ref={tradeIdeasRef}
            onRefresh={handleRefresh}
            hideHeader={false}
            isSidebarCollapsed={isSidebarCollapsed}
          />
        </div>
      </div>
    </div>
  );
};

export default TradeIdeasPage;