// Change this page to be a subscription overview

import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { motion } from 'framer-motion';
import { useSubscription } from '../../contexts/SubscriptionProvider';
import { useNavigate } from 'react-router-dom';
import { 
  Shield, 
  Calendar, 
  CheckCircle2, 
  ArrowRight,
  Zap,
  Star,
  Brain,
  User,
  MessageCircle,
  TrendingUp,
  Infinity
} from 'lucide-react';

const SubscriptionDashboard = () => {
  const { isDarkMode } = useTheme();
  const { subscription, hasActiveSubscription } = useSubscription();
  const navigate = useNavigate();

  // Update the formatDate function to accept Date objects
  const formatDate = (date: Date | string): string => {
    // Convert string to Date if needed
    const dateObj = date instanceof Date ? date : new Date(date);
    return dateObj.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="relative min-h-screen">
      {/* Background Effects */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <motion.div 
          animate={{
            opacity: [0.3, 0.15, 0.3],
            transition: { duration: 8, repeat: Number.POSITIVE_INFINITY }
          }}
          className="absolute -top-1/4 -right-1/4 w-96 h-96 bg-blue-500/10 rounded-full blur-3xl"
        />
        <motion.div 
          animate={{
            scale: [1.2, 1, 1.2],
            opacity: [0.4, 0.6, 0.4],
            transition: { 
              duration: 10,
              repeat: Number.POSITIVE_INFINITY,
              ease: "easeInOut" 
            }
          }}
          className="absolute bottom-1/4 left-1/4 w-96 h-96 bg-purple-500/10 rounded-full blur-3xl"
        />
      </div>

      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Subscription Status */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className={`p-8 rounded-2xl border backdrop-blur-xl ${
            isDarkMode 
              ? 'bg-gray-900/50 border-gray-800' 
              : 'bg-white/50 border-gray-200'
          }`}
        >
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h2 className={`text-2xl font-bold mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Subscription Status
              </h2>
              
              {hasActiveSubscription ? (
                <div className="flex items-center space-x-2">
                  <Shield className="w-8 h-8 text-blue-500" />
                  <span className={`text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent`}>
                    Pro Plan
                  </span>
                  <span className="bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400 text-sm px-2 py-1 rounded-md font-medium">
                    Active
                  </span>
                </div>
              ) : (
                <div className="flex items-center space-x-2">
                  <Shield className="w-8 h-8 text-gray-400" />
                  <span className={`text-2xl font-bold ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Free Plan
                  </span>
                </div>
              )}
              
              {subscription && (
                <div className="mt-4 space-y-2 text-sm">
                  <div className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                    <span className="font-medium">Current Period:</span> {formatDate(subscription.currentPeriodStart)} - {formatDate(subscription.currentPeriodEnd)}
                  </div>
                  {subscription.cancelAtPeriodEnd && (
                    <div className="text-yellow-500">
                      Your subscription will end on {formatDate(subscription.currentPeriodEnd)}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex items-center justify-end">
              <div>
                {hasActiveSubscription ? (
                  <button
                    onClick={() => navigate('/settings')}
                    className={`px-6 py-3 rounded-xl bg-blue-600 text-white hover:bg-blue-700 transition-colors flex items-center space-x-2`}
                  >
                    <span>Manage Subscription</span>
                    <ArrowRight className="w-4 h-4" />
                  </button>
                ) : (
                  <button
                    onClick={() => navigate('/subscribe')}
                    className={`px-6 py-3 rounded-xl bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:from-blue-700 hover:to-purple-700 transition-colors flex items-center space-x-2`}
                  >
                    <span>Upgrade to Pro</span>
                    <ArrowRight className="w-4 h-4" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </motion.div>

        {/* Pro Features */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className={`mt-12 p-8 rounded-2xl border backdrop-blur-xl ${
            isDarkMode 
              ? 'bg-gray-900/50 border-gray-800' 
              : 'bg-white/50 border-gray-200'
          }`}
        >
          <h3 className={`text-xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Pro Plan Features
          </h3>
          
          <div className="grid md:grid-cols-3 gap-6">
            <div className={`p-4 rounded-xl ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
              <Brain className="w-5 h-5 text-blue-500 mb-2" />
              <h4 className={`font-medium mb-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Advanced Research
              </h4>
              <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                Unlimited Prism conversations with in-depth analysis
              </p>
            </div>
            
            <div className={`p-4 rounded-xl ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
              <Star className="w-5 h-5 text-purple-500 mb-2" />
              <h4 className={`font-medium mb-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Premium Templates
              </h4>
              <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                Access to all premium research templates
              </p>
            </div>
            
            <div className={`p-4 rounded-xl ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
              <User className="w-5 h-5 text-green-500 mb-2" />
              <h4 className={`font-medium mb-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Expert Analysis
              </h4>
              <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                Human-quality research and insights
              </p>
            </div>
          </div>

          <div className="mt-6 p-4 rounded-xl bg-green-500/10 border border-green-500/20">
            <div className="flex items-start">
              <TrendingUp className="w-5 h-5 text-green-500 mr-3 mt-0.5" />
              <div>
                <h4 className="text-green-500 font-medium mb-1">Expert Analysis at a Fraction of the Cost</h4>
                <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  A single consultant session can cost $150-$300/hour. With a Pro subscription, get expert-level insights at a fraction of the cost. Our advanced AI can process and analyze information in seconds that would take humans hours.
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default SubscriptionDashboard;