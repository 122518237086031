// src/components/common/IdleDialog.tsx
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { RefreshCcw, Clock, PowerOff, Zap, ShieldAlert } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';

interface IdleDialogProps {
  isOpen: boolean;
  onResume: () => void;
}

const IdleDialog: React.FC<IdleDialogProps> = ({ isOpen, onResume }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed font-sans inset-0 z-[1000] flex items-center justify-center bg-black/90 backdrop-blur-md"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {/* Floating particles in background - simplified */}
          <div className="absolute inset-0 overflow-hidden">
            {[...Array(6)].map((_, i) => (
              <motion.div
                key={i}
                className="absolute rounded-full"
                initial={{ 
                  x: Math.random() * window.innerWidth, 
                  y: Math.random() * window.innerHeight,
                  opacity: 0.1 + Math.random() * 0.15,
                  scale: 0.5 + Math.random() * 0.5
                }}
                animate={{ 
                  x: Math.random() * window.innerWidth, 
                  y: Math.random() * window.innerHeight,
                  opacity: [0.1 + Math.random() * 0.15, 0.2 + Math.random() * 0.15, 0.1 + Math.random() * 0.15],
                }}
                transition={{ 
                  duration: 15 + Math.random() * 15,
                  repeat: Infinity,
                  ease: "linear"
                }}
                style={{ 
                  width: `${30 + Math.random() * 50}px`,
                  height: `${30 + Math.random() * 50}px`,
                  background: `radial-gradient(circle, ${isDarkMode ? 'rgba(59, 130, 246, 0.15)' : 'rgba(96, 165, 250, 0.15)'}, transparent)`
                }}
              />
            ))}
          </div>

          <motion.div 
            className={`w-full max-w-sm overflow-hidden rounded-2xl shadow-2xl ${
              isDarkMode 
                ? 'bg-gradient-to-br from-gray-900/90 via-gray-900 to-gray-800/90 text-white border border-gray-700/50' 
                : 'bg-white/95 text-gray-900 border border-gray-200/50'
            }`}
            initial={{ scale: 0.9, y: 20, opacity: 0 }}
            animate={{ scale: 1, y: 0, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ type: 'spring', damping: 25, stiffness: 300, delay: 0.1 }}
          >
            {/* Glowing top decoration - reduced height */}
            <div className="relative h-1 w-full overflow-hidden">
              <motion.div 
                className="absolute inset-0 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500"
                animate={{
                  backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'],
                }}
                transition={{
                  duration: 8,
                  repeat: Infinity,
                  ease: "linear"
                }}
                style={{
                  backgroundSize: '200% 200%',
                }}
              />
              <motion.div
                className="absolute bottom-0 left-0 right-0 h-px bg-white/30"
                animate={{
                  opacity: [0.2, 0.5, 0.2]
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                }}
              />
            </div>
            
            {/* Content with improved spacing - reduced padding */}
            <div className="p-8">
              {/* Icon with simplified pulsing effect */}
              <div className="relative flex items-center justify-center mb-8">
                <motion.div 
                  className="absolute rounded-full opacity-20"
                  animate={{ 
                    scale: [1, 1.2, 1],
                    opacity: [0.2, 0.4, 0.2]
                  }}
                  transition={{ 
                    duration: 3,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                  style={{ 
                    width: '120px', 
                    height: '120px', 
                    background: `radial-gradient(circle, ${isDarkMode ? '#3b82f6' : '#60a5fa'}, transparent)` 
                  }}
                />
                <div className={`relative p-5 rounded-2xl backdrop-blur-sm ${
                  isDarkMode 
                    ? 'bg-gradient-to-br from-blue-600/20 to-purple-600/10 border border-blue-500/20' 
                    : 'bg-gradient-to-br from-blue-100/90 to-blue-50/80 border border-blue-200/50'
                }`}>
                  <motion.div
                    animate={{ rotate: 360 }}
                    transition={{ duration: 60, repeat: Infinity, ease: "linear" }}
                    className="relative"
                  >
                    <Clock className={`w-10 h-10 ${
                      isDarkMode ? 'text-blue-300' : 'text-blue-600'
                    }`} />
                    <motion.div 
                      className={`absolute inset-0 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`}
                      animate={{ opacity: [0, 0.5, 0] }}
                      transition={{ duration: 2, repeat: Infinity }}
                    >
                      <ShieldAlert className="w-10 h-10" />
                    </motion.div>
                  </motion.div>
                </div>
              </div>
              
              {/* Title with reduced size */}
              <motion.h2 
                className="text-3xl font-bold text-center mb-3"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
                  System Paused
                </span>
              </motion.h2>
              
              <motion.p 
                className={`text-center mb-8 max-w-sm mx-auto text-sm ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                Live updates have been suspended due to inactivity to conserve resources and protect your system performance.
              </motion.p>
              
              {/* Buttons with reduced height */}
              <div className="flex flex-col space-y-4">
                <motion.button
                  whileHover={{ scale: 1.02, boxShadow: "0 8px 20px -5px rgba(59, 130, 246, 0.4)" }}
                  whileTap={{ scale: 0.98 }}
                  className={`group w-full py-3 px-5 rounded-xl flex items-center justify-center font-medium ${
                    isDarkMode 
                      ? 'bg-gradient-to-r from-blue-600 to-violet-600 hover:from-blue-500 hover:to-violet-500 text-white shadow-lg shadow-blue-500/30' 
                      : 'bg-gradient-to-r from-blue-500 to-violet-500 hover:from-blue-400 hover:to-violet-400 text-white shadow-lg shadow-blue-500/20'
                  } transition-all duration-200`}
                  onClick={onResume}
                >
                  <motion.div
                    animate={{ rotate: 360 }}
                    transition={{ duration: 10, repeat: Infinity, ease: "linear" }}
                    className="mr-3"
                  >
                    <Zap className="w-5 h-5 group-hover:scale-105 transition-transform" />
                  </motion.div>
                  <span className="font-semibold ">Resume Live Updates</span>
                </motion.button>
                
                <motion.button
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.98 }}
                  className={`w-full py-3 px-5 rounded-xl flex items-center justify-center ${
                    isDarkMode 
                      ? 'bg-gray-800/50 hover:bg-gray-700/80 text-gray-300 border border-gray-700/50 backdrop-blur-sm' 
                      : 'bg-gray-100/80 hover:bg-gray-200/80 text-gray-700 border border-gray-200/50'
                  } transition-all duration-200`}
                >
                  <PowerOff className="w-4 h-4 mr-2" />
                  <span className="font-medium text-sm">Remain Paused</span>
                </motion.button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default IdleDialog;