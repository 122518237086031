// src/components/market/TradeIdeaCard.tsx
import React from 'react';
import { TrendingUp, TrendingDown, MinusIcon, Brain, BarChart2, Zap, Calendar } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import { TradeIdea } from '../../types/tradeIdeas';

interface TradeIdeaCardProps {
  idea: TradeIdea;
  onAnalyze: () => void;
}

const TradeIdeaCard: React.FC<TradeIdeaCardProps> = ({ idea, onAnalyze }) => {
  const { isDarkMode } = useTheme();

  const getTypeIcon = (type: 'buy' | 'sell' | 'hold') => {
    if (type === 'buy') return <TrendingUp className="w-4 h-4 text-green-500" />;
    if (type === 'sell') return <TrendingDown className="w-4 h-4 text-red-500" />;
    return <MinusIcon className="w-4 h-4 text-yellow-500" />;
  };

  return (
    <div className={`w-full rounded-xl overflow-hidden border ${
      isDarkMode 
        ? 'bg-gray-800/80 border-gray-700 hover:border-gray-600' 
        : 'bg-white/80 border-gray-200 hover:border-gray-300'
      } transition-all shadow-sm`}
    >
      <div className={`px-4 py-3 border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} flex items-center justify-between`}>
        <div className="flex items-center space-x-3">
          <Brain className="w-5 h-5 text-blue-500" />
          <h3 className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Trade Idea
          </h3>
        </div>
        <div className="flex items-center space-x-2">
          <span className={`text-xs px-2 py-0.5 rounded-full ${
            idea.type === 'buy' ? 'bg-green-500/20 text-green-400' : 
            idea.type === 'sell' ? 'bg-red-500/20 text-red-400' : 
            'bg-yellow-500/20 text-yellow-400'
          }`}>
            {idea.type.toUpperCase()}
          </span>
          <span className={`text-xs px-2 py-0.5 rounded-full ${
            isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700'
          }`}>
            {idea.timeframe}
          </span>
        </div>
      </div>

      <div className="p-4">
        <div className="flex items-center space-x-2 mb-2">
          <span className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {idea.symbol}
          </span>
          <div className="flex items-center">
            {getTypeIcon(idea.type)}
          </div>
        </div>

        <h4 className={`text-base font-medium mb-3 ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
          {idea.title}
        </h4>

        <div className={`text-sm mb-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} line-clamp-3`}>
          {idea.description}
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            {idea.strategy && (
              <div className="flex items-center space-x-1">
                <BarChart2 className="w-4 h-4 text-blue-400" />
                <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  {idea.strategy}
                </span>
              </div>
            )}
            {idea.catalyst && (
              <div className="flex items-center space-x-1">
                <Zap className="w-3.5 h-3.5 text-amber-400" />
                <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  Catalyst
                </span>
              </div>
            )}
          </div>
          <button
            onClick={onAnalyze}
            className="px-3 py-1.5 rounded-lg bg-blue-500 text-white hover:bg-blue-600 text-sm transition-colors"
          >
            Analyze
          </button>
        </div>
      </div>
    </div>
  );
};

export default TradeIdeaCard;