import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Bookmark, 
  Plus, 
  List, 
  Edit,
  Eye,
  Filter,
  SlidersHorizontal,
  LineChart,
  ChevronRight
} from 'lucide-react';

const Watchlists = () => {
  const { isDarkMode } = useTheme();

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Bookmark className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Watchlists
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Create and manage custom watchlists to track your investments and market interests
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className={`prose max-w-none ${isDarkMode ? 'prose-invert' : ''}`}>
            <h2>Understanding Watchlists</h2>
            <p>
              Watchlists in Newsroom allow you to group and monitor stocks, ETFs, cryptocurrencies, and other 
              financial instruments that interest you. They provide a customizable way to track price movements, 
              key metrics, and performance at a glance.
            </p>
            
            <div className={`p-6 rounded-xl border my-8 ${
              isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
            }`}>
              <h3 className="mt-0">Benefits of Using Watchlists</h3>
              <ul className="mb-0">
                <li>Organize investments by sector, strategy, or personal categories</li>
                <li>Monitor real-time price changes across multiple assets</li>
                <li>Quick access to detailed information on watched stocks</li>
                <li>Compare performance across different securities</li>
                <li>Set up personalized alerts based on watchlist items</li>
                <li>Easily research multiple stocks with Prism AI</li>
              </ul>
            </div>

            <h2>Creating Your First Watchlist</h2>
            <p>
              To create a new watchlist:
            </p>
            
            <ol>
              <li>Navigate to the Watchlists section in your dashboard</li>
              <li>Click the "New Watchlist" button</li>
              <li>Enter a name for your watchlist (e.g., "Tech Stocks," "Dividend Payers," "Potential Buys")</li>
              <li>Optionally, add a description</li>
              <li>Click "Create"</li>
            </ol>
            
            <p>
              Your new watchlist will appear in the sidebar. Now you're ready to add stocks and other assets.
            </p>

            <h3>Adding Stocks to Your Watchlist</h3>
            <p>
              There are several ways to add stocks to your watchlist:
            </p>
            
            <h4>Method 1: From the Watchlist Page</h4>
            <ol>
              <li>Open your watchlist</li>
              <li>Click the "Add Symbol" button</li>
              <li>Search for a stock by ticker symbol or company name</li>
              <li>Select the stock from the search results</li>
              <li>Click "Add to Watchlist"</li>
            </ol>
            
            <h4>Method 2: From Stock Detail Pages</h4>
            <ol>
              <li>Navigate to any stock detail page</li>
              <li>Click the bookmark icon near the stock name</li>
              <li>Select which watchlist to add the stock to</li>
              <li>Click "Add"</li>
            </ol>
            
            <h4>Method 3: From Prism Chat</h4>
            <ol>
              <li>When Prism mentions a stock in a chat response, hover over the ticker symbol</li>
              <li>Click the bookmark icon that appears</li>
              <li>Select your desired watchlist</li>
              <li>Click "Add to Watchlist"</li>
            </ol>

            <div className={`p-6 rounded-xl border my-8 ${
              isDarkMode ? 'bg-gray-800/80 border-gray-700' : 'bg-gray-50 border-gray-200'
            }`}>
              <h3 className="mt-0 flex items-center">
                <Plus className="w-5 h-5 mr-2" />
                Pro Tip: Bulk Add Symbols
              </h3>
              <p className="mb-0">
                Pro subscribers can add multiple symbols at once:
              </p>
              <ol className="mb-0">
                <li>Click "Bulk Add" in your watchlist</li>
                <li>Enter multiple ticker symbols separated by commas (e.g., "AAPL, MSFT, GOOG")</li>
                <li>Click "Add All"</li>
              </ol>
            </div>

            <h2>Managing Your Watchlists</h2>
            
            <h3>Organizing Watchlists</h3>
            <p>
              Effective organization helps you get the most from your watchlists:
            </p>
            <ul>
              <li><strong>Create multiple themed watchlists</strong> rather than one large list</li>
              <li><strong>Use descriptive names</strong> that clearly indicate the list's purpose</li>
              <li><strong>Regularly review and update</strong> your watchlists to keep them relevant</li>
              <li><strong>Consider categorization strategies</strong> like sectors, investment goals, or time horizons</li>
            </ul>

            <h3>Editing a Watchlist</h3>
            <p>
              To edit an existing watchlist:
            </p>
            <ol>
              <li>Open the watchlist you want to modify</li>
              <li>Click the "Edit" button (pencil icon)</li>
              <li>Make changes to the watchlist name or description</li>
              <li>Click "Save Changes"</li>
            </ol>

            <h3>Removing Stocks</h3>
            <p>
              To remove a stock from your watchlist:
            </p>
            <ol>
              <li>Locate the stock in your watchlist</li>
              <li>Click the three-dot menu at the end of the stock's row</li>
              <li>Select "Remove from Watchlist"</li>
              <li>Confirm the removal</li>
            </ol>

            <h3>Deleting a Watchlist</h3>
            <p>
              If you no longer need a watchlist:
            </p>
            <ol>
              <li>Open the watchlist you want to delete</li>
              <li>Click the settings icon (gear or three dots)</li>
              <li>Select "Delete Watchlist"</li>
              <li>Confirm the deletion</li>
            </ol>
            <p>
              <strong>Note:</strong> Deleting a watchlist cannot be undone, but the stocks themselves are not affected.
            </p>

            <h2>Customizing Your Watchlist View</h2>
            <p>
              Newsroom offers several ways to customize how your watchlist displays information:
            </p>
            
            <h3>Column Configuration</h3>
            <p>
              Choose which data points to display for each stock:
            </p>
            <ol>
              <li>Open your watchlist</li>
              <li>Click the "Columns" button (typically represented by a table or column icon)</li>
              <li>Check or uncheck boxes for different data points (Price, Change, Volume, Market Cap, etc.)</li>
              <li>Drag columns to reorder them</li>
              <li>Click "Apply" to save your changes</li>
            </ol>

            <h3>Sorting Options</h3>
            <p>
              Sort your watchlist based on different criteria:
            </p>
            <ul>
              <li>Click any column header to sort by that metric</li>
              <li>Click again to toggle between ascending and descending order</li>
              <li>Use the "Sort By" dropdown for additional sorting options</li>
            </ul>

            <h3>View Modes</h3>
            <p>
              Newsroom offers different visualization modes for your watchlists:
            </p>
            <ul>
              <li><strong>Table View:</strong> Traditional tabular format with customizable columns</li>
              <li><strong>Card View:</strong> Visual cards with key metrics and mini-charts</li>
              <li><strong>Performance View:</strong> Focus on performance metrics with comparative visualization</li>
            </ul>
            <p>
              Switch between these views using the view selector in the watchlist toolbar.
            </p>

            <h2>Advanced Watchlist Features</h2>
            
            <h3>Performance Analytics</h3>
            <p>
              Analyze the collective performance of your watchlist:
            </p>
            <ul>
              <li>View aggregate performance metrics for the entire watchlist</li>
              <li>Compare watchlist performance against major indices</li>
              <li>See sector allocation breakdown within your watchlist</li>
              <li>Track historical performance over customizable time periods</li>
            </ul>

            <h3>Custom Alerts</h3>
            <p>
              Set up alerts for stocks in your watchlist:
            </p>
            <ol>
              <li>Select a stock in your watchlist</li>
              <li>Click "Create Alert" (bell icon)</li>
              <li>Choose an alert type (Price Target, Percent Change, Volume Spike, etc.)</li>
              <li>Set your alert parameters</li>
              <li>Choose notification method (Email, Push, In-app)</li>
              <li>Save the alert</li>
            </ol>

            <h3>Research Integration</h3>
            <p>
              Seamlessly research your watchlist stocks with Prism AI:
            </p>
            <ul>
              <li>Select one or multiple stocks from your watchlist</li>
              <li>Click "Research with Prism"</li>
              <li>Prism will open with the selected stocks pre-loaded as stock chips</li>
              <li>Ask your research question about the selected stocks</li>
            </ul>

            <h2>Next Steps</h2>
            <p>
              To get the most out of watchlists, explore these related topics:
            </p>

            <div className="grid sm:grid-cols-2 gap-6 mt-6 not-prose">
              <a 
                href="/docs/sort-filter" 
                className={`flex items-center justify-between p-4 rounded-lg transition-colors ${
                  isDarkMode 
                    ? 'bg-gray-800 hover:bg-gray-700 text-white border border-gray-700' 
                    : 'bg-white hover:bg-gray-50 text-gray-900 border border-gray-200'
                }`}
              >
                <div className="flex items-center">
                  <Filter className={`w-5 h-5 mr-3 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                  <span>Sorting & Filtering Options</span>
                </div>
                <ChevronRight className="w-5 h-5" />
              </a>
              
              <a 
                href="/docs/stock-view" 
                className={`flex items-center justify-between p-4 rounded-lg transition-colors ${
                  isDarkMode 
                    ? 'bg-gray-800 hover:bg-gray-700 text-white border border-gray-700' 
                    : 'bg-white hover:bg-gray-50 text-gray-900 border border-gray-200'
                }`}
              >
                <div className="flex items-center">
                  <Eye className={`w-5 h-5 mr-3 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                  <span>Stock View Options</span>
                </div>
                <ChevronRight className="w-5 h-5" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Watchlists;