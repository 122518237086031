// src/components/market/StockDetails.tsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import { marketDataService } from '../../services/marketDataService';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { Alert } from '../ui/alert';
import { CompanyDetails, StockPrice } from '../../types/market';

import { 
  DollarSign, 
  LineChart,
  Globe,
  Users 
} from 'lucide-react';

export const StockDetails: React.FC = () => {
  const { ticker } = useParams<{ ticker: string }>();
  const { isDarkMode } = useTheme();
  const [details, setDetails] = useState<CompanyDetails | null>(null);
  const [priceData, setPriceData] = useState<StockPrice | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadStockData = async () => {
      if (!ticker) return;

      setIsLoading(true);
      try {
        const [companyDetails, stockData] = await Promise.all([
          marketDataService.getStockDetails(ticker),
          marketDataService.getStockData(ticker, '1d')
        ]);

        setDetails(companyDetails);
        setPriceData(stockData[0] || null);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load stock data');
      } finally {
        setIsLoading(false);
      }
    };

    loadStockData();
  }, [ticker]);

  if (isLoading) return <LoadingSpinner />;
  if (error || !details || !priceData) {
    return <Alert variant="destructive">{error || 'Failed to load data'}</Alert>;
  }

  const metrics = [
    {
      label: 'Current Price',
      value: `$${priceData.c.toFixed(2)}`,
      icon: DollarSign
    },
    {
      label: 'Volume',
      value: priceData.v.toLocaleString(),
      icon: LineChart
    },
    {
      label: 'Market Cap',
      value: `$${(details.marketCap / 1e9).toFixed(2)}B`,
      icon: DollarSign
    },
    {
      label: 'Employees',
      value: details.employees.toLocaleString(),
      icon: Users
    }
  ];

  return (
    <div className="space-y-6">
      {/* Company Header */}
      <div>
        <h1 className={`text-2xl font-bold ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          {details.name} ({details.ticker})
        </h1>
        <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
          {details.sector} • {details.industry}
        </p>
      </div>

      {/* Metrics Grid */}
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
        {metrics.map((metric, index) => {
          const Icon = metric.icon;
          return (
            <div
              key={index}
              className={`p-4 rounded-lg ${
                isDarkMode ? 'bg-gray-800' : 'bg-white'
              }`}
            >
              <div className="flex items-center justify-between mb-2">
                <span className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  {metric.label}
                </span>
                <Icon className="w-5 h-5" />
              </div>
              <div className={`text-xl font-semibold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {metric.value}
              </div>
            </div>
          );
        })}
      </div>

      {/* Company Description */}
      <div className={`p-6 rounded-lg ${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      }`}>
        <h2 className={`text-xl font-bold mb-4 ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          About {details.name}
        </h2>
        <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
          {details.description}
        </p>
        {details.homepage && (
          <a
            href={details.homepage}
            target="_blank"
            rel="noopener noreferrer"
            className="mt-4 inline-flex items-center text-blue-500 hover:text-blue-600"
          >
            <Globe className="w-4 h-4 mr-2" />
            Visit Website
          </a>
        )}
      </div>
    </div>
  );
};