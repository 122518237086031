import { api } from './api';

interface NormalizedPrompt {
  text: string;
  isHeadline: boolean;
  imageQuery?: string | null;
  ticker?: string | null;
}

interface TrendingStockItem {
  ticker: string;
  name: string;
  reason: string;
  changePercent?: number;
  price?: number;
  priceLoaded?: boolean;
}

export const cachedPromptService = {
  /**
   * Fetches pre-cached prompts that are refreshed server-side every 15 minutes
   * This provides instant loading for homepage visitors
   */
  getCachedPrompts: async () => {
    try {
      // Updated to use the correct API path
      const { data } = await api.get('/market/prompts/market/cached');
      return data;
    } catch (error) {
      console.error('Failed to fetch cached prompts:', error);
      // Fallback to direct API if cached endpoint fails
      const { data } = await api.get('/market/prompts/market');
      return data;
    }
  },

  /**
   * Fetches pre-cached trending stocks that are refreshed server-side every 15 minutes
   */
  getCachedTrendingStocks: async () => {
    try {
      // Use the trending stocks endpoint (which now serves cached data)
      const { data } = await api.get('/trending-stocks');
      return data;
    } catch (error) {
      console.error('Failed to fetch cached trending stocks:', error);
      return [];
    }
  },

  /**
   * Forces a refresh of the server-side cache
   * Should only be used in dashboard contexts
   */
  refreshCache: async () => {
    try {
      // Updated to use the correct API path
      await api.post('/market/prompts/market/refresh-cache');
      return true;
    } catch (error) {
      console.error('Failed to refresh prompt cache:', error);
      return false;
    }
  }
};