// src/components/ui/HelpMenu.tsx
import React from 'react';
import { useWalkthrough } from '../../contexts/WalkthroughContext';
import { useTheme } from '../../contexts/ThemeContext';
import { useLocation } from 'react-router-dom';
import { IoIosWalk } from 'react-icons/io';

const HelpMenu: React.FC = () => {
  const { startWalkthrough, canShowWalkthrough } = useWalkthrough();
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const isInDashboard = canShowWalkthrough(location.pathname);
  
  // Only render the button if we're in a location where the walkthrough can be shown
  if (!isInDashboard) {
    return null;
  }
  
  return (
    <button 
      onClick={startWalkthrough} 
      className={`p-2 rounded-full transition-colors ${
        isDarkMode ? 'hover:bg-gray-800 text-gray-300' : 'hover:bg-gray-200 text-gray-600'
      }`}
      title="Start App Walkthrough"
    >
      <IoIosWalk className="w-5 h-5" />
    </button>
  );
};

export default HelpMenu;