import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { DocumentationLayout } from '../../components/layout/DocumentationLayout';
import { 
  MessageSquare, 
  Send, 
  FileText, 
  ArrowRight,
  Target,
  Search,
  Bookmark,
  RefreshCw
} from 'lucide-react';
import { Link } from 'react-router-dom';

const StartingChats = () => {
  const { isDarkMode } = useTheme();

  return (
    <DocumentationLayout
      title="Starting Conversations with Prism"
      icon={MessageSquare}
      description="Learn how to effectively start and navigate research conversations with Prism AI"
    >
      <div className={`prose max-w-none ${isDarkMode ? 'prose-invert' : ''}`}>
        <h2>Starting a New Conversation</h2>
        <p>
          Beginning a conversation with Prism AI is simple and intuitive. You can start researching 
          the markets in several ways:
        </p>

        <h3>From the Dashboard</h3>
        <ol>
          <li>Click the <strong>Prism</strong> button in the main navigation</li>
          <li>You'll be taken to the chat interface with a blank conversation</li>
          <li>Type your market question in the chat input field and press Enter or click Send</li>
        </ol>

        <h3>Using Quick Start</h3>
        <ol>
          <li>From the Dashboard, locate the "Quick Actions" section</li>
          <li>Click "New Research" to start a fresh conversation</li>
          <li>Optionally select a suggested topic or research mode to begin with specific focus</li>
        </ol>

        <div className={`p-6 rounded-xl border my-8 ${
          isDarkMode ? 'bg-gray-800/80 border-gray-700' : 'bg-gray-50 border-gray-200'
        }`}>
          <h3 className="mt-0">Effective Query Tips</h3>
          <ul>
            <li><strong>Be specific:</strong> Include particular stocks, time frames, or market aspects</li>
            <li><strong>Mention metrics:</strong> Specify indicators or data points you're interested in</li>
            <li><strong>Provide context:</strong> Include your investment approach or what you're trying to learn</li>
            <li><strong>Ask one thing at a time:</strong> Complex questions are better broken into steps</li>
          </ul>
        </div>

        <h2>Example Queries</h2>
        <p>
          Here are some effective queries to help you get started:
        </p>

        <h3>Stock Analysis</h3>
        <ul>
          <li>"Analyze AAPL's recent performance and provide a technical outlook for the next month"</li>
          <li>"Compare the fundamentals of MSFT and GOOGL, focusing on growth metrics and valuation"</li>
          <li>"What's been happening with Tesla stock this week and what factors are driving its movement?"</li>
        </ul>

        <h3>Market Research</h3>
        <ul>
          <li>"What sectors are outperforming the broader market over the last quarter?"</li>
          <li>"Analyze recent trends in semiconductor stocks and identify key drivers"</li>
          <li>"How are rising interest rates affecting different market sectors?"</li>
        </ul>

        <h3>Economic Analysis</h3>
        <ul>
          <li>"Explain the latest Fed meeting's impact on bond yields and equity markets"</li>
          <li>"How might current inflation data affect growth stocks in the coming months?"</li>
          <li>"Analyze the relationship between dollar strength and commodity prices this year"</li>
        </ul>

        <h2>Continuing the Conversation</h2>
        <p>
          After your initial query, Prism will research and respond with insights. You can then:
        </p>
        <ul>
          <li><strong>Ask follow-up questions:</strong> Dig deeper into specific aspects of the response</li>
          <li><strong>Request clarification:</strong> Ask Prism to explain concepts or terminology</li>
          <li><strong>Pivot to related topics:</strong> Explore connected areas of research</li>
          <li><strong>Request specific visualizations:</strong> Ask for charts or data displays</li>
        </ul>

        <h2>Using Chat Chips</h2>
        <p>
          Chat chips are special tags that help focus your research:
        </p>
        <ul>
          <li><strong>Stock chips:</strong> Add ticker symbols to focus on specific stocks</li>
          <li><strong>Research mode chips:</strong> Select approaches like Technical Analysis or News Focus</li>
          <li><strong>File chips:</strong> Upload documents for Prism to analyze</li>
        </ul>
        <p>
          Learn more about using chips in the <Link to="/docs/chat-chips" className="text-blue-500 hover:text-blue-700">Chat Chips guide</Link>.
        </p>

        <h2>Managing Chat Sessions</h2>
        <p>
          Each conversation is automatically saved with the following options:
        </p>
        <ul>
          <li><strong>Rename:</strong> Give your conversation a meaningful title</li>
          <li><strong>Export:</strong> Download your research as a PDF</li>
          <li><strong>New chat:</strong> Start a fresh conversation while saving the current one</li>
          <li><strong>Access history:</strong> Return to previous conversations from the sidebar</li>
        </ul>
      </div>
    </DocumentationLayout>
  );
};

export default StartingChats;