// src/components/chat/ChatSidebar.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../contexts/ThemeContext';
import { ChatSession } from '../../types/chat';
import { 
  Plus, MessageSquare, Calendar, Search,
  MoreVertical, Edit2, Trash2, Clock,
  ChevronRight, Database, Filter,
  ChevronLeft, Archive, Download,
  ListRestart, Share2, Star,
  MessageSquareDashed, AlertCircle,
  X
} from 'lucide-react';

// Add sorting options
type SortOption = 'recent' | 'alphabetical' | 'mostUsed';

interface ChatSidebarProps {
  sessions?: ChatSession[];
  currentSessionId: string | null;
  onSessionSelect: (id: string) => void;
  onNewChat: () => void;
  onDeleteSession: (id: string) => void;
  onDeleteAllSessions: () => void;
  onRenameSession: (id: string, title: string) => void;
  onLoadSessions?: () => void;
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  isLoading?: boolean;
  error?: string | null;
  onStarSession: (id: string, isStarred: boolean) => Promise<void>;
  isMobile?: boolean;
  isMobileSidebarOpen?: boolean;
  onCloseMobileSidebar?: () => void;
}

const ChatSidebar: React.FC<ChatSidebarProps> = ({
  sessions = [],
  currentSessionId,
  onSessionSelect,
  onNewChat,
  onDeleteSession,
  onDeleteAllSessions,
  onRenameSession,
  onLoadSessions,
  isCollapsed,
  onToggleCollapse,
  isLoading = false,
  error = null,
  onStarSession,
  isMobile = false,
  isMobileSidebarOpen = false,
  onCloseMobileSidebar = () => {}
}) => {
  const { isDarkMode } = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editTitle, setEditTitle] = useState('');
  const [menuOpen, setMenuOpen] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState<SortOption>('recent');
  const [filterStarred, setFilterStarred] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    if (sessions.length === 0 && !isLoading) {
      if (typeof window !== 'undefined') {
        const token = localStorage.getItem('token');
        if (token) {
          onLoadSessions?.();
        }
      }
    }
  }, [sessions.length, isLoading, onLoadSessions]);

  const styles = {
    bg: isDarkMode ? 'bg-gray-800' : 'bg-white',
    text: isDarkMode ? 'text-white' : 'text-gray-900',
    textSecondary: isDarkMode ? 'text-gray-400' : 'text-gray-600',
    border: isDarkMode ? 'border-gray-700' : 'border-gray-200',
    hover: isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100',
    active: isDarkMode 
      ? 'bg-gray-700/80 border-l-4 border-l-blue-500' 
      : 'bg-blue-50 border-l-4 border-l-blue-500',
    starred: 'border-l-4 border-l-transparent'
  };

  const handleRename = async (id: string) => {
    if (!editTitle.trim()) return;
    await onRenameSession(id, editTitle);
    setEditingId(null);
    setEditTitle('');
  };

  const formatDate = (date: Date) => {
    const now = new Date();
    const diff = now.getTime() - new Date(date).getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (days === 0) return 'Today';
    if (days === 1) return 'Yesterday';
    if (days < 7) return `${days} days ago`;
    return new Date(date).toLocaleDateString();
  };

  const sortedSessions = useCallback(() => {
    let sorted = [...sessions];
    switch (sortBy) {
      case 'alphabetical':
        return sorted.sort((a, b) => a.title.localeCompare(b.title));
      case 'mostUsed':
        return sorted.sort((a, b) => (b.usedTickers?.length || 0) - (a.usedTickers?.length || 0));
      case 'recent':
      default:
        return sorted.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
    }
  }, [sessions, sortBy]);

  const filteredSessions = sortedSessions().filter(session => {
    const matchesSearch = session?.title?.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesStarred = filterStarred ? session.isStarred : true;
    return matchesSearch && matchesStarred;
  });

  const handleSessionSelect = (id: string) => {
    onSessionSelect(id);
    if (isMobile) {
      onCloseMobileSidebar();
    }
  };

  const renderSidebarContent = () => (
    <>
      {/* Header with buttons */}
      <div className={`p-3.5 border-b ${styles.border}`}>
        {!isCollapsed || isMobile ? (
          <div className="space-y-3">
            {/* Mobile close button + header */}
            {isMobile && (
              <div className="flex items-center justify-between mb-2">
                <h2 className={`text-lg font-medium ${styles.text}`}>Your Chats</h2>
                <button 
                  onClick={onCloseMobileSidebar}
                  className={`p-2 rounded-lg ${styles.hover}`}
                >
                  <X className="w-5 h-5 text-gray-400" />
                </button>
              </div>
            )}

            {/* New Chat Button Row */}
            <div className="flex items-center gap-2">
              <button
                onClick={() => {
                  onNewChat();
                  if (isMobile) onCloseMobileSidebar();
                }}
                className="flex-1 py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-all duration-200 flex items-center justify-center space-x-2 shadow-sm"
              >
                <Plus className="w-4 h-4" />
                <span>New Chat</span>
              </button>
              <button
                onClick={() => setShowFilters(!showFilters)}
                className={`p-2.5 rounded-lg transition-colors ${styles.hover}`}
              >
                <Filter className="w-4 h-4 text-white" />
              </button>
            </div>

            {/* Filter Panel */}
            <AnimatePresence>
              {showFilters && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  className="space-y-2 text-white"
                >
                  {/* Sort Options */}
                  <select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value as SortOption)}
                    className={`w-full px-3 py-2 rounded-lg ${styles.bg} ${styles.border}`}
                  >
                    <option value="recent">Most Recent</option>
                    <option value="alphabetical">Alphabetical</option>
                    <option value="mostUsed">Most Used</option>
                  </select>

                  {/* Filter Toggles */}
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => setFilterStarred(!filterStarred)}
                      className={`px-3 py-1.5 rounded-lg flex items-center space-x-2 ${
                        filterStarred ? 'bg-yellow-500/20 text-yellow-500' : styles.hover
                      }`}
                    >
                      <Star className="w-4 h-4" />
                      <span>Starred</span>
                    </button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ) : (
          // Collapsed sidebar buttons
          <div className="flex flex-col">
            <button
              onClick={onNewChat}
              className="w-full flex justify-center p-3 rounded-lg transition-colors hover:bg-gray-700/20"
              title="New Chat"
            >
              <Plus className="w-5 h-5 text-blue-500" />
            </button>
          </div>
        )}
      </div>

      {/* Enhanced Search with Shortcuts */}
      {(!isCollapsed || isMobile) && (
        <div className="p-4">
          <div className={`relative ${styles.bg}`}>
            <Search className={`absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 ${styles.textSecondary}`} />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search chats..."
              className={`w-full pl-10 pr-4 py-2 rounded-lg ${styles.bg} ${styles.text} border ${styles.border} focus:ring-2 focus:ring-blue-500`}
            />
          </div>
        </div>
      )}

      {/* Chat List */}
      <div className={`overflow-y-auto ${isMobile ? 'h-[calc(100vh-180px)]' : 'h-[calc(100vh-160px)]'}`}>
        {filteredSessions.map(session => (
          <div
            key={session.id}
            className={`
              relative p-4 cursor-pointer transition-colors duration-200
              ${currentSessionId === session.id 
                ? styles.active 
                : `hover:${styles.hover} border-l-4 border-l-transparent`}
              ${styles.border} border-b
            `}
          >
            <div 
              className="flex items-start space-x-3"
              onClick={() => handleSessionSelect(session.id)}
            >
              <div className={`p-2 rounded-lg ${styles.bg}`}>
                <MessageSquare className={`w-4 h-4 ${styles.text}`} />
              </div>
              <div className="flex-1 min-w-0">
                {editingId === session.id ? (
                  <input
                    type="text"
                    value={editTitle}
                    onChange={(e) => setEditTitle(e.target.value)}
                    onBlur={() => handleRename(session.id)}
                    onKeyDown={(e) => e.key === 'Enter' && handleRename(session.id)}
                    autoFocus
                    className={`
                      w-full px-2 py-1 rounded
                      ${styles.bg} ${styles.text}
                      border ${styles.border}
                    `}
                  />
                ) : (
                  <>
                    <div className="flex items-center">
                      <h3 className={`font-medium truncate ${styles.text} flex-1`}>
                        {session.title}
                      </h3>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onStarSession(session.id, !session.isStarred);
                        }}
                        className={`ml-1 p-1 rounded-lg ${styles.hover}`}
                      >
                        <Star 
                          className={`w-4 h-4 ${
                            session.isStarred ? 'text-yellow-400 fill-yellow-400' : styles.textSecondary
                          }`} 
                        />
                      </button>
                    </div>
                    <div className={`flex items-center space-x-2 text-xs ${styles.textSecondary}`}>
                      <Clock className="w-3 h-3" />
                      <span>{formatDate(session.updatedAt)}</span>
                      {session.usedTickers?.length > 0 && (
                        <>
                          <span>•</span>
                          <div className="flex items-center space-x-1 max-w-[120px]">
                            <Database className="w-3 h-3 flex-shrink-0" />
                            <span className="truncate">{session.usedTickers.join(', ')}</span>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>

              {/* Actions Menu */}
              <div className="relative">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setMenuOpen(menuOpen === session.id ? null : session.id);
                  }}
                  className={`p-1 rounded-lg ${styles.hover}`}
                >
                  <MoreVertical className={`w-4 h-4 ${styles.textSecondary}`} />
                </button>

                <AnimatePresence>
                  {menuOpen === session.id && (
                    <motion.div
                      initial={{ opacity: 0, scale: 0.95 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.95 }}
                      className={`
                        absolute right-0 mt-1 w-48
                        rounded-lg shadow-lg
                        ${styles.bg} border ${styles.border}
                        z-50
                      `}
                    >
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditingId(session.id);
                          setEditTitle(session.title);
                          setMenuOpen(null);
                        }}
                        className={`
                          w-full px-4 py-2 text-left
                          flex items-center space-x-2
                          ${styles.hover}
                        `}
                      >
                        <Edit2 className="w-4 h-4" />
                        <span>Rename</span>
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteSession(session.id);
                          setMenuOpen(null);
                        }}
                        className={`
                          w-full px-4 py-2 text-left
                          flex items-center space-x-2
                          text-red-500 ${styles.hover}
                        `}
                      >
                        <Trash2 className="w-4 h-4" />
                        <span>Delete</span>
                      </button>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        ))}
        {/* Empty State */}
        {filteredSessions.length === 0 && (
          <div className="flex flex-col items-center justify-center h-[calc(100vh-200px)] p-6 text-center">
            <MessageSquareDashed className={`w-12 h-12 mb-4 ${
              isDarkMode ? 'text-gray-700' : 'text-gray-300'
            }`} />
            <h3 className={`font-medium mb-2 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-900'
            }`}>
              {searchQuery ? 'No matches found' : 'Start a new conversation'}
            </h3>
            <p className={`text-sm mb-6 ${
              isDarkMode ? 'text-gray-500' : 'text-gray-600'
            }`}>
              {searchQuery 
                ? 'Try adjusting your search or filters'
                : 'Get AI-powered market insights and analysis'
              }
            </p>
            <div className="space-y-2">
              <button
                onClick={() => {
                  onNewChat();
                  if (isMobile) onCloseMobileSidebar();
                }}
                className="w-full inline-flex items-center px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 transition-colors"
              >
                <Plus className="w-4 h-4 mr-2" />
                New Chat
              </button>
              {searchQuery && (
                <button
                  onClick={() => {
                    setSearchQuery('');
                    setFilterStarred(false);
                    setSortBy('recent');
                  }}
                  className={`w-full inline-flex items-center px-4 py-2 rounded-lg ${styles.hover}`}
                >
                  <AlertCircle className="w-4 h-4 mr-2" />
                  Clear Filters
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      
      {/* Footer with clear buttons */}
      {(!isCollapsed || isMobile) && sessions.length > 0 && (
        <div className={`sticky bottom-0 left-0 right-0 p-3 border-t ${styles.border} ${styles.bg}`}>
          <div className="flex justify-between">
            <button
              onClick={onDeleteAllSessions}
              className={`text-xs flex items-center ${styles.textSecondary} hover:text-red-500`}
            >
              <Trash2 className="w-3 h-3 mr-1" />
              Clear All
            </button>
            {filterStarred && (
              <button
                onClick={() => setFilterStarred(false)}
                className={`text-xs flex items-center ${styles.textSecondary} hover:text-blue-500`}
              >
                <Star className="w-3 h-3 mr-1" />
                Show All
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );

  // For desktop - regular sidebar with toggle
  if (!isMobile) {
    return (
      <div className={`${isCollapsed ? 'w-16' : 'w-80'} flex-shrink-0 border-r ${styles.border} ${styles.bg} relative transition-all duration-300`}
        style={{ zIndex: 50 }}
      >
        <button
          onClick={onToggleCollapse}
          className={`
            absolute -right-3 top-1/2 transform -translate-y-1/2
            w-6 h-12 rounded-full flex items-center justify-center
            ${styles.bg} border ${styles.border} z-10
            ${styles.hover}
          `}
        >
          <ChevronLeft className={`
            w-4 h-4 ${styles.text} transition-transform
            ${isCollapsed ? 'rotate-180' : ''}
          `} />
        </button>
        {renderSidebarContent()}
      </div>
    );
  }

  // For mobile - slide-in sidebar with backdrop
  return (
    <AnimatePresence>
      {isMobileSidebarOpen && (
        <>
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onCloseMobileSidebar}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm z-40"
          />
          
          {/* Sidebar */}
          <motion.div 
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ type: 'spring', damping: 25, stiffness: 300 }}
            className={`fixed top-0 left-0 bottom-0 w-[85%] max-w-[320px] ${styles.bg} z-50 overflow-hidden`}
          >
            {renderSidebarContent()}
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default ChatSidebar;