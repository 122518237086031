// src/services/chatService.ts

import { api } from './api';
import { 
  ChatSession, 
  ChatMessage, 
  ChatResponse, 
  DataRequirement 
} from '../types/chat';
import { ResearchSource } from '../types/research';
import logger from '../config/logger';

class ChatService {
  private static instance: ChatService;
  private activeResearch: Map<string, EventSource> = new Map();

  private constructor() {}

  static getInstance(): ChatService {
    if (!ChatService.instance) {
      ChatService.instance = new ChatService();
    }
    return ChatService.instance;
  }

  async analyzeQuery(query: string): Promise<{
    researchNeeded: boolean;
    requirements?: DataRequirement[];
    suggestedApproach?: string;
  }> {
    try {
      const { data } = await api.post('/market-chat/analyze', { query });
      return data;
    } catch (error) {
      console.log('Query analysis failed:', error);
      throw error;
    }
  }

  async startResearch(
    query: string,
    onProgress?: (progress: any) => void,
    onSource?: (source: ResearchSource) => void
  ): Promise<string> {
    const researchId = `research_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
    
    // Include auth token in URL since EventSource doesn't support headers
    const token = localStorage.getItem('token');
    const eventSource = new EventSource(
      `${api.defaults.baseURL}/market-chat/research?query=${encodeURIComponent(query)}&id=${researchId}&token=${token}`,
      { withCredentials: true }
    );

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      
      switch (data.type) {
        case 'progress':
          onProgress?.(data.progress);
          break;
        case 'source':
          onSource?.(data.source);
          break;
        case 'complete':
          eventSource.close();
          this.activeResearch.delete(researchId);
          break;
      }
    };

    eventSource.onerror = () => {
      eventSource.close();
      this.activeResearch.delete(researchId);
    };

    // Store active research
    this.activeResearch.set(researchId, eventSource);

    return researchId;
  }

  async getResearchResults(researchId: string): Promise<ChatResponse> {
    try {
      const { data } = await api.get(`/market-chat/research/${researchId}/results`);
      return data;
    } catch (error) {
      console.log('Failed to get research results:', error);
      throw error;
    }
  }

  async generateResponse(
    query: string,
    marketData?: any,
    fundamentalData?: any,
    newsData?: any,
    researchId?: string
  ): Promise<ChatResponse> {
    try {
      const { data } = await api.post('/market-chat/response', {
        query,
        marketData,
        fundamentalData,
        newsData,
        researchId
      });
      return data;
    } catch (error) {
      console.log('Response generation failed:', error);
      throw error;
    }
  }

  async createSession(): Promise<ChatSession> {
    try {
      const { data } = await api.post<ChatSession>('/market-chat/sessions');
      if (!data || !data.id) {
        throw new Error('Invalid session data received from server');
      }
      return data;
    } catch (error) {
      console.log('Failed to create chat session:', error);
      throw error;
    }
  }

  async updateSession(id: string, data: ChatMessage[] | { messages: ChatMessage[] }): Promise<void> {
    try {
      // Handle both array of messages or object with messages property
      const messages = Array.isArray(data) ? data : data.messages;
      
      if (!Array.isArray(messages)) {
        console.error('Invalid messages format:', data);
        throw new Error('Invalid messages format');
      }
      
      console.log(`Updating session ${id} with ${messages.length} messages`);
      
      // Retry logic for better reliability
      let retries = 3;
      while (retries > 0) {
        try {
          await api.put(`/market-chat/sessions/${id}`, { messages });
          return;
        } catch (error) {
          retries--;
          if (retries === 0) throw error;
          await new Promise(resolve => setTimeout(resolve, 500));
        }
      }
    } catch (error) {
      console.error('Failed to update chat session:', error);
      throw error;
    }
  }

  async deleteSession(id: string): Promise<void> {
    try {
      // Cancel any active research for this session
      this.activeResearch.forEach((eventSource, researchId) => {
        if (researchId.includes(id)) {
          eventSource.close();
          this.activeResearch.delete(researchId);
        }
      });

      await api.delete(`/market-chat/sessions/${id}`);
    } catch (error) {
      console.log('Failed to delete chat session:', error);
      throw error;
    }
  }

  async generateSessionTitle(messages: ChatMessage[]): Promise<string> {
    try {
      const { data } = await api.post('/market-chat/generate-title', { messages });
      return data.response;
    } catch (error) {
      console.log('Failed to generate title:', error);
      // Fallback to first message
      return messages[0]?.content.slice(0, 40) || 'New Chat';
    }
  }
}

export const chatService = ChatService.getInstance();
