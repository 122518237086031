import React from 'react';
import { motion } from 'framer-motion';
import { X, ChevronRight, ListFilter, Plus } from 'lucide-react';
import { useWatchlists } from '../../hooks/useWatchlists';
import { useTheme } from '../../contexts/ThemeContext';

interface WatchlistSelectModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectStock: (ticker: string, companyName: string, prefix: string) => void;
  selectedResearchModePrefix: string;
}

export const WatchlistSelectModal: React.FC<WatchlistSelectModalProps> = ({
  isOpen,
  onClose,
  onSelectStock,
  selectedResearchModePrefix
}) => {
  const { isDarkMode } = useTheme();
  const { watchlists, savedItems } = useWatchlists();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 flex items-center justify-center z-50 backdrop-blur-sm">
      <motion.div
        initial={{ scale: 0.95, opacity: 0, y: -20 }}
        animate={{ scale: 1, opacity: 1, y: 0 }}
        exit={{ scale: 0.95, opacity: 0, y: -20 }}
        className={`fixed inset-x-0 w-full max-w-md transform overflow-hidden rounded-xl ${
          isDarkMode ? 'bg-gray-900' : 'bg-white'
        } shadow-xl transition-all`}
      >
        {/* Header */}
        <div className={`p-4 flex items-center justify-between border-b ${
          isDarkMode ? 'border-gray-700' : 'border-gray-200'
        }`}>
          <h3 className={`text-lg font-medium ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>Add from Watchlists</h3>
          <button
            onClick={onClose}
            className={`rounded-lg p-2 ${
              isDarkMode 
                ? 'hover:bg-gray-800 text-gray-400' 
                : 'hover:bg-gray-100 text-gray-500'
            }`}
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        
        {/* Content */}
        <div className="py-1 max-h-[60vh] overflow-y-auto">
          {watchlists && watchlists.length > 0 ? ( 
            <div>
              {watchlists.map(list => (
                <div key={list.id} className="px-4 py-3">
                  <div className={`text-sm font-medium mb-2 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-700'
                  }`}>{list.name}</div>
                  
                  {list.items.length > 0 ? (
                    <div className="space-y-1">
                      {list.items.map(item => (
                        <button
                          key={item.id}
                          className={`w-full flex items-center justify-between p-2.5 rounded-lg ${
                            isDarkMode 
                              ? 'hover:bg-gray-800 text-gray-200' 
                              : 'hover:bg-gray-100 text-gray-700'
                          }`}
                          onClick={() => {
                            onSelectStock(
                              item.identifier,
                              item.title,
                              selectedResearchModePrefix
                            );
                            onClose();
                          }}
                        >
                          <div className="flex items-center">
                            <div className="w-7 h-7 mr-2 rounded-full bg-gray-700 flex items-center justify-center overflow-hidden">
                              <img
                                src={`/api/market/company-logo/${item.identifier}`}
                                alt={item.title}
                                className="w-full h-full object-cover"
                                onError={(e) => {
                                  (e.target as HTMLImageElement).style.display = 'none';
                                  e.currentTarget.parentElement!.innerHTML = `<span class="text-xs font-bold">${item.identifier[0]}</span>`;
                                }}
                              />
                            </div>
                            <div>
                              <div className="font-mono">{item.identifier}</div>
                              <div className="text-xs truncate max-w-[200px] text-gray-400">{item.title}</div>
                            </div>
                          </div>
                          <ChevronRight size={16} className="text-gray-500" />
                        </button>
                      ))}
                    </div>
                  ) : (
                    <div className={`text-xs text-center py-3 ${
                      isDarkMode ? 'text-gray-500' : 'text-gray-400'
                    }`}>No stocks in this list</div>
                  )}
                </div>
              ))}
            </div>
          ) : savedItems && savedItems.length > 0 ? (
            <div className="px-4 py-3">
              <div className={`text-sm font-medium mb-2 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>Saved Stocks</div>
              <div className="space-y-1">
                {savedItems.filter(item => item.type === 'stock').map(item => (
                  <button
                    key={item.id}
                    className={`w-full flex items-center justify-between p-2.5 rounded-lg ${
                      isDarkMode 
                        ? 'hover:bg-gray-800 text-gray-200' 
                        : 'hover:bg-gray-100 text-gray-700'
                    }`}
                    onClick={() => {
                      onSelectStock(
                        item.identifier,
                        item.title,
                        selectedResearchModePrefix
                      );
                      onClose();
                    }}
                  >
                    <div className="flex items-center">
                      <div className="w-7 h-7 mr-2 rounded-full bg-gray-700 flex items-center justify-center overflow-hidden">
                        <img
                          src={`/api/market/company-logo/${item.identifier}`}
                          alt={item.title}
                          className="w-full h-full object-cover"
                          onError={(e) => {
                            (e.target as HTMLImageElement).style.display = 'none';
                            e.currentTarget.parentElement!.innerHTML = `<span class="text-xs font-bold">${item.identifier[0]}</span>`;
                          }}
                        />
                      </div>
                      <div>
                        <div className="font-mono">{item.identifier}</div>
                        <div className="text-xs truncate max-w-[200px] text-gray-400">{item.title}</div>
                      </div>
                    </div>
                    <ChevronRight size={16} className="text-gray-500" />
                  </button>
                ))}
              </div>
            </div>
          ) : (
            <div className={`px-4 py-8 text-center ${
              isDarkMode ? 'text-gray-400' : 'text-gray-500'
            }`}>
              <ListFilter className="w-10 h-10 mx-auto mb-3 opacity-50" />
              <p className="text-sm mb-3">No watchlists or saved stocks found</p>
              <a 
                href="/dashboard/watchlists" 
                className={`inline-block px-4 py-2 rounded-lg ${
                  isDarkMode ? 'bg-gray-800 text-blue-400' : 'bg-gray-100 text-blue-600'
                } text-sm`}
              >
                Create a watchlist
              </a>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className={`p-4 border-t ${
          isDarkMode ? 'border-gray-700 bg-gray-800/50' : 'border-gray-200 bg-gray-50'
        }`}>
          <button
            className={`w-full flex items-center justify-center py-2.5 px-4 rounded-lg ${
              isDarkMode 
                ? 'bg-gray-700 hover:bg-gray-600 text-white' 
                : 'bg-gray-200 hover:bg-gray-300 text-gray-800'
            }`}
            onClick={() => {
              // Navigate to stock search or integrate with existing search UI
              onClose();
            }}
          >
            <Plus size={16} className="mr-2" />
            <span>Search for a stock</span>
          </button>
        </div>
      </motion.div>
    </div>
  );
};