import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { marketDataService } from '../../services/marketDataService';
import { LineChart, Line, YAxis, ResponsiveContainer } from 'recharts';

// Add cache interface
interface CacheEntry {
  data: any[];
  timestamp: number;
}

// Create a static cache object outside the component
const sparklineCache: { [key: string]: CacheEntry } = {};
const CACHE_DURATION = 30000; // 30 seconds in milliseconds

export type TimeRange = '1d' | '5d' | '1mo' | '3mo' | '6mo' | '1y' | '2y' | '5y';

interface StockSparklineProps {
  ticker: string;
  timeframe: TimeRange;
  width?: number;
  height?: number;
}

const StockSparkline: React.FC<StockSparklineProps> = ({
  ticker,
  timeframe,
  width = 120,
  height = 40
}) => {
  const { isDarkMode } = useTheme();
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let observer: IntersectionObserver;
    
    const setupObserver = () => {
      observer = new IntersectionObserver(
        ([entry]) => {
          requestAnimationFrame(() => {
            setTimeout(() => {
              setIsVisible(entry.isIntersecting);
            }, 50);
          });
        },
        { threshold: 0.1 }
      );

      if (containerRef.current) {
        observer.observe(containerRef.current);
      }
    };

    setupObserver();

    return () => {
      observer?.disconnect();
    };
  }, []);

  useEffect(() => {
    // Suppress ResizeObserver loop limit exceeded error
    const resizeObserverError = window.ResizeObserver.prototype.constructor as unknown as new (callback: ResizeObserverCallback) => ResizeObserver;
    window.ResizeObserver.prototype.constructor = (...args: [ResizeObserverCallback]) => {
      const observer = new resizeObserverError(...args);
      const error = console.error;
      console.error = (...args) => {
        if (args[0].includes('ResizeObserver loop')) return;
        error.apply(console, args);
      };
      return observer;
    };

    return () => {
      window.ResizeObserver.prototype.constructor = resizeObserverError;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    let timeoutId: NodeJS.Timeout;

    const fetchData = async () => {
      if (!isVisible) return;

      const cacheKey = `${ticker}-${timeframe}`;
      const cachedData = sparklineCache[cacheKey];
      const now = Date.now();

      // Check if we have valid cached data
      if (cachedData && (now - cachedData.timestamp) < CACHE_DURATION) {
        setData(cachedData.data);
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        setError(null);
        const stockData = await marketDataService.getStockData(ticker, timeframe);
        
        if (isMounted) {
          // Add a small delay to prevent rapid updates
          timeoutId = setTimeout(() => {
            // Update cache
            sparklineCache[cacheKey] = {
              data: stockData,
              timestamp: now
            };
            
            setData(stockData);
            setIsLoading(false);
          }, 100);
        }
      } catch (err) {
        console.error(`Error fetching data for ${ticker}:`, err);
        if (isMounted) {
          setError('Failed to load chart');
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [ticker, timeframe, isVisible]);

  // Memoize the content to prevent unnecessary re-renders
  const renderContent = () => {
    if (isLoading) {
      return (
        <div 
          style={{ width, height }}
          className="animate-pulse bg-gray-800/50 rounded"
        />
      );
    }

    if (error || !data.length) {
      return (
        <div 
          style={{ width, height }}
          className="flex items-center justify-center bg-gray-800/50 rounded"
        >
          <span className="text-xs text-gray-500">No data</span>
        </div>
      );
    }

    const isPositive = data[data.length - 1]?.c >= data[0]?.c;

    return (
      <div style={{ width, height }} className="transition-opacity duration-200">
        <ResponsiveContainer width="100%" height="100%" debounce={50}>
          <LineChart data={data} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
            <YAxis 
              domain={['dataMin', 'dataMax']}
              hide 
            />
            <Line
              type="monotone"
              dataKey="c"
              stroke={isPositive ? '#22c55e' : '#ef4444'}
              strokeWidth={1.5}
              dot={false}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <div ref={containerRef} className="relative" style={{ minHeight: height }}>
      {renderContent()}
    </div>
  );
};

// Use React.memo with a more strict comparison
export default React.memo(StockSparkline, (prevProps, nextProps) => {
  return (
    prevProps.ticker === nextProps.ticker && 
    prevProps.timeframe === nextProps.timeframe &&
    prevProps.width === nextProps.width &&
    prevProps.height === nextProps.height
  );
});