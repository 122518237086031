import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Shield, ChevronRight, Lock, Database, UserCheck, Globe, RefreshCw } from 'lucide-react';

const sections = [
  {
    title: 'Information We Collect',
    icon: Database,
    content: `We collect information you provide directly to us, including:
    • Account information (name, email, password)
    • Optional phone number for verification and rewards
    • Trading preferences and chat history
    • Trade ideas and market research preferences
    • Usage data including interactions with our AI features
    • Technical data such as IP address and device information
    • Financial data for processing subscription payments`
  },
  {
    title: 'How We Use Your Information',
    icon: UserCheck,
    content: `We use the collected information to:
    • Power Prism AI market research and analysis
    • Automatically save your chats and trade ideas
    • Personalize research results based on your preferences 
    • Improve our platform and AI capabilities
    • Process credit purchases and subscription management
    • Communicate with you about updates and features
    • Ensure platform security and prevent fraud`
  },
  {
    title: 'Data Storage and Security',
    icon: Lock,
    content: `We implement industry-standard security measures to protect your data:
    • End-to-end encryption for sensitive data
    • Automatic chat storage with secure backup
    • Data export functionality for your information
    • Regular security audits and monitoring
    • Secure data centers with redundancy
    • Access controls and authentication
    • Regular backups and disaster recovery plans`
  },
  {
    title: 'Your Rights and Choices',
    icon: UserCheck,
    content: `You have the right to:
    • Access your personal data through our Settings page
    • Export all your data in JSON format
    • Control data sharing through Privacy Settings
    • Choose whether to enable analytics sharing
    • Control email notification preferences
    • Delete your account and associated data
    • Withdraw consent at any time`
  },
  {
    title: 'Third-Party Services',
    icon: Globe,
    content: `We may share data with trusted third parties for:
    • Anthropic AI (Claude 3.5 Sonnet) for market analysis
    • Payment processing for subscriptions
    • Financial data providers for market information
    • Analytics tools (with your consent)
    • Customer support
    
    All third parties must adhere to our strict privacy standards and data is shared only as necessary to provide our services.`
  },
  {
    title: 'Updates to Privacy Policy',
    icon: RefreshCw,
    content: `We may update this privacy policy periodically. We will notify you of any material changes via email or through our platform. Continued use of our services after changes constitutes acceptance of the updated policy.`
  }
];

const PrivacyPolicy = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      {/* Hero Section with gradient background */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-600/20 to-purple-600/20" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
          <div className="text-center">
            <Shield className={`w-16 h-16 mx-auto mb-6 ${
              isDarkMode ? 'text-blue-400' : 'text-blue-600'
            }`} />
            <h1 className={`text-4xl md:text-5xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Privacy Policy
            </h1>
            <p className={`text-xl max-w-3xl mx-auto ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              Last updated: March 10, 2025
            </p>
          </div>
        </div>
      </div>

      {/* Content with improved styling */}
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Introduction with more modern styling */}
        <div className={`mb-12 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
          <p className="mb-4 text-lg">
            At Newsroom, we take your privacy seriously. This Privacy Policy describes how we collect,
            use, and handle your personal information when you use our AI-powered market research platform.
          </p>
          <p className="text-lg">
            By using Newsroom's Prism AI and related services, you agree to the collection and use of information in accordance
            with this policy.
          </p>
        </div>

        {/* Quick Navigation with updated styling */}
        <div className={`mb-12 p-8 rounded-2xl ${
          isDarkMode ? 'bg-gray-800/80 backdrop-blur-xl border border-gray-700/50' : 'bg-white shadow-xl'
        }`}>
          <h2 className={`text-xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Quick Navigation
          </h2>
          <div className="grid md:grid-cols-2 gap-4">
            {sections.map((section, index) => (
              <a
                key={index}
                href={`#${section.title.toLowerCase().replace(/\s+/g, '-')}`}
                className={`flex items-center justify-between p-4 rounded-xl transition-all duration-200 ${
                  isDarkMode
                    ? 'bg-gray-700/50 hover:bg-gray-700 text-gray-200 border border-gray-600/50'
                    : 'hover:bg-gray-50 text-gray-700 border border-gray-200 shadow-sm'
                }`}
              >
                <div className="flex items-center space-x-3">
                  <div className={`p-2 rounded-lg ${isDarkMode ? 'bg-blue-500/10' : 'bg-blue-50'}`}>
                    <section.icon className={`w-5 h-5 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
                  </div>
                  <span className="font-medium">{section.title}</span>
                </div>
                <ChevronRight className="w-5 h-5 text-gray-400" />
              </a>
            ))}
          </div>
        </div>

        {/* Sections with enhanced styling */}
        <div className="space-y-16">
          {sections.map((section, index) => (
            <section
              key={index}
              id={section.title.toLowerCase().replace(/\s+/g, '-')}
              className={`scroll-mt-24 ${
                index !== sections.length - 1 ? 'pb-16 border-b' : ''
              } ${isDarkMode ? 'border-gray-700/50' : 'border-gray-200'}`}
            >
              <div className="flex items-center mb-6">
                <div className={`p-3 rounded-lg mr-4 ${isDarkMode ? 'bg-blue-500/10' : 'bg-blue-50'}`}>
                  <section.icon className={`w-6 h-6 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
                </div>
                <h2 className={`text-2xl md:text-3xl font-bold ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {section.title}
                </h2>
              </div>
              <div className={`prose max-w-none ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'} text-lg`}>
                {section.content.split('\n').map((paragraph, i) => (
                  <p key={i} className="whitespace-pre-line mb-4 leading-relaxed">
                    {paragraph}
                  </p>
                ))}
              </div>
            </section>
          ))}
        </div>

        {/* Contact Section */}
        <div className={`mt-16 p-8 rounded-2xl ${
          isDarkMode ? 'bg-gray-800/80 backdrop-blur-xl border border-gray-700/50' : 'bg-white shadow-xl'
        }`}>
          <div className="flex items-center mb-4">
            <div className={`p-2 rounded-lg mr-3 ${isDarkMode ? 'bg-blue-500/10' : 'bg-blue-50'}`}>
              <Shield className={`w-5 h-5 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            </div>
            <h2 className={`text-xl font-bold ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Questions About Our Privacy Policy?
            </h2>
          </div>
          <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            If you have any questions about this Privacy Policy or how we handle your data, please contact us at:
          </p>
          <a
            href="mailto:hello@news-room.ca"
            className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-xl hover:bg-blue-600 transition-colors"
          >
            hello@news-room.ca
          </a>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;