import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Search, Database, LineChart, Brain, Globe, Clock, ExternalLink,
  ChevronDown, ChevronUp, Activity, Zap, CheckCircle, Target,
  Loader2, BadgeInfo, RefreshCw, Filter, BarChart3
} from 'lucide-react';
import { ResearchProgressUpdate, ResearchSource } from '../../types/research';

interface ResearchProgressProps {
  progress: ResearchProgressUpdate;
  sources: ResearchSource[];
  isComplete?: boolean;
  isCollapsed?: boolean;
  onToggleCollapse?: () => void;
}

// Define research stages with icons and labels
const stages = [
  { id: 'QUERY_ANALYSIS', icon: Brain, label: 'Analyzing Query', color: 'blue' },
  { id: 'WEB_DISCOVERY', icon: Globe, label: 'Web Research', color: 'purple' },
  { id: 'TICKER_DISCOVERY', icon: Search, label: 'Finding Stocks', color: 'green' },
  { id: 'DATA_GATHERING', icon: Database, label: 'Gathering Data', color: 'orange' },
  { id: 'ANALYSIS', icon: LineChart, label: 'Analyzing Results', color: 'pink' },
];

const ResearchProgress: React.FC<ResearchProgressProps> = ({
  progress,
  sources,
  isComplete,
  isCollapsed,
  onToggleCollapse
}) => {
  const { isDarkMode } = useTheme();
  const [expandedStage, setExpandedStage] = useState<string | null>(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [estimatedTotalTime, setEstimatedTotalTime] = useState(0);
  const [searchQueryHistory, setSearchQueryHistory] = useState<string[]>([]);
  const [tickerHistory, setTickerHistory] = useState<string[]>([]);
  const [activeMetrics, setActiveMetrics] = useState<Record<string, number>>({});
  const startTimeRef = useRef<number>(Date.now());
  const containerRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [activeMobileTab, setActiveMobileTab] = useState<'progress'|'sources'|'metrics'>('progress');
  
  // Check for mobile view on resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // Format time for display
  const formatTime = (ms: number): string => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Track elapsed time
  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTimeRef.current;
      setElapsedTime(elapsed);
      
      // Estimate total time based on current progress
      if (progress.progress > 5) {
        const timePerPercent = elapsed / progress.progress;
        const estimated = timePerPercent * 100;
        setEstimatedTotalTime(estimated);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [progress.progress]);

  // Update history when progress details change
  useEffect(() => {
    // Add search query to history if it's new
    if (progress.details?.searchQuery && 
        !searchQueryHistory.includes(progress.details.searchQuery)) {
      setSearchQueryHistory(prev => [...prev, progress.details?.searchQuery!]);
    }
    
    // Add tickers to history if they're new
    if (progress.details?.tickers && progress.details.tickers.length > 0) {
      const newTickers = progress.details.tickers.filter(
        ticker => !tickerHistory.includes(ticker)
      );
      if (newTickers.length > 0) {
        setTickerHistory(prev => [...prev, ...newTickers]);
      }
    }
  }, [progress.details?.searchQuery, progress.details?.tickers]);

  // Find current stage info
  const currentStage = stages.find(s => s.id === progress.stage) || stages[0];
  
  // Calculate completed stages for progressbar
  const completedStageCount = (progress.completedStages || []).length;
  const stageProgress = Math.round((completedStageCount / stages.length) * 100);
  
  // Get source counts by type
  const sourcesByType = sources.reduce((acc, source) => {
    const type = source.type || 'UNKNOWN';
    acc[type] = (acc[type] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  // Display collapsed view
  if (isCollapsed) {
    return (
      <button
        onClick={onToggleCollapse}
        className="w-full cursor-pointer p-4 rounded-xl border backdrop-blur-sm 
          bg-gray-900/50 border-gray-700/60 hover:border-blue-500/50 transition-colors flex items-center justify-between"
      >
        <div className="flex items-center space-x-3">
          <div className="relative flex items-center justify-center">
            {/* Circular progress indicator */}
            <svg className="w-10 h-10 -rotate-90" viewBox="0 0 36 36">
              <circle
                cx="18"
                cy="18"
                r="16"
                className="fill-none stroke-gray-700/40"
                strokeWidth="2"
              />
              <circle
                cx="18"
                cy="18"
                r="16"
                className="fill-none stroke-blue-500"
                strokeWidth="2"
                strokeDasharray="100"
                strokeDashoffset={100 - progress.progress}
                strokeLinecap="round"
              />
            </svg>
            
            {/* Stage icon */}
            <div className="absolute inset-0 flex items-center justify-center">
              <currentStage.icon className="w-5 h-5 text-blue-400" />
            </div>
          </div>
          
          <div>
            <div className="flex items-center">
              <h3 className="text-sm font-medium text-white">{currentStage.label}</h3>
              <span className="ml-2 text-xs text-blue-400 font-semibold">{Math.round(progress.progress)}%</span>
            </div>
            <p className="text-xs text-gray-400">{progress.message}</p>
          </div>
        </div>
        
        {/* Show discovered tickers */}
        {tickerHistory.length > 0 && (
          <div className="flex items-center space-x-1.5">
            {tickerHistory.slice(0, 3).map(ticker => (
              <span key={ticker} className="px-1.5 py-0.5 text-xs rounded bg-green-500/20 text-green-400">
                {ticker}
              </span>
            ))}
            {tickerHistory.length > 3 && (
              <span className="px-1.5 py-0.5 text-xs rounded bg-gray-500/20 text-gray-400">
                +{tickerHistory.length - 3}
              </span>
            )}
          </div>
        )}
        
        <ChevronDown className="w-4 h-4 text-gray-400" />
      </button>
    );
  }
  
  // Mobile expanded view
  if (isMobile) {
    return (
      <motion.div
        ref={containerRef}
        className="rounded-xl border border-gray-700/70 overflow-hidden bg-gradient-to-br from-gray-900 to-gray-900/70 backdrop-blur-xl"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
      >
        {/* Header with title and collapse button */}
        <div className="flex items-center justify-between px-4 py-3 border-b border-gray-800">
          <div className="flex items-center space-x-2">
            <div className="p-1.5 rounded-lg bg-blue-500/10">
              <Activity className="w-4 h-4 text-blue-400" />
            </div>
            <div>
              <h2 className="text-base font-medium text-white flex items-center">
                Research Progress
                {isComplete ? (
                  <CheckCircle className="w-3.5 h-3.5 text-green-400 ml-1.5" />
                ) : (
                  <Loader2 className="w-3.5 h-3.5 text-blue-400 ml-1.5 animate-spin" />
                )}
              </h2>
            </div>
          </div>
          
          <button 
            onClick={onToggleCollapse}
            className="p-1.5 rounded-lg hover:bg-gray-800 transition-colors"
          >
            <ChevronUp className="w-4 h-4 text-gray-400" />
          </button>
        </div>
        
        {/* Progress circle and stage indicator */}
        <div className="flex flex-col items-center justify-center p-4 pb-2">
          <div className="relative w-28 h-28">
            {/* Overall progress ring */}
            <svg className="w-full h-full -rotate-90" viewBox="0 0 100 100">
              <circle
                cx="50"
                cy="50"
                r="45"
                className="stroke-current text-gray-700/30"
                strokeWidth="8"
                fill="none"
              />
              <circle
                cx="50"
                cy="50"
                r="45"
                className="stroke-current text-blue-500"
                strokeWidth="8"
                fill="none"
                strokeLinecap="round"
                strokeDasharray={`${2 * Math.PI * 45}`}
                strokeDashoffset={`${(2 * Math.PI * 45) * (1 - progress.progress / 100)}`}
              />
            </svg>
            
            {/* Center text */}
            <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
              <div className="text-3xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 text-transparent bg-clip-text">
                {Math.round(progress.progress)}%
              </div>
              <div className="text-xs text-blue-300 font-medium px-2 py-0.5 rounded-full bg-blue-500/10 mt-1">
                {currentStage.label}
              </div>
            </div>
          </div>
          
          {/* Message */}
          <p className="text-sm text-gray-300 text-center mt-3 px-2">{progress.message}</p>
          
          {/* Time indicator */}
          <div className="flex items-center mt-2 text-xs text-gray-400">
            <Clock className="w-3 h-3 mr-1" />
            <span>{formatTime(elapsedTime)}</span>
            
            {estimatedTotalTime > 0 && (
              <span className="ml-2 opacity-75">
                ~{formatTime(Math.max(0, estimatedTotalTime - elapsedTime))} remaining
              </span>
            )}
          </div>
        </div>
        
        {/* Tab navigation */}
        <div className="flex border-t border-gray-800 mt-2">
          <button 
            onClick={() => setActiveMobileTab('progress')}
            className={`flex-1 py-2 text-xs font-medium flex items-center justify-center ${
              activeMobileTab === 'progress' 
                ? 'text-blue-400 border-b-2 border-blue-500' 
                : 'text-gray-400'
            }`}
          >
            <Target className="w-3.5 h-3.5 mr-1" />
            Progress
          </button>
          
          <button 
            onClick={() => setActiveMobileTab('sources')}
            className={`flex-1 py-2 text-xs font-medium flex items-center justify-center ${
              activeMobileTab === 'sources' 
                ? 'text-blue-400 border-b-2 border-blue-500' 
                : 'text-gray-400'
            }`}
          >
            <Database className="w-3.5 h-3.5 mr-1" />
            Sources ({sources.length})
          </button>
          
          <button 
            onClick={() => setActiveMobileTab('metrics')}
            className={`flex-1 py-2 text-xs font-medium flex items-center justify-center ${
              activeMobileTab === 'metrics' 
                ? 'text-blue-400 border-b-2 border-blue-500' 
                : 'text-gray-400'
            }`}
          >
            <BarChart3 className="w-3.5 h-3.5 mr-1" />
            Metrics
          </button>
        </div>
        
        {/* Tab content */}
        <div className="p-4 max-h-64 overflow-y-auto">
          {/* Progress Tab */}
          {activeMobileTab === 'progress' && (
            <div className="space-y-4">
              {/* Stage progress */}
              <div className="relative flex items-center space-x-1 px-1 py-2">
                {stages.map((stage, index) => {
                  const isCompleted = (progress.completedStages || []).includes(stage.id);
                  const isActive = progress.stage === stage.id;
                  
                  return (
                    <React.Fragment key={stage.id}>
                      {/* Stage indicator */}
                      <div 
                        className={`flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center ${
                          isCompleted 
                            ? 'bg-green-500/20 border border-green-500/40' 
                            : isActive 
                              ? 'bg-blue-500/20 border border-blue-500/40'
                              : 'bg-gray-800 border border-gray-700'
                        }`}
                      >
                        <stage.icon 
                          className={`w-3 h-3 ${
                            isCompleted 
                              ? 'text-green-400' 
                              : isActive 
                                ? 'text-blue-400'
                                : 'text-gray-500'
                          }`} 
                        />
                      </div>
                      
                      {/* Connector line */}
                      {index < stages.length - 1 && (
                        <div 
                          className={`flex-1 h-0.5 ${
                            isCompleted ? 'bg-green-500/50' : 'bg-gray-700'
                          }`}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
              
              {/* Discovered tickers */}
              {tickerHistory.length > 0 && (
                <div>
                  <h3 className="text-xs text-green-300 mb-2 flex items-center">
                    <Target className="w-3.5 h-3.5 mr-1" />
                    Discovered Stocks
                  </h3>
                  
                  <div className="flex flex-wrap gap-1.5">
                    {tickerHistory.map((ticker) => (
                      <div
                        key={ticker}
                        className="px-2 py-1 rounded-md bg-green-500/10 border border-green-500/30 text-green-300 text-xs"
                      >
                        {ticker}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              
              {/* Research queries */}
              {searchQueryHistory.length > 0 && (
                <div>
                  <h3 className="text-xs text-purple-300 mb-2 flex items-center">
                    <Search className="w-3.5 h-3.5 mr-1" />
                    Research Queries
                  </h3>
                  
                  <div className="space-y-1.5 max-h-28 overflow-y-auto pr-1">
                    {searchQueryHistory.map((query, index) => (
                      <div
                        key={`query-${index}`}
                        className="flex items-center rounded-md bg-purple-500/10 border border-purple-500/30 text-purple-200 p-1.5 text-xs"
                      >
                        <Search className="w-3 h-3 mr-1.5 text-purple-300 flex-shrink-0" />
                        <span className="line-clamp-1">{query}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              
              {/* Research context */}
              <div>
                <h3 className="text-xs text-yellow-300 mb-2 flex items-center">
                  <BadgeInfo className="w-3.5 h-3.5 mr-1" />
                  Research Context
                </h3>
                
                <div className="bg-gray-800/80 rounded-md p-2.5 text-xs text-gray-300">
                  {progress.details?.rationale || progress.details?.currentAction || progress.details?.intent ||
                    "Determining optimal research approach..."}
                </div>
              </div>
            </div>
          )}
          
          {/* Sources Tab */}
          {activeMobileTab === 'sources' && (
            <div className="space-y-4">
              {/* Source type summary */}
              <div className="grid grid-cols-2 gap-2">
                <div className="bg-gray-800/80 rounded-md p-2 flex items-center gap-2">
                  <div className="w-7 h-7 rounded-full bg-blue-500/30 flex items-center justify-center">
                    <Globe className="w-3.5 h-3.5 text-blue-400" />
                  </div>
                  <div>
                    <p className="text-xs text-gray-400">Web</p>
                    <p className="text-sm font-medium text-white">{sourcesByType['WEB'] || 0}</p>
                  </div>
                </div>
                
                <div className="bg-gray-800/80 rounded-md p-2 flex items-center gap-2">
                  <div className="w-7 h-7 rounded-full bg-green-500/30 flex items-center justify-center">
                    <BarChart3 className="w-3.5 h-3.5 text-green-400" />
                  </div>
                  <div>
                    <p className="text-xs text-gray-400">Market</p>
                    <p className="text-sm font-medium text-white">{sourcesByType['MARKET_DATA'] || 0}</p>
                  </div>
                </div>
                
                <div className="bg-gray-800/80 rounded-md p-2 flex items-center gap-2">
                  <div className="w-7 h-7 rounded-full bg-yellow-500/30 flex items-center justify-center">
                    <Zap className="w-3.5 h-3.5 text-yellow-400" />
                  </div>
                  <div>
                    <p className="text-xs text-gray-400">News</p>
                    <p className="text-sm font-medium text-white">{sourcesByType['NEWS'] || 0}</p>
                  </div>
                </div>
                
                <div className="bg-gray-800/80 rounded-md p-2 flex items-center gap-2">
                  <div className="w-7 h-7 rounded-full bg-purple-500/30 flex items-center justify-center">
                    <Filter className="w-3.5 h-3.5 text-purple-400" />
                  </div>
                  <div>
                    <p className="text-xs text-gray-400">Other</p>
                    <p className="text-sm font-medium text-white">
                      {(sourcesByType['TECHNICAL'] || 0) + 
                        (sourcesByType['FUNDAMENTAL'] || 0) + 
                        (sourcesByType['UNKNOWN'] || 0)}
                    </p>
                  </div>
                </div>
              </div>
              
              {/* Recent sources list */}
              <div>
                <h3 className="text-xs text-blue-300 mb-2 flex items-center">
                  <Database className="w-3.5 h-3.5 mr-1" />
                  Latest Sources
                </h3>
                
                <div className="space-y-2 max-h-44 overflow-y-auto">
                  {sources.length > 0 ? (
                    sources.slice(-5).reverse().map((source, index) => (
                      <div
                        key={`source-${index}`}
                        className="flex items-start rounded-md bg-gray-800/80 border border-gray-700 p-1.5 text-xs"
                      >
                        <div className={`w-6 h-6 rounded-full flex-shrink-0 flex items-center justify-center mr-1.5 
                          ${source.type === 'WEB' 
                            ? 'bg-blue-500/20 text-blue-400' 
                            : source.type === 'NEWS' 
                              ? 'bg-yellow-500/20 text-yellow-400'
                              : source.type === 'MARKET_DATA'
                                ? 'bg-green-500/20 text-green-400'
                                : 'bg-purple-500/20 text-purple-400'
                          }`}
                        >
                          {source.type === 'WEB' && <Globe className="w-3 h-3" />}
                          {source.type === 'NEWS' && <Zap className="w-3 h-3" />}
                          {source.type === 'MARKET_DATA' && <BarChart3 className="w-3 h-3" />}
                          {!['WEB', 'NEWS', 'MARKET_DATA'].includes(source.type) && <Database className="w-3 h-3" />}
                        </div>
                        
                        <div className="overflow-hidden flex-1">
                          <p className="font-medium text-white truncate">{source.source}</p>
                          {source.title && (
                            <p className="text-gray-300 mt-0.5 truncate text-2xs">{source.title}</p>
                          )}
                          
                          {source.tickers && source.tickers.length > 0 && (
                            <div className="flex flex-wrap gap-1 mt-1">
                              {source.tickers.map(ticker => (
                                <span 
                                  key={ticker} 
                                  className="px-1 py-0.5 text-2xs rounded bg-green-500/10 text-green-400"
                                >
                                  {ticker}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                        
                        <div className="text-2xs text-gray-500 ml-1 whitespace-nowrap">
                          {new Date(source.timestamp).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-xs text-gray-400">No sources gathered yet</p>
                  )}
                </div>
              </div>
            </div>
          )}
          
          {/* Metrics Tab */}
          {activeMobileTab === 'metrics' && (
            <div className="space-y-4">
              {/* Time metrics */}
              <div>
                <h3 className="text-xs text-blue-300 mb-2 flex items-center">
                  <Clock className="w-3.5 h-3.5 mr-1" />
                  Time Metrics
                </h3>
                
                <div className="grid grid-cols-2 gap-2">
                  <div className="bg-gray-800/80 rounded-md p-2">
                    <p className="text-xs text-gray-400">Elapsed</p>
                    <p className="text-lg font-mono text-white">{formatTime(elapsedTime)}</p>
                  </div>
                  <div className="bg-gray-800/80 rounded-md p-2">
                    <p className="text-xs text-gray-400">Estimated</p>
                    <p className="text-lg font-mono text-white">{
                      estimatedTotalTime > 0 
                        ? formatTime(Math.max(0, estimatedTotalTime - elapsedTime))
                        : '--:--'
                    }</p>
                  </div>
                </div>
              </div>
              
              {/* Stage completion */}
              <div>
                <h3 className="text-xs text-purple-300 mb-2 flex items-center">
                  <Target className="w-3.5 h-3.5 mr-1" />
                  Stage Completion
                </h3>
                
                <div className="space-y-2">
                  {stages.map(stage => {
                    const isCompleted = (progress.completedStages || []).includes(stage.id);
                    const isActive = progress.stage === stage.id;
                    
                    return (
                      <div 
                        key={stage.id}
                        className={`flex items-center p-1.5 rounded-md ${
                          isActive ? 'bg-blue-500/10 border border-blue-500/30' : 
                          isCompleted ? 'bg-green-500/10 border border-green-500/30' : 
                          'bg-gray-800 border border-gray-700'
                        }`}
                      >
                        <div className="w-6 h-6 rounded-full flex items-center justify-center mr-2">
                          <stage.icon 
                            className={`w-3.5 h-3.5 ${
                              isCompleted ? 'text-green-400' : 
                              isActive ? 'text-blue-400' : 
                              'text-gray-500'
                            }`} 
                          />
                        </div>
                        <div className="flex-1">
                          <p className={`text-xs font-medium ${
                            isCompleted ? 'text-green-300' :
                            isActive ? 'text-blue-300' :
                            'text-gray-400'
                          }`}>{stage.label}</p>
                        </div>
                        <div>
                          {isCompleted && <CheckCircle className="w-3.5 h-3.5 text-green-400" />}
                          {isActive && <Loader2 className="w-3.5 h-3.5 text-blue-400 animate-spin" />}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </motion.div>
    );
  }

  // Desktop expanded view (original code)
  return (
    <motion.div 
      ref={containerRef}
      className="rounded-2xl border border-gray-700/70 overflow-hidden bg-gradient-to-br from-gray-900 to-gray-900/70 backdrop-blur-xl"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
    >
      {/* Header with title and collapse button */}
      <div className="flex items-center justify-between px-6 py-4 border-b border-gray-800">
        <div className="flex items-center space-x-3">
          <div className="p-2 rounded-lg bg-blue-500/10">
            <Activity className="w-5 h-5 text-blue-400" />
          </div>
          <div>
            <h2 className="text-lg font-medium text-white flex items-center space-x-2">
              <span>Research in Progress</span>
              {isComplete ? (
                <CheckCircle className="w-4 h-4 text-green-400 ml-2" />
              ) : (
                <Loader2 className="w-4 h-4 text-blue-400 ml-2 animate-spin" />
              )}
            </h2>
            <p className="text-sm text-gray-400">{progress.message}</p>
          </div>
        </div>
        
        <button 
          onClick={onToggleCollapse}
          className="p-2 rounded-lg hover:bg-gray-800 transition-colors"
        >
          <ChevronUp className="w-5 h-5 text-gray-400" />
        </button>
      </div>
      
      {/* Main content grid */}
      <div className="grid grid-cols-3 gap-6 p-6">
        {/* Column 1: Progress visual */}
        <div className="space-y-6">
          {/* Main progress circle */}
          <div className="bg-gray-800/60 rounded-xl p-5 border border-gray-700/50">
            <div className="flex items-center justify-center">
              <div className="relative w-48 h-48">
                {/* Overall progress ring */}
                <svg className="w-full h-full -rotate-90" viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="45"
                    className="stroke-current text-gray-700/30"
                    strokeWidth="8"
                    fill="none"
                  />
                  <circle
                    cx="50"
                    cy="50"
                    r="45"
                    className="stroke-current text-blue-500"
                    strokeWidth="8"
                    fill="none"
                    strokeLinecap="round"
                    strokeDasharray={`${2 * Math.PI * 45}`}
                    strokeDashoffset={`${(2 * Math.PI * 45) * (1 - progress.progress / 100)}`}
                  />
                  
                  {/* Inner stages progress ring */}
                  <circle
                    cx="50"
                    cy="50"
                    r="32"
                    className="stroke-current text-gray-700/30"
                    strokeWidth="6"
                    fill="none"
                  />
                  <circle
                    cx="50"
                    cy="50"
                    r="32"
                    className="stroke-current text-purple-500"
                    strokeWidth="6"
                    fill="none"
                    strokeLinecap="round"
                    strokeDasharray={`${2 * Math.PI * 32}`}
                    strokeDashoffset={`${(2 * Math.PI * 32) * (1 - stageProgress / 100)}`}
                  />
                </svg>
                
                {/* Center text */}
                <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
                  <div className="text-4xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 text-transparent bg-clip-text">
                    {Math.round(progress.progress)}%
                  </div>
                  <div className="text-sm text-blue-300 font-medium px-3 py-1 rounded-full bg-blue-500/10 mt-2">
                    {currentStage.label}
                  </div>
                </div>
              </div>
            </div>
            
            {/* Stage indicators */}
            <div className="flex justify-center mt-8 space-x-3">
              {stages.map((stage, index) => {
                const isCompleted = (progress.completedStages || []).includes(stage.id);
                const isActive = progress.stage === stage.id;
                
                return (
                  <div 
                    key={stage.id}
                    className={`relative cursor-pointer group`}
                    onClick={() => setExpandedStage(expandedStage === stage.id ? null : stage.id)}
                  >
                    <div className={`w-8 h-8 rounded-full flex items-center justify-center transition-colors
                      ${isCompleted 
                        ? 'bg-green-500/20 border border-green-500/40' 
                        : isActive 
                          ? 'bg-blue-500/20 border border-blue-500/40'
                          : 'bg-gray-800 border border-gray-700'
                      }`}
                    >
                      <stage.icon 
                        className={`w-4 h-4 ${
                          isCompleted 
                            ? 'text-green-400' 
                            : isActive 
                              ? 'text-blue-400'
                              : 'text-gray-500'
                        }`} 
                      />
                    </div>
                    
                    {/* Stage tooltip */}
                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
                      <div className="bg-gray-800 text-white text-xs px-2 py-1 rounded whitespace-nowrap">
                        {stage.label}
                      </div>
                    </div>
                    
                    {/* Connector line (except last item) */}
                    {index < stages.length - 1 && (
                      <div className={`absolute top-1/2 left-full w-3 h-0.5 transform -translate-y-1/2
                        ${isCompleted ? 'bg-green-500/50' : 'bg-gray-700'}`}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          
          {/* Time information */}
          <div className="bg-gray-800/60 rounded-xl p-4 border border-gray-700/50">
            <h3 className="text-sm text-blue-300 mb-3 flex items-center">
              <Clock className="w-4 h-4 mr-2" />
              Time Metrics
            </h3>
            
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-gray-800/80 rounded-lg p-3">
                <p className="text-xs text-gray-400 mb-1">Elapsed</p>
                <p className="text-xl font-mono text-white">{formatTime(elapsedTime)}</p>
              </div>
              <div className="bg-gray-800/80 rounded-lg p-3">
                <p className="text-xs text-gray-400 mb-1">Estimated</p>
                <p className="text-xl font-mono text-white">{
                  estimatedTotalTime > 0 
                    ? formatTime(Math.max(0, estimatedTotalTime - elapsedTime))
                    : '--:--'
                }</p>
              </div>
            </div>
          </div>
        </div>
        
        {/* Column 2: Discovered data */}
        <div className="space-y-6">
          {/* Discovered tickers */}
          <div className="bg-gray-800/60 rounded-xl p-4 border border-gray-700/50">
            <h3 className="text-sm text-green-300 mb-3 flex items-center">
              <Target className="w-4 h-4 mr-2" />
              Discovered Stocks
            </h3>
            
            <div className="flex flex-wrap gap-2">
              {tickerHistory.length > 0 ? (
                tickerHistory.map((ticker) => (
                  <div
                    key={ticker}
                    className="px-2.5 py-1.5 rounded-lg bg-green-500/10 border border-green-500/30 text-green-300 text-sm"
                  >
                    {ticker}
                  </div>
                ))
              ) : (
                <p className="text-sm text-gray-400">No stocks identified yet</p>
              )}
            </div>
          </div>
          
          {/* Research queries */}
          <div className="bg-gray-800/60 rounded-xl p-4 border border-gray-700/50">
            <h3 className="text-sm text-purple-300 mb-3 flex items-center">
              <Search className="w-4 h-4 mr-2" />
              Research Queries
            </h3>
            
            <div className="space-y-2 max-h-40 overflow-y-auto pr-2 custom-scrollbar">
              {searchQueryHistory.length > 0 ? (
                searchQueryHistory.map((query, index) => (
                  <div
                    key={`query-${index}`}
                    className="flex items-center rounded-lg bg-purple-500/10 border border-purple-500/30 text-purple-200 p-2 text-sm"
                  >
                    <Search className="w-3.5 h-3.5 mr-2 text-purple-300 flex-shrink-0" />
                    <span className="line-clamp-1">{query}</span>
                  </div>
                ))
              ) : (
                <p className="text-sm text-gray-400">No search queries executed yet</p>
              )}
            </div>
          </div>
          
          {/* Context information */}
          <div className="bg-gray-800/60 rounded-xl p-4 border border-gray-700/50">
            <h3 className="text-sm text-yellow-300 mb-3 flex items-center">
              <BadgeInfo className="w-4 h-4 mr-2" />
              Research Context
            </h3>
            
            <div className="bg-gray-800/80 rounded-lg p-3 max-h-32 overflow-y-auto text-sm text-gray-300">
              {progress.details?.rationale || progress.details?.currentAction || progress.details?.intent ||
                "Determining optimal research approach..."}
            </div>
          </div>
        </div>
        
        {/* Column 3: Sources information */}
        <div className="space-y-6">
          {/* Sources by type */}
          <div className="bg-gray-800/60 rounded-xl p-4 border border-gray-700/50">
            <h3 className="text-sm text-orange-300 mb-3 flex items-center">
              <Database className="w-4 h-4 mr-2" />
              Sources ({sources.length})
            </h3>
            
            <div className="grid grid-cols-2 gap-3">
              <div className="bg-gray-800/80 rounded-lg p-3 flex items-center gap-2">
                <div className="w-8 h-8 rounded-full bg-blue-500/30 flex items-center justify-center">
                  <Globe className="w-4 h-4 text-blue-400" />
                </div>
                <div>
                  <p className="text-xs text-gray-400">Web</p>
                  <p className="text-lg font-medium text-white">{sourcesByType['WEB'] || 0}</p>
                </div>
              </div>
              
              <div className="bg-gray-800/80 rounded-lg p-3 flex items-center gap-2">
                <div className="w-8 h-8 rounded-full bg-green-500/30 flex items-center justify-center">
                  <BarChart3 className="w-4 h-4 text-green-400" />
                </div>
                <div>
                  <p className="text-xs text-gray-400">Market</p>
                  <p className="text-lg font-medium text-white">{sourcesByType['MARKET_DATA'] || 0}</p>
                </div>
              </div>
              
              <div className="bg-gray-800/80 rounded-lg p-3 flex items-center gap-2">
                <div className="w-8 h-8 rounded-full bg-yellow-500/30 flex items-center justify-center">
                  <Zap className="w-4 h-4 text-yellow-400" />
                </div>
                <div>
                  <p className="text-xs text-gray-400">News</p>
                  <p className="text-lg font-medium text-white">{sourcesByType['NEWS'] || 0}</p>
                </div>
              </div>
              
              <div className="bg-gray-800/80 rounded-lg p-3 flex items-center gap-2">
                <div className="w-8 h-8 rounded-full bg-purple-500/30 flex items-center justify-center">
                  <Filter className="w-4 h-4 text-purple-400" />
                </div>
                <div>
                  <p className="text-xs text-gray-400">Other</p>
                  <p className="text-lg font-medium text-white">
                    {(sourcesByType['TECHNICAL'] || 0) + 
                      (sourcesByType['FUNDAMENTAL'] || 0) + 
                      (sourcesByType['UNKNOWN'] || 0)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          {/* Recent sources */}
          <div className="bg-gray-800/60 rounded-xl p-4 border border-gray-700/50">
            <h3 className="text-sm text-blue-300 mb-3 flex items-center">
              <Globe className="w-4 h-4 mr-2" />
              Recent Sources
            </h3>
            
            <div className="space-y-2 max-h-64 overflow-y-auto pr-2 custom-scrollbar">
              {sources.length > 0 ? (
                sources.slice(-5).reverse().map((source, index) => (
                  <div
                    key={`source-${index}`}
                    className="flex items-start rounded-lg bg-gray-800/80 border border-gray-700 p-2 text-sm"
                  >
                    <div className={`w-7 h-7 rounded-full flex-shrink-0 flex items-center justify-center mr-2 
                      ${source.type === 'WEB' 
                        ? 'bg-blue-500/20 text-blue-400' 
                        : source.type === 'NEWS' 
                          ? 'bg-yellow-500/20 text-yellow-400'
                          : source.type === 'MARKET_DATA'
                            ? 'bg-green-500/20 text-green-400'
                            : 'bg-purple-500/20 text-purple-400'
                      }`}
                    >
                      {source.type === 'WEB' && <Globe className="w-3.5 h-3.5" />}
                      {source.type === 'NEWS' && <Zap className="w-3.5 h-3.5" />}
                      {source.type === 'MARKET_DATA' && <BarChart3 className="w-3.5 h-3.5" />}
                      {!['WEB', 'NEWS', 'MARKET_DATA'].includes(source.type) && <Database className="w-3.5 h-3.5" />}
                    </div>
                    
                    <div className="overflow-hidden flex-1">
                      <div className="flex items-center justify-between">
                        <p className="font-medium text-white truncate">{source.source}</p>
                        <div className="text-xs text-gray-400 whitespace-nowrap">
                          {new Date(source.timestamp).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                        </div>
                      </div>
                      
                      {source.title && (
                        <p className="text-xs text-gray-300 mt-1 truncate">{source.title}</p>
                      )}
                      
                      {source.tickers && source.tickers.length > 0 && (
                        <div className="flex flex-wrap gap-1 mt-1.5">
                          {source.tickers.map(ticker => (
                            <span 
                              key={ticker} 
                              className="px-1.5 py-0.5 text-xs rounded bg-green-500/10 text-green-400"
                            >
                              {ticker}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-sm text-gray-400">No sources gathered yet</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ResearchProgress;