import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Download,
  File,
  FileJson,
  FileText,
  Files,
  Settings,
  Clock,
  ChevronRight,
  Check,
  Calendar,
  ArrowRight,
  Shield,
  Info,
  AlertTriangle
} from 'lucide-react';

const DataExport = () => {
  const { isDarkMode } = useTheme();

  const exportFormats = [
    {
      title: 'JSON Format',
      description: 'Complete structured data with all relationships preserved',
      icon: FileJson,
      features: [
        'Comprehensive data structure',
        'Preserves nested relationships',
        'Ideal for developers and data analysis',
        'Contains metadata and timestamps',
        'Supports full account restoration'
      ],
      fileSize: 'Larger file size, complete data'
    },
    {
      title: 'CSV Format',
      description: 'Tabular data export compatible with spreadsheet applications',
      icon: Files,
      features: [
        'Easy to open in Excel or Google Sheets',
        'Simple tabular format',
        'Separate files for different data types',
        'Ideal for manual analysis',
        'Compact file size'
      ],
      fileSize: 'Smaller size, simplified structure'
    },
    {
      title: 'PDF Report',
      description: 'Human-readable summary of your account data',
      icon: FileText,
      features: [
        'Professionally formatted document',
        'Easy to read account summary',
        'Includes activity timelines',
        'Charts and visualizations included',
        'Ideal for record-keeping'
      ],
      fileSize: 'Medium size, visualization focused'
    }
  ];

  const exportSteps = [
    {
      title: 'Access Settings',
      description: 'Navigate to your account settings page',
      steps: [
        'Log in to your Prism account',
        'Click on your profile picture in the top right',
        'Select "Settings" from the dropdown menu'
      ]
    },
    {
      title: 'Find Data Export',
      description: 'Locate the data export section',
      steps: [
        'In the left sidebar, select "Privacy & Data"',
        'Scroll down to find the "Data Export" section',
        'Review the available export options'
      ]
    },
    {
      title: 'Select Format',
      description: 'Choose your preferred data format',
      steps: [
        'Select one or more export formats (JSON, CSV, PDF)',
        'Choose full export or select specific data categories',
        'Set any date range filters if needed'
      ]
    },
    {
      title: 'Generate & Download',
      description: 'Create and retrieve your data export',
      steps: [
        'Click "Generate Export" button',
        'Wait for the export to be prepared (may take a few minutes)',
        'Click "Download" when the export is ready',
        'Save the file to your preferred location'
      ]
    }
  ];

  const dataCategories = [
    {
      title: 'Account Information',
      description: 'Your basic account details and settings',
      includes: [
        'Profile information',
        'Email and contact details',
        'Account preferences',
        'Subscription information',
        'Security settings'
      ]
    },
    {
      title: 'Financial Research',
      description: 'Your saved research and analysis',
      includes: [
        'Chat history with Prism AI',
        'Research sessions and queries',
        'Saved research findings',
        'Custom research notes',
        'Source references'
      ]
    },
    {
      title: 'Watchlists & Portfolios',
      description: 'Your tracked stocks and financial instruments',
      includes: [
        'Watchlist configurations',
        'Saved stocks and assets',
        'Custom categories and tags',
        'Price alerts and notifications',
        'Performance tracking data'
      ]
    },
    {
      title: 'Canvas Documents',
      description: 'Your charts, annotations and visual analysis',
      includes: [
        'Saved canvas documents',
        'Chart annotations',
        'Custom indicators',
        'Sharing permissions',
        'Version history'
      ]
    }
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Download className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Data Export
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                How to export, download, and manage your personal data
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              Prism makes it easy to export your personal data at any time. Whether you need a backup of your research, 
              want to analyze your data in another tool, or simply wish to have a local copy of your information, 
              our data export tools provide flexible options to meet your needs. This guide explains how to export 
              your data, what formats are available, and what information is included in each export.
            </p>
          </div>

          {/* Export Formats */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Available Export Formats
          </h2>

          <div className="space-y-8 mb-16">
            {exportFormats.map((format, index) => {
              const Icon = format.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    } mr-4`}>
                      <Icon className={`w-6 h-6 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <div>
                      <h3 className={`text-xl font-bold ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {format.title}
                      </h3>
                    </div>
                  </div>
                  
                  <p className={`mb-6 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {format.description}
                  </p>
                  
                  <div className="grid md:grid-cols-2 gap-8">
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Features:</h4>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {format.features.map((feature, i) => (
                          <li key={i} className="flex items-start">
                            <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div className={`rounded-lg p-4 ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                    }`}>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>File Size:</h4>
                      <p className={`${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {format.fileSize}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* How to Export */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            How to Export Your Data
          </h2>

          <div className="mb-16">
            {exportSteps.map((method, index) => (
              <div key={index} className={`p-6 rounded-xl border mb-6 ${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
              }`}>
                <div className="flex items-center mb-4">
                  <div className={`w-8 h-8 rounded-full flex items-center justify-center text-white ${
                    isDarkMode ? 'bg-blue-600' : 'bg-blue-500'
                  } mr-4`}>
                    {index + 1}
                  </div>
                  <h3 className={`text-xl font-bold ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {method.title}
                  </h3>
                </div>
                
                <p className={`mb-6 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {method.description}
                </p>
                
                <div className="grid md:grid-cols-2 gap-8">
                  <div>
                    <h4 className={`text-sm font-medium mb-3 ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>Steps:</h4>
                    <ol className={`space-y-2 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-600'
                    }`}>
                      {method.steps.map((step, i) => (
                        <li key={i} className="flex items-start">
                          <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">{i+1}</div>
                          <span>{step}</span>
                        </li>
                      ))}
                    </ol>
                  </div>
                  
                  <div className={`rounded-lg overflow-hidden border ${
                    isDarkMode ? 'border-gray-700' : 'border-gray-200'
                  }`}>
                    <div className={`h-40 flex items-center justify-center ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                    }`}>
                      <p className={`text-sm ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        {method.title} illustration
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* What's Included */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            What's Included in Your Export
          </h2>

          <div className={`grid md:grid-cols-2 gap-6 mb-16`}>
            {dataCategories.map((category, index) => (
              <div key={index} className={`p-6 rounded-xl border ${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
              }`}>
                <h3 className={`text-lg font-bold mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {category.title}
                </h3>
                <p className={`mb-4 text-sm ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {category.description}
                </p>
                <h4 className={`text-sm font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Includes:
                </h4>
                <ul className={`space-y-1 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {category.includes.map((item, i) => (
                    <li key={i} className="flex items-start text-sm">
                      <Check className="w-3.5 h-3.5 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Scheduled Exports */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Scheduled Exports
          </h2>

          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="flex items-start mb-6">
              <Calendar className={`w-6 h-6 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Automated Data Exports</h3>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  Pro users can set up regular scheduled exports to automatically backup their data at set intervals:
                </p>
                <ul className={`space-y-2 mb-4 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Schedule daily, weekly, or monthly exports</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Have exports automatically emailed to you</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Set up cloud storage integration (Google Drive, Dropbox)</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Configure retention policies for exports</span>
                  </li>
                </ul>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  To configure scheduled exports, go to Settings → Privacy & Data → Scheduled Exports.
                </p>
              </div>
            </div>
          </div>

          {/* Important Notice */}
          <div className={`p-6 rounded-xl border ${
            isDarkMode ? 'bg-blue-900/20 border-blue-800/30' : 'bg-blue-50 border-blue-100'
          } mb-12`}>
            <div className="flex items-start">
              <Info className={`w-6 h-6 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Export Security Note</h3>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  Data exports contain sensitive personal information. Always store downloaded exports securely, 
                  and consider encrypting the files if you plan to keep them for an extended period. 
                  Remember that anyone with access to your export files will be able to view your personal data.
                </p>
              </div>
            </div>
          </div>

          {/* Related Documentation */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Related Documentation
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <a 
              href="/docs/privacy"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Privacy & Data Protection
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Learn about how we protect your data and privacy
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
            
            <a 
              href="/docs/account-protection"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Account Protection
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Best practices for securing your account
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default DataExport;