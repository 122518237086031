import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Code, 
  GitBranch, 
  Webhook, 
  Bell, 
  Link, 
  Server, 
  Shield,
  CheckCircle,
  XCircle,
  AlertTriangle
} from 'lucide-react';

const Webhooks = () => {
  const { isDarkMode } = useTheme();

  const webhookEvents = [
    {
      name: 'widget.created',
      description: 'Triggered when a new widget is created',
      payload: {
        id: 'wgt_123456',
        type: 'CHART',
        created_at: '2024-03-28T15:42:11Z',
        status: 'active'
      }
    },
    {
      name: 'market.alert',
      description: 'Triggered when a market alert condition is met',
      payload: {
        id: 'alrt_789012',
        ticker: 'AAPL',
        condition: 'price_above',
        threshold: 185.50,
        current_value: 186.20,
        triggered_at: '2024-03-28T14:30:22Z'
      }
    },
    {
      name: 'research.completed',
      description: 'Triggered when an AI research request completes',
      payload: {
        id: 'rsch_345678',
        query: 'Analyze TSLA earnings report',
        completed_at: '2024-03-28T16:15:33Z',
        duration_ms: 12450,
        result_url: 'https://api.news-room.ca/research/rsch_345678'
      }
    }
  ];

  const securityBestPractices = [
    'Store webhook secrets securely using environment variables',
    'Validate the signature of incoming webhook requests',
    'Implement retry logic for failed webhook deliveries',
    'Set up monitoring for webhook deliveries and failures',
    'Use HTTPS endpoints for receiving webhooks'
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <GitBranch className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Webhooks
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Receive real-time event notifications from the Newsroom platform
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              Webhooks allow your application to receive real-time notifications when specific events occur within 
              the Newsroom platform. Instead of continuously polling our API, webhooks push data to your 
              application as events happen, making your integration more efficient and responsive.
            </p>
          </div>

          {/* Getting Started */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Getting Started with Webhooks
          </h2>
          
          <div className="grid md:grid-cols-2 gap-8 mb-12">
            <div className={`p-6 rounded-lg border ${
              isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
            }`}>
              <h3 className={`flex items-center text-xl font-bold mb-4 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                <Bell className="w-5 h-5 mr-2 text-blue-500" />
                Setting Up Webhooks
              </h3>
              
              <ol className={`space-y-4 list-decimal list-inside ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                <li>Navigate to your <strong>Developer Settings</strong> in the dashboard</li>
                <li>Go to the <strong>Webhooks</strong> tab</li>
                <li>Click <strong>Add Endpoint</strong></li>
                <li>Enter your endpoint URL and select the events you want to receive</li>
                <li>Generate a webhook secret for verifying webhook signatures</li>
                <li>Click <strong>Save</strong> to activate your webhook</li>
              </ol>
            </div>

            <div className={`p-6 rounded-lg border ${
              isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
            }`}>
              <h3 className={`flex items-center text-xl font-bold mb-4 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                <Link className="w-5 h-5 mr-2 text-blue-500" />
                Handling Webhook Events
              </h3>
              
              <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                When an event occurs, we'll send an HTTP POST request to your endpoint with a JSON payload 
                containing event details. Your server should:
              </p>
              
              <ul className={`space-y-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                <li className="flex items-start">
                  <CheckCircle className="w-4 h-4 text-green-500 mr-2 mt-1 flex-shrink-0" />
                  <span>Verify the webhook signature using your secret</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="w-4 h-4 text-green-500 mr-2 mt-1 flex-shrink-0" />
                  <span>Return a 2xx response quickly to acknowledge receipt</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="w-4 h-4 text-green-500 mr-2 mt-1 flex-shrink-0" />
                  <span>Process the event asynchronously if needed</span>
                </li>
              </ul>
            </div>
          </div>

          {/* Event Types */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Webhook Event Types
          </h2>
          
          <div className="space-y-6 mb-12">
            {webhookEvents.map((event, index) => (
              <div key={index} className={`p-6 rounded-lg border ${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
              }`}>
                <h3 className={`font-bold mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {event.name}
                </h3>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  {event.description}
                </p>
                <div className="mb-2 text-sm font-medium text-blue-500">Example payload:</div>
                <pre className={`p-4 rounded-lg overflow-x-auto ${
                  isDarkMode ? 'bg-gray-900' : 'bg-gray-100'
                }`}>
                  <code className={isDarkMode ? 'text-gray-300' : 'text-gray-800'}>
                    {JSON.stringify(event.payload, null, 2)}
                  </code>
                </pre>
              </div>
            ))}
          </div>

          {/* Security */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Webhook Security
          </h2>
          
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <h3 className={`flex items-center text-xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              <Shield className="w-5 h-5 mr-2 text-blue-500" />
              Verifying Webhook Signatures
            </h3>
            
            <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Each webhook request includes a <code>X-Newsroom-Signature</code> header containing an HMAC 
              signature of the request body using your webhook secret. Verify this signature to ensure the 
              webhook came from Newsroom.
            </p>
            
            <div className="mt-6">
              <div className="mb-2 text-sm font-medium text-blue-500">Example verification code (Node.js):</div>
              <pre className={`p-4 rounded-lg overflow-x-auto ${
                isDarkMode ? 'bg-gray-900' : 'bg-gray-100'
              }`}>
                <code className={isDarkMode ? 'text-gray-300' : 'text-gray-800'}>
{`const crypto = require('crypto');

// Your webhook secret from Newsroom dashboard
const webhookSecret = process.env.NEWSROOM_WEBHOOK_SECRET;

function verifyWebhookSignature(signature, payload) {
  const hmac = crypto.createHmac('sha256', webhookSecret);
  const expectedSignature = hmac.update(payload).digest('hex');
  return crypto.timingSafeEqual(
    Buffer.from(signature),
    Buffer.from(expectedSignature)
  );
}

// In your webhook handler
app.post('/webhook', (req, res) => {
  const signature = req.headers['x-newsroom-signature'];
  const payload = JSON.stringify(req.body);
  
  if (!verifyWebhookSignature(signature, payload)) {
    return res.status(401).send('Invalid signature');
  }
  
  // Process the webhook event
  // ...
  
  res.status(200).send('Webhook received');
});`}
                </code>
              </pre>
            </div>
            
            <h4 className={`font-medium mt-6 mb-3 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Security Best Practices
            </h4>
            
            <ul className={`space-y-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              {securityBestPractices.map((practice, i) => (
                <li key={i} className="flex items-start">
                  <Shield className="w-4 h-4 text-blue-500 mr-2 mt-1 flex-shrink-0" />
                  <span>{practice}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Troubleshooting */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Troubleshooting
          </h2>
          
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="space-y-6">
              <div>
                <h3 className={`flex items-center font-bold mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  <XCircle className="w-5 h-5 mr-2 text-red-500" />
                  Webhook delivery failures
                </h3>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  If webhook deliveries are failing, check that your endpoint is publicly accessible and 
                  returns a 2xx status code promptly. You can view delivery logs in the Developer Settings 
                  dashboard.
                </p>
              </div>
              
              <div>
                <h3 className={`flex items-center font-bold mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  <AlertTriangle className="w-5 h-5 mr-2 text-yellow-500" />
                  Signature verification issues
                </h3>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  If you're having issues with signature verification, ensure you're using the correct webhook 
                  secret and that you're computing the HMAC on the raw request body before it's parsed.
                </p>
              </div>
              
              <div>
                <h3 className={`flex items-center font-bold mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  <Server className="w-5 h-5 mr-2 text-purple-500" />
                  Testing webhooks locally
                </h3>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  For local development, we recommend using tools like ngrok or Webhook.site to create a publicly 
                  accessible URL that forwards to your local development environment.
                </p>
              </div>
            </div>
          </div>

          {/* Rate Limits */}
          <div className={`p-6 rounded-xl border border-blue-100 ${
            isDarkMode ? 'bg-blue-900/20 border-blue-800/30' : 'bg-blue-50'
          } mb-12`}>
            <div className="flex items-start">
              <Bell className={`w-6 h-6 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Important Note</h3>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  Webhooks are only available on Pro and Enterprise plans. Standard plan users can upgrade 
                  through the billing settings to access webhook functionality.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Webhooks;