// src/hooks/useIdleDetection.ts
import { useState, useEffect, useCallback } from 'react';

export function useIdleDetection(timeoutInMs = 20 * 60 * 1000) {
  const [isIdle, setIsIdle] = useState(false);
  
  const resetTimer = useCallback(() => {
    if (isIdle) return; // Don't reset if already idle
    
    localStorage.setItem('lastActivity', Date.now().toString());
  }, [isIdle]);

  const checkIdle = useCallback(() => {
    const lastActivity = parseInt(localStorage.getItem('lastActivity') || '0', 10);
    const now = Date.now();
    
    if (now - lastActivity > timeoutInMs) {
      setIsIdle(true);
    }
  }, [timeoutInMs]);
  
  const handleUserActivity = useCallback(() => {
    resetTimer();
  }, [resetTimer]);

  const resumeActivity = useCallback(() => {
    resetTimer();
    setIsIdle(false);
    window.location.reload();
  }, [resetTimer]);

  useEffect(() => {
    // Set initial activity time
    if (!isIdle) resetTimer();
    
    // Set up events to track user activity
    const activityEvents = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
    
    activityEvents.forEach(event => {
      window.addEventListener(event, handleUserActivity, { passive: true });
    });
    
    // Set up interval to check for idle state
    const interval = setInterval(checkIdle, 10000); // Check every 10 seconds
    
    return () => {
      activityEvents.forEach(event => {
        window.removeEventListener(event, handleUserActivity);
      });
      clearInterval(interval);
    };
  }, [handleUserActivity, checkIdle, resetTimer, isIdle]);

  return { isIdle, resumeActivity };
}