import React, { useState, useEffect } from 'react';
import { SavedItemType, useWatchlists, WatchList } from '../../hooks/useWatchlists';
import { 
  Bookmark,
  Plus,
  MoreVertical,
  ExternalLink,
  Edit,
  Trash,
  MessageSquare,
  ChevronRight,
  Search,
  AlertTriangle,
  ListFilter,
  FilterX,
  ArrowUp,
  ArrowDown,
  Zap,
  Clock,
  Box
} from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import { WatchlistMenu } from '../../components/market/WatchlistMenu';
import { CreateListModal } from '../../components/market/CreateListModal';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import StockSparkline from './StockSparkline';
import { usePriceData } from '../../hooks/usePriceData';
import { motion, AnimatePresence } from 'framer-motion';

interface ListItemProps {
  item: {
    id: string | number;
    title: string;
    identifier: string;
    type: SavedItemType;
    metadata?: Record<string, any>;
    lists?: Array<{ id: string | number }>;
  };
  onClick?: () => void;
}

// Enhanced ListItem component with better responsiveness
const ListItem: React.FC<ListItemProps> = ({ item, onClick }) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const { removeFromList, deleteItem } = useWatchlists();
  const [showActions, setShowActions] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { data: priceData, isLoading: isPriceLoading } = usePriceData(item.identifier);

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      if (item.lists?.[0]) {
        // If item is in a list, remove from list
        await removeFromList(item.identifier, String(item.lists[0].id));
      } else {
        // If item is individually saved, delete it completely
        await deleteItem(item.identifier);
      }
      setShowActions(false);
      setShowConfirmation(false);
    } catch (error) {
      console.error('Failed to delete item:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={`group relative flex flex-wrap md:flex-nowrap items-center justify-between p-3 md:p-4 rounded-lg transition-all hover:shadow-md
        ${isDarkMode ? 'hover:bg-gray-800/50 text-gray-100' : 'hover:bg-gray-100/50 text-gray-900'}`}
      onClick={onClick}
    >
      {/* Left Section - Icon & Basic Info */}
      <div className="flex items-center space-x-3 md:space-x-4 min-w-0 flex-1 md:flex-none md:min-w-[10rem] lg:min-w-[15rem]">
        <div className={`relative w-8 h-8 md:w-10 md:h-10 rounded-lg overflow-hidden flex items-center justify-center shrink-0
          ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}
        >
          <img
            src={`/api/market/company-logo/${item.identifier}`}
            alt={`${item.title} logo`}
            className="w-6 h-6 md:w-8 md:h-8 object-contain"
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.style.display = 'none';
              target.parentElement!.innerHTML += `
                <div class="absolute inset-0 flex items-center justify-center text-base md:text-lg font-bold ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}">
                  ${item.identifier.charAt(0)}
                </div>
              `;
            }}
          />
        </div>
        
        <div className="min-w-0 flex-1">
          <div className="flex items-center space-x-2">
            <span className={`font-semibold truncate ${
              isDarkMode ? 'text-gray-100' : 'text-gray-900'
            }`}>{item.identifier}</span>
            <span className={`text-xs md:text-sm hidden sm:inline truncate ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>{item.title}</span>
          </div>
          <div className="text-xs text-gray-500 mt-1 truncate">
            {item.metadata?.sector && `${item.metadata.sector}`}
          </div>
        </div>
      </div>

      {/* Middle Section - Sparkline */}
      <div className="flex-1 mx-3 md:mx-6 h-8 md:h-10 hidden sm:block">
        <StockSparkline
          ticker={item.identifier}
          timeframe="1d"
          height={40}
        />
      </div>

      {/* Right Section - Price & Actions */}
      <div className="flex items-center space-x-2 md:space-x-4 mt-2 sm:mt-0 w-full sm:w-auto justify-end">
        {isPriceLoading ? (
          <div className="w-16 h-12 animate-pulse bg-gray-200 dark:bg-gray-700 rounded" />
        ) : (
          <div className="text-right mr-2">
            <div className={`font-mono text-sm md:text-base ${
              isDarkMode ? 'text-gray-100' : 'text-gray-900'
            }`}>
              ${priceData?.price?.toFixed(2) || '-.--'}
            </div>
            <div className={`text-xs md:text-sm ${
              (priceData?.change || 0) >= 0 ? 'text-green-500' : 'text-red-500'
            }`}>
              {(priceData?.change || 0) >= 0 ? '+' : ''}
              {priceData?.change?.toFixed(2) || '-.--'}%
            </div>
          </div>
        )}

        <div className={`flex items-center space-x-1 md:space-x-2 opacity-0 group-hover:opacity-100 
          transition-opacity`}
          onClick={e => e.stopPropagation()}
        >
          <button
            onClick={() => navigate(`/dashboard/market/${item.identifier}`)}
            className={`p-1.5 md:p-2 rounded-lg transition-colors ${
              isDarkMode 
                ? 'hover:bg-gray-700 text-gray-400' 
                : 'hover:bg-gray-200 text-gray-600'
            }`}
            title="View Details"
          >
            <ExternalLink className="w-3.5 h-3.5 md:w-4 md:h-4" />
          </button>

          <button
            onClick={() => navigate('/dashboard/market-chat', {
              state: { initialPrompt: `Analyze ${item.identifier} stock` }
            })}
            className={`p-1.5 md:p-2 rounded-lg transition-colors ${
              isDarkMode 
                ? 'hover:bg-gray-700 text-gray-400' 
                : 'hover:bg-gray-200 text-gray-600'
            }`}
            title="Open in Market Chat"
          >
            <MessageSquare className="w-3.5 h-3.5 md:w-4 md:h-4" />
          </button>

          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowActions(prev => !prev);
            }}
            className={`p-1.5 md:p-2 rounded-lg transition-colors ${
              isDarkMode 
                ? 'hover:bg-gray-700 text-gray-400' 
                : 'hover:bg-gray-200 text-gray-600'
            }`}
          >
            <MoreVertical className="w-3.5 h-3.5 md:w-4 md:h-4" />
          </button>
        </div>
      </div>

      {/* Actions Menu */}
      <AnimatePresence>
        {showActions && (
          <motion.div
            initial={{ opacity: 0, y: 10, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 10, scale: 0.95 }}
            className={`absolute right-0 top-full mt-2 z-20 w-48 rounded-lg shadow-lg
              ${isDarkMode ? 'bg-gray-800 border-gray-700 text-gray-100' : 'bg-white border-gray-200 text-gray-900'}
              border overflow-hidden`}
            onClick={e => e.stopPropagation()}
          >
            <button
              onClick={() => setShowConfirmation(true)}
              className={`w-full px-4 py-2 text-left text-sm transition-colors flex items-center space-x-2
                ${isDarkMode 
                  ? 'text-red-400 hover:bg-gray-700' 
                  : 'text-red-600 hover:bg-gray-100'}`}
            >
              <Trash className="w-4 h-4" />
              <span>{item.lists?.[0] ? 'Remove from List' : 'Delete Item'}</span>
            </button>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Confirmation Dialog */}
      <AnimatePresence>
        {showConfirmation && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm"
            onClick={() => setShowConfirmation(false)}
          >
            <motion.div
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.95 }}
              className={`w-full max-w-sm sm:max-w-md p-5 sm:p-6 rounded-xl shadow-xl ${
                isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'
              }`}
              onClick={e => e.stopPropagation()}
            >
              <div className="flex items-center space-x-3 text-amber-500 mb-4">
                <AlertTriangle className="w-5 h-5 sm:w-6 sm:h-6" />
                <h3 className="text-base sm:text-lg font-semibold">Confirm Removal</h3>
              </div>
              
              <p className={isDarkMode ? "text-gray-300 text-sm sm:text-base" : "text-gray-600 text-sm sm:text-base"}>
                Are you sure you want to {item.lists?.[0] ? "remove this item from the list" : "delete this item"}? 
                This action cannot be undone.
              </p>
              
              <div className="flex justify-end space-x-3 mt-6">
                <button
                  onClick={() => setShowConfirmation(false)}
                  className={`px-3 py-1.5 sm:px-4 sm:py-2 rounded-lg text-sm ${
                    isDarkMode ? 'hover:bg-gray-800 text-gray-400' : 'hover:bg-gray-100 text-gray-600'
                  }`}
                >
                  Cancel
                </button>
                <button
                  onClick={handleDelete}
                  disabled={isDeleting}
                  className={`px-3 py-1.5 sm:px-4 sm:py-2 rounded-lg bg-red-500 text-white hover:bg-red-600 flex items-center space-x-2 text-sm
                    ${isDeleting ? 'opacity-60 cursor-not-allowed' : ''}`}
                >
                  {isDeleting ? (
                    <>
                      <LoadingSpinner size="sm" />
                      <span>Removing...</span>
                    </>
                  ) : (
                    <>
                      <Trash className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
                      <span>Confirm</span>
                    </>
                  )}
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

// Enhanced ListCard component for gallery view
const ListCard: React.FC<{ 
  list: WatchList, 
  onClick: () => void 
}> = ({ list, onClick }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <motion.div 
      whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
      whileTap={{ scale: 0.98 }}
      className={`rounded-xl border p-4 cursor-pointer transition-all ${
        isDarkMode ? 
          'bg-gray-900/50 border-gray-800 text-gray-100 hover:shadow-md hover:shadow-blue-900/10' : 
          'bg-white/50 border-gray-200 text-gray-900 hover:shadow-lg'
      }`}
      onClick={onClick}
    >
      <div className="flex items-start justify-between mb-3">
        <div className="flex items-center space-x-3">
          <div className={`w-8 h-8 sm:w-9 sm:h-9 rounded-full flex items-center justify-center ${list.color || 'bg-blue-500'}`}>
            <span className="text-sm font-bold text-white">
              {list.name.charAt(0).toUpperCase()}
            </span>
          </div>
          <div>
            <div className="flex items-center space-x-2">
              <h3 className={`font-semibold text-sm sm:text-base ${
                isDarkMode ? 'text-gray-100' : 'text-gray-900'
              }`}>{list.name}</h3>
              <span className={`text-xs px-1.5 py-0.5 rounded-full ${
                isDarkMode ? 'bg-gray-800 text-gray-400' : 'bg-gray-100 text-gray-600'
              }`}>{list.items.length}</span>
            </div>
          </div>
        </div>
        <WatchlistMenu listId={list.id.toString()} />
      </div>

      <div className="border-t border-b my-2 py-2 border-gray-700/30">
        {list.items.length > 0 ? (
          <div className="flex justify-between items-center">
            <span className="text-xs text-gray-400">Items</span>
            <span className="text-xs text-gray-400">{list.items.length}</span>
          </div>
        ) : (
          <div className="flex justify-center py-1">
            <span className="text-xs text-gray-500">No items yet</span>
          </div>
        )}
      </div>

      {list.items.length > 0 ? (
        <div className="flex flex-wrap gap-1 mt-2">
          {list.items.slice(0, 3).map(item => (
            <div 
              key={item.id} 
              className={`rounded-md px-1.5 py-0.5 text-xs ${
                isDarkMode ? 'bg-gray-800 text-gray-300' : 'bg-gray-100 text-gray-700'
              }`}
            >
              {item.identifier}
            </div>
          ))}
          {list.items.length > 3 && (
            <div className={`rounded-md px-1.5 py-0.5 text-xs ${
              isDarkMode ? 'bg-gray-800 text-gray-400' : 'bg-gray-100 text-gray-500'
            }`}>
              +{list.items.length - 3} more
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-center">
          <button className="text-xs text-blue-500 hover:text-blue-400 transition-colors">
            Add stocks
          </button>
        </div>
      )}
    </motion.div>
  );
};

// Create new list card component
const NewListCard: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <motion.div
      whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
      whileTap={{ scale: 0.98 }}
      onClick={onClick}
      className={`rounded-xl border-2 border-dashed p-4 flex flex-col items-center justify-center cursor-pointer h-full min-h-[10rem] ${
        isDarkMode ? 'border-gray-700 hover:border-blue-500/30' : 'border-gray-200 hover:border-blue-500/30'
      }`}
    >
      <div className={`w-10 h-10 rounded-full flex items-center justify-center mb-3 ${
        isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
      }`}>
        <Plus className={`w-5 h-5 ${
          isDarkMode ? 'text-blue-400' : 'text-blue-500'
        }`} />
      </div>
      <span className={`text-sm font-medium ${
        isDarkMode ? 'text-gray-300' : 'text-gray-600'
      }`}>
        Create New Watchlist
      </span>
    </motion.div>
  );
};

// Market View Header with live indicators
const MarketViewHeader: React.FC = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className={`flex items-center justify-between mb-6`}>
      <div className="flex items-center">
        <h1 className="text-lg sm:text-xl md:text-2xl font-bold">Saved</h1>
        <div className={`ml-4 px-1.5 py-0.5 rounded-full text-xs flex items-center ${
          isDarkMode ? 'bg-green-500/20 text-green-400' : 'bg-green-50 text-green-600'
        }`}>
          <span className="w-1.5 h-1.5 rounded-full bg-green-500 mr-1 animate-pulse"></span>
          LIVE
        </div>
      </div>
      
      <div className="hidden md:flex items-center">
        <div className={`flex items-center ${
          isDarkMode ? 'text-gray-300' : 'text-gray-700'
        }`}>
          <Clock className="w-4 h-4 mr-1" />
          <span className="text-sm">{new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</span>
        </div>
      </div>
    </div>
  );
};

// Section title component with accent bar
const SectionTitle: React.FC<{ title: string, icon?: React.ReactNode }> = ({ title, icon }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="flex items-center mb-4">
      <div className={`w-1 h-6 rounded-full mr-2 ${isDarkMode ? 'bg-blue-500' : 'bg-blue-500'}`}></div>
      <h2 className="text-base font-semibold flex items-center">
        {icon && <span className="mr-2">{icon}</span>}
        {title}
      </h2>
    </div>
  );
};

// Tab component for view selection
const ViewTabs: React.FC<{ 
  activeTab: string, 
  onChange: (tab: string) => void 
}> = ({ activeTab, onChange }) => {
  const { isDarkMode } = useTheme();
  const tabs = [
    { id: 'watchlist', label: 'Watchlist' }
    // Removed other tabs
  ];
  
  return (
    <div className="flex space-x-2 mb-4 overflow-x-auto pb-1 scrollbar-hide">
      {tabs.map(tab => (
        <button
          key={tab.id}
          onClick={() => onChange(tab.id)}
          className={`px-4 py-1 rounded-full text-sm transition-colors whitespace-nowrap ${
            activeTab === tab.id
              ? isDarkMode
                ? 'bg-blue-500 text-white'
                : 'bg-blue-500 text-white'
              : isDarkMode
                ? 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
          }`}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export const WatchlistsView = () => {
  const { watchlists, savedItems, isLoading, error, createList } = useWatchlists();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [activeList, setActiveList] = useState<WatchList | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('watchlist');
  const [sortOption, setSortOption] = useState('alpha'); // 'alpha', 'recent', 'performance'
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  const handleCreateList = async (data: { name: string; description?: string }) => {
    if (!data.name) return;
  
    try {
      await createList({ 
        name: data.name,
        description: data.description || '' 
      });
      setShowCreateModal(false);
    } catch (error) {
      console.error('Failed to create watchlist:', error);
    }
  };

  // Filter items based on search query
  const filteredSavedItems = savedItems?.filter(item => 
    item.identifier.toLowerCase().includes(searchQuery.toLowerCase()) || 
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Error state with retry button
  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-16 flex flex-col items-center">
        <div className={`p-6 rounded-xl border text-center max-w-md mx-auto ${
          isDarkMode ? 'bg-gray-900/50 border-gray-800' : 'bg-white/50 border-gray-200'
        }`}>
          <AlertTriangle className="w-12 h-12 mx-auto mb-4 text-amber-500" />
          <h3 className="text-lg font-medium mb-2">Unable to load watchlists</h3>
          <p className={`mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            {error}
          </p>
          <button 
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-[60vh]">
        <LoadingSpinner size="lg" />
        <p className={`mt-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          Loading your watchlists...
        </p>
      </div>
    );
  }

  // Enhanced empty state with quick actions
  if (!watchlists?.length && !savedItems?.length) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-16 flex flex-col items-center">
        <div className={`p-8 rounded-xl border text-center max-w-md mx-auto ${
          isDarkMode ? 'bg-gray-900/50 border-gray-800' : 'bg-white/50 border-gray-200'
        }`}>
          <Bookmark className="w-16 h-16 mx-auto mb-6 text-blue-400 opacity-80" />
          <h3 className={`text-xl font-medium mb-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-600'}`}>Your watchlists are empty</h3>
          <p className={`mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            Save stocks to track their performance and get personalized insights
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button 
              onClick={() => navigate('/dashboard/search')} // Change from '/dashboard' to '/dashboard/search'
              className={`px-4 py-2 rounded-full flex items-center justify-center space-x-2
                ${isDarkMode ? 'bg-gray-800 hover:bg-gray-700 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-900'}`}
            >
              <Search className="w-4 h-4" />
              <span>Search Stocks</span>
            </button>
            <button 
              onClick={() => setShowCreateModal(true)}
              className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 flex items-center justify-center space-x-2"
            >
              <Plus className="w-4 h-4" />
              <span>Create Watchlist</span>
            </button>
          </div>
          
          {/* New Discover with Prism button - now centered */}
          <div className="flex justify-center">
            <motion.button 
              onClick={() => navigate('/dashboard/market-chat', { 
                state: { initialResearchMode: 'discovery' } 
              })}
              className={`mt-6 px-4 py-2 rounded-full flex items-center justify-center space-x-2
                ${isDarkMode ? 'bg-gray-800 hover:bg-gray-700 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-900'}`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              <Box className="w-4 h-4" />
              <span>Discover Stocks with Prism</span>
            </motion.button>
          </div>
        </div>
        <AnimatePresence>
        {showCreateModal && (
          <CreateListModal
            isOpen={showCreateModal}
            onClose={() => setShowCreateModal(false)}
            onSubmit={handleCreateList}
          />
        )}
      </AnimatePresence>
      </div>
    );
  }

  // Show list detail view if a list is selected
  if (activeList) {
    return (
      <div className={`max-w-7xl mx-auto px-4 py-6 sm:py-8 ${
        isDarkMode ? 'text-gray-100' : 'text-gray-900'
      }`}>
        {/* Header with back button */}
        <div className="flex items-center mb-6">
          <button 
            onClick={() => setActiveList(null)}
            className={`mr-4 p-2 rounded-lg transition-colors ${
              isDarkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-200'
            }`}
          >
            <ChevronRight className="w-5 h-5 transform rotate-180" />
          </button>
          <h1 className="text-xl sm:text-2xl font-bold">{activeList.name}</h1>
        </div>

        {/* List description if available */}
        {activeList.description && (
          <p className={`mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            {activeList.description}
          </p>
        )}
        
        {/* Sort and filter controls */}
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-400">Sort by:</span>
            <select
              className={`text-sm rounded-lg p-1.5 ${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
              } border`}
              onChange={(e) => setSortOption(e.target.value)}
              value={sortOption}
            >
              <option value="alpha">Alphabetical</option>
              <option value="recent">Recently Added</option>
              <option value="performance">Performance</option>
            </select>
          </div>
          
          <div className="flex items-center space-x-2">
            <button className={`p-1.5 rounded-lg ${
              isDarkMode ? 'hover:bg-gray-800 text-gray-300' : 'hover:bg-gray-100 text-gray-600'
            }`}>
              <ArrowUp className="w-4 h-4" />
            </button>
            <button className={`p-1.5 rounded-lg ${
              isDarkMode ? 'hover:bg-gray-800 text-gray-300' : 'hover:bg-gray-100 text-gray-600'
            }`}>
              <ArrowDown className="w-4 h-4" />
            </button>
          </div>
        </div>

        {/* List items */}
        <div className={`rounded-xl border ${
          isDarkMode ? 'bg-gray-900/50 border-gray-800' : 'bg-white/50 border-gray-200'
        }`}>
          {activeList.items.length === 0 ? (
            <div className="p-8 text-center">
              <p className={`mb-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                This list is empty. Add some stocks to get started.
              </p>
              <button
                onClick={() => navigate('/dashboard/search')}
                className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors"
              >
                Browse Stocks
              </button>
            </div>
          ) : (
            <div className="divide-y divide-gray-700/30">
            {activeList.items.map(item => (
              <div key={item.id} className="py-1">
                <ListItem
                  item={{
                    ...item,
                    lists: [{ id: activeList.id }]
                  }}
                  onClick={() => navigate(`/dashboard/market/${item.identifier}`)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

// Main view when no list is selected
return (
  <div className={`max-w-7xl mx-auto px-4 py-6 sm:py-8 ${
    isDarkMode ? 'text-gray-100' : 'text-gray-900'
  }`}>
    {/* Market View Header with live indicators */}
    <MarketViewHeader />
    
    {/* Search and Action Bar */}
    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
      <div className="relative w-full sm:w-auto flex-1 max-w-md">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
        <input
          type="text"
          placeholder="Search watchlists and stocks..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className={`pl-10 pr-4 py-2 w-full rounded-full border ${
            isDarkMode 
              ? 'bg-gray-800/70 border-gray-700 text-white placeholder-gray-500' 
              : 'bg-white/70 border-gray-200 text-gray-900 placeholder-gray-400'
          } focus:outline-none focus:ring-2 focus:ring-blue-500`}
        />
      </div>
      
      <button
        onClick={() => setShowCreateModal(true)}
        className="px-5 py-2.5 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors flex items-center space-x-2 whitespace-nowrap sm:ml-4 w-full sm:w-auto justify-center"
      >
        <Plus size={16} />
        <span>New Watchlist</span>
      </button>
    </div>

    {/* Tabs Navigation */}
    <ViewTabs activeTab={activeTab} onChange={setActiveTab} />

    {/* Watchlist View Content */}
    {activeTab === 'watchlist' && (
      <>
        {/* Lists Section */}
        {watchlists && watchlists.length > 0 && (
          <div className="mb-8">
            <SectionTitle title="YOUR LISTS" icon={<Bookmark size={16} />} />
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {watchlists.map(list => (
                <ListCard
                  key={list.id}
                  list={list}
                  onClick={() => setActiveList(list)}
                />
              ))}
              <NewListCard onClick={() => setShowCreateModal(true)} />
            </div>
          </div>
        )}

        {/* Individual Saved Items Section */}
        {savedItems && savedItems.length > 0 && (
          <div>
            <SectionTitle 
              title="SAVED ITEMS" 
              icon={<Zap size={16} />} 
            />
            <div className={`rounded-xl border ${
              isDarkMode ? 'bg-gray-900/50 border-gray-800' : 'bg-white/50 border-gray-200'
            }`}>
              <div className="divide-y divide-gray-700/30">
                {filteredSavedItems?.length ? (
                  filteredSavedItems.map(item => (
                    <div key={item.id} className="py-1">
                      <ListItem
                        item={item}
                        onClick={() => navigate(`/dashboard/market/${item.identifier}`)}
                      />
                    </div>
                  ))
                ) : (
                  <div className="p-8 text-center">
                    <p className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                      No items match your search.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    )}

    {/* Market View Content */}
    {activeTab === 'market' && (
      <div className="flex flex-col items-center justify-center py-12">
        <div className={`p-6 rounded-xl border text-center max-w-lg mx-auto ${
          isDarkMode ? 'bg-gray-900/50 border-gray-800' : 'bg-white/50 border-gray-200'
        }`}>
          <h3 className="text-lg font-medium mb-3">Market Overview Coming Soon</h3>
          <p className={`mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            We're working on market trends and insights for this section.
          </p>
        </div>
      </div>
    )}

    {/* News View Content */}
    {activeTab === 'news' && (
      <div className="flex flex-col items-center justify-center py-12">
        <div className={`p-6 rounded-xl border text-center max-w-lg mx-auto ${
          isDarkMode ? 'bg-gray-900/50 border-gray-800' : 'bg-white/50 border-gray-200'
        }`}>
          <h3 className="text-lg font-medium mb-3">Financial News Coming Soon</h3>
          <p className={`mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            Personalized news for your watchlists will appear here.
          </p>
        </div>
      </div>
    )}

    {/* Alerts View Content */}
    {activeTab === 'alerts' && (
      <div className="flex flex-col items-center justify-center py-12">
        <div className={`p-6 rounded-xl border text-center max-w-lg mx-auto ${
          isDarkMode ? 'bg-gray-900/50 border-gray-800' : 'bg-white/50 border-gray-200'
        }`}>
          <h3 className="text-lg font-medium mb-3">Price Alerts Coming Soon</h3>
          <p className={`mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            Set up custom alerts for price movements and events.
          </p>
        </div>
      </div>
    )}

    {/* Create List Modal */}
    <AnimatePresence>
      {showCreateModal && (
        <CreateListModal
          isOpen={showCreateModal}
          onClose={() => setShowCreateModal(false)}
          onSubmit={handleCreateList}
        />
      )}
    </AnimatePresence>
  </div>
);
};