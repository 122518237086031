import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  BarChart2,
  ChevronRight,
  LineChart,
  TrendingUp,
  Share2,
  Zap,
  Globe,
  Check,
  AlertTriangle,
  Info,
  Database
} from 'lucide-react';

const AdvancedAnalytics = () => {
  const { isDarkMode } = useTheme();

  const analyticsModules = [
    {
      title: 'Technical Indicators',
      description: 'Advanced technical analysis indicators for in-depth market analysis.',
      icon: LineChart,
      features: [
        'Over 100+ technical indicators available',
        'Customizable parameters for each indicator',
        'Multi-timeframe analysis capabilities',
        'Indicator correlation analysis',
        'Custom indicator creation and backtesting'
      ],
      examples: [
        'Advanced moving average systems',
        'Oscillator divergence detection',
        'Volume profile analysis',
        'Market breadth indicators',
        'Volatility-based position sizing'
      ]
    },
    {
      title: 'Fundamental Analysis',
      description: 'Comprehensive fundamental metrics and financial data analysis.',
      icon: Database,
      features: [
        'Financial statement analysis',
        'Real-time earnings metrics',
        'Valuation model comparisons',
        'Sector and industry benchmarking',
        'Forward guidance tracking'
      ],
      examples: [
        'PEG ratio comparisons across sectors',
        'Cash flow trend analysis',
        'Debt structure evaluation',
        'Return on capital assessment',
        'Margin expansion/contraction tracking'
      ]
    },
    {
      title: 'Sentiment Analysis',
      description: 'AI-powered sentiment tracking from news, social media, and market activity.',
      icon: Share2,
      features: [
        'Real-time news sentiment scoring',
        'Social media sentiment tracking',
        'Institutional money flow analysis',
        'Options sentiment indicators',
        'Insider trading pattern detection'
      ],
      examples: [
        'News sentiment divergence signals',
        'Social sentiment trend identification',
        'Unusual options activity alerts',
        'Short interest change analysis',
        'Institutional accumulation patterns'
      ]
    },
    {
      title: 'Market Internals',
      description: 'Deep analysis of market breadth, liquidity, and intermarket relationships.',
      icon: Globe,
      features: [
        'Advanced market breadth metrics',
        'Sector rotation analysis',
        'Intermarket correlation maps',
        'Liquidity flow visualization',
        'Risk on/risk off indicators'
      ],
      examples: [
        'Advance/decline analysis',
        'New highs vs. new lows',
        'Sector performance heatmaps',
        'Cross-asset correlation shifts',
        'Market regime identification'
      ]
    }
  ];

  const analyticsWorkflows = [
    {
      title: 'Creating Custom Dashboards',
      steps: [
        'Select "New Dashboard" from the Analytics menu',
        'Choose a dashboard template or start from scratch',
        'Add desired widgets and analysis components',
        'Arrange components in your preferred layout',
        'Save and name your custom dashboard configuration'
      ]
    },
    {
      title: 'Building Multi-Factor Screeners',
      steps: [
        'Navigate to the Screener section in Analytics',
        'Select base universe (index, sector, or custom list)',
        'Add technical, fundamental, and sentiment filters',
        'Set parameter values and logical conditions',
        'Run screen and save criteria for future use'
      ]
    },
    {
      title: 'Conducting Correlation Analysis',
      steps: [
        'Open the Correlation Tool from the Analytics menu',
        'Select assets to include in the correlation matrix',
        'Choose time period and sampling frequency',
        'Generate visualization (heatmap or network graph)',
        'Export results or save to your dashboard'
      ]
    },
    {
      title: 'Backtesting Trading Strategies',
      steps: [
        'Access the Strategy Builder in the Analytics section',
        'Define entry and exit conditions using available indicators',
        'Set position sizing and risk management parameters',
        'Select historical testing period and market conditions',
        'Run backtest and analyze performance metrics'
      ]
    }
  ];

  const bestPractices = [
    'Use multiple timeframes for comprehensive analysis',
    'Combine indicators from different analysis categories',
    'Consider both absolute and relative performance metrics',
    'Regularly review and adjust your analytical framework',
    'Back-test assumptions before implementing in real trading'
  ];

  const commonMistakes = [
    'Over-optimizing indicators to fit historical data',
    'Ignoring market regime changes when analyzing patterns',
    'Using too many overlapping indicators',
    'Focusing on lagging instead of leading indicators',
    'Not considering correlation between analysis factors'
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <BarChart2 className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Advanced Analytics
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Harness the power of sophisticated market analysis tools and techniques
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              Prism's Advanced Analytics suite provides institutional-grade tools to analyze markets and securities 
              across multiple dimensions. By combining technical analysis, fundamental data, sentiment tracking, and 
              market internals, you can develop a comprehensive view of potential opportunities and risks. These tools 
              help you move beyond basic chart analysis to identify complex patterns, relationships, and anomalies that 
              may provide trading or investment advantages.
            </p>
          </div>

          {/* Analytics Modules */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Analytics Modules
          </h2>

          <div className="space-y-12 mb-16">
            {analyticsModules.map((module, index) => {
              const Icon = module.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    } mr-4`}>
                      <Icon className={`w-6 h-6 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <div>
                      <h3 className={`text-xl font-bold ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {module.title}
                      </h3>
                    </div>
                  </div>
                  
                  <p className={`mb-6 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {module.description}
                  </p>
                  
                  <div className="grid md:grid-cols-2 gap-8">
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Features:</h4>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {module.features.map((feature, i) => (
                          <li key={i} className="flex items-start">
                            <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Examples:</h4>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {module.examples.map((example, i) => (
                          <li key={i} className="flex items-start">
                            <span className="w-1.5 h-1.5 rounded-full bg-green-500 mt-1.5 mr-2 flex-shrink-0" />
                            <span>{example}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Workflows */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Analytics Workflows
          </h2>

          <div className="space-y-8 mb-16">
            {analyticsWorkflows.map((workflow, index) => (
              <div key={index} className={`p-6 rounded-xl border ${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
              }`}>
                <div className="flex items-center mb-6">
                  <div className="flex-shrink-0 w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center text-white text-lg font-medium mr-4">
                    {index + 1}
                  </div>
                  <h3 className={`text-xl font-bold ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {workflow.title}
                  </h3>
                </div>
                
                <div className="ml-12">
                  <ol className={`space-y-3 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {workflow.steps.map((step, i) => (
                      <li key={i} className="flex items-start">
                        <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">{i+1}</div>
                        <span>{step}</span>
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            ))}
          </div>

          {/* Pro Features */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="flex items-center mb-6">
              <div className={`p-2 rounded-lg ${
                isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
              } mr-4`}>
                <Zap className={`w-6 h-6 ${
                  isDarkMode ? 'text-blue-400' : 'text-blue-600'
                }`} />
              </div>
              <h3 className={`text-xl font-bold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Pro Analytics Features
              </h3>
            </div>

            <p className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Prism Pro users have access to additional advanced analytics capabilities designed for 
              professional traders and institutional investors.
            </p>

            <div className="grid md:grid-cols-2 gap-6">
              <div className={`p-4 rounded-lg ${
                isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
              }`}>
                <h4 className={`text-base font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Institutional Data Feeds
                </h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Dark pool transaction data</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Options flow analytics</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>13F fillings analysis</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>COT (Commitment of Traders) data</span>
                  </li>
                </ul>
              </div>

              <div className={`p-4 rounded-lg ${
                isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
              }`}>
                <h4 className={`text-base font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Advanced Modeling
                </h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Multi-factor risk modeling</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Machine learning pattern detection</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Monte Carlo simulation</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Custom indicator scripting</span>
                  </li>
                </ul>
              </div>

              <div className={`p-4 rounded-lg ${
                isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
              }`}>
                <h4 className={`text-base font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Portfolio Analytics
                </h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Portfolio attribution analysis</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Risk factor decomposition</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Correlation-based optimization</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Stress testing scenarios</span>
                  </li>
                </ul>
              </div>

              <div className={`p-4 rounded-lg ${
                isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
              }`}>
                <h4 className={`text-base font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Advanced API & Integrations
                </h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Direct API access to all analytics</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Python notebook integration</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Trading platform webhooks</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Custom data source integration</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Best Practices */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <h3 className={`text-xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Best Practices for Advanced Analytics
            </h3>
            
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h4 className={`font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Do:</h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {bestPractices.map((practice, i) => (
                    <li key={i} className="flex items-start">
                      <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                      <span>{practice}</span>
                    </li>
                  ))}
                </ul>
              </div>
              
              <div>
                <h4 className={`font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Avoid:</h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {commonMistakes.map((mistake, i) => (
                    <li key={i} className="flex items-start">
                      <AlertTriangle className="w-4 h-4 text-yellow-500 mr-2 mt-0.5 flex-shrink-0" />
                      <span>{mistake}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Important Note */}
          <div className={`p-6 rounded-xl border ${
            isDarkMode ? 'bg-blue-900/20 border-blue-800/30' : 'bg-blue-50 border-blue-100'
          } mb-12`}>
            <div className="flex items-start">
              <Info className={`w-6 h-6 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Analytics Usage Note</h3>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  Advanced analytics features may consume more credits depending on the complexity 
                  and data requirements. Real-time analysis and custom calculations typically use more 
                  computational resources. Check the credit usage estimates displayed before running 
                  intensive analytics operations. Pro subscribers have higher usage limits and access to 
                  exclusive analytics features.
                </p>
              </div>
            </div>
          </div>

          {/* Related Documentation */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Related Documentation
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <a 
              href="/docs/pro-features"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Pro Features
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Learn about premium analytics capabilities
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
            
            <a 
              href="/docs/api"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  API Reference
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Access analytics data programmatically
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default AdvancedAnalytics;