import { SubscriptionResponse } from '../types/subscription';
import { api } from './api';

interface CreateCheckoutResponse {
  clientSecret: string;
  isAnnual: boolean;
}

class SubscriptionService {
  private static instance: SubscriptionService;

  private constructor() {}

  static getInstance(): SubscriptionService {
    if (!SubscriptionService.instance) {
      SubscriptionService.instance = new SubscriptionService();
    }
    return SubscriptionService.instance;
  }

  async createCheckoutSession(interval: 'month' | 'year'): Promise<any> {
    try {
      const { data } = await api.post('/payment/checkout', { interval });
      return data;
    } catch (error) {
      console.error('Failed to create checkout session:', error);
      throw error;
    }
  }

  async getSubscriptionStatus() {
    try {
      const { data } = await api.get('/payment/status');
      
      // Add more detailed logging
      console.log('Subscription status API response (raw):', data);
      
      // Improved response handling with better normalization
      const normalizedResponse = {
        subscription: data.subscription ? {
          ...data.subscription,
          status: data.subscription.status?.toUpperCase() || 'INACTIVE',
          planId: data.subscription.planId || 'free',
        } : null,
        tier: (data.tier || data.subscription?.tier || 'free').toLowerCase(),
        status: (data.status || data.subscription?.status || 'inactive').toLowerCase(),
      };
      
      // Show more helpful logging
      console.log('Normalized subscription response:', normalizedResponse);
      
      // If we have tier as "pro" but status isn't "active", make sure to log this inconsistency
      if (normalizedResponse.tier === 'pro' && normalizedResponse.status !== 'active') {
        console.warn('Inconsistent state: Pro tier with non-active status', normalizedResponse);
      }
      
      return normalizedResponse;
    } catch (error) {
      console.error('Failed to get subscription status:', error);
      throw error;
    }
  }

  async cancelSubscription(): Promise<boolean> {
    try {
      await api.post('/payment/cancel');
      return true;
    } catch (error) {
      console.error('Failed to cancel subscription:', error);
      throw error;
    }
  }

  isFeatureAvailable(feature: string, subscription: any | null): boolean {
    if (!subscription) return false;
    
    // Normalize status to lowercase
    const status = typeof subscription.status === 'string' 
      ? subscription.status.toLowerCase() 
      : '';
      
    return (status === 'active' || status === 'trialing') && 
           (subscription.features?.includes(feature) || 
            subscription.planId?.toLowerCase() === 'pro' ||
            subscription.tier?.toLowerCase() === 'pro');
  }
}

export const subscriptionService = SubscriptionService.getInstance();