import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { DocumentationLayout } from '../../components/layout/DocumentationLayout';
import { 
  Tag, 
  TrendingUp, 
  MessageSquare, 
  FileText, 
  Briefcase, 
  HelpCircle, 
  ChevronRight,
  BookOpen
} from 'lucide-react';
import { Link } from 'react-router-dom';

const ChatChips = () => {
  const { isDarkMode } = useTheme();

  const chipTypes = [
    {
      id: 'stock',
      icon: TrendingUp,
      color: 'blue',
      title: 'Stock Chips',
      description: 'Specify stock tickers for focused research',
      example: 'AAPL, TSLA, NVDA',
      details: 'When you include a stock chip, Prism will focus its analysis specifically on the selected stock, retrieving real-time price data, recent news, and relevant financial information.'
    },
    {
      id: 'topic',
      icon: MessageSquare,
      color: 'purple',
      title: 'Topic Chips',
      description: 'Focus on specific market topics',
      example: 'Earnings Analysis, Market Sentiment, Industry Trends',
      details: 'Topic chips help direct Prism to focus on particular subjects or themes in your query, ensuring more targeted and relevant responses to your questions.'
    },
    {
      id: 'file',
      icon: FileText,
      color: 'green',
      title: 'File Chips',
      description: 'Include documents for reference',
      example: 'PDFs, spreadsheets, text files',
      details: 'File chips allow you to upload documents that Prism will analyze as part of its research process. This is useful for analyzing earnings reports, research papers, or your own trading notes.'
    }
  ];

  return (
    <DocumentationLayout
      title="Using Chat Chips"
      icon={Tag}
      description="Enhance your market research with powerful query modifiers"
    >
      <div className={`prose max-w-none ${isDarkMode ? 'prose-invert' : ''}`}>
        <h2>What Are Chat Chips?</h2>
        <p>
          Chat chips are specialized modifiers that enhance your conversations with Prism AI. 
          They allow you to direct and focus your market research queries with greater precision, 
          ensuring that Prism understands exactly what you're looking for.
        </p>
        
        <p>
          By using chat chips, you can specify stocks, introduce specific topics, or include reference files - 
          all while maintaining a natural conversation flow.
        </p>

        <div className={`p-6 rounded-xl border my-8 ${
          isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
        }`}>
          <h3 className="mt-0 flex items-center">
            <HelpCircle className="w-5 h-5 mr-2" />
            Why Use Chat Chips?
          </h3>
          <ul className="mb-0">
            <li>Get more focused and relevant research results</li>
            <li>Save time by specifying exactly what you're looking for</li>
            <li>Maintain context across a multi-message conversation</li>
            <li>Include documents and files as part of your research</li>
            <li>Combine with research modes for even more precise analysis</li>
          </ul>
        </div>

        <h2>Types of Chat Chips</h2>
        <p>
          Prism supports three types of chat chips, each designed to enhance different aspects of your market research:
        </p>
      </div>

      {/* Chip types cards */}
      <div className="my-12 space-y-6">
        {chipTypes.map((chip) => {
          const Icon = chip.icon;
          const chipColorClass = 
            chip.color === 'blue' ? `${isDarkMode ? 'bg-blue-500/20 text-blue-400' : 'bg-blue-50 text-blue-600'}` :
            chip.color === 'purple' ? `${isDarkMode ? 'bg-purple-500/20 text-purple-400' : 'bg-purple-50 text-purple-600'}` :
            chip.color === 'green' ? `${isDarkMode ? 'bg-green-500/20 text-green-400' : 'bg-green-50 text-green-600'}` :
            `${isDarkMode ? 'bg-yellow-500/20 text-yellow-400' : 'bg-yellow-50 text-yellow-600'}`;
          
          return (
            <div 
              key={chip.id}
              className={`p-6 rounded-xl border ${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
              }`}
            >
              <div className="flex items-start">
                <div className={`p-3 rounded-lg mr-4 flex-shrink-0 ${chipColorClass}`}>
                  <Icon className="w-5 h-5" />
                </div>
                <div>
                  <h3 className={`text-lg font-medium mb-1 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {chip.title}
                  </h3>
                  <p className={`text-sm mb-3 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {chip.description}
                  </p>
                  <div className={`inline-block mb-3 px-3 py-1.5 rounded text-xs ${
                    isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700'
                  }`}>
                    Example: {chip.example}
                  </div>
                  <p className={`text-sm ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`}>
                    {chip.details}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className={`prose max-w-none ${isDarkMode ? 'prose-invert' : ''}`}>
        <h2>How to Use Chat Chips</h2>
        
        <h3>Adding Stock Chips</h3>
        <p>
          To add a stock chip to your query:
        </p>
        <ol>
          <li>Click on the "+" icon in the chat input</li>
          <li>Select "Stock" from the chip options</li>
          <li>Type or search for the stock ticker you want to analyze</li>
          <li>Select the stock from the dropdown</li>
          <li>The stock chip will appear in your message input</li>
          <li>Complete your query with additional text if needed</li>
        </ol>

        <h3>Working with Topic Chips</h3>
        <p>
          To use a topic chip in your conversation:
        </p>
        <ol>
          <li>Click the "+" icon in the chat input</li>
          <li>Select "Topic" from the available options</li>
          <li>Choose your desired topic or enter a custom one</li>
          <li>Add your specific question or request after the chip</li>
        </ol>

        <h3>Working with File Chips</h3>
        <p>
          File chips allow you to upload documents for Prism to analyze alongside your query:
        </p>
        <ol>
          <li>Click the file attachment icon in the chat input</li>
          <li>Select the file you want to upload</li>
          <li>Add your question or instructions about the file</li>
          <li>Submit your query</li>
        </ol>
        <p>
          Prism will analyze the document as part of its research process and incorporate relevant insights into its response.
        </p>

        <h3>Using Research Modes</h3>
        <p>
          While not a chip type, research modes complement your chat chips by directing the analytical approach:
        </p>
        <ul>
          <li><strong>Financial Analysis:</strong> Focuses on fundamentals, earnings, and financial metrics</li>
          <li><strong>Technical Analysis:</strong> Emphasizes chart patterns, indicators, and price action</li>
          <li><strong>News Focus:</strong> Prioritizes recent news, events, and market sentiment</li>
          <li><strong>Macro Analysis:</strong> Centers on macroeconomic factors and broad market context</li>
        </ul>
        <p>
          You can select a research mode from the mode selector in the chat interface to direct the overall analytical approach of your conversation.
        </p>

        <div className={`p-6 rounded-xl border my-8 ${
          isDarkMode ? 'bg-gray-800/80 border-gray-700' : 'bg-gray-50 border-gray-200'
        }`}>
          <h3 className="mt-0">Example Queries with Chat Chips</h3>
          
          <p className="font-medium mb-2">Stock Chip + Question:</p>
          <div className={`p-3 rounded-md mb-4 ${
            isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
          }`}>
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mr-2 ${
              isDarkMode ? 'bg-blue-500/30 text-blue-200' : 'bg-blue-100 text-blue-800'
            }`}>
              <TrendingUp className="w-3 h-3 mr-1" />
              AAPL
            </span>
            <span>What are the key technical indicators to watch this week?</span>
          </div>
          
          <p className="font-medium mb-2">Topic Chip + Stock Reference:</p>
          <div className={`p-3 rounded-md mb-4 ${
            isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
          }`}>
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mr-2 ${
              isDarkMode ? 'bg-purple-500/30 text-purple-200' : 'bg-purple-100 text-purple-800'
            }`}>
              <MessageSquare className="w-3 h-3 mr-1" />
              Earnings Analysis
            </span>
            <span>How did MSFT perform against analyst expectations?</span>
          </div>
          
          <p className="font-medium mb-2">Stock Chip + File Upload:</p>
          <div className={`p-3 rounded-md ${
            isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
          }`}>
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mr-2 ${
              isDarkMode ? 'bg-blue-500/30 text-blue-200' : 'bg-blue-100 text-blue-800'
            }`}>
              <TrendingUp className="w-3 h-3 mr-1" />
              NVDA
            </span>
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mr-2 ${
              isDarkMode ? 'bg-green-500/30 text-green-200' : 'bg-green-100 text-green-800'
            }`}>
              <FileText className="w-3 h-3 mr-1" />
              Q3 Earnings.pdf
            </span>
            <span>Summarize the key growth drivers mentioned in this report</span>
          </div>
        </div>

        <h2>Best Practices</h2>
        <p>
          To get the most out of chat chips:
        </p>
        <ul>
          <li><strong>Combine chips with research modes</strong> for more precise analysis</li>
          <li><strong>Be specific with context</strong> in your accompanying questions</li>
          <li><strong>Use topic chips</strong> when focusing on particular themes or subjects</li>
          <li><strong>Upload relevant documents</strong> when you have materials with specific information</li>
          <li><strong>Start broad</strong> and then use chips to narrow your focus in follow-up questions</li>
        </ul>

        <h2>Next Steps</h2>
        <p>
          Now that you understand chat chips, learn more about:
        </p>

        <div className="grid sm:grid-cols-2 gap-6 mt-6 not-prose">
          <Link 
            to="/docs/file-uploads" 
            className={`flex items-center justify-between p-4 rounded-lg transition-colors ${
              isDarkMode 
                ? 'bg-gray-800 hover:bg-gray-700 text-white border border-gray-700' 
                : 'bg-white hover:bg-gray-50 text-gray-900 border border-gray-200'
            }`}
          >
            <div className="flex items-center">
              <FileText className={`w-5 h-5 mr-3 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
              <span>File Uploads Guide</span>
            </div>
            <ChevronRight className="w-5 h-5" />
          </Link>
          
          <Link 
            to="/docs/research-modes" 
            className={`flex items-center justify-between p-4 rounded-lg transition-colors ${
              isDarkMode 
                ? 'bg-gray-800 hover:bg-gray-700 text-white border border-gray-700' 
                : 'bg-white hover:bg-gray-50 text-gray-900 border border-gray-200'
            }`}
          >
            <div className="flex items-center">
              <BookOpen className={`w-5 h-5 mr-3 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
              <span>Research Modes Guide</span>
            </div>
            <ChevronRight className="w-5 h-5" />
          </Link>
        </div>
      </div>
    </DocumentationLayout>
  );
};

export default ChatChips;