import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';

import {
  Hand,
  Building2,
  Layers,
  ArrowRight,
  CheckCircle2,
  ExternalLink,
  Code,
  Database,
  Globe,
  BadgeCheck,
  Users,
  BarChart,
  LineChart,
  Cpu,
  CloudLightning
} from 'lucide-react';

interface ServiceProvider {
  id: string;
  name: string;
  logo: string;
  description: string;
  type: string;
  link: string;
}

interface ServiceCategory {
  id: string;
  title: string;
  description: string;
  features: string[];
  icon: React.FC<{ className?: string }>;
}

const serviceCategories: ServiceCategory[] = [
  {
    id: 'ai',
    title: 'AI & Machine Learning',
    description: 'Advanced artificial intelligence solutions that power our research and analytics.',
    icon: Cpu,
    features: [
      'Natural language processing',
      'Sentiment analysis',
      'Research synthesis',
      'Pattern recognition'
    ]
  },
  {
    id: 'data',
    title: 'Market Data Services',
    description: 'Comprehensive market data that forms the foundation of our analysis platform.',
    icon: LineChart,
    features: [
      'Real-time price data',
      'Historical market information',
      'Technical indicators',
      'Company fundamentals'
    ]
  },
  {
    id: 'cloud',
    title: 'Cloud Infrastructure',
    description: 'Robust cloud solutions that ensure our platform is reliable and performant.',
    icon: CloudLightning,
    features: [
      'Scalable architecture',
      'High availability systems',
      'Data security',
      'Fast global access'
    ]
  }
];

const serviceProviders: ServiceProvider[] = [
  {
    id: '1',
    name: 'Anthropic',
    logo: '/providers/anthropic.png',
    description: 'AI assistant technology powering our advanced financial research capabilities.',
    type: 'AI Provider',
    link: 'https://anthropic.com'
  },
  {
    id: '2',
    name: 'Polygon.io',
    logo: '/providers/polygon.png',
    description: 'Financial market data API delivering comprehensive stock and ticker information.',
    type: 'Data Provider',
    link: 'https://polygon.io'
  },
  {
    id: '3',
    name: 'Deepseek',
    logo: '/providers/deepseek.png',
    description: 'AI model technology for specialized financial analysis.',
    type: 'AI Provider',
    link: 'https://deepseek.ai'
  },
  {
    id: '4',
    name: 'Spline',
    logo: '/providers/spline.png',
    description: '3D design and visualization tools used in our interactive market data displays.',
    type: 'Design & Visualization',
    link: 'https://spline.design'
  }
];

const Partners = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
      
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
          <div className="text-center">
            <Hand className={`w-12 h-12 mx-auto mb-6 ${
              isDarkMode ? 'text-blue-400' : 'text-blue-600'
            }`} />
            <h1 className={`text-4xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Our Technology Providers
            </h1>
            <p className={`text-xl max-w-3xl mx-auto mb-8 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              Newsroom leverages best-in-class services and technologies to deliver
              powerful financial insights and trading capabilities.
            </p>
            <div className="flex justify-center space-x-4">
              <a
                href="/contact"
                className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors"
              >
                Contact Us
                <ArrowRight className="w-5 h-5 ml-2" />
              </a>
              <a
                href="/docs"
                className={`inline-flex items-center px-6 py-3 rounded-lg ${
                  isDarkMode
                    ? 'bg-gray-800 text-white hover:bg-gray-700'
                    : 'bg-white text-gray-900 hover:bg-gray-50'
                } transition-colors`}
              >
                Our Technology
                <Cpu className="w-5 h-5 ml-2" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Service Categories */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h2 className={`text-2xl font-bold mb-12 text-center ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Technology Categories
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {serviceCategories.map((category) => {
            const Icon = category.icon;
            return (
              <div
                key={category.id}
                className={`p-6 rounded-xl border ${
                  isDarkMode 
                    ? 'bg-gray-800 border-gray-700' 
                    : 'bg-white border-gray-200'
                }`}
              >
                <div className="w-12 h-12 rounded-lg bg-blue-600 flex items-center justify-center mb-4">
                  <Icon className="w-6 h-6 text-white" />
                </div>
                <h3 className={`text-xl font-bold mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {category.title}
                </h3>
                <p className={`mb-6 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {category.description}
                </p>
                <ul className="space-y-3">
                  {category.features.map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <CheckCircle2 className={`w-5 h-5 mr-2 flex-shrink-0 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-500'
                      }`} />
                      <span className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                        {feature}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>

      {/* Service Providers */}
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} py-16`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className={`text-2xl font-bold mb-12 text-center ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Our Service Providers
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {serviceProviders.map((provider) => (
              <a
                key={provider.id}
                href={provider.link}
                target="_blank"
                rel="noopener noreferrer"
                className={`p-6 rounded-lg border group transition-colors ${
                  isDarkMode 
                    ? 'bg-gray-900 border-gray-700 hover:border-blue-500' 
                    : 'bg-gray-50 border-gray-200 hover:border-blue-500'
                }`}
              >
                <div className="flex items-center justify-between mb-4">
                  <div className={`text-sm font-medium ${
                    isDarkMode ? 'text-blue-400' : 'text-blue-600'
                  }`}>
                    {provider.type}
                  </div>
                  <ExternalLink className="w-4 h-4 text-gray-400" />
                </div>
                <h3 className={`text-xl font-bold mb-3 group-hover:text-blue-500 transition-colors ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {provider.name}
                </h3>
                <p className={`${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {provider.description}
                </p>
              </a>
            ))}
          </div>
        </div>
      </div>

      {/* Integration Examples */}
      

      {/* Platform Stats */}
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} py-16`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div className="text-center">
              <Globe className={`w-8 h-8 mx-auto mb-4 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div className={`text-3xl font-bold mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                4+
              </div>
              <div className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                Technology Providers
              </div>
            </div>
            <div className="text-center">
              <LineChart className={`w-8 h-8 mx-auto mb-4 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div className={`text-3xl font-bold mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                1B+
              </div>
              <div className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                Market Data Points
              </div>
            </div>
            <div className="text-center">
              <BarChart className={`w-8 h-8 mx-auto mb-4 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div className={`text-3xl font-bold mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                1K+
              </div>
              <div className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                Research Analyses
              </div>
            </div>
            <div className="text-center">
              <BadgeCheck className={`w-8 h-8 mx-auto mb-4 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div className={`text-3xl font-bold mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                99.9%
              </div>
              <div className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                Uptime
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;