import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

// Define available languages
export type Language = {
  code: string;
  name: string;
  flag: string;
};

export const LANGUAGES: Language[] = [
  { code: 'en', name: 'English', flag: '🇺🇸' },
  { code: 'es', name: 'Español', flag: '🇪🇸' },
  { code: 'fr', name: 'Français', flag: '🇫🇷' },
  { code: 'de', name: 'Deutsch', flag: '🇩🇪' },
  { code: 'ja', name: '日本語', flag: '🇯🇵' },
  { code: 'zh', name: '中文', flag: '🇨🇳' },
];

// Translation strings interface
export interface Translations {
  [key: string]: {
    [key: string]: string;
  };
}

// Create translation content
export const TRANSLATIONS: Translations = {
  en: {
    prism: 'Prism',
    canvas: 'Canvas',
    pro: 'Pro',
    docs: 'Docs',
    help: 'Help',
    login: 'Login',
    getStarted: 'Get Started',
    launchDashboard: 'Launch Dashboard',
    selectLanguage: 'Select Language',
    // Home2 translations
    marketQuestion: 'What do you want to know about the markets?',
    marketSubtitle: 'Ask anything and watch as Prism conducts real-time market research with live financial data and web search.',
    searchPlaceholder: 'Ask anything about markets and investing...',
    searchPlaceholderWithChip: 'Add details or press Enter...',
    loadingMarketPulse: 'Loading Market Pulse',
    loadingFirstTime: 'This can take a while if it\'s your first time',
    trendingTopics: 'Trending Topics',
    clickTopicBelow: 'Click any topic below to get AI-powered market insights',
    trending: 'Trending',
    trendingStocks: 'Trending Stocks',
    marketMoversToday: 'Market movers catching attention today',
    moreTopicsToExplore: 'More Topics to Explore',
    readyToDiveDeeper: 'Ready to dive deeper?',
    signUpForFree: 'Sign up for free to unlock unlimited market research, real-time analysis, and personalized insights.',
    getStartedForFree: 'Get Started For Free',
    meetYourMarketOracle: 'Meet Your',
    marketOracle: 'Market Oracle',
    experienceNewDimension: 'Experience a new dimension of market intelligence with Prism, your AI companion for navigating the financial universe.',
    tryItYourself: 'Try it yourself',
    researchModeHint: 'Research modes influence how Claude approaches your query',
    standardMode: 'Standard',
    standardModeDesc: 'Balanced research approach for general market inquiries',
    contrarianMode: 'Contrarian',
    contrarianModeDesc: 'Challenge market consensus and explore alternative views',
    discoveryMode: 'Discovery',
    discoveryModeDesc: 'Explore new market opportunities and emerging trends',
    deepDiveMode: 'Deep Dive',
    deepDiveModeDesc: 'Thorough analysis with comprehensive data examination',
    technicalMode: 'Technical',
    technicalModeDesc: 'Focus on technical indicators and chart patterns',
    subscribeNewsletter: 'Subscribe to our newsletter',
    getLatestNews: 'Get the latest news and updates from our team',
    enterEmail: 'Enter your email',
    subscribe: 'Subscribe',
    subscribing: 'Subscribing...',
    subscribeSuccess: 'Thank you for subscribing to our newsletter!',
    newsletterError: 'Failed to subscribe to newsletter',
    allRightsReserved: 'All rights reserved.',
    
    // Footer section titles
    'footer.product': 'Product',
    'footer.company': 'Company',
    'footer.resources': 'Resources',
    'footer.legal': 'Legal',
    
    // Footer links (lowercase versions of the link names)
    'footer.prism': 'Prism',
    'footer.canvas': 'Canvas',
    'footer.pricing': 'Pricing',
    'footer.documentation': 'Documentation',
    'footer.release notes': 'Release Notes',
    'footer.about': 'About',
    'footer.careers': 'Careers',
    'footer.press': 'Press',
    'footer.contact': 'Contact',
    'footer.partners': 'Partners',
    'footer.blog': 'Blog',
    'footer.newsletter': 'Newsletter',
    'footer.events': 'Events',
    'footer.help center': 'Help Center',
    'footer.tutorials': 'Tutorials',
    'footer.privacy': 'Privacy',
    'footer.terms': 'Terms',
    'footer.security': 'Security',
    'footer.cookies': 'Cookies',
  },
  es: {
    prism: 'Prisma',
    canvas: 'Lienzo',
    pro: 'Pro',
    docs: 'Documentos',
    help: 'Ayuda',
    login: 'Iniciar Sesión',
    getStarted: 'Comenzar',
    launchDashboard: 'Ir al Panel',
    selectLanguage: 'Seleccionar Idioma',
    // Home2 translations
    marketQuestion: '¿Qué quieres saber sobre los mercados?',
    marketSubtitle: 'Pregunta lo que quieras y observa cómo Prism realiza investigaciones de mercado en tiempo real con datos financieros en vivo y búsqueda web.',
    searchPlaceholder: 'Pregunta lo que quieras sobre mercados e inversiones...',
    searchPlaceholderWithChip: 'Añade detalles o pulsa Enter...',
    loadingMarketPulse: 'Cargando Pulso de Mercado',
    loadingFirstTime: 'Esto puede tardar un poco si es tu primera vez',
    trendingTopics: 'Temas Tendencia',
    clickTopicBelow: 'Haz clic en cualquier tema para obtener información del mercado con IA',
    trending: 'Tendencia',
    trendingStocks: 'Acciones Tendencia',
    marketMoversToday: 'Movimientos del mercado que llaman la atención hoy',
    moreTopicsToExplore: 'Más Temas para Explorar',
    readyToDiveDeeper: '¿Listo para profundizar?',
    signUpForFree: 'Regístrate gratis para desbloquear investigación de mercado ilimitada, análisis en tiempo real e información personalizada.',
    getStartedForFree: 'Comienza Gratis',
    meetYourMarketOracle: 'Conoce Tu',
    marketOracle: 'Oráculo del Mercado',
    experienceNewDimension: 'Experimenta una nueva dimensión de inteligencia de mercado con Prism, tu compañero de IA para navegar el universo financiero.',
    tryItYourself: 'Pruébalo tú mismo',
    researchModeHint: 'Los modos de investigación influyen en cómo Claude aborda tu consulta',
    standardMode: 'Estándar',
    standardModeDesc: 'Enfoque de investigación equilibrado para consultas generales del mercado',
    contrarianMode: 'Contrario',
    contrarianModeDesc: 'Cuestiona el consenso del mercado y explora puntos de vista alternativos',
    discoveryMode: 'Descubrimiento',
    discoveryModeDesc: 'Explora nuevas oportunidades de mercado y tendencias emergentes',
    deepDiveMode: 'Análisis Profundo',
    deepDiveModeDesc: 'Análisis exhaustivo con examen completo de datos',
    technicalMode: 'Técnico',
    technicalModeDesc: 'Enfoque en indicadores técnicos y patrones de gráficos',
    subscribeNewsletter: 'Suscríbete a nuestro boletín',
    getLatestNews: 'Recibe las últimas noticias y actualizaciones de nuestro equipo',
    enterEmail: 'Introduce tu email',
    subscribe: 'Suscribirse',
    subscribing: 'Suscribiendo...',
    subscribeSuccess: '¡Gracias por suscribirte a nuestro boletín!',
    newsletterError: 'Error al suscribirse al boletín',
    allRightsReserved: 'Todos los derechos reservados.',
    
    // Footer section titles
    'footer.product': 'Producto',
    'footer.company': 'Empresa',
    'footer.resources': 'Recursos',
    'footer.legal': 'Legal',
    
    // Footer links
    'footer.prism': 'Prisma',
    'footer.canvas': 'Lienzo',
    'footer.pricing': 'Precios',
    'footer.documentation': 'Documentación',
    'footer.release notes': 'Notas de versión',
    'footer.about': 'Acerca de',
    'footer.careers': 'Carreras',
    'footer.press': 'Prensa',
    'footer.contact': 'Contacto',
    'footer.partners': 'Socios',
    'footer.blog': 'Blog',
    'footer.newsletter': 'Boletín',
    'footer.events': 'Eventos',
    'footer.help center': 'Centro de Ayuda',
    'footer.tutorials': 'Tutoriales',
    'footer.privacy': 'Privacidad',
    'footer.terms': 'Términos',
    'footer.security': 'Seguridad',
    'footer.cookies': 'Cookies',
  },
  fr: {
    prism: 'Prisme',
    canvas: 'Toile',
    pro: 'Pro',
    docs: 'Documents',
    help: 'Aide',
    login: 'Connexion',
    getStarted: 'Commencer',
    launchDashboard: 'Tableau de Bord',
    selectLanguage: 'Choisir la Langue',
    // Home2 translations
    marketQuestion: 'Que voulez-vous savoir sur les marchés?',
    marketSubtitle: 'Posez n\'importe quelle question et regardez Prism effectuer des recherches de marché en temps réel avec des données financières et des recherches web.',
    searchPlaceholder: 'Posez n\'importe quelle question sur les marchés et l\'investissement...',
    searchPlaceholderWithChip: 'Ajoutez des détails ou appuyez sur Entrée...',
    loadingMarketPulse: 'Chargement du Pouls du Marché',
    loadingFirstTime: 'Cela peut prendre un moment s\'il s\'agit de votre première utilisation',
    trendingTopics: 'Sujets Tendance',
    clickTopicBelow: 'Cliquez sur un sujet ci-dessous pour obtenir des informations de marché alimentées par l\'IA',
    trending: 'Tendance',
    trendingStocks: 'Actions Tendance',
    marketMoversToday: 'Mouvements du marché qui attirent l\'attention aujourd\'hui',
    moreTopicsToExplore: 'Plus de Sujets à Explorer',
    readyToDiveDeeper: 'Prêt à approfondir?',
    signUpForFree: 'Inscrivez-vous gratuitement pour débloquer des recherches de marché illimitées, des analyses en temps réel et des informations personnalisées.',
    getStartedForFree: 'Commencer Gratuitement',
    meetYourMarketOracle: 'Rencontrez Votre',
    marketOracle: 'Oracle du Marché',
    experienceNewDimension: 'Découvrez une nouvelle dimension d\'intelligence de marché avec Prism, votre compagnon IA pour naviguer dans l\'univers financier.',
    tryItYourself: 'Essayez-le vous-même',
    researchModeHint: 'Les modes de recherche influencent la façon dont Claude aborde votre question',
    standardMode: 'Standard',
    standardModeDesc: 'Approche de recherche équilibrée pour les questions générales sur le marché',
    contrarianMode: 'Contrarian',
    contrarianModeDesc: 'Remettez en question le consensus du marché et explorez des points de vue alternatifs',
    discoveryMode: 'Découverte',
    discoveryModeDesc: 'Explorez de nouvelles opportunités de marché et tendances émergentes',
    deepDiveMode: 'Analyse Approfondie',
    deepDiveModeDesc: 'Analyse approfondie avec examen complet des données',
    technicalMode: 'Technique',
    technicalModeDesc: 'Concentration sur les indicateurs techniques et les motifs graphiques',
    subscribeNewsletter: 'Abonnez-vous à notre newsletter',
    getLatestNews: 'Recevez les dernières nouvelles et mises à jour de notre équipe',
    enterEmail: 'Entrez votre email',
    subscribe: 'S\'abonner',
    subscribing: 'Abonnement en cours...',
    subscribeSuccess: 'Merci de vous être abonné à notre newsletter!',
    newsletterError: 'Échec de l\'abonnement à la newsletter',
    allRightsReserved: 'Tous droits réservés.',
    
    // Footer section titles
    'footer.product': 'Produit',
    'footer.company': 'Entreprise',
    'footer.resources': 'Ressources',
    'footer.legal': 'Mentions légales',
    
    // Footer links
    'footer.prism': 'Prisme',
    'footer.canvas': 'Toile',
    'footer.pricing': 'Tarifs',
    'footer.documentation': 'Documentation',
    'footer.release notes': 'Notes de version',
    'footer.about': 'À propos',
    'footer.careers': 'Carrières',
    'footer.press': 'Presse',
    'footer.contact': 'Contact',
    'footer.partners': 'Partenaires',
    'footer.blog': 'Blog',
    'footer.newsletter': 'Newsletter',
    'footer.events': 'Événements',
    'footer.help center': 'Centre d\'aide',
    'footer.tutorials': 'Tutoriels',
    'footer.privacy': 'Confidentialité',
    'footer.terms': 'Conditions',
    'footer.security': 'Sécurité',
    'footer.cookies': 'Cookies',
  },
  de: {
    prism: 'Prisma',
    canvas: 'Leinwand',
    pro: 'Pro',
    docs: 'Dokumente',
    help: 'Hilfe',
    login: 'Anmelden',
    getStarted: 'Loslegen',
    launchDashboard: 'Dashboard Starten',
    selectLanguage: 'Sprache Wählen',
    // Home2 translations
    marketQuestion: 'Was möchten Sie über die Märkte wissen?',
    marketSubtitle: 'Stellen Sie beliebige Fragen und sehen Sie, wie Prism Echtzeit-Marktforschung mit aktuellen Finanzdaten und Websuche durchführt.',
    searchPlaceholder: 'Stellen Sie beliebige Fragen zu Märkten und Investitionen...',
    searchPlaceholderWithChip: 'Details hinzufügen oder Enter drücken...',
    loadingMarketPulse: 'Marktpuls wird geladen',
    loadingFirstTime: 'Dies kann eine Weile dauern, wenn es Ihr erstes Mal ist',
    trendingTopics: 'Trendthemen',
    clickTopicBelow: 'Klicken Sie auf ein Thema, um KI-gestützte Markteinblicke zu erhalten',
    trending: 'Trend',
    trendingStocks: 'Trendaktien',
    marketMoversToday: 'Marktbeweger, die heute Aufmerksamkeit erregen',
    moreTopicsToExplore: 'Weitere Themen zur Erkundung',
    readyToDiveDeeper: 'Bereit für tiefere Einblicke?',
    signUpForFree: 'Registrieren Sie sich kostenlos, um unbegrenzte Marktforschung, Echtzeit-Analysen und personalisierte Einblicke freizuschalten.',
    getStartedForFree: 'Kostenlos Starten',
    meetYourMarketOracle: 'Treffen Sie Ihr',
    marketOracle: 'Marktorakel',
    experienceNewDimension: 'Erleben Sie eine neue Dimension der Marktintelligenz mit Prism, Ihrem KI-Begleiter für die Navigation im Finanzuniversum.',
    tryItYourself: 'Probieren Sie es selbst',
    researchModeHint: 'Forschungsmodi beeinflussen, wie Claude Ihre Anfrage bearbeitet',
    standardMode: 'Standard',
    standardModeDesc: 'Ausgewogener Forschungsansatz für allgemeine Marktanfragen',
    contrarianMode: 'Konträr',
    contrarianModeDesc: 'Hinterfragen Sie den Marktkonsens und erkunden Sie alternative Ansichten',
    discoveryMode: 'Entdeckung',
    discoveryModeDesc: 'Erkunden Sie neue Marktchancen und aufkommende Trends',
    deepDiveMode: 'Tiefenanalyse',
    deepDiveModeDesc: 'Gründliche Analyse mit umfassender Datenprüfung',
    technicalMode: 'Technisch',
    technicalModeDesc: 'Fokus auf technische Indikatoren und Chartmuster',
    subscribeNewsletter: 'Abonnieren Sie unseren Newsletter',
    getLatestNews: 'Erhalten Sie die neuesten Nachrichten und Updates von unserem Team',
    enterEmail: 'E-Mail eingeben',
    subscribe: 'Abonnieren',
    subscribing: 'Wird abonniert...',
    subscribeSuccess: 'Vielen Dank für das Abonnieren unseres Newsletters!',
    newsletterError: 'Fehler beim Abonnieren des Newsletters',
    allRightsReserved: 'Alle Rechte vorbehalten.',
    
    // Footer section titles
    'footer.product': 'Produkt',
    'footer.company': 'Unternehmen',
    'footer.resources': 'Ressourcen',
    'footer.legal': 'Rechtliches',
    
    // Footer links
    'footer.prism': 'Prisma',
    'footer.canvas': 'Leinwand',
    'footer.pricing': 'Preise',
    'footer.documentation': 'Dokumentation',
    'footer.release notes': 'Versionshinweise',
    'footer.about': 'Über uns',
    'footer.careers': 'Karriere',
    'footer.press': 'Presse',
    'footer.contact': 'Kontakt',
    'footer.partners': 'Partner',
    'footer.blog': 'Blog',
    'footer.newsletter': 'Newsletter',
    'footer.events': 'Veranstaltungen',
    'footer.help center': 'Hilfezentrum',
    'footer.tutorials': 'Tutorials',
    'footer.privacy': 'Datenschutz',
    'footer.terms': 'Nutzungsbedingungen',
    'footer.security': 'Sicherheit',
    'footer.cookies': 'Cookies',
  },
  ja: {
    prism: 'プリズム',
    canvas: 'キャンバス',
    pro: 'プロ',
    docs: '資料',
    help: 'ヘルプ',
    login: 'ログイン',
    getStarted: '始める',
    launchDashboard: 'ダッシュボードを開く',
    selectLanguage: '言語を選択',
    // Home2 translations
    marketQuestion: '市場について何を知りたいですか？',
    marketSubtitle: '質問をすると、Prismがライブの金融データとウェブ検索を使用してリアルタイムの市場調査を行います。',
    searchPlaceholder: '市場や投資について何でも質問してください...',
    searchPlaceholderWithChip: '詳細を追加するか、Enterキーを押してください...',
    loadingMarketPulse: 'マーケットパルスを読み込み中',
    loadingFirstTime: '初めての場合は時間がかかることがあります',
    trendingTopics: 'トレンドトピック',
    clickTopicBelow: '以下のトピックをクリックしてAI駆動の市場洞察を得る',
    trending: 'トレンド',
    trendingStocks: 'トレンド銘柄',
    marketMoversToday: '今日注目を集めている市場の動き',
    moreTopicsToExplore: '探索するその他のトピック',
    readyToDiveDeeper: 'さらに深く掘り下げる準備はできましたか？',
    signUpForFree: '無制限の市場調査、リアルタイム分析、パーソナライズされた洞察のためにロック解除するには無料でサインアップしてください。',
    getStartedForFree: '無料で始める',
    meetYourMarketOracle: 'あなたの',
    marketOracle: '市場オラクル',
    experienceNewDimension: 'Prismで市場インテリジェンスの新次元を体験しましょう。金融世界をナビゲートするためのあなたのAIコンパニオンです。',
    tryItYourself: '自分で試してみる',
    researchModeHint: 'リサーチモードはClaudeがあなたの質問にアプローチする方法に影響します',
    standardMode: '標準',
    standardModeDesc: '一般的な市場の問い合わせのためのバランスの取れたリサーチアプローチ',
    contrarianMode: '逆張り',
    contrarianModeDesc: '市場のコンセンサスに挑戦し、代替的な見解を探る',
    discoveryMode: '発見',
    discoveryModeDesc: '新しい市場機会と新興トレンドを探る',
    deepDiveMode: '深掘り',
    deepDiveModeDesc: '包括的なデータ検査による徹底的な分析',
    technicalMode: 'テクニカル',
    technicalModeDesc: 'テクニカル指標とチャートパターンに焦点を当てる',
    subscribeNewsletter: 'ニュースレターを購読する',
    getLatestNews: '私たちのチームからの最新ニュースと更新情報を入手する',
    enterEmail: 'メールアドレスを入力',
    subscribe: '購読する',
    subscribing: '購読中...',
    subscribeSuccess: 'ニュースレターを購読いただきありがとうございます！',
    newsletterError: 'ニュースレターの購読に失敗しました',
    allRightsReserved: '無断複製禁止。',
    
    // Footer section titles
    'footer.product': '製品',
    'footer.company': '会社',
    'footer.resources': 'リソース',
    'footer.legal': '法的情報',
    
    // Footer links
    'footer.prism': 'プリズム',
    'footer.canvas': 'キャンバス',
    'footer.pricing': '料金',
    'footer.documentation': 'ドキュメンテーション',
    'footer.release notes': 'リリースノート',
    'footer.about': '会社概要',
    'footer.careers': '採用情報',
    'footer.press': 'プレス',
    'footer.contact': 'お問い合わせ',
    'footer.partners': 'パートナー',
    'footer.blog': 'ブログ',
    'footer.newsletter': 'ニュースレター',
    'footer.events': 'イベント',
    'footer.help center': 'ヘルプセンター',
    'footer.tutorials': 'チュートリアル',
    'footer.privacy': 'プライバシー',
    'footer.terms': '利用規約',
    'footer.security': 'セキュリティ',
    'footer.cookies': 'クッキー',
  },
  zh: {
    prism: '棱镜',
    canvas: '画布',
    pro: '专业版',
    docs: '文档',
    help: '帮助',
    login: '登录',
    getStarted: '开始使用',
    launchDashboard: '打开仪表板',
    selectLanguage: '选择语言',
    // Home2 translations
    marketQuestion: '您想了解市场的什么信息？',
    marketSubtitle: '提出任何问题，观看Prism如何通过实时金融数据和网络搜索进行实时市场研究。',
    searchPlaceholder: '询问关于市场和投资的任何问题...',
    searchPlaceholderWithChip: '添加详细信息或按回车键...',
    loadingMarketPulse: '正在加载市场脉搏',
    loadingFirstTime: '如果这是您第一次使用，可能需要一些时间',
    trendingTopics: '热门话题',
    clickTopicBelow: '点击下方任何话题获取AI驱动的市场洞察',
    trending: '趋势',
    trendingStocks: '热门股票',
    marketMoversToday: '今日引人注目的市场变动',
    moreTopicsToExplore: '更多探索话题',
    readyToDiveDeeper: '准备深入了解？',
    signUpForFree: '免费注册以解锁无限市场研究、实时分析和个性化洞察。',
    getStartedForFree: '免费开始',
    meetYourMarketOracle: '认识您的',
    marketOracle: '市场预言家',
    experienceNewDimension: '通过Prism体验市场智能的新维度，它是您导航金融世界的AI伙伴。',
    tryItYourself: '亲自尝试',
    researchModeHint: '研究模式影响Claude处理您查询的方式',
    standardMode: '标准',
    standardModeDesc: '针对一般市场查询的平衡研究方法',
    contrarianMode: '逆势',
    contrarianModeDesc: '挑战市场共识并探索替代观点',
    discoveryMode: '发现',
    discoveryModeDesc: '探索新的市场机会和新兴趋势',
    deepDiveMode: '深度分析',
    deepDiveModeDesc: '通过全面的数据检查进行彻底分析',
    technicalMode: '技术',
    technicalModeDesc: '专注于技术指标和图表模式',
    subscribeNewsletter: '订阅我们的通讯',
    getLatestNews: '获取我们团队的最新消息和更新',
    enterEmail: '输入您的电子邮箱',
    subscribe: '订阅',
    subscribing: '订阅中...',
    subscribeSuccess: '感谢您订阅我们的通讯！',
    newsletterError: '订阅通讯失败',
    allRightsReserved: '版权所有。',
    
    // Footer section titles
    'footer.product': '产品',
    'footer.company': '公司',
    'footer.resources': '资源',
    'footer.legal': '法律',
    
    // Footer links
    'footer.prism': '棱镜',
    'footer.canvas': '画布',
    'footer.pricing': '定价',
    'footer.documentation': '文档',
    'footer.release notes': '发布说明',
    'footer.about': '关于我们',
    'footer.careers': '招聘',
    'footer.press': '新闻',
    'footer.contact': '联系我们',
    'footer.partners': '合作伙伴',
    'footer.blog': '博客',
    'footer.newsletter': '通讯',
    'footer.events': '活动',
    'footer.help center': '帮助中心',
    'footer.tutorials': '教程',
    'footer.privacy': '隐私政策',
    'footer.terms': '条款',
    'footer.security': '安全',
    'footer.cookies': 'Cookie政策',
  },
};

interface LanguageContextType {
  currentLanguage: Language;
  setLanguage: (language: Language) => void;
  t: (key: string, params?: Record<string, string | number>) => string;
  formatDate: (date: Date, options?: Intl.DateTimeFormatOptions) => string;
  formatNumber: (number: number, options?: Intl.NumberFormatOptions) => string;
  formatCurrency: (amount: number, currency?: string) => string;
  getUserPreferredLanguage: () => Language;
}

const LanguageContext = createContext<LanguageContextType>({
  currentLanguage: LANGUAGES[0],
  setLanguage: () => {},
  t: (key: string) => key,
  formatDate: (date: Date) => date.toString(),
  formatNumber: (number: number) => number.toString(),
  formatCurrency: (amount: number) => amount.toString(),
  getUserPreferredLanguage: () => LANGUAGES[0],
});

export const useLanguage = () => useContext(LanguageContext);

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState(LANGUAGES[0]);

  // Load saved language preference from localStorage on initial load
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      const language = LANGUAGES.find(lang => lang.code === savedLanguage);
      if (language) {
        setCurrentLanguage(language);
      }
    } else {
      // Auto-detect language if nothing is saved
      setCurrentLanguage(getUserPreferredLanguage());
    }
  }, []);

  // Translation function
  const t = (key: string, params?: Record<string, string | number>): string => {
    let text = '';
    
    if (TRANSLATIONS[currentLanguage.code] && TRANSLATIONS[currentLanguage.code][key]) {
      text = TRANSLATIONS[currentLanguage.code][key];
    } else {
      // Fallback to English
      text = TRANSLATIONS.en[key] || key;
    }
    
    // Replace parameters in the format {{paramName}}
    if (params) {
      Object.keys(params).forEach(param => {
        text = text.replace(new RegExp(`{{${param}}}`, 'g'), String(params[param]));
      });
    }
    
    return text;
  };

  // Function to detect user's preferred language
  const getUserPreferredLanguage = (): Language => {
    const browserLanguages = navigator.languages || [navigator.language];
    
    // Try to match browser languages with our available languages
    for (const browserLang of browserLanguages) {
      const code = browserLang.split('-')[0]; // Get the language part of the locale
      const match = LANGUAGES.find(lang => lang.code === code);
      if (match) return match;
    }
    
    return LANGUAGES[0]; // Default to English if no match
  };

  // Add these formatters
  const formatDate = (date: Date, options?: Intl.DateTimeFormatOptions): string => {
    return new Intl.DateTimeFormat(currentLanguage.code, options).format(date);
  };

  const formatNumber = (number: number, options?: Intl.NumberFormatOptions): string => {
    return new Intl.NumberFormat(currentLanguage.code, options).format(number);
  };

  const formatCurrency = (amount: number, currency = 'USD'): string => {
    return new Intl.NumberFormat(currentLanguage.code, {
      style: 'currency',
      currency
    }).format(amount);
  };

  const setLanguage = (language: Language) => {
    setCurrentLanguage(language);
    localStorage.setItem('language', language.code);
    // Set HTML lang attribute for accessibility
    document.documentElement.lang = language.code;
  };

  return (
    <LanguageContext.Provider value={{ 
      currentLanguage, 
      setLanguage, 
      t, 
      formatDate,
      formatNumber,
      formatCurrency,
      getUserPreferredLanguage
    }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;