import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Activity,
  Search,
  Brain,
  Globe,
  Database,
  LineChart,
  ChevronDown,
  ChevronUp,
  Clock,
  Target,
  Zap,
  BarChart3,
  AlertCircle,
  ChevronRight
} from 'lucide-react';

const ResearchProgress = () => {
  const { isDarkMode } = useTheme();

  const sections = [
    {
      title: 'Overview Display',
      description: 'The main research progress interface showing the current stage, overall completion percentage, and a brief status message.',
      items: [
        'Current stage indicator with icon and label',
        'Progress bar showing overall completion percentage',
        'Status message describing the current action',
        'Estimated time remaining (for longer research processes)'
      ],
      image: '/images/docs/research-progress-overview.png'
    },
    {
      title: 'Research Stages Timeline',
      description: 'Visual timeline showing all research stages with completed, current, and pending states clearly marked.',
      items: [
        'All five research stages displayed sequentially',
        'Completed stages marked with checkmarks',
        'Current stage highlighted with animated indicator',
        'Pending stages shown in a neutral state',
        'Stage-specific progress indicators'
      ],
      image: '/images/docs/research-progress-timeline.png'
    },
    {
      title: 'Source Discovery',
      description: 'Real-time updates showing sources being discovered and evaluated during the research process.',
      items: [
        'Source type categorization (Web, Market Data, News, etc.)',
        'Source name and timestamp',
        'Confidence ratings for each source',
        'Related tickers when applicable',
        'Total source count by category'
      ],
      image: '/images/docs/research-progress-sources.png'
    },
    {
      title: 'Stock Identification',
      description: 'View stocks being identified and analyzed during the research process with relevance indicators.',
      items: [
        'Discovered ticker symbols with company names',
        'Relevance scores showing how central each stock is to the query',
        'Industry and sector classification when available',
        'Visual indicators for primary vs. secondary relevance'
      ],
      image: '/images/docs/research-progress-tickers.png'
    },
    {
      title: 'Research Context',
      description: 'Insights into how Prism is interpreting your query and forming its research approach.',
      items: [
        'Detected research intent (technical analysis, fundamental research, etc.)',
        'Time frame interpretation (short-term, long-term, specific period)',
        'Key concepts and entities extracted from your query',
        'Strategy explanation for the chosen research path'
      ],
      image: '/images/docs/research-progress-context.png'
    }
  ];

  const interactionTips = [
    {
      title: 'Expanding and Collapsing',
      description: 'Click the chevron icons to expand or collapse the research progress panel, adjusting the level of detail shown.',
      icon: ChevronDown
    },
    {
      title: 'Source Filtering',
      description: 'Use the filter options to focus on specific source types or confidence levels to streamline the information displayed.',
      icon: Search
    },
    {
      title: 'Time Tracking',
      description: 'Monitor elapsed time and estimated completion to plan your workflow around longer research tasks.',
      icon: Clock
    },
    {
      title: 'Following Specific Stocks',
      description: 'Click on discovered ticker symbols to highlight all research related to that specific company.',
      icon: Target
    }
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Activity className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Viewing Research Progress
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                How to track and interpret Prism's research process in real-time
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className={`mb-12 p-6 rounded-xl border ${
            isDarkMode ? 'bg-gray-800/50 border-gray-700' : 'bg-white/50 border-gray-200'
          }`}>
            <div className="flex items-start">
              <AlertCircle className={`w-6 h-6 mt-1 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Why Research Progress Matters</h3>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  Prism provides a transparent view into its research process, letting you monitor how information is being gathered and analyzed in real-time. This visibility helps you understand where insights come from, evaluate source quality, and track the thoroughness of the analysis behind Prism's responses.
                </p>
              </div>
            </div>
          </div>

          {/* Research Progress Interface */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Understanding the Research Progress Interface
          </h2>
          
          <div className="space-y-10 mb-16">
            {sections.map((section, index) => (
              <div key={index} className={`p-6 rounded-xl border ${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
              }`}>
                <h3 className={`text-xl font-bold mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {section.title}
                </h3>
                
                <p className={`mb-6 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {section.description}
                </p>
                
                <div className="grid md:grid-cols-2 gap-8">
                  <div>
                    <h4 className={`text-sm font-medium mb-3 ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>Key Features:</h4>
                    <ul className={`space-y-2 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-600'
                    }`}>
                      {section.items.map((item, i) => (
                        <li key={i} className="flex items-start">
                          <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  
                  <div className={`rounded-lg overflow-hidden border ${
                    isDarkMode ? 'border-gray-700' : 'border-gray-200'
                  }`}>
                    <div className={`h-48 flex items-center justify-center ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                    }`}>
                      <p className={`text-sm ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        Research progress interface screenshot
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Research Stages Icons */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Research Stage Indicators
          </h2>
          
          <div className={`p-6 rounded-xl border mb-16 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={`mb-6 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              Each research stage has a unique icon to help you quickly identify the current phase of research.
            </p>
            
            <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
              <div className={`p-4 rounded-lg text-center ${
                isDarkMode ? 'bg-gray-900/50' : 'bg-gray-50'
              }`}>
                <Brain className={`w-8 h-8 mx-auto mb-2 ${
                  isDarkMode ? 'text-blue-400' : 'text-blue-600'
                }`} />
                <h4 className={`text-sm font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Query Analysis</h4>
              </div>
              
              <div className={`p-4 rounded-lg text-center ${
                isDarkMode ? 'bg-gray-900/50' : 'bg-gray-50'
              }`}>
                <Globe className={`w-8 h-8 mx-auto mb-2 ${
                  isDarkMode ? 'text-purple-400' : 'text-purple-600'
                }`} />
                <h4 className={`text-sm font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Web Research</h4>
              </div>
              
              <div className={`p-4 rounded-lg text-center ${
                isDarkMode ? 'bg-gray-900/50' : 'bg-gray-50'
              }`}>
                <Target className={`w-8 h-8 mx-auto mb-2 ${
                  isDarkMode ? 'text-green-400' : 'text-green-600'
                }`} />
                <h4 className={`text-sm font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Stock Identification</h4>
              </div>
              
              <div className={`p-4 rounded-lg text-center ${
                isDarkMode ? 'bg-gray-900/50' : 'bg-gray-50'
              }`}>
                <Database className={`w-8 h-8 mx-auto mb-2 ${
                  isDarkMode ? 'text-orange-400' : 'text-orange-600'
                }`} />
                <h4 className={`text-sm font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Data Collection</h4>
              </div>
              
              <div className={`p-4 rounded-lg text-center ${
                isDarkMode ? 'bg-gray-900/50' : 'bg-gray-50'
              }`}>
                <LineChart className={`w-8 h-8 mx-auto mb-2 ${
                  isDarkMode ? 'text-pink-400' : 'text-pink-600'
                }`} />
                <h4 className={`text-sm font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Analysis</h4>
              </div>
            </div>
          </div>

          {/* Source Type Indicators */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Source Type Indicators
          </h2>
          
          <div className={`p-6 rounded-xl border mb-16 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={`mb-6 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              Different source types are represented with distinctive colors and icons to help you quickly assess the information being gathered.
            </p>
            
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <div className={`p-4 rounded-lg ${
                isDarkMode ? 'bg-gray-900/50' : 'bg-gray-50'
              }`}>
                <div className="flex items-center mb-2">
                  <div className="w-8 h-8 rounded-full bg-blue-500/20 flex items-center justify-center mr-3">
                    <Globe className="w-4 h-4 text-blue-400" />
                  </div>
                  <h4 className={`text-sm font-medium ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>Web Sources</h4>
                </div>
                <p className={`text-xs ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  News sites, analyst reports, company pages
                </p>
              </div>
              
              <div className={`p-4 rounded-lg ${
                isDarkMode ? 'bg-gray-900/50' : 'bg-gray-50'
              }`}>
                <div className="flex items-center mb-2">
                  <div className="w-8 h-8 rounded-full bg-yellow-500/20 flex items-center justify-center mr-3">
                    <Zap className="w-4 h-4 text-yellow-400" />
                  </div>
                  <h4 className={`text-sm font-medium ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>News</h4>
                </div>
                <p className={`text-xs ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Breaking news, earnings reports, events
                </p>
              </div>
              
              <div className={`p-4 rounded-lg ${
                isDarkMode ? 'bg-gray-900/50' : 'bg-gray-50'
              }`}>
                <div className="flex items-center mb-2">
                  <div className="w-8 h-8 rounded-full bg-green-500/20 flex items-center justify-center mr-3">
                    <BarChart3 className="w-4 h-4 text-green-400" />
                  </div>
                  <h4 className={`text-sm font-medium ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>Market Data</h4>
                </div>
                <p className={`text-xs ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Price data, trading volumes, market metrics
                </p>
              </div>
              
              <div className={`p-4 rounded-lg ${
                isDarkMode ? 'bg-gray-900/50' : 'bg-gray-50'
              }`}>
                <div className="flex items-center mb-2">
                  <div className="w-8 h-8 rounded-full bg-purple-500/20 flex items-center justify-center mr-3">
                    <Database className="w-4 h-4 text-purple-400" />
                  </div>
                  <h4 className={`text-sm font-medium ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>Fundamental</h4>
                </div>
                <p className={`text-xs ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Financial statements, valuation metrics
                </p>
              </div>
            </div>
          </div>

          {/* Interaction Tips */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Interacting with the Research Panel
          </h2>
          
          <div className="grid md:grid-cols-2 gap-6 mb-16">
            {interactionTips.map((tip, index) => {
              const Icon = tip.icon;
              return (
                <div 
                  key={index}
                  className={`p-6 rounded-xl border ${
                    isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                  }`}
                >
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg mr-4 ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    }`}>
                      <Icon className={`w-5 h-5 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <h3 className={`font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {tip.title}
                    </h3>
                  </div>
                  
                  <p className={`${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {tip.description}
                  </p>
                </div>
              );
            })}
          </div>

          {/* Next Steps */}
          <div>
            <h2 className={`text-2xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Next Steps
            </h2>
            <div className="grid md:grid-cols-2 gap-6">
              <a
                href="/docs/export-research"
                className={`p-6 rounded-xl border flex items-center ${
                  isDarkMode 
                    ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                    : 'bg-white border-gray-200 hover:border-blue-500'
                } transition-colors`}
              >
                <div>
                  <h3 className={`font-medium mb-1 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    Exporting Research Results
                  </h3>
                  <p className={`text-sm ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`}>
                    How to save, export, and share research findings
                  </p>
                </div>
                <ChevronRight className={`ml-auto w-5 h-5 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`} />
              </a>
              <a
                href="/docs/create-watchlist"
                className={`p-6 rounded-xl border flex items-center ${
                  isDarkMode 
                    ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                    : 'bg-white border-gray-200 hover:border-blue-500'
                } transition-colors`}
              >
                <div>
                  <h3 className={`font-medium mb-1 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    Creating Watchlists
                  </h3>
                  <p className={`text-sm ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`}>
                    Save stocks discovered during research to custom watchlists
                  </p>
                </div>
                <ChevronRight className={`ml-auto w-5 h-5 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ResearchProgress;