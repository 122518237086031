import React, { useState, useCallback } from 'react';
import { 
  Pencil, 
  Square, 
  Circle, 
  Triangle,
  Type, 
  TrendingUp, 
  TrendingDown,
  ArrowUpRight,
  Redo2,
  Undo2,
  ChevronDown,
  Palette,
  Edit3,
  Trash2,
  LineChart,
  Ruler,
  MessageCircle,
  MousePointer,
  Move,
  LucideIcon
} from 'lucide-react';

interface Tool {
  id: string;
  icon: LucideIcon;
  label: string;
  group: string;
  description?: string;
}

interface GroupedTools {
  [key: string]: Tool[];
}

interface ChartToolbarProps {
  onToolSelect: (toolId: string) => void;
  onColorSelect: (color: string) => void;
  onUndo: () => void;
  onRedo: () => void;
  canUndo: boolean;
  canRedo: boolean;
  selectedTool: string;
  selectedColor: string;
}

const COLORS = [
  { value: '#3B82F6', label: 'Blue' },
  { value: '#10B981', label: 'Green' },
  { value: '#EF4444', label: 'Red' },
  { value: '#F59E0B', label: 'Yellow' },
  { value: '#8B5CF6', label: 'Purple' },
  { value: '#EC4899', label: 'Pink' },
  { value: '#FFFFFF', label: 'White' },
  { value: '#6B7280', label: 'Gray' }
];

const TOOLS: Tool[] = [
  {
    id: 'select',
    icon: MousePointer,
    label: 'Select',
    group: 'basic',
    description: 'Select and modify existing drawings'
  },
  {
    id: 'move',
    icon: Move,
    label: 'Move',
    group: 'basic',
    description: 'Pan and move around the chart'
  },
  {
    id: 'line',
    icon: LineChart,
    label: 'Trend Line',
    group: 'drawing',
    description: 'Draw trend lines and support/resistance levels'
  },
  {
    id: 'rectangle',
    icon: Square,
    label: 'Rectangle',
    group: 'drawing',
    description: 'Draw rectangles for highlighting areas'
  },
  {
    id: 'circle',
    icon: Circle,
    label: 'Circle',
    group: 'drawing',
    description: 'Draw circles for highlighting points'
  },
  {
    id: 'text',
    icon: Type,
    label: 'Text',
    group: 'annotation',
    description: 'Add text labels and annotations'
  },
  {
    id: 'note',
    icon: MessageCircle,
    label: 'Note',
    group: 'annotation',
    description: 'Add detailed notes with background'
  },
  {
    id: 'long',
    icon: TrendingUp,
    label: 'Long Position',
    group: 'trading',
    description: 'Mark long position entry and exit points'
  },
  {
   id: 'short',
    icon: TrendingDown,
    label: 'Short Position',
    group: 'trading',
    description: 'Mark short position entry and exit points'
  },
  {
    id: 'measure',
    icon: Ruler,
    label: 'Measure',
    group: 'analysis',
    description: 'Measure price distances and ranges'
  }
];

const ChartToolbar: React.FC<ChartToolbarProps> = ({ 
  onToolSelect, 
  onColorSelect, 
  onUndo, 
  onRedo,
  canUndo,
  canRedo,
  selectedTool,
  selectedColor 
}) => {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [tooltipContent, setTooltipContent] = useState<{ text: string; position: { x: number; y: number } } | null>(null);
  
  const groupedTools = TOOLS.reduce<GroupedTools>((acc, tool) => {
    const groupName = tool.group.charAt(0).toUpperCase() + tool.group.slice(1);
    if (!acc[groupName]) acc[groupName] = [];
    acc[groupName].push(tool);
    return acc;
  }, {});

  const handleToolSelect = useCallback((tool: Tool) => {
    onToolSelect(tool.id);
  }, [onToolSelect]);

  const handleColorSelect = useCallback((color: string) => {
    onColorSelect(color);
    setIsColorPickerOpen(false);
  }, [onColorSelect]);

  const showTooltip = (e: React.MouseEvent, content: string) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setTooltipContent({
      text: content,
      position: {
        x: rect.left + rect.width / 2,
        y: rect.bottom + 8
      }
    });
  };

  const hideTooltip = () => {
    setTooltipContent(null);
  };

  return (
    <div className="flex flex-col gap-2">
      {/* Main Toolbar */}
      <div className="flex items-center gap-2 p-2 rounded-lg bg-gray-900/90 backdrop-blur-sm border border-gray-800 shadow-lg">
        {/* Tool Groups */}
        {Object.entries(groupedTools).map(([groupName, tools]) => (
          <div key={groupName} className="flex items-center">
            <div className="flex items-center gap-1">
              {tools.map((tool) => (
                <button
                  key={tool.id}
                  onClick={() => handleToolSelect(tool)}
                  onMouseEnter={(e) => showTooltip(e, tool.description || tool.label)}
                  onMouseLeave={hideTooltip}
                  className={`p-2 rounded-lg transition-all ${
                    selectedTool === tool.id
                      ? 'bg-blue-500/20 text-blue-400 ring-1 ring-blue-500/50'
                      : 'text-gray-400 hover:bg-gray-800/50 hover:text-gray-300'
                  }`}
                >
                  <tool.icon className="w-5 h-5" />
                </button>
              ))}
            </div>
            {/* Group Separator */}
            <div className="w-px h-8 bg-gray-800 mx-2" />
          </div>
        ))}

        {/* Color Picker */}
        <div className="relative">
          <button
            onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
            onMouseEnter={(e) => showTooltip(e, 'Select Color')}
            onMouseLeave={hideTooltip}
            className="p-2 rounded-lg hover:bg-gray-800/50 text-gray-400 hover:text-gray-300"
          >
            <div className="flex items-center gap-1.5">
              <Palette className="w-5 h-5" />
              <div 
                className="w-4 h-4 rounded-full ring-1 ring-gray-700"
                style={{ backgroundColor: selectedColor }}
              />
            </div>
          </button>

          {isColorPickerOpen && (
            <div className="absolute top-full left-0 mt-2 p-3 bg-gray-900 rounded-lg border border-gray-800 shadow-xl z-50">
              <div className="grid grid-cols-4 gap-2">
                {COLORS.map((color) => (
                  <button
                    key={color.value}
                    onClick={() => handleColorSelect(color.value)}
                    onMouseEnter={(e) => showTooltip(e, color.label)}
                    onMouseLeave={hideTooltip}
                    className={`w-8 h-8 rounded-lg transition-all ${
                      selectedColor === color.value 
                        ? 'ring-2 ring-blue-500 ring-offset-2 ring-offset-gray-900' 
                        : 'ring-1 ring-gray-700 hover:ring-gray-600'
                    }`}
                    style={{ backgroundColor: color.value }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Undo/Redo */}
        <div className="flex items-center gap-1 ml-2 pl-2 border-l border-gray-800">
          <button
            onClick={onUndo}
            disabled={!canUndo}
            onMouseEnter={(e) => showTooltip(e, 'Undo (Ctrl+Z)')}
            onMouseLeave={hideTooltip}
            className={`p-2 rounded-lg transition-all ${
              canUndo 
                ? 'text-gray-400 hover:bg-gray-800/50 hover:text-gray-300' 
                : 'text-gray-600 cursor-not-allowed'
            }`}
          >
            <Undo2 className="w-5 h-5" />
          </button>
          <button
            onClick={onRedo}
            disabled={!canRedo}
            onMouseEnter={(e) => showTooltip(e, 'Redo (Ctrl+Y)')}
            onMouseLeave={hideTooltip}
            className={`p-2 rounded-lg transition-all ${
              canRedo 
                ? 'text-gray-400 hover:bg-gray-800/50 hover:text-gray-300' 
                : 'text-gray-600 cursor-not-allowed'
            }`}
          >
            <Redo2 className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Tooltip */}
      {tooltipContent && (
        <div
          className="fixed z-50 px-3 py-2 text-sm text-white bg-gray-900 rounded-lg border border-gray-800 shadow-lg pointer-events-none transition-opacity"
          style={{
            left: tooltipContent.position.x,
            top: tooltipContent.position.y,
            transform: 'translateX(-50%)'
          }}
        >
          {tooltipContent.text}
        </div>
      )}
    </div>
  );
};

export default ChartToolbar;