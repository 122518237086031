import React from 'react';
import { motion } from 'framer-motion';
import { AlertCircle, Clock, Zap } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useChatLimit } from '../../contexts/ChatLimitContext';
import { useTheme } from '../../contexts/ThemeContext';

const ChatLimitReached: React.FC = () => {
  const { limitState } = useChatLimit();
  const { isDarkMode } = useTheme();
  
  // Format the time until reset in a user-friendly way
  const formatTimeUntilReset = (): string => {
    if (!limitState.nextResetTime) return 'tomorrow';
    
    const now = new Date();
    const resetTime = new Date(limitState.nextResetTime);
    const diffMs = resetTime.getTime() - now.getTime();
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
    
    if (diffHours > 0) {
      return `in ${diffHours}h ${diffMinutes}m`;
    } else if (diffMinutes > 0) {
      return `in ${diffMinutes} minutes`;
    } else {
      return 'very soon';
    }
  };
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`max-w-lg mx-auto text-center p-8 rounded-xl border ${
        isDarkMode 
          ? 'bg-gray-800/80 border-gray-700' 
          : 'bg-white/90 border-gray-200'
      } shadow-lg backdrop-blur-sm`}
    >
      <div className="flex justify-center mb-6">
        <div className={`p-4 rounded-full ${
          isDarkMode ? 'bg-orange-900/30' : 'bg-orange-100'
        }`}>
          <AlertCircle className="w-8 h-8 text-orange-500" />
        </div>
      </div>
      
      <h2 className={`text-2xl font-bold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        Daily Chat Limit Reached
      </h2>
      
      <p className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
        You've used all {limitState.dailyChatsLimit} of your daily free Prism chats.
      </p>
      
      <div className={`flex items-center justify-center space-x-2 mb-8 ${
        isDarkMode ? 'text-blue-300' : 'text-blue-600'
      }`}>
        <Clock className="w-5 h-5" />
        <span className="font-medium">
          Your limit will reset {formatTimeUntilReset()}
        </span>
      </div>
      
      <div className="grid gap-4 md:grid-cols-2">
        <Link
          to="/pricing"
          className="flex items-center justify-center space-x-2 px-5 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-xl transition-colors shadow-md shadow-blue-500/20"
        >
          <Zap className="w-5 h-5" />
          <span className="font-medium">Upgrade to Pro</span>
        </Link>
        
        <Link
          to="/dashboard"
          className={`px-5 py-3 rounded-xl font-medium ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          } transition-colors`}
        >
          Go to Dashboard
        </Link>
      </div>
    </motion.div>
  );
};

export default ChatLimitReached;