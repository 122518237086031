import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Shield,
  Lock,
  Server,
  Key,
  Eye,
  FileText,
  Users,
  AlertTriangle,
  Database,
  RefreshCw,
  ChevronRight,
  Check,
  ShieldCheck,
  Info
} from 'lucide-react';

const Security = () => {
  const { isDarkMode } = useTheme();

  const securityFeatures = [
    {
      title: 'Data Encryption',
      description: 'How we protect your data both in transit and at rest.',
      icon: Lock,
      features: [
        'End-to-end encryption for all communication',
        'AES-256 encryption for all stored data',
        'TLS 1.3 for all data in transit',
        'Encrypted database backups',
        'Zero-knowledge architecture for sensitive information'
      ],
      standard: 'Complies with industry standard encryption protocols (NIST-approved)'
    },
    {
      title: 'Access Controls',
      description: 'Multi-layered access management system to protect account security.',
      icon: Key,
      features: [
        'Role-based access control (RBAC)',
        'Multi-factor authentication (MFA)',
        'Single sign-on (SSO) integration',
        'IP-based access restrictions',
        'Automatic session timeout'
      ],
      standard: 'Follows OWASP authentication best practices'
    },
    {
      title: 'Infrastructure Security',
      description: 'Secure hosting and system architecture designed for maximum protection.',
      icon: Server,
      features: [
        'SOC 2 Type II compliant cloud infrastructure',
        'Continuous vulnerability scanning',
        'Network segregation and firewalls',
        'Regular penetration testing',
        'Real-time threat monitoring'
      ],
      standard: 'Infrastructure meets ISO 27001 requirements'
    },
    {
      title: 'Audit & Compliance',
      description: 'Comprehensive logging and compliance standards.',
      icon: FileText,
      features: [
        'Detailed audit logs of all system activities',
        'Anomaly detection for suspicious behavior',
        'Compliance with financial industry regulations',
        'Regular security assessments',
        'Data privacy compliance (GDPR, CCPA)'
      ],
      standard: 'Maintains regulatory compliance with financial data protection standards'
    }
  ];

  const accountProtectionSections = [
    {
      title: 'Password Security',
      recommendations: [
        'Use a strong, unique password with at least 12 characters',
        'Enable multi-factor authentication',
        'Change passwords regularly',
        'Never share your password with anyone',
        'Use a password manager for secure storage'
      ]
    },
    {
      title: 'Access Management',
      recommendations: [
        'Review active sessions regularly',
        'Revoke access from devices you no longer use',
        'Enable automatic session timeout for inactive periods',
        'Use the "Log out all other sessions" feature if suspicious activity is detected'
      ]
    },
    {
      title: 'Account Monitoring',
      recommendations: [
        'Enable security alerts for login attempts',
        'Check account activity logs periodically',
        'Verify email and phone contact information is current',
        'Report any suspicious activity immediately to support'
      ]
    }
  ];

  const securityFAQs = [
    {
      question: 'How does Prism protect my financial data?',
      answer: 'All financial data is protected with enterprise-grade encryption both in transit and at rest. We implement multiple security layers including end-to-end encryption, secure infrastructure, and strict access controls. Our security practices follow industry standards and are regularly audited by third-party security experts.'
    },
    {
      question: 'Is my data shared with third parties?',
      answer: 'We never sell your data to third parties. We only share data with trusted service providers who help us deliver our services, and they are bound by strict confidentiality agreements. These providers must adhere to our security requirements and only process data as instructed by us.'
    },
    {
      question: 'What happens if there is a security incident?',
      answer: 'In the unlikely event of a security incident, we have a comprehensive incident response plan. This includes immediate containment, thorough investigation, prompt notification to affected users in accordance with applicable laws, and remediation to prevent similar issues in the future.'
    },
    {
      question: 'Can Prism employees see my financial information?',
      answer: 'Access to customer data is strictly limited to essential personnel who require it for customer support or maintaining system operations. All access is logged, monitored, and governed by strict internal policies. Employees undergo background checks and regular security training.'
    }
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Shield className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Security
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                How we protect your data and ensure platform security
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              At Prism, we understand the sensitivity of financial data and market research. Security is foundational 
              to our platform, not an afterthought. We've implemented comprehensive security measures at every level 
              of our infrastructure and application to ensure your data remains protected, private, and available only 
              to authorized users.
            </p>
          </div>

          {/* Security Features */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Platform Security
          </h2>

          <div className="space-y-8 mb-16">
            {securityFeatures.map((feature, index) => {
              const Icon = feature.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    } mr-4`}>
                      <Icon className={`w-6 h-6 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <div>
                      <h3 className={`text-xl font-bold ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {feature.title}
                      </h3>
                    </div>
                  </div>
                  
                  <p className={`mb-6 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {feature.description}
                  </p>
                  
                  <div className="grid md:grid-cols-2 gap-8">
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Features:</h4>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {feature.features.map((item, i) => (
                          <li key={i} className="flex items-start">
                            <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                            <span>{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div className={`rounded-lg p-4 ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                    }`}>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Compliance Standard:</h4>
                      <p className={`${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {feature.standard}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Account Protection */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Account Protection Recommendations
          </h2>

          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="grid md:grid-cols-3 gap-6">
              {accountProtectionSections.map((section, index) => (
                <div key={index}>
                  <h3 className={`text-lg font-medium mb-4 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {section.title}
                  </h3>
                  <ul className={`space-y-2 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {section.recommendations.map((rec, i) => (
                      <li key={i} className="flex items-start">
                        <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                        <span>{rec}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          {/* Security FAQs */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Security FAQs
          </h2>

          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="space-y-6">
              {securityFAQs.map((item, index) => (
                <div key={index} className={index < securityFAQs.length - 1 ? `pb-6 border-b ${
                  isDarkMode ? 'border-gray-700' : 'border-gray-200'
                }` : ''}>
                  <h3 className={`text-lg font-medium mb-2 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {item.question}
                  </h3>
                  <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                    {item.answer}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* Security Reporting */}
          <div className={`p-6 rounded-xl border ${
            isDarkMode ? 'bg-amber-900/20 border-amber-800/30' : 'bg-amber-50 border-amber-200'
          }`}>
            <div className="flex items-start">
              <AlertTriangle className={`w-6 h-6 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-amber-400' : 'text-amber-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Report Security Issues</h3>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  We take security seriously. If you discover a potential security issue, please let us know immediately.
                  Email our security team at <a href="mailto:hello@news-room.ca" className="text-blue-500 hover:underline">hello@news-room.ca</a>.
                  We appreciate your help in keeping Prism secure for everyone.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Security;