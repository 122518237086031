import api from './api';
import { TradeIdea, TradeIdeasPreferences, TradeIdeasPreferencesResponse } from '../types/tradeIdeas';

export const tradeIdeaService = {
  // Cache storage
  cache: {
    ideas: null as TradeIdea[] | null,
    timestamp: 0,
    // Change cache duration to last the entire day for free users
    CACHE_DURATION: 24 * 60 * 60 * 1000, // 24 hours in milliseconds
    isGenerating: false // Flag to prevent concurrent requests
  },

  async getPreferences(): Promise<TradeIdeasPreferences | null> {
    // Existing implementation
    try {
      const response = await api.get<TradeIdeasPreferencesResponse>('/trade-ideas/preferences');
      return response.data;
    } catch (error) {
      console.error('Error fetching trade idea preferences:', error);
      return null;
    }
  },
  
  async savePreferences(preferences: TradeIdeasPreferences): Promise<boolean> {
    // Existing implementation
    try {
      await api.post('/trade-ideas/preferences', { preferences });
      // Clear cache when preferences change
      this.cache.ideas = null;
      this.cache.timestamp = 0;
      return true;
    } catch (error) {
      console.error('Error saving trade idea preferences:', error);
      return false;
    }
  },
  
  async generateIdeas(preferences: TradeIdeasPreferences, forceRefresh = false, isPro = false): Promise<TradeIdea[]> {
    try {
      // Check if current cache is from today (for free users)
      const now = new Date();
      const cacheDate = new Date(this.cache.timestamp);
      const cacheIsFromToday = cacheDate.setHours(0,0,0,0) === now.setHours(0,0,0,0);
      
      // For free users: always use today's cache if available
      // For pro users: use normal cache duration logic or honor force refresh
      const shouldUseCache = this.cache.ideas && (
        (!isPro && cacheIsFromToday) || // Free user with today's cache
        (!forceRefresh && (Date.now() - this.cache.timestamp) < this.cache.CACHE_DURATION) // Pro user with fresh cache
      );
      
      if (shouldUseCache) {
        return this.cache.ideas || [];
      }

      // Non-pro users cannot force refresh if they already have today's cache
      if (!isPro && !forceRefresh && cacheIsFromToday) {
        return this.cache.ideas || [];
      }
      
      if (this.cache.isGenerating) {
        return this.cache.ideas || [];
      }

      this.cache.isGenerating = true;
      const response = await api.post<{ data: TradeIdea[] }>('/trade-ideas/generate', { preferences });
      
      if (response?.data) {
        this.cache.ideas = response.data;
        this.cache.timestamp = Date.now();
      }
      
      return this.cache.ideas || [];
    } catch (error) {
      console.error('Error generating trade ideas:', error);
      return this.cache.ideas || []; // Return cached ideas on error
    } finally {
      this.cache.isGenerating = false;
    }
  },
  
  clearCache(): void {
    this.cache.ideas = null;
    this.cache.timestamp = 0;
  }
};