import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';


interface ErrorResponse {
  message: string;
  details?: string;
  code?: string;
}

export const fetchPublicPrompts = async (count = 10): Promise<any[]> => {
  try {
    const response = await fetch(`/api/public-prompts?count=${count}`);
    if (!response.ok) {
      throw new Error('Failed to fetch public prompts');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching public prompts:', error);
    return []; // Return empty array as fallback
  }
};

class ApiService {
  private static instance: ApiService;
  private client: AxiosInstance;

  private constructor() {
    this.client = axios.create({
      baseURL: process.env.NODE_ENV === 'development'
        ? 'http://localhost:5000/api'
        : 'https://news-room.ca/api',
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true
    });

    this.setupInterceptors();
  }

  static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService();
    }
    return ApiService.instance;
  }

  private setupInterceptors(): void {
    // Request interceptor
    this.client.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token');
        if (token) {
         
          config.headers.Authorization = `Bearer ${token}`;
        }

        // Log requests in development
        if (process.env.NODE_ENV === 'development') {
          console.log('API Request:', {
            method: config.method,
            url: config.url,
            data: config.data,
            headers: config.headers
          });
        }

        return config;
      },
      (error) => {
        console.error('Request error:', error);
        return Promise.reject(this.normalizeError(error));
      }
    );

    // Response interceptor
    this.client.interceptors.response.use(
      (response) => {
        // Log responses in development
        if (process.env.NODE_ENV === 'development') {
          console.log('API Response:', {
            status: response.status,
            data: response.data,
            url: response.config.url
          });
        }
        return response;
      },
      (error) => {
        const normalizedError = this.normalizeError(error);

        // Handle authentication errors
        if (error.response?.status === 401) {
          localStorage.removeItem('token');
          window.location.href = '/login';
        }

        // Log errors in development
        if (process.env.NODE_ENV === 'development') {
          console.error('API Error:', {
            message: normalizedError.message,
            response: error.response?.data,
            status: error.response?.status,
            url: error.config?.url
          });
        }

        return Promise.reject(normalizedError);
      }
    );
  }

  private normalizeError(error: AxiosError<ErrorResponse>): Error {
    if (error.response?.data) {
      const errorData = error.response.data;
      const message = errorData.message || 'An unknown error occurred';
      const details = errorData.details ? `: ${errorData.details}` : '';
      return new Error(`${message}${details}`);
    }

    if (error.message === 'Network Error') {
      return new Error('Unable to connect to the server. Please check your internet connection.');
    }

    return error;
  }

  getClient(): AxiosInstance {
    return this.client;
  }

  // Utility methods for common operations
  // src/services/api.ts
// Update the utility methods in the ApiService class

async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
  const response = await this.client.get<T>(url, config);
  return response.data;
}

// src/services/api.ts
async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
  const response = await this.client.post<T>(url, data, config);
  return response.data;
}

async put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
  const response = await this.client.put<T>(url, data, config);
  return response.data;
}

async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
  const response = await this.client.delete<T>(url, config);
  return response.data;
}
}

// Create and export API singleton instance
const apiService = ApiService.getInstance();
export const api = apiService.getClient();
export default apiService;