// src/contexts/CanvasContext.tsx
import React, { createContext, useContext, useState } from 'react';

interface CanvasContextType {
  isCanvasEditorOpen: boolean;
  setIsCanvasEditorOpen: (isOpen: boolean) => void;
}

const CanvasContext = createContext<CanvasContextType>({
  isCanvasEditorOpen: false,
  setIsCanvasEditorOpen: () => {},
});

export const useCanvas = () => useContext(CanvasContext);

export const CanvasProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isCanvasEditorOpen, setIsCanvasEditorOpen] = useState(false);

  return (
    <CanvasContext.Provider value={{ isCanvasEditorOpen, setIsCanvasEditorOpen }}>
      {children}
    </CanvasContext.Provider>
  );
};