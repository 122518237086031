// src/components/walkthrough/WalkthroughLayouts.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { TrendingUp, LineChart, Newspaper, Brain, ArrowRight, Bookmark, MessageSquare, ChevronRight, BarChart3, CandlestickChart, ChevronDown, PieChart, RotateCcw, Search, Settings, Sparkles, SendHorizonal, Activity, Clock, ExternalLink, Filter, Maximize2, Plus, RefreshCcw } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';

export const DashboardLayout: React.FC = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="w-full max-w-3xl mx-auto p-4">
      <div className="mb-6">
        <h2 className={`text-2xl font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          Dashboard Overview
        </h2>
        <p className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          Your personalized command center for market data, news, and trading ideas
        </p>
      </div>
      
      <motion.div 
        className="w-full bg-zinc-900 border border-zinc-800 rounded-xl overflow-hidden shadow-lg"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {/* Header section with greeting */}
        <motion.div 
          className="p-4 border-b border-zinc-800"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-center space-x-2">
                <div className="h-2 w-24 bg-blue-500/50 rounded-full"></div>
                <div className="px-2 py-1 rounded-md bg-blue-500/20 flex items-center">
                  <div className="h-2 w-12 bg-blue-500/40 rounded-full"></div>
                </div>
              </div>
              <div className="mt-1 text-xs text-gray-400">
                Market Status Timeline
              </div>
            </div>
            
            <div className="flex items-center">
              <div className="w-20 h-1 bg-gray-700 rounded-full mr-2">
                <div className="h-full w-1/3 bg-green-500 rounded-full"></div>
              </div>
              <div className="w-6 h-6 rounded-lg bg-zinc-800 flex items-center justify-center">
                <Settings className="w-3 h-3 text-gray-400" />
              </div>
            </div>
          </div>
        </motion.div>

        {/* Main content grid */}
        <div className="p-3 space-y-4">
          {/* Welcome preview */}
          <motion.div 
            className="w-full h-40 bg-zinc-800/50 rounded-lg p-3 relative overflow-hidden border border-zinc-700/50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            <div className="flex justify-between h-full">
              <div className="flex flex-col justify-between">
                <div>
                  <div className="h-3 w-32 bg-white/20 rounded-full mb-2"></div>
                  <div className="h-2 w-48 bg-white/10 rounded-full mb-1"></div>
                  <div className="h-2 w-40 bg-white/10 rounded-full"></div>
                </div>
                
                <div className="space-y-2">
                  <motion.div
                    className="flex items-center space-x-2 px-2 py-1 rounded-md bg-blue-900/20 w-fit"
                    animate={{ backgroundColor: ['rgba(30, 58, 138, 0.2)', 'rgba(30, 58, 138, 0.3)', 'rgba(30, 58, 138, 0.2)'] }}
                    transition={{ duration: 2, repeat: Infinity }}
                  >
                    <MessageSquare className="w-3 h-3 text-blue-400" />
                    <div className="h-2 w-20 bg-blue-500/30 rounded-full"></div>
                  </motion.div>
                  
                  <div className="flex items-center space-x-2 px-2 py-1 rounded-md bg-zinc-800/80 w-fit">
                    <Search className="w-3 h-3 text-gray-400" />
                    <div className="h-2 w-16 bg-gray-600 rounded-full"></div>
                  </div>
                </div>
              </div>
              
              <motion.div
                className="w-32 h-full relative"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
              >
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="relative w-full h-20">
                    <svg className="w-full h-full" viewBox="0 0 100 50" preserveAspectRatio="none">
                      <motion.path
                        d="M0,35 C10,32 20,40 30,30 C40,20 50,35 60,25 C70,15 80,20 90,15 L90,50 L0,50 Z"
                        fill="rgba(59, 130, 246, 0.2)"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1, delay: 0.8 }}
                      />
                      <motion.path
                        d="M0,35 C10,32 20,40 30,30 C40,20 50,35 60,25 C70,15 80,20 90,15"
                        fill="none"
                        stroke="rgba(59, 130, 246, 0.8)"
                        strokeWidth="1"
                        initial={{ pathLength: 0 }}
                        animate={{ pathLength: 1 }}
                        transition={{ duration: 1.5, delay: 0.7 }}
                      />
                    </svg>
                  </div>
                </div>
              </motion.div>
            </div>
            
            <div className="absolute bottom-2 right-2 flex space-x-1">
              <div className="w-4 h-4 rounded-md bg-zinc-700 flex items-center justify-center">
                <Maximize2 className="w-2 h-2 text-gray-400" />
              </div>
              <div className="w-4 h-4 rounded-md bg-zinc-700 flex items-center justify-center">
                <RefreshCcw className="w-2 h-2 text-gray-400" />
              </div>
            </div>
            
            <div className="absolute top-3 right-3 text-xs text-blue-400">
              AI Market Assistant
            </div>
          </motion.div>

          {/* Two column layout for cards */}
          <div className="grid grid-cols-2 gap-3">
            {/* Trade Ideas Card */}
            <motion.div 
              className="bg-zinc-800/50 rounded-lg p-3 border border-zinc-700/50"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
            >
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center">
                  <Brain className="w-4 h-4 text-blue-500 mr-2" />
                  <div className="text-sm text-white">Trade Ideas</div>
                </div>
                <div className="flex items-center space-x-1">
                  <div className="flex items-center space-x-1 text-xs text-gray-500">
                    <Clock className="w-3 h-3" />
                    <div className="text-xs">Live</div>
                  </div>
                  <ExternalLink className="w-3 h-3 text-gray-500" />
                </div>
              </div>
              
              <div className="space-y-2">
                <div className="px-2 py-1.5 rounded bg-zinc-800 flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-5 h-5 rounded bg-green-500/20 flex items-center justify-center mr-2">
                      <TrendingUp className="w-3 h-3 text-green-400" />
                    </div>
                    <div className="space-y-1">
                      <div className="h-2 w-16 bg-gray-600 rounded-full"></div>
                      <div className="h-1.5 w-24 bg-gray-700 rounded-full"></div>
                    </div>
                  </div>
                  <ChevronDown className="w-3 h-3 text-gray-500" />
                </div>
                
                <motion.div 
                  className="px-2 py-1.5 rounded bg-zinc-800 flex items-center justify-between"
                  animate={{ backgroundColor: ['rgba(39,39,42,0.5)', 'rgba(30,64,175,0.1)', 'rgba(39,39,42,0.5)'] }}
                  transition={{ duration: 3, repeat: Infinity }}
                >
                  <div className="flex items-center">
                    <div className="w-5 h-5 rounded bg-blue-500/20 flex items-center justify-center mr-2">
                      <Brain className="w-3 h-3 text-blue-400" />
                    </div>
                    <div className="space-y-1">
                      <div className="h-2 w-20 bg-gray-600 rounded-full"></div>
                      <div className="h-1.5 w-16 bg-gray-700 rounded-full"></div>
                    </div>
                  </div>
                  <ChevronDown className="w-3 h-3 text-gray-500" />
                </motion.div>
              </div>
              
              <div className="text-xs text-gray-500 mt-3 leading-tight">
                AI-generated trade opportunities based on market conditions and analysis
              </div>
            </motion.div>
            
            {/* Market Feed Card */}
            <motion.div 
              className="bg-zinc-800/50 rounded-lg p-3 border border-zinc-700/50"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.5 }}
            >
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center">
                  <TrendingUp className="w-4 h-4 text-green-500 mr-2" />
                  <div className="text-sm text-white">Market Feed</div>
                </div>
                <div className="flex items-center space-x-1">
                  <div className="flex items-center space-x-1 text-xs text-gray-500">
                    <Clock className="w-3 h-3" />
                    <div className="text-xs">Live</div>
                  </div>
                  <MessageSquare className="w-3 h-3 text-gray-500" />
                </div>
              </div>
              
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <div className="w-8 h-8 bg-zinc-800 rounded"></div>
                  <div className="flex-1 space-y-1">
                    <div className="h-2 w-full bg-gray-700 rounded-full"></div>
                    <div className="h-1.5 w-3/4 bg-gray-700 rounded-full"></div>
                    <div className="flex items-center space-x-2 mt-0.5">
                      <div className="text-xs text-gray-500">5m ago</div>
                      <div className="px-1.5 py-0.5 bg-green-500/20 rounded text-xs text-green-400">+2.3%</div>
                    </div>
                  </div>
                </div>
                
                <motion.div 
                  className="flex items-center space-x-2"
                  animate={{ opacity: [1, 0.7, 1] }}
                  transition={{ duration: 2, repeat: Infinity, delay: 0.3 }}
                >
                  <div className="w-8 h-8 bg-zinc-800 rounded"></div>
                  <div className="flex-1 space-y-1">
                    <div className="h-2 w-full bg-gray-700 rounded-full"></div>
                    <div className="h-1.5 w-2/3 bg-gray-700 rounded-full"></div>
                    <div className="flex items-center space-x-2 mt-0.5">
                      <div className="text-xs text-gray-500">12m ago</div>
                      <div className="px-1.5 py-0.5 bg-red-500/20 rounded text-xs text-red-400">-1.4%</div>
                    </div>
                  </div>
                </motion.div>
              </div>
              
              <div className="text-xs text-gray-500 mt-3 leading-tight">
                Real-time updates on market movements, news, and trading volume
              </div>
            </motion.div>

            {/* Active Signals Card */}
            <motion.div 
              className="bg-zinc-800/50 rounded-lg p-3 border border-zinc-700/50"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 0.5 }}
            >
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center">
                  <Activity className="w-4 h-4 text-blue-400 mr-2" />
                  <div className="text-sm text-white">Active Signals</div>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="px-1.5 py-0.5 rounded bg-green-500/20 text-xs text-green-400">3</div>
                  <div className="px-1.5 py-0.5 rounded bg-red-500/20 text-xs text-red-400">2</div>
                </div>
              </div>
              
              <div className="space-y-2">
                <div className="px-2 py-1.5 rounded bg-zinc-800 flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <div className="px-2 py-1 bg-blue-500/20 text-blue-400 text-xs rounded">AAPL</div>
                    <div className="text-xs text-gray-400">Breakout</div>
                  </div>
                  <div className="flex items-center">
                    <Sparkles className="w-3 h-3 text-blue-400 mr-1" />
                    <div className="text-xs text-gray-400">92%</div>
                  </div>
                </div>
                
                <motion.div 
                  className="px-2 py-1.5 rounded bg-zinc-800 flex items-center justify-between"
                  animate={{ backgroundColor: ['rgba(39,39,42,0.5)', 'rgba(22,78,99,0.2)', 'rgba(39,39,42,0.5)'] }}
                  transition={{ duration: 3, repeat: Infinity }}
                >
                  <div className="flex items-center space-x-2">
                    <div className="px-2 py-1 bg-blue-500/20 text-blue-400 text-xs rounded">MSFT</div>
                    <div className="text-xs text-gray-400">Volume Spike</div>
                  </div>
                  <div className="flex items-center">
                    <Sparkles className="w-3 h-3 text-blue-400 mr-1" />
                    <div className="text-xs text-gray-400">87%</div>
                  </div>
                </motion.div>
              </div>
              
              <div className="text-xs text-gray-500 mt-3 leading-tight">
                Automated market signals for potential trading opportunities
              </div>
            </motion.div>
            
            {/* Watchlists Card */}
            <motion.div 
              className="bg-zinc-800/50 rounded-lg p-3 border border-zinc-700/50"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7, duration: 0.5 }}
            >
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center">
                  <Bookmark className="w-4 h-4 text-purple-400 mr-2" />
                  <div className="text-sm text-white">Watchlists</div>
                </div>
                <div className="flex items-center space-x-1">
                  <div className="w-4 h-4 rounded bg-zinc-700 flex items-center justify-center">
                    <Plus className="w-3 h-3 text-gray-400" />
                  </div>
                </div>
              </div>
              
              <div className="space-y-2">
                <div className="flex items-center justify-between px-2 py-1.5 rounded bg-zinc-800">
                  <div className="flex items-center space-x-2">
                    <div className="text-xs text-white">AAPL</div>
                    <div className="text-xs text-green-400">+1.2%</div>
                  </div>
                  <div className="w-16 h-4 relative">
                    <svg className="w-full h-full" viewBox="0 0 32 8" preserveAspectRatio="none">
                      <path
                        d="M0,4 C2,3 4,6 6,2 C8,0 10,2 12,4 C14,6 16,7 18,5 C20,3 22,1 24,2 C26,3 28,5 30,4 L32,4"
                        fill="none"
                        stroke="rgba(74, 222, 128, 0.5)"
                        strokeWidth="1"
                      />
                    </svg>
                  </div>
                </div>
                
                <div className="flex items-center justify-between px-2 py-1.5 rounded bg-zinc-800">
                  <div className="flex items-center space-x-2">
                    <div className="text-xs text-white">MSFT</div>
                    <div className="text-xs text-red-400">-0.8%</div>
                  </div>
                  <div className="w-16 h-4 relative">
                    <svg className="w-full h-full" viewBox="0 0 32 8" preserveAspectRatio="none">
                      <path
                        d="M0,2 C2,4 4,3 6,5 C8,7 10,6 12,4 C14,2 16,0 18,1 C20,2 22,4 24,2 C26,1 28,3 30,4 L32,4"
                        fill="none"
                        stroke="rgba(248, 113, 113, 0.5)"
                        strokeWidth="1"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              
              <div className="text-xs text-gray-500 mt-3 leading-tight">
                Track your favorite stocks and create custom watchlists
              </div>
            </motion.div>
          </div>
          
          {/* News section */}
          <motion.div 
            className="bg-zinc-800/50 rounded-lg p-3 border border-zinc-700/50"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8, duration: 0.5 }}
          >
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center">
                <Newspaper className="w-4 h-4 text-amber-400 mr-2" />
                <div className="text-sm text-white">Latest News</div>
              </div>
              <div className="flex items-center space-x-2">
                <Search className="w-3 h-3 text-gray-500" />
                <Filter className="w-3 h-3 text-gray-500" />
              </div>
            </div>
            
            <div className="grid grid-cols-2 gap-2">
              <div className="p-2 rounded bg-zinc-800">
                <div className="space-y-1">
                  <div className="h-2 w-full bg-gray-700 rounded-full"></div>
                  <div className="h-2 w-3/4 bg-gray-700 rounded-full"></div>
                  <div className="flex items-center justify-between mt-2">
                    <div className="text-xs text-gray-500">2h ago</div>
                    <div className="flex items-center space-x-1">
                      <Bookmark className="w-3 h-3 text-gray-500" />
                      <MessageSquare className="w-3 h-3 text-gray-500" />
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="p-2 rounded bg-zinc-800">
                <div className="space-y-1">
                  <div className="h-2 w-full bg-gray-700 rounded-full"></div>
                  <div className="h-2 w-4/5 bg-gray-700 rounded-full"></div>
                  <div className="flex items-center justify-between mt-2">
                    <div className="text-xs text-gray-500">3h ago</div>
                    <div className="flex items-center space-x-1">
                      <Bookmark className="w-3 h-3 text-gray-500" />
                      <MessageSquare className="w-3 h-3 text-gray-500" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="text-xs text-gray-500 mt-3 leading-tight">
              Curated market news and analysis from trusted sources
            </div>
          </motion.div>
        </div>
      </motion.div>
      
      <div className="mt-6 text-sm">
        <div className={`font-medium mb-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          Dashboard Features:
        </div>
        <ul className={`list-disc pl-5 space-y-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          <li>Personalized market overview with real-time data</li>
          <li>AI-powered trade ideas and market analysis</li>
          <li>Customizable watchlists and stock tracking</li>
          <li>Active trading signals and live market feed</li>
          <li>Latest news filtered by your preferences</li>
        </ul>
      </div>
    </div>
  );
};

// Keep your existing welcome and other layouts
export const WelcomeLayout: React.FC = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="w-full max-w-6xl mx-auto">
      <motion.div 
        className="text-center mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
      >
        <motion.h1 
          className={`text-4xl md:text-5xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          Welcome to <span className="text-blue-500">Newsroom</span>
        </motion.h1>
        <motion.p 
          className={`text-xl max-w-3xl mx-auto ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          Your all-in-one platform for market intelligence, analysis, and trading tools.
          Let's explore the key features to help you make better informed decisions.
        </motion.p>
      </motion.div>

      <motion.div 
        className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-12"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
      >
        {[
          { 
            title: 'Interactive Charts', 
            description: 'Advanced technical analysis tools with AI-powered insights',
            icon: <LineChart className="w-10 h-10 text-blue-500" />,
            delay: 0.6
          },
          { 
            title: 'Market AI Assistant', 
            description: 'Ask questions and get real-time insights about any market',
            icon: <MessageSquare className="w-10 h-10 text-purple-500" />,
            delay: 0.7
          },
          { 
            title: 'Customizable Workspace', 
            description: 'Tailor the platform to your specific trading workflow',
            icon: <ChevronRight className="w-10 h-10 text-green-500" />,
            delay: 0.8
          }
        ].map((feature, index) => (
          <motion.div
            key={index}
            className={`p-6 rounded-2xl ${isDarkMode ? 'bg-zinc-900 border border-zinc-800' : 'bg-white border border-gray-200'} shadow-lg`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: feature.delay }}
            whileHover={{ y: -5, transition: { duration: 0.2 } }}
          >
            <div className="mb-4">{feature.icon}</div>
            <h3 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {feature.title}
            </h3>
            <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
              {feature.description}
            </p>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export const ChartsLayout: React.FC = () => {
    return (
      <div className="w-full max-w-3xl mx-auto p-4">
        <motion.div
          className="w-full bg-black border border-zinc-800 rounded-xl overflow-hidden shadow-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* Chart header */}
          <div className="flex items-center justify-between px-4 py-3 border-b border-zinc-800">
            <div className="flex items-center">
              <CandlestickChart className="w-4 h-4 text-blue-500 mr-2" />
              <div className="h-3 w-24 bg-zinc-800 rounded-full"></div>
            </div>
            <div className="flex space-x-2">
              <div className="w-6 h-6 rounded-md bg-zinc-800 flex items-center justify-center">
                <Settings className="w-3 h-3 text-zinc-500" />
              </div>
              <div className="w-6 h-6 rounded-md bg-zinc-800 flex items-center justify-center">
                <ChevronDown className="w-3 h-3 text-zinc-500" />
              </div>
            </div>
          </div>
  
          {/* Chart content */}
          <div className="p-3 space-y-3">
            {/* Chart toolbar */}
            <div className="flex items-center justify-between">
              <div className="flex space-x-2">
                <motion.div
                  className="h-6 px-2 rounded-md bg-blue-500/20 flex items-center"
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.2 }}
                >
                  <LineChart className="w-3 h-3 text-blue-500 mr-1" />
                  <div className="h-2 w-8 bg-blue-500/40 rounded-full"></div>
                </motion.div>
                <div className="h-6 px-2 rounded-md bg-zinc-800 flex items-center">
                  <BarChart3 className="w-3 h-3 text-zinc-500 mr-1" />
                  <div className="h-2 w-8 bg-zinc-700 rounded-full"></div>
                </div>
                <div className="h-6 px-2 rounded-md bg-zinc-800 flex items-center">
                  <PieChart className="w-3 h-3 text-zinc-500 mr-1" />
                  <div className="h-2 w-8 bg-zinc-700 rounded-full"></div>
                </div>
              </div>
              
              <div className="flex space-x-2">
                <div className="h-6 px-2 rounded-md bg-zinc-800 flex items-center">
                  <div className="h-2 w-8 bg-zinc-700 rounded-full"></div>
                </div>
                <div className="h-6 px-2 rounded-md bg-zinc-800 flex items-center">
                  <div className="h-2 w-6 bg-zinc-700 rounded-full"></div>
                </div>
              </div>
            </div>
  
            {/* Chart visualization */}
            <div className="relative h-36 w-full rounded-lg bg-zinc-900 overflow-hidden">
              {/* Chart grid */}
              <div className="absolute inset-0 grid grid-cols-4 grid-rows-4">
                {Array.from({ length: 5 }).map((_, i) => (
                  <div key={`v-${i}`} className="absolute top-0 bottom-0 border-r border-zinc-800/30" style={{ left: `${(i) * 25}%` }} />
                ))}
                {Array.from({ length: 5 }).map((_, i) => (
                  <div key={`h-${i}`} className="absolute left-0 right-0 border-t border-zinc-800/30" style={{ top: `${(i) * 25}%` }} />
                ))}
              </div>
              
              {/* Chart line */}
              <motion.div
                className="absolute left-0 right-0 bottom-0 h-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3, duration: 0.5 }}
              >
                <svg className="w-full h-full" preserveAspectRatio="none">
                  <motion.path
                    d="M0,70 C20,65 40,90 60,75 C80,60 100,80 120,85 C140,90 160,60 180,40 C200,20 220,30 240,35 C260,40 280,20 300,25"
                    fill="none"
                    stroke="rgba(59, 130, 246, 0.5)"
                    strokeWidth="2"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 1.5, delay: 0.5 }}
                  />
                  <motion.path
                    d="M0,70 C20,65 40,90 60,75 C80,60 100,80 120,85 C140,90 160,60 180,40 C200,20 220,30 240,35 C260,40 280,20 300,25"
                    fill="url(#blueGradient)"
                    strokeWidth="0"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0.2 }}
                    transition={{ duration: 1, delay: 1 }}
                  />
                  <defs>
                    <linearGradient id="blueGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                      <stop offset="0%" stopColor="rgb(59, 130, 246)" stopOpacity="0.5"/>
                      <stop offset="100%" stopColor="rgb(59, 130, 246)" stopOpacity="0"/>
                    </linearGradient>
                  </defs>
                </svg>
                
                {/* Animated data point */}
                <motion.div
                  className="absolute w-2 h-2 rounded-full bg-blue-500 shadow-lg shadow-blue-500/50"
                  style={{ left: '60%', top: '30%' }}
                  animate={{ 
                    scale: [1, 1.5, 1],
                    boxShadow: [
                      '0 0 0 0 rgba(59, 130, 246, 0.7)',
                      '0 0 0 4px rgba(59, 130, 246, 0)',
                      '0 0 0 0 rgba(59, 130, 246, 0.7)'
                    ]
                  }}
                  transition={{ duration: 2, repeat: Infinity }}
                />
              </motion.div>
            </div>
  
            {/* Chart controls */}
            <div className="flex justify-between items-center">
              <div className="space-x-2 flex">
                <motion.div 
                  className="h-7 px-2 rounded-md bg-zinc-800 flex items-center"
                  whileHover={{ backgroundColor: 'rgba(59, 130, 246, 0.2)' }}
                >
                  <div className="h-2 w-8 bg-zinc-700 rounded-full"></div>
                </motion.div>
                <motion.div 
                  className="h-7 px-2 rounded-md bg-zinc-800 flex items-center"
                  whileHover={{ backgroundColor: 'rgba(59, 130, 246, 0.2)' }}
                >
                  <div className="h-2 w-12 bg-zinc-700 rounded-full"></div>
                </motion.div>
              </div>
              
              <div className="flex space-x-1">
                <motion.div
                  className="flex items-center justify-center w-7 h-7 rounded-md bg-zinc-800"
                  whileHover={{ backgroundColor: 'rgba(59, 130, 246, 0.2)' }}
                >
                  <RotateCcw className="w-3 h-3 text-zinc-500" />
                </motion.div>
                <motion.div
                  className="flex items-center justify-center w-7 h-7 rounded-md bg-blue-500/20"
                  animate={{ backgroundColor: ['rgba(59, 130, 246, 0.2)', 'rgba(59, 130, 246, 0.3)', 'rgba(59, 130, 246, 0.2)'] }}
                  transition={{ duration: 2, repeat: Infinity }}
                >
                  <Sparkles className="w-3 h-3 text-blue-500" />
                </motion.div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    );
  };
  
  export const AIAssistantLayout: React.FC = () => {
    return (
      <div className="w-full max-w-3xl mx-auto p-4">
        <motion.div
          className="w-full bg-black border border-zinc-800 rounded-xl overflow-hidden shadow-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* AI Assistant header */}
          <div className="flex items-center justify-between px-4 py-3 border-b border-zinc-800">
            <div className="flex items-center">
              <div className="w-5 h-5 rounded-full bg-indigo-600/70 flex items-center justify-center mr-2">
                <Brain className="w-3 h-3 text-white" />
              </div>
              <div className="h-3 w-32 bg-zinc-800 rounded-full"></div>
            </div>
            <div className="flex space-x-2">
              <div className="w-6 h-6 rounded-md bg-zinc-800 flex items-center justify-center">
                <Search className="w-3 h-3 text-zinc-500" />
              </div>
              <div className="w-6 h-6 rounded-md bg-zinc-800 flex items-center justify-center">
                <Settings className="w-3 h-3 text-zinc-500" />
              </div>
            </div>
          </div>
  
          {/* Chat content */}
          <div className="p-3 h-52 flex flex-col">
            <div className="flex-grow space-y-3 overflow-y-auto">
              {/* Assistant message */}
              <motion.div
                className="flex"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="w-6 h-6 rounded-full bg-indigo-600/70 flex items-center justify-center mr-2 flex-shrink-0 mt-1">
                  <Brain className="w-3 h-3 text-white" />
                </div>
                <div className="bg-zinc-800/50 rounded-lg p-2 max-w-[80%]">
                  <div className="space-y-1.5">
                    <div className="h-2 w-full bg-zinc-700 rounded-full"></div>
                    <div className="h-2 w-5/6 bg-zinc-700 rounded-full"></div>
                    <div className="h-2 w-4/6 bg-zinc-700 rounded-full"></div>
                  </div>
                </div>
              </motion.div>
  
              {/* User message */}
              <motion.div
                className="flex justify-end"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <div className="bg-blue-600/30 rounded-lg p-2 max-w-[80%]">
                  <div className="space-y-1.5">
                    <div className="h-2 w-full bg-blue-500/30 rounded-full"></div>
                    <div className="h-2 w-3/6 bg-blue-500/30 rounded-full"></div>
                  </div>
                </div>
              </motion.div>
  
              {/* Assistant response with typing animation */}
              <motion.div
                className="flex"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                <div className="w-6 h-6 rounded-full bg-indigo-600/70 flex items-center justify-center mr-2 flex-shrink-0 mt-1">
                  <Brain className="w-3 h-3 text-white" />
                </div>
                <div className="bg-zinc-800/50 rounded-lg p-2 max-w-[80%]">
                  <div className="space-y-1.5">
                    <div className="h-2 w-full bg-zinc-700 rounded-full"></div>
                    <div className="h-2 w-4/5 bg-zinc-700 rounded-full"></div>
                    <div className="flex space-x-1 mt-1">
                      <motion.div
                        className="w-1 h-1 rounded-full bg-zinc-500"
                        animate={{ opacity: [0.3, 1, 0.3] }}
                        transition={{ duration: 1.2, repeat: Infinity, delay: 0 }}
                      />
                      <motion.div
                        className="w-1 h-1 rounded-full bg-zinc-500"
                        animate={{ opacity: [0.3, 1, 0.3] }}
                        transition={{ duration: 1.2, repeat: Infinity, delay: 0.2 }}
                      />
                      <motion.div
                        className="w-1 h-1 rounded-full bg-zinc-500"
                        animate={{ opacity: [0.3, 1, 0.3] }}
                        transition={{ duration: 1.2, repeat: Infinity, delay: 0.4 }}
                      />
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
  
            {/* Chat input */}
            <motion.div
              className="mt-2 flex items-center bg-zinc-800/40 rounded-lg p-1.5"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.8 }}
            >
              <div className="h-2 w-full bg-zinc-700/50 rounded-full mr-2"></div>
              <motion.div 
                className="w-6 h-6 rounded-md bg-blue-600/70 flex items-center justify-center"
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.2 }}
              >
                <SendHorizonal className="w-3 h-3 text-blue-100" />
              </motion.div>
            </motion.div>
  
            {/* Suggestion buttons */}
            <motion.div 
              className="mt-2 flex flex-wrap gap-2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}
            >
              <motion.div 
                className="bg-zinc-800/50 rounded-full px-2 py-1 text-xs flex items-center"
                whileHover={{ backgroundColor: 'rgba(59, 130, 246, 0.2)' }}
              >
                <div className="h-1.5 w-16 bg-zinc-700 rounded-full"></div>
              </motion.div>
              <motion.div 
                className="bg-zinc-800/50 rounded-full px-2 py-1 text-xs flex items-center"
                whileHover={{ backgroundColor: 'rgba(59, 130, 246, 0.2)' }}
              >
                <div className="h-1.5 w-12 bg-zinc-700 rounded-full"></div>
              </motion.div>
              <motion.div 
                className="bg-zinc-800/50 rounded-full px-2 py-1 text-xs flex items-center"
                whileHover={{ backgroundColor: 'rgba(59, 130, 246, 0.2)' }}
              >
                <div className="h-1.5 w-20 bg-zinc-700 rounded-full"></div>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    );
  };