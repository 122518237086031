import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import { ArrowLeft, Mail, CheckCircle2, AlertCircle, Loader2 } from 'lucide-react';
import { authService } from '../../services/authService';

// Header component
const AuthHeader: React.FC = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <nav className="container mx-auto px-6 py-4">
      <div className="flex items-center mt-16 justify-between">
        <Link to="/" className="flex items-center space-x-2">
          <ArrowLeft className={isDarkMode ? 'text-white' : 'text-gray-900'} size={20} />
          <p className={`text-sm font-bold ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Back to home
          </p>
        </Link>
      </div>
    </nav>
  );
};

export const ForgotPassword: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    
    try {
      await authService.requestPasswordReset(email);
      setSuccess(true);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to request password reset');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}>
      <AuthHeader />
      
      <div className="flex min-h-screen">
        <div className="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:px-20">
          <div className="w-full max-w-md mx-auto">
            <div className="text-center">
              
              <h2 className={`text-2xl font-bold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Reset your password
              </h2>
              <p className={`text-center text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              } mt-2`}>
                Enter your email address and we'll send you a link to reset your password
              </p>
            </div>

            {success ? (
              <div className="mt-8 p-4 rounded-lg border bg-green-50 border-green-100">
                <div className="flex items-center space-x-3 text-green-700">
                  <CheckCircle2 className="h-5 w-5" />
                  <p>
                    If an account exists for {email}, you will receive a password reset link at your email address.
                  </p>
                </div>
              </div>
            ) : (
              <>
                {error && (
                  <div className="mt-6 p-3 rounded-lg bg-red-50 border border-red-200 text-red-600 flex items-center space-x-2">
                    <AlertCircle className="w-5 h-5" />
                    <span className="text-sm">{error}</span>
                  </div>
                )}

                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="email" className={`block text-sm font-medium ${
                      isDarkMode ? 'text-gray-200' : 'text-gray-700'
                    }`}>
                      Email address
                    </label>
                    <div className="relative mt-1">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Mail className={`h-5 w-5 ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-500'
                        }`} />
                      </div>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={`block w-full pl-10 pr-3 py-2 rounded-lg border ${
                          isDarkMode
                            ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                            : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                        } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                        placeholder="you@example.com"
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full flex justify-center py-3 px-4 rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                  >
                    {isSubmitting ? (
                      <div className="flex items-center">
                        <Loader2 className="w-5 h-5 animate-spin mr-2" />
                        <span>Sending...</span>
                      </div>
                    ) : (
                      'Send Reset Link'
                    )}
                  </button>
                </form>
              </>
            )}
            
            <p className={`mt-6 text-center text-sm ${
              isDarkMode ? 'text-gray-400' : 'text-gray-600'
            }`}>
              Remembered your password?{' '}
              <Link
                to="/login"
                className="font-medium text-blue-500 hover:text-blue-400"
              >
                Sign in
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;