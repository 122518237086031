import React, { createContext, useContext, useState, ReactNode } from 'react';
import { StockViewModal } from '../components/market/StockViewModal';

interface StockViewContextType {
  openStockModal: (ticker: string) => void;
  closeStockModal: () => void;
}

const StockViewContext = createContext<StockViewContextType | undefined>(undefined);

interface StockViewProviderProps {
  children: ReactNode;
  onAnalyze?: (prompt: string) => void;
  fullScreenMode?: boolean;
}

export const StockViewProvider: React.FC<StockViewProviderProps> = ({ 
  children, 
  onAnalyze,
  fullScreenMode = false
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTicker, setCurrentTicker] = useState<string>('');

  const openStockModal = (ticker: string) => {
    setCurrentTicker(ticker);
    setIsModalOpen(true);
  };

  const closeStockModal = () => {
    setIsModalOpen(false);
  };

  return (
    <StockViewContext.Provider value={{ openStockModal, closeStockModal }}>
      {children}
      <StockViewModal 
        isOpen={isModalOpen} 
        onClose={closeStockModal} 
        ticker={currentTicker} 
        onAnalyze={onAnalyze}
        fullScreenMode={fullScreenMode}
      />
    </StockViewContext.Provider>
  );
};

export const useStockView = (): StockViewContextType => {
  const context = useContext(StockViewContext);
  if (context === undefined) {
    throw new Error('useStockView must be used within a StockViewProvider');
  }
  return context;
};