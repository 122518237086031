import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Pencil,
  Type,
  Square,
  Circle,
  ArrowRight,
  ChevronRight,
  LineChart,
  Check,
  AlertTriangle,
  Image,
  Share2
} from 'lucide-react';

const Annotations = () => {
  const { isDarkMode } = useTheme();

  const annotationTypes = [
    {
      title: 'Text Notes',
      description: 'Add explanatory text notes to any area of your charts',
      features: [
        'Customizable text size and font',
        'Rich text formatting options (bold, italic, etc.)',
        'Adjustable background opacity',
        'Ability to link to external resources',
        'Movable and resizable text boxes'
      ],
      icon: Type
    },
    {
      title: 'Drawing Tools',
      description: 'Draw directly on charts to highlight patterns and areas of interest',
      features: [
        'Freehand drawing with adjustable line width',
        'Shape tools (rectangles, circles, triangles)',
        'Line tools with optional arrows',
        'Fibonacci retracement tools',
        'Customizable colors and opacity'
      ],
      icon: Pencil
    },
    {
      title: 'Technical Markings',
      description: 'Add standard technical analysis markings to identify patterns',
      features: [
        'Support and resistance lines',
        'Trend channels and trend lines',
        'Chart pattern indicators (head & shoulders, wedges, etc.)',
        'Volume profile overlays',
        'Customizable appearance for all markings'
      ],
      icon: LineChart
    }
  ];

  const bestPractices = [
    'Use consistent color coding across all annotations',
    'Keep text annotations concise for better readability',
    'Group related annotations to reduce visual clutter',
    'Create a legend when using multiple annotation types',
    'Save important annotation sets as templates for reuse'
  ];

  const mistakes = [
    'Overcrowding charts with too many annotations',
    'Using inconsistent terminology in text annotations',
    'Creating annotations that obscure important chart data',
    'Failing to update annotations after significant market changes',
    'Using colors that are difficult to see against the chart background'
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Pencil className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Chart Annotations
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                How to add notes, drawings, and technical markings to your charts
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              Chart annotations are powerful tools that allow you to add context, insights, and 
              technical analysis markings directly to your charts. Prism provides a comprehensive 
              set of annotation tools that help you document your analysis, highlight important 
              patterns, and share insights with others. This guide covers all annotation features 
              and how to use them effectively.
            </p>
          </div>

          {/* Annotation Types */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Types of Annotations
          </h2>
          
          <div className="space-y-10 mb-16">
            {annotationTypes.map((type, index) => {
              const Icon = type.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-3 rounded-lg ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    } mr-4`}>
                      <Icon className={`w-6 h-6 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <div>
                      <h3 className={`text-xl font-bold ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {type.title}
                      </h3>
                    </div>
                  </div>
                  
                  <p className={`mb-6 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {type.description}
                  </p>
                  
                  <div className="grid md:grid-cols-2 gap-8">
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Features:</h4>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {type.features.map((feature, i) => (
                          <li key={i} className="flex items-start">
                            <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div className={`rounded-lg overflow-hidden border ${
                      isDarkMode ? 'border-gray-700' : 'border-gray-200'
                    }`}>
                      <div className={`h-48 flex items-center justify-center ${
                        isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                      }`}>
                        <p className={`text-sm ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-500'
                        }`}>
                          {type.title} illustration
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* How to Create Annotations */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Creating Annotations
          </h2>
          
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <ol className={`space-y-4 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white font-medium mr-3 mt-0.5">1</div>
                <div>
                  <p className="font-medium mb-1">Open the Annotations Toolbar</p>
                  <p className="text-sm">Click the annotation icon in the chart toolbar or use the keyboard shortcut Alt+A (Option+A on Mac).</p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white font-medium mr-3 mt-0.5">2</div>
                <div>
                  <p className="font-medium mb-1">Select an Annotation Type</p>
                  <p className="text-sm">Choose from text notes, drawing tools, or technical markings in the annotation toolbar.</p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white font-medium mr-3 mt-0.5">3</div>
                <div>
                  <p className="font-medium mb-1">Place the Annotation</p>
                  <p className="text-sm">Click or drag on the chart where you want to place the annotation.</p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white font-medium mr-3 mt-0.5">4</div>
                <div>
                  <p className="font-medium mb-1">Customize Your Annotation</p>
                  <p className="text-sm">Use the properties panel to adjust color, size, text, and other parameters.</p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white font-medium mr-3 mt-0.5">5</div>
                <div>
                  <p className="font-medium mb-1">Save Your Work</p>
                  <p className="text-sm">Click the save button or press Ctrl+S (Cmd+S on Mac) to save your annotated chart.</p>
                </div>
              </li>
            </ol>
          </div>

          {/* Best Practices */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Annotation Best Practices
          </h2>
          
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h4 className={`font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Do:</h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {bestPractices.map((practice, index) => (
                    <li key={index} className="flex items-start">
                      <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                      <span>{practice}</span>
                    </li>
                  ))}
                </ul>
              </div>
              
              <div>
                <h4 className={`font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Avoid:</h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {mistakes.map((mistake, index) => (
                    <li key={index} className="flex items-start">
                      <AlertTriangle className="w-4 h-4 text-yellow-500 mr-2 mt-0.5 flex-shrink-0" />
                      <span>{mistake}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Related Documentation */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Related Documentation
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <a 
              href="/docs/patterns"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Pattern Recognition
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Using AI to identify chart patterns automatically
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
            
            <a 
              href="/docs/canvas-export"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Sharing & Exporting
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  How to share and export your annotated charts
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Annotations;