import React, { useState } from 'react';
import { 
  Cookie, Info, Settings, Shield, RefreshCw, Database, 
  UserCheck, MessageSquare, LineChart, Save, ToggleLeft
} from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import { motion } from 'framer-motion';

interface CookieType {
  name: string;
  purpose: string;
  duration: string;
  required: boolean;
}

const cookieCategories: Record<string, CookieType[]> = {
  'Essential': [
    {
      name: 'session_id',
      purpose: 'Maintains your authenticated session state and security',
      duration: 'Session',
      required: true
    },
    {
      name: 'csrf_token',
      purpose: 'Protects against cross-site request forgery attacks',
      duration: 'Session',
      required: true
    },
    {
      name: 'auth_token',
      purpose: 'Secures your account access and preferences',
      duration: '30 days',
      required: true
    }
  ],
  'Functional': [
    {
      name: 'theme_preference',
      purpose: 'Stores your light/dark mode theme selection',
      duration: '1 year',
      required: false
    },
    {
      name: 'chat_settings',
      purpose: 'Saves your Prism AI conversation preferences',
      duration: '6 months',
      required: false
    },
    {
      name: 'research_mode',
      purpose: 'Remembers your preferred market research mode',
      duration: '3 months',
      required: false
    },
    {
      name: 'watchlist_view',
      purpose: 'Maintains your watchlist display preferences',
      duration: '6 months',
      required: false
    }
  ],
  'Analytics': [
    {
      name: 'analytics_id',
      purpose: 'Tracks anonymous usage patterns to improve platform features',
      duration: '1 year',
      required: false
    },
    {
      name: 'performance_data',
      purpose: 'Monitors site performance and identifies potential improvements',
      duration: '30 days',
      required: false
    },
    {
      name: 'feature_usage',
      purpose: 'Analyzes which trading features are most valuable to users',
      duration: '90 days',
      required: false
    }
  ],
  'Trading Data': [
    {
      name: 'market_preferences',
      purpose: 'Stores your preferred markets and securities for faster loading',
      duration: '6 months',
      required: false
    },
    {
      name: 'chart_settings',
      purpose: 'Remembers your custom chart configurations and indicators',
      duration: '1 year',
      required: false
    }
  ]
};

const CookiesPage = () => {
  const { isDarkMode } = useTheme();
  const [showPreferences, setShowPreferences] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    essential: true,
    functional: true,
    analytics: false,
    tradingData: true
  });
  
  const handlePreferenceToggle = (category: string) => {
    if (category === 'essential') return; // Essential cookies can't be disabled
    setCookiePreferences(prev => ({
      ...prev,
      [category]: !prev[category as keyof typeof prev]
    }));
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-black text-white">
      {/* Animated background effect */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-900/20 via-gray-900/5 to-transparent"></div>
      </div>
      
      {/* Hero Section with gradient background */}
      <div className="relative overflow-hidden pt-24 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center"
          >
            <Cookie className="w-16 h-16 mx-auto mb-6 text-blue-400" />
            <h1 className="text-4xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-300">
              Cookie Policy
            </h1>
            <p className="text-xl max-w-3xl mx-auto text-gray-300">
              Understanding how we use cookies to enhance your trading experience
            </p>
          </motion.div>
        </div>
      </div>

      {/* Content */}
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12 relative z-10 space-y-8">
        {/* Introduction Card */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
          className="bg-white/5 backdrop-blur-xl rounded-2xl border border-white/10 p-6"
        >
          <div className="text-gray-300 space-y-4">
            <p>
              This Cookie Policy explains how Newsroom uses cookies and similar tracking technologies
              on our platform. We use cookies to provide essential functionality, remember your preferences, 
              and improve our market analysis and trading features.
            </p>
            <p>
              By using our platform, you consent to our use of cookies in accordance with this policy.
              You can customize your cookie preferences at any time through your account settings or
              browser controls.
            </p>
          </div>
        </motion.div>

        {/* Cookie Types Section */}
        <div className="space-y-6">
          {Object.entries(cookieCategories).map(([category, cookies], index) => (
            <motion.div
              key={category}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 + (index * 0.1) }}
              className="bg-white/5 backdrop-blur-xl rounded-2xl border border-white/10 overflow-hidden"
            >
              <div className="p-6 border-b border-white/10">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    {category === 'Essential' && <Shield className="w-5 h-5 text-blue-400" />}
                    {category === 'Functional' && <Settings className="w-5 h-5 text-blue-400" />}
                    {category === 'Analytics' && <Database className="w-5 h-5 text-blue-400" />}
                    {category === 'Trading Data' && <LineChart className="w-5 h-5 text-blue-400" />}
                    <h2 className="text-xl font-semibold">
                      {category} Cookies
                      {category === 'Essential' && (
                        <span className="ml-2 text-sm text-blue-500">(Required)</span>
                      )}
                    </h2>
                  </div>
                </div>
              </div>
              
              <div className="p-6 space-y-4">
                {cookies.map((cookie, idx) => (
                  <div
                    key={idx}
                    className="bg-white/5 rounded-xl p-4 border border-white/5"
                  >
                    <div className="flex items-center justify-between mb-2">
                      <h3 className="font-medium text-white">
                        {cookie.name}
                      </h3>
                      <span className="text-sm text-gray-400">
                        Duration: {cookie.duration}
                      </span>
                    </div>
                    <p className="text-gray-300 text-sm">
                      {cookie.purpose}
                    </p>
                  </div>
                ))}
              </div>
            </motion.div>
          ))}
        </div>

        {/* Cookie Management Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="bg-white/5 backdrop-blur-xl rounded-2xl border border-white/10 overflow-hidden"
        >
          <div className="p-6 border-b border-white/10">
            <div className="flex items-center space-x-3">
              <Settings className="w-5 h-5 text-blue-400" />
              <h2 className="text-xl font-semibold">
                Managing Your Cookie Preferences
              </h2>
            </div>
          </div>
          
          <div className="p-6 space-y-6">
            <p className="text-gray-300">
              You can customize which cookies you accept. Essential cookies cannot be disabled as they are
              required for the platform to function properly and keep your account secure.
            </p>
            
            <button
              className="bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 
              text-white px-6 py-3 rounded-xl transition-colors flex items-center justify-center space-x-2"
              onClick={() => setShowPreferences(!showPreferences)}
            >
              <Settings className="w-4 h-4" />
              <span>Manage Cookie Preferences</span>
            </button>
            
            {showPreferences && (
              <div className="mt-6 space-y-4">
                {Object.entries(cookiePreferences).map(([category, enabled]) => (
                  <div key={category} className="flex items-center justify-between p-4 bg-white/5 rounded-xl">
                    <div>
                      <h3 className="font-medium text-white capitalize">{category} Cookies</h3>
                      <p className="text-sm text-gray-400">
                        {category === 'essential' && 'Required for basic platform functionality'}
                        {category === 'functional' && 'Enhances your experience with personalized features'}
                        {category === 'analytics' && 'Helps us improve our platform'}
                        {category === 'tradingData' && 'Remembers your trading preferences'}
                      </p>
                    </div>
                    <button
                      onClick={() => handlePreferenceToggle(category)}
                      disabled={category === 'essential'}
                      className={`flex items-center justify-center w-12 h-6 ${
                        category === 'essential' ? 'bg-blue-500/30' : 'bg-white/5'
                      } border border-white/10 rounded-full`}
                    >
                      {enabled ? 
                        <ToggleLeft className="w-5 h-5 text-blue-400" /> : 
                        <ToggleLeft className="w-5 h-5 text-gray-400" />
                      }
                    </button>
                  </div>
                ))}
                
                <button 
                  className="w-full bg-blue-500/10 hover:bg-blue-500/20 text-blue-400 px-6 py-3 
                  rounded-xl transition-colors flex items-center justify-center space-x-2"
                >
                  <Save className="w-4 h-4" />
                  <span>Save Preferences</span>
                </button>
              </div>
            )}
          </div>
        </motion.div>

        {/* How We Use Your Data */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.7 }}
          className="bg-white/5 backdrop-blur-xl rounded-2xl border border-white/10 overflow-hidden"
        >
          <div className="p-6 border-b border-white/10">
            <div className="flex items-center space-x-3">
              <UserCheck className="w-5 h-5 text-blue-400" />
              <h2 className="text-xl font-semibold">
                How We Use Your Data
              </h2>
            </div>
          </div>
          
          <div className="p-6 space-y-4">
            <div className="flex space-x-4">
              <div className="p-3 rounded-lg bg-blue-500/20">
                <MessageSquare className="w-6 h-6 text-blue-400" />
              </div>
              <div>
                <h3 className="font-medium text-white mb-1">AI Chat & Research</h3>
                <p className="text-gray-300 text-sm">
                  Your conversations with Prism AI are automatically saved to provide continuity and 
                  improve our AI market analysis. You can export or delete this data at any time in your settings.
                </p>
              </div>
            </div>
            
            <div className="flex space-x-4">
              <div className="p-3 rounded-lg bg-blue-500/20">
                <LineChart className="w-6 h-6 text-blue-400" />
              </div>
              <div>
                <h3 className="font-medium text-white mb-1">Trading Preferences</h3>
                <p className="text-gray-300 text-sm">
                  We store your watchlists, chart preferences, and market interests to customize your experience.
                  This data helps us provide more relevant insights for your trading strategy.
                </p>
              </div>
            </div>
            
            <div className="flex space-x-4">
              <div className="p-3 rounded-lg bg-blue-500/20">
                <Database className="w-6 h-6 text-blue-400" />
              </div>
              <div>
                <h3 className="font-medium text-white mb-1">Data Control</h3>
                <p className="text-gray-300 text-sm">
                  You can export your complete data history or delete your account at any time through 
                  your account settings. We implement advanced security measures to protect all your information.
                </p>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Additional Information */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
          className="bg-white/5 backdrop-blur-xl rounded-2xl border border-white/10 overflow-hidden"
        >
          <div className="p-6 border-b border-white/10">
            <div className="flex items-center space-x-3">
              <Info className="w-5 h-5 text-blue-400" />
              <h2 className="text-xl font-semibold">
                Additional Information
              </h2>
            </div>
          </div>
          
          <div className="p-6 space-y-4">
            <p className="text-gray-300">
              This cookie policy was last updated on December 1, 2024. We may update this policy periodically 
              to reflect changes in our practices or for other operational, legal, or regulatory reasons.
            </p>
            <p className="text-gray-300">
              For any questions about our use of cookies or your data, please contact our privacy team:
            </p>
            <a
              href="mailto:hello@news-room.ca"
              className="text-blue-400 hover:text-blue-300 transition-colors"
            >
              hello@news-room.ca
            </a>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default CookiesPage;