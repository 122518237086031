import React, { useState, useEffect } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import {
  CheckCircle2,
  AlertCircle,
  XCircle,
  Clock,
  Activity,
  Server,
  BarChart3,
  Database,
  Cloud,
  Globe,
  ChevronRight,
  CalendarClock,
  RotateCcw,
  Bell
} from 'lucide-react';

interface SystemComponent {
  name: string;
  status: 'operational' | 'degraded' | 'outage' | 'maintenance';
  lastUpdated: string;
  icon: React.ElementType;
  description?: string;
  uptime?: string;
}

interface Incident {
  id: string;
  title: string;
  status: 'investigating' | 'identified' | 'monitoring' | 'resolved';
  createdAt: string;
  updatedAt: string;
  updates: {
    timestamp: string;
    message: string;
    status: string;
  }[];
}

interface MaintenanceWindow {
  id: string;
  title: string;
  startTime: string;
  endTime: string;
  status: 'scheduled' | 'in-progress' | 'completed';
  components: string[];
  description: string;
}

export default function SystemStatus() {
  const { isDarkMode } = useTheme();
  const [selectedTab, setSelectedTab] = useState<'current' | 'upcoming' | 'history'>('current');
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [apiHealth, setApiHealth] = useState<'operational' | 'degraded' | 'outage'>('operational');
  const [lastChecked, setLastChecked] = useState<Date>(new Date());

  const checkApiHealth = async () => {
    try {
      const response = await fetch('http://localhost:5000/api/health');
      if (!response.ok) {
        setApiHealth('outage');
        return;
      }
      
      const data = await response.json();
      if (data.status === 'healthy') {
        setApiHealth('operational');
      } else {
        setApiHealth('degraded');
      }
    } catch (error) {
      console.error('Health check failed:', error);
      setApiHealth('outage');
    }
    setLastChecked(new Date());
  };

  useEffect(() => {
    checkApiHealth();
    // Check every 60 seconds
    const interval = setInterval(checkApiHealth, 60000);
    return () => clearInterval(interval);
  }, []);

  const systemComponents: SystemComponent[] = [
    {
      name: 'Widget Generation API',
      status: apiHealth, // Now uses real API status
      lastUpdated: lastChecked.toISOString(),
      icon: Server,
      uptime: '99.98%'
    },
    {
      name: 'Data Processing Pipeline',
      status: 'operational',
      lastUpdated: '2024-01-20T15:30:00Z',
      icon: BarChart3,
      uptime: '99.95%'
    },
    {
      name: 'Database Services',
      status: 'operational',
      lastUpdated: '2024-01-20T15:30:00Z',
      icon: Database,
      uptime: '99.99%'
    },
    {
      name: 'Claude Integration',
      status: 'degraded',
      lastUpdated: '2024-01-20T15:20:00Z',
      icon: Cloud,
      description: 'Experiencing increased latency',
      uptime: '99.90%'
    },
    {
      name: 'Widget Dashboard',
      status: 'operational',
      lastUpdated: '2024-01-20T15:30:00Z',
      icon: Activity,
      uptime: '99.97%'
    },
    {
      name: 'Market Data Feed',
      status: 'operational',
      lastUpdated: '2024-01-20T15:30:00Z',
      icon: Globe,
      uptime: '99.95%'
    }
  ];

  const maintenanceWindows: MaintenanceWindow[] = [
    {
      id: '1',
      title: 'Infrastructure Upgrades',
      startTime: '2024-01-21T01:00:00Z',
      endTime: '2024-01-21T03:00:00Z',
      status: 'scheduled',
      components: ['Widget Generation API', 'Database Services'],
      description: 'Scheduled system maintenance and infrastructure upgrades'
    }
  ];

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'operational':
        return <CheckCircle2 className="text-green-500" />;
      case 'degraded':
        return <AlertCircle className="text-yellow-500" />;
      case 'outage':
        return <XCircle className="text-red-500" />;
      case 'maintenance':
        return <Clock className="text-blue-500" />;
      default:
        return <AlertCircle className="text-gray-500" />;
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      timeZoneName: 'short'
    });
  };

  const formatUptime = (uptime: string) => {
    return parseFloat(uptime).toFixed(2) + '%';
  };

  const handleRefresh = () => {
    checkApiHealth();
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className={`text-3xl font-bold ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            System Status
          </h1>
          <p className={`mt-2 ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}>
            Current status of Newsroom services and components
          </p>
          <button
            onClick={() => setShowSubscribeModal(true)}
            className="mt-4 inline-flex items-center px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors"
          >
            <Bell className="w-4 h-4 mr-2" />
            Subscribe to Updates
          </button>
        </div>

        {/* Overall Status */}
        <div className={`mb-12 p-6 rounded-xl ${
          isDarkMode ? 'bg-gray-800' : 'bg-white'
        } shadow-lg`}>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <CheckCircle2 className="w-8 h-8 text-green-500 mr-3" />
              <div>
                <h2 className={`text-2xl font-semibold ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  All Systems Operational
                </h2>
                <p className={`mt-1 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  Last checked {new Date().toLocaleTimeString()}
                </p>
              </div>
            </div>
            <button
              onClick={handleRefresh}
              className={`p-2 rounded-lg ${
                isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
              }`}
            >
              <RotateCcw className="w-5 h-5" />
            </button>
          </div>
        </div>

        {/* Component Status Grid */}
        <div className="grid md:grid-cols-2 gap-6 mb-12">
          {systemComponents.map((component) => {
            const Icon = component.icon;
            return (
              <div
                key={component.name}
                className={`p-6 rounded-xl ${
                  isDarkMode ? 'bg-gray-800' : 'bg-white'
                } shadow-lg`}
              >
                <div className="flex items-start justify-between">
                  <div className="flex items-start">
                    <div className={`p-2 rounded-lg ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                    } mr-4`}>
                      <Icon className={`w-5 h-5 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`} />
                    </div>
                    <div>
                      <h3 className={`font-medium ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {component.name}
                      </h3>
                      <p className={`text-sm mt-1 ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-600'
                      }`}>
                        {component.description || `Uptime: ${component.uptime}`}
                      </p>
                    </div>
                  </div>
                  {getStatusIcon(component.status)}
                </div>
              </div>
            );
          })}
        </div>

        {/* Scheduled Maintenance */}
        <div className="mb-12">
          <h2 className={`text-xl font-semibold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Scheduled Maintenance
          </h2>
          {maintenanceWindows.map((maintenance) => (
            <div
              key={maintenance.id}
              className={`p-6 rounded-xl ${
                isDarkMode ? 'bg-gray-800' : 'bg-white'
              } shadow-lg`}
            >
              <div className="flex items-start">
                <CalendarClock className="w-6 h-6 text-blue-500 mr-4" />
                <div>
                  <h3 className={`font-medium ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {maintenance.title}
                  </h3>
                  <p className={`mt-1 text-sm ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-600'
                  }`}>
                    {maintenance.description}
                  </p>
                  <div className="mt-4 space-y-2">
                    <p className={`text-sm ${
                      isDarkMode ? 'text-gray-400' : 'text-gray-600'
                    }`}>
                      <span className="font-medium">Start:</span> {formatDate(maintenance.startTime)}
                    </p>
                    <p className={`text-sm ${
                      isDarkMode ? 'text-gray-400' : 'text-gray-600'
                    }`}>
                      <span className="font-medium">End:</span> {formatDate(maintenance.endTime)}
                    </p>
                    <div className="flex flex-wrap gap-2 mt-2">
                      {maintenance.components.map((component) => (
                        <span
                          key={component}
                          className={`px-3 py-1 text-xs rounded-full ${
                            isDarkMode
                              ? 'bg-gray-700 text-gray-300'
                              : 'bg-gray-100 text-gray-600'
                          }`}
                        >
                          {component}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Historical Uptime */}
        <div>
          <h2 className={`text-xl font-semibold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            30-Day Historical Uptime
          </h2>
          <div className={`p-6 rounded-xl ${
            isDarkMode ? 'bg-gray-800' : 'bg-white'
          } shadow-lg`}>
            <div className="space-y-4">
              {systemComponents.map((component) => (
                <div key={component.name} className="flex items-center justify-between">
                  <span className={`text-sm ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {component.name}
                  </span>
                  <span className={`text-sm font-medium ${
                    isDarkMode ? 'text-gray-200' : 'text-gray-900'
                  }`}>
                    {component.uptime}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}