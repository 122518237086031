// src/hooks/useActivities.ts
import { useState, useEffect } from 'react';
import { activityService, Activity } from '../services/activityService';

export function useActivities(limit: number = 10) {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchActivities = async () => {
    try {
      setIsLoading(true);
      const data = await activityService.getRecentActivities(limit);
      setActivities(data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch activities');
      console.error('Error fetching activities:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchActivities();
    // Refresh activities every minute
    const interval = setInterval(fetchActivities, 60000);
    return () => clearInterval(interval);
  }, [limit]);

  const addActivity = async (activity: Omit<Activity, 'id' | 'createdAt'>) => {
    try {
      const newActivity = await activityService.createActivity(activity);
      setActivities(prev => [newActivity, ...prev].slice(0, limit));
      return newActivity;
    } catch (err) {
      console.error('Error adding activity:', err);
      throw err;
    }
  };

  const deleteActivity = async (id: string) => {
    try {
      await activityService.deleteActivity(id);
      setActivities(prev => prev.filter(activity => activity.id !== id));
    } catch (err) {
      console.error('Error deleting activity:', err);
      throw err;
    }
  };

  const clearActivities = async () => {
    try {
      await activityService.clearActivities();
      setActivities([]);
    } catch (err) {
      console.error('Error clearing activities:', err);
      throw err;
    }
  };

  return {
    activities,
    isLoading,
    error,
    addActivity,
    deleteActivity,
    clearActivities,
    refresh: fetchActivities
  };
}