import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from './MainLayout';
import { ChevronRight, BookOpen } from 'lucide-react';

interface DocumentationLayoutProps {
  children: React.ReactNode;
  title: string;
  icon: React.FC<{ className?: string }>;
  description?: string;
}

export const DocumentationLayout: React.FC<DocumentationLayoutProps> = ({
  children,
  title,
  icon: Icon,
  description
}) => {
  const { isDarkMode } = useTheme();
  const location = useLocation();

  // Comprehensive list of all documentation categories and pages
  const docCategories = [
    {
      title: 'Getting Started',
      links: [
        { name: 'Introduction to Prism AI', href: '/docs/introduction' },
        { name: 'Account Setup', href: '/docs/account-setup' },
        { name: 'Dashboard Overview', href: '/docs/dashboard' },
        { name: 'Understanding Credits', href: '/docs/credits' },
      ]
    },
    {
      title: 'Prism AI Chat',
      links: [
        { name: 'Starting Conversations', href: '/docs/starting-chats' },
        { name: 'Research Modes', href: '/docs/research-modes' },
        { name: 'Using Chat Chips', href: '/docs/chat-chips' },
        { name: 'File Uploads', href: '/docs/file-uploads' },
        { name: 'Managing Chat History', href: '/docs/chat-history' },
      ]
    },
    {
      title: 'Market Research',
      links: [
        { name: 'Real-time Research Process', href: '/docs/research-process' },
        { name: 'Understanding Research Stages', href: '/docs/research-stages' },
        { name: 'Source Types & Quality', href: '/docs/source-types' },
        { name: 'Viewing Research Progress', href: '/docs/research-progress' },
        { name: 'Exporting Research Results', href: '/docs/export-research' },
      ]
    },
    {
      title: 'Watchlists & Saved Items',
      links: [
        { name: 'Creating Watchlists', href: '/docs/create-watchlist' },
        { name: 'Managing Saved Stocks', href: '/docs/saved-stocks' },
        { name: 'Sorting & Filtering', href: '/docs/sort-filter' },
        { name: 'Stock View Options', href: '/docs/stock-view' },
        { name: 'Sparkline Charts', href: '/docs/sparklines' },
      ]
    },
    {
      title: 'Canvas Features',
      links: [
        { name: 'Visualizing Conversations', href: '/docs/canvas-intro' },
        { name: 'Chart Annotations', href: '/docs/annotations' },
        { name: 'Pattern Recognition', href: '/docs/patterns' },
        { name: 'Sharing & Exporting', href: '/docs/canvas-export' },
      ]
    },
    {
      title: 'Developer Tools',
      links: [
        { name: 'API Reference', href: '/docs/api' },
        { name: 'Authentication', href: '/docs/api#authentication' },
        { name: 'Rate Limits', href: '/docs/api#rate-limits' },
        { name: 'Webhooks', href: '/docs/webhooks' },
      ]
    },
    {
      title: 'Security & Privacy',
      links: [
        { name: 'Data Security', href: '/docs/security' },
        { name: 'Privacy Controls', href: '/docs/privacy' },
        { name: 'Data Export', href: '/docs/data-export' },
        { name: 'Account Protection', href: '/docs/account-protection' },
      ]
    },
    {
      title: 'Advanced Features',
      links: [
        { name: 'Trade Ideas Generation', href: '/docs/trade-ideas' },
        { name: 'Advanced Analytics', href: '/docs/advanced-analytics' },
        { name: 'Customizing Prism', href: '/docs/customize-prism' },
        { name: 'Pro Features', href: '/docs/pro-features' },
      ]
    },
    {
      title: 'Resources',
      links: [
        { name: 'API Reference', href: '/docs/api' },
        { name: 'Tutorials', href: '/docs/tutorials' },
        { name: 'Release Notes', href: '/docs/releases' },
      ]
    },
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Icon className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {title}
              </h1>
              {description && (
                <p className={`text-xl max-w-3xl mx-auto ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {description}
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="flex flex-col lg:flex-row gap-8">
            {/* Sidebar */}
            <div className="lg:w-64 flex-shrink-0">
              <div className={`sticky top-8 p-6 rounded-xl border ${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
              }`}>
                <div className="flex items-center space-x-2 mb-6">
                  <BookOpen className={`w-5 h-5 ${
                    isDarkMode ? 'text-blue-400' : 'text-blue-600'
                  }`} />
                  <h3 className={`text-lg font-bold ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    Documentation
                  </h3>
                </div>
                
                <nav>
                  {docCategories.map((category) => (
                    <div key={category.title} className="mb-6">
                      <h4 className={`text-sm font-medium mb-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-700'
                      }`}>
                        {category.title}
                      </h4>
                      <ul className="space-y-1">
                        {category.links.map((link) => (
                          <li key={link.name}>
                            <Link
                              to={link.href}
                              className={`block px-3 py-2 text-sm rounded-lg transition-colors ${
                                location.pathname === link.href
                                  ? isDarkMode 
                                    ? 'bg-blue-900/30 text-blue-400 font-medium' 
                                    : 'bg-blue-50 text-blue-700 font-medium'
                                  : isDarkMode
                                    ? 'text-gray-400 hover:text-gray-200 hover:bg-gray-700/50'
                                    : 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
                              }`}
                            >
                              {link.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </nav>
                
                {location.pathname !== '/docs' && (
                  <div className="pt-4 mt-6 border-t border-gray-700">
                    <Link
                      to="/docs"
                      className={`flex items-center text-sm ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      } hover:underline`}
                    >
                      <ChevronRight className="w-4 h-4 mr-1 rotate-180" />
                      Back to Documentation Home
                    </Link>
                  </div>
                )}
              </div>
            </div>
            
            {/* Main Content */}
            <div className="flex-1">
              <div className={`p-6 rounded-xl border ${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
              }`}>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};