import React, { useLayoutEffect, useState, useMemo } from 'react';
import { Lock, AlertTriangle, Activity, BarChart2 } from 'lucide-react';
import { TechnicalPattern } from '../../types/superChart';

interface PatternPopupProps {
  pattern: TechnicalPattern;
  position: { x: number; y: number };
  chartBounds: DOMRect;
}

const POPUP_WIDTH = 320;
const POPUP_HEIGHT = 320;
const TOOLBAR_HEIGHT = 80; // Height of the top toolbar
const TOOLTIP_BUFFER = 40; // Buffer space for price tooltip

const SmartPatternPopup: React.FC<PatternPopupProps> = ({
  pattern,
  position,
  chartBounds
}) => {
  // Memoize pattern config to prevent recalculations
  const config = useMemo(() => ({
    text: pattern.type,
    sentiment: pattern.type.includes('bull') ? 'bullish' : 
               pattern.type.includes('bear') ? 'bearish' : 'neutral',
    color: pattern.type.includes('bull') ? '#10B981' : 
           pattern.type.includes('bear') ? '#EF4444' : '#3B82F6'
  }), [pattern.type]);

  // Calculate optimal position using useLayoutEffect to prevent flashing
  const [popupStyle, setPopupStyle] = useState({
    transform: 'translate(0, 0)',
    opacity: 0
  });

  useLayoutEffect(() => {
    // Calculate the optimal position
    let x = position.x;
    let y = position.y;

    // Adjust horizontal position to keep popup within bounds
    x = Math.min(x, chartBounds.width - POPUP_WIDTH - 20);
    x = Math.max(20, x);

    // Adjust vertical position to avoid toolbar and stay within bounds
    const spaceAbove = y - TOOLBAR_HEIGHT;
    const spaceBelow = chartBounds.height - y - TOOLTIP_BUFFER;

    // Decide whether to show above or below
    if (spaceBelow >= POPUP_HEIGHT) {
      // Show below if there's enough space
      y += 20;
    } else if (spaceAbove >= POPUP_HEIGHT) {
      // Show above if there's enough space
      y = y - POPUP_HEIGHT - 20;
    } else {
      // Center vertically if no ideal space
      y = Math.max(TOOLBAR_HEIGHT + 20, 
          Math.min(chartBounds.height - POPUP_HEIGHT - 20, 
          chartBounds.height / 2 - POPUP_HEIGHT / 2));
    }

    // Update position with transform for better performance
    setPopupStyle({
      transform: `translate(${x}px, ${y}px)`,
      opacity: 1
    });
  }, [position, chartBounds]);

  return (
    <div
      className="fixed left-0 top-0 pointer-events-none transition-all duration-150"
      style={{
        width: POPUP_WIDTH,
        ...popupStyle
      }}
    >
      <div className="relative backdrop-blur-lg bg-gray-900/90 border border-gray-700/50 rounded-xl shadow-xl overflow-hidden">
        {/* Gradient accent bar */}
        <div 
          className="absolute top-0 left-0 right-0 h-1"
          style={{ 
            background: `linear-gradient(to right, ${config.color}, ${config.color}88)`
          }}
        />
        
        <div className="p-4">
          {/* Header */}
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center space-x-2">
              <div 
                className="w-2 h-2 rounded-full" 
                style={{ backgroundColor: config.color }} 
              />
              <h3 className="text-white font-medium capitalize">
                {pattern.type.replace('_', ' ')}
              </h3>
            </div>
            <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${
              config.sentiment === 'bullish' 
                ? 'bg-green-500/20 text-green-400' 
                : config.sentiment === 'bearish'
                ? 'bg-red-500/20 text-red-400'
                : 'bg-blue-500/20 text-blue-400'
            }`}>
              {config.sentiment.toUpperCase()}
            </span>
          </div>

          {/* Pattern Description */}
          <p className="text-sm text-gray-300 mb-4">
            {pattern.explanation}
          </p>

          {/* Technical Metrics */}
          <div className="grid grid-cols-2 gap-3 mb-4">
            {/* Confidence Score */}
            <div className="bg-gray-800/50 rounded-lg p-3">
              <div className="flex items-center justify-between mb-2">
                <span className="text-xs text-gray-400">Confidence</span>
                <Lock className="w-3 h-3 text-gray-500" />
              </div>
              <div className="flex items-center justify-between">
                <div className="w-16 h-1.5 rounded-full bg-gray-700">
                  <div 
                    className="h-full rounded-full"
                    style={{ 
                      width: `${pattern.confidence * 100}%`,
                      backgroundColor: config.color
                    }}
                  />
                </div>
                <span className={`text-sm font-medium ${
                  pattern.confidence >= 0.8 ? 'text-green-400' :
                  pattern.confidence >= 0.6 ? 'text-blue-400' :
                  'text-yellow-400'
                }`}>
                  {Math.round(pattern.confidence * 100)}%
                </span>
              </div>
            </div>

            {/* Duration */}
            <div className="bg-gray-800/50 rounded-lg p-3">
              <div className="flex items-center justify-between mb-2">
                <span className="text-xs text-gray-400">Duration</span>
                <Activity className="w-3 h-3 text-gray-500" />
              </div>
              <span className="text-sm font-medium text-white">
                {pattern.endIndex - pattern.startIndex} periods
              </span>
            </div>
          </div>

          {/* Analysis Summary */}
          <div className="bg-gray-800/30 rounded-lg p-3 mb-3">
            <div className="flex items-center space-x-2 mb-2">
              <BarChart2 className="w-4 h-4 text-gray-400" />
              <span className="text-xs font-medium text-gray-300">Impact Analysis</span>
            </div>
            <div className="space-y-1.5 text-sm">
              <div className="flex justify-between">
                <span className="text-gray-400">Success Rate</span>
                <span className="text-gray-200">
                  {Math.round(65 + pattern.confidence * 20)}%
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-400">Avg. Movement</span>
                <span className="text-gray-200">
                  {pattern.type.includes('bull') ? '+' : '-'}
                  {(2 + Math.random() * 3).toFixed(1)}%
                </span>
              </div>
            </div>
          </div>

          {/* Risk Level */}
          <div className="flex items-center justify-between text-xs">
            <div className="flex items-center space-x-1.5">
              <AlertTriangle className="w-3 h-3 text-yellow-500" />
              <span className="text-gray-400">Risk Level</span>
            </div>
            <span className="text-yellow-500">Moderate</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartPatternPopup;