// src/components/market/WatchlistMenu.tsx

import React, { useState } from 'react';
import { MoreVertical, Edit, Trash, Archive } from 'lucide-react';
import { useWatchlists } from '../../hooks/useWatchlists';

interface WatchlistMenuProps {
  listId: string;
  onEdit?: () => void;
}

export const WatchlistMenu: React.FC<WatchlistMenuProps> = ({ listId, onEdit }) => {
  const { deleteList } = useWatchlists();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this watchlist?')) {
      return;
    }

    setIsDeleting(true);
    try {
      await deleteList(listId);
    } catch (error) {
      console.error('Failed to delete list:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="relative group">
      <button className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg">
        <MoreVertical className="w-4 h-4" />
      </button>
      <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg 
                    border border-gray-200 dark:border-gray-700 hidden group-hover:block">
        <button
          onClick={onEdit}
          className="w-full flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-300 
                   hover:bg-gray-100 dark:hover:bg-gray-700"
          disabled={isDeleting}
        >
          <Edit className="w-4 h-4 mr-2" />
          Edit
        </button>
        <button
          onClick={handleDelete}
          className="w-full flex items-center px-4 py-2 text-sm text-red-600 dark:text-red-400 
                   hover:bg-gray-100 dark:hover:bg-gray-700"
          disabled={isDeleting}
        >
          <Trash className="w-4 h-4 mr-2" />
          {isDeleting ? 'Deleting...' : 'Delete'}
        </button>
      </div>
    </div>
  );
};