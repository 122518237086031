import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeContext';
import { ErrorAlert } from '../../components/common/ErrorAlert';
import { Moon, Sun, ArrowLeft } from 'lucide-react';
import Spline from '@splinetool/react-spline';
import { motion } from 'framer-motion';


const FloatingElements = React.memo(() => {
  const { isDarkMode } = useTheme();
  
  // Generate random positions once on mount
  const particles = React.useMemo(() => 
    Array.from({ length: 20 }).map(() => ({
      left: `${Math.random() * 100}%`,
      top: `${Math.random() * 100}%`,
      scale: Math.random() * 0.5 + 0.5,
      duration: Math.random() * 3 + 2,
      delay: Math.random() * 2
    })), []
  );
  
  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none isolate">
      <motion.div
        className="absolute inset-0"
        style={{
          perspective: 1000
        }}
      >
        {particles.map((particle, i) => (
          <motion.div
            key={i}
            className={`absolute w-2 h-2 rounded-full ${
              isDarkMode ? 'bg-blue-500' : 'bg-blue-400'
            }`}
            style={{
              left: particle.left,
              top: particle.top,
              opacity: 0.2,
              scale: particle.scale
            }}
            animate={{
              x: [-20, 20],
              y: [-20, 20],
              scale: [1, 1.2, 1],
              opacity: [0.2, 0.5, 0.2],
            }}
            transition={{
              duration: particle.duration,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "easeInOut",
              delay: particle.delay
            }}
          />
        ))}
      </motion.div>

      {/* Animated Lines */}
      <svg className="absolute inset-0 w-full h-full">
        <defs>
          <linearGradient id="lineGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={isDarkMode ? '#3B82F6' : '#60A5FA'} stopOpacity="0.2" />
            <stop offset="50%" stopColor={isDarkMode ? '#8B5CF6' : '#A78BFA'} stopOpacity="0.3" />
            <stop offset="100%" stopColor={isDarkMode ? '#3B82F6' : '#60A5FA'} stopOpacity="0.2" />
          </linearGradient>
        </defs>
        {Array.from({ length: 5 }).map((_, i) => (
          <motion.path
            key={i}
            d={`M0 ${100 + i * 100} Q ${window.innerWidth / 2} ${50 + Math.sin(i) * 50} ${window.innerWidth} ${100 + i * 100}`}
            stroke="url(#lineGradient)"
            strokeWidth="1"
            fill="none"
            animate={{
              d: [
                `M0 ${100 + i * 100} Q ${window.innerWidth / 2} ${50 + Math.sin(i) * 50} ${window.innerWidth} ${100 + i * 100}`,
                `M0 ${100 + i * 100} Q ${window.innerWidth / 2} ${150 + Math.sin(i) * 50} ${window.innerWidth} ${100 + i * 100}`,
                `M0 ${100 + i * 100} Q ${window.innerWidth / 2} ${50 + Math.sin(i) * 50} ${window.innerWidth} ${100 + i * 100}`
              ]
            }}
            transition={{
              duration: 10 + i * 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />
        ))}
      </svg>

      {/* Interactive Grid */}
      <div className="absolute inset-0 grid grid-cols-8 grid-rows-8">
        {Array.from({ length: 64 }).map((_, i) => (
          <motion.div
            key={i}
            className={`border border-gray-800/10 ${
              isDarkMode ? 'bg-gray-900/5' : 'bg-gray-100/5'
            }`}
            whileHover={{ scale: 1.1, backgroundColor: isDarkMode ? 'rgba(59, 130, 246, 0.1)' : 'rgba(96, 165, 250, 0.1)' }}
            transition={{ type: "spring", damping: 15 }}
          />
        ))}
      </div>
    </div>
  );
});

// Shared header component for auth pages
const AuthHeader: React.FC = () => {
  const { isDarkMode, toggleDarkMode } = useTheme();
  
  return (
    <nav className="container mx-auto px-6 py-4">
      <div className="flex items-center mt-16 justify-between">
        <Link to="/" className="flex items-center space-x-2">
        <ArrowLeft className={isDarkMode ? 'text-white' : 'text-gray-900'} size={20} />
            <p className={`text-sm font-bold ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Back to home
            </p>
        </Link>
        
          
      </div>
    </nav>
  );
};

// Login Page
export const Login: React.FC = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { isDarkMode } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      await login(formData);
      navigate('/dashboard');
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(err.message);
      } else if (typeof err === 'object' && err && 'response' in err) {
        const axiosError = err as { response?: { data?: { message?: string } } };
        setError(axiosError.response?.data?.message || 'Failed to login');
      } else {
        setError('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative min-h-screen font-heading tracking-tighter">
      {/* Spline 3D background */}
      <div className="absolute inset-0">
        <Spline
          scene="https://prod.spline.design/1PqVYx7rFIWocw7K/scene.splinecode" className='w-full h-full z-0' />
      </div>

      {/* Overlay gradient */}
      <div className="absolute z-10 inset-0 bg-gradient-to-b from-transparent via-black/20 to-black/40" />
      
      {/* Radial gradient */}
      <div className="absolute inset-0">
        <div className="absolute z-15 inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent blur-3xl" />
      </div>

      <FloatingElements />

      {/* Main content */}
      <div className="relative z-20">
        <AuthHeader />
        
      <div className="flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8 py-12">
        <div className={`w-full max-w-md space-y-8 ${
          isDarkMode ? 'bg-gray-800' : 'bg-white'
        } p-8 rounded-xl shadow-lg`}>
           <div className="w-[150px] h-[150px] mx-auto">
             <Spline
               scene="https://prod.spline.design/Doc7YlUmwCnGu0M5/scene.splinecode"
             />
           </div>
          <div>
        <h2 className={`text-center text-3xl font-bold ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Sign in to your account
        </h2>
  <p className={`text-center text-sm ${
    isDarkMode ? 'text-gray-400' : 'text-gray-600'
  } mt-2`}>
    Welcome back! Please enter your credentials below.
  </p>
</div>

          {error && (
            <ErrorAlert
              message={error}
              onClose={() => setError(null)}
            />
          )}

          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="space-y-4">
              <div>
                <label htmlFor="email" className={`block text-sm font-medium ${
                  isDarkMode ? 'text-gray-200' : 'text-gray-700'
                }`}>
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className={`mt-1 block w-full rounded-lg ${
                    isDarkMode 
                      ? 'bg-gray-700 border-gray-600 text-white'
                      : 'bg-white border-gray-300 text-gray-900'
                  } shadow-sm px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                />
              </div>

              <div>
                <label htmlFor="password" className={`block text-sm font-medium ${
                  isDarkMode ? 'text-gray-200' : 'text-gray-700'
                }`}>
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  value={formData.password}
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  className={`mt-1 block w-full rounded-lg ${
                    isDarkMode 
                      ? 'bg-gray-700 border-gray-600 text-white'
                      : 'bg-white border-gray-300 text-gray-900'
                  } shadow-sm px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                />
              </div>
              <div className="flex justify-end">
  <Link
    to="/forgot-password"
    className="text-sm font-medium text-blue-500 hover:text-blue-400"
  >
    Forgot your password?
  </Link>
</div>
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center py-3 px-4 rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {isLoading ? 'Signing in...' : 'Sign in'}
            </button>

            <p className={`text-center text-sm ${
              isDarkMode ? 'text-gray-400' : 'text-gray-600'
            }`}>
              Don't have an account?{' '}
              <Link
                to="/register"
                className="font-medium text-blue-500 hover:text-blue-400"
              >
                Sign up
              </Link>
            </p>
          </form>
        </div>
        </div>
      </div>
    </div>
  );
};
