import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { DocumentationLayout } from '../../components/layout/DocumentationLayout';
import { 
  UserCircle, 
  Mail, 
  Lock, 
  Shield, 
  Bell,
  Phone,
  Users
} from 'lucide-react';
import { Link } from 'react-router-dom';

const AccountSetup = () => {
  const { isDarkMode } = useTheme();

  return (
    <DocumentationLayout
      title="Account Setup"
      icon={UserCircle}
      description="Create and configure your Newsroom account"
    >
      <div className={`prose max-w-none ${isDarkMode ? 'prose-invert' : ''}`}>
        <h2>Creating Your Account</h2>
        <p>
          Setting up your Newsroom account is simple and secure. Follow these steps to get started with Prism AI and all other platform features.
        </p>

        <ol>
          <li><strong>Sign Up:</strong> Visit the registration page and enter your email address and create a password</li>
          <li><strong>Check Email:</strong> Check your inbox for an email from us. If you got it, your email is confirmed</li>
          <li><strong>Complete Profile:</strong> Add your name and basic information to personalize your experience</li>
          <li><strong>Choose Subscription:</strong> Choose between Pro status or a free account</li>
        </ol>

        <div className={`p-6 rounded-xl border my-8 ${
          isDarkMode ? 'bg-gray-800/80 border-gray-700' : 'bg-gray-50 border-gray-200'
        }`}>
          <h3 className="mt-0">Security Recommendations</h3>
          <ul>
            <li>Use a strong, unique password for your Newsroom account</li>
           
            <li>Review active sessions periodically in your account settings</li>
          </ul>
        </div>

        <h2>Account Settings</h2>
        <p>
          Once your account is set up, you can customize various settings to optimize your experience:
        </p>

        <h3>Profile Settings</h3>
        <ul>
          <li><strong>Personal Information:</strong> Update your display name and email</li>
          <li><strong>Password Management:</strong> Change your password</li>
          
        </ul>

        <h3>Notification Preferences</h3>
        <ul>
          <li><strong>Email Notifications:</strong> Choose which updates and alerts you receive via email</li>
         
        </ul>

        <h3>Privacy Settings</h3>
        <ul>
          <li><strong>Data Sharing:</strong> Control how your usage data is collected and used</li>
          <li><strong>Analytics Participation:</strong> Choose whether to share anonymous usage data to improve the platform</li>
          
        </ul>

        <h2>Accessing Your Account</h2>
        <p>
          You can access your Newsroom account through:
        </p>
        <ul>
          <li>Web browser at news-room.ca</li>
          <li>Desktop application (Windows, Mac, Linux) [Coming Soon]</li>
          
        </ul>

        <p>
          All platforms sync automatically, so you can seamlessly transition between devices while maintaining access to your chats, watchlists, and custom settings.
        </p>
      </div>
    </DocumentationLayout>
  );
};

export default AccountSetup;