import React from 'react';
import { ChartTimeframe, TIME_CONFIGS } from '../../types/superChart';
import { motion, AnimatePresence } from 'framer-motion';
import { Clock } from 'lucide-react';

interface TimeframeSelectorProps {
  value: ChartTimeframe;
  onChange: (timeframe: ChartTimeframe) => void;
  disabled?: boolean;
}

export const TimeframeSelector: React.FC<TimeframeSelectorProps> = ({
  value,
  onChange,
  disabled = false
}) => {
  const timeframes = Object.entries(TIME_CONFIGS);

  return (
    <div className="flex space-x-2">
      {timeframes.map(([tf, config]) => (
        <button
          key={tf}
          onClick={() => onChange(tf as ChartTimeframe)}
          disabled={disabled}
          className={`
            px-3 py-1.5 rounded-lg text-sm font-medium transition-all
            ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
            ${value === tf
              ? 'bg-blue-500/20 text-blue-400 ring-1 ring-blue-500/30'
              : 'text-gray-400 hover:bg-gray-800/50'
            }
          `}
          title={config.description}
        >
          <div className="flex items-center space-x-1.5">
            <Clock className="w-3.5 h-3.5" />
            <span>{tf}</span>
          </div>
        </button>
      ))}
    </div>
  );
};

export default TimeframeSelector;