import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  History, 
  MessageSquare, 
  Search, 
  Trash2, 
  Edit, 
  Filter,
  Archive,
  Download,
  Share2,
  Star
} from 'lucide-react';

const ChatHistory = () => {
  const { isDarkMode } = useTheme();

  return (
    <MainLayout>
      <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <History className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Managing Chat History
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Learn how to organize, search, and get the most from your Prism chat history
              </p>
            </div>
          </div>
        </div>

        {/* How Chat History Works */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="grid md:grid-cols-3 gap-8">
            <div className="md:col-span-2">
              <h2 className={`text-2xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>Understanding Chat History</h2>
              <div className={`prose max-w-none ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                <p>
                  Every conversation you have with Prism is automatically saved in your chat history, making it easy to revisit past research, continue ongoing analysis, or reference insights later.
                </p>
                <p>
                  Your chat history provides several benefits:
                </p>
                <ul>
                  <li>Preserves research context and continuity</li>
                  <li>Allows for picking up conversations where you left off</li>
                  <li>Creates an organized library of your market research</li>
                  <li>Reduces redundant queries, saving research credits</li>
                  <li>Provides a historical record of your investment research</li>
                </ul>
                <p>
                  For Pro users, chat history is stored indefinitely. Free users have access to their 10 most recent conversations, with older chats being archived after 30 days.
                </p>
              </div>
            </div>
            <div>
              <div className={`p-6 rounded-xl ${
                isDarkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white shadow-md'
              }`}>
                <h3 className={`text-lg font-medium mb-4 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Chat Retention</h3>
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>
                      Free Plan
                    </span>
                    <span className="font-medium text-amber-500">
                      30 days, 10 chats
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>
                      Pro Plan
                    </span>
                    <span className="font-medium text-green-500">
                      Unlimited
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>
                      Enterprise
                    </span>
                    <span className="font-medium text-blue-500">
                      Unlimited + Admin
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Navigation and Organization */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 bg-gradient-to-r from-blue-50 to-indigo-50 dark:from-gray-800/50 dark:to-gray-800/50 rounded-2xl">
          <h2 className={`text-2xl font-bold mb-10 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>Navigating and Organizing Your Chats</h2>
          
          <div className="grid md:grid-cols-3 gap-8">
            <div className={`p-6 rounded-xl ${
              isDarkMode ? 'bg-gray-800/80 border border-gray-700' : 'bg-white shadow-sm'
            }`}>
              <div className="flex items-center mb-4">
                <div className={`w-10 h-10 rounded-full flex items-center justify-center ${
                  isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                }`}>
                  <Search className={`w-5 h-5 ${
                    isDarkMode ? 'text-blue-400' : 'text-blue-600'
                  }`} />
                </div>
                <h3 className={`ml-3 text-lg font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Finding Past Chats
                </h3>
              </div>
              <ul className={`space-y-2 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">•</span>
                  <span>Use the search bar in the chat sidebar</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">•</span>
                  <span>Search by ticker symbol, company name, or topic</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">•</span>
                  <span>Filter by date range to narrow results</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">•</span>
                  <span>Browse sessions sorted by most recent</span>
                </li>
              </ul>
            </div>
            
            <div className={`p-6 rounded-xl ${
              isDarkMode ? 'bg-gray-800/80 border border-gray-700' : 'bg-white shadow-sm'
            }`}>
              <div className="flex items-center mb-4">
                <div className={`w-10 h-10 rounded-full flex items-center justify-center ${
                  isDarkMode ? 'bg-purple-500/20' : 'bg-purple-100'
                }`}>
                  <MessageSquare className={`w-5 h-5 ${
                    isDarkMode ? 'text-purple-400' : 'text-purple-600'
                  }`} />
                </div>
                <h3 className={`ml-3 text-lg font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Organizing Sessions
                </h3>
              </div>
              <ul className={`space-y-2 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">•</span>
                  <span>Rename chats with descriptive titles</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">•</span>
                  <span>Star important conversations for quick access</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">•</span>
                  <span>Sort by date, alphabetical order, or usage</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">•</span>
                  <span>Use filters to view specific types of analysis</span>
                </li>
              </ul>
            </div>
            
            <div className={`p-6 rounded-xl ${
              isDarkMode ? 'bg-gray-800/80 border border-gray-700' : 'bg-white shadow-sm'
            }`}>
              <div className="flex items-center mb-4">
                <div className={`w-10 h-10 rounded-full flex items-center justify-center ${
                  isDarkMode ? 'bg-green-500/20' : 'bg-green-100'
                }`}>
                  <Edit className={`w-5 h-5 ${
                    isDarkMode ? 'text-green-400' : 'text-green-600'
                  }`} />
                </div>
                <h3 className={`ml-3 text-lg font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Managing Content
                </h3>
              </div>
              <ul className={`space-y-2 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">•</span>
                  <span>Archive infrequently used conversations</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">•</span>
                  <span>Delete outdated or unnecessary chats</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">•</span>
                  <span>Export chats to PDF for external reference</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">•</span>
                  <span>Create folders (Pro) to categorize research</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Pro Chat Features */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>Pro Chat Management Features</h2>
          
          <div className="grid md:grid-cols-2 gap-8">
            <div className={`p-6 rounded-xl border ${
              isDarkMode ? 'bg-gray-800/70 border-gray-700' : 'bg-white border-gray-200'
            }`}>
              <h3 className={`text-xl font-medium mb-4 flex items-center ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                <Archive className="w-5 h-5 mr-2 text-blue-500" />
                Organizational Features
              </h3>
              <ul className={`space-y-3 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">1.</span>
                  <span><strong>Folders & Tags:</strong> Create folders to group related chats and add tags for easy filtering</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">2.</span>
                  <span><strong>Bulk Actions:</strong> Select multiple chats to archive, export, or delete in one operation</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">3.</span>
                  <span><strong>Enhanced Search:</strong> Full-text search across all your conversations with advanced filters</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">4.</span>
                  <span><strong>Custom Views:</strong> Create saved views with specific filters and sorting preferences</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">5.</span>
                  <span><strong>Favorites:</strong> Star important conversations for instant access from any device</span>
                </li>
              </ul>
            </div>
            
            <div className={`p-6 rounded-xl border ${
              isDarkMode ? 'bg-gray-800/70 border-gray-700' : 'bg-white border-gray-200'
            }`}>
              <h3 className={`text-xl font-medium mb-4 flex items-center ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                <Share2 className="w-5 h-5 mr-2 text-green-500" />
                Sharing & Export
              </h3>
              <ul className={`space-y-3 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">1.</span>
                  <span><strong>PDF Export:</strong> Generate professionally formatted PDFs with complete chat history and charts</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">2.</span>
                  <span><strong>Shareable Links:</strong> Create secure links to share specific conversations with colleagues</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">3.</span>
                  <span><strong>Email Reports:</strong> Schedule regular email summaries of selected conversations</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">4.</span>
                  <span><strong>Data Export:</strong> Download conversations in JSON or CSV format for external analysis</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">5.</span>
                  <span><strong>Collaboration:</strong> Add comments and notes to shared conversations</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Tips and Best Practices */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="grid md:grid-cols-5 gap-8">
            <div className="md:col-span-3">
              <h2 className={`text-2xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>Chat History Best Practices</h2>
              <div className={`prose max-w-none ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                <p>
                  To get the most out of your chat history and keep your research organized:
                </p>
                <ul>
                  <li><strong>Create dedicated chats</strong> for different research purposes (e.g., "TSLA Technical Analysis" or "Semiconductor Industry Overview")</li>
                  <li><strong>Rename your chats</strong> with descriptive titles as soon as you've established their focus</li>
                  <li><strong>Continue existing conversations</strong> for related follow-ups rather than starting new ones</li>
                  <li><strong>Use the archive feature</strong> for completed research you may need to reference later</li>
                  <li><strong>Export important analyses</strong> as PDFs for your permanent records or external sharing</li>
                  <li><strong>Delete redundant or preliminary</strong> conversations to keep your history clean</li>
                  <li><strong>Star conversations</strong> you access frequently for quick reference</li>
                </ul>
                <p>
                  Remember that well-organized chat history not only makes information easier to find but also helps you build on previous insights and maintain continuity in your research process.
                </p>
              </div>
            </div>
            <div className="md:col-span-2">
              <div className={`p-6 rounded-xl ${
                isDarkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white shadow-md'
              }`}>
                <div className="flex items-center mb-6">
                  <Star className={`w-6 h-6 mr-3 ${
                    isDarkMode ? 'text-amber-400' : 'text-amber-500'
                  }`} />
                  <h3 className={`text-lg font-medium ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    Pro Tips
                  </h3>
                </div>
                <ul className={`space-y-4 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-700'
                }`}>
                  <li className="flex items-start">
                    <span className={`inline-flex items-center justify-center w-5 h-5 rounded-full mr-3 text-xs ${
                      isDarkMode ? 'bg-blue-500/20 text-blue-400' : 'bg-blue-100 text-blue-600'
                    }`}>1</span>
                    <span>Use consistent naming conventions for easier sorting</span>
                  </li>
                  <li className="flex items-start">
                    <span className={`inline-flex items-center justify-center w-5 h-5 rounded-full mr-3 text-xs ${
                      isDarkMode ? 'bg-blue-500/20 text-blue-400' : 'bg-blue-100 text-blue-600'
                    }`}>2</span>
                    <span>Include ticker symbols in chat titles for quick identification</span>
                  </li>
                  <li className="flex items-start">
                    <span className={`inline-flex items-center justify-center w-5 h-5 rounded-full mr-3 text-xs ${
                      isDarkMode ? 'bg-blue-500/20 text-blue-400' : 'bg-blue-100 text-blue-600'
                    }`}>3</span>
                    <span>Create a "Quick Reference" folder for your most valuable insights</span>
                  </li>
                  <li className="flex items-start">
                    <span className={`inline-flex items-center justify-center w-5 h-5 rounded-full mr-3 text-xs ${
                      isDarkMode ? 'bg-blue-500/20 text-blue-400' : 'bg-blue-100 text-blue-600'
                    }`}>4</span>
                    <span>Review and clean up your chat history monthly</span>
                  </li>
                  <li className="flex items-start">
                    <span className={`inline-flex items-center justify-center w-5 h-5 rounded-full mr-3 text-xs ${
                      isDarkMode ? 'bg-blue-500/20 text-blue-400' : 'bg-blue-100 text-blue-600'
                    }`}>5</span>
                    <span>Use keyboard shortcuts (⌘/Ctrl+F) to quickly find content</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ChatHistory;