// src/pages/settings/index.tsx
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSubscription } from '../../contexts/SubscriptionProvider';
import { CountrySelector, defaultCountry } from './components/CountrySelector';
import { 
  User, 
  Shield,
  Phone,
  Sparkles,
  CheckCircle,
  AlertTriangle,
  Eye,
  EyeOff,
  Save,
  Lock,
  Download, 
  Trash2, 
  Settings2,
  ToggleLeft, 
  ToggleRight,
  BarChart,
  Bell,
  Mail,
  Users,
  Plus,
  CreditCard,
  X
} from 'lucide-react';
import { authService } from '../../services/authService';
import { useProfileUpdate, usePasswordUpdate } from './components/handlers';
import { subscriptionService } from '../../services/subscriptionService';

interface ProfileForm {
  name: string;
  email: string;
}

interface PasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface PrivacySettings {
  shareAnalytics: boolean;
  emailNotifications: boolean;
  marketingEmails: boolean;
  publicProfile: boolean;
}
export const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();
  const { isDarkMode } = useTheme();
  const { subscription, isLoading: subscriptionLoading, refreshSubscription } = useSubscription();
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const [verificationStep, setVerificationStep] = useState<'idle' | 'sendCode' | 'verifyCode'>('idle');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Profile Form State
  const [profileForm, setProfileForm] = useState<ProfileForm>({
    name: user?.name || '',
    email: user?.email || ''
  });
  const { isLoading: isProfileSaving, error: profileError, success: profileSuccess, handleProfileUpdate } = useProfileUpdate();

  // Password Form State
  const [passwordForm, setPasswordForm] = useState<PasswordForm>({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const { isLoading: isPasswordSaving, error: passwordError, success: passwordSuccess, handlePasswordUpdate } = usePasswordUpdate();

  // Phone Verification State
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState<string | null>(null);

  // Privacy Settings State
  const [privacySettings, setPrivacySettings] = useState<PrivacySettings>({
    shareAnalytics: true,
    emailNotifications: true,
    marketingEmails: false,
    publicProfile: false
  });
  const [isExporting, setIsExporting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  // Check for subscription success parameter
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const subscriptionStatus = queryParams.get('subscription');
    
    if (subscriptionStatus === 'success') {
      // Remove the query parameter from the URL immediately to prevent re-triggering
      const newUrl = window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
      
      // Set loading state to prevent multiple refreshes
      setIsLoading(true);
      
      // Explicitly refresh the subscription data one time
      if (refreshSubscription) {
        refreshSubscription()
          .then(() => {
            setSuccess('Your subscription has been activated successfully!');
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      }
      
      // Clear the success message after 5 seconds
      const timer = setTimeout(() => {
        setSuccess(null);
      }, 5000);
      
      return () => clearTimeout(timer);
    }
  }, [location.search]);

  const handleProfileSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await handleProfileUpdate(profileForm);
  };

  const handlePasswordSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await handlePasswordUpdate(passwordForm);
  };

  const handlePhoneSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    
    try {
      const fullPhoneNumber = `${selectedCountry.dial_code}${phoneNumber.replace(/\D/g, '')}`;
      
      if (verificationStep !== 'verifyCode') {
        const response = await authService.verifyPhone(fullPhoneNumber);
        setVerificationId(response.verificationId);
        setVerificationStep('verifyCode');
        setSuccess('Verification code sent!');
      } else {
        if (!verificationId) throw new Error('Verification ID not found');
        await authService.confirmPhoneVerification(verificationId, verificationCode);
        setSuccess('Phone verified successfully! 20 credits added to your account.');
        setVerificationStep('idle');
        setPhoneNumber('');
        setVerificationCode('');
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newCode = verificationCode.split('');
      newCode[index] = value;
      setVerificationCode(newCode.join(''));
      
      if (value && index < 5) {
        const nextInput = document.querySelector(`input[name=code-${index + 1}]`) as HTMLInputElement;
        if (nextInput) nextInput.focus();
      }
    }
  };

  const handleExportData = async () => {
    setIsExporting(true);
    try {
      if (!user?.id) throw new Error('User ID not found');
      const response = await authService.exportUserData(user.id);
      // Create and download file
      const blob = new Blob([JSON.stringify(response, null, 2)], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `newsroom-data-${new Date().toISOString().split('T')[0]}.json`;
      a.click();
    } catch (err) {
      setError('Failed to export data');
    } finally {
      setIsExporting(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure? This action cannot be undone.')) {
      try {
        await authService.deleteAccount(user?.id);
        // Redirect to homepage and clear auth
        logout();
        navigate('/');
      } catch (err) {
        setError('Failed to delete account');
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-black text-white">
      {/* Animated background effect */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(17,24,39,1),rgba(0,0,0,0.95))]" />
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#232323_1px,transparent_1px),linear-gradient(to_bottom,#232323_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_50%,#000_70%,transparent_100%)]" />
      </div>

      <div className="relative max-w-6xl mx-auto px-6 py-12">
        {/* Header */}
        <div className="mb-6 bg-white/5 backdrop-blur-xl rounded-2xl border border-white/10 p-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="bg-blue-500/10 p-3 rounded-xl">
                <User className="w-6 h-6 text-blue-400" />
              </div>
              <div>
                <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-300">
                  Account Settings
                </h1>
                <p className="text-gray-400">Manage your profile and preferences</p>
              </div>
            </div>
            
            {/* User avatar circle */}
            <div className="flex items-center space-x-3">
              <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-700 border border-gray-600">
                <span className="text-lg font-medium text-white">
                  {user?.name?.[0]?.toUpperCase()}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="space-y-8">
            {/* Profile Section */}
            <section className="bg-white/5 backdrop-blur-xl rounded-2xl border border-white/10 overflow-hidden">
              <div className="p-6 border-b border-white/10">
                <h2 className="text-xl font-semibold flex items-center space-x-2">
                  <User className="w-5 h-5 text-blue-400" />
                  <span>Profile</span>
                </h2>
              </div>
              
              <form onSubmit={handleProfileSubmit} className="p-6 space-y-6">
                <div className="grid gap-6">
                  <div className="space-y-2">
                    <label className="block text-sm text-gray-400">Display Name</label>
                    <input
                      type="text"
                      value={profileForm.name}
                      onChange={(e) => setProfileForm(prev => ({ ...prev, name: e.target.value }))}
                      className="w-full bg-white/5 border border-white/10 rounded-xl px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm text-gray-400">Email</label>
                    <input
                      type="email"
                      value={profileForm.email}
                      onChange={(e) => setProfileForm(prev => ({ ...prev, email: e.target.value }))}
                      className="w-full bg-white/5 border border-white/10 rounded-xl px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>

                  {profileError && (
                    <div className="text-red-400 text-sm flex items-center space-x-2">
                      <AlertTriangle className="w-4 h-4" />
                      <span>{profileError}</span>
                    </div>
                  )}

                  {profileSuccess && (
                    <div className="text-green-400 text-sm flex items-center space-x-2">
                      <CheckCircle className="w-4 h-4" />
                      <span>{profileSuccess}</span>
                    </div>
                  )}

                  <button
                    type="submit"
                    disabled={isProfileSaving}
                    className="bg-blue-500/10 hover:bg-blue-500/20 text-blue-400 px-6 py-3 rounded-xl transition-colors flex items-center justify-center space-x-2 disabled:opacity-50"
                  >
                    {isProfileSaving ? (
                      <div className="w-5 h-5 border-2 border-blue-400/30 border-t-blue-400 rounded-full animate-spin" />
                    ) : (
                      <>
                        <Save className="w-4 h-4" />
                        <span>Save Changes</span>
                      </>
                    )}
                  </button>
                </div>
              </form>
            </section>
            <section className="bg-white/5 backdrop-blur-xl rounded-2xl border border-white/10 overflow-hidden">
  <div className="p-6 border-b border-white/10">
    <h2 className="text-xl font-semibold flex items-center space-x-2">
      <CreditCard className="w-5 h-5 text-blue-400" />
      <span>Subscription</span>
    </h2>
  </div>
  
  <div className="p-6 space-y-6">
  {subscriptionLoading ? (
    <div className="flex justify-center">
      <div className="w-6 h-6 border-2 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
    </div>
  ) : subscription ? (
    <>
      <div className="flex justify-between">
        <div>
          <h3 className="font-medium">Current Plan</h3>
          <p className={`mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            {subscription.planId === 'pro' ? 'Pro' : subscription.planId}
          </p>
        </div>
        <div>
          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
            subscription.status === 'active'
              ? 'bg-green-100 text-green-800'
              : subscription.status === 'past_due'
              ? 'bg-yellow-100 text-yellow-800'
              : 'bg-gray-100 text-gray-800'
          }`}>
            {subscription.status}
          </span>
        </div>
      </div>

      <div>
        <h3 className="font-medium">Billing Period</h3>
        <p className={`mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          {new Date(subscription.currentPeriodStart).toLocaleDateString()} to {new Date(subscription.currentPeriodEnd).toLocaleDateString()}
        </p>
      </div>
      
      {subscription.cancelAtPeriodEnd && (
        <div className="p-4 rounded-lg bg-yellow-500/10 border border-yellow-500/20">
          <div className="flex items-start">
            <AlertTriangle className="w-5 h-5 text-yellow-500 mt-0.5 mr-3" />
            <div>
              <h4 className="font-medium text-yellow-500">Subscription Ending</h4>
              <p className="text-sm mt-1">
                Your subscription will end on {new Date(subscription.currentPeriodEnd).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>
      )}
      
      {subscription.status === 'active' && !subscription.cancelAtPeriodEnd && (
        <button
          onClick={() => subscriptionService.cancelSubscription()}
          className="text-red-500 hover:text-red-600 text-sm flex items-center"
        >
          <X className="w-4 h-4 mr-1" />
          Cancel subscription
        </button>
      )}
    </>
  ) : (
    <div>
      <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
        You don't have an active subscription.
      </p>
      <button
        onClick={() => navigate('/pricing')}
        className="mt-4 flex items-center text-blue-500 hover:text-blue-600"
      >
        <Plus className="w-4 h-4 mr-1" />
        Get a subscription
      </button>
    </div>
  )}
</div>
</section>

            {/* Security Section */}
            <section className="bg-white/5 backdrop-blur-xl rounded-2xl border border-white/10 overflow-hidden">
              <div className="p-6 border-b border-white/10">
                <h2 className="text-xl font-semibold flex items-center space-x-2">
                  <Shield className="w-5 h-5 text-blue-400" />
                  <span>Security</span>
                </h2>
              </div>
              
              <form onSubmit={handlePasswordSubmit} className="p-6 space-y-6">
                <div className="grid gap-6">
                  <div className="space-y-2">
                    <label className="block text-sm text-gray-400">Current Password</label>
                    <div className="relative">
                      <input
                        type={showCurrentPassword ? "text" : "password"}
                        value={passwordForm.currentPassword}
                        onChange={(e) => setPasswordForm(prev => ({ ...prev, currentPassword: e.target.value }))}
                        className="w-full bg-white/5 border border-white/10 rounded-xl px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent pr-10"
                      />
                      <button
                        type="button"
                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                        className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300"
                      >
                        {showCurrentPassword ? <EyeOff className="w-4 h-4" /> : <Eye className="w-4 h-4" />}
                      </button>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm text-gray-400">New Password</label>
                    <div className="relative">
                      <input
                        type={showNewPassword ? "text" : "password"}
                        value={passwordForm.newPassword}
                        onChange={(e) => setPasswordForm(prev => ({ ...prev, newPassword: e.target.value }))}
                        className="w-full bg-white/5 border border-white/10 rounded-xl px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent pr-10"
                      />
                      <button
                        type="button"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300"
                      >
                        {showNewPassword ? <EyeOff className="w-4 h-4" /> : <Eye className="w-4 h-4" />}
                      </button>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm text-gray-400">Confirm New Password</label>
                    <div className="relative">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        value={passwordForm.confirmPassword}
                        onChange={(e) => setPasswordForm(prev => ({ ...prev, confirmPassword: e.target.value }))}
                        className="w-full bg-white/5 border border-white/10 rounded-xl px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent pr-10"
                      />
                      <button
                        type="button"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300"
                      >
                        {showConfirmPassword ? <EyeOff className="w-4 h-4" /> : <Eye className="w-4 h-4" />}
                      </button>
                    </div>
                  </div>

                  {passwordError && (
                    <div className="text-red-400 text-sm flex items-center space-x-2">
                      <AlertTriangle className="w-4 h-4" />
                      <span>{passwordError}</span>
                    </div>
                  )}

                  {passwordSuccess && (
                    <div className="text-green-400 text-sm flex items-center space-x-2">
                      <CheckCircle className="w-4 h-4" />
                      <span>{passwordSuccess}</span>
                    </div>
                  )}

                  <button
                    type="submit"
                    disabled={isPasswordSaving}
                    className="bg-blue-500/10 hover:bg-blue-500/20 text-blue-400 px-6 py-3 rounded-xl transition-colors flex items-center justify-center space-x-2 disabled:opacity-50"
                  >
                    {isPasswordSaving ? (
                      <div className="w-5 h-5 border-2 border-blue-400/30 border-t-blue-400 rounded-full animate-spin" />
                    ) : (
                      <>
                        <Lock className="w-4 h-4" />
                        <span>Update Password</span>
                      </>
                    )}
                  </button>
                </div>
              </form>
            </section>
          </div>

          {/* Keep existing Phone Verification section */}
          <div className="space-y-8">
            

            {/* Privacy Settings Section */}
            <section className="bg-white/5 backdrop-blur-xl rounded-2xl border border-white/10 overflow-hidden">
              <div className="p-6 border-b border-white/10">
                <h2 className="text-xl font-semibold flex items-center space-x-2">
                  <Settings2 className="w-5 h-5 text-blue-400" />
                  <span>Privacy Settings</span>
                </h2>
              </div>
              
              <div className="p-6 space-y-6">
                {/* Privacy commitment message */}
                <div className="mb-4 p-4 bg-blue-500/5 border border-blue-500/10 rounded-xl">
                  <h3 className="text-sm font-medium text-blue-400 mb-2">Our Privacy Commitment</h3>
                  <p className="text-xs text-gray-400 leading-relaxed">
                    We value your privacy and will never sell or share your personal information with third parties. 
                    All data is encrypted and stored securely. You have complete control over your data and 
                    how we communicate with you.
                  </p>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm text-gray-400">Share Analytics</label>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      <button
                        type="button"
                        onClick={() => setPrivacySettings(prev => ({ ...prev, shareAnalytics: !prev.shareAnalytics }))}
                        className="flex items-center justify-center w-12 h-6 bg-white/5 border border-white/10 rounded-full"
                      >
                        {privacySettings.shareAnalytics ? <ToggleRight className="w-5 h-5 text-blue-400" /> : <ToggleLeft className="w-5 h-5 text-gray-400" />}
                      </button>
                      <span className="text-gray-400">{privacySettings.shareAnalytics ? 'Enabled' : 'Disabled'}</span>
                    </div>
                    <div className="bg-white/5 px-3 py-1.5 rounded-lg flex items-center space-x-2 max-w-xs">
                      <p className="text-xs font-serif text-gray-400">Help us improve by sharing anonymous usage data. We never track personally identifiable information.</p>
                      <BarChart className="w-4 h-4 text-blue-400 flex-shrink-0" />
                    </div>
                  </div>
                  <p className="text-xs text-gray-500 mt-1 ml-15">Analytics data is completely anonymized and used only to improve our service.</p>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm text-gray-400">Email Notifications</label>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      <button
                        type="button"
                        onClick={() => setPrivacySettings(prev => ({ ...prev, emailNotifications: !prev.emailNotifications }))}
                        className="flex items-center justify-center w-12 h-6 bg-white/5 border border-white/10 rounded-full"
                      >
                        {privacySettings.emailNotifications ? <ToggleRight className="w-5 h-5 text-blue-400" /> : <ToggleLeft className="w-5 h-5 text-gray-400" />}
                      </button>
                      <span className="text-gray-400">{privacySettings.emailNotifications ? 'Enabled' : 'Disabled'}</span>
                    </div>
                    <div className="bg-white/5 px-3 py-1.5 rounded-lg flex items-center space-x-2 max-w-xs">
                      <p className="text-xs font-serif text-gray-400">Only essential account updates and security alerts</p>
                      <Bell className="w-4 h-4 text-blue-400 flex-shrink-0" />
                    </div>
                  </div>
                  <p className="text-xs text-gray-500 mt-1 ml-15">These include security alerts, password changes, and account status updates only.</p>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm text-gray-400">Marketing Emails</label>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      <button
                        type="button"
                        onClick={() => setPrivacySettings(prev => ({ ...prev, marketingEmails: !prev.marketingEmails }))}
                        className="flex items-center justify-center w-12 h-6 bg-white/5 border border-white/10 rounded-full"
                      >
                        {privacySettings.marketingEmails ? <ToggleRight className="w-5 h-5 text-blue-400" /> : <ToggleLeft className="w-5 h-5 text-gray-400" />}
                      </button>
                      <span className="text-gray-400">{privacySettings.marketingEmails ? 'Enabled' : 'Disabled'}</span>
                    </div>
                    <div className="bg-white/5 px-3 py-1.5 rounded-lg flex items-center space-x-2 max-w-xs">
                      <p className="text-xs font-serif text-gray-400">Occasional updates about new features</p>
                      <Mail className="w-4 h-4 text-blue-400 flex-shrink-0" />
                    </div>
                  </div>
                  <p className="text-xs text-gray-500 mt-1 ml-15">We promise to never spam you. Maximum one email per month with relevant updates.</p>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm text-gray-400">Public Profile</label>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      <button
                        type="button"
                        onClick={() => setPrivacySettings(prev => ({ ...prev, publicProfile: !prev.publicProfile }))}
                        className="flex items-center justify-center w-12 h-6 bg-white/5 border border-white/10 rounded-full"
                      >
                        {privacySettings.publicProfile ? <ToggleRight className="w-5 h-5 text-blue-400" /> : <ToggleLeft className="w-5 h-5 text-gray-400" />}
                      </button>
                      <span className="text-gray-400">{privacySettings.publicProfile ? 'Enabled' : 'Disabled'}</span>
                    </div>
                    <div className="bg-white/5 px-3 py-1.5 rounded-lg flex items-center space-x-2 max-w-xs">
                      <p className="text-xs font-serif text-gray-400">Allow others to view your basic profile</p>
                      <Users className="w-4 h-4 text-blue-400 flex-shrink-0" />
                    </div>
                  </div>
                  <p className="text-xs text-gray-500 mt-1 ml-15">Only your display name and activity will be visible. Your email and personal data remain private.</p>
                </div>
                
                {/* Data usage note */}
                <div className="mt-6 p-3 bg-white/5 rounded-lg border border-white/10">
                  <p className="text-xs text-gray-400 leading-relaxed">
                    <span className="text-blue-400 font-medium">Data Protection:</span> Your data is protected under our strict privacy policy. 
                    We implement industry-standard security measures to protect your personal information. 
                    You can request deletion of your data at any time through the Delete Account option below.
                  </p>
                </div>
              </div>
            </section>

            {/* Export Data Section */}
            <section className="bg-white/5 backdrop-blur-xl rounded-2xl border border-white/10 overflow-hidden">
              <div className="p-6 border-b border-white/10">
                <h2 className="text-xl font-semibold flex items-center space-x-2">
                  <Download className="w-5 h-5 text-blue-400" />
                  <span>Export Data</span>
                </h2>
              </div>
              
              <div className="p-6 space-y-6">
                <button
                  type="button"
                  onClick={handleExportData}
                  disabled={isExporting}
                  className="w-full bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white px-6 py-3 rounded-xl transition-colors flex items-center justify-center space-x-2 disabled:opacity-50"
                >
                  {isExporting ? (
                    <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                  ) : (
                    <>
                      <Download className="w-4 h-4" />
                      <span>Export Data</span>
                    </>
                  )}
                </button>
              </div>
            </section>

            {/* Delete Account Section */}
            <section className="bg-white/5 backdrop-blur-xl rounded-2xl border border-white/10 overflow-hidden">
              <div className="p-6 border-b border-white/10">
                <h2 className="text-xl font-semibold flex items-center space-x-2">
                  <Trash2 className="w-5 h-5 text-red-400" />
                  <span>Delete Account</span>
                </h2>
              </div>
              
              <div className="p-6 space-y-6">
                <button
                  type="button"
                  onClick={handleDeleteAccount}
                  className="w-full bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700 text-white px-6 py-3 rounded-xl transition-colors flex items-center justify-center space-x-2"
                >
                  <Trash2 className="w-4 h-4" />
                  <span>Delete Account</span>
                </button>
              </div>
            </section>

            {/* Notifications */}
            {(error || success) && (
              <div className={`fixed bottom-8 right-8 p-4 rounded-xl ${
                error ? 'bg-red-500/10 border-red-500/20 text-red-400' : 
                'bg-green-500/10 border-green-500/20 text-green-400'
              } border backdrop-blur-xl`}>
                <div className="flex items-center space-x-2">
                  {error ? (
                    <AlertTriangle className="w-5 h-5" />
                  ) : (
                    <CheckCircle className="w-5 h-5" />
                  )}
                  <span>{error || success}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;