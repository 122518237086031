import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  LineChart,
  ChevronRight,
  TrendingUp,
  Triangle,
  ChevronsUp,
  ChevronsDown,
  Sparkles,
  AlertTriangle,
  Activity,
  Check,
  Info
} from 'lucide-react';

const Patterns = () => {
  const { isDarkMode } = useTheme();

  const patternTypes = [
    {
      title: 'Trend Patterns',
      description: 'Identify and analyze directional market movements and potential trend reversals.',
      icon: TrendingUp,
      features: [
        'Automatic trend line detection',
        'Support and resistance level identification',
        'Breakout and breakdown detection',
        'Momentum analysis integration',
        'Historical trend comparison'
      ],
      examples: [
        'Uptrend channels',
        'Downtrend channels',
        'Sideways consolidation',
        'Trend reversals'
      ],
      image: '/images/docs/trend-patterns.png'
    },
    {
      title: 'Chart Patterns',
      description: 'Recognize classic technical analysis patterns that signal potential price movements.',
      icon: Triangle,
      features: [
        'Head and shoulders pattern recognition',
        'Double tops and bottoms detection',
        'Triangle pattern identification',
        'Flag and pennant pattern alerts',
        'Cup and handle formation analysis'
      ],
      examples: [
        'Head and shoulders',
        'Double top/bottom',
        'Ascending/descending triangles',
        'Wedges and rectangles',
        'Cup and handle'
      ],
      image: '/images/docs/chart-patterns.png'
    },
    {
      title: 'Candlestick Patterns',
      description: 'Identify Japanese candlestick formations that provide insights into market psychology.',
      icon: Activity,
      features: [
        'Single, double, and triple candlestick pattern detection',
        'Reversal pattern identification',
        'Continuation pattern alerts',
        'Pattern strength indicators',
        'Historical success rate analytics'
      ],
      examples: [
        'Doji, hammer, and shooting star',
        'Engulfing patterns (bullish/bearish)',
        'Morning/evening star',
        'Harami patterns',
        'Three black crows/white soldiers'
      ],
      image: '/images/docs/candlestick-patterns.png'
    }
  ];

  const aiFeatures = [
    {
      title: 'AI-Powered Pattern Detection',
      description: 'Advanced machine learning algorithms that automatically scan and identify patterns across multiple timeframes.',
      items: [
        'Trained on millions of historical chart examples',
        'Continuously learning and improving pattern recognition',
        'Customizable sensitivity settings',
        'Multi-timeframe pattern scanning',
        'Real-time pattern alerts'
      ]
    },
    {
      title: 'Pattern Success Probability',
      description: 'Statistical analysis of pattern reliability based on historical performance and current market conditions.',
      items: [
        'Historical completion rate analysis',
        'Market context consideration',
        'Volume confirmation metrics',
        'Pattern quality scoring',
        'Target price projection with confidence levels'
      ]
    },
    {
      title: 'Custom Pattern Builder',
      description: 'Create and save your own pattern definitions to identify unique setups that match your trading strategy.',
      items: [
        'Visual pattern editor',
        'Conditional rule creation',
        'Indicator combination options',
        'Backtest custom patterns',
        'Share patterns with your team'
      ]
    }
  ];

  const bestPractices = [
    'Always confirm patterns with additional indicators',
    'Consider the overall market context and trend',
    'Use multiple timeframes for pattern validation',
    'Look for volume confirmation of pattern signals',
    'Set clear risk management rules for each pattern trade'
  ];

  const commonMistakes = [
    'Relying solely on pattern recognition without confirmation',
    'Ignoring the broader market context',
    'Trading small timeframe patterns with poor risk/reward',
    'Over-optimizing pattern parameters on limited historical data',
    'Failing to adapt when market conditions change'
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <LineChart className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Pattern Recognition
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                How to identify and utilize chart patterns for market analysis
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              Prism's advanced pattern recognition system helps you identify predictive chart patterns that may signal future price movements. 
              By combining traditional technical analysis with AI-powered pattern detection, you can spot potential trading opportunities 
              that align with your investment strategy across multiple timeframes and asset classes.
            </p>
          </div>

          {/* Pattern Types */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Supported Pattern Types
          </h2>

          <div className="space-y-12 mb-16">
            {patternTypes.map((type, index) => {
              const Icon = type.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    } mr-4`}>
                      <Icon className={`w-6 h-6 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <div>
                      <h3 className={`text-xl font-bold ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {type.title}
                      </h3>
                    </div>
                  </div>
                  
                  <p className={`mb-6 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {type.description}
                  </p>
                  
                  <div className="grid md:grid-cols-2 gap-8">
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Features:</h4>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {type.features.map((feature, i) => (
                          <li key={i} className="flex items-start">
                            <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>

                      <h4 className={`text-sm font-medium mb-3 mt-6 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Examples:</h4>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {type.examples.map((example, i) => (
                          <li key={i} className="flex items-start">
                            <span className="w-1.5 h-1.5 rounded-full bg-green-500 mt-1.5 mr-2 flex-shrink-0" />
                            <span>{example}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div className={`rounded-lg overflow-hidden border ${
                      isDarkMode ? 'border-gray-700' : 'border-gray-200'
                    }`}>
                      <div className={`h-60 flex items-center justify-center ${
                        isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                      }`}>
                        <p className={`text-sm ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-500'
                        }`}>
                          {type.title} illustration
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* AI-Powered Features */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            AI-Powered Pattern Features
          </h2>

          <div className="space-y-8 mb-16">
            {aiFeatures.map((feature, index) => (
              <div key={index} className={`p-6 rounded-xl border ${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
              }`}>
                <h3 className={`text-xl font-bold mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {feature.title}
                </h3>
                
                <p className={`mb-4 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {feature.description}
                </p>
                
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {feature.items.map((item, i) => (
                    <li key={i} className="flex items-start">
                      <Sparkles className="w-4 h-4 text-blue-500 mr-2 mt-0.5 flex-shrink-0" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Best Practices */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <h3 className={`text-xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Best Practices for Pattern Trading
            </h3>
            
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h4 className={`font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Do:</h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {bestPractices.map((practice, i) => (
                    <li key={i} className="flex items-start">
                      <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                      <span>{practice}</span>
                    </li>
                  ))}
                </ul>
              </div>
              
              <div>
                <h4 className={`font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Avoid:</h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {commonMistakes.map((mistake, i) => (
                    <li key={i} className="flex items-start">
                      <AlertTriangle className="w-4 h-4 text-yellow-500 mr-2 mt-0.5 flex-shrink-0" />
                      <span>{mistake}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Important Note */}
          <div className={`p-6 rounded-xl border ${
            isDarkMode ? 'bg-blue-900/20 border-blue-800/30' : 'bg-blue-50 border-blue-100'
          } mb-12`}>
            <div className="flex items-start">
              <Info className={`w-6 h-6 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Important Note</h3>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  While pattern recognition can be a powerful tool in technical analysis, it's important 
                  to remember that no pattern works 100% of the time. Always use pattern signals as part of 
                  a comprehensive trading strategy that includes proper risk management and considers fundamental 
                  factors affecting the security you're analyzing.
                </p>
              </div>
            </div>
          </div>

          {/* Related Documentation */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Related Documentation
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <a 
              href="/docs/annotations"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Chart Annotations
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Learn how to add notes and markings to your charts
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
            
            <a 
              href="/docs/canvas-export"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Canvas Export
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Save and share your chart analysis with others
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Patterns;