// src/components/common/LoadingSpinner.tsx

import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Loader2 } from 'lucide-react';

interface LoadingSpinnerProps {
  size?: 'sm' | 'md' | 'lg';
  message?: string;
  progress?: number;
  fullScreen?: boolean;
  overlay?: boolean;
  className?: string;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = 'md',
  message,
  progress,
  fullScreen = false,
  overlay = false,
  className = ''
}) => {
  const { isDarkMode } = useTheme();

  const sizeClasses = {
    sm: 'w-4 h-4',
    md: 'w-8 h-8',
    lg: 'w-12 h-12'
  };

  const spinnerContent = (
    <div className={`flex flex-col items-center justify-center ${className}`}>
      <div className={`relative ${progress !== undefined ? 'mb-4' : ''}`}>
        <Loader2 
          className={`animate-spin ${sizeClasses[size]} text-blue-500`}
        />
        {progress !== undefined && (
          <div className="absolute -bottom-6 w-full text-center text-sm font-medium">
            {Math.round(progress)}%
          </div>
        )}
      </div>

      {message && (
        <div className={`mt-4 text-sm font-medium ${
          isDarkMode ? 'text-gray-300' : 'text-gray-700'
        }`}>
          {message}
        </div>
      )}

      {progress !== undefined && (
        <div className="mt-6 w-48">
          <div className={`h-1.5 rounded-full ${
            isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
          }`}>
            <div
              className="h-full rounded-full bg-blue-500 transition-all duration-300"
              style={{ width: `${Math.min(Math.max(progress, 0), 100)}%` }}
            />
          </div>
        </div>
      )}
    </div>
  );

  if (fullScreen || overlay) {
    return (
      <div className={`fixed inset-0 z-50 ${
        overlay ? 'bg-black/50 backdrop-blur-sm' : isDarkMode ? 'bg-gray-900' : 'bg-gray-50'
      } flex items-center justify-center`}>
        {spinnerContent}
      </div>
    );
  }

  return spinnerContent;
};

export { LoadingSpinner as default } from './LoadingSpinner';