// src/components/research/components/StockSelector.tsx
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useTheme } from '../../../contexts/ThemeContext';
import { SearchResult } from '../../../types/market';
import { marketDataService } from '../../../services/marketDataService';
import { SelectedStockInfo } from './SelectedStockInfo';
import { motion, AnimatePresence } from 'framer-motion';
import debounce from 'lodash/debounce';
import { 
  Search, 
  Building2, 
  ArrowUpRight, 
  Loader2, 
  TrendingUp,
  TrendingDown,
  Globe,
  Activity
} from 'lucide-react';
import { useActivities } from '../../../hooks/useActivities';

interface StockSelectorProps {
  value: string;
  onChange: (value: string) => void;
  onSelect: (ticker: string) => void;
  onValid: (isValid: boolean) => void;
  disabled?: boolean;
}

interface EnhancedSearchResult extends SearchResult {
  currentPrice?: number;
  priceChange?: number;
  changePercent?: number;
  volume?: number;
  description?: string;
  marketCap?: number;
  previousClose?: number;
}

export const StockSelector: React.FC<StockSelectorProps> = ({
  value,
  onChange,
  onValid,
  onSelect,
  disabled = false
}) => {
  const { addActivity } = useActivities();
  const { isDarkMode } = useTheme();
  const [searchResults, setSearchResults] = useState<EnhancedSearchResult[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedStock, setSelectedStock] = useState<EnhancedSearchResult | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Create search function
  const searchStocks = async (query: string) => {
    console.log('Searching for:', query); // Debug log
    if (query.length < 1) return;

    setIsSearching(true);
    setError(null);

    try {
      const results = await marketDataService.searchStocks(query);
      console.log('Search results:', results); // Debug log
       // Get previous day's data for proper previous close
       const prevDayData = await marketDataService.getStockData(results[0].ticker, '1d');
       const prevClose = prevDayData.length > 1 ? prevDayData[prevDayData.length - 2].c : prevDayData[0].o;
       
       // Calculate day range
       const dayLow = Math.min(...prevDayData.map((bar) => bar.l));
       const dayHigh = Math.max(...prevDayData.map((bar) => bar.h));

      // Get first price update
      const enhancedResults = await Promise.all(
        results.slice(0, 5).map(async (result) => {
          try {
            const stockData = await marketDataService.getStockData(result.ticker, '1d');
            const latestData = stockData[stockData.length - 1];
            
            return {
              ...result,
              currentPrice: stockData[stockData.length - 1].c,
              priceChange: stockData[stockData.length - 1].c - prevClose,
              changePercent: ((stockData[stockData.length - 1].c - prevClose) / prevClose) * 100,
              previousClose: prevClose,
              volume: stockData[stockData.length - 1].v,
              dayRange: {
                low: dayLow,
                high: dayHigh
              },
              exchange: result.primaryExchange || '',
            };
          } catch {
            return {
              ...result,
              exchange: result.primaryExchange || '',
            };
          }
        })
      );

      setSearchResults(enhancedResults);
      setShowDropdown(true);
      onValid(enhancedResults.length > 0);
    } catch (err) {
      console.error('Search error:', err); // Debug log
      setError('Failed to search stocks');
      onValid(false);
    } finally {
      setIsSearching(false);
    }
  };

  // Debounce the search
  const debouncedSearch = useCallback(
    debounce((query: string) => searchStocks(query), 300),
    []
  );

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.toUpperCase();
    onChange(newValue);
    console.log('Input changed:', newValue); // Debug log
    
    if (newValue.length >= 1) {
      debouncedSearch(newValue);
    } else {
      setShowDropdown(false);
      setSearchResults([]);
    }
  };

  // Handle stock selection
  const handleStockSelect = async (result: SearchResult) => {
    console.log('Selected stock:', result); // Debug log
    setShowDropdown(false);
    setIsSearching(true);
    
    try {
      await addActivity({
        type: 'analysis',
        title: `Technical Analysis: ${result.ticker}`,
        description: `Started technical analysis for ${result.ticker}`,
        metadata: {
          type: 'chart_analysis',
          ticker: result.ticker,
          
          timestamp: new Date().toISOString()
        }
      });
      const [stockData, stockDetails] = await Promise.all([
        marketDataService.getStockData(result.ticker, '1d'),
        marketDataService.getStockDetails(result.ticker)
      ]);
      
      const enhancedResult = {
        ...result,
        ...stockDetails,
        currentPrice: stockData[stockData.length - 1].c,
        priceChange: stockData[stockData.length - 1].c - stockData[0].o,
        changePercent: ((stockData[stockData.length - 1].c - stockData[0].o) / stockData[0].o) * 100,
        volume: stockData[stockData.length - 1].v
      };
      
      setSelectedStock(enhancedResult);
      onSelect(result.ticker); // Call onSelect instead of onChange for final selection
    } catch (error) {
      console.error('Stock selection error:', error); // Debug log
      setError('Failed to load stock details');
    } finally {
      setIsSearching(false);
    }
  };

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Search Input */}
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          value={value}
          onChange={handleInputChange}
          onFocus={() => {
            if (value.length >= 1) {
              debouncedSearch(value);
            }
          }}
          disabled={disabled}
          placeholder="Enter stock symbol (e.g., AAPL)"
          className={`w-full pl-12 pr-12 py-4 rounded-xl border-2 transition-all duration-200 
            ${isDarkMode
              ? 'bg-gray-900 border-gray-800 text-white focus:border-blue-500'
              : 'bg-white border-gray-200 text-gray-900 focus:border-blue-500'
            } focus:ring-2 focus:ring-blue-500/20 focus:outline-none
            ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
          `}
        />
        <Search className={`absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 
          ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}
        />
        {isSearching && (
          <Loader2 className={`absolute right-4 top-1/2 -translate-y-1/2 w-5 h-5 animate-spin 
            ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}
          />
        )}
      </div>

      {/* Search Results Dropdown */}
      <AnimatePresence>
        {showDropdown && searchResults.length > 0 && (
          <div
            className="absolute z-[999] top-full left-0 right-0 mt-2 bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-200 dark:border-gray-700 max-h-96 overflow-auto"
          >
            {searchResults.map((result) => (
  <button
    key={result.ticker}
    onClick={() => handleStockSelect(result)}
    className={`w-full px-6 py-4 hover:bg-blue-500/10 transition-colors 
      flex items-center justify-between group text-left`}
  >
    {/* Stock Info with Icon */}
    <div className="flex items-center space-x-4">
      <div
        className={`overflow-hidden relative w-10 h-10 rounded-full flex items-center justify-center 
          ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} 
          border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}
      >
        <img
          src={`/api/market/company-logo/${result.ticker}`}
          alt={`${result.name} logo`}
          className="object-cover w-full h-full"
          onError={(e) => {
        e.currentTarget.style.display = 'none';
        const parent = e.currentTarget.parentElement;
        if (parent) {
          parent.classList.add('fallback-icon');
          parent.innerHTML = `
            <span class="${
          isDarkMode ? 'text-gray-400' : 'text-gray-600'
            } font-semibold text-lg flex items-center justify-center w-full h-full">
          ${result.name[0]}
            </span>
          `;
        }
          }}
        />
      </div>

      {/* Company Info */}
      <div>
        <div className="flex items-center">
          <span className={`text-lg font-semibold 
            ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
          >
            {result.ticker}
          </span>
          <span className={`ml-2 px-2 py-0.5 rounded text-xs 
            ${isDarkMode ? 'bg-gray-800 text-gray-400' : 'bg-gray-100 text-gray-600'}`}
          >
            {result.market}
          </span>
        </div>
        <span className={`text-sm 
          ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}
        >
          {result.name}
        </span>
      </div>
    </div>

    {/* Price Info - Rest of your existing price display code */}
    {result.currentPrice && (
      <div className="flex items-center space-x-4">
                    <div className="text-right">
                      <div className={`text-lg font-semibold 
                        ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
                      >
                        ${result.currentPrice.toFixed(2)}
                      </div>
                      {result.changePercent && (
                        <div className={`flex items-center text-sm 
                          ${result.changePercent > 0 ? 'text-green-500' : 'text-red-500'}`}
                        >
                          {result.changePercent > 0 ? (
                            <TrendingUp className="w-4 h-4 mr-1" />
                          ) : (
                            <TrendingDown className="w-4 h-4 mr-1" />
                          )}
                          {Math.abs(result.changePercent).toFixed(2)}%
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </button>
            ))}
          </div>
        )}
      </AnimatePresence>

      {/* Selected Stock Info */}
      {selectedStock && <SelectedStockInfo stock={{
        ticker: selectedStock.ticker,
        name: selectedStock.name,
        price: selectedStock.currentPrice || 0,
        change: selectedStock.priceChange || 0,
        changePercent: selectedStock.changePercent || 0,
        previousClose: selectedStock.previousClose || 0,
        volume: selectedStock.volume || 0,
        description: selectedStock.description,
        exchange: selectedStock.market || '',
        marketCap: selectedStock.marketCap,
      }} />}

      {/* Error Message */}
      {error && (
        <p className="text-sm text-red-500 mt-2">
          {error}
        </p>
      )}
    </div>
  );
};

export default StockSelector;