// src/services/screeningService.ts

import { claudeService } from './claudeService';
import { marketDataService } from './marketDataService';
import logger from '../config/logger';

export interface ScreeningCriteria {
  type: 'fundamental' | 'technical' | 'sentiment';
  field: string;
  operator: 'gt' | 'lt' | 'eq' | 'between' | 'contains';
  value: number;
}

export interface ScreeningResult {
  ticker: string;
  name: string;
  score: number;
  matchedCriteria: string[];
  price: number;
  change: number;
  volume: number;
}

class ScreeningService {
  private static instance: ScreeningService;

  private constructor() {}

  static getInstance(): ScreeningService {
    if (!ScreeningService.instance) {
      ScreeningService.instance = new ScreeningService();
    }
    return ScreeningService.instance;
  }

  async processNaturalLanguageQuery(query: string): Promise<ScreeningResult[]> {
    try {
      // Create Claude prompt
      const prompt = `You are a stock screening expert. Convert this natural language request into a JSON format:
      "${query}"
      
      Return ONLY a valid JSON object without any additional text.
      The JSON must have this exact structure:
      {
        "criteria": [
          {
            "type": "technical",
            "field": "price",
            "operator": "gt",
            "value": 100
          }
        ]
      }
      
      Valid types: "fundamental", "technical", "sentiment"
      Valid fields: "price", "volume", "market_cap", "pe_ratio", "revenue_growth"
      Valid operators: "gt", "lt", "eq"
      Values must be numbers`;

      // Get response from Claude
      const response = await claudeService.generateCode({
        systemPrompt: '',
        userPrompt: prompt
      });

      // Parse the response
      const cleanedResponse = response.trim()
        .replace(/^```json\s*/, '')  // Remove any markdown json prefix
        .replace(/\s*```$/, '')      // Remove any markdown suffix
        .trim();

      const parsedResponse = JSON.parse(cleanedResponse);
      
      // Validate the response structure
      if (!parsedResponse.criteria || !Array.isArray(parsedResponse.criteria)) {
        throw new Error('Invalid response format');
      }

      // Execute screen with criteria
      return await this.executeScreen(parsedResponse.criteria);

    } catch (error) {
      console.log('Screening analysis failed:', error);
      throw new Error('Failed to analyze screening request');
    }
  }

  async executeScreen(criteria: ScreeningCriteria[]): Promise<ScreeningResult[]> {
    try {
      // Get stock universe
      const stocks = await marketDataService.getPopularStocks();
      const results: ScreeningResult[] = [];

      // Process each stock
      for (const stock of stocks) {
        try {
          const [priceData, details] = await Promise.all([
            marketDataService.getStockData(stock.ticker, '1d'),
            marketDataService.getStockDetails(stock.ticker)
          ]);

          const latestPrice = priceData[priceData.length - 1];
          if (!latestPrice) continue;

          // Apply criteria
          const matches = await this.evaluateStock(stock, latestPrice, details, criteria);
          
          if (matches.length === criteria.length) {
            results.push({
              ticker: stock.ticker,
              name: stock.name,
              score: matches.length / criteria.length,
              matchedCriteria: matches,
              price: latestPrice.c,
              change: ((latestPrice.c - latestPrice.o) / latestPrice.o) * 100,
              volume: latestPrice.v
            });
          }
        } catch (error) {
          logger.warn(`Failed to evaluate ${stock.ticker}:`, error);
          continue;
        }
      }

      // Sort by score
      return results.sort((a, b) => b.score - a.score);

    } catch (error) {
      console.log('Screen execution failed:', error);
      throw new Error('Failed to execute screen');
    }
  }

  private async evaluateStock(
    stock: any,
    price: any,
    details: any,
    criteria: ScreeningCriteria[]
  ): Promise<string[]> {
    const matches: string[] = [];

    for (const criterion of criteria) {
      let value = this.getStockValue(price, details, criterion);
      
      if (this.compareValues(value, criterion.value, criterion.operator)) {
        matches.push(
          `${criterion.field} ${this.getOperatorSymbol(criterion.operator)} ${criterion.value}`
        );
      }
    }

    return matches;
  }

  private getStockValue(price: any, details: any, criterion: ScreeningCriteria): number {
    switch (criterion.field) {
      case 'price':
        return price.price;
      case 'volume':
        return price.volume;
      case 'market_cap':
        return details.marketCap || 0;
      case 'pe_ratio':
        return details.peRatio || 0;
      case 'revenue_growth':
        return details.revenueGrowth || 0;
      default:
        return 0;
    }
  }

  private compareValues(actual: number, target: number, operator: string): boolean {
    switch (operator) {
      case 'gt':
        return actual > target;
      case 'lt':
        return actual < target;
      case 'eq':
        return Math.abs(actual - target) < 0.0001; // Float comparison
      default:
        return false;
    }
  }

  private getOperatorSymbol(operator: string): string {
    switch (operator) {
      case 'gt':
        return '>';
      case 'lt':
        return '<';
      case 'eq':
        return '=';
      default:
        return '';
    }
  }
}

export const screeningService = ScreeningService.getInstance();