// src/components/walkthrough/AppWalkthrough.tsx
import React, { useState, useEffect } from 'react';
import { ArrowRight, X, Lightbulb } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import { Button } from '../ui/button';
import { motion, AnimatePresence } from 'framer-motion';

interface WalkthroughStep {
  title: string;
  description: string;
  image?: string;
  tips?: string[];
  elementId?: string;
  customLayout?: React.ReactNode;
}



interface AppWalkthroughProps {
  steps: WalkthroughStep[];
  isOpen: boolean;
  onComplete: () => void;
  onSkip: () => void;
}

const AppWalkthrough: React.FC<AppWalkthroughProps> = ({ 
  steps, 
  isOpen, 
  onComplete, 
  onSkip 
}) => {
  const { isDarkMode } = useTheme();
  const [currentStep, setCurrentStep] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Prevent scrolling on the body when walkthrough is active
    document.body.style.overflow = isOpen ? 'hidden' : '';
    
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      handleComplete();
    }
  };

  const handleComplete = () => {
    setIsVisible(false);
    setTimeout(onComplete, 500);
  };

  const handleSkip = () => {
    setIsVisible(false);
    setTimeout(onSkip, 500);
  };

  const currentStepData = steps[currentStep];
  const isLastStep = currentStep === steps.length - 1;

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div 
        className={`fixed inset-0 z-[9999] ${isDarkMode ? 'bg-black/95' : 'bg-gray-900/95'}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: isVisible ? 1 : 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        {/* Logo container */}
        <motion.div 
          className="absolute top-6 left-6 z-10"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          <img 
            src={isDarkMode ? '/logo-dark.png' : '/logo-light.png'} 
            alt="Newsroom Logo" 
            className="h-10" 
          />
        </motion.div>
        
        {/* Close button */}
        <motion.button 
          onClick={handleSkip}
          className="absolute top-6 right-6 text-white hover:text-gray-300 transition-colors"
          aria-label="Close walkthrough"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          <X size={24} />
        </motion.button>

        {/* Progress indicator */}
        <motion.div 
          className="absolute top-6 left-1/2 transform -translate-x-1/2 flex items-center gap-2"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          {steps.map((_, index) => (
            <div 
              key={index} 
              className={`w-2 h-2 rounded-full transition-all duration-300 ${
                index === currentStep 
                  ? 'bg-blue-500 w-4' 
                  : index < currentStep 
                    ? 'bg-blue-400' 
                    : 'bg-gray-500'
              }`}
            />
          ))}
        </motion.div>
        
        {/* Content area */}
        <div className="w-full h-full flex flex-col items-center justify-center overflow-hidden">
          <AnimatePresence mode="wait">
            <motion.div 
              key={currentStep}
              className="max-w-6xl w-full h-full flex flex-col items-center justify-center px-6"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.5 }}
            >
              {/* If there's a custom layout, render it */}
              {currentStepData.customLayout ? (
                currentStepData.customLayout
              ) : (
                <div className="w-full max-w-4xl mx-auto">
                  {/* Default layout */}
                  <motion.div 
                    className={`bg-${isDarkMode ? 'zinc-900' : 'white'} rounded-2xl shadow-2xl p-8 relative overflow-hidden border ${isDarkMode ? 'border-zinc-800' : 'border-gray-200'}`}
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.4 }}
                    >
                      <h2 className={`text-3xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                        {currentStepData.title}
                      </h2>
                      
                      <p className={`text-lg mb-6 max-w-3xl ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                        {currentStepData.description}
                      </p>
                      
                      {currentStepData.tips && currentStepData.tips.length > 0 && (
                        <div className={`mt-6 p-4 rounded-lg ${isDarkMode ? 'bg-blue-900/20' : 'bg-blue-50'} border ${isDarkMode ? 'border-blue-800' : 'border-blue-100'}`}>
                          <div className="flex items-center mb-2">
                            <Lightbulb className="w-5 h-5 mr-2 text-blue-500" />
                            <h3 className={`font-medium ${isDarkMode ? 'text-blue-300' : 'text-blue-700'}`}>Pro Tips</h3>
                          </div>
                          <ul className="space-y-2 pl-7 list-disc">
                            {currentStepData.tips.map((tip, index) => (
                              <motion.li 
                                key={index}
                                className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}
                                initial={{ opacity: 0, x: -10 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ delay: 0.6 + index * 0.1 }}
                              >
                                {tip}
                              </motion.li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </motion.div>
                  </motion.div>
                </div>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
        
        {/* Next button */}
        <motion.div
          className="absolute bottom-10 right-10"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
        >
          <Button 
            onClick={handleNext}
            className="rounded-full flex items-center justify-center p-6 bg-blue-600 hover:bg-blue-700 text-white shadow-lg"
          >
            <ArrowRight size={24} />
          </Button>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AppWalkthrough;