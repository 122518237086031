import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  BookmarkIcon,
  Edit,
  Trash,
  Move,
  Filter,
  ArrowUpDown,
  Star,
  Clock,
  Bell,
  ChevronRight,
  Tag,
  Check,
  AlertTriangle
} from 'lucide-react';

const SavedStocks = () => {
  const { isDarkMode } = useTheme();

  const sections = [
    {
      title: 'Managing Individual Stocks',
      description: 'Learn how to manage individual stocks in your watchlists effectively.',
      items: [
        'Edit notes and tags for each saved stock',
        'Set custom price alerts for important thresholds',
        'Add personal sentiment indicators (bullish/bearish)',
        'Track your position size and average cost basis',
        'View historical actions taken on each stock'
      ],
      image: '/images/docs/saved-stock-management.png'
    },
    {
      title: 'Bulk Actions',
      description: 'Work with multiple stocks at once to save time and stay organized.',
      items: [
        'Select multiple stocks using checkboxes or Shift+Click',
        'Move selected stocks between watchlists',
        'Apply tags to multiple stocks simultaneously',
        'Delete multiple stocks from watchlists',
        'Export selected stocks to CSV or PDF reports'
      ],
      image: '/images/docs/bulk-stock-actions.png'
    },
    {
      title: 'Stock Information & Research',
      description: 'Access detailed information and research directly from your saved stocks.',
      items: [
        'View key metrics and price information at a glance',
        'One-click access to detailed stock view',
        'Generate AI analysis of selected stocks',
        'Compare metrics across multiple saved stocks',
        'View recent news and events for saved stocks'
      ],
      image: '/images/docs/stock-research.png'
    }
  ];

  const features = [
    {
      title: 'Custom Notes',
      description: 'Add personalized notes to each stock to record your investment thesis, important dates, or reasons for tracking',
      icon: Edit
    },
    {
      title: 'Custom Tags',
      description: 'Create and apply tags to categorize stocks across watchlists (e.g., "Dividend", "Growth", "Swing Trade")',
      icon: Tag
    },
    {
      title: 'Price Alerts',
      description: 'Set custom price alerts to be notified when a stock reaches important price levels',
      icon: Bell
    },
    {
      title: 'Performance Tracking',
      description: 'Monitor the performance of saved stocks over time with customizable timeframes',
      icon: Clock
    }
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <BookmarkIcon className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Managing Saved Stocks
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                How to organize, annotate, and get the most value from your saved stocks
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              Once you've created watchlists and added stocks, Prism provides powerful tools 
              to manage and organize your saved stocks. This guide covers how to edit, organize,
              and get valuable insights from the stocks you're tracking, helping you make better investment
              decisions based on your personalized watchlists.
            </p>
          </div>

          {/* Management Sections */}
          <div className="space-y-16 mb-16">
            {sections.map((section, index) => (
              <div key={index} className={`p-6 rounded-xl border ${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
              }`}>
                <h2 className={`text-2xl font-bold mb-4 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {section.title}
                </h2>
                
                <p className={`mb-6 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {section.description}
                </p>
                
                <div className="grid md:grid-cols-2 gap-8">
                  <div>
                    <h3 className={`text-sm font-medium mb-3 ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>Features:</h3>
                    <ul className={`space-y-2 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-600'
                    }`}>
                      {section.items.map((item, i) => (
                        <li key={i} className="flex items-start">
                          <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  
                  <div className={`rounded-lg overflow-hidden border ${
                    isDarkMode ? 'border-gray-700' : 'border-gray-200'
                  }`}>
                    <div className={`h-48 flex items-center justify-center ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                    }`}>
                      <p className={`text-sm ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        Illustration for {section.title.toLowerCase()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Key Features */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Key Personalization Features
          </h2>
          
          <div className="grid grid-cols-2 gap-6 mb-16">
            {features.map((feature, index) => {
              const Icon = feature.icon;
              return (
                <div 
                  key={index}
                  className={`p-6 rounded-xl border ${
                    isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                  }`}
                >
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg mr-4 ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    }`}>
                      <Icon className={`w-5 h-5 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <h3 className={`font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {feature.title}
                    </h3>
                  </div>
                  
                  <p className={`${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {feature.description}
                  </p>
                </div>
              );
            })}
          </div>

          {/* Best Practices Section */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <h3 className={`text-xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Best Practices
            </h3>
            
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h4 className={`font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Do:</h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Add detailed notes about why you're tracking each stock</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Regularly review and update your watchlists</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Use tags consistently across all your watchlists</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Set meaningful price alerts at support/resistance levels</span>
                  </li>
                </ul>
              </div>
              
              <div>
                <h4 className={`font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Avoid:</h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <AlertTriangle className="w-4 h-4 text-yellow-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Adding stocks without clear reasons or investment theses</span>
                  </li>
                  <li className="flex items-start">
                    <AlertTriangle className="w-4 h-4 text-yellow-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Letting your watchlists grow too large to be manageable</span>
                  </li>
                  <li className="flex items-start">
                    <AlertTriangle className="w-4 h-4 text-yellow-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Setting too many price alerts that become overwhelming</span>
                  </li>
                  <li className="flex items-start">
                    <AlertTriangle className="w-4 h-4 text-yellow-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Neglecting to update your notes after significant events</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Related Documentation */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Related Documentation
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <a 
              href="/docs/sort-filter"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Sorting & Filtering
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Learn how to sort and filter your saved stocks efficiently
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
            
            <a
              href="/docs/stock-view"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Stock View Options
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Explore different ways to view and display your saved stocks
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default SavedStocks;