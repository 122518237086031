import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { Subscription, SubscriptionStatus } from '../types/subscription';
import { subscriptionService } from '../services/subscriptionService';
import { useAuth } from './AuthContext';

interface SubscriptionContextType {
  subscription: Subscription | null;
  isLoading: boolean;
  error: string | null;
  success: string | null; // Add success message to the context
  refreshSubscription: () => Promise<void>;
  hasActiveSubscription: boolean;
  isSubscriptionLoading: boolean;
  canUseFeature: (featureName: string) => boolean;
}

const SubscriptionContext = createContext<SubscriptionContextType | null>(null);

export const useSubscription = () => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error('useSubscription must be used within a SubscriptionProvider');
  }
  return context;
};

interface SubscriptionProviderProps {
  children: ReactNode;
}

// Add features mapping constant
const FEATURE_REQUIREMENTS: Record<string, string[]> = {
  'advanced-research': ['pro'],
  'unlimited-chats': ['pro'],
  'custom-templates': ['pro'],
  'export-data': ['pro'],
  'priority-support': ['pro'],
  'beta-features': ['pro'],
};

// Helper to normalize status
const normalizeStatus = (status?: string): SubscriptionStatus => {
  if (!status) return 'INACTIVE' as any;
  const normalized = status.toLowerCase();
  if (normalized === 'active' || normalized === 'trialing') return 'active';
  if (normalized === 'past_due') return 'past_due';
  if (normalized === 'canceled') return 'canceled';
  return normalized as SubscriptionStatus;
};

export const SubscriptionProvider: React.FC<SubscriptionProviderProps> = ({ children }) => {
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null); // Add the missing state variable
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  
  const fetchSubscription = async () => {
    setIsLoading(true);
    try {
      // Get the response from the subscription service
      const response = await subscriptionService.getSubscriptionStatus();
      
      // Enhanced logging to diagnose issues
      console.log('Raw subscription response:', response);
      
      // Normalize status and tier regardless of data structure
      const tier = (response?.tier || response?.subscription?.tier || 'free').toLowerCase();
      const status = (response?.status || response?.subscription?.status || 'inactive').toLowerCase();
      
      // Check if user has pro status from either the subscription object or top-level fields
      const isPro = tier === 'pro' && (status === 'active' || status === 'trialing');
      
      if (isPro) {
        console.log('User has PRO status!');
      } else {
        console.log('User does not have PRO status', { tier, status });
      }
      
      // Create a normalized subscription object
      let subscriptionData: Subscription | null = null;
      
      if (response?.subscription) {
        // If we have a subscription object, use it with normalized fields
        subscriptionData = {
          ...response.subscription,
          tier: tier,
          status: status,
          // Convert string dates to Date objects if they exist
          currentPeriodStart: response.subscription.currentPeriodStart ? 
            new Date(response.subscription.currentPeriodStart) : new Date(),
          currentPeriodEnd: response.subscription.currentPeriodEnd ? 
            new Date(response.subscription.currentPeriodEnd) : new Date(),
          canceledAt: response.subscription.canceledAt ? 
            new Date(response.subscription.canceledAt) : undefined,
          expiresAt: response.subscription.expiresAt ? 
            new Date(response.subscription.expiresAt) : null,
        };
      } else if (isPro) {
        // If we don't have a subscription object but user is pro, create a minimal one
        subscriptionData = {
          id: 'pending-sync',
          userId: 'pending-sync',
          planId: 'pro',
          tier: 'pro',
          status: 'active',
          currentPeriodStart: new Date(),
          currentPeriodEnd: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
        };
      }
      
      setSubscription(subscriptionData);
      setError(null);
    } catch (error) {
      console.error('Failed to fetch subscription status', error);
      setError('Failed to load subscription data');
      setSubscription(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchSubscription();
    }
  }, [isAuthenticated]);

  // Check if user has an active subscription
  const hasActiveSubscription = Boolean(
    subscription && 
    (normalizeStatus(subscription.status) === 'active' || 
     subscription.tier?.toLowerCase() === 'pro')
  );

  // Check for subscription=success in URL 
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const subscriptionStatus = queryParams.get('subscription');
    
    if (subscriptionStatus === 'success') {
      // Remove the query parameter from the URL immediately to prevent re-triggering
      const newUrl = window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
      
      // Set loading state to prevent multiple refreshes
      setIsLoading(true);
      
      // Define a function to retry subscription status checks
      const checkSubscriptionWithRetry = async (retries = 5, delay = 2000) => {
        try {
          const response = await subscriptionService.getSubscriptionStatus();
          console.log('Subscription check attempt response:', response);
          
          // Check if the subscription is actually active
          const isPro = response?.subscription?.planId === 'pro' && 
                      (response?.subscription?.status?.toLowerCase() === 'active' || 
                       response?.tier?.toLowerCase() === 'pro');
          
          if (isPro) {
            // Success! Update subscription state
            fetchSubscription();
            setSuccess('Your subscription has been activated successfully!');
            setIsLoading(false);
            return true;
          } else if (retries > 0) {
            // Not active yet but we have retries left
            console.log(`Subscription not active yet, retrying in ${delay}ms. Attempts left: ${retries}`);
            setTimeout(() => checkSubscriptionWithRetry(retries - 1, delay), delay);
            return false;
          } else {
            // Out of retries
            console.warn('Failed to confirm subscription activation after multiple attempts');
            setSuccess('Your payment was successful. If your subscription is not active within a few minutes, please contact support.');
            setIsLoading(false);
            return false;
          }
        } catch (error) {
          console.error('Error checking subscription status:', error);
          setIsLoading(false);
          return false;
        }
      };
      
      // Start the retry process
      checkSubscriptionWithRetry();
      
      // Clear the success message after 10 seconds
      const timer = setTimeout(() => {
        setSuccess(null);
      }, 10000);
      
      return () => clearTimeout(timer);
    }
  }, [location.search]);

  // Add feature check functionality
  const canUseFeature = (featureName: string): boolean => {
    if (!subscription) return false;
    if (normalizeStatus(subscription.status) !== 'active') return false;
    
    const requiredPlans = FEATURE_REQUIREMENTS[featureName];
    if (!requiredPlans) return true; // If no specific requirement, allow by default
    
    const planId = subscription.planId?.toLowerCase() || '';
    const tier = subscription.tier?.toLowerCase() || '';
    
    return requiredPlans.includes(planId) || requiredPlans.includes(tier);
  };

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        isLoading,
        error,
        success, // Add the success message to the context
        refreshSubscription: fetchSubscription,
        hasActiveSubscription,
        isSubscriptionLoading: isLoading,
        canUseFeature
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};