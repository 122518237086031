// client/src/components/layout/MainLayout.tsx
import React from 'react';
import { MarketingHeader } from './MarketingHeader';
import { useAuth } from '../../contexts/AuthContext';
import { Footer } from '../../components/Footer';
import { Outlet, useLocation } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';

interface MainLayoutProps {
  children: React.ReactNode;
  showFooter?: boolean;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ 
  children, 
  showFooter = true 
}) => {
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <div className={`min-h-screen flex flex-col ${
      isDarkMode ? 'bg-black' : 'bg-gray-50'
    } relative`}>
      <div className={isHomePage ? 'mt-[7.25vh]' : ''}>
        <MarketingHeader />
      </div>
      <main className="flex-grow">
        {children}
      </main>
      {showFooter && <Footer />}
    </div>
  );
};