// src/components/market/StockDetailView.tsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import { marketDataService } from '../../services/marketDataService';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { Alert } from '../ui/alert';
import { 
  ArrowLeft,
  TrendingUp,
  TrendingDown,
  Globe,
  Users,
  Building2,
  BarChart2
} from 'lucide-react';
import { CompanyDetails } from '../../types/market';
import { StockPrice } from '../../types/market';




interface StockDetailViewState {
    details: CompanyDetails | null;
    priceData: StockPrice | null;
    isLoading: boolean;
    error: string | null;
  }
  
  export const StockDetailView: React.FC = () => {
    const { ticker } = useParams<{ ticker: string }>();
    const navigate = useNavigate();
    const { isDarkMode } = useTheme();
    const [details, setDetails] = useState<CompanyDetails | null>(null);
    const [priceData, setPriceData] = useState<StockPrice | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
  
    useEffect(() => {
      const loadStockData = async () => {
        if (!ticker) return;
  
        setIsLoading(true);
        try {
          const [stockDetails, stockPrices] = await Promise.all([
            marketDataService.getStockDetails(ticker),
            marketDataService.getStockData(ticker, '1d')
          ]);
  
          setDetails(stockDetails);
          setPriceData(stockPrices[0] || null); // Get most recent price data
        } catch (err) {
          setError(err instanceof Error ? err.message : 'Failed to load stock data');
        } finally {
          setIsLoading(false);
        }
      };
  
      loadStockData();
    }, [ticker]);

  if (isLoading) {
    return <LoadingSpinner size="lg" />;
  }

  if (error || !details) {
    return <Alert variant="destructive">{error || 'Stock not found'}</Alert>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header */}
      <div className="flex items-center mb-8">
        <button
          onClick={() => navigate('/dashboard/market')}
          className={`mr-4 p-2 rounded-lg ${
            isDarkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-100'
          }`}
        >
          <ArrowLeft className="w-6 h-6" />
        </button>
        <div>
          <h1 className={`text-2xl font-bold ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            {details.name} ({ticker})
          </h1>
          <p className={`mt-1 ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}>
            {details.exchange} • {details.sector}
          </p>
        </div>
      </div>

      {/* Price Overview */}
      {priceData && (
        <div className={`grid grid-cols-1 md:grid-cols-4 gap-4 mb-8`}>
          {[
            {
              label: 'Current Price',
              value: `$${priceData.c.toFixed(2)}`,
              change: ((priceData.c - priceData.o) / priceData.o * 100).toFixed(2),
              icon: priceData.c > priceData.o ? TrendingUp : TrendingDown
            },
            {
              label: 'Market Cap',
              value: `$${(details.marketCap / 1e9).toFixed(2)}B`,
              icon: Building2
            },
            {
              label: 'Volume',
              value: priceData.v.toLocaleString(),
              icon: BarChart2
            },
            {
              label: 'Employees',
              value: details.employees.toLocaleString(),
              icon: Users
            }
          ].map((metric, index) => (
            <div
              key={index}
              className={`p-4 rounded-lg ${
                isDarkMode ? 'bg-gray-800' : 'bg-white'
              }`}
            >
              <div className="flex items-center justify-between mb-2">
                <span className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  {metric.label}
                </span>
                <metric.icon className={`w-5 h-5 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`} />
              </div>
              <div className="flex items-center justify-between">
                <span className={`text-xl font-bold ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {metric.value}
                </span>
                {metric.change && (
                  <span className={`text-sm font-medium ${
                    Number(metric.change) >= 0 ? 'text-green-500' : 'text-red-500'
                  }`}>
                    {Number(metric.change) >= 0 ? '+' : ''}{metric.change}%
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Company Description */}
      <div className={`p-6 rounded-lg ${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      }`}>
        <h2 className={`text-xl font-bold mb-4 ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          About {details.name}
        </h2>
        <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
          {details.description}
        </p>
        {details.homepage && (
          <a
            href={details.homepage}
            target="_blank"
            rel="noopener noreferrer"
            className="mt-4 inline-flex items-center text-blue-500 hover:text-blue-600"
          >
            <Globe className="w-4 h-4 mr-2" />
            Visit Website
          </a>
        )}
      </div>
    </div>
  );
};

export default StockDetailView;