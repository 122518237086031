// src/router/AppRouter.tsx
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MainLayout } from '../components/layout/MainLayout';
import { ProtectedRoute } from './ProtectedRoute';
import { Home } from '../pages/Home';
import { Home as Home2 } from '../pages/Home2';
import { Login } from '../pages/auth/Login';
import { Register } from '../pages/auth/Register';
import { Dashboard } from '../pages/dashboard/Dashboard';
import PrismFeatures from '../pages/PrismFeatures';
import CanvasFeatures from '../pages/CanvasFeatures';
import Pricing from '../pages/Pricing';
import About from '../pages/About';
import Contact from '../pages/Contact';
import OAuthCallback from '../components/auth/OAuthCallback';
import ApiDocumentation from '../pages/docs/api';
import Documentation from '../pages/docs/documentation';
import ReleaseNotes from '../pages/docs/release-notes';
import Tutorials from '../pages/docs/tutorials';
import { BetaRequest } from '../pages/BetaRequest';

import { BetaManagement } from '../pages/admin/BetaManagement';
import SystemStatus from '../pages/status/SystemStatus';
import NotFound from '../pages/NotFound';
import { StockSearch } from '../components/market/StockSearch';

import { Market } from '../pages/Market';
import { StockDetails } from '../components/market/StockDetails';

// Company pages
import Careers from '../pages/company/careers';
import Press from '../pages/company/press';
import Partners from '../pages/company/partners';

// Resource pages
import Blog from '../pages/resources/blog';
import Newsletter from '../pages/resources/newsletter';
import Events from '../pages/resources/events';
import HelpCenter from '../pages/resources/help';

// Legal pages
import Privacy from '../pages/legal/privacy';
import Terms from '../pages/legal/terms';
import Security from '../pages/legal/security';
import Cookies from '../pages/legal/cookies';
import MarketChat from '../pages/MarketChat';
import Subscribe from '../pages/Subscribe';  
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';
import Introduction from '../pages/docs/introduction';
import ChatChips from '../pages/docs/chat-chips';
import ResearchProcess from '../pages/docs/research-process';
import Watchlists from '../pages/docs/watchlists';
import AccountSetup from '../pages/docs/account-setup';
import DashboardDoc from '../pages/docs/dashboard';
import Credits from '../pages/docs/credits';
import StartingChats from '../pages/docs/starting-chats';
import ResearchModes from '../pages/docs/research-modes';
import FileUploads from '../pages/docs/file-uploads';
import ChatHistory from '../pages/docs/chat-history';
import ResearchStages from '../pages/docs/research-stages';
import SourceTypes from '../pages/docs/source-types';
import ResearchProgress from '../pages/docs/research-progress';
import ExportResearch from '../pages/docs/export-research';
import CreateWatchlist from '../pages/docs/create-watchlist';
import SavedStocks from '../pages/docs/saved-stocks';
import SortFilter from '../pages/docs/sort-filter';
import StockView from '../pages/docs/stock-view';
import Sparklines from '../pages/docs/sparklines';
import CanvasIntro from '../pages/docs/canvas-intro';
import Annotations from '../pages/docs/annotations';
import Patterns from '../pages/docs/patterns';
import CanvasExport from '../pages/docs/canvas-export';
import SecurityDoc from '../pages/docs/security';
import PrivacyDoc from '../pages/docs/privacy';
import DataExport from '../pages/docs/data-export';
import AccountProtection from '../pages/docs/account-protection';
import TradeIdeas from '../pages/docs/trade-ideas';
import AdvancedAnalytics from '../pages/docs/advanced-analytics';
import CustomizePrism from '../pages/docs/customize-prism';
import ProFeatures from '../pages/docs/pro-features';
import Webhooks from '../pages/docs/webhooks';

export const AppRouter: React.FC = () => {
  return (
    
      <Routes>
        <Route path="/" element={<MainLayout><Home2 /></MainLayout>} />
        <Route path="/login" element={<MainLayout><Login /></MainLayout>} />
        <Route path="/register" element={<MainLayout><Register /></MainLayout>} />
        <Route path="/404" element={<MainLayout><NotFound /></MainLayout>} />
        <Route path="/prism" element={<MainLayout><PrismFeatures /></MainLayout>} />
        <Route path="/canvas" element={<MainLayout><CanvasFeatures /></MainLayout>} />
        <Route path="/pricing" element={<MainLayout><Pricing /></MainLayout>} />
        <Route path="/about" element={<MainLayout><About /></MainLayout>} />
        <Route path="/contact" element={<MainLayout><Contact /></MainLayout>} />
        <Route path="/subscribe" element={<MainLayout><Subscribe /></MainLayout>} />
        <Route path="/dashboard/*" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="*" element={<NotFound />} />
        <Route path="/auth/github/callback" element={<OAuthCallback />} />
        <Route path="/auth/linkedin/callback" element={<OAuthCallback />} />
        <Route path="/docs" element={<MainLayout><Documentation /></MainLayout>} />
        <Route path="/docs/api" element={<MainLayout><ApiDocumentation /></MainLayout>} />
        <Route path="/docs/releases" element={<MainLayout><ReleaseNotes /></MainLayout>} />
        <Route path="/docs/tutorials" element={<MainLayout><Tutorials /></MainLayout>} />
        <Route path="/forgot-password" element={<MainLayout><ForgotPassword /></MainLayout>} />
        <Route path="/reset-password/:token" element={<MainLayout><ResetPassword /></MainLayout>} />
        <Route path="/beta" element={<MainLayout><BetaRequest /></MainLayout>} />
        <Route path="/docs/introduction" element={<Introduction />} />
      <Route path="/docs/research-process" element={<ResearchProcess />} />
      <Route path="/docs/chat-chips" element={<ChatChips />} />
      <Route path="/docs/watchlists" element={<Watchlists />} />
      <Route path="/docs/account-setup" element={<AccountSetup />} />
      <Route path="/docs/dashboard" element={<DashboardDoc />} />
      <Route path="/docs/credits" element={<Credits />} />
      <Route path="/docs/starting-chats" element={<StartingChats />} />
      <Route path="/docs/research-modes" element={<ResearchModes />} />
      <Route path="/docs/file-uploads" element={<FileUploads />} />
      <Route path="/docs/chat-history" element={<ChatHistory />} />
      <Route path="/docs/research-stages" element={<ResearchStages />} />
      <Route path="/docs/source-types" element={<SourceTypes />} />
      <Route path="/docs/research-progress" element={<ResearchProgress />} />
      <Route path="/docs/export-research" element={<ExportResearch />} />
      <Route path="/docs/create-watchlist" element={<CreateWatchlist />} />
      <Route path="/docs/saved-stocks" element={<SavedStocks />} />
      <Route path="/docs/sort-filter" element={<SortFilter />} />
      <Route path="/docs/stock-view" element={<StockView />} />
      <Route path="/docs/sparklines" element={<Sparklines />} />
      <Route path="/docs/canvas" element={<CanvasIntro />} />
      <Route path="/docs/annotations" element={<Annotations />} />
      <Route path="/docs/patterns" element={<Patterns />} />
      <Route path="/docs/canvas-export" element={<CanvasExport />} />
      <Route path="/docs/webhooks" element={<Webhooks />} />
      <Route path="/docs/security" element={<SecurityDoc />} />
      <Route path="/docs/privacy" element={<PrivacyDoc />} />
      <Route path="/docs/data-export" element={<DataExport />} />
      <Route path="/docs/account-protection" element={<AccountProtection />} />
      <Route path="/docs/trade-ideas" element={<TradeIdeas />} />
      <Route path="/docs/advanced-analytics" element={<AdvancedAnalytics />} />
      <Route path="/docs/customize-prism" element={<CustomizePrism />} />
      <Route path="/docs/pro-features" element={<ProFeatures />} />
        {/* Company Routes */}
        <Route path="/careers" element={<MainLayout><Careers /></MainLayout>} />
        <Route path="/press" element={<MainLayout><Press /></MainLayout>} />
        <Route path="/partners" element={<MainLayout><Partners /></MainLayout>} />
        
        {/* Resource Routes */}
        <Route path="/blog" element={<MainLayout><Blog /></MainLayout>} />
        <Route path="/newsletter" element={<MainLayout><Newsletter /></MainLayout>} />
        <Route path="/events" element={<MainLayout><Events /></MainLayout>} />
        <Route path="/help" element={<MainLayout><HelpCenter /></MainLayout>} />
        <Route path="/status" element={<MainLayout><SystemStatus /></MainLayout>} />
        {/* Legal Routes */}
        <Route path="/market" element={<Market />} />
  <Route path="/market/stocks/:ticker" element={<StockDetails />} />
        <Route path="/privacy" element={<MainLayout><Privacy /></MainLayout>} />
        <Route path="/terms" element={<MainLayout><Terms /></MainLayout>} />
        <Route path="/security" element={<MainLayout><Security /></MainLayout>} />
        <Route path="/cookies" element={<MainLayout><Cookies /></MainLayout>} />
        
        <Route 
  path="/admin/beta" 
  element={
    
      <BetaManagement />
    
  } 
/>

      </Routes>
   
  );
};