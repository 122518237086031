import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../contexts/ThemeContext';
import { useChat } from '../../contexts/ChatContext';
import { ChatMessage } from '../../types/chat';
import { v4 as uuidv4 } from 'uuid';
import { ResearchProgressUpdate, ResearchSource } from '../../types/research';
import { useAuth } from '../../contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom'; // Add useNavigate
import { api } from '../../services/api';
import { usePrompt } from '../../contexts/PromptContext';
// Components
import MessageBlock from './MessageBlock';
import MarketChatHeader from './MarketChatHeader';
import ResearchProgress from './ResearchProgress';
import ChatSidebar from './ChatSidebar';
import WelcomeStatePreview from './WelcomeStatePreview';
import TradeIdeaCard from './TradeIdeaCard';
import { useChatLimit } from '../../contexts/ChatLimitContext';
import ChatLimitReached from './ChatLimitReached';
import { WatchlistSelectModal } from './WatchlistSelectModal';

// Icons
import { 
  Send, Brain, AlertCircle, Database, ArrowRight, 
  Paperclip, LineChart, Sparkles, Target, 
  ChevronDown, ChevronUp, X, Box, BrainCircuit, Flame, Zap,
  Plus,
  Menu,
  MessageSquare
} from 'lucide-react';
import WebSearchResults from './WebSearchResults';

// Research modes configuration
const researchModes = [
  {
    id: 'default',
    name: 'Standard',
    icon: Brain,
    description: 'Balanced research approach for general market inquiries',
    prefix: ''
  },
  {
    id: 'discovery',
    name: 'Discovery',
    icon: Sparkles,
    description: 'Explore new market opportunities and emerging trends',
    prefix: '[DISCOVERY MODE] '
  },
  {
    id: 'deep-dive',
    name: 'Deep Dive',
    icon: Database,
    description: 'Thorough analysis with comprehensive data examination',
    prefix: '[DEEP DIVE] '
  },
  {
    id: 'contrarian',
    name: 'Contrarian',
    icon: Target,
    description: 'Challenge market consensus and explore alternative views',
    prefix: '[CONTRARIAN ANALYSIS] '
  },
  {
    id: 'technical',
    name: 'Technical',
    icon: LineChart,
    description: 'Focus on technical indicators and chart patterns',
    prefix: '[TECHNICAL ANALYSIS] '
  }
];

// AI models configuration
const aiModels = [
  {
    id: 'claude3-opus',
    name: 'Claude 3 Opus',
    icon: BrainCircuit,
    description: 'Most capable model with enhanced reasoning',
    available: true
  },
  {
    id: 'gpt4',
    name: 'GPT-4',
    icon: Flame,
    description: 'OpenAI\'s most advanced model',
    available: false
  },
  {
    id: 'claude3-sonnet',
    name: 'Claude 3 Sonnet',
    icon: Zap,
    description: 'Balanced performance and speed',
    available: false
  },
  {
    id: 'gemini-pro',
    name: 'Gemini Pro',
    icon: Sparkles,
    description: 'Google\'s most capable model',
    available: false
  }
];

// Authenticated EventSource with token handling
// In MarketChat.tsx, update the AuthEventSource class to be more reliable
// Around line 106-145
// Around line 107-145, update the AuthEventSource class:

class AuthEventSource extends EventSource {
  constructor(url: string, options: { withCredentials: boolean, token: string }) {
    // Add token as a URL parameter since EventSource doesn't support headers
    let authenticatedUrl = url;
    const separator = url.includes('?') ? '&' : '?';
    
    // Make sure we don't add token if it's already in the URL
    if (!url.includes('token=')) {
      authenticatedUrl = `${url}${separator}token=${encodeURIComponent(options.token)}`;
    }
    
    // Use the withCredentials option for cross-origin requests
    super(authenticatedUrl, { withCredentials: options.withCredentials });
    
    // Improve error handling with reconnection logic
    let reconnectAttempts = 0;
    const MAX_RECONNECT_ATTEMPTS = 3;
    let consecutiveErrorCount = 0;
    
    this.addEventListener('error', (err) => {
      console.error('EventSource error:', err);
      consecutiveErrorCount++;
      
      // If we get more than 3 consecutive errors, we should close and try to reconnect
      if (consecutiveErrorCount >= 3) {
        this.close();
        
        if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
          console.log(`Connection lost, attempting to reconnect (${reconnectAttempts + 1}/${MAX_RECONNECT_ATTEMPTS})...`);
          reconnectAttempts++;
          // Dispatch a new reconnection event
          const reconnectEvent = new Event('reconnectneeded');
          this.dispatchEvent(reconnectEvent);
        }
      }
    });
    
    this.addEventListener('open', () => {
      // Reset error count when connection is successfully established
      consecutiveErrorCount = 0;
    });
    
    this.addEventListener('message', () => {
      // Reset error count when we receive a message
      consecutiveErrorCount = 0;
    });
  }
}

// Utility to deep clone message objects
const cloneMessage = (message: ChatMessage): ChatMessage => {
  return JSON.parse(JSON.stringify(message));
};

// Format timestamp for display
const formatTimestamp = (timestamp: string | Date): string => {
  const date = typeof timestamp === 'string' ? new Date(timestamp) : timestamp;
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

interface ErrorState {
  message: string;
  isRetryable?: boolean;
  retryFn?: () => Promise<void>;
}

const MarketChat: React.FC = () => {
  const { isDarkMode } = useTheme();
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate(); // Add this hook
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const currentSessionIdRef = useRef<string | null>(null);
  const eventSourceRef = useRef<AuthEventSource | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const userMessageRef = useRef<ChatMessage | null>(null);
  const { limitState, checkChatAvailability, recordChatUsage } = useChatLimit();
  const [isWatchlistModalOpen, setIsWatchlistModalOpen] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  // Chat context
  const { 
    state: chatState,
    createNewSession,
    deleteSession,
    deleteAllSessions,
    renameSession,
    updateCurrentSession,
    switchSession,
    loadSessions,
    starSession // Add this line
  } = useChat();

  // UI state

  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [activeTickers, setActiveTickers] = useState<string[]>([]);
  const [chatTitle, setChatTitle] = useState("New Chat");
  const [isGeneratingTitle, setIsGeneratingTitle] = useState(false);
  const [error, setError] = useState<ErrorState | null>(null);
  const [estimatedCost, setEstimatedCost] = useState<number | null>(null);
  const [tradeIdea, setTradeIdea] = useState<any | null>(null);
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const [showKeyboardShortcuts, setShowKeyboardShortcuts] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [showLimitReached, setShowLimitReached] = useState(false);

  // Input enhancements
  const {
    selectedPromptChip, setSelectedPromptChip,
    selectedStockChip, setSelectedStockChip,
    selectedFiles, setSelectedFiles,
    searchQuery, setSearchQuery: setQuery
  } = usePrompt();

  const [selectedResearchMode, setSelectedResearchMode] = useState(researchModes[0]);
  const [showResearchModes, setShowResearchModes] = useState(false);

  // Research state
  // Find the research state initialization code and ensure it's set up correctly:
// Research state
const [isResearching, setIsResearching] = useState(false);
const [researchProgress, setResearchProgress] = useState<ResearchProgressUpdate | null>(null);
const [researchSources, setResearchSources] = useState<ResearchSource[]>([]);
const [isProgressCollapsed, setIsProgressCollapsed] = useState(false);

  // AI model state
  const [selectedModel, setSelectedModel] = useState(aiModels[0]);
  const [showModelSelector, setShowModelSelector] = useState(false);

  // Theme-based styles
  const styles = {
    card: isDarkMode ? 'bg-gray-800' : 'bg-white',
    text: isDarkMode ? 'text-white' : 'text-gray-900',
    textSecondary: isDarkMode ? 'text-gray-400' : 'text-gray-600',
    border: isDarkMode ? 'border-gray-700' : 'border-gray-200',
    hover: isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
  };

  // Reset research state
  const resetResearchState = () => {
    setIsResearching(false);
    setResearchProgress(null);
    setResearchSources([]);
    setIsProgressCollapsed(false);
    
  };

  // Scroll to bottom when new messages arrive
  useEffect(() => {
    if (chatContainerRef.current) {
      const { scrollHeight, clientHeight } = chatContainerRef.current;
      const isNearBottom = chatContainerRef.current.scrollTop + clientHeight >= scrollHeight - 200;
      
      if (isNearBottom) {
        setTimeout(() => {
          if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
          }
        }, 100);
      }
    }
  }, [messages, researchProgress, researchSources]);

  // Check for mobile devices
  useEffect(() => {
    const checkMobile = () => {
      const isMobileView = window.innerWidth < 768;
      setIsMobile(isMobileView);
      
      // Auto-collapse sidebar on mobile
      if (isMobileView) {
        setIsSidebarCollapsed(true);
      }
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const checkLimit = async () => {
      const isAvailable = await checkChatAvailability();
      setShowLimitReached(!isAvailable);
    };
    
    checkLimit();
  }, [checkChatAvailability]);

  // Close research modes dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.research-mode-selector')) {
        setShowResearchModes(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Load session messages
  useEffect(() => {
    if (chatState.currentSessionId) {
      const session = chatState.sessions.find(s => s.id === chatState.currentSessionId);
      if (session) {
        setMessages(session.messages);
        setActiveTickers(session.usedTickers || []);
        setChatTitle(session.title);
      }
    }
  }, [chatState.currentSessionId, chatState.sessions]);
  

  useEffect(() => {
    // Handle initial props from location state
    if (location.state) {
      // Clear location state first to prevent re-rendering loops
      const currentState = { ...location.state };
      
      // Handle trade idea
      if (currentState.tradeIdea) {
        setTradeIdea(currentState.tradeIdea);
      }
      
      // Handle initialPrompt
      if (currentState.initialPrompt && messages.length === 0) {
        const promptText = currentState.initialPrompt;
        const displayAsChip = currentState.displayAsChip;
        const submitImmediately = currentState.submitImmediately;
        
        // Set appropriate research mode
        if (currentState.researchModeId) {
          const mode = researchModes.find(m => m.id === currentState.researchModeId);
          if (mode) {
            setSelectedResearchMode(mode);
          }
        }
        
        // Extract research mode prefix if present
        const researchModePrefix = researchModes.find(m => 
          m.prefix && promptText.startsWith(m.prefix)
        )?.prefix || '';
        
        // Setup the appropriate chip or query using the shared context
        if (displayAsChip) {
          if (promptText.includes('Tell me about') && promptText.includes('stock')) {
            const tickerMatch = promptText.match(/Tell me about ([A-Z]+) stock/i);
            if (tickerMatch && tickerMatch[1]) {
              setSelectedStockChip({
                ticker: tickerMatch[1],
                prefix: researchModePrefix,
                companyName: ''
              });
            }
          } else {
            setSelectedPromptChip({
              text: researchModePrefix ? promptText.replace(researchModePrefix, '') : promptText,
              prefix: researchModePrefix
            });
          }
          setQuery(''); // Clear the search query
        } else {
          setQuery(promptText);
        }
        
        // Clear location state and handle submission
        navigate(location.pathname, { 
          replace: true,
          state: { ...currentState, initialPrompt: null, timestamp: null }
        });
        
        if (submitImmediately) {
          const timer = setTimeout(() => {
            console.log("Auto-submitting with query:", searchQuery || promptText);
            const submitEvent = new Event('submit');
            const formElement = document.querySelector('form');
            if (formElement) {
              formElement.dispatchEvent(submitEvent);
            } else {
              // Direct call as fallback
              handleSubmit(submitEvent as any);
            }
          }, 800);
          
          return () => clearTimeout(timer);
        }
      }
    }
  }, [location.state, navigate, location.pathname, messages.length, setQuery, setSelectedPromptChip, setSelectedStockChip]);

  // Clean up EventSource on unmount
  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  // Clean up location state after processing
  useEffect(() => {
    // Clear location state after processing to prevent re-processing on refresh
    if (location.state?.initialPrompt) {
      const currentState = { ...location.state };
      delete currentState.initialPrompt;
      delete currentState.displayAsChip;
      delete currentState.submitImmediately;
      navigate(location.pathname, { replace: true, state: currentState });
    }
  }, [location.state, navigate, location.pathname]);

  // Handle file selection
  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      // Create a merged array and then set state with the array directly, not with a function
      const updatedFiles = [...selectedFiles, ...newFiles].slice(0, 5);
      setSelectedFiles(updatedFiles); // Limit to 5 files
    }
  };
  
  // Remove file
  const removeFile = (index: number) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };

  // Show file selection dialog
  const handleFileButtonClick = () => {
    fileInputRef.current?.click();
  };

  // Initialize research stream connection
    // Around line 416
        // Around line 414, update the initializeResearch function:
    
        const initializeResearch = async (query: string, sessionId: string) => {
          try {
            currentSessionIdRef.current = sessionId;
            resetResearchState();
            setIsResearching(true);
            
            const token = localStorage.getItem('token');
            if (!token) {
              throw new Error('Authentication token not found');
            }
            
            console.log(`Establishing research connection for query: ${query}, sessionId: ${sessionId}`);
            
            // Close any existing event source
            if (eventSourceRef.current) {
              eventSourceRef.current.close();
              eventSourceRef.current = null;
            }
            
            // Get the base URL from api service or fallback to relative path
            const baseUrl = api.defaults.baseURL || '';
            
            // Create a clean URL with proper encoding
            const encodedQuery = encodeURIComponent(query);
            const timestamp = Date.now();
            
            // Add a mode parameter based on the selected research mode
            const modeParam = selectedResearchMode.id !== 'default' 
              ? `&mode=${encodeURIComponent(selectedResearchMode.id)}` 
              : '';
            
            const url = `${baseUrl}/market-chat/research?query=${encodedQuery}&sessionId=${sessionId}&timestamp=${timestamp}${modeParam}`;
            
            console.log('Connecting to SSE endpoint:', url);
            
            // Add error handling for the connection
            let connectionSuccess = false;
            
            // Create the event source with token in options
            const eventSource = new AuthEventSource(
              url,
              { withCredentials: true, token }
            );
        
        // Set connection timeout - use a reference so we can clear it
        const connectionTimeout = setTimeout(() => {
          if (!connectionSuccess) {
            console.error('Connection timeout after 10 seconds');
            eventSource.close();
            setError({ 
              message: 'Connection timeout. Server may be unavailable.',
              isRetryable: true,
              retryFn: async () => {
                setError(null);
                await initializeResearch(query, sessionId);
              }
            });
            setIsResearching(false);
          }
        }, 10000);
        
        // Handle reconnection needs
        eventSource.addEventListener('reconnectneeded', () => {
          // Close current source
          eventSource.close();
          // Wait briefly before reconnecting
          setTimeout(() => {
            initializeResearch(query, sessionId)
              .catch(error => console.error('Reconnection failed:', error));
          }, 1000);
        });
        
        // Handle connected message
        eventSource.addEventListener('message', (event) => {
          try {
            const data = JSON.parse(event.data);
            if (data.type === 'connected') {
              connectionSuccess = true;
              clearTimeout(connectionTimeout);
            }
            handleResearchUpdate(data);
          } catch (err) {
            console.error('Failed to parse research update in direct handler:', err);
          }
        });
        
        eventSource.addEventListener('open', () => {
          console.log('Research connection established successfully');
          connectionSuccess = true;
          clearTimeout(connectionTimeout);
        });
        
        eventSource.onerror = (error) => {
          console.error('Research EventSource error:', error);
          
          // Only act on the error if we're still researching (avoid duplicate error handling)
          if (eventSource.readyState === EventSource.CLOSED && isResearching) {
            clearTimeout(connectionTimeout);
            setIsResearching(false);
            setError({ 
              message: 'Research connection lost. Please try again.',
              isRetryable: true,
              retryFn: async () => {
                setError(null);
                await initializeResearch(query, sessionId);
              }
            });
          }
        };
        
        // Store event source for cleanup
        eventSourceRef.current = eventSource;
        
      } catch (error) {
        console.error('Failed to initialize research:', error);
        setIsResearching(false);
        setError({ 
          message: error instanceof Error ? error.message : 'Failed to start research',
          isRetryable: true,
          retryFn: async () => {
            setError(null);
            await initializeResearch(query, sessionId);
          }
        });
      }
    };

  // Handle research SSE events
    // Replace the handleResearchUpdate function around line 425
  
    const handleResearchUpdate = async (data: any) => {
      console.log(`Processing research update type: ${data.type}, data:`, data);
      
      switch (data.type) {
      
          case 'progress':
            if (!data.progress) {
              console.error('Invalid progress data received:', data);
              return;
            }
            
            // Create a valid progress update that matches ResearchProgressUpdate interface
            const progressUpdate: ResearchProgressUpdate = {
              stage: data.progress.stage || 'QUERY_ANALYSIS',
              progress: typeof data.progress.progress === 'number' ? data.progress.progress : 0,
              message: data.progress.message || 'Processing...',
              completedStages: Array.isArray(data.progress.completedStages) ? data.progress.completedStages : [],
              details: data.progress.details || {}
            };
            
            // Ensure required fields are present in details
            progressUpdate.details = {
              currentAction: progressUpdate.details?.currentAction || progressUpdate.message,
              intent: progressUpdate.details?.intent || '',
              rationale: progressUpdate.details?.rationale || '',
              foundContent: progressUpdate.details?.foundContent || '',
              searchQuery: progressUpdate.details?.searchQuery || '',
              tickers: progressUpdate.details && Array.isArray(progressUpdate.details.tickers) 
                ? progressUpdate.details.tickers 
                : []
            };
            
            console.log('Setting research progress:', progressUpdate);
            setResearchProgress(progressUpdate);
            
            // Make sure we're showing the research is in progress
            if (!isResearching) {
              setIsResearching(true);
            }
            
            // Ensure progress is visible
            if (isProgressCollapsed) setIsProgressCollapsed(false);
            
            // Scroll to show latest updates
            if (chatContainerRef.current) {
              chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            }
            break;
      
          case 'source':
            // Add new source, avoiding duplicates with better logging
            console.log('New source:', data.source.type, data.source.source);
            setResearchSources(prev => {
              // Check if source already exists to avoid duplicates
              if (prev.some(s => 
                s.title === data.source.title && 
                s.timestamp === data.source.timestamp)) {
                return prev;
              }
              return [...prev, data.source];
            });
            
            // Make sure we're showing research is in progress
            if (!isResearching) {
              setIsResearching(true);
            }
            
            // Scroll to show latest sources
            if (chatContainerRef.current) {
              chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            }
            break;
  
      case 'complete':
        console.log('Research complete, processing results');
        // Clean up event source
        if (eventSourceRef.current) {
          console.log('Closing event source');
          eventSourceRef.current.close();
          eventSourceRef.current = null;
        }
        
        setIsResearching(false);
        setIsProgressCollapsed(true);
        
        try {
          // Get session ID with fallbacks
          const targetSessionId = data.sessionId || 
                                currentSessionIdRef.current || 
                                chatState.currentSessionId;
          
          if (!targetSessionId) {
            console.error("No session ID available for update");
            setError({
              message: "Session data was lost. Please try again.",
              isRetryable: true,
              retryFn: async () => {
                setError(null);
                const newSession = await createNewSession();
                if (newSession && newSession.id) {
                  await handleResponseGeneration(data, newSession.id);
                }
              }
            });
            return;
          }
          
          // Make a complete, reliable deep copy of ALL current messages
          const currentMessagesDeepCopy = JSON.parse(JSON.stringify(messages));
          
          // Log information for debugging
          console.log("Preserving messages before processing research results:", 
            currentMessagesDeepCopy.length > 0 ? 
              `${currentMessagesDeepCopy.length} messages, last one from ${currentMessagesDeepCopy[currentMessagesDeepCopy.length-1]?.role}` : 
              "No messages to preserve");
          
          // Process research results with our preserved messages
          await handleResponseGeneration(data, targetSessionId, currentMessagesDeepCopy);
        } catch (error) {
          console.error("Error handling research completion:", error);
          setError({
            message: "Failed to process research results. Please try again.",
            isRetryable: true,
            retryFn: async () => {
              setError(null);
              if (chatState.currentSessionId) {
                await handleSubmit(new Event('submit') as any);
              } else {
                await createNewSession();
                await handleSubmit(new Event('submit') as any);
              }
            }
          });
        }
        break;
        
      default:
        console.log('Unknown update type:', data.type);
    }
  };

  const createFakeEvent = () => {
    return {
      preventDefault: () => {},
      currentTarget: {
        reset: () => {}
      }
    } as unknown as React.FormEvent;
  };

  // Generate response from research results
    // Replace the handleResponseGeneration function around line 531
  
    // Update the function signature to accept the optional baseMessages parameter
        // Update the handleResponseGeneration function
       const handleResponseGeneration = async (
      data: any, 
      sessionId: string,
      baseMessages: ChatMessage[] = []
    ) => {
      try {
        // Ensure we have the user's message
        const userMsg = userMessageRef.current;
        if (!userMsg) {
          console.error('No user message found for response generation');
          setError({ message: 'Unable to process research results' });
          return;
        }
    
        // Use provided baseMessages or create new array with user message
        const messages = baseMessages.length > 0 ? baseMessages : [userMsg];
        
        // Create a placeholder assistant message that will be updated with streaming content
        const assistantMessage: ChatMessage = {
          id: uuidv4(),
          role: 'assistant',
          content: '',
          timestamp: new Date(),
          charts: data.result.data.marketData,
          news: data.result.data.news,
          webSearchResults: data.result.data.webDiscovery?.results,
          researchSources: data.result.sources,
          suggestedNextSteps: data.result.suggestedNextSteps,
          isStreaming: true
        };
        
        // Add the placeholder message to UI
        const updatedMessages = [...messages, assistantMessage];
        setMessages(updatedMessages);
        
        // Get token for authentication
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authentication required');
        }
        
        // Prepare query parameters 
        const timestamp = Date.now();
        
        // First send the request body in a POST to initiate the stream
        const response = await fetch(`/api/market-chat/stream-response?token=${token}&_=${timestamp}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: userMsg.content,
            research: true,
            researchResults: data.result,
            marketData: data.result.data.marketData,
            fundamentalData: data.result.data.fundamentals,
            newsData: data.result.data.news,
            webSearchResults: data.result.data.webDiscovery?.results,
            sessionId: sessionId
          }),
        });
        
        if (!response.ok) {
          console.error("Error initiating stream response:", response.status);
          setError({ message: "Error initiating response stream" });
          return;
        }
    
        // Now setup the reader for the response body stream
        const reader = response.body?.getReader();
        if (!reader) {
          throw new Error('Failed to get reader from response');
        }
        
        // Read the stream chunks
        const decoder = new TextDecoder();
        let buffer = '';
        
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          
          // Decode the chunk and add to buffer
          buffer += decoder.decode(value, { stream: true });
          
          // Process any complete events in the buffer
          const lines = buffer.split('\n\n');
          buffer = lines.pop() || ''; // Keep the last incomplete chunk in the buffer
          
          for (const line of lines) {
            if (line.startsWith('data: ')) {
              try {
                const eventData = JSON.parse(line.substring(6));
                
                // Process the event based on its type
                switch (eventData.type) {
                  case 'chunk':
                    // Update the message content with new chunk
                    setMessages(currentMessages => {
                      const newMessages = [...currentMessages];
                      const lastMessage = newMessages[newMessages.length - 1];
                      
                      if (lastMessage && lastMessage.role === 'assistant' && lastMessage.id === assistantMessage.id) {
                        lastMessage.content += eventData.content;
                      }
                      
                      return newMessages;
                    });
                    
                    // Scroll to bottom to follow new content
                    if (chatContainerRef.current) {
                      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                    }
                    break;
                    
                  case 'complete':
                    // Update message to final state and save
                    setMessages(currentMessages => {
                      const newMessages = [...currentMessages];
                      const lastMessage = newMessages[newMessages.length - 1];
                      
                      if (lastMessage && lastMessage.role === 'assistant' && lastMessage.id === assistantMessage.id) {
                        // If we got a final content in the complete message, use it
                        if (eventData.content) {
                          lastMessage.content = eventData.content;
                        }
                        lastMessage.isStreaming = false;
                      }
                      
                      return newMessages;
                    });
                    
                    // Update session with final message
                                        // Inside the case 'complete' block around line 736
                    // Update session with final message
                                        // Inside the case 'complete' block around line 736
                    // Update session with final message
                    const updatedSession = chatState.sessions.find(s => s.id === sessionId);
                    
                    // Get all current messages INCLUDING the user message that triggered this response
                    // First make sure we have the user message
                    const userMessage = userMessageRef.current;
                    
                    // Start with the base messages
                    const currentUIMessages = [...baseMessages];
                    
                    // Make sure the user message is included if it's not already in the base messages
                    if (userMessage && !currentUIMessages.some(m => m.id === userMessage.id)) {
                      currentUIMessages.push(cloneMessage(userMessage));
                    }
                    
                    // Find and ensure the assistant message is properly updated
                    const assistantIndex = currentUIMessages.findIndex(m => m.id === assistantMessage.id);
                    if (assistantIndex !== -1) {
                      // Update with final content
                      currentUIMessages[assistantIndex] = {
                        ...currentUIMessages[assistantIndex],
                        content: eventData.content || currentUIMessages[assistantIndex].content,
                        isStreaming: false
                      };
                    } else {
                      // If somehow the message isn't in the array, add it
                      currentUIMessages.push({
                        ...assistantMessage,
                        content: eventData.content || assistantMessage.content,
                        isStreaming: false
                      });
                    }
                    
                    // Log what we're about to save
                    console.log('Updating session with message count:', currentUIMessages.length, 
                      'messages:', currentUIMessages.map(m => ({role: m.role, id: m.id})));
                    
                    // Update the session with the current complete set of messages
                    await updateCurrentSession(currentUIMessages, sessionId);
                    
                    // Generate title for new sessions
                    if ((updatedSession?.messages?.length || 0) <= 1) {
                      generateTitle(currentUIMessages, sessionId);
                    }
                    break;
                    
                  case 'error':
                    console.error("Streaming error:", eventData.error);
                    setError({ message: eventData.error || 'Error generating response' });
                    break;
                }
              } catch (parseError) {
                console.error('Error parsing streaming event:', parseError, 'Raw data:', line);
                setError({ message: 'Error processing response data' });
              }
            }
          }
        }
        
      } catch (error) {
        console.error('Failed to generate response:', error);
        setError({ 
          message: 'Failed to process results',
          isRetryable: true,
          retryFn: async () => {
            setError(null);
            // Try to regenerate the response
            handleResponseGeneration(data, sessionId, baseMessages);
          }
        });
      }
    };

    const handleCreateNewSession = async () => {
      // Check if the user has available chats
      const isAvailable = await checkChatAvailability();
      
      if (!isAvailable) {
        setShowLimitReached(true);
        return;
      }
      
      resetResearchState();
      await createNewSession();
      
      // Record chat usage upon successful creation
      recordChatUsage();
    };
  // Generate title for a chat session
  const generateTitle = async (messages: ChatMessage[], sessionId: string) => {
    try {
      setIsGeneratingTitle(true);
      
      const titleResponse = await api.post('/market-chat/generate-title', {
        messages: messages.slice(0, 2), // First exchange only
        sessionId: sessionId
      });
      
      if (titleResponse.data?.response) {
        const newTitle = titleResponse.data.response;
        setChatTitle(newTitle);
        await renameSession(sessionId, newTitle);
      }
    } catch (error) {
      console.error("Title generation failed:", error);
    } finally {
      setIsGeneratingTitle(false);
    }
  };

  // Direct response (fallback without research)
  const handleFallback = async (query: string) => {
    try {
      const response = await api.post('/market-chat/response', {
        searchQuery,
        research: false
      });
  
      const fallbackMessage: ChatMessage = {
        id: uuidv4(),
        role: 'assistant',
        content: response.data.response,
        timestamp: new Date(),
        charts: response.data.charts,
        news: response.data.news
      };
  
      setMessages(prev => [...prev, fallbackMessage]);
    } catch (error) {
      setError({ message: 'Failed to process query' });
    } finally {
      setIsResearching(false);
    }
  };

  // Main form submission handler
  const handleSubmit = async (e: React.FormEvent) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    
    
    // Temporarily store the query state to ensure we're using the latest values
    const currentQuery = searchQuery.trim();
  const currentPromptChip = selectedPromptChip ? { ...selectedPromptChip } : null;
  const currentStockChip = selectedStockChip ? { ...selectedStockChip } : null;
  const currentFiles = [...selectedFiles];
  const hasInput = currentQuery || currentPromptChip || currentStockChip || currentFiles.length > 0;
                  
  if (!hasInput) {
    console.error("No valid input to submit");
    return;
  }

  console.log("Submitting chat with:", {
    query: currentQuery,
    promptChip: currentPromptChip,
    stockChip: currentStockChip,
    files: currentFiles.length > 0 ? `${currentFiles.length} files` : "None"
  });
  
  
    
    // Validate input - make sure we check each possible input source
    if (!currentQuery && !currentPromptChip && !currentStockChip && currentFiles.length === 0) {
      console.error("No valid input to submit");
      return;
    }
    
    // Clear trade idea if present
    if (tradeIdea) {
      setTradeIdea(null);
    }
  
    try {
      setIsLoading(true);
      setError(null);
      
      // Store the query and prepare API query
      let apiQuery = currentQuery;
      
      // Create user chips data
      const userChips: ChatMessage['chips'] = {};
      
      // Add prompt chip if selected - prioritize chips over raw text
      if (currentPromptChip) {
        userChips.prompt = {
          text: currentPromptChip.text,
          prefix: currentPromptChip.prefix
        };
        apiQuery = currentPromptChip.prefix + currentPromptChip.text + (currentQuery ? ` ${currentQuery}` : '');
        console.log("Using prompt chip for query:", apiQuery);
      } 
      // Add stock chip if selected
      else if (currentStockChip) {
        userChips.stock = {
          ticker: currentStockChip.ticker,
          companyName: currentStockChip.companyName || '',
          prefix: currentStockChip.prefix
        };
        apiQuery = currentStockChip.prefix + `Tell me about ${currentStockChip.ticker} stock` + (currentQuery ? `: ${currentQuery}` : '');
        console.log("Using stock chip for query:", apiQuery);
      } 
      // Apply research mode prefix to raw query
      else {
        // Check for ticker pattern in query
        const tickerMatch = currentQuery.match(/\$([A-Z]{1,5})\b/);
        if (tickerMatch && tickerMatch[1]) {
          const ticker = tickerMatch[1];
          userChips.stock = {
            ticker: ticker,
            prefix: selectedResearchMode.prefix,
            companyName: ""
          };
          console.log("Detected ticker in query:", ticker);
        }
        apiQuery = selectedResearchMode.prefix + currentQuery;
        console.log("Using research mode prefix for query:", apiQuery);
      }
  

      // Add files if selected
      if (currentFiles.length > 0) {
        userChips.files = currentFiles.map(file => ({
          name: file.name,
          size: file.size,
          type: file.type
        }));
        console.log("Added files to query:", currentFiles.length);
      }
  
      // Create user message with formatted data
      const userMessage: ChatMessage = {
        id: uuidv4(),
        role: 'user',
        content: apiQuery,
        timestamp: new Date(),
        chips: Object.keys(userChips).length > 0 ? userChips : undefined
      };
  
      console.log("Created user message:", {
        id: userMessage.id,
        content: userMessage.content.substring(0, 50) + (userMessage.content.length > 50 ? '...' : ''),
        hasChips: !!userMessage.chips,
        chipTypes: userMessage.chips ? Object.keys(userMessage.chips) : []
      });
  
      // Clear input state AFTER we've captured everything
      setQuery('');
      setSelectedPromptChip(null);
      setSelectedStockChip(null);
      setSelectedFiles([]);
  

      // Add user message to UI immediately
      setMessages(prevMessages => [...prevMessages, cloneMessage(userMessage)]);
      console.log("Added user message to UI");

// Store this user message in a ref to ensure it's available for the research process
      userMessageRef.current = cloneMessage(userMessage);
      userMessageRef.current = cloneMessage(userMessage);


      // Session handling
      let sessionId = chatState.currentSessionId;
      let currentSession;
      
      // Create a new session if needed
      if (!sessionId) {
        try {
          const isAvailable = await checkChatAvailability();
          if (!isAvailable) {
            setShowLimitReached(true);
            setIsLoading(false);
            return;
          }
          console.log("Creating new session");
          const newSession = await createNewSession();
          sessionId = newSession.id;
          currentSession = newSession;
          setChatTitle(newSession.title || 'New Chat');
          console.log("Created new session:", sessionId);
          recordChatUsage();
        } catch (sessionError) {
          console.error("Failed to create session:", sessionError);
          setError({ message: "Failed to create a chat session. Please try again." });
          setIsLoading(false);
          return;
        }
      } else {
        currentSession = chatState.sessions.find(s => s.id === sessionId);
        console.log("Using existing session:", sessionId);
      }

      // Save initial user message to session
      try {
        console.log("Saving user message to session");
        const existingMessages = currentSession?.messages || [];
        await updateCurrentSession([...existingMessages, cloneMessage(userMessage)], sessionId);
      } catch (updateError) {
        console.error("Failed to update session with user message:", updateError);
      }
  
      // Set title generation flag for first message
      if (currentSession?.messages?.length === 0) {
        setIsGeneratingTitle(true);
      }

      // Query analysis to determine research needs
      const analysisResponse = await api.post('/market-chat/analyze', { query: apiQuery });

      // Choose appropriate processing approach
      if (analysisResponse.data.researchNeeded) {
        // Research workflow
        await initializeResearch(apiQuery, sessionId);
        
        // Store the messages including user message for later use
        const currentMessages = [...messages, userMessageRef.current].filter(Boolean);
        currentSessionIdRef.current = sessionId;
        
        // Set this in state for recovery if needed
        setStoredMessages(currentMessages);
      }  else {
        // Direct response workflow
        try {
          const response = await api.post('/market-chat/response', { 
            query: apiQuery,
            research: false,
            sessionId
          });
          
          // Create assistant message
          const assistantMessage: ChatMessage = {
            id: uuidv4(),
            role: 'assistant',
            content: response.data.response,
            timestamp: new Date(),
            charts: response.data.charts,
            news: response.data.news
          };

          // Update messages and session
          const finalMessages = [...(currentSession?.messages || []), userMessage, assistantMessage];
          setMessages(finalMessages);
          await updateCurrentSession(finalMessages, sessionId);
          
          // Generate title for new sessions
          if ((currentSession?.messages.length || 0) <= 1) {
            await generateTitle(finalMessages, sessionId);
          }
        } catch (error) {
          console.error('Response generation failed:', error);
          setError({
            message: 'Failed to generate response. Please try again.',
            isRetryable: true, 
            retryFn: async () => {
              setError(null);
              // Create new session and retry
              await createNewSession();
              await handleSubmit(new Event('submit') as any);
            }
          });
        }
      }
    } catch (error) {
      console.error("Chat submission error:", error);
      setError({ message: 'Failed to process your request. Please try again.' });
    } finally {
      setIsLoading(false);
    }
  };

// State for emergency message recovery
const [storedMessages, setStoredMessages] = useState<ChatMessage[]>([]);

  return (
    <div className="flex font-sans h-screen overflow-hidden">
      {/* Chat Sidebar */}
      <ChatSidebar
        sessions={chatState.sessions}
        currentSessionId={chatState.currentSessionId}
        onSessionSelect={(id) => {
          resetResearchState();
          switchSession(id);
        }}
        onNewChat={handleCreateNewSession} 
        onDeleteSession={deleteSession}
        onDeleteAllSessions={deleteAllSessions}
        onRenameSession={renameSession}
        onLoadSessions={loadSessions}
        onStarSession={starSession}
        isCollapsed={isSidebarCollapsed}
        onToggleCollapse={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
        isLoading={chatState.isLoading}
        error={chatState.error}
        isMobile={isMobile}
        isMobileSidebarOpen={isMobileSidebarOpen}
        onCloseMobileSidebar={() => setIsMobileSidebarOpen(false)}
      />
      
      {/* Main Chat Area */}
      <div className="flex-1 flex flex-col h-full overflow-hidden">
        {/* Header with mobile menu toggle */}
        <div className="relative">
          {isMobile && (
            <div className="absolute left-0 top-0 bottom-0 flex items-center px-3 z-10">
              <button
                onClick={() => setIsMobileSidebarOpen(true)}
                className="p-2 rounded-lg bg-gray-800/70"
              >
                <Menu className="w-5 h-5 text-gray-300" />
              </button>
            </div>
          )}
          <MarketChatHeader 
            onOpenSettings={() => {}}
            sessionTitle={chatTitle}
            isGeneratingTitle={isGeneratingTitle}
            onRenameSession={(newTitle: string) => {
              setChatTitle(newTitle);
              if (chatState.currentSessionId) {
                renameSession(chatState.currentSessionId, newTitle);
              }
            }}
            isPro={false}
            messages={messages}
            sources={researchSources}
            isMobile={isMobile}
            onCreateNewChat={handleCreateNewSession}
          />
        </div>

        {/* Messages Area - Make it scrollable but leave room for fixed input */}
        <div 
          ref={chatContainerRef}
          className={`flex-1 overflow-y-auto space-y-8 px-4 py-4 ${isMobile ? 'pb-28' : ''}`}
        >
          {/* Limit Reached Component */}
          {showLimitReached ? (
            <ChatLimitReached />
          ) : (
            <>
              {/* Trade Idea Card */}
              {tradeIdea && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                  className="mb-6"
                >
                  <TradeIdeaCard 
                    idea={tradeIdea} 
                    onAnalyze={() => {
                      handleSubmit(new Event('submit') as any);
                      setTradeIdea(null);
                    }}
                  />
                </motion.div>
              )}

              {/* Welcome State */}
              {messages.length === 0 && !isResearching && !tradeIdea && (
                <div className="-mx-4 -my-4">
                  <WelcomeStatePreview
                    isDarkMode={isDarkMode}
                    onPromptSelect={(prompt, researchModePrefix, displayAsChip) => {
                      console.log("Prompt selected:", prompt, "Display as chip:", displayAsChip);
                      
                      if (displayAsChip) {
                        if (prompt.includes('Tell me about') && prompt.includes('stock')) {
                          const tickerMatch = prompt.match(/Tell me about ([A-Z]+) stock/);
                          if (tickerMatch && tickerMatch[1]) {
                            setSelectedStockChip({
                              ticker: tickerMatch[1],
                              companyName: '',
                              prefix: researchModePrefix || ""
                            });
                          }
                        } else {
                          setSelectedPromptChip({
                            text: prompt.replace(researchModePrefix || "", ""),
                            prefix: researchModePrefix || ""
                          });
                        }
                        setQuery('');
                      } else {
                        setQuery(prompt);
                      }
                      
                      // Use a greater delay on mobile to ensure state updates complete
                      const submissionDelay = isMobile ? 300 : 100;
                      
                      // Create event outside the setTimeout to avoid closure issues
                      const fakeEvent = createFakeEvent();
                      
                      setTimeout(() => {
                        console.log("Submitting prompt:", prompt, "Is mobile:", isMobile);
                        handleSubmit(fakeEvent);
                      }, submissionDelay);
                    }}
                    selectedResearchMode={selectedResearchMode.id}
                    onResearchModeChange={(modeId) => {
                      const mode = researchModes.find(m => m.id === modeId);
                      if (mode) setSelectedResearchMode(mode);
                    }}
                  />
                </div>
              )}

              {/* Message Blocks */}
              {messages.map((message) => (
                <MessageBlock
                  key={message.id}
                  message={message}
                  styles={styles}
                  tickers={activeTickers}
                  formatTimestamp={formatTimestamp}
                />
              ))}

              {/* Research Progress */}
              {isResearching && (
                <motion.div
                  layout
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ 
                    opacity: 1, 
                    y: 0,
                    scale: isProgressCollapsed ? 0.95 : 1 
                  }}
                  transition={{ duration: 0.3 }}
                  className="relative px-5 py-3 mb-8"
                >
                  <ResearchProgress
                    progress={researchProgress || {
                      stage: 'QUERY_ANALYSIS',
                      progress: 0,
                      message: 'Initializing research...',
                      completedStages: [],
                      details: {
                        currentAction: 'Starting analysis',
                      }
                    }}
                    sources={researchSources}
                    isComplete={!isResearching}
                    isCollapsed={isProgressCollapsed}
                    onToggleCollapse={() => setIsProgressCollapsed(!isProgressCollapsed)}
                  />
                </motion.div>
              )}

              {/* Error Message */}
              {error && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className={`flex items-center justify-between p-4 rounded-lg ${
                    isDarkMode ? 'bg-red-500/10' : 'bg-red-50'
                  }`}
                >
                  <div className="flex items-center space-x-2 text-red-500">
                    <AlertCircle className="w-5 h-5" />
                    <span>{error.message}</span>
                  </div>
                  {error.isRetryable && error.retryFn && (
                    <button
                      onClick={() => error.retryFn?.()}
                      className="flex items-center space-x-2 px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition-colors"
                    >
                      <ArrowRight className="w-4 h-4" />
                      <span>Retry Analysis</span>
                    </button>
                  )}
                </motion.div>
              )}
            </>
          )}
        </div>

        {/* Input Area - Mobile-optimized and fixed at bottom */}
        {!showLimitReached && (messages.length > 0 || isResearching || tradeIdea) && (
          <div className={`${isMobile ? 'fixed bottom-0 left-0 right-0 z-30' : ''} p-4 border-t ${styles.border} ${
            isDarkMode ? 'bg-gray-900' : 'bg-white'
          } ${isMobile ? 'shadow-lg' : ''}`}>
            <form onSubmit={handleSubmit} className="relative flex flex-col md:flex-row gap-3 max-w-7xl mx-auto">
              {/* Mobile layout for research mode & stock selector */}
              {isMobile && (
                <div className="flex gap-2 w-full overflow-x-auto pb-2">
                  {/* Research Mode Selector - Mobile */}
                  <div className="relative research-mode-selector flex-shrink-0">
                    <button
                      type="button"
                      onClick={() => setShowResearchModes(!showResearchModes)}
                      className={`flex items-center space-x-2 px-3 py-3 rounded-lg transition-all duration-200 ${
                        isDarkMode
                          ? 'bg-gray-800/80 hover:bg-gray-750 text-gray-300 border border-gray-700/80' 
                          : 'bg-gray-50 hover:bg-gray-100 text-gray-700 border border-gray-200/80'
                      } whitespace-nowrap backdrop-blur-sm shadow-sm`}
                    >
                      <div className={`p-1 rounded-md ${
                        isDarkMode ? 'bg-blue-500/10' : 'bg-blue-500/10'
                      }`}>
                        <selectedResearchMode.icon className="w-4 h-4 text-blue-500" />
                      </div>
                      <span className="font-medium text-sm">
                        {selectedResearchMode.name}
                      </span>
                      <ChevronDown className="w-3.5 h-3.5 text-gray-400" />
                    </button>
                    
                    {/* Research Modes Dropdown - Mobile */}
                    <AnimatePresence>
                      {showResearchModes && (
                        <motion.div
                          initial={{ opacity: 0, y: 10, scale: 0.98 }}
                          animate={{ opacity: 1, y: 0, scale: 1 }}
                          exit={{ opacity: 0, y: 10, scale: 0.98 }}
                          transition={{ duration: 0.15, ease: "easeOut" }}
                          className={`absolute bottom-full mb-2 left-0 z-50 w-80 rounded-xl shadow-lg border overflow-hidden ${
                            isDarkMode 
                              ? 'bg-gray-800 border-gray-700/80' 
                              : 'bg-white border-gray-200/80'
                          } backdrop-blur-sm`}
                        >
                          <div className="p-2 space-y-1">
                            {researchModes.map(mode => (
                              <button
                                key={mode.id}
                                onClick={() => {
                                  setSelectedResearchMode(mode);
                                  setShowResearchModes(false);
                                }}
                                className={`w-full flex items-center px-4 py-2.5 rounded-lg transition-colors ${
                                  selectedResearchMode.id === mode.id
                                    ? isDarkMode ? 'bg-blue-900/50' : 'bg-blue-50'
                                    : isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
                                }`}
                              >
                                <div className={`p-1.5 rounded-md mr-3 ${
                                  isDarkMode ? 'bg-blue-900/50' : 'bg-blue-100/80'
                                }`}>
                                  <mode.icon className={`w-4 h-4 ${
                                    selectedResearchMode.id === mode.id
                                      ? 'text-blue-400' 
                                      : isDarkMode ? 'text-gray-400' : 'text-gray-500'
                                  }`} />
                                </div>
                                <div className="flex-1 text-left">
                                  <div className="font-medium text-sm">{mode.name}</div>
                                  <div className={`text-xs mt-0.5 ${
                                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                                  }`}>{mode.description}</div>
                                </div>
                              </button>
                            ))}
                          </div>
                          <div className={`px-4 py-3 text-xs ${isDarkMode ? 'bg-gray-750 text-gray-400' : 'bg-gray-50 text-gray-500'} border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
                            Research modes influence how Claude approaches your query
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>

                  {/* Add Watchlist Stock Button - Mobile */}
                  <div className="relative flex-shrink-0">
                    <button
                      type="button"
                      onClick={() => setIsWatchlistModalOpen(true)}
                      className={`flex items-center space-x-2 px-3 py-3 rounded-lg transition-all duration-200 ${
                        isDarkMode
                          ? 'bg-gray-800/80 hover:bg-gray-750 text-gray-300 border border-gray-700/80' 
                          : 'bg-gray-50 hover:bg-gray-100 text-gray-700 border border-gray-200/80'
                      } whitespace-nowrap backdrop-blur-sm shadow-sm`}
                    >
                      <div className={`p-1 rounded-md ${
                        isDarkMode ? 'bg-green-500/10' : 'bg-green-500/10'
                      }`}>
                        <Plus className="w-4 h-4 text-green-500" />
                      </div>
                      <span className="font-medium text-sm">Add Stock</span>
                    </button>
                  </div>
                </div>
              )}
              
              {/* Desktop layout */}
              {!isMobile && (
                <>
                  {/* Research Mode Selector - Desktop */}
                  <div className="relative research-mode-selector">
                    <button
                      type="button"
                      onClick={() => setShowResearchModes(!showResearchModes)}
                      className={`flex items-center space-x-2 px-3 py-3 rounded-lg transition-all duration-200 ${
                        isDarkMode
                          ? 'bg-gray-800/80 hover:bg-gray-750 text-gray-300 border border-gray-700/80' 
                          : 'bg-gray-50 hover:bg-gray-100 text-gray-700 border border-gray-200/80'
                      } whitespace-nowrap backdrop-blur-sm shadow-sm`}
                    >
                      <div className={`p-1 rounded-md ${
                        isDarkMode ? 'bg-blue-500/10' : 'bg-blue-500/10'
                      }`}>
                        <selectedResearchMode.icon className="w-4 h-4 text-blue-500" />
                      </div>
                      <span className="font-medium text-sm">
                        {selectedResearchMode.name}
                      </span>
                      <div className={`w-5 h-5 flex items-center justify-center rounded-full ml-1 ${
                        showResearchModes ? 
                          (isDarkMode ? 'bg-gray-700' : 'bg-gray-200') : 
                          'text-gray-400'
                      }`}>
                        {showResearchModes ? (
                          <ChevronUp className="w-3.5 h-3.5 text-gray-400" />
                        ) : (
                          <ChevronDown className="w-3.5 h-3.5 text-gray-400" />
                        )}
                      </div>
                    </button>
                    
                    {/* Research Modes Dropdown - Desktop */}
                    <AnimatePresence>
                      {showResearchModes && (
                        <motion.div
                          initial={{ opacity: 0, y: 10, scale: 0.98 }}
                          animate={{ opacity: 1, y: 0, scale: 1 }}
                          exit={{ opacity: 0, y: 10, scale: 0.98 }}
                          transition={{ duration: 0.15, ease: "easeOut" }}
                          className={`absolute bottom-full mb-2 left-0 z-50 w-80 rounded-xl shadow-lg border overflow-hidden ${
                            isDarkMode 
                              ? 'bg-gray-800 border-gray-700/80' 
                              : 'bg-white border-gray-200/80'
                          } backdrop-blur-sm`}
                        >
                          <div className="p-2 space-y-1">
                            {researchModes.map(mode => (
                              <button
                                key={mode.id}
                                onClick={() => {
                                  setSelectedResearchMode(mode);
                                  setShowResearchModes(false);
                                }}
                                className={`w-full flex items-center px-4 py-2.5 rounded-lg transition-colors ${
                                  selectedResearchMode.id === mode.id
                                    ? isDarkMode ? 'bg-blue-900/50' : 'bg-blue-50'
                                    : isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
                                }`}
                              >
                                <div className={`p-1.5 rounded-md mr-3 ${
                                  isDarkMode ? 'bg-blue-900/50' : 'bg-blue-100/80'
                                }`}>
                                  <mode.icon className={`w-4 h-4 ${
                                    selectedResearchMode.id === mode.id
                                      ? 'text-blue-400' 
                                      : isDarkMode ? 'text-gray-400' : 'text-gray-500'
                                  }`} />
                                </div>
                                <div className="flex-1 text-left">
                                  <div className="font-medium text-sm">{mode.name}</div>
                                  <div className={`text-xs mt-0.5 ${
                                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                                  }`}>{mode.description}</div>
                                </div>
                              </button>
                            ))}
                          </div>
                          <div className={`px-4 py-3 text-xs ${isDarkMode ? 'bg-gray-750 text-gray-400' : 'bg-gray-50 text-gray-500'} border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
                            Research modes influence how Claude approaches your query
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>

                  {/* Add Watchlist Stock Button - Desktop */}
                  <div className="relative">
                    <button
                      type="button"
                      onClick={() => setIsWatchlistModalOpen(true)}
                      className={`flex items-center space-x-2 px-3 py-3 rounded-lg transition-all duration-200 ${
                        isDarkMode
                          ? 'bg-gray-800/80 hover:bg-gray-750 text-gray-300 border border-gray-700/80' 
                          : 'bg-gray-50 hover:bg-gray-100 text-gray-700 border border-gray-200/80'
                      } whitespace-nowrap backdrop-blur-sm shadow-sm`}
                    >
                      <div className={`p-1 rounded-md ${
                        isDarkMode ? 'bg-green-500/10' : 'bg-green-500/10'
                      }`}>
                        <Plus className="w-4 h-4 text-green-500" />
                      </div>
                    </button>
                  </div>
                </>
              )}

              {/* Input field and buttons - Always present */}
              <div className="flex w-full items-center space-x-2">
                <div className="relative flex-1">
                  {/* Chip badges for selected items */}
                  <div className="absolute -top-2 left-2 flex flex-wrap gap-1 max-w-full">
                    {selectedPromptChip && (
                      <div className={`inline-flex items-center gap-1 py-1 px-2 rounded-md text-sm ${
                        isDarkMode ? 'bg-blue-900/40 text-blue-300 border border-blue-700/50' : 
                                    'bg-blue-100 text-blue-800 border border-blue-200'
                      }`}>
                        <Box size={14} className={`mr-1 ${isDarkMode ? 'text-blue-300' : 'text-blue-600'}`} />
                        <span className="truncate max-w-[150px]">{selectedPromptChip.text}</span>
                        <button
                          type="button"
                          onClick={() => setSelectedPromptChip(null)}
                          className="ml-1 p-0.5 rounded-full hover:bg-blue-800/30"
                        >
                          <X size={12} />
                        </button>
                      </div>
                    )}
                    
                    {selectedStockChip && (
                      <div className={`inline-flex items-center gap-1 py-1 px-2 rounded-md text-sm ${
                        isDarkMode ? 'bg-green-900/40 text-green-300 border border-green-700/50' : 
                                    'bg-green-100 text-green-800 border border-green-200'
                      }`}>
                        <Box size={14} className={`mr-1 ${isDarkMode ? 'text-green-300' : 'text-green-600'}`} />
                        <span className="truncate max-w-[150px]">{selectedStockChip.ticker}</span>
                        <button
                          type="button"
                          onClick={() => setSelectedStockChip(null)}
                          className="ml-1 p-0.5 rounded-full hover:bg-green-800/30"
                        >
                          <X size={12} />
                        </button>
                      </div>
                    )}
                    
                    {selectedFiles.length > 0 && (
                      <div className={`inline-flex items-center gap-1 py-1 px-2 rounded-md text-sm ${
                        isDarkMode ? 'bg-purple-900/40 text-purple-300 border border-purple-700/50' : 
                                    'bg-purple-100 text-purple-800 border border-purple-200'
                      }`}>
                        <Paperclip size={14} className={`mr-1 ${isDarkMode ? 'text-purple-300' : 'text-purple-600'}`} />
                        <span className="truncate max-w-[150px]">{selectedFiles.length} file(s)</span>
                        <button
                          type="button"
                          onClick={() => setSelectedFiles([])}
                          className="ml-1 p-0.5 rounded-full hover:bg-purple-800/30"
                        >
                          <X size={12} />
                        </button>
                      </div>
                    )}
                  </div>
                  
                  {/* Text input */}
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Ask about any stock, sector, or market trend..."
                    className={`w-full px-4 py-3.5 pr-24 rounded-lg transition-all duration-200 ${
                      isDarkMode 
                        ? 'bg-gray-800/80 border-gray-700/80 text-white placeholder-gray-400 shadow-inner shadow-gray-900/10' 
                        : 'bg-white border-gray-200 text-gray-900 placeholder-gray-500 shadow-sm'
                    } ${
                      (selectedPromptChip || selectedStockChip || selectedFiles.length > 0) ?
                        'border-blue-500/50 focus:border-blue-500' :
                        `border ${styles.border} focus:border-blue-500`
                    } focus:ring-0 focus:ring-blue-500/30`}
                    disabled={isLoading || isResearching}
                  />
                </div>
                
                {/* File input (hidden) */}
                <input
                  ref={fileInputRef}
                  type="file"
                  multiple
                  className="hidden"
                  onChange={handleFileSelect}
                  accept=".pdf,.doc,.docx,.txt,.csv,.xls,.xlsx,.json,.md"
                />
                
                {/* Action buttons */}
                <div className="flex space-x-2">
                  <button
                    type="button"
                    onClick={handleFileButtonClick}
                    disabled={isLoading || isResearching || selectedFiles.length >= 5}
                    className={`p-2.5 rounded-lg transition-all duration-200 ${
                      isLoading || isResearching || selectedFiles.length >= 5
                        ? isDarkMode ? 'text-gray-600' : 'text-gray-400'
                        : isDarkMode ? 'hover:bg-gray-700 text-gray-400' : 'hover:bg-gray-100 text-gray-500'
                    }`}
                  >
                    <Paperclip className="w-4 h-4" />
                  </button>

                  {/* Loading indicator or submit button */}
                  {isLoading ? (
                    <div className="p-2">
                      <div className="w-5 h-5 border-2 border-blue-400 border-t-transparent rounded-full animate-spin" />
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className={`p-2.5 rounded-lg transition-all duration-200 ${
                        searchQuery.trim() || selectedPromptChip || selectedStockChip || selectedFiles.length > 0
                          ? 'bg-blue-500 hover:bg-blue-600 text-white shadow-md shadow-blue-500/20' 
                          : isDarkMode 
                            ? 'bg-gray-700 text-gray-400' 
                            : 'bg-gray-200 text-gray-500'
                      }`}
                      disabled={!searchQuery.trim() && !selectedPromptChip && !selectedStockChip && selectedFiles.length === 0}
                    >
                      <Send className="w-4 h-4" />
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        )}
        
        {/* Mobile floating action button - only shown if no messages */}
        {isMobile && messages.length === 0 && !isResearching && !tradeIdea && !showLimitReached && (
          <div className="fixed bottom-4 right-4 z-20">
            <button
              onClick={handleCreateNewSession}
              className="p-4 rounded-full bg-blue-500 text-white shadow-lg flex items-center justify-center hover:bg-blue-600 transition-colors"
              aria-label="New Chat"
            >
              <MessageSquare className="w-6 h-6" />
            </button>
          </div>
        )}
      </div>

      {/* Modals */}
      <AnimatePresence>
        {isWatchlistModalOpen && (
          <WatchlistSelectModal
            isOpen={isWatchlistModalOpen}
            onClose={() => setIsWatchlistModalOpen(false)}
            onSelectStock={(ticker, companyName, prefix) => {
              setSelectedStockChip({
                ticker,
                companyName,
                prefix: selectedResearchMode.prefix
              });
              setQuery('');
              setIsWatchlistModalOpen(false);
            }}
            selectedResearchModePrefix={selectedResearchMode.prefix}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default MarketChat;