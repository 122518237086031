import React, { createContext, useContext, useState, ReactNode } from 'react';

interface PromptChip {
  text: string;
  prefix: string;
}

interface StockChip {
  ticker: string;
  companyName?: string;
  prefix: string;
}

interface PromptContextType {
  selectedPromptChip: PromptChip | null;
  setSelectedPromptChip: (chip: PromptChip | null) => void;
  selectedStockChip: StockChip | null;
  setSelectedStockChip: (chip: StockChip | null) => void;
  selectedFiles: File[];
  setSelectedFiles: (files: File[]) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}

const PromptContext = createContext<PromptContextType | undefined>(undefined);

export const PromptProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const [selectedPromptChip, setSelectedPromptChip] = useState<PromptChip | null>(null);
  const [selectedStockChip, setSelectedStockChip] = useState<StockChip | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  return (
    <PromptContext.Provider value={{
      selectedPromptChip,
      setSelectedPromptChip,
      selectedStockChip,
      setSelectedStockChip,
      selectedFiles,
      setSelectedFiles,
      searchQuery,
      setSearchQuery
    }}>
      {children}
    </PromptContext.Provider>
  );
};

export const usePrompt = (): PromptContextType => {
  const context = useContext(PromptContext);
  if (context === undefined) {
    throw new Error('usePrompt must be used within a PromptProvider');
  }
  return context;
};