// src/components/layout/DashboardLayout.tsx
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeContext';
import { User, Settings as SettingsIcon, CompassIcon, ChevronLeft, Bookmark, Box, File, Folder, Lightbulb, SearchIcon } from 'lucide-react';
import { CreditDisplay } from '../../components/common/CreditDisplay';
import { Info } from 'lucide-react';
import { useCanvas } from '../../contexts/CanvasContext';
import { useRef } from 'react';
import { X } from 'lucide-react';
import { AnimatePresence, motion } from 'framer-motion';
import {
  MessagesSquare,
  LayoutDashboard,
  LineChart,
  Library,
  Menu,
  Settings,
  Star,
  Plus,
  Sparkles,
  LogOut,
  Moon,
  Sun,
  Search,
  Bell,
  Brain,
  Monitor,
  Microscope,
  ChevronRight,
  Zap,
  ArrowUpRight,
  Coins,
  CandlestickChartIcon,
  Home,
  MessageCircle,
} from 'lucide-react';
import { useWalkthrough } from '../../contexts/WalkthroughContext';
import AppWalkthrough from '../../components/walkthrough/AppWalkthrough';
import HelpMenu from '../../components/ui/HelpMenu';

interface NavItem {
  icon: React.FC<{ className?: string; strokeWidth?: string | number }>;
  label: string;
  path: string;
  badge?: number;
  isNew?: boolean;
  isBeta?: boolean;
  isHighlighted?: boolean;
}
interface QuickCreateOption {
  title: string;
  description: string;
  path: string;
  icon: React.FC<{ className?: string }>;
}

const APP_VERSION = '0.9.2-beta';

export const DashboardLayout: React.FC<{ 
  children: React.ReactNode,
  onSidebarToggle?: (isCollapsed: boolean) => void
}> = ({ children, onSidebarToggle }) => {
  const navigate = useNavigate();
  const { isCanvasEditorOpen } = useCanvas();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const location = useLocation();
  const { isDarkMode, toggleDarkMode } = useTheme();
  const { logout, user } = useAuth();
  
  const [notifications] = useState<number>(3);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [showQuickCreate, setShowQuickCreate] = useState(false);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { 
    isWalkthroughActive, 
    completeWalkthrough, 
    skipWalkthrough,
    walkthroughSteps
  } = useWalkthrough();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Add this effect to handle outside clicks
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowQuickCreate(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Add this useEffect hook to monitor the route and update the sidebar state
  useEffect(() => {
    if (location.pathname === '/dashboard/market-chat') {
      setIsSidebarCollapsed(true);
    }
  }, [location.pathname]);

  const quickCreateOptions: QuickCreateOption[] = [
    {
      title: 'New SuperChart',
      description: 'Advanced technical analysis',
      path: '/dashboard/superchart',
      icon: LineChart
    },
    {
      title: 'Start Research',
      description: 'AI-powered stock research',
      path: '/dashboard/research',
      icon: Brain
    },
    {
      title: 'Profile',
      description: 'Edit your account settings',
      path: '/dashboard/settings',
      icon: User
    }
  ];

  const primaryNavItems: NavItem[] = [
    { icon: LayoutDashboard, label: 'Overview', path: '/dashboard' },
    {
      icon: Box,
      label: 'Prism',
      path: '/dashboard/market-chat',
      isNew: true
    },
    {
      icon: SearchIcon, // Add the search nav item
      label: 'Search',
      path: '/dashboard/search',
    },
    
    {
      icon: Folder,
      label: 'Saved',
      path: '/dashboard/watchlists',
    },
    { 
      icon: Monitor, 
      label: 'Pro', 
      path: '/dashboard/credits',
      isHighlighted: true,
    },
  ];

  const secondaryNavItems: NavItem[] = [
    { icon: Settings, label: 'Settings', path: '/dashboard/settings' },
  ];

  const mobileNavItems = [
    { icon: LayoutDashboard, label: 'Home', path: '/dashboard' },
    { icon: Search, label: 'Search', path: '/dashboard/search' },
    { icon: Box, label: 'Prism', path: '/dashboard/market-chat', isPrimary: true },
    { icon: Folder, label: 'Saved', path: '/dashboard/watchlists' },
    { icon: User, label: 'Profile', path: '/dashboard/settings' },
  ];

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const toggleSidebar = () => {
    const newState = !isSidebarCollapsed;
    setIsSidebarCollapsed(newState);
    if (onSidebarToggle) {
      onSidebarToggle(newState);
    }
    
    // Add the data attribute for detection by other components
    const layoutElement = document.querySelector('.dashboard-layout');
    if (layoutElement) {
      layoutElement.setAttribute('data-sidebar-state', newState ? 'collapsed' : 'expanded');
    }
  };

  return (
    <div className={`flex font-heading tracking-tighter h-screen ${isDarkMode ? 'bg-black' : 'bg-gray-50'}`}>
      {/* Sidebar - only show on desktop */}
      {!isMobile && (
        <div className={`${
          isCanvasEditorOpen ? 'w-20' : isSidebarCollapsed ? 'w-20' : 'w-64'
        } z-[9999] flex-shrink-0 ${
          isDarkMode ? 'bg-black/40 backdrop-blur-xl' : 'bg-white/80 backdrop-blur-xl'
        } border-r ${isDarkMode ? 'border-zinc-800' : 'border-gray-200'} 
        transition-all duration-300 relative`}>
          <div className="flex flex-col h-full">
            {/* Logo Section */}
            <div className={`p-6 flex items-center justify-between ${isSidebarCollapsed ? 'justify-center' : ''}`}>
              {!isSidebarCollapsed ? (
                <>
                  <img
                    src={isDarkMode ? '/logo-dark.png' : '/logo-light.png'}
                    alt="Newsroom"
                    className="h-8"
                  />
                  
                  <HelpMenu />
                </>
              ) : (
                <img
                  src={isDarkMode ? '/logo-darkmini.png' : '/logo-lightmini.png'}
                  alt="N"
                  className="h-8"
                />
              )}
            </div>

            {/* Quick Create Menu */}
            <AnimatePresence>
              {showQuickCreate && !isSidebarCollapsed && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className="overflow-hidden"
                >
                  <div className={`mx-4 mb-4 rounded-xl border ${
                    isDarkMode ? 'border-gray-800' : 'border-gray-200'
                  }`}>
                    <HelpMenu />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

      
            <button
              onClick={toggleSidebar}
              className={`
                absolute -right-3 top-1/2 transform -translate-y-1/2
                w-6 h-12 rounded-full flex items-center justify-center
                ${isDarkMode ? 'bg-black' : 'bg-white'}
                border ${isDarkMode ? 'border-zinc-800' : 'border-gray-200'} 
                hover:bg-opacity-80 transition-all z-10
                ${isDarkMode ? 'hover:border-zinc-700 hover:bg-zinc-900' : 'hover:bg-gray-50'}
              `}
            >
              <ChevronLeft className={`
                w-4 h-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}
                transition-transform duration-300
                ${isSidebarCollapsed ? 'rotate-180' : ''}
              `} />
            </button>

            {/* Navigation */}
            <motion.div 
              className="flex-1 px-4"
              animate={{ 
                marginTop: showQuickCreate ? 0 : 0 
              }}
              transition={{ duration: 0.2 }}
            >
              <nav className="space-y-1">
                {primaryNavItems.map((item) => (
                  <Link
                    key={item.path}
                    to={item.path}
                    className={`group flex items-center ${isSidebarCollapsed ? 'justify-center' : 'px-4'} py-3 rounded-xl transition-all ${
                      location.pathname === item.path
                      ? `${isDarkMode 
                        ? 'bg-zinc-900/50 text-white border border-zinc-800' 
                        : 'bg-blue-50 text-blue-600'}`
                      : item.isHighlighted
                        ? `${isDarkMode 
                            ? 'bg-blue-900/20 text-blue-400 border border-blue-900/50' 
                            : 'bg-blue-50/50 text-blue-600 border border-blue-100'}`
                        : `${isDarkMode 
                            ? 'text-gray-400 hover:text-white hover:bg-zinc-900/30' 
                            : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'}`
                    }`}
                  >
                    <item.icon className={`w-5 h-5 ${
                      location.pathname === item.path 
                        ? 'text-blue-500' 
                        : item.isHighlighted 
                          ? 'text-blue-500' 
                          : 'text-current'
                    }`} strokeWidth={1.5} />
                    {!isSidebarCollapsed && (
                      <>
                        <span className="ml-3 font-medium">{item.label}</span>
                        {item.isNew && (
                          <span className="ml-auto px-2 py-0.5 text-xs font-medium bg-blue-500 text-white rounded-full">
                            New
                          </span>
                        )}
                        {item.isBeta && (
                          <span className="ml-auto px-2 py-0.5 text-xs font-medium bg-yellow-500 text-black rounded-full">
                            BETA
                          </span>
                        )}
                      </>
                    )}
                  </Link>
                ))}
              </nav>
            </motion.div>

            {/* Beta Notice */}
            <div className="px-4 mb-4">
              <div className={`
                p-2 rounded-xl border backdrop-blur-sm
                ${isDarkMode 
                  ? 'bg-yellow-500/5 border-zinc-800 shadow-lg shadow-yellow-500/5' 
                  : 'bg-amber-50/80 border-amber-200/80 shadow-sm'
                }
              `}>
                {!isSidebarCollapsed ? (
                  <>
                    <div className="flex ml-1 items-center space-x-2.5">
                      <div className='space-y-0'>
                        <span className={`text-sm  font-semibold ${
                          isDarkMode ? 'text-yellow-500' : 'text-amber-700'
                        }`}>
                          OPEN BETA v{APP_VERSION}
                        </span>
                      </div>
                    </div>
                    <div className={`mt-0 ml-1 items-center text-xs ${
                      isDarkMode ? 'text-yellow-500/70' : 'text-amber-600/70'
                    }`}>
                      <span>Features may change or break.</span>
                    </div>
                  </>
                ) : (
                  <div className="flex justify-center">
                    <span className={`text-xs font-semibold ${
                      isDarkMode ? 'text-yellow-500' : 'text-amber-700'
                    }`}>
                      BETA
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="px-4 mb-4">
              <div className={`p-4 rounded-xl ${isDarkMode ? 'bg-zinc-900/30 backdrop-blur-sm border border-zinc-800' : 'bg-gray-50 border border-gray-200'}`}>
                {!isSidebarCollapsed ? (
                  <>
                    <div 
                      className="flex items-center group rounded-lg p-2 cursor-pointer hover:bg-gray-700/20 transition-colors"
                      onClick={() => navigate('/dashboard/settings')}
                    >
                      <div className={`w-10 h-10 rounded-full flex items-center justify-center ${
                        isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
                      }`}>
                        <span className={isDarkMode ? 'text-white' : 'text-gray-700'}>
                          {user?.name?.[0]?.toUpperCase()}
                        </span>
                      </div>
                      <div className="ml-3 flex-1">
                        <div className={`text-sm font-medium ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>
                          {user?.name}
                        </div>
                        <div className="text-sm text-gray-500">
                          {user?.email}
                        </div>
                      </div>
                      <div className="relative flex items-center group">
                        <Sparkles 
                          className="w-5 h-5 cursor-pointer"
                          style={{
                            background: `linear-gradient(
                            to right,
                            #ff0000,
                            #ffa500,
                            #ffff00,
                            #008000,
                            #0000ff,
                            #4b0082,
                            #ee82ee
                            )`,
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'yellow',
                            color: 'green',
                            filter: `
                            drop-shadow(0 0 5px rgba(59, 130, 246, 0.7))
                            drop-shadow(0 0 10px rgba(236, 72, 153, 0.7))
                            drop-shadow(0 0 15px rgba(234, 179, 8, 0.7))
                            `,
                            animation: 'rainbow 3s linear infinite'
                          }}
                        />
                        <style>{`
                          @keyframes rainbow {
                            0% { filter: drop-shadow(0 0 5px rgba(59, 130, 246, 0.7)); }
                            33% { filter: drop-shadow(0 0 5px rgba(236, 72, 153, 0.7)); }
                            66% { filter: drop-shadow(0 0 5px rgba(234, 179, 8, 0.7)); }
                            100% { filter: drop-shadow(0 0 5px rgba(59, 130, 246, 0.7)); }
                          }
                        `}</style>
                        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 
                          px-3 py-2 text-xs font-medium text-white bg-gray-900/90 
                          backdrop-blur-sm border border-gray-700 rounded-lg 
                          opacity-0 group-hover:opacity-100 transition-opacity duration-200 
                          pointer-events-none w-48 text-center shadow-xl">
                          Early Adopter Status
                          <span className="block text-gray-400 tracking-wide text-[10px] mt-1">
                            You're one of our first users! Thanks for being part of the journey.
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* Credits Display */}
                    <div className="mt-4">
                      <div className="px-2 py-1.5 rounded-lg bg-blue-500/10 backdrop-blur-sm border border-blue-500/20 flex items-center">
                        <CreditDisplay 
                          showBuyButton={false}
                          className="flex items-center text-sm font-medium text-blue-400"
                        />
                      </div>
                    </div>

                    {/* Actions Row */}
                    <div className="flex items-center justify-between mt-4 pt-4 border-t border-gray-700/50">
                      <button
                        onClick={() => navigate('/dashboard/settings')}
                        className={`p-2 rounded-lg transition-colors ${
                          isDarkMode 
                          ? 'hover:bg-zinc-800 text-gray-400 hover:text-white' 
                          : 'hover:bg-gray-200 text-gray-600'
                        }`}
                      >
                        <Settings className="w-5 h-5" />
                      </button>

                      <button
                        onClick={toggleDarkMode}
                        className={`p-2 rounded-lg transition-colors ${
                          isDarkMode 
                          ? 'hover:bg-gray-700 text-gray-400' 
                          : 'hover:bg-gray-200 text-gray-600'
                        }`}
                      >
                        {isDarkMode ? <Sun className="w-5 h-5" /> : <Moon className="w-5 h-5" />}
                      </button>

                      <button
                        onClick={handleLogout}
                        className={`p-2 rounded-lg transition-colors ${
                          isDarkMode 
                          ? 'hover:bg-gray-700 text-red-400' 
                          : 'hover:bg-gray-200 text-red-500'
                        }`}
                      >
                        <LogOut className="w-5 h-5" />
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col items-center space-y-4">
                    <div 
                      onClick={() => navigate('/dashboard/settings')}
                      className={`w-8 h-8 rounded-full flex items-center justify-center ${
                        isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
                      } cursor-pointer hover:opacity-80 transition-opacity`}
                    >
                      <span className={`text-sm ${isDarkMode ? 'text-white' : 'text-gray-700'}`}>
                        {user?.name?.[0]?.toUpperCase()}
                      </span>
                    </div>
                    
                    <div className="w-full h-px bg-gray-700/50" />
                    
                    <div className="flex flex-col space-y-2">
                      <button
                        onClick={() => navigate('/dashboard/settings')}
                        className={`p-2 rounded-lg transition-colors ${
                          isDarkMode 
                          ? 'hover:bg-gray-700 text-gray-400' 
                          : 'hover:bg-gray-200 text-gray-600'
                        }`}
                      >
                        <Settings className="w-4 h-4" />
                      </button>

                      <button
                        onClick={toggleDarkMode}
                        className={`p-2 rounded-lg transition-colors ${
                          isDarkMode 
                          ? 'hover:bg-gray-700 text-gray-400' 
                          : 'hover:bg-gray-200 text-gray-600'
                        }`}
                      >
                        {isDarkMode ? <Sun className="w-4 h-4" /> : <Moon className="w-4 h-4" />}
                      </button>

                      <button
                        onClick={handleLogout}
                        className={`p-2 rounded-lg transition-colors ${
                          isDarkMode 
                          ? 'hover:bg-gray-700 text-red-400' 
                          : 'hover:bg-gray-200 text-red-500'
                        }`}
                      >
                        <LogOut className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Main Content */}
      <div className="flex-1 flex flex-col min-w-0">
        <div className={`flex-1 overflow-auto ${isMobile ? 'pb-20' : ''}`}> {/* Add padding at bottom for mobile */}
          <div>
            {children}
          </div>
        </div>
      </div>

      {/* Mobile Bottom Navigation Bar - Apple-inspired design */}
      {isMobile && (
        <div className={`fixed bottom-0 left-0 right-0 z-50 ${
          isDarkMode ? 'bg-gray-900/95 border-t border-gray-800/50' : 'bg-white/95 border-t border-gray-200/50'
        } backdrop-blur-xl pb-2 pt-3 px-6`}>
          <div className="flex items-center justify-between max-w-sm mx-auto">
            {mobileNavItems.map((item, index) => (
              <Link
                key={item.path}
                to={item.path}
                className={`flex flex-col items-center ${
                  item.isPrimary ? 'relative' : ''
                }`}
              >
                {item.isPrimary ? (
                  <div className="flex flex-col items-center">
                    <div className={`flex items-center justify-center w-12 h-12 rounded-full -mt-8 shadow-lg ${
                      isDarkMode ? 'bg-blue-600 shadow-blue-500/20' : 'bg-blue-500 shadow-blue-500/30'
                    }`}>
                      <item.icon className="w-5 h-5 text-white" />
                    </div>
                    <span className={`text-[10px] mt-1.5 font-medium tracking-tight ${
                      isDarkMode ? 'text-blue-400' : 'text-blue-500'
                    }`}>
                      {item.label}
                    </span>
                  </div>
                ) : (
                  <>
                    <div className={`mb-1 ${
                      location.pathname === item.path ? 
                        (isDarkMode ? 'text-blue-400' : 'text-blue-500') : 
                        (isDarkMode ? 'text-gray-500' : 'text-gray-500')
                    }`}>
                      <item.icon className="w-6 h-6 stroke-[1.5px]" />
                    </div>
                    <span className={`text-[10px] font-medium tracking-tight ${
                      location.pathname === item.path ? 
                        (isDarkMode ? 'text-blue-400 font-medium' : 'text-blue-500 font-medium') : 
                        (isDarkMode ? 'text-gray-500' : 'text-gray-500')
                    }`}>
                      {item.label}
                    </span>
                  </>
                )}
                
                {/* Active indicator dot - Apple style */}
                {location.pathname === item.path && !item.isPrimary && (
                  <div className={`absolute -bottom-1.5 w-1 h-1 rounded-full ${
                    isDarkMode ? 'bg-blue-400' : 'bg-blue-500'
                  }`}></div>
                )}
              </Link>
            ))}
          </div>
        </div>
      )}

      {/* Show the walkthrough only in dashboard */}
      {isWalkthroughActive && (
        <AppWalkthrough 
          steps={walkthroughSteps}
          isOpen={isWalkthroughActive}
          onComplete={completeWalkthrough} 
          onSkip={skipWalkthrough} 
        />
      )}
    </div>
  );
};