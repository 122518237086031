import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';
import { 
  Paintbrush, Sparkles, LineChart, Target, 
  PencilRuler, Lightbulb, ChevronRight,
  Wand2, ArrowRight, Crown, Lock,
  Zap, MessageSquare, Box
} from 'lucide-react';
import { Link } from 'react-router-dom';

const CanvasFeatures: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [hoverPattern, setHoverPattern] = useState<number | null>(null);
  const [showGlow, setShowGlow] = useState(false);
  const [magicPosition, setMagicPosition] = useState({ x: 300, y: 200 });
  const canvasRef = useRef<HTMLDivElement>(null);
  
  // Cycle through glow effect
  useEffect(() => {
    const interval = setInterval(() => {
      setShowGlow(prev => !prev);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  // Magic wand animation
  useEffect(() => {
    const interval = setInterval(() => {
      if (canvasRef.current) {
        const bounds = canvasRef.current.getBoundingClientRect();
        const randomX = Math.random() * (bounds.width * 0.8);
        const randomY = Math.random() * (bounds.height * 0.7);
        setMagicPosition({ x: randomX, y: randomY });
      }
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gradient-to-br from-gray-900 via-black to-gray-900' : 'bg-gradient-to-br from-gray-50 via-white to-gray-100'}`}>
      {/* Background elements */}
      <div className="fixed inset-0 pointer-events-none overflow-hidden">
        <div className={`absolute inset-0 ${
          isDarkMode 
            ? 'bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-900/15 via-gray-900/10 to-transparent' 
            : 'bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-400/10 via-gray-100/10 to-transparent'
        } animate-pulse-slow`} />
        <div className={`absolute inset-0 ${
          isDarkMode 
            ? 'bg-grid-dark opacity-10' 
            : 'bg-grid-light opacity-20'
        }`} />
      </div>
      
      <div className="max-w-7xl w-full px-6 py-16 mx-auto relative">
        {/* Coming soon badge */}
        <motion.div 
          className="absolute top-40 right-4 md:right-6"
          animate={{ y: [0, -5, 0] }}
          transition={{ duration: 3, repeat: Infinity }}
        >
          <div className={`px-4 py-2 rounded-xl ${isDarkMode ? 
            'bg-blue-900/30 border border-blue-700/50 text-blue-300' : 
            'bg-blue-100 border border-blue-200 text-blue-700'
          } backdrop-blur-sm shadow-lg`}>
            <div className="flex items-center gap-2">
              <span className="font-medium">Coming Soon</span>
              <span className="px-1.5 py-0.5 rounded bg-blue-500/20 text-xs">
                April 2025
              </span>
            </div>
          </div>
        </motion.div>
        
        {/* Pro badge */}
        <motion.div 
          className="absolute top-20 right-4 md:right-6"
          initial={{ scale: 0.95 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1.5, repeat: Infinity, repeatType: "reverse" }}
        >
          <div className={`px-4 py-2 rounded-xl ${isDarkMode ? 
            'bg-gradient-to-r from-yellow-500/20 to-amber-500/20 border border-yellow-500/30 text-yellow-300' : 
            'bg-gradient-to-r from-yellow-100 to-amber-100 border border-yellow-200 text-yellow-700'
          } backdrop-blur-sm shadow-lg`}>
            <div className="flex items-center gap-2">
              <Crown className="w-4 h-4" />
              <span className="font-medium text-sm">
                Early Access with Pro
              </span>
            </div>
          </div>
        </motion.div>
        
        {/* Main content */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mt-10"
        >
          <div className="flex items-center mb-6">
            <Box className={`mr-3 w-8 h-8 ${isDarkMode ? 'text-white' : 'text-gray-800'}`} />
            <h1 className={`text-3xl md:text-4xl font-heading tracking-tighter ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
              Canvas
            </h1>
          </div>
          
          <p className={`text-xl max-w-2xl mb-12 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            Transform your market conversations into rich, interactive visualizations with a single click.
            <span className="block mt-2 text-lg opacity-75">Powered by Claude's visual intelligence.</span>
          </p>

          {/* Preview area */}
          <div 
            ref={canvasRef}
            className={`relative w-full h-[500px] rounded-2xl overflow-hidden border shadow-xl mb-16
              ${isDarkMode ? 'border-gray-700/50 bg-gray-900/30' : 'border-gray-200 bg-white/80'}
              backdrop-blur-sm
            `}
          >
            {/* Stock chart */}
            <svg className="absolute inset-0 w-full h-full" viewBox="0 0 1000 500">
              <motion.path 
                d="M0,350 C50,360 100,330 150,340 C200,350 250,310 300,290 C350,270 400,250 450,220 C500,190 550,170 600,160 C650,150 700,170 750,180 C800,190 850,150 900,130 C950,110 1000,120 1000,120"
                fill="none" 
                stroke={isDarkMode ? "#3b82f6" : "#2563eb"}
                strokeWidth="3"
                strokeLinecap="round"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 2, delay: 0.5 }}
              />

              {/* Support line */}
              <motion.line 
                x1="0" y1="320" x2="1000" y2="320"
                stroke={isDarkMode ? "#475569" : "#94a3b8"}
                strokeWidth="2"
                strokeDasharray="5,5"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 1, delay: 1.5 }}
              />

              {/* Resistance line */}
              <motion.line 
                x1="0" y1="200" x2="1000" y2="200"
                stroke={isDarkMode ? "#475569" : "#94a3b8"}
                strokeWidth="2"
                strokeDasharray="5,5"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 1, delay: 1.8 }}
              />
              
              {/* Volume bars */}
              {[...Array(20)].map((_, i) => {
                const x = i * 50;
                const height = Math.random() * 60 + 20;
                const y = 450 - height;
                return (
                  <motion.rect
                    key={`vol-${i}`}
                    x={x}
                    y={y}
                    width={30}
                    height={height}
                    fill={isDarkMode ? "#3b82f630" : "#2563eb20"}
                    initial={{ scaleY: 0 }}
                    animate={{ scaleY: 1 }}
                    transition={{ delay: 1 + i * 0.05, duration: 0.5 }}
                    style={{ transformOrigin: `${x + 15}px ${y + height}px` }}
                  />
                );
              })}

              {/* Pattern annotations */}
              <motion.g
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 2.5 }}
              >
                {/* Double bottom pattern */}
                <motion.ellipse
                  cx="250" cy="340" rx="40" ry="20"
                  fill="transparent"
                  stroke={hoverPattern === 1 ? '#f472b6' : '#8b5cf6'}
                  strokeWidth="2"
                  strokeDasharray={hoverPattern === 1 ? "0" : "5,5"}
                  whileHover={{ scale: 1.1 }}
                  onMouseEnter={() => setHoverPattern(1)}
                  onMouseLeave={() => setHoverPattern(null)}
                />

                {/* Breakout */}
                <motion.polygon
                  points="550,200 650,200 600,100"
                  fill="transparent"
                  stroke={hoverPattern === 2 ? '#f472b6' : '#8b5cf6'}
                  strokeWidth="2"
                  strokeDasharray={hoverPattern === 2 ? "0" : "5,5"}
                  whileHover={{ scale: 1.1 }}
                  onMouseEnter={() => setHoverPattern(2)}
                  onMouseLeave={() => setHoverPattern(null)}
                />

                {/* Trend line */}
                <motion.line
                  x1="700" y1="200" x2="900" y2="130"
                  stroke={hoverPattern === 3 ? '#f472b6' : '#8b5cf6'}
                  strokeWidth="3"
                  strokeDasharray={hoverPattern === 3 ? "0" : "5,5"}
                  whileHover={{ scale: 1.1 }}
                  onMouseEnter={() => setHoverPattern(3)}
                  onMouseLeave={() => setHoverPattern(null)}
                />
              </motion.g>

              {/* Callouts */}
              <motion.g
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 3 }}
              >
                <foreignObject x="200" y="250" width="120" height="60">
                  <div className={`px-2 py-1 rounded-md text-xs backdrop-blur-sm shadow-lg ${
                    isDarkMode 
                      ? 'bg-purple-500/20 border border-purple-500/30 text-purple-200' 
                      : 'bg-purple-100 border border-purple-200 text-purple-700'
                  }`}>
                    <div className="font-medium">Double bottom</div>
                    <div className="text-xs">Bullish reversal</div>
                  </div>
                </foreignObject>

                <foreignObject x="580" y="120" width="120" height="60">
                  <div className={`px-2 py-1 rounded-md text-xs backdrop-blur-sm shadow-lg ${
                    isDarkMode 
                      ? 'bg-green-500/20 border border-green-500/30 text-green-200' 
                      : 'bg-green-100 border border-green-200 text-green-700'
                  }`}>
                    <div className="font-medium">Triangle breakout</div>
                    <div className="text-xs">Entry point</div>
                  </div>
                </foreignObject>

                <foreignObject x="850" y="80" width="120" height="60">
                  <div className={`px-2 py-1 rounded-md text-xs backdrop-blur-sm shadow-lg ${
                    isDarkMode 
                      ? 'bg-blue-500/20 border border-blue-500/30 text-blue-200' 
                      : 'bg-blue-100 border border-blue-200 text-blue-700'
                  }`}>
                    <div className="font-medium">Strong uptrend</div>
                    <div className="text-xs">Price target: $425</div>
                  </div>
                </foreignObject>
              </motion.g>

              {/* AI annotation glow effect */}
              <motion.ellipse
                cx="250" cy="340" rx="50" ry="30"
                fill="none"
                stroke={isDarkMode ? "#8b5cf6" : "#a855f7"}
                strokeWidth="2"
                animate={{ 
                  opacity: showGlow ? [0, 0.5, 0] : 0,
                  scale: showGlow ? [1, 1.2, 1] : 1,
                }}
                transition={{ duration: 1.5 }}
              />
              
              {/* Animated star particles */}
              {[...Array(7)].map((_, i) => (
                <motion.path
                  key={`star-${i}`}
                  d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
                  fill="#fcd34d"
                  stroke="none"
                  initial={{ scale: 0, x: magicPosition.x + i*30, y: magicPosition.y + i*10 }}
                  animate={{
                    scale: [0, 1, 0],
                    opacity: [0, 0.8, 0],
                    x: magicPosition.x + i*30 + Math.random()*30,
                    y: magicPosition.y + i*10 - (i*5),
                  }}
                  transition={{
                    duration: 1.5,
                    delay: i*0.1,
                    repeat: Infinity,
                    repeatDelay: 5
                  }}
                  style={{ transformOrigin: 'center' }}
                />
              ))}
            </svg>

            {/* Wand cursor effect */}
            <motion.div
              className="absolute w-10 h-10 pointer-events-none"
              animate={{ 
                x: [200, 600, 800, 200],
                y: [340, 200, 150, 340],
              }}
              transition={{
                duration: 10,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            >
              <motion.div
                className="absolute inset-0 rounded-full bg-blue-500/20"
                animate={{ scale: [1, 1.5, 1] }}
                transition={{ duration: 2, repeat: Infinity }}
              />
              <Wand2 className={`w-full h-full ${
                isDarkMode ? 'text-blue-400' : 'text-blue-500'
              }`} />
            </motion.div>

            {/* "Powered by Claude" badge */}
            <div className="absolute right-4 bottom-4">
              <motion.div
                className={`flex items-center space-x-2 px-3 py-1.5 rounded-lg ${
                  isDarkMode 
                    ? 'bg-blue-500/20 border border-blue-500/30' 
                    : 'bg-blue-100 border border-blue-200'
                } backdrop-blur-sm shadow-lg`}
                animate={{ y: [0, -5, 0] }}
                transition={{ duration: 3, repeat: Infinity }}
              >
                <Sparkles className={`w-4 h-4 ${
                  isDarkMode ? 'text-blue-400' : 'text-blue-600'
                }`} />
                <span className={`text-sm font-medium ${
                  isDarkMode ? 'text-blue-300' : 'text-blue-700'
                }`}>
                  Powered by Claude
                </span>
              </motion.div>
            </div>

            {/* "Coming soon" overlay */}
            <div className="absolute inset-0 backdrop-blur-[1px]">
              <div className="flex h-full items-center justify-center">
                <motion.div
                  className={`px-6 py-3 rounded-xl border shadow-lg ${
                    isDarkMode 
                      ? 'bg-gray-900/70 border-gray-700/50 text-white' 
                      : 'bg-white/80 border-gray-200 text-gray-700'
                  } backdrop-blur-sm`}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 3, duration: 0.5 }}
                >
                  <div className="text-xl font-bold font-heading tracking-tighter">Coming soon to Prism...</div>
                  <div className="mt-1 opacity-75">Visualize market insights with a single click.</div>
                </motion.div>
              </div>
            </div>
            
            {/* Pro subscriber badge */}
            <div className="absolute left-4 bottom-4">
              <motion.div
                className={`flex items-center space-x-2 px-3 py-1.5 rounded-lg ${
                  isDarkMode 
                    ? 'bg-amber-500/20 border border-amber-500/30' 
                    : 'bg-amber-100 border border-amber-200'
                } backdrop-blur-sm shadow-lg`}
                animate={{ 
                  boxShadow: ['0 0 0 rgba(245, 158, 11, 0)', '0 0 15px rgba(245, 158, 11, 0.3)', '0 0 0 rgba(245, 158, 11, 0)']
                }}
                transition={{ duration: 4, repeat: Infinity }}
              >
                <Lock className={`w-4 h-4 ${
                  isDarkMode ? 'text-amber-400' : 'text-amber-600'
                }`} />
                <span className={`text-sm font-medium ${
                  isDarkMode ? 'text-amber-300' : 'text-amber-700'
                }`}>
                  Pro Subscribers Get Early Access
                </span>
              </motion.div>
            </div>
          </div>

          {/* Features section */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-16">
            {[
              {
                icon: Lightbulb, 
                title: "Market Intelligence", 
                description: "Let Claude analyze your charts, detecting patterns and adding expert annotations automatically."
              },
              {
                icon: PencilRuler, 
                title: "Custom Visualization", 
                description: "Fine-tune visualizations with professional tools designed for market analysis."
              },
              {
                icon: Zap, 
                title: "One-Click Creation", 
                description: "Generate beautiful, annotated charts directly from your market conversations."
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                className={`p-6 rounded-2xl ${isDarkMode ? 
                  'bg-gray-900/50 border border-gray-700/50 hover:bg-gray-800/50' : 
                  'bg-white/80 border border-gray-200/50 hover:bg-gray-50/80'
                } backdrop-blur-sm transition-all duration-300 hover:scale-[1.02]`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 * index }}
              >
                <div className={`p-3 rounded-lg inline-block mb-4 ${isDarkMode ?
                  'bg-blue-900/20' : 'bg-blue-50'
                }`}>
                  <feature.icon className={`w-6 h-6 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
                </div>
                <h3 className={`text-xl font-bold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
                  {feature.title}
                </h3>
                <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                  {feature.description}
                </p>
              </motion.div>
            ))}
          </div>
          
          {/* "What can you do with Canvas?" section */}
          <div className="mb-16">
            <h2 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
              What can you do with Canvas?
            </h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {[
                { 
                  icon: MessageSquare,
                  title: "Visualize market conversations",
                  description: "Transform dialogue with Prism into interactive visual insights"
                },
                { 
                  icon: Target,
                  title: "Identify key technical patterns",
                  description: "Let AI highlight support, resistance and chart patterns"
                },
                { 
                  icon: LineChart,
                  title: "Annotate price action",
                  description: "Add professional markup to any stock or asset chart"
                },
                { 
                  icon: Zap,
                  title: "Generate chart narratives",
                  description: "Let Claude explain what's happening in your visualizations"
                }
              ].map((item, index) => (
                <motion.div
                  key={index}
                  className={`flex items-start p-4 rounded-xl ${isDarkMode ? 
                    'bg-gray-800/30 border border-gray-700/50' : 
                    'bg-gray-50/80 border border-gray-200/50'
                  } backdrop-blur-sm`}
                  initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.3 + (index * 0.1) }}
                >
                  <div className={`p-2 rounded-lg mr-4 ${isDarkMode ?
                    'bg-blue-500/20 text-blue-400' : 'bg-blue-100 text-blue-600'
                  }`}>
                    <item.icon className="w-5 h-5" />
                  </div>
                  <div>
                    <h3 className={`font-medium mb-1 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
                      {item.title}
                    </h3>
                    <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                      {item.description}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>

          {/* CTA Section */}
          <div className="max-w-2xl mx-auto text-center">
            <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
              Be among the first to experience Canvas
            </h2>
            <p className={`mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              Pro subscribers get early access when Canvas launches in April 2025.
            </p>
            <p className={`mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              Da Vinci had patrons. You have a Pro subscription.
              </p>
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
              <Link
                to="/dashboard"
                className={`inline-flex items-center px-6 py-3 rounded-xl text-white font-medium ${
                  isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'
                } transition-colors w-full sm:w-auto`}
              >
                <span>Try Prism Today</span>
                <ChevronRight className="ml-2 w-5 h-5" />
              </Link>
              
              <Link
                to="/pricing"
                className={`inline-flex items-center px-6 py-3 rounded-xl font-medium ${
                  isDarkMode ? 
                    'bg-gradient-to-r from-amber-600 to-yellow-500 hover:from-amber-500 hover:to-yellow-400' : 
                    'bg-gradient-to-r from-amber-500 to-yellow-400 hover:from-amber-400 hover:to-yellow-300'
                } text-white transition-colors w-full sm:w-auto`}
              >
                <Crown className="mr-2 w-5 h-5" />
                <span>Upgrade to Pro</span>
              </Link>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default CanvasFeatures;