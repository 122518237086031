import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { DashboardLayout } from './DashboardLayout';

import Credits from './Credits';
import Settings from '../settings';

import MarketData from '../../components/MarketData';
import MarketChat from '../../pages/MarketChat';
import UserSettings from '../settings';
import DashboardOverview from './DashboardOverview';
import SuperChart from '../superchart/SuperChart';

import { StockDetails } from '../../components/market/StockDetails';
import { StockDetailView } from '../../components/market/StockDetailView';

import SubscriptionDashboard from './SubscriptionDashboard';
import MarketIntelligence from '../market/MarketIntelligence';
import { WatchlistsView } from './WatchlistsView';
import TradeIdeasPage from '../TradeIdeasPage';
import StockSearchPage from '../search/StockSearchPage'; // Add this import
export const Dashboard: React.FC = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);

  const handleSidebarToggle = (isCollapsed: boolean) => {
    setIsSidebarCollapsed(isCollapsed);
  };
  
  return (
    <DashboardLayout onSidebarToggle={handleSidebarToggle}>
      <Routes>
        <Route index element={<DashboardOverview />} />
        <Route path="settings" element={<Settings />} />
        <Route path="credits" element={<SubscriptionDashboard />} />
        <Route path="market-chat" element={<MarketChat />} />
        <Route path="/trade-ideas" element={<TradeIdeasPage />} />
        <Route path="market" element={<MarketIntelligence />} />
        <Route path="market/:ticker" element={<StockDetailView />} />
        <Route path="superchart" element={<SuperChart />} />
        <Route path="watchlists" element={<WatchlistsView />} />
        <Route path="search" element={<StockSearchPage />} />
      </Routes>
    </DashboardLayout>
  );
};