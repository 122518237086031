// Add price fetching hook
import { useEffect, useState } from 'react';
import { api } from '../services/api';

interface PriceData {
  price?: number;
  change?: number;
}

export const usePriceData = (identifier: string) => {
  const [data, setData] = useState<PriceData>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await api.get(`/market/stock/${identifier}?timeframe=1D`);
        const results = response.data?.results || [];
        if (results.length > 0) {
          const latest = results[results.length - 1];
          setData({
            price: latest.c,
            change: ((latest.c - latest.o) / latest.o) * 100
          });
        }
      } catch (error) {
        console.error(`Failed to fetch price for ${identifier}:`, error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPrice();
    const interval = setInterval(fetchPrice, 60000); // Refresh every minute

    return () => clearInterval(interval);
  }, [identifier]);

  return { data, isLoading };
};

