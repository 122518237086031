// src/services/patternDetection.ts
import { ChartDataPoint } from '../types/superChart';

interface Peak {
  index: number;
  price: number;
  isHigh: boolean;
}

// Helper functions for pattern detection
function calculateHSConfidence(
  leftShoulder: Peak,
  head: Peak,
  rightShoulder: Peak
): number {
  // Calculate confidence based on symmetry and proportion
  const heightDiff = Math.abs(leftShoulder.price - rightShoulder.price) / head.price;
  const symmetry = Math.abs(
    (rightShoulder.index - head.index) - (head.index - leftShoulder.index)
  ) / (rightShoulder.index - leftShoulder.index);
  
  return Math.min(
    1 - heightDiff * 2, // Penalize height difference between shoulders
    1 - symmetry * 2,   // Penalize asymmetry
    0.95                // Cap maximum confidence
  );
}

function isLocalPeak(
  data: ChartDataPoint[],
  index: number,
  isHigh: boolean
): boolean {
  if (index < 2 || index > data.length - 3) return false;
  
  const compareValues = isHigh
    ? (a: number, b: number) => a > b
    : (a: number, b: number) => a < b;
  
  const price = data[index].price;
  
  return compareValues(price, data[index - 1].price) &&
         compareValues(price, data[index - 2].price) &&
         compareValues(price, data[index + 1].price) &&
         compareValues(price, data[index + 2].price);
}

function calculateLinearRegression(prices: number[]): { slope: number; r2: number } {
  const n = prices.length;
  const x = Array.from({ length: n }, (_, i) => i);
  
  const sumX = x.reduce((a, b) => a + b, 0);
  const sumY = prices.reduce((a, b) => a + b, 0);
  const sumXY = x.reduce((a, i) => a + (i * prices[i]), 0);
  const sumXX = x.reduce((a, b) => a + (b * b), 0);
  
  const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  const intercept = (sumY - slope * sumX) / n;
  
  // Calculate R-squared
  const yMean = sumY / n;
  const ssTot = prices.reduce((a, y) => a + Math.pow(y - yMean, 2), 0);
  const ssRes = prices.reduce((a, y, i) => {
    const yPred = slope * x[i] + intercept;
    return a + Math.pow(y - yPred, 2);
  }, 0);
  
  return { slope, r2: 1 - (ssRes / ssTot) };
}

function isHorizontalResistance(prices: number[]): boolean {
  const { slope, r2 } = calculateLinearRegression(prices);
  return Math.abs(slope) < 0.001 && r2 > 0.7;
}

function isHorizontalSupport(prices: number[]): boolean {
  return isHorizontalResistance(prices);
}

function isAscendingSupport(prices: number[]): boolean {
  const { slope, r2 } = calculateLinearRegression(prices);
  return slope > 0.001 && r2 > 0.7;
}

function isDescendingResistance(prices: number[]): boolean {
  const { slope, r2 } = calculateLinearRegression(prices);
  return slope < -0.001 && r2 > 0.7;
}

function calculateTriangleConfidence(highs: number[], lows: number[]): number {
  const { r2: highR2 } = calculateLinearRegression(highs);
  const { r2: lowR2 } = calculateLinearRegression(lows);
  
  return Math.min(highR2, lowR2, 0.95);
}

function isStrongTrend(data: ChartDataPoint[], isUp: boolean): boolean {
  const prices = data.map(d => d.price);
  const { slope, r2 } = calculateLinearRegression(prices);
  const threshold = 0.002; // Minimum slope for strong trend
  
  return isUp
    ? slope > threshold && r2 > 0.7
    : slope < -threshold && r2 > 0.7;
}

function isStrongUptrend(data: ChartDataPoint[]): boolean {
  return isStrongTrend(data, true);
}

function isStrongDowntrend(data: ChartDataPoint[]): boolean {
  return isStrongTrend(data, false);
}

function isParallelConsolidation(data: ChartDataPoint[]): boolean {
  const highs = data.map(d => d.price).filter((_, i) => isLocalPeak(data, i, true));
  const lows = data.map(d => d.price).filter((_, i) => isLocalPeak(data, i, false));
  
  if (highs.length < 2 || lows.length < 2) return false;
  
  const { slope: highSlope } = calculateLinearRegression(highs);
  const { slope: lowSlope } = calculateLinearRegression(lows);
  
  return Math.abs(highSlope - lowSlope) < 0.001;
}

function calculateFlagConfidence(
  trend: ChartDataPoint[],
  consolidation: ChartDataPoint[]
): number {
  const trendStrength = calculateLinearRegression(trend.map(d => d.price)).r2;
  const isParallel = isParallelConsolidation(consolidation);
  
  return isParallel ? trendStrength * 0.95 : trendStrength * 0.7;
}

export function findLocalPeaks(data: ChartDataPoint[], windowSize = 5): Peak[] {
  const peaks: Peak[] = [];
  
  for (let i = windowSize; i < data.length - windowSize; i++) {
    const currentPrice = data[i].price;
    const windowPrices = data.slice(i - windowSize, i + windowSize + 1).map(d => d.price);
    
    // Check for high peak
    if (currentPrice === Math.max(...windowPrices)) {
      peaks.push({ index: i, price: currentPrice, isHigh: true });
    }
    // Check for low peak
    else if (currentPrice === Math.min(...windowPrices)) {
      peaks.push({ index: i, price: currentPrice, isHigh: false });
    }
  }

  return peaks;
}

export function detectDoubleTop(peaks: Peak[], priceThreshold = 0.01): any[] {
  const patterns = [];
  const highPeaks = peaks.filter(p => p.isHigh);

  for (let i = 0; i < highPeaks.length - 1; i++) {
    const peak1 = highPeaks[i];
    const peak2 = highPeaks[i + 1];
    const priceDiff = Math.abs(peak1.price - peak2.price) / peak1.price;

    if (priceDiff <= priceThreshold && peak2.index - peak1.index > 10) {
      patterns.push({
        type: 'double_top',
        startIndex: peak1.index,
        endIndex: peak2.index,
        points: [peak1, peak2],
        confidence: 1 - priceDiff / priceThreshold,
        explanation: `Double top detected around $${peak1.price.toFixed(2)} with ${Math.round((1 - priceDiff) * 100)}% price similarity`
      });
    }
  }

  return patterns;
}

export function detectHeadAndShoulders(peaks: Peak[]): any[] {
  const patterns = [];
  const highPeaks = peaks.filter(p => p.isHigh);

  for (let i = 0; i < highPeaks.length - 2; i++) {
    const leftShoulder = highPeaks[i];
    const head = highPeaks[i + 1];
    const rightShoulder = highPeaks[i + 2];

    if (head.price > leftShoulder.price && 
        head.price > rightShoulder.price && 
        Math.abs(leftShoulder.price - rightShoulder.price) / leftShoulder.price < 0.03) {
      
      patterns.push({
        type: 'head_and_shoulders',
        startIndex: leftShoulder.index,
        endIndex: rightShoulder.index,
        confidence: calculateHSConfidence(leftShoulder, head, rightShoulder),
        explanation: `Head & Shoulders pattern detected with head at $${head.price.toFixed(2)}`
      });
    }
  }

  return patterns;
}

export function detectTriangles(data: ChartDataPoint[]): any[] {
  const patterns = [];
  const windowSize = Math.floor(data.length * 0.1); // 10% of the data length

  for (let i = windowSize; i < data.length - windowSize; i++) {
    const segment = data.slice(i - windowSize, i + windowSize);
    const highs = segment.map(d => d.price).filter((_, idx) => isLocalPeak(segment, idx, true));
    const lows = segment.map(d => d.price).filter((_, idx) => isLocalPeak(segment, idx, false));

    // Ascending Triangle
    if (isHorizontalResistance(highs) && isAscendingSupport(lows)) {
      patterns.push({
        type: 'ascending_triangle',
        startIndex: i - windowSize,
        endIndex: i + windowSize,
        confidence: calculateTriangleConfidence(highs, lows),
        explanation: 'Ascending triangle pattern with rising support level'
      });
    }

    // Descending Triangle
    if (isHorizontalSupport(lows) && isDescendingResistance(highs)) {
      patterns.push({
        type: 'descending_triangle',
        startIndex: i - windowSize,
        endIndex: i + windowSize,
        confidence: calculateTriangleConfidence(highs, lows),
        explanation: 'Descending triangle pattern with falling resistance level'
      });
    }
  }

  return patterns;
}

export function detectFlags(data: ChartDataPoint[]): any[] {
  const patterns = [];
  const trendLength = Math.floor(data.length * 0.15); // 15% of the data length

  for (let i = trendLength; i < data.length - trendLength; i++) {
    const preTrend = data.slice(i - trendLength, i);
    const consolidation = data.slice(i, i + trendLength);

    // Bull Flag
    if (isStrongUptrend(preTrend) && isParallelConsolidation(consolidation)) {
      patterns.push({
        type: 'bull_flag',
        startIndex: i - trendLength,
        endIndex: i + trendLength,
        confidence: calculateFlagConfidence(preTrend, consolidation),
        explanation: 'Bullish flag pattern following strong uptrend'
      });
    }

    // Bear Flag
    if (isStrongDowntrend(preTrend) && isParallelConsolidation(consolidation)) {
      patterns.push({
        type: 'bear_flag',
        startIndex: i - trendLength,
        endIndex: i + trendLength,
        confidence: calculateFlagConfidence(preTrend, consolidation),
        explanation: 'Bearish flag pattern following strong downtrend'
      });
    }
  }

  return patterns;
}

export function detectDoubleBottom(peaks: Peak[], priceThreshold = 0.01): any[] {
  const patterns = [];
  const lowPeaks = peaks.filter(p => !p.isHigh);

  for (let i = 0; i < lowPeaks.length - 1; i++) {
    const peak1 = lowPeaks[i];
    const peak2 = lowPeaks[i + 1];
    const priceDiff = Math.abs(peak1.price - peak2.price) / peak1.price;

    if (priceDiff <= priceThreshold && peak2.index - peak1.index > 10) {
      patterns.push({
        type: 'double_bottom',
        startIndex: peak1.index,
        endIndex: peak2.index,
        points: [peak1, peak2],
        confidence: 1 - priceDiff / priceThreshold,
        explanation: `Double bottom detected around $${peak1.price.toFixed(2)} with ${Math.round((1 - priceDiff) * 100)}% price similarity`
      });
    }
  }

  return patterns;
}

export function detectTrend(data: ChartDataPoint[], windowSize = 20): any {
  const startPrice = data[0].price;
  const endPrice = data[data.length - 1].price;
  const priceChange = (endPrice - startPrice) / startPrice;
  const threshold = 0.02; // 2% change

  if (Math.abs(priceChange) >= threshold) {
    return {
      type: priceChange > 0 ? 'uptrend' : 'downtrend',
      startIndex: 0,
      endIndex: data.length - 1,
      confidence: Math.min(Math.abs(priceChange) / threshold, 1),
      explanation: `${priceChange > 0 ? 'Upward' : 'Downward'} trend of ${Math.abs(Math.round(priceChange * 100))}% detected`
    };
  }

  return null;
}

export function detectBreakouts(data: ChartDataPoint[], levels: any[]): any[] {
  const patterns: any[] = [];
  const windowSize = 5;

  levels.forEach(level => {
    for (let i = windowSize; i < data.length - windowSize; i++) {
      const beforePrices = data.slice(i - windowSize, i).map(d => d.price);
      const afterPrices = data.slice(i, i + windowSize).map(d => d.price);
      
      const beforeAvg = beforePrices.reduce((a, b) => a + b, 0) / windowSize;
      const afterAvg = afterPrices.reduce((a, b) => a + b, 0) / windowSize;

      if (level.type === 'resistance' && beforeAvg < level.price && afterAvg > level.price) {
        patterns.push({
          type: 'breakout',
          startIndex: i - windowSize,
          endIndex: i + windowSize,
          direction: 'up',
          price: level.price,
          confidence: level.strength / 10,
          explanation: `Upward breakout through resistance at $${level.price.toFixed(2)}`
        });
      } else if (level.type === 'support' && beforeAvg > level.price && afterAvg < level.price) {
        patterns.push({
          type: 'breakout',
          startIndex: i - windowSize,
          endIndex: i + windowSize,
          direction: 'down',
          price: level.price,
          confidence: level.strength / 10,
          explanation: `Downward breakout through support at $${level.price.toFixed(2)}`
        });
      }
    }
  });

  return patterns;
}