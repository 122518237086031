import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import { authService } from '../../services/authService';
import { motion } from 'framer-motion';
import { RegisterFormData, ValidationErrors, PasswordStrength, TradingRole, ExperienceLevel } from '../../types/register';
import { 
  Key,
  Mail, 
  Lock, 
  User, 
  EyeOff, 
  Eye, 
  Github, 
  Linkedin,
  ArrowLeft,
  CheckCircle2,
  AlertCircle,
  Loader2,
  ChevronRight,
  
} from 'lucide-react';
import { RegisterCredentials } from '../../types/auth';
import Spline from '@splinetool/react-spline';

const FloatingElements = React.memo(() => {
  const { isDarkMode } = useTheme();
  
  const particles = React.useMemo(() => 
    Array.from({ length: 20 }).map(() => ({
      left: `${Math.random() * 100}%`,
      top: `${Math.random() * 100}%`,
      scale: Math.random() * 0.5 + 0.5,
      duration: Math.random() * 3 + 2,
      delay: Math.random() * 2
    })), []
  );
  
  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none isolate">
      <motion.div className="absolute inset-0" style={{ perspective: 1000 }}>
        {particles.map((particle, i) => (
          <motion.div
            key={i}
            className={`absolute w-2 h-2 rounded-full ${
              isDarkMode ? 'bg-blue-500' : 'bg-blue-400'
            }`}
            style={{
              left: particle.left,
              top: particle.top,
              opacity: 0.2,
              scale: particle.scale
            }}
            animate={{
              x: [-20, 20],
              y: [-20, 20], 
              scale: [1, 1.2, 1],
              opacity: [0.2, 0.5, 0.2],
            }}
            transition={{
              duration: particle.duration,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "easeInOut",
              delay: particle.delay
            }}
          />
        ))}
      </motion.div>

      <svg className="absolute inset-0 w-full h-full">
        <defs>
          <linearGradient id="lineGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={isDarkMode ? '#3B82F6' : '#60A5FA'} stopOpacity="0.2" />
            <stop offset="50%" stopColor={isDarkMode ? '#8B5CF6' : '#A78BFA'} stopOpacity="0.3" />
            <stop offset="100%" stopColor={isDarkMode ? '#3B82F6' : '#60A5FA'} stopOpacity="0.2" />
          </linearGradient>
        </defs>
        {Array.from({ length: 5 }).map((_, i) => (
          <motion.path
            key={i}
            d={`M0 ${100 + i * 100} Q ${window.innerWidth / 2} ${50 + Math.sin(i) * 50} ${window.innerWidth} ${100 + i * 100}`}
            stroke="url(#lineGradient)"
            strokeWidth="1"
            fill="none"
            animate={{
              d: [
                `M0 ${100 + i * 100} Q ${window.innerWidth / 2} ${50 + Math.sin(i) * 50} ${window.innerWidth} ${100 + i * 100}`,
                `M0 ${100 + i * 100} Q ${window.innerWidth / 2} ${150 + Math.sin(i) * 50} ${window.innerWidth} ${100 + i * 100}`,
                `M0 ${100 + i * 100} Q ${window.innerWidth / 2} ${50 + Math.sin(i) * 50} ${window.innerWidth} ${100 + i * 100}`
              ]
            }}
            transition={{
              duration: 10 + i * 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />
        ))}
      </svg>

      <div className="absolute inset-0 grid grid-cols-8 grid-rows-8">
        {Array.from({ length: 64 }).map((_, i) => (
          <motion.div
            key={i}
            className={`border border-gray-800/10 ${
              isDarkMode ? 'bg-gray-900/5' : 'bg-gray-100/5'
            }`}
            whileHover={{ 
              scale: 1.1, 
              backgroundColor: isDarkMode ? 'rgba(59, 130, 246, 0.1)' : 'rgba(96, 165, 250, 0.1)' 
            }}
            transition={{ type: "spring", damping: 15 }}
          />
        ))}
      </div>
    </div>
  );
});

const initialFormData: RegisterFormData = {
  name: '',
  email: '',
  password: '',
  role: '',
  experience: '',
  
};

const initialStrength: PasswordStrength = {
  score: 0,
  hasLength: false,
  hasUpperCase: false,
  hasLowerCase: false,
  hasNumber: false,
  hasSpecial: false
};

const AuthHeader: React.FC = () => {
  const { isDarkMode, toggleDarkMode } = useTheme();
  
  return (
    <nav className="container mx-auto px-6 py-4">
      <div className="flex items-center mt-16 justify-between">
        <Link to="/" className="flex items-center space-x-2">
        <ArrowLeft className={isDarkMode ? 'text-white' : 'text-gray-900'} size={20} />
            <p className={`text-sm font-bold ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Back to home
            </p>
        </Link>
        
          
      </div>
    </nav>
  );
};


const Register = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<RegisterFormData>(initialFormData);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [strength, setStrength] = useState<PasswordStrength>(initialStrength);

  const validatePassword = (password: string) => {
    const newStrength = {
      score: 0,
      hasLength: password.length >= 8,
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasNumber: /\d/.test(password),
      hasSpecial: /[!@#$%^&*(),.?":{}|<>]/.test(password)
    };
  
    let score = 0;
    if (newStrength.hasLength) score++;
    if (newStrength.hasUpperCase) score++;
    if (newStrength.hasLowerCase) score++;
    if (newStrength.hasNumber) score++;
    if (newStrength.hasSpecial) score++;
    
    newStrength.score = score;
    setStrength(newStrength);
    
    return score >= 4;
  };

  const validateStep = (stepNumber: number): boolean => {
    const newErrors: ValidationErrors = {};

    if (stepNumber === 1) {
      if (!formData.email) newErrors.email = 'Email is required';
      if (!formData.password) {
        newErrors.password = 'Password is required';
      } else if (!validatePassword(formData.password)) {
        newErrors.password = 'Password does not meet requirements';
      }
      
    } else if (stepNumber === 2) {
      if (!formData.name) newErrors.name = 'Name is required';
      if (!formData.role) newErrors.role = 'Role is required';
      if (!formData.experience) newErrors.experience = 'Experience level is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (name === 'password') {
      validatePassword(value);
    }
  };

  const handleContinue = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateStep(1)) {
      return;
    }

   
        setStep(2);
      
        
     
    
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!validateStep(2)) {
      return;
    }
  
    setIsSubmitting(true);
    // In handleSubmit function
    try {
      // Register the user
      await authService.register({
        name: formData.name,
        email: formData.email,
        password: formData.password,
        role: formData.role as TradingRole,
        experience: formData.experience as ExperienceLevel,
      });
      
      // After registration, log the user in to establish the session properly
      await authService.login({
        email: formData.email,
        password: formData.password
      });
      
      // Force a full page reload and navigate to dashboard
      window.location.href = '/dashboard';
    } catch (error) {

      setErrors({
        general: error instanceof Error ? error.message : 'Registration failed'
      });
      setIsSubmitting(false);
    }
  };

  const renderPasswordStrength = () => (
    <div className="mt-2 space-y-2">
      <div className="flex gap-2">
        {[1, 2, 3, 4, 5].map((index) => (
          <div
            key={index}
            className={`h-2 w-full rounded ${
              index <= strength.score
                ? strength.score >= 4
                  ? 'bg-green-500'
                  : strength.score >= 3
                  ? 'bg-yellow-500'
                  : 'bg-red-500'
                : 'bg-gray-200 dark:bg-gray-700'
            }`}
          />
        ))}
      </div>
      <div className="grid grid-cols-2 gap-2 text-sm">
        <div className="flex items-center gap-1">
          <CheckCircle2 className={`h-4 w-4 ${strength.hasLength ? 'text-green-500' : 'text-gray-400'}`} />
          <span className={strength.hasLength ? 'text-green-500' : 'text-gray-500'}>8+ characters</span>
        </div>
        <div className="flex items-center gap-1">
          <CheckCircle2 className={`h-4 w-4 ${strength.hasUpperCase ? 'text-green-500' : 'text-gray-400'}`} />
          <span className={strength.hasUpperCase ? 'text-green-500' : 'text-gray-500'}>Uppercase</span>
        </div>
        <div className="flex items-center gap-1">
          <CheckCircle2 className={`h-4 w-4 ${strength.hasLowerCase ? 'text-green-500' : 'text-gray-400'}`} />
          <span className={strength.hasLowerCase ? 'text-green-500' : 'text-gray-500'}>Lowercase</span>
        </div>
        <div className="flex items-center gap-1">
          <CheckCircle2 className={`h-4 w-4 ${strength.hasNumber ? 'text-green-500' : 'text-gray-400'}`} />
          <span className={strength.hasNumber ? 'text-green-500' : 'text-gray-500'}>Number</span>
        </div>
      </div>
    </div>
  );

  return (
   
 
    
    <div className={`w-full font-heading tracking-tighter max-w-md p-8 rounded-2xl shadow-xl ${
      isDarkMode ? 'bg-gray-800' : 'bg-white'
    }`}>
      <div className="text-center">
      <div className="w-[150px] h-[150px] mb-[1rem] mx-auto">
             <Spline
               scene="https://prod.spline.design/Doc7YlUmwCnGu0M5/scene.splinecode"
             />
           </div>
          
        <h2 className={`text-3xl font-bold ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          {step === 1 ? 'Create your free account' : 'Complete your profile'}
        </h2>
        <p className={`mt-2 mb-3 text-sm ${
          isDarkMode ? 'text-gray-400' : 'text-gray-600'
        }`}>
          {step === 1 
                       ? 'Start exploring AI-powered market insights with Prism'
            : 'Tell us about yourself to personalize your experience'
          }
        </p>
      </div>

      {/* Progress Steps */}
      <div className="mb-8">
        <div className="flex justify-between">
          {[1, 2].map((stepNumber) => (
            <div
              key={stepNumber}
              className={`flex items-center ${stepNumber === 2 ? 'flex-1 ml-2' : 'flex-1'}`}
            >
              <div className={`relative flex items-center justify-center w-8 h-8 rounded-full border-2 ${
                step >= stepNumber
                  ? 'border-blue-500 bg-blue-500 text-white'
                  : isDarkMode
                    ? 'border-gray-600 text-gray-400'
                    : 'border-gray-300 text-gray-500'
              }`}>
                {step > stepNumber ? (
                  <CheckCircle2 className="w-5 h-5" />
                ) : (
                  <span>{stepNumber}</span>
                )}
              </div>
              {stepNumber === 1 && (
                <div className={`flex-1 h-0.5 mx-4 ${
                  step > 1
                    ? 'bg-blue-500'
                    : isDarkMode
                      ? 'bg-gray-600'
                      : 'bg-gray-300'
                }`} />
              )}
            </div>
          ))}
        </div>
      </div>

      {errors.general && (
        <div className="mb-6 p-3 rounded-lg bg-red-50 border border-red-200 text-red-600 flex items-center space-x-2">
          <AlertCircle className="w-5 h-5" />
          <span className="text-sm">{errors.general}</span>
        </div>
      )}

      <form onSubmit={step === 1 ? handleContinue : handleSubmit} className="space-y-6">
        {step === 1 ? (
          <>
            {/* Step 1: Account Creation */}
            <div>
              <label className={`block text-sm font-medium mb-1.5 ${
                isDarkMode ? 'text-gray-200' : 'text-gray-700'
              }`}>
                Email address
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className={`h-5 w-5 ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`} />
                </div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`block w-full pl-10 pr-3 py-2 rounded-lg border ${
                    isDarkMode
                      ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                      : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                  } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                  placeholder="you@example.com"
                />
              </div>
              {errors.email && (
                <p className="mt-1.5 text-sm text-red-500">{errors.email}</p>
              )}
            </div>

            <div>
              <label className={`block text-sm font-medium mb-1.5 ${
                isDarkMode ? 'text-gray-200' : 'text-gray-700'
              }`}>
                Password
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className={`h-5 w-5 ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`} />
                </div>
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className={`block w-full pl-10 pr-10 py-2 rounded-lg border ${
                    isDarkMode
                      ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                      : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                  } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                  placeholder="••••••••"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                >
                  {showPassword ? (
                    <EyeOff className="h-5 w-5 text-gray-400" />
                  ) : (
                    <Eye className="h-5 w-5 text-gray-400" />
                  )}
                </button>
              </div>
              {renderPasswordStrength()}
              {errors.password && (
                <p className="mt-1.5 text-sm text-red-500">{errors.password}</p>
              )}
            </div>

            
          </>
        ) : (
          <>
            {/* Step 2: Profile Information */}
            <div>
              <label className={`block text-sm font-medium mb-1.5 ${
                isDarkMode ? 'text-gray-200' : 'text-gray-700'
              }`}>
                Full name
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User className={`h-5 w-5 ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`} />
                </div>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className={`block w-full pl-10 pr-3 py-2 rounded-lg border ${
                    isDarkMode
                      ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                      : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                  } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                  placeholder="John Doe"
                />
              </div>
              {errors.name && (
                <p className="mt-1.5 text-sm text-red-500">{errors.name}</p>
              )}
            </div>

            <div>
              <label className={`block text-sm font-medium mb-1.5 ${
                isDarkMode ? 'text-gray-200' : 'text-gray-700'
              }`}>
                Trading role
              </label>
              <select
                name="role"
                value={formData.role}
                onChange={handleChange}
                className={`block w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? 'bg-gray-700 border-gray-600 text-white'
                    : 'bg-white border-gray-300 text-gray-900'
                } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
              >
                <option value="">Select your role</option>
                <option value="INDIVIDUAL">Individual Trader</option>
                <option value="PROFESSIONAL">Professional Trader</option>
                <option value="INSTITUTIONAL">Institutional Trader</option>
                <option value="DEVELOPER">Trading Developer</option>
                <option value="ANALYST">Market Analyst</option>
              </select>
              {errors.role && (
                <p className="mt-1.5 text-sm text-red-500">{errors.role}</p>
              )}
            </div>

            <div>
              <label className={`block text-sm font-medium mb-1.5 ${
                isDarkMode ? 'text-gray-200' : 'text-gray-700'
              }`}>
                Trading experience
              </label>
              <select
                name="experience"
                value={formData.experience}
                onChange={handleChange}
                className={`block w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? 'bg-gray-700 border-gray-600 text-white'
                    : 'bg-white border-gray-300 text-gray-900'
                } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
              >
                <option value="">Select your experience</option>
                <option value="BEGINNER">Beginner (0-2 years)</option>
                <option value="INTERMEDIATE">Intermediate (2-5 years)</option>
                <option value="ADVANCED">Advanced (5-10 years)</option>
                <option value="EXPERT">Expert (10+ years)</option>
              </select>
              {errors.experience && (
                <p className="mt-1.5 text-sm text-red-500">{errors.experience}</p>
              )}
            </div>
          </>
        )}

        <div className="space-y-4">
          {step === 1 ? (
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full flex items-center justify-center px-4 py-2.5 border border-transparent text-sm font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              Continue
              <ChevronRight className="ml-2 h-5 w-5" />
            </button>
          ) : (
            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => setStep(1)}
                className={`flex-1 flex items-center justify-center px-4 py-2.5 border text-sm font-medium rounded-lg ${
                  isDarkMode
                    ? 'border-gray-600 text-gray-300 hover:bg-gray-700'
                    : 'border-gray-300 text-gray-700 hover:bg-gray-50'
                }`}
              >
                <ArrowLeft className="mr-2 h-5 w-5" />
                Back
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="flex-1 flex items-center justify-center px-4 py-2.5 border border-transparent text-sm font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {isSubmitting ? (
                  <Loader2 className="w-5 h-5 animate-spin" />
                ) : (
                  <>
                    Complete Sign Up
                    <ChevronRight className="ml-2 h-5 w-5" />
                  </>
                )}
              </button>
            </div>
          )}
        </div>

        

        <p className="mt-6 text-center text-sm text-gray-500">
          Already have an account?{' '}
          <Link
            to="/login"
            className="font-medium text-blue-600 hover:text-blue-500"
          >
            Sign in
          </Link>
        </p>
      </form>
    </div>
  );
};

export const RegisterForm: React.FC = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="relative min-h-screen min-w-screen w-full font-heading tracking-tighter">
      {/* Spline 3D background */}
      <div className="absolute inset-0">
        <Spline
          scene="https://prod.spline.design/1PqVYx7rFIWocw7K/scene.splinecode" 
          className='w-full h-full z-0'
        />
      </div>

      {/* Overlay gradient */}
      <div className="absolute z-10 inset-0 bg-gradient-to-b from-transparent via-black/20 to-black/40" />
      
      {/* Radial gradient */}
      <div className="absolute inset-0">
        <div className="absolute z-15 inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent blur-3xl" />
      </div>

      <FloatingElements />

      {/* Main content */}
      <div className="relative z-20 w-full">
        <AuthHeader />
        
        <div className="flex flex-col items-center justify-center w-full mb-32">
          <Register />
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
