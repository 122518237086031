import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Brain, 
  Sparkles, 
  Database, 
  Target, 
  LineChart, 
  ArrowRight, 
  MoveRight,
  Lightbulb
} from 'lucide-react';
import { DocumentationLayout } from '../../components/layout/DocumentationLayout';
const ResearchModes = () => {
  const { isDarkMode } = useTheme();

  const modes = [
    {
      id: 'default',
      name: 'Standard',
      icon: Brain,
      description: 'Balanced research approach for general market inquiries',
      benefits: [
        'Comprehensive analysis balancing depth and breadth',
        'Suitable for most general market questions',
        'Efficient use of research credits'
      ],
      bestFor: 'General market questions and company research',
      example: `Tell me about Apple's latest earnings report`
    },
    {
      id: 'discovery',
      name: 'Discovery',
      icon: Sparkles,
      description: 'Explore new market opportunities and emerging trends',
      benefits: [
        'Wider search for emerging opportunities',
        'Focus on recent developments and trends',
        'Emphasis on forward-looking insights'
      ],
      bestFor: 'Finding new investment ideas and market trends',
      example: 'What emerging tech sectors are showing promise for 2024?'
    },
    {
      id: 'deep-dive',
      name: 'Deep Dive',
      icon: Database,
      description: 'Thorough analysis with comprehensive data examination',
      benefits: [
        'Extensive data gathering across multiple sources',
        'In-depth fundamental and technical analysis',
        'Historical context and detailed competitive landscape'
      ],
      bestFor: 'Thorough research before major investment decisions',
      example: 'Perform a complete analysis of Tesla including fundamentals, technicals, and competitive positioning'
    },
    {
      id: 'contrarian',
      name: 'Contrarian',
      icon: Target,
      description: 'Challenge market consensus and explore alternative views',
      benefits: [
        'Highlights risks and counterarguments to popular narratives',
        'Seeks out contradictory evidence and bearish perspectives',
        'Helps identify potential market blind spots'
      ],
      bestFor: 'Testing investment theses and managing risk',
      example: 'What are the bear cases against NVIDIA that most analysts are overlooking?'
    },
    {
      id: 'technical',
      name: 'Technical',
      icon: LineChart,
      description: 'Focus on charts, patterns, and technical indicators',
      benefits: [
        'Prioritizes technical analysis and chart patterns',
        'Examines volume, momentum, and historical price action',
        'Integrates multiple technical indicators for a complete picture'
      ],
      bestFor: 'Trading decisions based on technical signals',
      example: 'Analyze the technical setup for Bitcoin and identify key support/resistance levels'
    }
  ];

  return (
    <DocumentationLayout 
    title="Research Modes" 
    icon={Brain}
    description="Understand different research modes and how they affect Prism's analysis approach."
  >
      <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
        {/* Hero Section */}
        

        {/* Introduction */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-4 pb-16">
          <div className={`p-8 rounded-xl border ${
            isDarkMode ? 'bg-gray-800/50 border-gray-700' : 'bg-white border-gray-200'
          } mb-12`}>
            <h2 className={`text-2xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Understanding Research Modes
            </h2>
            <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Prism AI offers specialized research modes that customize how it approaches your market questions. Each mode adjusts the AI's research strategy, prioritizing different types of information and analytical approaches to best answer your specific question type.
            </p>
            <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Selecting the right research mode helps Prism focus its efforts for optimal results, making better use of your research credits while delivering more relevant insights.
            </p>
            <div className={`flex items-center p-4 rounded-lg ${
              isDarkMode ? 'bg-blue-900/20 border border-blue-800/30' : 'bg-blue-50 border border-blue-100'
            }`}>
              <Lightbulb className={`w-6 h-6 mr-3 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <p className={`text-sm ${isDarkMode ? 'text-blue-300' : 'text-blue-700'}`}>
                <strong>Pro Tip:</strong> When starting a new chat, you can specify a research mode by clicking the mode selector icon next to the input box. You can also change modes mid-conversation by typing commands like "/technical" or "/deep-dive".
              </p>
            </div>
          </div>

          {/* Research Modes */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Available Research Modes
          </h2>
          <div className="space-y-8 mb-16">
            {modes.map((mode) => {
              const Icon = mode.icon;
              return (
                <div key={mode.id} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-3 rounded-lg ${
                      isDarkMode ? 'bg-blue-900/20' : 'bg-blue-50'
                    } mr-4`}>
                      <Icon className={`w-6 h-6 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <h3 className={`text-xl font-bold ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {mode.name} Mode
                    </h3>
                  </div>
                  <p className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    {mode.description}
                  </p>
                  <div className="grid md:grid-cols-2 gap-6">
                    <div>
                      <h4 className={`font-medium mb-2 ${
                        isDarkMode ? 'text-gray-200' : 'text-gray-700'
                      }`}>
                        Key Benefits
                      </h4>
                      <ul className="space-y-2">
                        {mode.benefits.map((benefit, index) => (
                          <li key={index} className={`flex items-start ${
                            isDarkMode ? 'text-gray-300' : 'text-gray-600'
                          }`}>
                            <MoveRight className="w-5 h-5 mr-2 flex-shrink-0 text-blue-500" />
                            <span>{benefit}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <h4 className={`font-medium mb-2 ${
                        isDarkMode ? 'text-gray-200' : 'text-gray-700'
                      }`}>
                        Best Used For
                      </h4>
                      <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                        {mode.bestFor}
                      </p>
                      <h4 className={`font-medium mb-2 ${
                        isDarkMode ? 'text-gray-200' : 'text-gray-700'
                      }`}>
                        Example Query
                      </h4>
                      <div className={`p-3 rounded-lg font-mono text-sm ${
                        isDarkMode ? 'bg-gray-900 text-gray-300' : 'bg-gray-100 text-gray-800'
                      }`}>
                        {mode.example}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* How to Select a Mode */}
          <div className={`p-8 rounded-xl border ${
            isDarkMode ? 'bg-gray-800/50 border-gray-700' : 'bg-white border-gray-200'
          } mb-12`}>
            <h2 className={`text-2xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              How to Select a Research Mode
            </h2>
            <div className="space-y-6">
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Method 1: Mode Selector
                </h3>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  When starting a new chat, click the mode icon (brain symbol) to the left of the input field to open the mode selector. Choose your preferred research mode before sending your first message.
                </p>
              </div>
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Method 2: Command Shortcut
                </h3>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  During an active conversation, you can switch modes by typing a command:
                </p>
                <ul className="space-y-2 mb-4">
                  <li className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    <code className={`px-2 py-1 rounded ${
                      isDarkMode ? 'bg-gray-900' : 'bg-gray-100'
                    }`}>/standard</code> - Switch to Standard mode
                  </li>
                  <li className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    <code className={`px-2 py-1 rounded ${
                      isDarkMode ? 'bg-gray-900' : 'bg-gray-100'
                    }`}>/discovery</code> - Switch to Discovery mode
                  </li>
                  <li className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    <code className={`px-2 py-1 rounded ${
                      isDarkMode ? 'bg-gray-900' : 'bg-gray-100'
                    }`}>/deep-dive</code> - Switch to Deep Dive mode
                  </li>
                  <li className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    <code className={`px-2 py-1 rounded ${
                      isDarkMode ? 'bg-gray-900' : 'bg-gray-100'
                    }`}>/contrarian</code> - Switch to Contrarian mode
                  </li>
                  <li className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    <code className={`px-2 py-1 rounded ${
                      isDarkMode ? 'bg-gray-900' : 'bg-gray-100'
                    }`}>/technical</code> - Switch to Technical mode
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          {/* Credit Usage Note */}
          
        </div>
      </div>
    </DocumentationLayout>
  );
};

export default ResearchModes;