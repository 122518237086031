// src/pages/settings/components/handlers.ts
import { useState } from 'react';
import { authService } from '../../../services/authService';

export const useProfileUpdate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleProfileUpdate = async (data: { name: string; email: string }) => {
    setIsLoading(true);
    setError(null);
    setSuccess(null);

    try {
      await authService.updateProfile(data);
      setSuccess('Profile updated successfully. You may need to refresh to see changes.');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update profile');
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, success, handleProfileUpdate };
};

export const usePasswordUpdate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handlePasswordUpdate = async (data: { 
    currentPassword: string; 
    newPassword: string;
    confirmPassword: string;
  }) => {
    setIsLoading(true);
    setError(null);
    setSuccess(null);

    if (data.newPassword !== data.confirmPassword) {
      setError('Passwords do not match');
      setIsLoading(false);
      return;
    }

    try {
      await authService.updatePassword(data.currentPassword, data.newPassword);
      setSuccess('Password updated successfully');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update password');
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, success, handlePasswordUpdate };
};

export const usePhoneVerification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [verificationId, setVerificationId] = useState<string | null>(null);
  const [showVerificationInput, setShowVerificationInput] = useState(false);

  const startVerification = async (phoneNumber: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const { verificationId } = await authService.verifyPhone(phoneNumber);
      setVerificationId(verificationId);
      setShowVerificationInput(true);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to start verification');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmVerification = async (code: string) => {
    if (!verificationId) return;

    setIsLoading(true);
    setError(null);

    try {
      await authService.confirmPhoneVerification(verificationId, code);
      setSuccess('Phone number verified successfully! 20 credits added to your account.');
      setShowVerificationInput(false);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to confirm verification');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    success,
    showVerificationInput,
    startVerification,
    confirmVerification
  };
};