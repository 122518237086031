import React from 'react';
import ReactDOM from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { X, CheckCircle2, Star, Zap, ArrowRight } from 'lucide-react';
import { Brain, Sparkles, Database, Target, LineChart } from 'lucide-react';

interface ResearchModeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (mode: any) => void;
  selectedMode: any;
  isDarkMode: boolean;
  style?: React.CSSProperties;
}

const researchModeDetails = [
  {
    id: 'default',
    name: 'Standard Research',
    icon: Brain,
    description: 'Balanced research approach for general market inquiries',
    longDescription: `The Standard Research mode provides a balanced analytical approach, combining fundamental and technical factors. It's ideal for getting comprehensive market insights and is suitable for both beginners and experienced investors.`,
    bestFor: [
      'General market research',
      'Company overviews',
      'Basic investment decisions',
      'Understanding market trends'
    ],
    features: [
      'Balanced analysis of multiple factors',
      'Clear, straightforward insights',
      'Comprehensive market context',
      'Easy-to-understand explanations'
    ],
    color: 'blue',
  },
  {
    id: 'discovery',
    name: 'Discovery Mode',
    icon: Sparkles,
    description: 'Explore new market opportunities and emerging trends',
    longDescription: 'Discovery Mode helps you uncover hidden opportunities and emerging trends in the market. It focuses on identifying potential growth areas and innovative companies that might be flying under the radar.',
    bestFor: [
      'Finding new investment opportunities',
      'Exploring emerging sectors',
      'Identifying market trends early',
      'Understanding innovative companies'
    ],
    features: [
      'Trend analysis and prediction',
      'Innovation tracking',
      'Growth potential assessment',
      'Alternative data analysis'
    ],
    color: 'purple',
  },
  {
    id: 'deep-dive',
    name: 'Deep Dive Analysis',
    icon: Database,
    description: 'Thorough analysis with comprehensive data examination',
    longDescription: 'Deep Dive mode provides exhaustive analysis using comprehensive data sets. It\'s designed for thorough research that considers multiple angles and data points for more informed decision-making.',
    bestFor: [
      'Detailed company analysis',
      'Complex market situations',
      'Long-term investment research',
      'Risk assessment'
    ],
    features: [
      'Comprehensive data analysis',
      'Multiple perspective evaluation',
      'Historical trend analysis',
      'Detailed risk assessment'
    ],
    color: 'green',
  },
  {
    id: 'contrarian',
    name: 'Contrarian Analysis',
    icon: Target,
    description: 'Challenge market consensus and explore alternative views',
    longDescription: 'Contrarian Analysis mode helps you think differently about market consensus. It identifies potential flaws in common assumptions and explores alternative viewpoints for better-informed decisions.',
    bestFor: [
      'Challenging popular opinions',
      'Finding overlooked opportunities',
      'Risk mitigation',
      'Understanding market biases'
    ],
    features: [
      'Consensus analysis',
      'Alternative viewpoint exploration',
      'Bias identification',
      'Counter-trend opportunities'
    ],
    color: 'orange',
  },
  {
    id: 'technical',
    name: 'Technical Analysis',
    icon: LineChart,
    description: 'Focus on technical indicators and chart patterns',
    longDescription: `Technical Analysis mode concentrates on price movements, patterns, and technical indicators. It's perfect for traders and investors who rely on chart analysis and technical signals.`,
    bestFor: [
      'Chart pattern analysis',
      'Trading decisions',
      'Market timing',
      'Trend identification'
    ],
    features: [
      'Pattern recognition',
      'Technical indicator analysis',
      'Price movement study',
      'Volume analysis'
    ],
    color: 'cyan',
  }
];

export const ResearchModeModal: React.FC<ResearchModeModalProps> = ({
  isOpen,
  onClose,
  onSelect,
  selectedMode,
  isDarkMode
}) => {
  const getColorClasses = (mode: typeof researchModeDetails[0], isSelected: boolean) => {
    const colorMap = {
      blue: {
        selected: isDarkMode 
          ? 'bg-blue-900/20 border-blue-500/50 shadow-[0_0_20px_rgba(59,130,246,0.25)]' 
          : 'bg-blue-50/90 border-blue-400/50 shadow-lg',
        icon: isDarkMode 
          ? 'bg-blue-900/50 text-blue-300 ring-1 ring-blue-500/40' 
          : 'bg-blue-100/90 text-blue-600 ring-1 ring-blue-300/70',
        glow: 'from-blue-400 via-blue-500 to-blue-600',
        dot: 'bg-blue-500',
        text: isDarkMode ? 'text-blue-300/90' : 'text-blue-700/90'
      },
      purple: {
        selected: isDarkMode 
          ? 'bg-purple-900/20 border-purple-500/50 shadow-[0_0_20px_rgba(168,85,247,0.25)]' 
          : 'bg-purple-50/90 border-purple-400/50 shadow-lg',
        icon: isDarkMode 
          ? 'bg-purple-900/50 text-purple-300 ring-1 ring-purple-500/40' 
          : 'bg-purple-100/90 text-purple-600 ring-1 ring-purple-300/70',
        glow: 'from-purple-400 via-purple-500 to-purple-600',
        dot: 'bg-purple-500',
        text: isDarkMode ? 'text-purple-300/90' : 'text-purple-700/90'
      },
      green: {
        selected: isDarkMode 
          ? 'bg-green-900/20 border-green-500/50 shadow-[0_0_20px_rgba(34,197,94,0.25)]' 
          : 'bg-green-50/90 border-green-400/50 shadow-lg',
        icon: isDarkMode 
          ? 'bg-green-900/50 text-green-300 ring-1 ring-green-500/40' 
          : 'bg-green-100/90 text-green-600 ring-1 ring-green-300/70',
        glow: 'from-green-400 via-green-500 to-green-600',
        dot: 'bg-green-500',
        text: isDarkMode ? 'text-green-300/90' : 'text-green-700/90'
      },
      orange: {
        selected: isDarkMode 
          ? 'bg-orange-900/20 border-orange-500/50 shadow-[0_0_20px_rgba(249,115,22,0.25)]' 
          : 'bg-orange-50/90 border-orange-400/50 shadow-lg',
        icon: isDarkMode 
          ? 'bg-orange-900/50 text-orange-300 ring-1 ring-orange-500/40' 
          : 'bg-orange-100/90 text-orange-600 ring-1 ring-orange-300/70',
        glow: 'from-orange-400 via-orange-500 to-orange-600',
        dot: 'bg-orange-500',
        text: isDarkMode ? 'text-orange-300/90' : 'text-orange-700/90'
      },
      cyan: {
        selected: isDarkMode 
          ? 'bg-cyan-900/20 border-cyan-500/50 shadow-[0_0_20px_rgba(6,182,212,0.25)]' 
          : 'bg-cyan-50/90 border-cyan-400/50 shadow-lg',
        icon: isDarkMode 
          ? 'bg-cyan-900/50 text-cyan-300 ring-1 ring-cyan-500/40' 
          : 'bg-cyan-100/90 text-cyan-600 ring-1 ring-cyan-300/70',
        glow: 'from-cyan-400 via-cyan-500 to-cyan-600',
        dot: 'bg-cyan-500',
        text: isDarkMode ? 'text-cyan-300/90' : 'text-cyan-700/90'
      }
    };

    const color = mode.color || 'blue';
    return isSelected ? colorMap[color as keyof typeof colorMap] : {
      selected: isDarkMode 
        ? 'border-gray-700/50 bg-gray-800/40 hover:border-gray-600' 
        : 'border-gray-200 bg-white/80 hover:border-gray-300',
      icon: isDarkMode
        ? 'bg-gray-700/80 text-gray-300'
        : 'bg-gray-100 text-gray-600',
      glow: '',
      dot: isDarkMode ? 'bg-gray-600' : 'bg-gray-400',
      text: isDarkMode ? 'text-gray-400' : 'text-gray-500'
    };
  };

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={`fixed inset-0 z-[9999] flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm ${
            isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
          } transition-opacity duration-300`}
        >
          {/* Backdrop */}
          <motion.div 
            initial={{ backdropFilter: 'blur(0px)', backgroundColor: 'rgba(0,0,0,0)' }}
            animate={{ backdropFilter: 'blur(10px)', backgroundColor: 'rgba(0,0,0,0.7)' }}
            exit={{ backdropFilter: 'blur(0px)', backgroundColor: 'rgba(0,0,0,0)' }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0" 
            onClick={onClose}
          />

          {/* Modal content - Reduced size */}
          <motion.div
            initial={{ scale: 0.95, opacity: 0, y: 20 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            exit={{ scale: 0.95, opacity: 0, y: 20 }}
            transition={{ 
              type: "spring", 
              duration: 0.5,
              stiffness: 350,
              damping: 25 
            }}
            className="relative w-full max-w-4xl mx-auto my-8 p-1.5 z-10" // Reduced from max-w-5xl
          >
            {/* Glass container with subtle gradient - added isolation to prevent blur effects from affecting text */}
            <div className={`relative w-full rounded-2xl shadow-2xl overflow-hidden isolation ${
              isDarkMode 
                ? 'bg-gradient-to-br from-gray-900/95 to-gray-800/95 backdrop-blur-md border border-gray-700/50' 
                : 'bg-gradient-to-br from-white/98 to-gray-50/98 backdrop-blur-md border border-gray-200/50'
            }`}>
              {/* Design elements - contained better with overflow-hidden */}
              <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-500 via-purple-500 to-cyan-500 opacity-80"></div>
              
              {/* Reduced blur intensity on these decorative elements */}
              <div className={`absolute top-0 right-0 w-64 h-64 rounded-full blur-xl ${
                isDarkMode ? 'bg-blue-900/5' : 'bg-blue-200/10'
              }`}></div>
              
              <div className={`absolute bottom-0 left-0 w-64 h-64 rounded-full blur-xl ${
                isDarkMode ? 'bg-purple-900/5' : 'bg-purple-200/10'
              }`}></div>

              {/* Enhanced header with visual flair */}
              <div className={`p-6 border-b ${  // Reduced padding from p-8
                isDarkMode ? 'border-gray-800/70' : 'border-gray-200/70'
              }`}>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className={`p-2.5 rounded-xl mr-4 relative group ${  // Reduced padding and margin
                      isDarkMode 
                        ? 'bg-gradient-to-br from-blue-900/30 to-purple-900/30 border border-blue-500/20 shadow-lg shadow-blue-500/10' 
                        : 'bg-gradient-to-br from-blue-50 to-indigo-50 border border-blue-200/50 shadow-md'
                    }`}>
                      <Brain className={`w-6 h-6 ${  // Reduced icon size from w-7 h-7
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                      <motion.div 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1, repeat: Infinity, repeatType: "reverse" }}
                        className="absolute -inset-0.5 rounded-xl bg-gradient-to-r from-blue-400 to-purple-400 opacity-20"
                      ></motion.div>
                    </div>
                    <div>
                      <motion.h2 
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.1 }}
                        className={`text-xl font-bold tracking-tight ${  // Reduced text size from text-2xl
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}
                      >
                        Research Mode
                      </motion.h2>
                      <motion.p 
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className={`mt-1 text-sm ${  // Added text-sm
                          isDarkMode ? 'text-blue-300/80' : 'text-blue-700/70'
                        }`}
                      >
                        <span className="inline-flex items-center">
                          <Zap className="w-3.5 h-3.5 mr-1.5" />
                          Customize how Prism AI approaches your market research
                        </span>
                      </motion.p>
                    </div>
                  </div>
                  
                  {/* Fixed close button - Added z-index and made button larger */}
                  <button
                    onClick={onClose}
                    className={`p-2 rounded-lg transition-all hover:scale-105 z-10 ${
                      isDarkMode 
                        ? 'hover:bg-gray-800/80 text-gray-400 hover:text-gray-300 border border-gray-700/50' 
                        : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700 border border-gray-200/50'
                    }`}
                    style={{ cursor: 'pointer' }}  // Ensure cursor is a pointer
                  >
                    <X size={20} />
                  </button>
                </div>
              </div>

              {/* Main content with improved grid */}
              <div className="px-6 py-5">  {/* Reduced padding */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">  {/* Reduced gap */}
                  {researchModeDetails.map((mode, index) => {
                    const isSelected = selectedMode.id === mode.id;
                    const colorClasses = getColorClasses(mode, isSelected);
                    
                    return (
                      <motion.button
                        key={mode.id}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ 
                          opacity: 1, 
                          y: 0,
                          transition: { delay: 0.1 + (index * 0.05) }
                        }}
                        onClick={() => {
                          onSelect(mode);
                          onClose();
                        }}
                        className={`relative p-4 rounded-xl border transition-all h-full text-left ${  // Reduced padding
                          isSelected
                            ? colorClasses.selected
                            : colorClasses.selected
                        }`}
                        whileHover={{ 
                          scale: 1.02,
                          boxShadow: isSelected 
                            ? "0 8px 30px rgba(59, 130, 246, 0.3)" 
                            : isDarkMode 
                              ? "0 8px 20px rgba(0, 0, 0, 0.3)" 
                              : "0 8px 20px rgba(0, 0, 0, 0.1)" 
                        }}
                        whileTap={{ scale: 0.98 }}
                      >
                        {isSelected && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-purple-500/5 rounded-xl"
                          />
                        )}
                        
                        <div className="flex flex-col h-full relative z-10">
                          {/* Mode header with enhanced icon - added better z-index */}
                          <div className="flex items-start mb-4 relative z-20">  {/* Added explicit z-index */}
                            <div className={`p-2.5 rounded-lg relative ${colorClasses.icon}`}>  {/* Reduced padding */}
                              <mode.icon size={20} />  {/* Reduced icon size */}
                              {isSelected && (
                                <motion.div 
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  transition={{ 
                                    duration: 1.5, 
                                    repeat: Infinity, 
                                    repeatType: "reverse" 
                                  }}
                                  className="absolute -inset-1 rounded-lg bg-gradient-to-r from-blue-400/20 to-purple-400/20"
                                ></motion.div>
                              )}
                            </div>
                            <div className="ml-3 flex-1 relative z-20"> {/* Added explicit z-index */}
                              <h3 className={`font-medium text-base ${  // Reduced from text-lg
                                isDarkMode ? 'text-white' : 'text-gray-900'
                              }`}>
                                {mode.name}
                              </h3>
                              <p className={`text-xs mt-1 line-clamp-2 ${
                                isSelected
                                  ? isDarkMode ? 'text-blue-200/90' : 'text-blue-700/90'  // Increased opacity from /80 to /90
                                  : isDarkMode ? 'text-gray-300' : 'text-gray-700'        // Improved contrast
                              }`}>
                                {mode.description}
                              </p>
                            </div>
                            
                            {/* Enhanced selected indicator */}
                            {isSelected && (
                              <div className="ml-2">
                                <motion.div
                                  initial={{ scale: 0 }}
                                  animate={{ scale: 1 }}
                                  transition={{
                                    type: "spring",
                                    stiffness: 500,
                                    damping: 15,
                                  }}
                                >
                                  <CheckCircle2 className={`w-4 h-4 ${  // Reduced size
                                    isDarkMode ? 'text-blue-400' : 'text-blue-500'
                                  }`} />
                                </motion.div>
                              </div>
                            )}
                          </div>

                          {/* Features list - added explicit z-index */}
                          <div className="space-y-3 text-left mt-auto relative z-20">  {/* Added z-index */}
                            <div>
                              <h4 className={`text-xs font-bold uppercase tracking-wider mb-2 flex items-center ${colorClasses.text}`}>  {/* Reduced margin */}
                                <Star className="w-3 h-3 mr-1" />  {/* Smaller icon */}
                                Best For
                              </h4>
                              <ul className="space-y-1.5 pl-1">  {/* Reduced spacing */}
                                {mode.bestFor.slice(0, 3).map((item, index) => (
                                  <motion.li 
                                    key={index} 
                                    initial={{ opacity: 0, x: -10 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ delay: 0.3 + (index * 0.1) }}
                                    className={`flex items-center text-xs ${  // Reduced from text-sm
                                      isSelected
                                        ? isDarkMode ? 'text-blue-100' : 'text-blue-900'
                                        : isDarkMode ? 'text-gray-300' : 'text-gray-600'
                                    }`}
                                  >
                                    <span className={`w-1.5 h-1.5 rounded-full mr-2 ${colorClasses.dot}`} />  {/* Reduced margin */}
                                    {item}
                                  </motion.li>
                                ))}
                              </ul>
                            </div>
                          </div>

                          {/* Animated highlight effect */}
                          {isSelected && (
                            <>
                              <motion.div
                                initial={{ width: 0 }}
                                animate={{ width: '100%' }}
                                className="absolute bottom-0 left-0 right-0 h-[1px] bg-gradient-to-r from-transparent via-blue-500/40 to-transparent"
                              />
                            </>
                          )}
                        </div>
                      </motion.button>
                    );
                  })}
                </div>
              </div>

              {/* Footer */}
              <div className={`p-4 ${  // Reduced padding
                isDarkMode 
                  ? 'bg-gradient-to-b from-gray-800/0 to-gray-800/90 border-t border-gray-800/50' 
                  : 'bg-gradient-to-b from-gray-50/0 to-gray-50/90 border-t border-gray-200/50'
              }`}>
                <div className="flex items-center">
                  <div className={`p-1.5 rounded-lg ${  // Reduced padding
                    isDarkMode 
                      ? 'bg-gradient-to-br from-blue-900/40 to-purple-900/30 text-blue-300 border border-blue-500/20' 
                      : 'bg-gradient-to-br from-blue-100/80 to-purple-100/60 text-blue-600 border border-blue-200/30'
                  } mr-3`}>
                    <Sparkles className="w-3.5 h-3.5" />  {/* Reduced size */}
                  </div>
                  <p className={`text-xs ${  // Reduced text size
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    Change research modes anytime to adjust how Prism AI analyzes the market.
                  </p>
                </div>
                
                <div className="mt-3 flex justify-end">  {/* Reduced margin */}
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.97 }}
                    onClick={onClose}
                    className={`flex items-center px-4 py-1.5 rounded-lg ${  // Reduced padding
                      isDarkMode
                        ? 'bg-blue-600 hover:bg-blue-500 text-white'
                        : 'bg-blue-500 hover:bg-blue-600 text-white'
                    } transition-colors shadow-md text-sm`}  // Added text-sm
                  >
                    <span>Continue</span>
                    <ArrowRight className="w-3.5 h-3.5 ml-2" />  {/* Reduced size */}
                  </motion.button>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};