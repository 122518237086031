import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { 
  Coins, 
  Zap, 
  RefreshCw, 
  CreditCard, 
  LineChart, 
  AlertTriangle,
  CheckCircle,
  Archive,
  Clock,
  Infinity,
  Users,
  Monitor
} from 'lucide-react';
import { DocumentationLayout } from '../../components/layout/DocumentationLayout';

const SubscriptionDocs = () => {
  const { isDarkMode } = useTheme();

  return (
    <DocumentationLayout 
      title="Understanding Subscriptions" 
      icon={CreditCard}
      description="Learn how our subscription plans work and how to get the most out of your membership."
    >
      <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
        {/* What are Subscriptions */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 pb-16">
          <div className="grid md:grid-cols-3 gap-8">
            <div className="md:col-span-2">
              <h2 className={`text-2xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>Our Subscription Plans</h2>
              <div className={`prose max-w-none ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                <p>
                  We offer simple, straightforward subscription plans to provide you with unlimited access to our AI-driven market research capabilities. Our subscription model ensures you can research as much as you need without worrying about running out of credits.
                </p>
                <p>
                  With a subscription, you get access to:
                </p>
                <ul>
                  <li>Unlimited Prism chats for market analysis</li>
                  <li>Advanced chart types and indicators</li>
                  <li>Full historical market data</li>
                  <li>Comprehensive technical and fundamental insights</li>
                  <li>Canvas visualization tools (Pro plan)</li>
                </ul>
                <p>
                  Our subscription model is designed to provide predictable costs while ensuring the highest quality of service and continuous improvement of our AI capabilities.
                </p>
              </div>
            </div>
            <div>
              <div className={`p-6 rounded-xl ${
                isDarkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white shadow-md'
              }`}>
                <h3 className={`text-lg font-medium mb-4 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Plan Overview</h3>
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>
                      Free Plan
                    </span>
                    <span className="font-medium text-green-500">
                      $0/month
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>
                      Pro Plan
                    </span>
                    <span className="font-medium text-green-500">
                      $9.99/month
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-green-500">
                      Save 17% with annual billing
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Plan Features */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 bg-gradient-to-r from-blue-50 to-indigo-50 dark:from-gray-800/50 dark:to-gray-800/50 rounded-2xl">
          <h2 className={`text-2xl font-bold mb-10 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>Plan Features</h2>
          
          <div className="grid md:grid-cols-2 gap-8">
            <div className={`p-6 rounded-xl ${
              isDarkMode ? 'bg-gray-800/80 border border-gray-700' : 'bg-white shadow-sm'
            }`}>
              <div className="flex items-center mb-4">
                <div className={`w-10 h-10 rounded-full flex items-center justify-center ${
                  isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                }`}>
                  <Zap className={`w-5 h-5 ${
                    isDarkMode ? 'text-blue-400' : 'text-blue-600'
                  }`} />
                </div>
                <h3 className={`ml-3 text-lg font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Free Plan
                </h3>
              </div>
              <ul className={`space-y-2 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                <li className="flex items-start">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                  <span>Basic Prism functionality</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                  <span>5 Prism responses per day</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                  <span>Basic chart types only</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                  <span>Ask about any stock, ever</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                  <span>Community support</span>
                </li>
              </ul>
            </div>
            
            <div className={`p-6 rounded-xl ${
              isDarkMode ? 'bg-gray-800/80 border border-gray-700' : 'bg-white shadow-sm'
            }`}>
              <div className="flex items-center mb-4">
                <div className={`w-10 h-10 rounded-full flex items-center justify-center ${
                  isDarkMode ? 'bg-purple-500/20' : 'bg-purple-100'
                }`}>
                  <Monitor className={`w-5 h-5 ${
                    isDarkMode ? 'text-purple-400' : 'text-purple-600'
                  }`} />
                </div>
                <h3 className={`ml-3 text-lg font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Pro Plan
                </h3>
              </div>
              <ul className={`space-y-2 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                <li className="flex items-start">
                  <CheckCircle className="w-5 h-5 text-blue-500 mr-2 flex-shrink-0" />
                  <span>Unlimited Prism usage</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="w-5 h-5 text-blue-500 mr-2 flex-shrink-0" />
                  <span>Early access to Canvas (Beta)</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="w-5 h-5 text-blue-500 mr-2 flex-shrink-0" />
                  <span>All chart types & indicators</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="w-5 h-5 text-blue-500 mr-2 flex-shrink-0" />
                  <span>Full historical data</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="w-5 h-5 text-blue-500 mr-2 flex-shrink-0" />
                  <span>Unlimited saved chats</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="w-5 h-5 text-blue-500 mr-2 flex-shrink-0" />
                  <span>Priority email support</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Tips for Getting the Most from Your Subscription */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>Tips for Getting the Most from Your Subscription</h2>
          
          <div className="grid md:grid-cols-2 gap-8">
            <div className={`p-6 rounded-xl border ${
              isDarkMode ? 'bg-gray-800/70 border-gray-700' : 'bg-white border-gray-200'
            }`}>
              <h3 className={`text-xl font-medium mb-4 flex items-center ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                <CheckCircle className="w-5 h-5 mr-2 text-green-500" />
                Best Practices
              </h3>
              <ul className={`space-y-3 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">1.</span>
                  <span>Be specific with your research questions for the most relevant results</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">2.</span>
                  <span>Use advanced chart features to identify trends and patterns</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">3.</span>
                  <span>Save and categorize important chats for future reference</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">4.</span>
                  <span>Create watchlists for stocks you frequently research</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">5.</span>
                  <span>Utilize Canvas tools for comprehensive market analysis</span>
                </li>
              </ul>
            </div>
            
            <div className={`p-6 rounded-xl border ${
              isDarkMode ? 'bg-gray-800/70 border-gray-700' : 'bg-white border-gray-200'
            }`}>
              <h3 className={`text-xl font-medium mb-4 flex items-center ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                <AlertTriangle className="w-5 h-5 mr-2 text-yellow-500" />
                What to Avoid
              </h3>
              <ul className={`space-y-3 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">1.</span>
                  <span>Overly vague queries that require multiple clarification rounds</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">2.</span>
                  <span>Starting a new chat for each related question</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">3.</span>
                  <span>Ignoring chat history that may already have relevant information</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">4.</span>
                  <span>Uploading large files without trimming to relevant sections</span>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 mr-2">5.</span>
                  <span>Requesting multiple visualizations in separate queries</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Subscription Management */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="grid md:grid-cols-5 gap-8">
            <div className="md:col-span-3">
              <h2 className={`text-2xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>Subscription Management</h2>
              <div className={`prose max-w-none ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                <p>
                  Your subscription renews automatically based on your billing cycle - monthly or annually, depending on the option you've chosen at sign-up.
                </p>
                <p>
                  Annual subscribers enjoy a 17% discount compared to the monthly plan, making it the most cost-effective option for regular users.
                </p>
                <p>
                  You can manage your subscription at any time through your account settings page:
                </p>
                <ul>
                  <li>Upgrade from Free to Pro plan</li>
                  <li>Switch between monthly and annual billing</li>
                  <li>Update payment methods</li>
                  <li>Cancel your subscription</li>
                </ul>
                <p>
                  If you cancel your subscription, you'll continue to have access to your Pro features until the end of your current billing period.
                </p>
              </div>
            </div>
            <div className="md:col-span-2">
              <div className={`p-6 rounded-xl ${
                isDarkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white shadow-md'
              }`}>
                <div className="flex items-center mb-6">
                  <Clock className={`w-6 h-6 mr-3 ${
                    isDarkMode ? 'text-blue-400' : 'text-blue-600'
                  }`} />
                  <h3 className={`text-lg font-medium ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    Pro Benefits
                  </h3>
                </div>
                <ul className={`space-y-4 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-700'
                }`}>
                  <li className="flex items-start">
                    <span className={`inline-flex items-center justify-center w-5 h-5 rounded-full mr-3 text-xs ${
                      isDarkMode ? 'bg-green-500/20 text-green-400' : 'bg-green-100 text-green-600'
                    }`}>✓</span>
                    <span>Unlimited Prism usage</span>
                  </li>
                  <li className="flex items-start">
                    <span className={`inline-flex items-center justify-center w-5 h-5 rounded-full mr-3 text-xs ${
                      isDarkMode ? 'bg-green-500/20 text-green-400' : 'bg-green-100 text-green-600'
                    }`}>✓</span>
                    <span>All premium features unlocked</span>
                  </li>
                  <li className="flex items-start">
                    <span className={`inline-flex items-center justify-center w-5 h-5 rounded-full mr-3 text-xs ${
                      isDarkMode ? 'bg-green-500/20 text-green-400' : 'bg-green-100 text-green-600'
                    }`}>✓</span>
                    <span>Early access to new features</span>
                  </li>
                  <li className="flex items-start">
                    <span className={`inline-flex items-center justify-center w-5 h-5 rounded-full mr-3 text-xs ${
                      isDarkMode ? 'bg-green-500/20 text-green-400' : 'bg-green-100 text-green-600'
                    }`}>✓</span>
                    <span>No daily usage limits</span>
                  </li>
                  <li className="flex items-start">
                    <span className={`inline-flex items-center justify-center w-5 h-5 rounded-full mr-3 text-xs ${
                      isDarkMode ? 'bg-green-500/20 text-green-400' : 'bg-green-100 text-green-600'
                    }`}>✓</span>
                    <span>Priority email support</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Value Proposition */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-16">
          <div className={`p-6 rounded-xl ${
            isDarkMode ? 'bg-gray-800/70 border border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <h3 className={`text-xl font-medium mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>Why Go Pro?</h3>
              
            <div className="grid md:grid-cols-3 gap-6">
              <div className={`p-4 rounded-xl ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
                <Infinity className="w-5 h-5 text-blue-500 mb-2" />
                <h4 className={`font-medium mb-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Unlimited Research</h4>
                <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  No limits on how much you can research and analyze
                </p>
              </div>
              <div className={`p-4 rounded-xl ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
                <LineChart className="w-5 h-5 text-purple-500 mb-2" />
                <h4 className={`font-medium mb-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Advanced Charts</h4>
                <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  All chart types and technical indicators
                </p>
              </div>
              <div className={`p-4 rounded-xl ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
                <Users className="w-5 h-5 text-green-500 mb-2" />
                <h4 className={`font-medium mb-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Priority Support</h4>
                <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  Get help when you need it most
                </p>
              </div>
            </div>
                
            <div className="mt-6 p-4 rounded-xl bg-green-500/10 border border-green-500/20">
              <div className="flex items-start">
                <Zap className="w-5 h-5 text-green-500 mr-3 mt-0.5" />
                <div>
                  <h4 className="text-green-500 font-medium mb-1">Expert Analysis at Your Fingertips</h4>
                  <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                    A traditional financial consultant can cost $150-$300/hour. With our Pro plan at just $9.99/month, you get unlimited access to AI-powered market research that would cost thousands with traditional services. Upgrade today and transform your investment research workflow.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentationLayout>
  );
};

export default SubscriptionDocs;