import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  ChevronRight,
  LineChart,
  TrendingUp,
  TrendingDown,
  AlertCircle,
  Settings,
  BarChart2,
  Clock,
  Zap,
  ArrowUpRight,
  Palette,
  Maximize
} from 'lucide-react';

const Sparklines = () => {
  const { isDarkMode } = useTheme();

  const sparklineTypes = [
    {
      title: 'Price Sparklines',
      description: 'Compact line charts showing price movement over time.',
      features: [
        'Simple visualization of price trends',
        'Multiple timeframe options',
        'Colored based on performance (green/red)',
        'Optional moving average overlay',
        'Minimalist design for at-a-glance analysis'
      ],
      icon: LineChart
    },
    {
      title: 'Volume Sparklines',
      description: 'Compact bar charts displaying trading volume patterns.',
      features: [
        'Volume representation with tiny bars',
        'Relative volume comparison',
        'Unusual volume highlighting',
        'Pre/post market volume indicators',
        'Volume trend identification'
      ],
      icon: BarChart2
    },
    {
      title: 'Performance Sparklines',
      description: 'Visual representation of performance relative to benchmarks.',
      features: [
        'Comparison to indices (S&P 500, Nasdaq, etc.)',
        'Sector performance comparison',
        'Peer group relative performance',
        'Divergence/convergence visualization',
        'YTD, MTD, and custom period options'
      ],
      icon: TrendingUp
    },
    {
      title: 'Volatility Sparklines',
      description: 'Quick visualization of price volatility patterns.',
      features: [
        'Historical volatility representation',
        'Options-derived implied volatility',
        'Volatility spikes identification',
        'Calm periods identification',
        'Comparison to historical averages'
      ],
      icon: Zap
    }
  ];

  const customizationOptions = [
    {
      title: 'Time Range Selection',
      description: 'Choose the time period for your sparklines',
      options: [
        'Intraday (1m, 5m, 15m, 1h)',
        'Daily (1D, 5D, 1M)',
        'Weekly (1W, 1M, 3M)',
        'Long-term (6M, YTD, 1Y, 5Y)'
      ],
      icon: Clock
    },
    {
      title: 'Visual Styling',
      description: 'Customize the appearance of your sparkline charts',
      options: [
        'Line thickness adjustment',
        'Color scheme selection',
        'Area fill options (on/off, opacity)',
        'Dark/light mode specific styling',
        'Custom threshold coloring'
      ],
      icon: Palette
    },
    {
      title: 'Size and Density',
      description: 'Adjust how much space your sparklines occupy',
      options: [
        'Micro size for high-density watchlists',
        'Standard size for balanced visibility',
        'Expanded size for detailed analysis',
        'Auto-adjusting based on screen size'
      ],
      icon: Maximize
    }
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <LineChart className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Sparkline Charts
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Compact visual representations of stock data patterns
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              Sparklines are miniature charts that provide a simple, condensed visual representation of data trends.
              In Prism, sparklines allow you to quickly assess a stock's performance, volatility, or volume patterns
              without needing to open a full chart. They're particularly useful when managing large watchlists or
              comparing multiple securities at once.
            </p>
          </div>

          {/* Sparkline Types */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Types of Sparklines
          </h2>

          <div className="space-y-8 mb-16">
            {sparklineTypes.map((type, index) => {
              const Icon = type.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    } mr-4`}>
                      <Icon className={`w-6 h-6 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <div>
                      <h3 className={`text-xl font-bold ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {type.title}
                      </h3>
                    </div>
                  </div>
                  
                  <p className={`mb-6 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {type.description}
                  </p>
                  
                  <div className="grid md:grid-cols-2 gap-8">
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Features:</h4>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {type.features.map((feature, i) => (
                          <li key={i} className="flex items-start">
                            <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div className={`rounded-lg overflow-hidden border ${
                      isDarkMode ? 'border-gray-700' : 'border-gray-200'
                    }`}>
                      <div className={`h-48 flex items-center justify-center ${
                        isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                      }`}>
                        <p className={`text-sm ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-500'
                        }`}>
                          Illustration of {type.title.toLowerCase()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Customization Options */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Customization Options
          </h2>

          <div className="grid md:grid-cols-3 gap-6 mb-12">
            {customizationOptions.map((option, index) => {
              const Icon = option.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg mr-4 ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    }`}>
                      <Icon className={`w-5 h-5 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <h3 className={`font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {option.title}
                    </h3>
                  </div>
                  
                  <p className={`mb-4 text-sm ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {option.description}
                  </p>
                  
                  <ul className={`space-y-2 text-sm ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {option.options.map((opt, i) => (
                      <li key={i} className="flex items-start">
                        <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                        <span>{opt}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>

          {/* Implementation Tips */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="flex items-start">
              <AlertCircle className={`w-6 h-6 mt-1 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Best Practices for Using Sparklines</h3>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  Maximize the effectiveness of sparklines in your investment workflow:
                </p>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Match the sparkline time period to your investment horizon</span>
                  </li>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Use different sparkline types in parallel for comprehensive analysis</span>
                  </li>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>For long-term investments, focus on weekly or monthly patterns</span>
                  </li>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>For day trading, use intraday sparklines with volume indicators</span>
                  </li>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Click on any sparkline to expand it into a full chart for detailed analysis</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Technical Implementation */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            How Sparklines Work
          </h2>

          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Prism's sparklines are built using a high-performance rendering engine that efficiently displays 
              data points without compromising dashboard responsiveness. The implementation uses:
            </p>

            <ul className={`space-y-2 mb-4 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              <li className="flex items-start">
                <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                <span>Canvas-based rendering for optimal performance with large datasets</span>
              </li>
              <li className="flex items-start">
                <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                <span>Adaptive data sampling that maintains visual fidelity while reducing load</span>
              </li>
              <li className="flex items-start">
                <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                <span>Reactive scaling that adjusts to available space and data characteristics</span>
              </li>
              <li className="flex items-start">
                <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                <span>Cached data processing to minimize redundant calculations</span>
              </li>
            </ul>

            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              This approach ensures that you can have dozens of sparklines on a single dashboard without 
              experiencing performance degradation, even when tracking high-frequency data.
            </p>
          </div>

          {/* Related Documentation */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Related Documentation
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <a 
              href="/docs/stock-view"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Stock View Options
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Different ways to view and analyze your saved stocks
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
            
            <a
              href="/docs/annotations"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Chart Annotations
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Adding notes and markers to your chart visualizations
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Sparklines;