import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Check, Minus } from 'lucide-react';

const features = [
  {
    category: 'Prism (Market Chat)',
    features: [
      {
        name: 'Chat Sessions',
        free: '5 per month',
        pro: 'Unlimited'
      },
      {
        name: 'Market Data Access',
        free: 'Basic',
        pro: 'Full'
      },
      {
        name: 'Chart Types',
        free: 'Basic Only',
        pro: 'All Types'
      },
      {
        name: 'Technical Indicators',
        free: '5 Basic',
        pro: 'All'
      },
      {
        name: 'Historical Data',
        free: '1 Day',
        pro: 'Full History'
      }
    ]
  },
  {
    category: 'Features',
    features: [
      {
        name: 'Saved Chats',
        free: false,
        pro: true
      },
      {
        name: 'Custom Templates',
        free: false,
        pro: true
      },
      {
        name: 'Early Access to Canvas',
        free: false,
        pro: true
      },
      {
        name: 'Document Export',
        free: false,
        pro: true
      }
    ]
  },
  {
    category: 'Support',
    features: [
      {
        name: 'Community Support',
        free: true,
        pro: true
      },
      {
        name: 'Email Support',
        free: false,
        pro: 'Priority'
      }
    ]
  }
];

export const FeatureComparison: React.FC = () => {
  const { isDarkMode } = useTheme();

  const renderCell = (value: boolean | string) => {
    if (typeof value === 'boolean') {
      return value ? (
        <Check className="w-5 h-5 text-green-500 mx-auto" />
      ) : (
        <Minus className="w-5 h-5 text-gray-400 mx-auto" />
      );
    }
    return <span className="text-sm">{value}</span>;
  };

  return (
    <div className="mt-24 max-w-4xl mx-auto">
      <h2 className={`text-2xl font-bold text-center mb-12 ${
        isDarkMode ? 'text-white' : 'text-gray-900'
      }`}>
        Compare Features
      </h2>

      <div className={`rounded-xl overflow-hidden ${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      } shadow-lg`}>
        <table className="w-full">
          <thead>
            <tr className={isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}>
              <th className="py-4 px-6 text-left"></th>
              <th className="py-4 px-6 text-center">Free</th>
              <th className="py-4 px-6 text-center">Pro</th>
            </tr>
          </thead>
          <tbody>
            {features.map((section, sectionIndex) => (
              <React.Fragment key={section.category}>
                <tr className={isDarkMode ? 'bg-gray-700/50' : 'bg-gray-100'}>
                  <td
                    colSpan={3}
                    className={`py-3 px-6 font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}
                  >
                    {section.category}
                  </td>
                </tr>
                {section.features.map((feature, featureIndex) => (
                  <tr 
                    key={`${section.category}-${feature.name}`}
                    className={`
                      ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}
                      ${isDarkMode ? 'hover:bg-gray-700/50' : 'hover:bg-gray-50'}
                      border-t
                    `}
                  >
                    <td className={`py-4 px-6 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}>
                      {feature.name}
                    </td>
                    <td className="py-4 px-6 text-center">
                      {renderCell(feature.free)}
                    </td>
                    <td className="py-4 px-6 text-center">
                      {renderCell(feature.pro)}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};