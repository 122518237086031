import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../contexts/ThemeContext';
import { Globe, Menu, X, ChevronRight, ArrowUpRight, Check } from 'lucide-react';
import { CreditDisplay } from '../common/CreditDisplay';
import { useAuth } from '../../contexts/AuthContext';
import { useLanguage, LANGUAGES } from '../../contexts/LanguageContext';

export const MarketingHeader = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const { isAuthenticated, user } = useAuth();
  const { currentLanguage, setLanguage, t } = useLanguage();
  
  // Close language menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.language-selector') && isLanguageMenuOpen) {
        setIsLanguageMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isLanguageMenuOpen]);
  
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navigationItems = [
    { label: t('prism'), path: '/prism' },
    { label: t('canvas'), path: '/canvas' },
    { label: t('pro'), path: '/pricing' },
    { label: t('docs'), path: '/docs' },
    { label: t('help'), path: '/help' },
  ];

  return (
    <>
      <header className={`fixed font-heading tracking-tighter top-0 left-0 right-0 z-50 transition-all duration-200 bg-none ${
        isScrolled 
          ? isDarkMode 
            ? 'bg-black/80 backdrop-blur-lg border-b border-zinc-800'
            : 'bg-white/80 backdrop-blur-lg border-b border-gray-200'
          : ''
      }`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            {/* Logo */}
            <Link 
              to="/"
              className="flex items-center space-x-2"
            >
              <img
                src={isDarkMode ? '/logo-dark.png' : '/logo-light.png'}
                alt="Newsroom"
                className="h-10"
              />
            </Link>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex ml-[10.95rem] items-center space-x-8">
              {navigationItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`text-sm font-medium transition-colors ${
                    isDarkMode 
                      ? 'text-gray-300 hover:text-white' 
                      : 'text-gray-600 hover:text-gray-900'
                  }`}
                >
                  {item.label}
                </Link>
              ))}
            </nav>

            {/* Right Section */}
            <div className="flex items-center space-x-4">
              {/* Language Selector */}
              <div className="language-selector relative">
                <button
                  onClick={() => setIsLanguageMenuOpen(!isLanguageMenuOpen)}
                  className={`p-2 rounded-lg transition-colors flex items-center ${
                    isDarkMode 
                      ? 'hover:bg-zinc-800 text-gray-400 hover:text-white' 
                      : 'hover:bg-gray-100 text-gray-600 hover:text-gray-900'
                  }`}
                  aria-label={t('selectLanguage')}
                >
                  <Globe className="w-5 h-5" />
                  
                </button>
                
                <AnimatePresence>
                  {isLanguageMenuOpen && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      transition={{ duration: 0.2 }}
                      className={`absolute right-0 mt-2 w-48 rounded-md shadow-lg z-50 overflow-hidden ${
                        isDarkMode 
                          ? 'bg-gray-800 border border-gray-700' 
                          : 'bg-white border border-gray-200'
                      }`}
                    >
                      <div className={`py-1.5 px-3 border-b ${
                        isDarkMode ? 'border-gray-700 text-gray-300' : 'border-gray-200 text-gray-700'
                      } text-sm font-medium`}>
                        {t('selectLanguage')}
                      </div>
                      <div className="py-1 max-h-60 overflow-y-auto">
                        {LANGUAGES.map((language) => (
                          <button
                            key={language.code}
                            className={`w-full text-left px-4 py-2 flex items-center justify-between text-sm ${
                              isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'
                            } ${currentLanguage.code === language.code ? 'bg-blue-500/10' : ''}`}
                            onClick={() => {
                              setLanguage(language);
                              setIsLanguageMenuOpen(false);
                            }}
                          >
                            <div className="flex items-center">
                              <span className="mr-2 text-lg">{language.flag}</span>
                              <span>{language.name}</span>
                            </div>
                            {currentLanguage.code === language.code && (
                              <Check className="w-4 h-4 text-blue-500" />
                            )}
                          </button>
                        ))}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              {isAuthenticated ? (
                <>
                  {/* Launch Dashboard Button */}
                  <button
                    onClick={() => navigate('/dashboard')}
                    className={`hidden md:flex items-center px-4 py-2 rounded-lg transition-all ${
                      isDarkMode
                        ? 'hover:bg-zinc-800 text-gray-300 hover:text-white'
                        : 'hover:bg-gray-100 text-gray-700'
                    }`}
                  >
                    {t('launchDashboard')}
                    <ArrowUpRight className="w-4 h-4 ml-1" />
                  </button>
                  
                  {/* User Avatar */}
                  <div 
                    onClick={() => navigate('/dashboard/settings')}
                    className={`hidden md:flex cursor-pointer items-center justify-center w-8 h-8 rounded-full ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
                    }`}
                  >
                    <span className={isDarkMode ? 'text-white' : 'text-gray-700'}>
                      {user?.name?.[0]?.toUpperCase()}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  {/* Login Button */}
                  <button
                    onClick={() => navigate('/login')}
                    className={`hidden md:flex items-center px-4 py-2 rounded-lg transition-all ${
                      isDarkMode
                        ? 'hover:bg-zinc-800 text-gray-300 hover:text-white'
                        : 'hover:bg-gray-100 text-gray-700'
                    }`}
                  >
                    {t('login')}
                  </button>

                  {/* Get Started Button */}
                  <button
                    onClick={() => navigate('/register')}
                    className="hidden md:flex items-center px-4 py-1.5 rounded-lg bg-blue-500 hover:bg-blue-600 text-white transition-colors"
                  >
                    {t('getStarted')}
                    <ChevronRight className="w-4 h-4 ml-1" />
                  </button>
                </>
              )}

              {/* Mobile Menu Toggle */}
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="md:hidden p-2 rounded-lg hover:bg-zinc-800 text-gray-400"
              >
                {isMobileMenuOpen ? (
                  <X className="w-6 h-6" />
                ) : (
                  <Menu className="w-6 h-6" />
                )}
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Menu */}
      <motion.div
        initial={false}
        animate={{
          height: isMobileMenuOpen ? 'auto' : 0,
          opacity: isMobileMenuOpen ? 1 : 0
        }}
        className={`md:hidden fixed top-16 left-0 right-0 z-40 overflow-hidden ${
          isDarkMode 
            ? 'bg-black/90 backdrop-blur-lg border-b border-zinc-800' 
            : 'bg-white/90 backdrop-blur-lg border-b border-gray-200'
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 py-4 space-y-4">
          {navigationItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`block text-sm font-medium transition-colors ${
                isDarkMode 
                  ? 'text-gray-300 hover:text-white' 
                  : 'text-gray-600 hover:text-gray-900'
              }`}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              {item.label}
            </Link>
          ))}
          
          {/* Language Selector in Mobile Menu */}
          <div className="pt-2 pb-2 border-t border-b border-gray-700/20">
            <p className={`text-xs mb-2 ${isDarkMode ? 'text-gray-500' : 'text-gray-500'}`}>
              {t('selectLanguage')}
            </p>
            <div className="grid grid-cols-3 gap-2">
              {LANGUAGES.map((language) => (
                <button
                  key={language.code}
                  onClick={() => {
                    setLanguage(language);
                  }}
                  className={`flex items-center justify-center px-3 py-2 rounded-lg text-sm ${
                    currentLanguage.code === language.code
                      ? isDarkMode
                        ? 'bg-blue-900/30 text-blue-400 border border-blue-800/50'
                        : 'bg-blue-50 text-blue-700 border border-blue-200'
                      : isDarkMode
                        ? 'hover:bg-gray-800 text-gray-300 border border-gray-700'
                        : 'hover:bg-gray-100 text-gray-700 border border-gray-200'
                  }`}
                >
                  <span className="mr-2">{language.flag}</span>
                  {language.code.toUpperCase()}
                </button>
              ))}
            </div>
          </div>
          
          <div className="pt-4 space-y-4">
            {isAuthenticated ? (
              <button
                onClick={() => {
                  navigate('/dashboard');
                  setIsMobileMenuOpen(false);
                }}
                className="w-full px-4 py-2 rounded-lg text-left font-medium bg-blue-500 hover:bg-blue-600 text-white transition-colors"
              >
                {t('launchDashboard')}
              </button>
            ) : (
              <>
                <button
                  onClick={() => {
                    navigate('/login');
                    setIsMobileMenuOpen(false);
                  }}
                  className="w-full px-4 py-2 rounded-lg text-left font-medium transition-colors hover:bg-zinc-800 text-gray-300"
                >
                  {t('login')}
                </button>
                <button
                  onClick={() => {
                    navigate('/register');
                    setIsMobileMenuOpen(false);
                  }}
                  className="w-full px-4 py-2 rounded-lg text-left font-medium bg-blue-500 hover:bg-blue-600 text-white transition-colors"
                >
                  {t('getStarted')}
                </button>
              </>
            )}
          </div>
        </div>
      </motion.div>
    </>
  );
};