import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Globe, 
  Database, 
  Newspaper, 
  BarChart2, 
  ChevronRight, 
  AlertCircle, 
  CheckCircle,
  XCircle,
  AlertTriangle,
  Clock,
  Filter
} from 'lucide-react';

const SourceTypes = () => {
  const { isDarkMode } = useTheme();

  const sourceTypes = [
    {
      id: 'WEB',
      name: 'Web Sources',
      icon: Globe,
      color: 'blue',
      description: 'Financial websites, analyst reports, company pages, and general information from trusted online sources.',
      examples: [
        'Financial news websites',
        'Company investor relations pages',
        'Analyst blogs and publications',
        'SEC filings and official documents',
        'Industry reports and whitepapers'
      ],
      strengths: [
        'Broad coverage of topics and companies',
        'Access to qualitative analysis and expert opinions',
        'Context for market movements and events',
        'Long-form explanations of complex topics'
      ],
      limitations: [
        'Variable quality and potential bias',
        'Information may be outdated',
        'Requires verification across multiple sources',
        'Opinion-based content might lack objective metrics'
      ]
    },
    {
      id: 'MARKET_DATA',
      name: 'Market Data',
      icon: Database,
      color: 'green',
      description: 'Real-time and historical price data, including trading volumes, market breadth metrics, and technical indicators.',
      examples: [
        'Real-time stock prices and trading volumes',
        'Historical price data and patterns',
        'Technical indicators (RSI, MACD, moving averages)',
        'Market-wide statistics and breadth indicators',
        'Options and derivatives data'
      ],
      strengths: [
        'Objective, quantitative information',
        'Highly accurate real-time data',
        'Long historical records for pattern analysis',
        'Mathematical precision for technical analysis'
      ],
      limitations: [
        'Lacks qualitative context',
        'Requires interpretation to be actionable',
        'Past patterns dont guarantee future performance',
        'May contain reporting delays or adjustments'
      ]
    },
    {
      id: 'NEWS',
      name: 'News & Events',
      icon: Newspaper,
      color: 'yellow',
      description: 'Current events, breaking news, earnings reports, and other time-sensitive information affecting markets.',
      examples: [
        'Breaking financial news',
        'Earnings announcements and results',
        'Merger & acquisition activity',
        'Regulatory changes and legal developments',
        'Macroeconomic data releases'
      ],
      strengths: [
        'Timely information that drives short-term moves',
        'Event-driven insights on specific companies',
        'Explanation of market reactions',
        'Coverage of unexpected developments'
      ],
      limitations: [
        'Initial reports may be incomplete or inaccurate',
        'Market impact can be overestimated',
        'Headline risk vs. substantive impact varies',
        'Subject to journalistic interpretation'
      ]
    },
    {
      id: 'FUNDAMENTAL',
      name: 'Fundamental Data',
      icon: BarChart2,
      color: 'purple',
      description: 'Financial statements, valuation metrics, growth rates, and other company-specific financial information.',
      examples: [
        'Income statements, balance sheets, and cash flow statements',
        'Valuation metrics (P/E, P/S, EV/EBITDA)',
        'Growth rates and profitability metrics',
        'Company guidance and forward projections',
        'Sector and industry comparisons'
      ],
      strengths: [
        'Objective company financial health metrics',
        'Standard reporting enables direct comparisons',
        'Historical trends show company trajectory',
        'Strong foundation for valuation analysis'
      ],
      limitations: [
        'Quarterly reporting means data can be dated',
        'Accounting methods may vary between companies',
        'Forward projections are estimates, not facts',
        'Non-GAAP metrics require careful interpretation'
      ]
    }
  ];

  const qualityRatings = [
    {
      level: 'High Confidence',
      icon: CheckCircle,
      color: 'green',
      description: 'Information verified across multiple reputable sources with strong consensus.',
      indicators: [
        'Verified by multiple credible sources',
        'Backed by quantitative data',
        'From official company or regulatory filings',
        'Recent publication from established financial sources',
        'Consistent with other verified information'
      ]
    },
    {
      level: 'Medium Confidence',
      icon: AlertTriangle,
      color: 'yellow',
      description: 'Generally reliable information that may need additional verification or contains some subjective elements.',
      indicators: [
        'Limited source verification',
        'Contains some analysis or opinion',
        'From credible but not official sources',
        'Slightly dated information',
        'Generally aligns with other sources but with minor discrepancies'
      ]
    },
    {
      level: 'Low Confidence',
      icon: XCircle,
      color: 'red',
      description: 'Information that is unverified, highly subjective, or from questionable sources. Used cautiously or for context only.',
      indicators: [
        'Unverified claims',
        'Single source reporting',
        'Heavily opinion-based content',
        'Significantly outdated information',
        'Contradicts other more reliable sources',
        'From non-established or anonymous sources'
      ]
    }
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Filter className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Source Types & Quality
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Understanding the different types of sources Prism uses and how to evaluate their reliability
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className={`mb-12 p-6 rounded-xl border ${
            isDarkMode ? 'bg-gray-800/50 border-gray-700' : 'bg-white/50 border-gray-200'
          }`}>
            <div className="flex items-start">
              <AlertCircle className={`w-6 h-6 mt-1 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Source Diversity Matters</h3>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  Prism draws from multiple source types to provide a comprehensive view of market conditions. Each source type has unique strengths and limitations. The platform automatically prioritizes high-quality sources while maintaining transparency about where information originates.
                </p>
              </div>
            </div>
          </div>

          {/* Source Types */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Types of Sources
          </h2>
          
          <div className="space-y-8 mb-16">
            {sourceTypes.map((source) => {
              const Icon = source.icon;
              return (
                <div 
                  key={source.id}
                  className={`p-6 rounded-xl border ${
                    isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                  }`}
                >
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg mr-4 ${
                      source.color === 'blue' ? (isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100') :
                      source.color === 'green' ? (isDarkMode ? 'bg-green-500/20' : 'bg-green-100') :
                      source.color === 'yellow' ? (isDarkMode ? 'bg-yellow-500/20' : 'bg-yellow-100') :
                      (isDarkMode ? 'bg-purple-500/20' : 'bg-purple-100')
                    }`}>
                      <Icon className={`w-6 h-6 ${
                        source.color === 'blue' ? (isDarkMode ? 'text-blue-400' : 'text-blue-600') :
                        source.color === 'green' ? (isDarkMode ? 'text-green-400' : 'text-green-600') :
                        source.color === 'yellow' ? (isDarkMode ? 'text-yellow-400' : 'text-yellow-600') :
                        (isDarkMode ? 'text-purple-400' : 'text-purple-600')
                      }`} />
                    </div>
                    <h3 className={`text-xl font-bold ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {source.name}
                    </h3>
                  </div>
                  
                  <p className={`mb-6 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {source.description}
                  </p>
                  
                  <div className="grid md:grid-cols-3 gap-6">
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Examples</h4>
                      <ul className={`space-y-2 text-sm ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {source.examples.map((example, i) => (
                          <li key={i} className="flex items-start">
                            <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                            <span>{example}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Strengths</h4>
                      <ul className={`space-y-2 text-sm ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {source.strengths.map((strength, i) => (
                          <li key={i} className="flex items-start">
                            <CheckCircle className="w-3.5 h-3.5 text-green-500 mt-0.5 mr-2 flex-shrink-0" />
                            <span>{strength}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Limitations</h4>
                      <ul className={`space-y-2 text-sm ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {source.limitations.map((limitation, i) => (
                          <li key={i} className="flex items-start">
                            <AlertTriangle className="w-3.5 h-3.5 text-yellow-500 mt-0.5 mr-2 flex-shrink-0" />
                            <span>{limitation}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Source Quality */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Source Quality Ratings
          </h2>
          
          <div className="space-y-6 mb-16">
            {qualityRatings.map((rating) => {
              const Icon = rating.icon;
              return (
                <div 
                  key={rating.level}
                  className={`p-6 rounded-xl border ${
                    isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                  }`}
                >
                  <div className="flex items-center mb-4">
                    <Icon className={`w-6 h-6 mr-3 ${
                      rating.color === 'green' ? 'text-green-500' :
                      rating.color === 'yellow' ? 'text-yellow-500' :
                      'text-red-500'
                    }`} />
                    <h3 className={`text-lg font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {rating.level}
                    </h3>
                  </div>
                  
                  <p className={`mb-4 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {rating.description}
                  </p>
                  
                  <div className={`p-4 rounded-lg ${
                    isDarkMode ? 'bg-gray-900/50' : 'bg-gray-50'
                  }`}>
                    <h4 className={`text-sm font-medium mb-3 ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>Indicators:</h4>
                    <ul className={`grid md:grid-cols-2 gap-x-4 gap-y-2 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-600'
                    }`}>
                      {rating.indicators.map((indicator, i) => (
                        <li key={i} className="flex items-start">
                          <span className={`inline-block w-1.5 h-1.5 rounded-full mt-1.5 mr-2 flex-shrink-0 ${
                            rating.color === 'green' ? 'bg-green-500' :
                            rating.color === 'yellow' ? 'bg-yellow-500' :
                            'bg-red-500'
                          }`} />
                          <span>{indicator}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Source Freshness */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Source Freshness
          </h2>
          
          <div className={`p-6 rounded-xl border mb-16 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="flex items-center mb-4">
              <Clock className={`w-6 h-6 mr-3 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h3 className={`text-lg font-medium ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Time Sensitivity
              </h3>
            </div>
            
            <p className={`mb-6 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              The freshness of information is critical in financial markets. Prism prioritizes sources based on their recency relative to the information type.
            </p>
            
            <div className={`space-y-4 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                <div className={`p-4 rounded-lg text-center ${
                  isDarkMode ? 'bg-gray-900/50' : 'bg-gray-50'
                }`}>
                  <h4 className="text-sm font-medium mb-1">Market Data</h4>
                  <p className="text-xs">Real-time to minutes</p>
                </div>
                <div className={`p-4 rounded-lg text-center ${
                  isDarkMode ? 'bg-gray-900/50' : 'bg-gray-50'
                }`}>
                  <h4 className="text-sm font-medium mb-1">Breaking News</h4>
                  <p className="text-xs">Hours to 1 day</p>
                </div>
                <div className={`p-4 rounded-lg text-center ${
                  isDarkMode ? 'bg-gray-900/50' : 'bg-gray-50'
                }`}>
                  <h4 className="text-sm font-medium mb-1">Analysis</h4>
                  <p className="text-xs">1-7 days</p>
                </div>
                <div className={`p-4 rounded-lg text-center ${
                  isDarkMode ? 'bg-gray-900/50' : 'bg-gray-50'
                }`}>
                  <h4 className="text-sm font-medium mb-1">In-depth Research</h4>
                  <p className="text-xs">7-30 days</p>
                </div>
              </div>
              
              <p className="text-sm">
                <strong>Note:</strong> Prism automatically adjusts for source freshness based on the type of information and your query context. For long-term analysis, older but highly reliable sources may be prioritized over newer but less substantial information.
              </p>
            </div>
          </div>

          {/* Next Steps */}
          <div>
            <h2 className={`text-2xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Next Steps
            </h2>
            <div className="grid md:grid-cols-2 gap-6">
              <a
                href="/docs/research-progress"
                className={`p-6 rounded-xl border flex items-center ${
                  isDarkMode 
                    ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                    : 'bg-white border-gray-200 hover:border-blue-500'
                } transition-colors`}
              >
                <div>
                  <h3 className={`font-medium mb-1 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    Viewing Research Progress
                  </h3>
                  <p className={`text-sm ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`}>
                    How to track and interpret Prism's research in real-time
                  </p>
                </div>
                <ChevronRight className={`ml-auto w-5 h-5 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`} />
              </a>
              <a
                href="/docs/export-research"
                className={`p-6 rounded-xl border flex items-center ${
                  isDarkMode 
                    ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                    : 'bg-white border-gray-200 hover:border-blue-500'
                } transition-colors`}
              >
                <div>
                  <h3 className={`font-medium mb-1 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    Exporting Research Results
                  </h3>
                  <p className={`text-sm ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`}>
                    How to save, export, and share research findings
                  </p>
                </div>
                <ChevronRight className={`ml-auto w-5 h-5 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default SourceTypes;