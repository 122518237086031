// client/src/types/credits.ts

export interface CreditTransaction {
  id: string;
  amount: number;
  type: 'PURCHASE' | 'USAGE';
  description?: string;
  createdAt: string;
  status: 'PENDING' | 'COMPLETED' | 'FAILED';
}

export interface CreditPackage {
  id: string;
  name: string;
  credits: number;
  price: number;
  originalPrice?: number;
  description?: string;
  pricePerChat?: string;
  savePercent?: number;
  features?: string[];
  popular?: boolean;
}

// Update default credit packages to match new pricing
export const CREDIT_PACKAGES: CreditPackage[] = [
  { 
    id: 'starter', 
    name: '100 Prism Chats', 
    credits: 100, 
    price: 4.99,
    originalPrice: 9.99,
    pricePerChat: '0.05'
  },
  { 
    id: 'pro', 
    name: '500 Prism Chats', 
    credits: 500, 
    price: 19.99,
    originalPrice: 49.99,
    pricePerChat: '0.04', 
    popular: true,
    savePercent: 60
  },
  { 
    id: 'unlimited', 
    name: '2000 Prism Chats', 
    credits: 2000, 
    price: 49.99,
    originalPrice: 199.99,
    pricePerChat: '0.025',
    savePercent: 75
  },
];

// Update credit cost constants for Prism chats
export const PRISM_CHAT_COST = 1; // Each chat costs exactly 1 credit

// types/credits.ts
export interface CreditDeductionParams {
  cost: number;
  reason?: string;
}

// Credit calculation constants
export const BASE_CREDIT_COST = 10;
export const WORD_CREDIT_MULTIPLIER = 0.5;
export const CHART_COMPLEXITY_MULTIPLIER = 1.5;