import { useState, useEffect } from 'react';
import { marketDataService } from '../services/marketDataService';
import { SearchResult } from '../types/market';

export function useStockSearch() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const searchStocks = async () => {
      if (!query.trim()) {
        setResults([]);
        return;
      }

      setIsLoading(true);
      setError(null);
      
      try {
        const searchResults = await marketDataService.searchStocks(query);
        setResults(searchResults);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Search failed'));
        setResults([]);
      } finally {
        setIsLoading(false);
      }
    };

    const debounced = setTimeout(searchStocks, 300);
    return () => clearTimeout(debounced);
  }, [query]);

  return {
    query,
    setQuery,
    results,
    isLoading,
    error
  };
}