import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Zap,
  Diamond,
  BarChart4,
  MessageSquare,
  Clock,
  Server,
  FileText,
  Download,
  Compass,
  Shield,
  ChevronRight,
  Check,
  AlertTriangle,
  CreditCard,
  Info
} from 'lucide-react';

const ProFeatures = () => {
  const { isDarkMode } = useTheme();

  const proFeatures = [
    {
      title: 'Advanced Analytics',
      description: 'Institutional-grade market analysis and visualization tools',
      icon: BarChart4,
      features: [
        'Multi-factor analysis models',
        'Advanced technical indicators',
        'Cross-asset correlation analysis',
        'Custom indicator builder',
        'Volatility surface visualization'
      ],
      standardComparison: 'Standard plan includes basic technical indicators only'
    },
    {
      title: 'Enhanced AI Capabilities',
      description: 'More powerful AI-driven research and insights',
      icon: MessageSquare,
      features: [
        'Priority Claude-2 model access',
        'Extended context window (100K tokens)',
        'Specialized financial models',
        'Multi-source research synthesis',
        'PDF and document analysis'
      ],
      standardComparison: 'Standard plan limited to 8K token context and basic research'
    },
    {
      title: 'Data & Performance',
      description: 'Expanded data access and improved platform performance',
      icon: Zap,
      features: [
        'Real-time data with 0 delay',
        'Extended historical data (25+ years)',
        'Alternative data sources integration',
        'Higher API rate limits',
        'Priority server allocation'
      ],
      standardComparison: 'Standard plan includes 15-minute delayed data and 5-year history'
    },
    {
      title: 'Export & Integration',
      description: 'Advanced export options and third-party integrations',
      icon: Download,
      features: [
        'Advanced report generation',
        'Trading platform integration',
        'Custom dashboard export',
        'Data export in multiple formats',
        'Automated scheduled exports'
      ],
      standardComparison: 'Standard plan limited to basic CSV and PDF exports'
    }
  ];

  const creditFeatures = [
    {
      title: 'Pro Subscription Credit Benefits',
      items: [
        'Monthly allocation of 500 AI credits (vs. 50 in Standard)',
        'Higher regeneration rate (15/hour vs. 5/hour)',
        'Priority queuing during peak hours',
        'Bulk discount on additional credit purchases (30% off)'
      ]
    },
    {
      title: 'Pro-Exclusive Features',
      items: [
        'Advanced prompt templates with no credit cost',
        'Longer conversations without context limits',
        'Specialized research pathways for complex analysis',
        'Credit-free dashboard refreshes',
        'Premium data sources with no additional credit charge'
      ]
    }
  ];

  const upgradeOptions = [
    {
      title: 'Monthly Pro Subscription',
      description: 'Flexible month-to-month access to Pro features',
      price: '$49.99/month',
      includes: [
        'All Pro features',
        '500 AI credits per month',
        'Monthly billing with no commitment',
        'Cancel anytime'
      ]
    },
    {
      title: 'Annual Pro Subscription',
      description: 'Best value for committed users',
      price: '$499.99/year',
      includes: [
        'All Pro features',
        '600 AI credits per month (7,200/year)',
        'Save over 16% compared to monthly',
        'Priority support'
      ]
    },
    {
      title: 'Enterprise Plan',
      description: 'Customized solutions for teams and organizations',
      price: 'Custom pricing',
      includes: [
        'All Pro features plus custom capabilities',
        'Team management and collaboration tools',
        'Dedicated account manager',
        'Custom integrations and data feeds',
        'Volume discounts and SLA options'
      ]
    }
  ];

  const comparisons = [
    {
      category: 'AI Capabilities',
      standard: 'Basic Claude model access',
      pro: 'Priority access to latest Claude models'
    },
    {
      category: 'Context Window',
      standard: '8K tokens',
      pro: '100K tokens'
    },
    {
      category: 'Monthly Credits',
      standard: '50 credits',
      pro: '500 credits'
    },
    {
      category: 'Market Data',
      standard: '15-minute delayed',
      pro: 'Real-time'
    },
    {
      category: 'Historical Data',
      standard: '5 years',
      pro: '25+ years'
    },
    {
      category: 'Indicators',
      standard: '20+ basic indicators',
      pro: '100+ advanced indicators'
    },
    {
      category: 'API Access',
      standard: 'Limited endpoints, 60 requests/min',
      pro: 'Full access, 300 requests/min'
    },
    {
      category: 'Watchlists',
      standard: 'Up to 5 watchlists, 25 symbols each',
      pro: 'Unlimited watchlists, 100 symbols each'
    }
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Diamond className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Pro Features
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Enhanced capabilities and premium features available with Prism Pro
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              Prism Pro takes your market research and analysis capabilities to the next level with enhanced AI features, 
              advanced analytics, real-time data, and priority access to new capabilities. This guide outlines the 
              additional features available to Pro subscribers and explains how to maximize the value of your subscription.
            </p>
          </div>

          {/* Pro Feature Categories */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Pro Feature Categories
          </h2>

          <div className="space-y-12 mb-16">
            {proFeatures.map((feature, index) => {
              const Icon = feature.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    } mr-4`}>
                      <Icon className={`w-6 h-6 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <div>
                      <h3 className={`text-xl font-bold ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {feature.title}
                      </h3>
                    </div>
                  </div>
                  
                  <p className={`mb-6 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {feature.description}
                  </p>
                  
                  <div className="grid md:grid-cols-2 gap-8">
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Includes:</h4>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {feature.features.map((item, i) => (
                          <li key={i} className="flex items-start">
                            <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                            <span>{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div className={`rounded-lg p-4 ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                    }`}>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Standard Plan Comparison:</h4>
                      <p className={`${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {feature.standardComparison}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Credits System */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Pro Credits System
          </h2>

          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="mb-6">
              <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                The Pro subscription includes a significantly enhanced credit allocation for AI-powered features, 
                giving you more flexibility and capacity for in-depth analysis and research.
              </p>
            </div>

            <div className="grid md:grid-cols-2 gap-6">
              {creditFeatures.map((section, index) => (
                <div key={index}>
                  <h3 className={`text-lg font-medium mb-4 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {section.title}
                  </h3>
                  <ul className={`space-y-2 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {section.items.map((item, i) => (
                      <li key={i} className="flex items-start">
                        <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          {/* Subscription Options */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Pro Subscription Options
          </h2>

          <div className="grid md:grid-cols-3 gap-6 mb-12">
            {upgradeOptions.map((option, index) => (
              <div key={index} className={`p-6 rounded-xl border ${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
              }`}>
                <h3 className={`text-xl font-bold mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {option.title}
                </h3>
                
                <p className={`text-sm mb-4 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  {option.description}
                </p>
                
                <div className={`text-2xl font-bold mb-4 ${
                  isDarkMode ? 'text-blue-400' : 'text-blue-600'
                }`}>
                  {option.price}
                </div>
                
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {option.includes.map((item, i) => (
                    <li key={i} className="flex items-start">
                      <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Feature Comparison Table */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Standard vs. Pro Comparison
          </h2>

          <div className={`rounded-xl border overflow-hidden mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <table className="w-full">
              <thead>
                <tr className={`border-b ${
                  isDarkMode ? 'border-gray-700 bg-gray-900' : 'border-gray-200 bg-gray-50'
                }`}>
                  <th className={`px-6 py-4 text-left ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>Feature Category</th>
                  <th className={`px-6 py-4 text-left ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>Standard Plan</th>
                  <th className={`px-6 py-4 text-left ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>Pro Plan</th>
                </tr>
              </thead>
              <tbody>
                {comparisons.map((row, index) => (
                  <tr key={index} className={`border-b ${
                    isDarkMode ? 'border-gray-700' : 'border-gray-200'
                  } ${index === comparisons.length - 1 ? 'border-b-0' : ''}`}>
                    <td className={`px-6 py-4 font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>{row.category}</td>
                    <td className={`px-6 py-4 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-600'
                    }`}>{row.standard}</td>
                    <td className={`px-6 py-4 ${
                      isDarkMode ? 'text-blue-400' : 'text-blue-600'
                    }`}>{row.pro}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* How to Upgrade */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <h3 className={`text-xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              How to Upgrade to Pro
            </h3>
            
            <ol className={`space-y-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">1</div>
                <div>
                  <p className="font-medium">Navigate to Account Settings</p>
                  <p className="text-sm mt-1">Click on your profile icon in the top-right corner and select "Settings" from the dropdown menu.</p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">2</div>
                <div>
                  <p className="font-medium">Select Subscription Tab</p>
                  <p className="text-sm mt-1">In the settings page, navigate to the "Subscription" tab to view your current plan and available options.</p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">3</div>
                <div>
                  <p className="font-medium">Choose Pro Plan</p>
                  <p className="text-sm mt-1">Review the Pro plan options and select either the monthly or annual billing cycle based on your preference.</p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">4</div>
                <div>
                  <p className="font-medium">Complete Payment</p>
                  <p className="text-sm mt-1">Enter your payment information and confirm your subscription purchase. We support credit/debit cards and PayPal.</p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">5</div>
                <div>
                  <p className="font-medium">Access Pro Features</p>
                  <p className="text-sm mt-1">Pro features will be activated immediately after successful payment processing. No restart or logout required.</p>
                </div>
              </li>
            </ol>
          </div>

          {/* Important Notice */}
          <div className={`p-6 rounded-xl border ${
            isDarkMode ? 'bg-amber-900/20 border-amber-800/30' : 'bg-amber-50 border-amber-200'
          } mb-12`}>
            <div className="flex items-start">
              <Info className={`w-6 h-6 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-amber-400' : 'text-amber-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Subscription Note</h3>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  Pro subscriptions are automatically renewed at the end of your billing cycle. You can cancel
                  anytime through your account settings to prevent automatic renewal. If you cancel, you'll retain 
                  Pro access until the end of your current billing period.
                </p>
              </div>
            </div>
          </div>

          {/* Related Documentation */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Related Documentation
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <a 
              href="/docs/advanced-analytics"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Advanced Analytics
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Deep dive into Pro-level market analysis tools
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
            
            <a 
              href="/docs/customize-prism"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Customizing Prism
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Extended customization options with Pro
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ProFeatures;