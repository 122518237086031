import React, { useEffect, useState, useCallback, useMemo, forwardRef, useImperativeHandle } from "react";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import { SaveItemButton } from "../../components/common/SaveItemButton";
import { useTheme } from "../../contexts/ThemeContext";
import { TrendingUp, TrendingDown, MinusIcon, Brain, Settings, RefreshCcw, CalendarDays, Calendar, DollarSign, Activity, Lightbulb, AlertCircle, ChevronDown, ChevronUp, Minimize2, Maximize2, X, ExternalLink, Trash2, Zap, Clock, BarChart2, Newspaper, SearchIcon, Info } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { tradeIdeaService } from "../../services/tradeIdeaService";
import { TradeIdea, TradeIdeasPreferences } from "../../types/tradeIdeas";
import { motion, AnimatePresence } from "framer-motion";
import ReactDOM from 'react-dom';
import { useAuth } from '../../contexts/AuthContext';
// Constant for layout ID (shared with WelcomeStatePreview)
const CENTRAL_SUN_LAYOUT_ID2 = "central-sun2";

// Move PreferencesModal component outside TradeIdeasFeed
interface PreferencesModalProps {
  isDarkMode: boolean;
  preferences: TradeIdeasPreferences | null;
  onSave: (newPreferences: TradeIdeasPreferences) => void;
  onClose: () => void;
  availableSectors: string[];
  availableStrategies: string[];
  isSidebarCollapsed?: boolean; // Add this prop
}

// ...existing code...

const PreferencesModal: React.FC<PreferencesModalProps> = ({ 
  isDarkMode, 
  preferences, 
  onSave, 
  onClose,
  availableSectors,
  availableStrategies,
  isSidebarCollapsed = true // Default to true (collapsed)
}) => {
  const [newPreferences, setNewPreferences] = useState<TradeIdeasPreferences>({
    sectors: preferences?.sectors || [],
    tradingStyle: preferences?.tradingStyle || 'all',
    riskTolerance: preferences?.riskTolerance || 'medium',
    assetClasses: preferences?.assetClasses || ['stocks'],
    preferredStrategies: preferences?.preferredStrategies || [],
    customPrompt: preferences?.customPrompt || '',
    useOnlyCustomPrompt: preferences?.useOnlyCustomPrompt || false,
    useDeepseek: true
  });

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

   const modalWidth = useMemo(() => {
    const isSmallScreen = windowDimensions.width < 640;
    const isMediumScreen = windowDimensions.width >= 640 && windowDimensions.width < 1024;
    
    if (isSmallScreen) return '95%';
    if (isMediumScreen) return 'min(90%, 600px)';
    return 'min(85%, 700px)';
  }, [windowDimensions.width]);
  
  const modalHeight = useMemo(() => {
    return windowDimensions.height > 800 ? 'auto' : '90vh';
  }, [windowDimensions.height]);
  
  
  // Effect to apply visual changes when useOnlyCustomPrompt is toggled
  useEffect(() => {
    const prefSections = document.querySelectorAll('.pref-section');
    if (newPreferences.useOnlyCustomPrompt) {
      prefSections.forEach(section => {
        section.classList.add('opacity-50', 'pointer-events-none');
      });
    } else {
      prefSections.forEach(section => {
        section.classList.remove('opacity-50', 'pointer-events-none');
      });
    }
  }, [newPreferences.useOnlyCustomPrompt]);
  
  return (
    <div className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center">
      <div 
        className="flex space-x-4 max-w-6xl w-full px-4"
        style={{
          // When sidebar is expanded (not collapsed), shift content right
          transform: !isSidebarCollapsed ? 'translateX(112px)' : 'translateX(0)',
          transition: 'transform 0.3s ease-in-out',
          // Adjust width to account for sidebar
          width: !isSidebarCollapsed ? 'calc(100% - 224px)' : '100%',
          margin: '0 auto'
        }}
      >
        {/* Rest of your panels remain the same */}
        
        {/* New Info Panel */}
        <div className={`w-1/3 p-6 rounded-2xl shadow-lg ${
          isDarkMode ? 'bg-gray-800/90 border border-gray-700' : 'bg-gray-100/90 border border-gray-200'
        }`}>
          <div className="h-full flex flex-col">
            <h3 className={`text-xl font-semibold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Finding Your Perfect Trade
            </h3>
            
            <div className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
              Our AI analyzes market conditions to find opportunities matching your preferences.
            </div>
            
            <div className="space-y-6 flex-1">
              {/* Tip 1 */}
              <div className="space-y-2">
                <div className="flex items-center">
                  <div className={`p-2 rounded-full ${isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'} mr-3`}>
                    <Brain className={`w-4 h-4 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                  </div>
                  <h4 className={`font-medium ${isDarkMode ? 'text-blue-300' : 'text-blue-700'}`}>Custom Prompts</h4>
                </div>
                <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} pl-9`}>
                  Use natural language to describe exactly what you're looking for. For example: "Find oversold tech stocks with strong fundamentals."
                </p>
              </div>
              
              {/* Tip 2 */}
              <div className="space-y-2">
                <div className="flex items-center">
                  <div className={`p-2 rounded-full ${isDarkMode ? 'bg-green-500/20' : 'bg-green-100'} mr-3`}>
                    <BarChart2 className={`w-4 h-4 ${isDarkMode ? 'text-green-400' : 'text-green-600'}`} />
                  </div>
                  <h4 className={`font-medium ${isDarkMode ? 'text-green-300' : 'text-green-700'}`}>Market Sectors</h4>
                </div>
                <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} pl-9`}>
                  Select multiple sectors to diversify your trade ideas, or focus on a specific sector for targeted opportunities.
                </p>
              </div>
              
              {/* Tip 3 */}
              <div className="space-y-2">
                <div className="flex items-center">
                  <div className={`p-2 rounded-full ${isDarkMode ? 'bg-purple-500/20' : 'bg-purple-100'} mr-3`}>
                    <Calendar className={`w-4 h-4 ${isDarkMode ? 'text-purple-400' : 'text-purple-600'}`} />
                  </div>
                  <h4 className={`font-medium ${isDarkMode ? 'text-purple-300' : 'text-purple-700'}`}>Timeframes</h4>
                </div>
                <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} pl-9`}>
                  Choose a trading timeframe that matches your style: day trading, swing trading, or long-term investing.
                </p>
              </div>
            </div>
            
            <div className={`mt-6 p-4 rounded-lg ${
              isDarkMode ? 'bg-gray-700/50 border border-gray-600' : 'bg-white border border-gray-200'
            }`}>
              <div className="flex items-center mb-2">
                <Lightbulb className={`w-4 h-4 mr-2 ${isDarkMode ? 'text-amber-400' : 'text-amber-500'}`} />
                <span className={`font-medium ${isDarkMode ? 'text-amber-300' : 'text-amber-600'}`}>Pro Tip</span>
              </div>
              <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                Refresh your trade ideas periodically as market conditions change. The best opportunities often appear after major market movements.
              </p>
            </div>
          </div>
        </div>
        
        {/* Existing Preferences Panel */}
        <div className={`w-2/3 p-6 rounded-2xl shadow-xl overflow-y-auto max-h-[80vh] ${
          isDarkMode ? 'bg-gray-900 border border-gray-800' : 'bg-white'
        }`}>
          {/* Original content of the preferences modal */}
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-3">
              <div className={`p-2 rounded-full ${isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'}`}>
                <Lightbulb className={`w-5 h-5 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
              </div>
              <h3 className={`text-lg font-semibold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>Find Trade Ideas</h3>
            </div>
            <button 
              onClick={onClose}
              className={`p-2 rounded-full ${
                isDarkMode ? 'hover:bg-gray-800 text-gray-400' : 'hover:bg-gray-100 text-gray-600'
              }`}
            >
              <X className="w-4 h-4" />
            </button>
          </div>
          
          <p className={`mb-6 text-sm ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}>
            Tell us what you're looking for and we'll find relevant trading opportunities for you.
          </p>
          
          {/* Custom Prompt - Now at the top for emphasis */}
          <div className="mb-6 bg-gradient-to-r from-blue-500/10 to-purple-500/10 p-4 rounded-xl border border-blue-500/20">
            <div className="flex items-center mb-2">
              <Brain className={`w-4 h-4 mr-2 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
              <h4 className={`text-sm font-medium ${
                isDarkMode ? 'text-blue-300' : 'text-blue-700'
              }`}>What are you looking for?</h4>
            </div>
            
            <textarea
              value={newPreferences.customPrompt || ''}
              onChange={(e) => setNewPreferences({
                ...newPreferences,
                customPrompt: e.target.value
              })}
              placeholder="Describe the trade ideas you're looking for... (e.g., 'Find oversold tech stocks with upcoming earnings' or 'High-dividend stocks with low volatility')"
              className={`w-full p-3 rounded-lg border transition-all ${
                isDarkMode
                  ? 'bg-gray-800/80 border-gray-700 text-white focus:border-blue-500'
                  : 'bg-white/90 border-gray-200 text-gray-900 focus:border-blue-500'
              } min-h-[80px]`}
            />
            
            <div className="mt-3 flex items-center">
              <input
                type="checkbox"
                id="useOnlyCustomPrompt"
                checked={newPreferences.useOnlyCustomPrompt || false}
                onChange={(e) => setNewPreferences({
                  ...newPreferences,
                  useOnlyCustomPrompt: e.target.checked
                })}
                className="mr-2"
              />
              <label 
                htmlFor="useOnlyCustomPrompt" 
                className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}
              >
                Use only this prompt (ignore filters below)
              </label>
            </div>
          </div>
          
          {/* Sectors with visual indicators */}
          <div className={`mb-6 ${newPreferences.useOnlyCustomPrompt ? 'opacity-50 pointer-events-none' : ''} pref-section`}>
            <div className="flex items-center justify-between mb-2">
              <h4 className={`text-sm font-medium ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>Market Sectors</h4>
              <span className={`text-xs ${isDarkMode ? 'text-gray-500' : 'text-gray-500'}`}>
                {newPreferences.sectors.length} selected
              </span>
            </div>
            
            <div className="flex flex-wrap gap-2">
              {availableSectors.map(sector => {
                const isSelected = newPreferences.sectors.includes(sector);
                return (
                  <button
                    key={sector}
                    onClick={() => {
                      if (isSelected) {
                        setNewPreferences({
                          ...newPreferences,
                          sectors: newPreferences.sectors.filter(s => s !== sector)
                        });
                      } else {
                        setNewPreferences({
                          ...newPreferences,
                          sectors: [...newPreferences.sectors, sector]
                        });
                      }
                    }}
                    className={`px-3 py-1.5 rounded-full text-sm transition-all flex items-center ${
                      isSelected
                        ? isDarkMode 
                          ? 'bg-blue-500/20 border-blue-500 text-blue-400 font-medium' 
                          : 'bg-blue-50 border-blue-200 text-blue-600 font-medium'
                        : isDarkMode
                          ? 'bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700' 
                          : 'bg-gray-100 border-gray-200 text-gray-600 hover:bg-gray-200'
                    } border`}
                  >
                    {isSelected && (
                      <div className="w-2 h-2 bg-blue-500 rounded-full mr-1.5"></div>
                    )}
                    {sector}
                  </button>
                );
              })}
            </div>
          </div>
          
          {/* Trading Style - Now with icons */}
          <div className={`mb-6 ${newPreferences.useOnlyCustomPrompt ? 'opacity-50 pointer-events-none' : ''} pref-section`}>
            <h4 className={`text-sm font-medium mb-2 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-700'
            }`}>Trading Timeframe</h4>
            
            <div className="grid grid-cols-5 gap-2">
              {[
                { value: 'day', label: 'Day', icon: <Clock className="w-3 h-3 mr-1" /> },
                { value: 'swing', label: 'Swing', icon: <Activity className="w-3 h-3 mr-1" /> },
                { value: 'position', label: 'Position', icon: <BarChart2 className="w-3 h-3 mr-1" /> },
                { value: 'long-term', label: 'Long-term', icon: <Calendar className="w-3 h-3 mr-1" /> },
                { value: 'all', label: 'Any', icon: <DollarSign className="w-3 h-3 mr-1" /> },
              ].map(({ value, label, icon }) => (
                <button
                  key={value}
                  onClick={() => setNewPreferences({
                    ...newPreferences,
                    tradingStyle: value as any
                  })}
                  className={`px-3 py-2 rounded-lg text-sm transition-colors flex items-center justify-center ${
                    newPreferences.tradingStyle === value
                      ? isDarkMode 
                        ? 'bg-blue-500/20 border-blue-500 text-blue-400 font-medium' 
                        : 'bg-blue-50 border-blue-200 text-blue-600 font-medium'
                      : isDarkMode
                        ? 'bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700' 
                        : 'bg-gray-100 border-gray-200 text-gray-600 hover:bg-gray-200'
                  } border`}
                >
                  {icon}
                  {label}
                </button>
              ))}
            </div>
          </div>
          
          {/* Trading Strategies - Now with badges */}
          <div className={`mb-6 ${newPreferences.useOnlyCustomPrompt ? 'opacity-50 pointer-events-none' : ''} pref-section`}>
            <div className="flex items-center justify-between mb-2">
              <h4 className={`text-sm font-medium ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>Trading Strategies</h4>
              <span className={`text-xs ${isDarkMode ? 'text-gray-500' : 'text-gray-500'}`}>
                {newPreferences.preferredStrategies.length > 0 ? 
                  `${newPreferences.preferredStrategies.length} selected` : 
                  'Any strategy'}
              </span>
            </div>
            
            <div className="flex flex-wrap gap-2">
              {availableStrategies.map(strategy => {
                const isSelected = newPreferences.preferredStrategies.includes(strategy);
                return (
                  <button
                    key={strategy}
                    onClick={() => {
                      if (isSelected) {
                        setNewPreferences({
                          ...newPreferences,
                          preferredStrategies: newPreferences.preferredStrategies.filter(s => s !== strategy)
                        });
                      } else {
                        setNewPreferences({
                          ...newPreferences,
                          preferredStrategies: [...newPreferences.preferredStrategies, strategy]
                        });
                      }
                    }}
                    className={`px-3 py-1.5 rounded-lg text-sm transition-colors flex items-center ${
                      isSelected
                        ? isDarkMode 
                          ? 'bg-blue-500/20 border-blue-500 text-blue-400 font-medium' 
                          : 'bg-blue-50 border-blue-200 text-blue-600 font-medium'
                        : isDarkMode
                          ? 'bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700' 
                          : 'bg-gray-100 border-gray-200 text-gray-600 hover:bg-gray-200'
                    } border`}
                  >
                    {isSelected && (
                      <div className="w-2 h-2 bg-blue-500 rounded-full mr-1.5"></div>
                    )}
                    {strategy}
                  </button>
                );
              })}
            </div>
          </div>

          {/* Risk Tolerance - Visual slider */}
          <div className={`mb-6 ${newPreferences.useOnlyCustomPrompt ? 'opacity-50 pointer-events-none' : ''} pref-section`}>
            <h4 className={`text-sm font-medium mb-3 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-700'
            }`}>Risk Tolerance</h4>
            
            <div className="relative">
              {/* Slider track with gradient */}
              <div className={`h-2 rounded-full ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} mb-6 relative`}>
                <div className="absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-green-500 to-yellow-500 rounded-l-full"></div>
                <div className="absolute inset-y-0 left-1/3 w-1/3 bg-gradient-to-r from-yellow-500 to-orange-500"></div>
                <div className="absolute inset-y-0 left-2/3 w-1/3 bg-gradient-to-r from-orange-500 to-red-500 rounded-r-full"></div>
                
                {/* Selector dots - positioned absolutely over the track */}
                <div className="absolute top-0 left-0 w-full h-full flex justify-between px-0">
                  {['low', 'medium', 'high'].map((risk, index) => {
                    const positions = ['0%', '50%', '100%'];
                    const isSelected = newPreferences.riskTolerance === risk;
                    
                    return (
                      <button
                        key={risk}
                        onClick={() => setNewPreferences({
                          ...newPreferences,
                          riskTolerance: risk as any
                        })}
                        className={`absolute top-1/2 -translate-y-1/2 h-6 w-6 rounded-full flex items-center justify-center transform -translate-x-1/2 ${
                          isSelected
                            ? isDarkMode 
                              ? 'bg-blue-500 ring-2 ring-blue-400 ring-offset-2 ring-offset-gray-900' 
                              : 'bg-blue-600 ring-2 ring-blue-400 ring-offset-2'
                            : isDarkMode
                              ? 'bg-gray-700 hover:bg-gray-600' 
                              : 'bg-gray-300 hover:bg-gray-400'
                        }`}
                        style={{
                          left: positions[index],
                        }}
                      >
                        {isSelected && (
                          <div className="w-2 h-2 bg-white rounded-full"></div>
                        )}
                      </button>
                    );
                  })}
                </div>
              </div>
              
              <div className="flex justify-between mt-2 text-xs">
                <span className={isDarkMode ? 'text-green-400' : 'text-green-600'}>Conservative</span>
                <span className={isDarkMode ? 'text-yellow-400' : 'text-yellow-600'}>Balanced</span>
                <span className={isDarkMode ? 'text-red-400' : 'text-red-600'}>Aggressive</span>
              </div>
            </div>
          </div> 
          
          {/* Model Selection */}
          
          <div className="flex justify-end space-x-3 mt-8">
            <button
              onClick={onClose}
              className={`px-4 py-2 rounded-lg ${
                isDarkMode 
                  ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              Cancel
            </button>
            <button
              onClick={() => onSave(newPreferences)}
              className="px-5 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 flex items-center font-medium"
            >
              <SearchIcon className="w-4 h-4 mr-2" />
              Find Trade Ideas
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

interface TradeIdeasFeedProps {
  isIdle?: boolean;
  hideHeader?: boolean;
  isSidebarCollapsed?: boolean; 
  onRefresh?: () => void;
  onShowPreferences?: () => void;
  onToggleFullscreen?: () => void; // Add this missing prop
}

// Define the ref type for TradeIdeasFeed
export interface TradeIdeasFeedRef {
  refresh: () => Promise<void>;
  showPreferences: () => void;
  toggleFullscreen: () => void;
  clearCache: () => Promise<void>;
}

const TradeIdeasFeed = forwardRef<TradeIdeasFeedRef, TradeIdeasFeedProps>(({
  isIdle = false,
  hideHeader = false,
  isSidebarCollapsed = true, // Default to true (collapsed)
  onRefresh,
  onShowPreferences,
  onToggleFullscreen
}, ref) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const { user } = useAuth(); // Get user from AuthContext
  const isPro = user?.isPro || false; // Check if user is pro
  
  // Add the rest of your existing state variables
  const [tradeIdeas, setTradeIdeas] = useState<TradeIdea[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [preferences, setPreferences] = useState<TradeIdeasPreferences | null>(null);
  const [showPreferencesModal, setShowPreferencesModal] = useState(false);
  const [expandedIdeas, setExpandedIdeas] = useState<string[]>([]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isClearing, setIsClearing] = useState(false);
  const [wasHeaderHidden, setWasHeaderHidden] = useState(false);
  
  // Add loading messages similar to WelcomeStatePreview
  const loadingMessages = [
    "Scanning market opportunities...",
    "Analyzing price patterns...",
    "Finding potential trade setups...",
    "Checking technical indicators...",
    "Evaluating risk/reward ratios..."
  ];
  
  const [loadingText, setLoadingText] = useState(loadingMessages[0]);
  
  // Effect for rotating loading messages
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    
    if (isLoading) {
      interval = setInterval(() => {
        setLoadingText(current => {
          const currentIndex = loadingMessages.indexOf(current);
          return loadingMessages[(currentIndex + 1) % loadingMessages.length];
        });
      }, 2000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isLoading]);

  const toggleFullscreen = useCallback(() => {
    setIsFullscreen(prev => !prev);
    
    if (!isFullscreen) {
      document.body.classList.add('has-fullscreen-component');
      if (hideHeader) {
        setWasHeaderHidden(true);
      }
    } else {
      document.body.classList.remove('has-fullscreen-component');
    }
    
    if (onToggleFullscreen) onToggleFullscreen();
  }, [isFullscreen, onToggleFullscreen, hideHeader]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isFullscreen) {
        setIsFullscreen(false);
      }
    };
    
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isFullscreen]);
  
  // Available sectors for selection
  const availableSectors = [
    'Technology', 'Healthcare', 'Financial Services', 'Consumer Cyclical', 
    'Industrials', 'Energy', 'Basic Materials', 'Utilities', 'Real Estate', 
    'Communication Services', 'Consumer Defensive', 'Cryptocurrency'
  ];
  
  // Available trading strategies
  const availableStrategies = [
    'Trend Following', 'Mean Reversion', 'Breakout Trading', 'Momentum Trading', 
    'Technical Analysis', 'Value Investing', 'Growth Investing', 'Income Investing', 
    'CANSLIM', 'Options Strategies', 'Scalping', 'Earnings Trading'
  ];
  
  const fetchTradeIdeas = useCallback(async (prefs: TradeIdeasPreferences, forceRefresh = false) => {
    try {
      setIsLoading(true);
      
      const timeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error('Timeout')), 30000)
      );
      
      // Pass isPro to the service
      const ideasPromise = tradeIdeaService.generateIdeas(prefs, forceRefresh, isPro);
      const result = await Promise.race([ideasPromise, timeoutPromise]);
      
      if (Array.isArray(result) && result.length > 0) {
        setTradeIdeas(result);
      }
    } catch (error) {
      console.error('Failed to fetch trade ideas:', error);
    } finally {
      setIsLoading(false);
    }
  }, [isPro]); // Add isPro as dependency

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        setIsLoading(true);
        const prefs = await tradeIdeaService.getPreferences();
        
        if (prefs) {
          // Ensure useDeepseek is always set to true
          setPreferences({
            ...prefs,
            useDeepseek: true
          });
          await fetchTradeIdeas({
            ...prefs,
            useDeepseek: true
          });
        } else {
          setIsLoading(false);
          setShowPreferencesModal(true);
        }
      } catch (error) {
        console.error('Failed to fetch trade ideas preferences:', error);
        setIsLoading(false);
        setShowPreferencesModal(true);
      }
    };
    
    fetchPreferences();
  }, [fetchTradeIdeas]);
  
  // Add a separate effect for auto-refresh that respects the idle state
  useEffect(() => {
    let autoRefreshInterval: NodeJS.Timeout | null = null;
    let isCurrentlyRefreshing = false;
    
    if (!isIdle && preferences) {
      autoRefreshInterval = setInterval(() => {
        // Only refresh if not already refreshing and the component is mounted
        if (!isRefreshing && !isCurrentlyRefreshing && preferences) {
          isCurrentlyRefreshing = true;
          handleForceRefresh().finally(() => {
            isCurrentlyRefreshing = false;
          });
        }
      }, 20 * 60 * 1000); // Refresh every 20 minutes
    }
    
    return () => {
      if (autoRefreshInterval) {
        clearInterval(autoRefreshInterval);
      }
    };
  }, [preferences, isIdle, isRefreshing]);

  const savePreferences = async (newPreferences: TradeIdeasPreferences) => {
    try {
      setIsLoading(true);
      // Always ensure useDeepseek is true regardless of what was passed
      const preferencesWithDeepseek = {
        ...newPreferences,
        useDeepseek: true
      };
      
      const success = await tradeIdeaService.savePreferences(preferencesWithDeepseek);
      
      if (success) {
        setPreferences(preferencesWithDeepseek);
        setShowPreferencesModal(false);
        
        // Fetch trade ideas based on new preferences
        const ideas = await tradeIdeaService.generateIdeas(preferencesWithDeepseek);
        setTradeIdeas(ideas);
      }
    } catch (error) {
      console.error('Failed to save trade ideas preferences:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle forced refresh
  const handleForceRefresh = useCallback(async () => {
    // Only allow pro users to force refresh
    if (!preferences || isRefreshing || !isPro) return Promise.resolve();
    
    try {
      setIsRefreshing(true);
      await fetchTradeIdeas(preferences, true);
      if (onRefresh) onRefresh();
      return Promise.resolve();
    } catch (error) {
      console.error('Failed to refresh trade ideas:', error);
      return Promise.reject(error);
    } finally {
      setIsRefreshing(false);
    }
  }, [preferences, isRefreshing, onRefresh, fetchTradeIdeas, isPro]);

  // Function to handle cache clearing
  const handleClearCache = useCallback(async () => {
    // Only allow pro users to clear cache
    if (isClearing || !isPro) return Promise.resolve();
    
    try {
      setIsClearing(true);
      await tradeIdeaService.clearCache();
      // Optionally refresh after clearing cache
      return handleForceRefresh();
    } catch (error) {
      console.error('Failed to clear trade ideas cache:', error);
      return Promise.reject(error);
    } finally {
      setIsClearing(false);
    }
  }, [isClearing, handleForceRefresh, isPro]);
  
  // Styling for the refresh button
  const refreshButtonClass = `p-2 rounded-lg transition-colors ${
    isDarkMode 
      ? 'hover:bg-gray-800 text-gray-400' 
      : 'hover:bg-gray-100 text-gray-600'
  } ${isRefreshing ? 'animate-spin' : ''}`;

  const exploreTradeIdea = (idea: TradeIdea) => {
    navigate('/dashboard/market-chat', { 
      state: { 
        tradeIdea: idea,
        initialPrompt: `Analyze this trade idea: ${idea.title}. Why is it a ${idea.type} opportunity for ${idea.symbol}? What's the recent price action and news? How does it fit with my ${idea.timeframe} trading style?` 
      }
    });
  };
  
  const getConfidenceColor = (confidence: number) => {
    if (confidence >= 75) return 'bg-green-500';
    if (confidence >= 50) return 'bg-yellow-500';
    return 'bg-red-500';
  };
  
  const getTypeColor = (type: 'buy' | 'sell' | 'hold') => {
    if (type === 'buy') return isDarkMode ? 'text-green-400' : 'text-green-600';
    if (type === 'sell') return isDarkMode ? 'text-red-400' : 'text-red-600';
    return isDarkMode ? 'text-yellow-400' : 'text-yellow-600';
  };
  
  const getTypeIcon = (type: 'buy' | 'sell' | 'hold') => {
    if (type === 'buy') return <TrendingUp className="w-4 h-4" />;
    if (type === 'sell') return <TrendingDown className="w-4 h-4" />;
    return <MinusIcon className="w-4 h-4" />;
  };

  // Loading UI Component
  const PulsatingLoadingIndicator = () => (
    <div className="relative w-full h-full overflow-hidden flex flex-col items-center justify-center bg-black/20">
      <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-indigo-900/20 via-gray-900/10 to-transparent" />
      
      <motion.div
        layoutId={CENTRAL_SUN_LAYOUT_ID2}
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ 
          scale: 1,
          opacity: 1,
        }}
        className="w-16 h-16 rounded-full bg-gradient-to-br from-yellow-300 via-orange-400 to-red-500
        shadow-[0_0_50px_rgba(251,146,60,0.3)]"
      >
        <motion.div 
          className="w-full h-full rounded-full bg-gradient-to-br from-yellow-200 to-orange-400 opacity-80"
          animate={{ 
            scale: [1, 1.1, 1],
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
      </motion.div>

      <div className="mt-8 text-center">
        <div className="text-gray-200 font-medium mb-2">Finding Trade Opportunities</div>
        <div className="h-6 overflow-hidden">
          <AnimatePresence mode="popLayout">
            <motion.div
              key={loadingText}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -20, opacity: 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              className="text-gray-400 text-sm"
            >
              {loadingText}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );

  // Effect to handle fullscreen portal
  useEffect(() => {
    // Create fullscreen portal if it doesn't exist
    if (!document.getElementById('fullscreen-portal')) {
      const portalDiv = document.createElement('div');
      portalDiv.id = 'fullscreen-portal';
      portalDiv.className = 'fullscreen-portal';
      document.body.appendChild(portalDiv);
    }
    
    // Clean up any lingering body styles when component unmounts
    return () => {
      document.body.classList.remove('has-fullscreen-component');
    };
  }, []);

  // Toggle idea expansion function
  const toggleIdeaExpansion = (ideaId: string) => {
    setExpandedIdeas(current => 
      current.includes(ideaId) 
        ? current.filter(id => id !== ideaId) 
        : [...current, ideaId]
    );
  };

  const fullscreenVariants = {
    normal: {
      position: 'relative' as const,
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      width: '100%',
      height: '100%',
      zIndex: 1,
      borderRadius: '0.75rem',
    },
    fullscreen: {
      position: 'fixed' as const,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100vw',
      height: '100vh',
      zIndex: 9999,
      borderRadius: 0,
      transform: 'none',
      margin: 0,
      padding: 0,
    }
  };

  // IMPORTANT: useImperativeHandle must be called unconditionally
  // We move this before any conditional returns
  useImperativeHandle(ref, () => ({
    refresh: handleForceRefresh,
    showPreferences: () => setShowPreferencesModal(true),
    toggleFullscreen: toggleFullscreen,
    clearCache: handleClearCache
  }));

  // Welcome screen when no preferences are set
  const WelcomeScreen = () => (
    <div className="h-full flex flex-col items-center justify-center p-6">
      <Brain className={`w-12 h-12 mb-4 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
      <h3 className={`text-xl font-semibold mb-2 ${
        isDarkMode ? 'text-white' : 'text-gray-900'
      }`}>
        Personalize Your Trade Ideas To Get Started
      </h3>
      <p className={`text-center mb-6 max-w-md ${
        isDarkMode ? 'text-gray-400' : 'text-gray-600'
      }`}>
        Get AI-powered trade ideas tailored to your preferences, trading style, and risk tolerance.
      </p>
      <button
        onClick={() => setShowPreferencesModal(true)}
        className="px-6 py-3 rounded-lg bg-blue-500 text-white hover:bg-blue-600 transition-colors"
      >
        Set Up Your Preferences
      </button>
    </div>
  );

  // Styling for the clear cache button
  const clearCacheButtonClass = `p-2 rounded-lg transition-colors ${
    isDarkMode 
      ? 'hover:bg-gray-800 text-gray-400' 
      : 'hover:bg-gray-100 text-gray-600'
  } ${isClearing ? 'animate-pulse' : ''}`;

  // Content of the trade ideas component
  const TradeIdeasContent = () => (
    <>
      {/* Only show header if not hideHeader or if in fullscreen mode */}
      {(!hideHeader || isFullscreen) && (
        <div className="p-4 flex items-center justify-between border-b border-gray-800">
          <div className="flex items-center space-x-3">
            <Brain className="w-5 h-5 text-blue-500" />
            <h3 className={isDarkMode ? 'text-white' : 'text-gray-900'}>
              Trade Ideas
            </h3>
          </div>
          <div className="flex items-center space-x-2">
            {/* Only show these buttons for Pro users */}
            {isPro && (
              <>
                <button
                  onClick={handleClearCache}
                  disabled={isClearing}
                  className={clearCacheButtonClass}
                  title="Clear Cache"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
                <button
                  onClick={handleForceRefresh}
                  disabled={isRefreshing}
                  className={refreshButtonClass}
                  title="Refresh Ideas"
                >
                  <RefreshCcw className="w-4 h-4" />
                </button>
              </>
            )}
            <button
              onClick={() => setShowPreferencesModal(true)}
              className={`p-2 rounded-lg transition-colors ${
                isDarkMode 
                  ? 'hover:bg-gray-700 text-gray-400' 
                  : 'hover:bg-gray-100 text-gray-600'
              }`}
              title="Preferences"
            >
              <Settings className="w-4 h-4" />
            </button>
            <button
              onClick={toggleFullscreen}
              className={`p-2 rounded-lg transition-colors ${
                isDarkMode 
                  ? 'hover:bg-gray-700 text-gray-400' 
                  : 'hover:bg-gray-100 text-gray-600'
              }`}
              title={isFullscreen ? "Exit Fullscreen" : "Fullscreen"}
            >
              {isFullscreen ? <Minimize2 className="w-4 h-4" /> : <Maximize2 className="w-4 h-4" />}
            </button>
          </div>
        </div>
      )}
      
      {/* Add a notification for free users */}
      {!isPro && tradeIdeas.length > 0 && (
        <div className={`px-4 py-2 text-xs ${
          isDarkMode ? 'bg-gray-800 text-gray-400' : 'bg-gray-100 text-gray-600'
        }`}>
          <div className="flex items-center">
            <Info className="w-3 h-3 mr-1" />
            <span>Upgrade to Pro to refresh trade ideas on demand</span>
          </div>
        </div>
      )}
      
      <div className="flex-1 overflow-y-auto p-4">
        {tradeIdeas.length > 0 ? (
          <div className="space-y-4">
            {tradeIdeas.map((idea) => (
              <div
                key={idea.id}
                className={`p-4 rounded-xl border transition-all ${
                  isDarkMode 
                    ? 'bg-gray-800/50 border-gray-700 hover:border-gray-600' 
                    : 'bg-white/50 border-gray-200 hover:border-gray-300'
                }`}
              >
                {/* Trade idea content */}
                <div className="flex items-start justify-between">
                  <div>
                    <div className="flex items-center space-x-2">
                      <span className={`font-medium ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {idea.symbol}
                      </span>
                      <span className={`px-2 py-0.5 rounded-full text-xs ${getTypeColor(idea.type)} bg-opacity-20`}>
                        {idea.type.toUpperCase()}
                      </span>
                      <span className={`px-2 py-0.5 rounded-full text-xs ${
                        isDarkMode ? 'text-gray-400 bg-gray-700' : 'text-gray-100 bg-gray-100'
                      }`}>
                        {idea.timeframe}
                      </span>
                    </div>
                    <h3 className={`mt-1 font-medium ${
                      isDarkMode ? 'text-gray-200' : 'text-gray-800'
                    }`}>
                      {idea.title}
                    </h3>
                  </div>
                  
                  <div className="flex items-center space-x-1">
                    <div className="flex items-center">
                      <div className={`h-2 w-12 rounded-full bg-gray-700`}>
                        <div 
                          className={`h-full rounded-full ${getConfidenceColor(idea.confidence)}`}
                          style={{ width: `${idea.confidence}%` }}
                        />
                      </div>
                      <span className={`ml-2 text-xs ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-600'
                      }`}>
                        {idea.confidence}%
                      </span>
                    </div>
                    <div className={`ml-2 p-1 rounded-full ${getTypeColor(idea.type)} bg-opacity-10`}>
                      {getTypeIcon(idea.type)}
                    </div>
                  </div>
                </div>
                
                <div className={`mt-2 text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                } ${expandedIdeas.includes(idea.id) ? '' : 'line-clamp-2'}`}>
                  {idea.description}
                </div>
                
                <div className="mt-3 flex items-center justify-between">
  <div className="flex flex-wrap items-center gap-2">
    {idea.sectors.slice(0, 2).map(sector => (
      <span
        key={sector}
        className={`px-2 py-0.5 rounded-full text-xs ${
          isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700'
        }`}
      >
        {sector}
      </span>
    ))}
    {idea.sectors.length > 2 && (
      <span className={`text-xs ${
        isDarkMode ? 'text-gray-500' : 'text-gray-400'
      }`}>
        +{idea.sectors.length - 2} more
      </span>
    )}
    
    {/* Added compact strategy and catalyst display */}
    <div className="flex items-center ml-[-0.5vw]">
      <div className={`h-3 w-[1px] mx-2 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'}`}></div>
      <div className="flex items-center">
        <Brain className={`w-3 h-3 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'} mr-1`} />
        <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          {idea.strategy.length > 20 ? idea.strategy.substring(0, 20) + '...' : idea.strategy}
        </span>
      </div>
      
      {idea.catalyst && (
        <>
          <div className={`h-3 w-[1px] mx-2 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'}`}></div>
          <div className="flex items-center">
            <Zap className={`w-3 h-3 ${isDarkMode ? 'text-amber-400' : 'text-amber-600'} mr-1`} />
            <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              {idea.catalyst.length > 40 ? idea.catalyst.substring(0, 40) + '...' : idea.catalyst}
            </span>
          </div>
        </>
      )}
    </div>
  </div>
  
  <div className="flex items-center space-x-2">
    <button
      onClick={() => toggleIdeaExpansion(idea.id)}
      className={`p-1 rounded-lg transition-colors ${
        isDarkMode 
          ? 'hover:bg-gray-700 text-gray-400' 
          : 'hover:bg-gray-200 text-gray-600'
      }`}
    >
      {expandedIdeas.includes(idea.id) ? (
        <ChevronUp className="w-4 h-4" />
      ) : (
        <ChevronDown className="w-4 h-4" />
      )}
    </button>
    <SaveItemButton
      identifier={idea.id}
      title={idea.title}
      type="RESEARCH"
    />
    <button
      onClick={() => exploreTradeIdea(idea)}
      className={`p-1 rounded-lg transition-colors ${
        isDarkMode 
          ? 'hover:bg-gray-700 text-blue-400' 
          : 'hover:bg-gray-200 text-blue-600'
      }`}
    >
      <ExternalLink className="w-4 h-4" />
    </button>
  </div>
</div>

                {expandedIdeas.includes(idea.id) && (
                  <div className="mt-4 space-y-3 pt-3 border-t border-gray-700/50">
                    {/* Strategy */}
                    <div className="flex items-start">
                      <div className={`p-1.5 rounded-md ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'} mr-3`}>
                        <Brain className="w-4 h-4 text-blue-500" />
                      </div>
                      <div>
                        <div className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                          Strategy
                        </div>
                        <div className={`text-sm ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                          {idea.strategy}
                        </div>
                      </div>
                    </div>
                    
                    {/* Catalyst */}
                    {idea.catalyst && (
                      <div className="flex items-start">
                        <div className={`p-1.5 rounded-md ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'} mr-3`}>
                          <Zap className="w-4 h-4 text-amber-500" />
                        </div>
                        <div>
                          <div className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                            Catalyst
                          </div>
                          <div className={`text-sm ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                            {idea.catalyst}
                          </div>
                        </div>
                      </div>
                    )}
                    
                    {/* Timeliness Score */}
                    {idea.timelinessScore !== undefined && (
                      <div className="flex items-start">
                        <div className={`p-1.5 rounded-md ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'} mr-3`}>
                          <Clock className="w-4 h-4 text-purple-500" />
                        </div>
                        <div className="flex-1">
                          <div className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                            Timeliness
                          </div>
                          <div className="flex items-center mt-1">
                            <div className={`h-1.5 flex-1 rounded-full bg-gray-700`}>
                              <div 
                                className={`h-full rounded-full ${
                                  idea.timelinessScore > 75 ? 'bg-green-500' : 
                                  idea.timelinessScore > 50 ? 'bg-yellow-500' : 'bg-red-500'
                                }`}
                                style={{ width: `${idea.timelinessScore}%` }}
                              />
                            </div>
                            <span className={`ml-2 text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                              {idea.timelinessScore}%
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    
                    {/* Technical Factors */}
                    {idea.technicalFactors && idea.technicalFactors.length > 0 && (
                      <div className="flex items-start">
                        <div className={`p-1.5 rounded-md ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'} mr-3`}>
                          <BarChart2 className="w-4 h-4 text-cyan-500" />
                        </div>
                        <div>
                          <div className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                            Technical Factors
                          </div>
                          <div className="mt-1 space-y-1">
                            {idea.technicalFactors.map((factor, index) => (
                              <div 
                                key={index}
                                className={`text-sm flex items-center ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}
                              >
                                <div className="w-1.5 h-1.5 rounded-full bg-cyan-500 mr-2"></div>
                                {factor}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                    
                    {/* News Events */}
                    {idea.newsEvents && idea.newsEvents.length > 0 && (
                      <div className="flex items-start">
                        <div className={`p-1.5 rounded-md ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'} mr-3`}>
                          <Newspaper className="w-4 h-4 text-blue-500" />
                        </div>
                        <div>
                          <div className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                            News Events
                          </div>
                          <div className="mt-1 space-y-1">
                            {idea.newsEvents.map((event, index) => (
                              <div 
                                key={index} 
                                className={`text-sm flex items-center ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}
                              >
                                <div className="w-1.5 h-1.5 rounded-full bg-blue-500 mr-2"></div>
                                {event}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                    
                    {/* Created Date */}
                    <div className="flex items-center justify-end mt-3">
                      <CalendarDays className={`w-3.5 h-3.5 mr-1.5 ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`} />
                      <span className={`text-xs ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>
                        Generated on {new Date(idea.createdAt).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="h-full flex flex-col items-center justify-center p-6 text-center">
            <Lightbulb className={`w-12 h-12 mb-4 ${isDarkMode ? 'text-gray-700' : 'text-gray-300'}`} />
            <h3 className={`text-lg font-medium mb-2 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-700'
            }`}>
              No trade ideas available
            </h3>
            <p className={`mb-6 ${
              isDarkMode ? 'text-gray-500' : 'text-gray-500'
            }`}>
              Try refreshing or updating your preferences
            </p>
            <div className="flex space-x-4">
              <button
                onClick={handleForceRefresh}
                className="px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600"
              >
                Refresh Ideas
              </button>
              <button
                onClick={() => setShowPreferencesModal(true)}
                className={`px-4 py-2 rounded-lg ${
                  isDarkMode 
                    ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' 
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
              >
                Update Preferences
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );

  // Render appropriate content based on state
  return (
    <>
      {showPreferencesModal && (
        <PreferencesModal 
          isDarkMode={isDarkMode}
          preferences={preferences}
          onSave={savePreferences}
          onClose={() => setShowPreferencesModal(false)}
          availableSectors={availableSectors}
          availableStrategies={availableStrategies}
          isSidebarCollapsed={isSidebarCollapsed} // Pass the prop
        />
      )}
      
      {isFullscreen && (
        ReactDOM.createPortal(
          <motion.div 
            className="fixed inset-0 bg-black/30 backdrop-blur-sm z-[9998]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={toggleFullscreen}
          />,
          document.getElementById('fullscreen-portal') || document.body
        )
      )}

      {isLoading ? (
        <PulsatingLoadingIndicator />
      ) : !preferences && !showPreferencesModal ? (
        <WelcomeScreen />
      ) : isFullscreen ? (
        ReactDOM.createPortal(
          <motion.div 
            className={`fixed inset-0 flex flex-col z-[9999] ${
              isDarkMode ? 'bg-gray-900' : 'bg-white'
            }`}
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            <TradeIdeasContent />
          </motion.div>,
          document.getElementById('fullscreen-portal') || document.body
        )
      ) : (
        <motion.div 
          className={`h-full font-sans flex flex-col w-full transition-all duration-300`}
          style={{ 
            maxWidth: '100%',
            width: '100%'
          }}
          variants={fullscreenVariants}
          animate="normal"
        >
          <TradeIdeasContent />
        </motion.div>
      )}
    </>
  );
});

export default TradeIdeasFeed;