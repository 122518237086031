import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { SEO } from '../components/common/SEO';
import { debounce } from 'lodash';
import ClaudeBadge from './ClaudeBadge';

import { LucideIcon } from 'lucide-react';
import {
  Box,
  BrainCircuit,
  Database,
  LineChart,
  Sparkles,
  Target,
  Search,
  Globe,
  ArrowRight,
  CheckCircle,
  Zap,
  MousePointer2,
  RefreshCw,
  BarChart2,
  MessageSquare,
  ArrowUpRight,
  Clock,
  PieChart,
  Activity
} from 'lucide-react';

const Spline = lazy(() => import('@splinetool/react-spline'));
const FloatingElements = lazy(() => import('./FloatingElements'));

// Optimized Spline component with mobile support
interface OptimizedSplineProps {
  scene: string;
  className?: string;
  onLoad?: () => void;
  isMobile?: boolean;
}

const OptimizedSpline = ({ scene, className, onLoad, isMobile }: OptimizedSplineProps) => {
  const splineOptions = {
    rendererSettings: isMobile ? {
      powerPreference: "low-power",
      antialias: false,
      alpha: false,
      precision: "lowp",
    } : undefined
  };

  return (
    <Suspense fallback={<SplineFallback />}>
      <Spline 
        scene={scene} 
        className={className} 
        onLoad={onLoad}
        {...splineOptions}
      />
    </Suspense>
  );
};

const SplineFallback = () => (
  <div className="w-full h-full flex items-center justify-center bg-gray-900/50">
    <div className="animate-pulse text-gray-400">Loading 3D Scene...</div>
  </div>
);

// Background component similar to Home page
const Background = ({ isLoaded, isMobile }: { isLoaded: boolean; isMobile: boolean }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="fixed inset-0 pointer-events-none overflow-hidden">
      <div className="absolute inset-0 w-full h-full overflow-hidden pointer-events-none">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 via-purple-500/5 to-transparent opacity-50" />
        
        <div className="absolute inset-0">
          <OptimizedSpline
            scene="https://prod.spline.design/1PqVYx7rFIWocw7K/scene.splinecode"
            className="w-full h-full z-0"
            isMobile={isMobile}
          />
        </div>
        
        <div className="absolute z-10 inset-0 bg-gradient-to-b from-transparent via-black/20 to-black/40" />
        <div className="absolute inset-0">
          <div className="absolute z-15 inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent blur-3xl" />
        </div>
        {isLoaded && !isMobile && (
          <Suspense fallback={null}>
            <FloatingElements />
          </Suspense>
        )}
      </div>
    </div>
  );
};

// Research modes visualization component
const ResearchModeCard = ({ 
  icon: Icon, 
  title, 
  description, 
  color, 
  bgColor,
  delay = 0 
}: {
  icon: LucideIcon;
  title: string;
  description: string;
  color: string;
  bgColor: string;
  delay?: number;
}) => {
  const { isDarkMode } = useTheme();
  
  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay }}
      className={`p-6 rounded-xl ${
        isDarkMode ? 'bg-gray-900/70 hover:bg-gray-800/80' : 'bg-white hover:bg-gray-50'
      } backdrop-blur-sm border ${
        isDarkMode ? 'border-gray-800/50' : 'border-gray-200/70'
      } shadow-lg transition-all duration-300 group relative`}
      whileHover={{ y: -5 }}
    >
      <div className="absolute -inset-1 bg-gradient-to-r from-blue-600/20 to-blue-400/20 rounded-xl opacity-0 group-hover:opacity-100 transition duration-200" />
      
      <div className="flex justify-between items-start mb-4">
        <div className={`p-3 rounded-lg ${bgColor} ${color}`}>
          <Icon className="w-6 h-6" />
        </div>
      </div>
      
      <h3 className={`text-xl font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {title}
      </h3>
      
      <p className={`text-base ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
        {description}
      </p>
    </motion.div>
  );
};

// Research process stage component
const ResearchStageCard = ({ 
  icon: Icon, 
  title, 
  description,
  number,
  delay = 0 
}: {
  icon: LucideIcon;
  title: string;
  description: string;
  number: string | number;
  delay?: number;
}) => {
  const { isDarkMode } = useTheme();
  
  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay }}
      className={`p-6 rounded-xl ${
        isDarkMode ? 'bg-gray-900/70' : 'bg-white'
      } backdrop-blur-sm border ${
        isDarkMode ? 'border-gray-800/50' : 'border-gray-200/70'
      } shadow-lg relative`}
    >
      <div className="absolute -top-3 -left-3 w-8 h-8 rounded-full bg-blue-500 text-white flex items-center justify-center font-bold text-sm">
        {number}
      </div>
      
      <div className="flex justify-between items-start mb-4">
        <div className={`p-3 rounded-lg ${
          isDarkMode ? 'bg-blue-500/10' : 'bg-blue-500/10'
        } text-blue-500`}>
          <Icon className="w-6 h-6" />
        </div>
      </div>
      
      <h3 className={`text-xl font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {title}
      </h3>
      
      <p className={`text-base ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
        {description}
      </p>
    </motion.div>
  );
};

// SVG Component for Research Process Visualization
const ResearchProcessSVG = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <svg className="w-full h-64" viewBox="0 0 800 200">
      {/* Neural network background */}
      <g opacity="0.2">
        {Array.from({ length: 10 }).map((_, i) => (
          <g key={`node-${i}`}>
            <circle cx={100 + i * 70} cy={Math.sin(i * 0.5) * 30 + 100} r="4" fill="#3b82f6" />
            <circle cx={135 + i * 70} cy={Math.cos(i * 0.5) * 40 + 100} r="4" fill="#8b5cf6" />
          </g>
        ))}
        {Array.from({ length: 18 }).map((_, i) => (
          <path 
            key={`connection-${i}`} 
            d={`M${100 + Math.floor(i/2) * 70},${Math.sin(Math.floor(i/2) * 0.5) * 30 + 100} 
               L${135 + Math.floor(i/2) * 70},${Math.cos(Math.floor(i/2) * 0.5) * 40 + 100}`} 
            stroke="#3b82f6" 
            strokeWidth="0.5" 
          />
        ))}
      </g>
      
      {/* Main Flow Path */}
      <path 
        d="M50,100 C150,60 250,140 350,100 C450,60 550,140 650,100 C750,60 850,140 750,100" 
        fill="none" 
        stroke={isDarkMode ? "#3b82f6" : "#2563eb"} 
        strokeWidth="3"
        strokeDasharray="10,5"
      />
      
      {/* Process Nodes */}
      <motion.circle 
        cx="50" 
        cy="100" 
        r="20" 
        fill={isDarkMode ? "#1e3a8a" : "#3b82f6"} 
        initial={{ scale: 0 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 0.5 }}
      />
      <motion.circle 
        cx="200" 
        cy="100" 
        r="20" 
        fill={isDarkMode ? "#1e3a8a" : "#3b82f6"} 
        initial={{ scale: 0 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 0.7 }}
      />
      <motion.circle 
        cx="350" 
        cy="100" 
        r="20" 
        fill={isDarkMode ? "#1e3a8a" : "#3b82f6"} 
        initial={{ scale: 0 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 0.9 }}
      />
      <motion.circle 
        cx="500" 
        cy="100" 
        r="20" 
        fill={isDarkMode ? "#1e3a8a" : "#3b82f6"} 
        initial={{ scale: 0 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 1.1 }}
      />
      <motion.circle 
        cx="650" 
        cy="100" 
        r="20" 
        fill={isDarkMode ? "#1e3a8a" : "#3b82f6"} 
        initial={{ scale: 0 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 1.3 }}
      />
      
      {/* Node Icons */}
      <motion.g 
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 0.6 }}
      >
        <text x="50" y="103" fontSize="12" fill="white" textAnchor="middle" dominantBaseline="middle">
          1
        </text>
      </motion.g>
      
      <motion.g 
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 0.8 }}
      >
        <text x="200" y="103" fontSize="12" fill="white" textAnchor="middle" dominantBaseline="middle">
          2
        </text>
      </motion.g>
      
      <motion.g 
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 1.0 }}
      >
        <text x="350" y="103" fontSize="12" fill="white" textAnchor="middle" dominantBaseline="middle">
          3
        </text>
      </motion.g>
      
      <motion.g 
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 1.2 }}
      >
        <text x="500" y="103" fontSize="12" fill="white" textAnchor="middle" dominantBaseline="middle">
          4
        </text>
      </motion.g>
      
      <motion.g 
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 1.4 }}
      >
        <text x="650" y="103" fontSize="12" fill="white" textAnchor="middle" dominantBaseline="middle">
          5
        </text>
      </motion.g>
      
      {/* Stage Labels */}
      <motion.text 
        x="50" 
        y="140" 
        fontSize="10" 
        fill={isDarkMode ? "#93c5fd" : "#1d4ed8"} 
        textAnchor="middle"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 0.7 }}
      >
        Query Analysis
      </motion.text>
      
      <motion.text 
        x="200" 
        y="140" 
        fontSize="10" 
        fill={isDarkMode ? "#93c5fd" : "#1d4ed8"} 
        textAnchor="middle"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 0.9 }}
      >
        Web Discovery
      </motion.text>
      
      <motion.text 
        x="350" 
        y="140" 
        fontSize="10" 
        fill={isDarkMode ? "#93c5fd" : "#1d4ed8"} 
        textAnchor="middle"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 1.1 }}
      >
        Ticker Discovery
      </motion.text>
      
      <motion.text 
        x="500" 
        y="140" 
        fontSize="10" 
        fill={isDarkMode ? "#93c5fd" : "#1d4ed8"} 
        textAnchor="middle"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 1.3 }}
      >
        Data Gathering
      </motion.text>
      
      <motion.text 
        x="650" 
        y="140" 
        fontSize="10" 
        fill={isDarkMode ? "#93c5fd" : "#1d4ed8"} 
        textAnchor="middle"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 1.5 }}
      >
        Response Generation
      </motion.text>
    </svg>
  );
};

// SVG Component for Interface Visualization
const InterfaceVisualizationSVG = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <svg className="w-full h-96" viewBox="0 0 800 500" preserveAspectRatio="xMidYMid meet">
      {/* Interface Frame */}
      <rect x="100" y="50" width="600" height="400" rx="16" fill={isDarkMode ? "#111827" : "#f3f4f6"} stroke={isDarkMode ? "#374151" : "#d1d5db"} strokeWidth="2" />
      
      {/* Top Bar */}
      <rect x="100" y="50" width="600" height="50" rx="16" fill={isDarkMode ? "#1f2937" : "#e5e7eb"} />
      
      
      {/* Box Icon */}
      <rect x="120" y="65" width="20" height="20" rx="4" fill="#3b82f6" />
      <text x="150" y="80" fontSize="14" fontWeight="bold" fill={isDarkMode ? "#f3f4f6" : "#111827"}>Prism</text>
      
      {/* Search Area */}
      <rect x="150" y="120" width="500" height="50" rx="8" fill={isDarkMode ? "#1f2937" : "#e5e7eb"} stroke={isDarkMode ? "#374151" : "#d1d5db"} strokeWidth="1" />
      <rect x="150" y="120" width="80" height="50" rx="8" fill={isDarkMode ? "#2d3748" : "#d1d5db"} />
      <circle cx="190" cy="145" r="10" fill={isDarkMode ? "#3b82f6" : "#2563eb"} fillOpacity="0.2" />
      <text x="245" y="145" fontSize="12" fill={isDarkMode ? "#9ca3af" : "#6b7280"} textAnchor="start" dominantBaseline="middle">Ask anything about markets and investing...</text>
      <rect x="600" y="130" width="30" height="30" rx="4" fill={isDarkMode ? "#f9fafb" : "#1f2937"} />
      <path d="M610,145 L620,145 M615,140 L615,150" stroke={isDarkMode ? "#111827" : "#f9fafb"} strokeWidth="2" />
      
      {/* Trending Topics */}
      <text x="150" y="200" fontSize="12" fontWeight="bold" fill={isDarkMode ? "#3b82f6" : "#2563eb"}>TRENDING TOPICS</text>
      
      {/* Topic Cards - Improved spacing and alignment */}
      {[0, 1, 2].map(i => (
        <g key={`topic-${i}`}>
          <rect x={150 + i*160} y="220" width="140" height="80" rx="8" fill={isDarkMode ? "#1f2937" : "#e5e7eb"} stroke={isDarkMode ? "#374151" : "#d1d5db"} strokeWidth="1" />
          <rect x={150 + i*160} y="220" width="140" height="40" rx="8" fill="url(#topicGradient)" />
          <rect x={150 + i*160} y="240" width="140" height="20" fill={isDarkMode ? "#1f2937" : "#e5e7eb"} fillOpacity="0.5" />
          <text x={160 + i*160} y="276" fontSize="10" fill={isDarkMode ? "#f3f4f6" : "#111827"}>Market analysis topic...</text>
          <rect x={160 + i*160} y="230" width="16" height="16" rx="4" fill="#f9fafb" />
        </g>
      ))}
      
      {/* Trending Stocks */}
      <text x="150" y="330" fontSize="12" fontWeight="bold" fill={isDarkMode ? "#10b981" : "#059669"}>TRENDING STOCKS</text>
      
      {/* Stock Cards - Improved spacing */}
      {[0, 1, 2].map(i => (
        <g key={`stock-${i}`}>
          <rect x={150 + i*160} y="350" width="140" height="80" rx="8" fill={isDarkMode ? "#1f2937" : "#e5e7eb"} stroke={isDarkMode ? "#374151" : "#d1d5db"} strokeWidth="1" />
          <rect x={150 + i*160} y="350" width="140" height="80" rx="8" fill="url(#stockGradient)" fillOpacity="0.6" />
          <text x={160 + i*160} y="370" fontSize="12" fontWeight="bold" fill="#f9fafb">TICKER</text>
          <path d={`M${160 + i*160},390 L${180 + i*160},380 L${200 + i*160},385 L${220 + i*160},370 L${240 + i*160},375 L${260 + i*160},365 L${280 + i*160},380`} fill="none" stroke="#10b981" strokeWidth="2" />
          <text x={160 + i*160} y="410" fontSize="10" fill="#f9fafb">Trending because...</text>
        </g>
      ))}
      
      {/* Gradients */}
      <defs>
        <linearGradient id="topicGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor={isDarkMode ? "#312e81" : "#3b82f6"} stopOpacity="0.8" />
          <stop offset="100%" stopColor={isDarkMode ? "#1e3a8a" : "#2563eb"} stopOpacity="0.6" />
        </linearGradient>
        
        <linearGradient id="stockGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor={isDarkMode ? "#065f46" : "#10b981"} stopOpacity="0.5" />
          <stop offset="100%" stopColor={isDarkMode ? "#064e3b" : "#059669"} stopOpacity="0.3" />
        </linearGradient>
      </defs>
    </svg>
  );
};

// Visualize the research progress component
const ResearchProgressSVG = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <svg className="w-full h-96" viewBox="0 0 800 400" preserveAspectRatio="xMidYMid meet">
      {/* Progress Panel Background */}
      <rect x="100" y="50" width="600" height="300" rx="16" fill={isDarkMode ? "rgba(17, 24, 39, 0.7)" : "rgba(255, 255, 255, 0.7)"} stroke={isDarkMode ? "#374151" : "#d1d5db"} strokeWidth="2" />
      
      {/* Core Progress Circle - Made more prominent */}
      <circle cx="400" cy="150" r="70" fill="none" stroke={isDarkMode ? "#1f2937" : "#e5e7eb"} strokeWidth="10" />
      <motion.circle 
        cx="400" 
        cy="150" 
        r="70" 
        fill="none" 
        stroke="#3b82f6" 
        strokeWidth="10"
        strokeLinecap="round"
        strokeDasharray="439.8228" // 2πr
        strokeDashoffset="109.9557" // 25% of total (75% complete)
        initial={{ strokeDashoffset: 439.8228 }}
        whileInView={{ strokeDashoffset: 109.9557 }}
        viewport={{ once: true }}
        transition={{ duration: 1.5, ease: "easeOut" }}
      />
      
      {/* Progress Percentage */}
      <motion.text 
        x="400" 
        y="140" 
        fontSize="28" 
        fontWeight="bold" 
        fill={isDarkMode ? "#3b82f6" : "#2563eb"} 
        textAnchor="middle"
        dominantBaseline="middle"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 0.5 }}
      >
        75%
      </motion.text>
      <motion.text 
        x="400" 
        y="175" 
        fontSize="12" 
        fill={isDarkMode ? "#93c5fd" : "#3b82f6"} 
        textAnchor="middle"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 0.7 }}
      >
        Data Gathering
      </motion.text>
      
      {/* Left panel - Intent Analysis - Repositioned */}
      <rect x="120" y="110" width="180" height="90" rx="8" fill={isDarkMode ? "rgba(139, 92, 246, 0.1)" : "rgba(109, 40, 217, 0.1)"} stroke={isDarkMode ? "rgba(139, 92, 246, 0.2)" : "rgba(109, 40, 217, 0.2)"} strokeWidth="1" />
      <text x="135" y="130" fontSize="12" fontWeight="bold" fill={isDarkMode ? "#a78bfa" : "#7c3aed"}>Query Intent Analysis</text>
      <text x="135" y="155" fontSize="10" fill={isDarkMode ? "#d1d5db" : "#4b5563"}>Market research on tech sector</text>
      <text x="135" y="175" fontSize="10" fill={isDarkMode ? "#d1d5db" : "#4b5563"}>Focus on AI chip manufacturers</text>
      
      {/* Source Panel - Better positioned */}
      <rect x="220" y="240" width="360" height="80" rx="8" fill={isDarkMode ? "rgba(30, 58, 138, 0.1)" : "rgba(59, 130, 246, 0.1)"} stroke={isDarkMode ? "rgba(59, 130, 246, 0.2)" : "rgba(37, 99, 235, 0.2)"} strokeWidth="1" />
      
      <text x="240" y="265" fontSize="14" fontWeight="bold" fill={isDarkMode ? "#93c5fd" : "#2563eb"}>Sources Found</text>
      
      {/* Sources - Better typography and spacing */}
      <circle cx="240" cy="285" r="4" fill={isDarkMode ? "#93c5fd" : "#2563eb"} />
      <text x="255" y="288" fontSize="11" fill={isDarkMode ? "#d1d5db" : "#4b5563"}>SEC Filing: Q1 2025 Earnings Report</text>
      
      <circle cx="240" cy="305" r="4" fill={isDarkMode ? "#93c5fd" : "#2563eb"} />
      <text x="255" y="308" fontSize="11" fill={isDarkMode ? "#d1d5db" : "#4b5563"}>Financial Times: Market Analysis (Mar 10, 2025)</text>
      
      {/* Ticker Discovery - Improved vertical spacing */}
      <motion.g 
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: 0.9 }}
      >
        <rect x="580" y="110" width="90" height="30" rx="15" fill={isDarkMode ? "rgba(16, 185, 129, 0.1)" : "rgba(5, 150, 105, 0.1)"} stroke={isDarkMode ? "rgba(16, 185, 129, 0.2)" : "rgba(5, 150, 105, 0.2)"} strokeWidth="1" />
        <text x="625" y="130" fontSize="12" fontWeight="medium" fill={isDarkMode ? "#34d399" : "#059669"} textAnchor="middle" dominantBaseline="middle">AAPL</text>
      </motion.g>
      
      <motion.g 
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: 1.0 }}
      >
        <rect x="580" y="150" width="90" height="30" rx="15" fill={isDarkMode ? "rgba(16, 185, 129, 0.1)" : "rgba(5, 150, 105, 0.1)"} stroke={isDarkMode ? "rgba(16, 185, 129, 0.2)" : "rgba(5, 150, 105, 0.2)"} strokeWidth="1" />
        <text x="625" y="170" fontSize="12" fontWeight="medium" fill={isDarkMode ? "#34d399" : "#059669"} textAnchor="middle" dominantBaseline="middle">MSFT</text>
      </motion.g>
      
      <motion.g 
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: 1.1 }}
      >
        <rect x="580" y="190" width="90" height="30" rx="15" fill={isDarkMode ? "rgba(16, 185, 129, 0.1)" : "rgba(5, 150, 105, 0.1)"} stroke={isDarkMode ? "rgba(16, 185, 129, 0.2)" : "rgba(5, 150, 105, 0.2)"} strokeWidth="1" />
        <text x="625" y="210" fontSize="12" fontWeight="medium" fill={isDarkMode ? "#34d399" : "#059669"} textAnchor="middle" dominantBaseline="middle">NVDA</text>
      </motion.g>
      
      
    </svg>
  );
};

// Main Features component
export default function PrismFeatures() {
  const { isDarkMode } = useTheme();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  // Update mobile state on resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    const debouncedResize = debounce(handleResize, 250);
    
    window.addEventListener('resize', debouncedResize);
    return () => window.removeEventListener('resize', debouncedResize);
  }, []);

  return (
    <div className="relative">
      <SEO
        title="Prism Market Chat | Newsroom"
        description="Explore the features of Prism, our AI-powered market intelligence platform with real-time data analysis and comprehensive research."
      />
      
      <Background isLoaded={isLoaded} isMobile={isMobile} />
      
      <div className="relative z-10 pt-20 pb-32">
        {/* Hero Section */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-12 pb-24 text-center">
          <div className="flex items-center justify-center mb-6">
            <Box className={`w-12 h-12 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'} mr-4`} />
            <h1 className={`text-5xl font-bold tracking-tight ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Prism Market Chat
            </h1>
          </div>
          
          <p className={`mt-6 text-xl max-w-3xl mx-auto ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            Next-generation market intelligence powered by AI, delivering real-time financial insights and comprehensive research through an intuitive chat interface.
          </p>
          
          <div className="mt-12">
            <Link to="/dashboard/market-chat" className={`inline-flex items-center px-6 py-3 rounded-lg ${
              isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'
            } text-white font-medium text-lg transition-colors shadow-lg`}>
              Try Prism Now
              <ArrowRight className="ml-2 w-5 h-5" />
            </Link>
          </div>
        </div>

        {/* Main Features Section */}
        <section className="py-24 bg-gradient-to-b from-transparent via-blue-900/5 to-transparent">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className={`text-3xl font-bold tracking-tight ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Intelligent Market Research at Your Fingertips
              </h2>
              <p className={`mt-4 max-w-2xl mx-auto text-lg ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                Prism isn't just an AI chat - it's your research assistant, data analyst, and market expert combined.
              </p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <ResearchModeCard
                icon={BrainCircuit}
                title="AI-Powered Intelligence"
                description="Ask complex market questions and get research-backed answers with Claude AI technology."
                color="text-purple-500"
                bgColor="bg-purple-500/10"
                delay={0.1}
              />
              <ResearchModeCard
                icon={Database}
                title="Real-Time Market Data"
                description="Access up-to-date pricing, financial metrics, and technical indicators for thousands of stocks."
                color="text-blue-500"
                bgColor="bg-blue-500/10"
                delay={0.2}
              />
              <ResearchModeCard
                icon={Globe}
                title="Web Discovery"
                description="Automatically scours the web for relevant news articles and market research reports."
                color="text-cyan-500"
                bgColor="bg-cyan-500/10"
                delay={0.3}
              />
              <ResearchModeCard
                icon={LineChart}
                title="Dynamic Visualizations"
                description="View price charts, performance metrics, and technical indicators generated from your queries."
                color="text-green-500"
                bgColor="bg-green-500/10"
                delay={0.4}
              />
              <ResearchModeCard
                icon={BarChart2}
                title="Trending Topics & Stocks"
                description="Discover what's moving the market with curated trending financial topics and stocks."
                color="text-amber-500"
                bgColor="bg-amber-500/10"
                delay={0.5}
              />
              <ResearchModeCard
                icon={MessageSquare}
                title="Conversational Research"
                description="Chat naturally and refine your financial inquiries through an interactive dialogue."
                color="text-pink-500"
                bgColor="bg-pink-500/10"
                delay={0.6}
              />
            </div>
          </div>
        </section>

        {/* Research Process Section */}
        <section className="py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className={`text-3xl font-bold tracking-tight ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                How Prism Researches Your Questions
              </h2>
              <p className={`mt-4 max-w-2xl mx-auto text-lg ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                Watch in real-time as Prism conducts comprehensive market research, gathering data from multiple sources.
              </p>
            </div>
            
            <div className="mt-12 lg:mt-20">
              <ResearchProcessSVG />
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-16">
              <ResearchStageCard
                icon={BrainCircuit}
                title="Query Analysis"
                description="Prism analyzes your question to understand context, identify potential stocks, and determine the best research approach."
                number="1"
                delay={0.1}
              />
              <ResearchStageCard
                icon={Globe}
                title="Web Discovery"
                description="The system searches the web for relevant articles, reports, and analyses related to your query."
                number="2"
                delay={0.2}
              />
              <ResearchStageCard
                icon={Search}
                title="Ticker Discovery"
                description="Prism identifies relevant stock tickers and financial instruments from your query and research sources."
                number="3"
                delay={0.3}
              />
              <ResearchStageCard
                icon={Database}
                title="Data Gathering"
                description="Real-time financial data is collected, including price history, fundamentals, and technical indicators."
                number="4"
                delay={0.4}
              />
              <ResearchStageCard
                icon={MessageSquare}
                title="Response Generation"
                description="All gathered information is synthesized into a comprehensive, accurate answer tailored to your question."
                number="5"
                delay={0.5}
              />
              <div className="relative">
                <motion.div 
                  className="h-full"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.6 }}
                >
                  <div className={`flex flex-col items-center justify-center h-full p-6 rounded-xl ${
                    isDarkMode ? 'bg-blue-500/10 border-blue-500/30' : 'bg-blue-50 border-blue-200'
                  } border-2 border-dashed`}>
                    <div className="mb-4">
                      <Clock className={`w-12 h-12 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
                    </div>
                    <p className={`text-center text-lg font-medium ${isDarkMode ? 'text-blue-300' : 'text-blue-600'}`}>
                      Watch the process in real-time
                    </p>
                    <p className={`text-center mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                      See each step as Prism researches your question
                    </p>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </section>

        {/* Research Modes Section */}
        <section className="py-24 bg-gradient-to-b from-transparent via-purple-900/5 to-transparent">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className={`text-3xl font-bold tracking-tight ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Specialized Research Modes
              </h2>
              <p className={`mt-4 max-w-2xl mx-auto text-lg ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                Customize how Prism approaches your market questions with specialized research modes.
              </p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.1 }}
                className={`p-8 rounded-xl ${
                  isDarkMode ? 'bg-gray-900/70 hover:bg-gray-800/80' : 'bg-white hover:bg-gray-50'
                } backdrop-blur-sm border ${
                  isDarkMode ? 'border-gray-800/50' : 'border-gray-200/70'
                } shadow-lg transition-all duration-300`}
              >
                <div className="p-4 rounded-lg bg-purple-500/10 text-purple-500 inline-block mb-6">
                  <BrainCircuit className="w-8 h-8" />
                </div>
                <h3 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  Standard Mode
                </h3>
                <p className={`mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  Balanced research approach for general market inquiries, providing well-rounded insights.
                </p>
                <ul className="space-y-2">
                  <li className="flex items-start">
                    <CheckCircle className={`w-5 h-5 mt-0.5 mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Comprehensive data gathering</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className={`w-5 h-5 mt-0.5 mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Balanced technical and fundamental</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className={`w-5 h-5 mt-0.5 mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Consensus market perspective</span>
                  </li>
                </ul>
              </motion.div>
              
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 }}
                className={`p-8 rounded-xl ${
                  isDarkMode ? 'bg-gray-900/70 hover:bg-gray-800/80' : 'bg-white hover:bg-gray-50'
                } backdrop-blur-sm border ${
                  isDarkMode ? 'border-gray-800/50' : 'border-gray-200/70'
                } shadow-lg transition-all duration-300`}
              >
                <div className="p-4 rounded-lg bg-blue-500/10 text-blue-500 inline-block mb-6">
                  <Database className="w-8 h-8" />
                </div>
                <h3 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  Deep Dive Mode
                </h3>
                <p className={`mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  Thorough analysis with comprehensive data examination for detailed market insights.
                </p>
                <ul className="space-y-2">
                  <li className="flex items-start">
                    <CheckCircle className={`w-5 h-5 mt-0.5 mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Extended historical analysis</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className={`w-5 h-5 mt-0.5 mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Detailed financial metrics</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className={`w-5 h-5 mt-0.5 mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>In-depth sector comparisons</span>
                  </li>
                </ul>
              </motion.div>
              
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.3 }}
                className={`p-8 rounded-xl ${
                  isDarkMode ? 'bg-gray-900/70 hover:bg-gray-800/80' : 'bg-white hover:bg-gray-50'
                } backdrop-blur-sm border ${
                  isDarkMode ? 'border-gray-800/50' : 'border-gray-200/70'
                } shadow-lg transition-all duration-300`}
              >
                <div className="p-4 rounded-lg bg-green-500/10 text-green-500 inline-block mb-6">
                  <LineChart className="w-8 h-8" />
                </div>
                <h3 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  Technical Mode
                </h3>
                <p className={`mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  Focus on technical indicators and chart patterns for trading-oriented analysis.
                </p>
                <ul className="space-y-2">
                  <li className="flex items-start">
                    <CheckCircle className={`w-5 h-5 mt-0.5 mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Multiple timeframe analysis</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className={`w-5 h-5 mt-0.5 mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Key support/resistance levels</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className={`w-5 h-5 mt-0.5 mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Trend and momentum indicators</span>
                  </li>
                </ul>
              </motion.div>
              
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.4 }}
                className={`p-8 rounded-xl ${
                  isDarkMode ? 'bg-gray-900/70 hover:bg-gray-800/80' : 'bg-white hover:bg-gray-50'
                } backdrop-blur-sm border ${
                  isDarkMode ? 'border-gray-800/50' : 'border-gray-200/70'
                } shadow-lg transition-all duration-300`}
              >
                <div className="p-4 rounded-lg bg-amber-500/10 text-amber-500 inline-block mb-6">
                  <Sparkles className="w-8 h-8" />
                </div>
                <h3 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  Discovery Mode
                </h3>
                <p className={`mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  Explore new market opportunities and emerging trends across various sectors.
                </p>
                <ul className="space-y-2">
                  <li className="flex items-start">
                    <CheckCircle className={`w-5 h-5 mt-0.5 mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Identify emerging sectors</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className={`w-5 h-5 mt-0.5 mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Find undervalued opportunities</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className={`w-5 h-5 mt-0.5 mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Broader market trend analysis</span>
                  </li>
                </ul>
              </motion.div>
              
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.5 }}
                className={`p-8 rounded-xl ${
                  isDarkMode ? 'bg-gray-900/70 hover:bg-gray-800/80' : 'bg-white hover:bg-gray-50'
                } backdrop-blur-sm border ${
                  isDarkMode ? 'border-gray-800/50' : 'border-gray-200/70'
                } shadow-lg transition-all duration-300`}
              >
                <div className="p-4 rounded-lg bg-red-500/10 text-red-500 inline-block mb-6">
                  <Target className="w-8 h-8" />
                </div>
                <h3 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  Contrarian Mode
                </h3>
                <p className={`mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  Challenge market consensus and explore alternative views on financial topics.
                </p>
                <ul className="space-y-2">
                  <li className="flex items-start">
                    <CheckCircle className={`w-5 h-5 mt-0.5 mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Identify market sentiment extremes</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className={`w-5 h-5 mt-0.5 mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Question popular assumptions</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className={`w-5 h-5 mt-0.5 mr-2 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Alternative data interpretation</span>
                  </li>
                </ul>
              </motion.div>
              
              <div className="md:col-span-2 lg:col-span-1">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.6 }}
                  className={`p-8 h-full rounded-xl ${
                    isDarkMode ? 'bg-gray-900/70' : 'bg-white'
                  } backdrop-blur-sm border border-blue-500/20 shadow-lg`}
                >
                  <div className="h-full flex flex-col justify-center">
                    <Box className={`w-12 h-12 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'} mb-6`} />
                    <h3 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      Try All Research Modes
                    </h3>
                    <p className={`mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                      Each research mode offers unique insights and approaches to your market questions.
                    </p>
                    <Link to="/market-chat" className={`inline-flex items-center justify-center px-6 py-3 rounded-lg ${
                      isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'
                    } text-white font-medium transition-colors mt-auto`}>
                      Start Researching
                      <ArrowRight className="ml-2 w-5 h-5" />
                    </Link>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </section>

        {/* User Interface Section */}
        <section className="py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className={`text-3xl font-bold tracking-tight ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Intuitive Research Interface
              </h2>
              <p className={`mt-4 max-w-2xl mx-auto text-lg ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                Prism's clean, user-friendly interface puts market intelligence at your fingertips.
              </p>
            </div>
            
            <div className="relative">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className={`rounded-xl overflow-hidden ${
                  isDarkMode ? 'shadow-blue-500/5' : 'shadow-lg'
                } shadow-2xl`}
              >
                <InterfaceVisualizationSVG />
                <ResearchProgressSVG />
              </motion.div>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.1 }}
                  className="flex flex-col items-center text-center"
                >
                  <div className={`p-4 rounded-full ${
                    isDarkMode ? 'bg-blue-500/10' : 'bg-blue-50'
                  } mb-4`}>
                    <Box className={`w-10 h-10 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
                  </div>
                  <h3 className={`text-xl font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    Clean Design
                  </h3>
                  <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                    Intuitive, distraction-free interface focused on delivering financial insights.
                  </p>
                </motion.div>
                
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.2 }}
                  className="flex flex-col items-center text-center"
                >
                  <div className={`p-4 rounded-full ${
                    isDarkMode ? 'bg-blue-500/10' : 'bg-blue-50'
                  } mb-4`}>
                    <Zap className={`w-10 h-10 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
                  </div>
                  <h3 className={`text-xl font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    Instant Insights
                  </h3>
                  <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                    Trending topics and real-time market movers right at your fingertips.
                  </p>
                </motion.div>
                
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.3 }}
                  className="flex flex-col items-center text-center"
                >
                  <div className={`p-4 rounded-full ${
                    isDarkMode ? 'bg-blue-500/10' : 'bg-blue-50'
                  } mb-4`}>
                    <MousePointer2 className={`w-10 h-10 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
                  </div>
                  <h3 className={`text-xl font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    One-Click Access
                  </h3>
                  <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                    Quick navigation and easy access to all market research tools.
                  </p>
                </motion.div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

// SVG Component for Research Query Analysis
const ResearchQueryAnalysisSVG = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <svg className="w-full h-64" viewBox="0 0 800 200">
      <text x="160" y="120" fontSize="12" fontWeight="bold" fill={isDarkMode ? "#a78bfa" : "#7c3aed"}>Query Intent Analysis</text>
      <text x="160" y="145" fontSize="10" fill={isDarkMode ? "#d1d5db" : "#4b5563"}>Market research on tech sector</text>
      <text x="160" y="165" fontSize="10" fill={isDarkMode ? "#d1d5db" : "#4b5563"}>Focus on AI chip manufacturers</text>
    </svg>
  );
};