// src/services/superChartService.ts
import { api } from './api';
import { polygonService } from './polygonService';
import logger from '../config/logger';

import { 

  ChartInterval,

  TIME_CONFIGS
} from '../types/superChart';
import { 
  ChartTimeframe, 
  SuperChartAnalysis, 
  ChartDataPoint,
  TechnicalPattern,
  IndicatorCrossover,
  PriceLevel
} from '../types/superChart';
import { 
  findLocalPeaks, 
  detectDoubleTop, 
  detectDoubleBottom, 
  detectTrend, 
  detectBreakouts,
  detectHeadAndShoulders,
  detectTriangles,
  detectFlags
} from './patternDetection';

interface ChartResponse {
  priceData: ChartDataPoint[];
  analysis: SuperChartAnalysis;
}

class SuperChartService {
  private static instance: SuperChartService;

  private constructor() {}

  static getInstance(): SuperChartService {
    if (!SuperChartService.instance) {
      SuperChartService.instance = new SuperChartService();
    }
    return SuperChartService.instance;
  }

  private getPolygonMultiplier(interval: ChartInterval): number {
    const multipliers: Record<ChartInterval, number> = {
      '1min': 1,
      '5min': 5,
      '15min': 15,
      '30min': 30,
      '1hour': 60,
      '4hour': 240,
      'day': 1,
      'week': 1,
      'month': 1
    };
    return multipliers[interval];
  }

  private getPolygonTimespan(interval: ChartInterval): string {
    if (interval.includes('min') || interval.includes('hour')) {
      return 'minute';
    }
    return interval;
  }

  private calculateTimeRange(timeframe: ChartTimeframe): { 
    startDate: Date; 
    endDate: Date;
  } {
    const now = new Date();
    const config = TIME_CONFIGS[timeframe];
    let startDate = new Date(now);

    switch (timeframe) {
      case '1H':
        startDate.setHours(now.getHours() - 1);
        break;
      case '4H':
        startDate.setHours(now.getHours() - 4);
        break;
      case '1D':
        startDate.setDate(now.getDate() - 1);
        break;
      case '1W':
        startDate.setDate(now.getDate() - 7);
        break;
      case '1M':
        startDate.setMonth(now.getMonth() - 1);
        break;
      case '3M':
        startDate.setMonth(now.getMonth() - 3);
        break;
      case '6M':
        startDate.setMonth(now.getMonth() - 6);
        break;
      case '1Y':
        startDate.setFullYear(now.getFullYear() - 1);
        break;
      case 'YTD':
        startDate = new Date(now.getFullYear(), 0, 1); // January 1st
        break;
      case 'MAX':
        startDate = new Date(now.getFullYear() - 10, now.getMonth(), now.getDate());
        break;
    }

    // Adjust for market hours
    if (['1H', '4H', '1D'].includes(timeframe)) {
      const marketOpen = new Date(now);
      marketOpen.setHours(9, 30, 0, 0);
      const marketClose = new Date(now);
      marketClose.setHours(16, 0, 0, 0);

      // If outside market hours, adjust to last market close
      if (now > marketClose || now < marketOpen) {
        now.setHours(16, 0, 0, 0);
        if (now < marketOpen) {
          now.setDate(now.getDate() - 1);
        }
      }
    }

    return { startDate, endDate: now };
  }

  async getAdvancedChartData(
    ticker: string, 
    timeframe: ChartTimeframe
  ): Promise<{
    priceData: ChartDataPoint[];
    analysis: SuperChartAnalysis;
  }> {
    try {
      const config = TIME_CONFIGS[timeframe];
      const { startDate, endDate } = this.calculateTimeRange(timeframe);
      
      console.log('Fetching chart data:', {
        ticker,
        timeframe,
        interval: config.interval,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      });

      const multiplier = this.getPolygonMultiplier(config.interval);
      const timespan = this.getPolygonTimespan(config.interval);

      const data = await polygonService.getAggregates(
        ticker,
        multiplier,
        timespan,
        startDate.toISOString().split('T')[0],
        endDate.toISOString().split('T')[0]
      );

      if (!data?.results?.length) {
        throw new Error(`No data available for ${ticker}`);
      }

      // Transform the data
      const priceData = data.results.map((bar: { t: number; c: number; o: number; h: number; l: number; v: number }) => ({
        timestamp: bar.t,
        price: bar.c,
        open: bar.o,
        high: bar.h,
        low: bar.l,
        close: bar.c,
        volume: bar.v
      }));

      // Generate analysis using pattern detection
      const analysis: SuperChartAnalysis = {
        patterns: this.analyzePatterns(priceData),
        levels: this.calculateSupportResistance(priceData),
        crossovers: this.detectCrossovers(priceData)
      };

      return {
        priceData,
        analysis
      };

    } catch (error) {
      console.log('Failed to fetch chart data:', error);
      throw error;
    }
  }

  private adjustForMarketHours(startDate: string, endDate: string, timeframe: ChartTimeframe) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    // Ensure we're not requesting future data
    if (end > new Date()) {
      end.setHours(0, 0, 0, 0);
    }
    
    // For intraday timeframes, adjust to last market day if current day has no data
    if (timeframe === '1D') {
      const now = new Date();
      const currentHour = now.getHours();
      
      // If it's before market open or on weekend, adjust to last market day
      if (currentHour < 9 || now.getDay() === 0 || now.getDay() === 6) {
        end.setDate(end.getDate() - 1);
        while (end.getDay() === 0 || end.getDay() === 6) {
          end.setDate(end.getDate() - 1);
        }
        start.setDate(end.getDate() - 1);
      }
    }

    return {
      startDate: start.toISOString().split('T')[0],
      endDate: end.toISOString().split('T')[0]
    };
  }

  private getTimeframeParams(timeframe: ChartTimeframe) {
    // Start with current date
    const now = new Date();
    let endDate = new Date(now);
    let startDate = new Date(now);
    let multiplier = 1;
    let timespan = 'minute';
  
    // Helper to get last trading day
    const getLastTradingDay = (date: Date): Date => {
      const d = new Date(date);
      while (d.getDay() === 0 || d.getDay() === 6) { // 0 = Sunday, 6 = Saturday
        d.setDate(d.getDate() - 1);
      }
      return d;
    };
  
    // Adjust end date to last trading day if needed
    const currentHour = now.getHours();
    const currentDay = now.getDay();
    
    if (currentDay === 0 || currentDay === 6 || currentHour < 9) {
      endDate = getLastTradingDay(endDate);
    }
  
    // Set time to end of trading day (4:00 PM)
    endDate.setHours(16, 0, 0, 0);
  
    // Calculate start date based on timeframe
    switch (timeframe) {
      case '1D': {
        // For 1D, start at previous trading day at market open
        startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 1);
        startDate = getLastTradingDay(startDate);
        startDate.setHours(9, 30, 0, 0);
        multiplier = 5;  // 5-minute bars
        timespan = 'minute';
        break;
      }
      
      case '1W': {
        startDate = new Date(endDate);
        // Go back 7 trading days
        let tradingDays = 0;
        while (tradingDays < 7) {
          startDate.setDate(startDate.getDate() - 1);
          if (startDate.getDay() !== 0 && startDate.getDay() !== 6) {
            tradingDays++;
          }
        }
        startDate.setHours(9, 30, 0, 0);
        multiplier = 30;  // 30-minute bars
        timespan = 'minute';
        break;
      }
      
      case '1M': {
        startDate = new Date(endDate);
        startDate.setMonth(startDate.getMonth() - 1);
        startDate = getLastTradingDay(startDate);
        startDate.setHours(9, 30, 0, 0);
        multiplier = 1;
        timespan = 'day';
        break;
      }
      
      case '3M': {
        startDate = new Date(endDate);
        startDate.setMonth(startDate.getMonth() - 3);
        startDate = getLastTradingDay(startDate);
        startDate.setHours(9, 30, 0, 0);
        multiplier = 1;
        timespan = 'day';
        break;
      }
      
      case '1Y': {
        startDate = new Date(endDate);
        startDate.setFullYear(startDate.getFullYear() - 1);
        startDate = getLastTradingDay(startDate);
        startDate.setHours(9, 30, 0, 0);
        multiplier = 1;
        timespan = 'day';
        break;
      }
      
      default: {
        // Default to 1D
        startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 1);
        startDate = getLastTradingDay(startDate);
        startDate.setHours(9, 30, 0, 0);
        multiplier = 5;
        timespan = 'minute';
      }
    }
  
    // Ensure start date isn't after end date
    if (startDate > endDate) {
      startDate = new Date(endDate);
      startDate.setHours(9, 30, 0, 0);
    }
  
    // Format dates for API - use full ISO string and extract date part
    return {
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0],
      multiplier,
      timespan
    };
  }
  private async getEMA(ticker: string, window: number, timeframe: ChartTimeframe) {
    try {
      const { timespan } = this.getTimeframeParams(timeframe);
      return await polygonService.getTechnicalIndicator(ticker, 'ema', {
        timespan,
        window,
        series_type: 'close'
      });
    } catch (error) {
      logger.warn(`Failed to fetch EMA-${window}:`, error);
      return [];
    }
  }

  private async getRSI(ticker: string, timeframe: ChartTimeframe) {
    try {
      const { timespan } = this.getTimeframeParams(timeframe);
      return await polygonService.getTechnicalIndicator(ticker, 'rsi', {
        timespan,
        window: 14,
        series_type: 'close'
      });
    } catch (error) {
      logger.warn('Failed to fetch RSI:', error);
      return [];
    }
  }

  private async getMACD(ticker: string, timeframe: ChartTimeframe) {
    try {
      const { timespan } = this.getTimeframeParams(timeframe);
      return await polygonService.getTechnicalIndicator(ticker, 'macd', {
        timespan,
        short_window: 12,
        long_window: 26,
        signal_window: 9,
        series_type: 'close'
      });
    } catch (error) {
      logger.warn('Failed to fetch MACD:', error);
      return [];
    }
  }

  private analyzePatterns(data: ChartDataPoint[]): TechnicalPattern[] {
    if (data.length < 10) return [];

    const patterns: TechnicalPattern[] = [];
    const peaks = findLocalPeaks(data);
    
    patterns.push(...detectDoubleTop(peaks));
    patterns.push(...detectDoubleBottom(peaks));
    
    const trend = detectTrend(data);
    if (trend) patterns.push(trend);
    
    const levels = this.calculateSupportResistance(data);
    patterns.push(...detectBreakouts(data, levels));
    const headAndShoulders = detectHeadAndShoulders(peaks);
    const triangles = detectTriangles(data);
    const flags = detectFlags(data);

    patterns.push(...headAndShoulders);
    patterns.push(...triangles);
    patterns.push(...flags);
    return patterns;
  }

  private calculateSupportResistance(data: ChartDataPoint[]): PriceLevel[] {
    if (data.length < 10) return [];

    const prices = data.map(point => point.price);
    const min = Math.min(...prices);
    const max = Math.max(...prices);
    
    // Find potential support/resistance levels using price clusters
    const priceStep = (max - min) / 10;
    const priceLevels: { price: number; touches: number }[] = [];
    
    for (let i = 0; i < data.length; i++) {
      const price = Math.round(data[i].price / priceStep) * priceStep;
      const existingLevel = priceLevels.find(level => Math.abs(level.price - price) < priceStep / 2);
      
      if (existingLevel) {
        existingLevel.touches++;
      } else {
        priceLevels.push({ price, touches: 1 });
      }
    }

    // Convert to PriceLevel format
    return priceLevels
      .filter(level => level.touches >= 3)
      .map(level => ({
        price: level.price,
        type: level.price > data[data.length - 1].price ? 'resistance' as 'resistance' : 'support' as 'support',
        strength: Math.min(10, Math.floor(level.touches / 2)),
        touches: level.touches
      }))
      .sort((a, b) => b.touches - a.touches)
      .slice(0, 5);
  }

  private detectCrossovers(data: ChartDataPoint[]): IndicatorCrossover[] {
    if (data.length < 2) return [];

    return data
      .slice(1)
      .map((point, i) => {
        const prev = data[i];
        if (!point.ema20 || !point.ema50 || !prev.ema20 || !prev.ema50) return null;

        if ((prev.ema20 < prev.ema50 && point.ema20 > point.ema50) ||
            (prev.ema20 > prev.ema50 && point.ema20 < point.ema50)) {
          return {
            timestamp: point.timestamp,
            type: 'ema_cross',
            direction: point.ema20 > point.ema50 ? 'bullish' : 'bearish',
            significance: 8
          };
        }
        return null;
      })
      .filter((crossover): crossover is IndicatorCrossover => crossover !== null);
  }
}

export const superChartService = SuperChartService.getInstance();