// client/src/pages/Market.tsx

import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { StockSearch } from '../components/market/StockSearch';
import { LineChart, TrendingUp } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export const Market: React.FC = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-12">
          <LineChart className={`w-12 h-12 mx-auto mb-4 ${
            isDarkMode ? 'text-blue-400' : 'text-blue-600'
          }`} />
          <h1 className={`text-3xl font-bold mb-4 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Market Data
          </h1>
          <p className={`text-lg ${
            isDarkMode ? 'text-gray-300' : 'text-gray-600'
          }`}>
            Search stocks and access real-time market data
          </p>
        </div>

        {/* Search Section */}
        <div className="max-w-2xl mx-auto">
          <StockSearch />
        </div>

        {/* Popular Stocks */}
        <div className="mt-12">
          <h2 className={`text-xl font-semibold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Popular Stocks
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {['AAPL', 'MSFT', 'GOOGL'].map((ticker) => (
              <div
                key={ticker}
                className={`p-6 rounded-lg ${
                  isDarkMode ? 'bg-gray-800' : 'bg-white'
                } hover:shadow-lg transition-shadow cursor-pointer`}
                onClick={() => navigate(`/market/stocks/${ticker}`)}
              >
                <div className="flex items-center justify-between mb-4">
                  <h3 className={`text-lg font-medium ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {ticker}
                  </h3>
                  <TrendingUp className="w-5 h-5 text-green-500" />
                </div>
                <div className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  View details →
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Market;