// src/pages/resources/events.tsx
import { Calendar as CalendarIcon, MapPin, Users, ExternalLink } from 'lucide-react';
import { MainLayout } from '../../components/layout/MainLayout';
import { Card, CardContent } from '../../components/ui/card';
import { useTheme } from '../../contexts/ThemeContext';
interface Event {
  id: string;
  title: string;
  description: string;
  date: string;
  time: string;
  location: string;
  type: 'Webinar' | 'Workshop' | 'Conference';
  capacity: number;
  registeredCount: number;
  speakers: Array<{
    name: string;
    role: string;
    avatar: string;
  }>;
}

const events: Event[] = [
  
  // Add more events...
];

const EventsPage = () => {
  const { isDarkMode } = useTheme();

  return (
   
      <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <CalendarIcon className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Upcoming Events
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Join our events to learn from experts and connect with the trading community
              </p>
            </div>
          </div>
        </div>

        {/* Events List */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
  <div className="space-y-8">
    {events.length > 0 ? (
      events.map((event) => (
        <Card
          key={event.id}
          className={`group hover:shadow-lg transition-shadow ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white'
          }`}
        >
          {/* Card content remains the same */}
          {/* ... */}
        </Card>
      ))
    ) : (
      <Card className={`text-center py-12 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white'}`}>
        <CardContent>
          <CalendarIcon className={`w-16 h-16 mx-auto mb-4 ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`} />
          <h3 className={`text-xl font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            No Upcoming Events
          </h3>
          <p className={`max-w-md mx-auto ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            There are no events scheduled at the moment. Please check back later for new events.
          </p>
        </CardContent>
      </Card>
    )}
  </div>
</div>

      </div>
    
  );
};

export default EventsPage;