import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { DocumentationLayout } from '../../components/layout/DocumentationLayout';
import { 
  BookOpen, 
  MessageSquare, 
  Search,
  LineChart,
  Database,
  Bookmark,
  Code,
  FileText,
  Video,
  ChevronRight,
  Lightbulb,
  Zap,
  Brain,
  Target,
  Sparkles,
  BarChart3,
  Globe,
  History,
  Shield,
  PencilRuler
} from 'lucide-react';

const Documentation = () => {
  const { isDarkMode } = useTheme();

  const featuredGuides = [
    {
      title: "How Prism AI Research Works",
      description: "Understand the multi-stage research process that powers Prism AI's market insights",
      icon: Brain,
      href: "/docs/prism-research-explained"
    },
    {
      title: "Maximizing Chat Effectiveness",
      description: "Learn how to craft queries that get the most insightful market analysis from Prism",
      icon: Zap,
      href: "/docs/effective-queries"
    },
    {
      title: "Building Your Investment Watchlist",
      description: "Create and organize custom watchlists to track your investments more effectively",
      icon: Target,
      href: "/docs/watchlist-guide"
    },
    {
      title: "Complete Guide to Stock Analysis",
      description: "Use Prism's AI to conduct thorough fundamental and technical analysis",
      icon: BarChart3,
      href: "/docs/stock-analysis"
    }
  ];

  return (
    <DocumentationLayout 
      title="Prism Documentation" 
      icon={BookOpen}
      description="Your complete guide to using Newsroom's AI-powered market research platform. From getting started with Prism chats to advanced trading analysis."
    >
      {/* Featured Guides Section */}
      <div className="mb-12">
        <h2 className={`text-xl font-bold mb-6 ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Featured Guides
        </h2>
        <div className="grid md:grid-cols-2 gap-6">
          {featuredGuides.map((guide) => (
            <Link
              key={guide.title}
              to={guide.href}
              className={`p-6 rounded-xl border transition-all hover:shadow-lg ${
                isDarkMode 
                  ? 'bg-gray-800/70 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              }`}
            >
              <div className={`p-3 rounded-lg inline-flex mb-4 ${
                isDarkMode ? 'bg-blue-500/20' : 'bg-blue-50'
              }`}>
                <guide.icon className={`w-6 h-6 ${
                  isDarkMode ? 'text-blue-400' : 'text-blue-500'
                }`} />
              </div>
              <h3 className={`font-bold text-lg mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {guide.title}
              </h3>
              <p className={`text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                {guide.description}
              </p>
            </Link>
          ))}
        </div>
      </div>
      
      {/* Quick Access Section */}
      <div className="mb-12">
        <h2 className={`text-xl font-bold mb-6 ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Quick Access
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <Link
            to="/docs/research-process"
            className={`flex items-center p-4 rounded-xl border transition-colors ${
              isDarkMode 
                ? 'bg-gray-700 border-gray-600 hover:border-blue-500' 
                : 'bg-gray-50 border-gray-200 hover:border-blue-500'
            }`}
          >
            <Globe className={`w-6 h-6 mr-3 ${
              isDarkMode ? 'text-blue-400' : 'text-blue-600'
            }`} />
            <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
              Research Process
            </span>
          </Link>

          <Link
            to="/docs/chat-chips"
            className={`flex items-center p-4 rounded-xl border transition-colors ${
              isDarkMode 
                ? 'bg-gray-700 border-gray-600 hover:border-blue-500' 
                : 'bg-gray-50 border-gray-200 hover:border-blue-500'
            }`}
          >
            <MessageSquare className={`w-6 h-6 mr-3 ${
              isDarkMode ? 'text-blue-400' : 'text-blue-600'
            }`} />
            <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
              Chat Chips
            </span>
          </Link>

          <Link
            to="/docs/watchlists"
            className={`flex items-center p-4 rounded-xl border transition-colors ${
              isDarkMode 
                ? 'bg-gray-700 border-gray-600 hover:border-blue-500' 
                : 'bg-gray-50 border-gray-200 hover:border-blue-500'
            }`}
          >
            <Bookmark className={`w-6 h-6 mr-3 ${
              isDarkMode ? 'text-blue-400' : 'text-blue-600'
            }`} />
            <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
              Watchlists
            </span>
          </Link>

          <Link
            to="/docs/canvas-intro"
            className={`flex items-center p-4 rounded-xl border transition-colors ${
              isDarkMode 
                ? 'bg-gray-700 border-gray-600 hover:border-blue-500' 
                : 'bg-gray-50 border-gray-200 hover:border-blue-500'
            }`}
          >
            <PencilRuler className={`w-6 h-6 mr-3 ${
              isDarkMode ? 'text-blue-400' : 'text-blue-600'
            }`} />
            <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
              Canvas
            </span>
          </Link>
        </div>
      </div>
      
      {/* Additional Resources Section */}
      <div>
        <h2 className={`text-xl font-bold mb-6 ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Additional Resources
        </h2>
        <div className="grid md:grid-cols-3 gap-6">
          <Link
            to="/docs/api"
            className={`flex items-center p-5 rounded-xl border transition-colors ${
              isDarkMode 
                ? 'bg-gray-700 border-gray-600 hover:border-blue-500' 
                : 'bg-white border-gray-200 hover:border-blue-500'
            }`}
          >
            <Code className="w-6 h-6 text-blue-500 mr-3" />
            <div>
              <h3 className={`font-medium ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                API Reference
              </h3>
              <p className={`text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`}>
                For developers
              </p>
            </div>
          </Link>

          <Link
            to="/docs/tutorials"
            className={`flex items-center p-5 rounded-xl border transition-colors ${
              isDarkMode 
                ? 'bg-gray-700 border-gray-600 hover:border-blue-500' 
                : 'bg-white border-gray-200 hover:border-blue-500'
            }`}
          >
            <Video className="w-6 h-6 text-blue-500 mr-3" />
            <div>
              <h3 className={`font-medium ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Video Tutorials
              </h3>
              <p className={`text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`}>
                Step-by-step guides
              </p>
            </div>
          </Link>

          <Link
            to="/docs/releases"
            className={`flex items-center p-5 rounded-xl border transition-colors ${
              isDarkMode 
                ? 'bg-gray-700 border-gray-600 hover:border-blue-500' 
                : 'bg-white border-gray-200 hover:border-blue-500'
            }`}
          >
            <History className="w-6 h-6 text-blue-500 mr-3" />
            <div>
              <h3 className={`font-medium ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Release Notes
              </h3>
              <p className={`text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`}>
                Latest updates
              </p>
            </div>
          </Link>
        </div>
      </div>
    </DocumentationLayout>
  );
};

export default Documentation;