// src/services/authService.ts
import apiService from './api';
import { api } from './api';
import { 
  AuthResponse,
  LoginCredentials, 
  RegisterCredentials, 
  SocialAuthCredentials,
  User,
  SocialAuthResponse
} from '../types/auth';
import { BetaValidationResponse } from '../types/beta';

export class AuthService {
  private static instance: AuthService;
  
  private constructor() {}
  
  static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  async getCurrentUser(): Promise<User> {
    try {
      const { data } = await api.get<User>('/auth/me');
      return data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async socialAuth(credentials: SocialAuthCredentials): Promise<AuthResponse> {
    try {
      const { data } = await api.post<AuthResponse>(
        `/auth/${credentials.provider}`, 
        credentials
      );
      if (data.token) {
        this.setToken(data.token);
      }
      return data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  

  async login(credentials: LoginCredentials): Promise<AuthResponse> {
    try {
      const { data } = await api.post<AuthResponse>('/auth/login', credentials);
      if (data.token) {
        this.setToken(data.token);
      }
      return data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async register(credentials: RegisterCredentials): Promise<AuthResponse> {
    try {
      const { data } = await api.post<AuthResponse>('/auth/register', credentials);
      if (data.token) {
        this.setToken(data.token);
      }
      return data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async validateBetaCode(code: string): Promise<BetaValidationResponse> {
    try {
      const { data } = await api.post<BetaValidationResponse>('/beta/validate', { code });
      return data;
    } catch (error) {
      throw new Error('Failed to validate beta code');
    }
  }

  private setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  private getToken(): string | null {
    return localStorage.getItem('token');
  }

  logout(): void {
    localStorage.removeItem('token');
  }

  private handleError(error: unknown): Error {
    if (error instanceof Error) {
      return error;
    }
    return new Error('An unexpected error occurred');
  }

  // Add these methods to authService.ts

async updateProfile(updates: Partial<User>): Promise<User> {
  try {
    const { data } = await api.put<AuthResponse>('/auth/profile', updates);
    return this.normalizeUser(data.user);
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error('Failed to update profile');
  }
}

async updatePassword(currentPassword: string, newPassword: string): Promise<void> {
  try {
    await api.put('/auth/password', { currentPassword, newPassword });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error('Failed to update password');
  }
}

async verifyPhone(phoneNumber: string): Promise<{ verificationId: string }> {
  try {
    const { data } = await api.post('/auth/verify-phone/start', { phoneNumber });
    return data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error('Failed to start phone verification');
  }
}

async confirmPhoneVerification(verificationId: string, code: string): Promise<void> {
  try {
    await api.post('/auth/verify-phone/confirm', { verificationId, code });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error('Failed to confirm phone verification');
  }
}

async exportUserData(userId: string): Promise<any> {
  try {
    const { data } = await api.get(`/auth/export-data/${userId}`);
    return data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error('Failed to export user data');
  }
}

async deleteAccount(userId?: string): Promise<void> {
  try {
    await api.delete(`/auth/account/${userId}`);
    this.logout(); // Clear auth state after deletion
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error('Failed to delete account');
  }
}

async requestPasswordReset(email: string): Promise<void> {
  try {
    await api.post('/auth/forgot-password', { email });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error('Failed to request password reset');
  }
}

async validateResetToken(token: string): Promise<boolean> {
  try {
    const { data } = await api.get(`/auth/reset-password/validate/${token}`);
    return data.valid;
  } catch (error) {
    return false;
  }
}

async resetPassword(token: string, newPassword: string): Promise<void> {
  try {
    await api.post('/auth/reset-password', { token, newPassword });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error('Failed to reset password');
  }
}

  private normalizeUser(data: any): User {
    return {
      id: data.id,
      email: data.email,
      name: data.name,
      role: data.role,
      experience: data.experience,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      avatarUrl: data.avatarUrl,
      socialProviders: data.socialProviders
    };
  }
}

export const authService = AuthService.getInstance();