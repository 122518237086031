import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Share2,
  Download,
  FileText,
  Mail,
  Link as LinkIcon,
  Code,
  Image,
  ChevronRight,
  UserPlus,
  Lock,
  AlertTriangle,
  Check,
  Info,
  Clipboard
} from 'lucide-react';

const CanvasExport = () => {
  const { isDarkMode } = useTheme();

  const exportFormats = [
    {
      title: 'Image Formats',
      description: 'Export your canvas as high-quality images for presentations, reports, or social media.',
      icon: Image,
      formats: [
        {
          name: 'PNG',
          description: 'High-quality image with transparent background support',
          resolution: 'Up to 4K resolution (3840×2160)',
          bestFor: 'Sharing on social media, embedding in documents'
        },
        {
          name: 'JPEG',
          description: 'Compressed image format with smaller file size',
          resolution: 'Up to 4K resolution (3840×2160)',
          bestFor: 'Email attachments, web usage where file size matters'
        },
        {
          name: 'SVG',
          description: 'Vector format that scales perfectly to any size',
          resolution: 'Unlimited (vector-based)',
          bestFor: 'Professional publications, scaling to different sizes'
        }
      ]
    },
    {
      title: 'Document Formats',
      description: 'Export your analysis into structured document formats for detailed reports.',
      icon: FileText,
      formats: [
        {
          name: 'PDF',
          description: 'Professional document format with embedded charts and analysis',
          features: 'Preserves layout, interactive elements, clickable links',
          bestFor: 'Formal reports, investment presentations'
        },
        {
          name: 'HTML',
          description: 'Interactive web-ready version of your canvas',
          features: 'Maintains interactivity, zoomable charts, clickable elements',
          bestFor: 'Web publishing, interactive reports'
        },
        {
          name: 'Markdown',
          description: 'Text-based format with embedded images',
          features: 'Easy to edit, compatible with most documentation systems',
          bestFor: 'Technical documentation, GitHub repositories'
        }
      ]
    },
    {
      title: 'Data Formats',
      description: 'Export the underlying data for further analysis in other tools.',
      icon: Code,
      formats: [
        {
          name: 'JSON',
          description: 'Structured data format with all canvas elements and relationships',
          features: 'Complete canvas structure, metadata, relationships between elements',
          bestFor: 'Importing to other applications, programmatic analysis'
        },
        {
          name: 'CSV',
          description: 'Tabular data export of charts and datasets',
          features: 'Compatible with Excel, Google Sheets, and other data tools',
          bestFor: 'Further analysis in spreadsheet applications'
        },
        {
          name: 'API Endpoint',
          description: 'Dynamic link that always provides the latest data',
          features: 'Real-time updates, authentication options, webhook capabilities',
          bestFor: 'Integration with other systems, always updated data'
        }
      ]
    }
  ];

  const sharingMethods = [
    {
      title: 'Direct Link Sharing',
      description: 'Generate a secure URL that gives recipients direct access to your canvas.',
      icon: LinkIcon,
      steps: [
        'Click the "Share" button in the top-right corner of the canvas',
        'Select "Generate Link" from the dropdown menu',
        'Choose permission level (View only, Comment, or Edit)',
        'Copy the generated link or use the email option to send directly'
      ],
      tips: [
        'Links can be set to expire after a specific time period',
        'Track who has accessed your shared link in the sharing dashboard',
        'Add password protection for sensitive canvases'
      ]
    },
    {
      title: 'Team Collaboration',
      description: 'Share with specific team members or groups with granular permission controls.',
      icon: UserPlus,
      steps: [
        'Click the "Share" button in the top-right corner of the canvas',
        'Select the "Invite People" tab',
        'Enter email addresses or select from your contacts',
        'Set permission level for each recipient (View, Comment, Edit)',
        'Add an optional message and send invitations'
      ],
      tips: [
        'Recipients will receive email notifications with access instructions',
        'Set up role-based permissions for different team members',
        'Enable/disable download capabilities for specific users'
      ]
    },
    {
      title: 'Embed in Other Platforms',
      description: 'Integrate your canvas into websites, dashboards, or other applications.',
      icon: Code,
      steps: [
        'Click the "Share" button in the top-right corner of the canvas',
        'Select "Embed" from the dropdown menu',
        'Choose size and display options',
        'Copy the generated embed code',
        'Paste the code into the HTML of your destination page'
      ],
      tips: [
        'Embedded canvases can be set to auto-update with the latest changes',
        'Control whether viewers can interact with the embedded canvas',
        'Set custom dimensions or responsive sizing options'
      ]
    }
  ];

  const securitySettings = [
    {
      setting: 'Access Controls',
      description: 'Determine who can view, edit, or share your exported canvas.',
      options: [
        'Public (anyone with the link)',
        'Organization-only (only users in your company)',
        'Private (only specific users you invite)',
        'Password-protected (requires a password to access)'
      ]
    },
    {
      setting: 'Permission Levels',
      description: 'Set what actions recipients can take with your shared canvas.',
      options: [
        'View only (can only see the canvas)',
        'Comment (can view and add comments)',
        'Edit (can make changes to the canvas)',
        'Admin (can change permissions and invite others)'
      ]
    },
    {
      setting: 'Watermarking',
      description: 'Add visible ownership information to exported files.',
      options: [
        'None (no watermark)',
        'Subtle (light company logo or username)',
        'Standard (visible but unobtrusive branding)',
        'Confidential (includes "Confidential" marking and timestamp)'
      ]
    }
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Share2 className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Canvas Export & Sharing
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Save, export, and share your canvas analysis with colleagues and clients
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              Prism Canvas provides versatile options for exporting and sharing your analysis. Whether you need to 
              create high-quality images for presentations, generate comprehensive reports, or collaborate with team members, 
              our export and sharing tools make it easy to communicate your insights effectively and securely.
            </p>
          </div>

          {/* Export Formats */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Export Formats
          </h2>

          <div className="space-y-12 mb-16">
            {exportFormats.map((format, index) => {
              const Icon = format.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    } mr-4`}>
                      <Icon className={`w-6 h-6 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <div>
                      <h3 className={`text-xl font-bold ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {format.title}
                      </h3>
                    </div>
                  </div>
                  
                  <p className={`mb-6 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {format.description}
                  </p>
                  
                  <div className="overflow-x-auto">
                    <table className={`w-full ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-600'
                    }`}>
                      <thead>
                        <tr className={`border-b ${
                          isDarkMode ? 'border-gray-700' : 'border-gray-200'
                        }`}>
                          <th className="text-left py-2 px-4 font-medium">Format</th>
                          <th className="text-left py-2 px-4 font-medium">Description</th>
                          <th className="text-left py-2 px-4 font-medium">
                            {'resolution' in format.formats[0] ? 'Resolution' : 'Features'}
                          </th>
                          <th className="text-left py-2 px-4 font-medium">Best For</th>
                        </tr>
                      </thead>
                      <tbody>
                        {format.formats.map((subformat, i) => (
                          <tr key={i} className={`border-b ${
                            isDarkMode ? 'border-gray-700' : 'border-gray-200'
                          }`}>
                            <td className="py-3 px-4 font-medium">{subformat.name}</td>
                            <td className="py-3 px-4">{subformat.description}</td>
                            <td className="py-3 px-4">{'resolution' in subformat ? subformat.resolution : subformat.features}</td>
                            <td className="py-3 px-4">{subformat.bestFor}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
          </div>

          {/* How to Export */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            How to Export Your Canvas
          </h2>

          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <ol className={`space-y-4 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white font-medium mr-3 mt-0.5">1</div>
                <div>
                  <p className="font-medium mb-1">Open the Export Menu</p>
                  <p className="text-sm">Click the "Export" button in the top toolbar of the canvas interface, or use the keyboard shortcut Ctrl+E (Windows/Linux) or Cmd+E (Mac).</p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white font-medium mr-3 mt-0.5">2</div>
                <div>
                  <p className="font-medium mb-1">Select Export Format</p>
                  <p className="text-sm">Choose your desired export format from the available options (image, document, or data format).</p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white font-medium mr-3 mt-0.5">3</div>
                <div>
                  <p className="font-medium mb-1">Configure Export Settings</p>
                  <p className="text-sm">Adjust settings specific to your chosen format (resolution, quality, data fields, etc.).</p>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white font-medium mr-3 mt-0.5">4</div>
                <div>
                  <p className="font-medium mb-1">Export File</p>
                  <p className="text-sm">Click the "Export" button to generate your file. Depending on the format, you'll either download the file directly or be given a link for access.</p>
                </div>
              </li>
            </ol>
          </div>

          {/* Sharing Methods */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Sharing Methods
          </h2>

          <div className="space-y-8 mb-16">
            {sharingMethods.map((method, index) => {
              const Icon = method.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    } mr-4`}>
                      <Icon className={`w-6 h-6 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <h3 className={`text-xl font-bold ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {method.title}
                    </h3>
                  </div>
                  
                  <p className={`mb-4 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {method.description}
                  </p>
                  
                  <div className="grid md:grid-cols-2 gap-8">
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Steps:</h4>
                      <ol className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {method.steps.map((step, i) => (
                          <li key={i} className="flex items-start">
                            <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">{i+1}</div>
                            <span>{step}</span>
                          </li>
                        ))}
                      </ol>
                    </div>
                    
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Tips:</h4>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {method.tips.map((tip, i) => (
                          <li key={i} className="flex items-start">
                            <span className="w-1.5 h-1.5 rounded-full bg-green-500 mt-1.5 mr-2 flex-shrink-0" />
                            <span>{tip}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Security & Access Controls */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Security & Access Controls
          </h2>

          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              When sharing sensitive market analysis, it's important to control who can access your work 
              and what they can do with it. Prism Canvas provides comprehensive security options for shared content.
            </p>
            
            <div className="space-y-6">
              {securitySettings.map((setting, index) => (
                <div key={index}>
                  <h3 className={`text-lg font-medium mb-2 flex items-center ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    <Lock className="w-5 h-5 mr-2 text-blue-500" />
                    {setting.setting}
                  </h3>
                  <p className={`mb-3 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    {setting.description}
                  </p>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                    {setting.options.map((option, i) => (
                      <div key={i} className={`p-3 rounded ${
                        isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                      }`}>
                        <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                          {option}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Best Practices */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <h3 className={`text-xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Best Practices for Sharing Analysis
            </h3>
            
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h4 className={`font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Do:</h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Apply appropriate access controls based on content sensitivity</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Add clear context and explanations to shared analysis</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Regularly review who has access to your shared canvases</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Choose the most appropriate format for your audience's needs</span>
                  </li>
                </ul>
              </div>
              
              <div>
                <h4 className={`font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Avoid:</h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <AlertTriangle className="w-4 h-4 text-yellow-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Using public sharing for sensitive financial analysis</span>
                  </li>
                  <li className="flex items-start">
                    <AlertTriangle className="w-4 h-4 text-yellow-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Sharing canvas with edit permissions unless collaboration is needed</span>
                  </li>
                  <li className="flex items-start">
                    <AlertTriangle className="w-4 h-4 text-yellow-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Forgetting to include necessary context for your analysis</span>
                  </li>
                  <li className="flex items-start">
                    <AlertTriangle className="w-4 h-4 text-yellow-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Leaving old shared links active longer than necessary</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Compliance Notice */}
          <div className={`p-6 rounded-xl border ${
            isDarkMode ? 'bg-amber-900/20 border-amber-800/30' : 'bg-amber-50 border-amber-100'
          } mb-12`}>
            <div className="flex items-start">
              <Info className={`w-6 h-6 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-amber-400' : 'text-amber-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Compliance Notice</h3>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  Always ensure that any shared financial analysis complies with your organization's policies and relevant 
                  regulations regarding financial information sharing. Consider whether the content contains material non-public 
                  information or other sensitive data before sharing with external parties.
                </p>
              </div>
            </div>
          </div>

          {/* Related Documentation */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Related Documentation
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <a 
              href="/docs/annotations"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Chart Annotations
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Learn how to add notes and markings to your charts
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
            
            <a 
              href="/docs/patterns"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Pattern Recognition
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Automatically identify chart patterns with AI
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default CanvasExport;