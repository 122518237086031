import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Shield, 
  UserCheck,
  Database,
  Eye,
  Lock,
  Server,
  RefreshCw,
  ChevronRight,
  Globe,
  Download,
  Check,
  Info,
  AlertTriangle
} from 'lucide-react';

const PrivacyDoc = () => {
  const { isDarkMode } = useTheme();

  const privacyPolicySections = [
    {
      title: 'Information Collection',
      description: 'Types of data we collect and how we use it',
      icon: Database,
      features: [
        'Account information (name, email, password)',
        'Usage data and interaction patterns',
        'Trading preferences and watchlists',
        'Search queries and research interests',
        'Device information and IP address'
      ],
      standard: 'Compliant with GDPR and CCPA requirements'
    },
    {
      title: 'Data Usage',
      description: 'How your information improves your experience',
      icon: UserCheck,
      features: [
        'Personalized research results and recommendations',
        'Trading insights tailored to your interests',
        'Service improvements and feature development',
        'Account security and fraud prevention',
        'Communication about platform updates'
      ],
      standard: 'Purpose limitation principle - data only used for stated purposes'
    },
    {
      title: 'Data Sharing',
      description: 'When and how we share your information',
      icon: Globe,
      features: [
        'Service providers that help operate our platform',
        'Identity verification and payment processors',
        'With your explicit consent only',
        'As required by applicable law or regulation',
        'No selling of personal data to third parties'
      ],
      standard: 'Strict data sharing agreements with all third parties'
    },
    {
      title: 'Your Controls',
      description: 'Tools to manage your privacy preferences',
      icon: Eye,
      features: [
        'Data export functionality',
        'Account deletion options',
        'Email notification preferences',
        'Privacy settings dashboard',
        'Opt-out mechanisms for non-essential processing'
      ],
      standard: 'Designed for maximum user control over personal data'
    }
  ];

  const dataRights = [
    {
      title: 'Access & Portability',
      recommendations: [
        'View all your personal data in your account settings',
        'Export your data in machine-readable format',
        'Request additional information we may have',
        'Review all connected applications and services'
      ]
    },
    {
      title: 'Control & Deletion',
      recommendations: [
        'Update your personal information at any time',
        'Delete specific items from your account',
        'Request complete account deletion',
        'Withdraw consent for specific processing activities'
      ]
    },
    {
      title: 'Preferences & Communication',
      recommendations: [
        'Manage email notification preferences',
        'Control data processing settings',
        'Update marketing preferences',
        'Set privacy level for your account visibility'
      ]
    }
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Shield className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Privacy & Data Protection
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                How we protect your privacy and give you control over your data
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              At Prism, we take your privacy seriously. Our platform is built with privacy by design principles, 
              ensuring that you maintain control over your personal information while still benefiting from our 
              powerful AI-driven market research and analysis. This document explains our approach to privacy, 
              how we handle your data, and the tools we provide for you to control your information.
            </p>
          </div>

          {/* Privacy Features */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Privacy Policy Overview
          </h2>

          <div className="space-y-12 mb-16">
            {privacyPolicySections.map((section, index) => {
              const Icon = section.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    } mr-4`}>
                      <Icon className={`w-6 h-6 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <div>
                      <h3 className={`text-xl font-bold ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {section.title}
                      </h3>
                    </div>
                  </div>
                  
                  <p className={`mb-6 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {section.description}
                  </p>
                  
                  <div className="grid md:grid-cols-2 gap-8">
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Details:</h4>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {section.features.map((item, i) => (
                          <li key={i} className="flex items-start">
                            <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                            <span>{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div className={`rounded-lg p-4 ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                    }`}>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Compliance Standard:</h4>
                      <p className={`${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {section.standard}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Your Data Rights */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Your Data Rights
          </h2>

          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="grid md:grid-cols-3 gap-6">
              {dataRights.map((section, index) => (
                <div key={index}>
                  <h3 className={`text-lg font-medium mb-4 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {section.title}
                  </h3>
                  <ul className={`space-y-2 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {section.recommendations.map((rec, i) => (
                      <li key={i} className="flex items-start">
                        <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                        <span>{rec}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          {/* Data Export Section */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Data Export
          </h2>

          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="flex items-start mb-6">
              <Download className={`w-6 h-6 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Exporting Your Data</h3>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  You can export all your data at any time from your account settings. The exported file will include:
                </p>
                <ul className={`space-y-2 mb-4 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Personal account information</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Watchlists and saved stocks</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Chat history and research conversations</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Canvas documents and annotations</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>Usage history and preferences</span>
                  </li>
                </ul>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  To export your data, go to Settings → Privacy → Export Data, and choose your preferred format (JSON or CSV).
                </p>
              </div>
            </div>
          </div>

          {/* Important Privacy Notice */}
          <div className={`p-6 rounded-xl border ${
            isDarkMode ? 'bg-blue-900/20 border-blue-800/30' : 'bg-blue-50 border-blue-100'
          } mb-12`}>
            <div className="flex items-start">
              <Info className={`w-6 h-6 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Privacy Policy Updates</h3>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  We regularly review and update our privacy practices to ensure they comply with evolving regulations and best practices.
                  When we make significant changes to our Privacy Policy, we'll notify you via email and through the platform.
                  You can always find the latest version of our Privacy Policy on our website.
                </p>
              </div>
            </div>
          </div>

          {/* Related Documentation */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Related Documentation
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <a 
              href="/docs/security"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Security
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Learn about our comprehensive security measures
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
            
            <a 
              href="/docs/data-export"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Data Export
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  How to export and manage your personal data
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default PrivacyDoc;