import React, { useState, useEffect } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { SubscriptionPayment } from '../components/payments/StripePayment';
import { CreditCard, Check } from 'lucide-react';

export const Subscribe: React.FC = () => {
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const [isAnnual, setIsAnnual] = useState(true);
  const [subscriptionComplete, setSubscriptionComplete] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState<string | null>(null);
  const navigate = useNavigate();

  // Read interval from URL parameters on component mount
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const interval = params.get('interval');
    if (interval === 'monthly') {
      setIsAnnual(false);
    } else if (interval === 'annual') {
      setIsAnnual(true);
    }
  }, [location.search]);

  // Handle success
  const handleSuccess = () => {
    setSubscriptionComplete(true);
    setTimeout(() => {
      navigate('/dashboard');
    }, 2000);
  };

  // Handle error
  const handleError = (error: string) => {
    setSubscriptionError(error);
    setTimeout(() => {
      setSubscriptionError(null);
    }, 5000);
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <div className="max-w-4xl mx-auto pt-12 pb-24 px-4">
        <div className="text-center mb-12">
          <h1 className={`text-3xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Complete Your Pro Subscription
          </h1>
          <p className={`mt-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            Unlock all features and get unlimited access to our premium tools
          </p>
        </div>

        {/* Subscription type toggle */}
        <div className="flex justify-center mb-12">
          <div className="flex items-center space-x-2">
            <span className={`text-sm ${
              !isAnnual 
                ? isDarkMode ? 'text-white' : 'text-gray-900' 
                : isDarkMode ? 'text-gray-400' : 'text-gray-500'
            }`}>
              Monthly
            </span>
            <button
              onClick={() => setIsAnnual(!isAnnual)}
              className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                isAnnual ? 'bg-blue-600' : isDarkMode ? 'bg-gray-600' : 'bg-gray-200'
              }`}
            >
              <span className={`inline-block h-4 w-4 transform rounded-full bg-white transition ${
                isAnnual ? 'translate-x-6' : 'translate-x-1'
              }`} />
            </button>
            <span className={`text-sm ${
              isAnnual 
                ? isDarkMode ? 'text-white' : 'text-gray-900' 
                : isDarkMode ? 'text-gray-400' : 'text-gray-500'
            }`}>
              Annual
            </span>
            <span className="ml-2 inline-flex items-center text-sm text-green-500">
              Save 20%
            </span>
          </div>
        </div>

        {/* Payment section */}
        <div className={`max-w-lg mx-auto rounded-2xl ${
          isDarkMode ? 'bg-gray-800/80 border border-gray-700' : 'bg-white border border-gray-200'
        } shadow-xl backdrop-blur-sm overflow-hidden`}>
          <div className="p-6 border-b border-gray-200 dark:border-gray-700">
            <div className="flex items-center space-x-3">
              <div className={`p-2 rounded-full ${isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'}`}>
                <CreditCard className={`h-5 w-5 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
              </div>
              <h2 className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Pro Subscription - {isAnnual ? 'Annual' : 'Monthly'}
              </h2>
            </div>
          </div>

          {/* Payment form */}
          <div className="px-6 py-8">
            <SubscriptionPayment 
              interval={isAnnual ? 'year' : 'month'}
              onSuccess={handleSuccess} 
              onError={handleError}
            />
          </div>
        </div>

        {/* Success/Error message */}
        {subscriptionComplete && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="mt-6 p-4 rounded-lg bg-green-500/10 border border-green-500/20 flex items-center"
          >
            <Check className="h-5 w-5 text-green-500 mr-2" />
            <span className={`${isDarkMode ? 'text-green-400' : 'text-green-700'}`}>
              Subscription successful! Redirecting to your dashboard...
            </span>
          </motion.div>
        )}

        {subscriptionError && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="mt-6 p-4 rounded-lg bg-red-500/10 border border-red-500/20"
          >
            <span className={`${isDarkMode ? 'text-red-400' : 'text-red-700'}`}>
              {subscriptionError}
            </span>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default Subscribe;