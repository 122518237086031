import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Brain, 
  Globe, 
  Search, 
  Database, 
  LineChart, 
  ArrowRight, 
  ChevronRight, 
  AlertCircle 
} from 'lucide-react';

const ResearchStages = () => {
  const { isDarkMode } = useTheme();

  const stages = [
    {
      id: 'QUERY_ANALYSIS',
      name: 'Query Analysis',
      icon: Brain,
      description: 'Prism analyzes your question to determine the optimal research strategy, including understanding intent, identifying potential stocks, and planning the research process.',
      details: [
        'Intent recognition to categorize queries (technical, fundamental, news, etc.)',
        'Implicit ticker identification from natural language',
        'Time frame detection (short-term vs. long-term analysis)',
        'Determining required data sources and research priority'
      ]
    },
    {
      id: 'WEB_DISCOVERY',
      name: 'Web Research',
      icon: Globe,
      description: 'Prism conducts targeted web searches to gather relevant information about your query topic, extracting insights from trusted financial sources.',
      details: [
        'Dynamic generation of search queries based on context',
        'Source evaluation and prioritization',
        'Content extraction and filtering for relevance',
        'Fact verification across multiple sources'
      ]
    },
    {
      id: 'TICKER_DISCOVERY',
      name: 'Stock Identification',
      icon: Search,
      description: 'Prism identifies the most relevant stocks related to your query, establishing confidence scores for each potential match.',
      details: [
        'Named entity recognition for company mentions',
        'Industry and sector analysis for thematic queries',
        'Confidence scoring based on relevance to original query',
        'Disambiguation of similar company names and tickers'
      ]
    },
    {
      id: 'DATA_GATHERING',
      name: 'Data Collection',
      icon: Database,
      description: 'Prism gathers comprehensive market data for identified stocks, including price history, fundamentals, news sentiment, and technical indicators.',
      details: [
        'Real-time and historical price data retrieval',
        'Financial statement analysis where relevant',
        'Technical indicator calculation',
        'News sentiment aggregation and analysis',
        'SEC filing examination for material information'
      ]
    },
    {
      id: 'ANALYSIS',
      name: 'Comprehensive Analysis',
      icon: LineChart,
      description: 'Prism synthesizes all gathered information to produce a coherent analysis, identifying patterns, risks, and opportunities relevant to your query.',
      details: [
        'Cross-referencing data points across multiple sources',
        'Pattern recognition in price movements and fundamentals',
        'Detecting divergences between indicators',
        'Contextualizing analysis within broader market conditions',
        'Generating actionable insights based on findings'
      ]
    }
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <LineChart className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Understanding Research Stages
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Learn how Prism's multi-stage research process delivers comprehensive market insights
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className={`mb-12 p-6 rounded-xl border ${
            isDarkMode ? 'bg-gray-800/50 border-gray-700' : 'bg-white/50 border-gray-200'
          }`}>
            <div className="flex items-start">
              <AlertCircle className={`w-6 h-6 mt-1 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Why Research Stages Matter</h3>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  Prism's research process is split into distinct stages to ensure comprehensive, accurate, and transparent analysis. Each stage builds upon the previous one, creating a robust research pipeline that transforms your natural language query into actionable market insights.
                </p>
              </div>
            </div>
          </div>

          {/* Research Stages */}
          <div className="space-y-12">
            {stages.map((stage, index) => {
              const Icon = stage.icon;
              return (
                <div key={stage.id} className={`relative ${index < stages.length - 1 ? 'pb-12' : ''}`}>
                  {/* Connector Line */}
                  {index < stages.length - 1 && (
                    <div className={`absolute left-6 top-14 bottom-0 w-0.5 ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
                    }`} />
                  )}
                  
                  <div className={`p-6 rounded-xl border ${
                    isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                  }`}>
                    <div className="flex items-center mb-4">
                      <div className={`p-2 rounded-lg mr-4 ${
                        isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                      }`}>
                        <Icon className={`w-6 h-6 ${
                          isDarkMode ? 'text-blue-400' : 'text-blue-600'
                        }`} />
                      </div>
                      <div>
                        <span className={`text-sm font-medium ${
                          isDarkMode ? 'text-blue-400' : 'text-blue-600'
                        }`}>
                          Stage {index + 1}
                        </span>
                        <h3 className={`text-xl font-bold ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>
                          {stage.name}
                        </h3>
                      </div>
                    </div>
                    
                    <p className={`mb-6 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-600'
                    }`}>
                      {stage.description}
                    </p>
                    
                    <div className={`p-4 rounded-lg ${
                      isDarkMode ? 'bg-gray-900/50' : 'bg-gray-50'
                    }`}>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Key Activities:</h4>
                      <ul className="space-y-2">
                        {stage.details.map((detail, i) => (
                          <li key={i} className="flex items-start">
                            <ArrowRight className={`w-4 h-4 mt-1 mr-2 flex-shrink-0 ${
                              isDarkMode ? 'text-blue-400' : 'text-blue-600'
                            }`} />
                            <span className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                              {detail}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Next Steps */}
          <div className="mt-16">
            <h2 className={`text-2xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Next Steps
            </h2>
            <div className="grid md:grid-cols-2 gap-6">
              <a
                href="/docs/source-types"
                className={`p-6 rounded-xl border flex items-center ${
                  isDarkMode 
                    ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                    : 'bg-white border-gray-200 hover:border-blue-500'
                } transition-colors`}
              >
                <div>
                  <h3 className={`font-medium mb-1 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    Source Types & Quality
                  </h3>
                  <p className={`text-sm ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`}>
                    Learn about the different types of sources Prism uses
                  </p>
                </div>
                <ChevronRight className={`ml-auto w-5 h-5 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`} />
              </a>
              <a
                href="/docs/research-progress"
                className={`p-6 rounded-xl border flex items-center ${
                  isDarkMode 
                    ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                    : 'bg-white border-gray-200 hover:border-blue-500'
                } transition-colors`}
              >
                <div>
                  <h3 className={`font-medium mb-1 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    Viewing Research Progress
                  </h3>
                  <p className={`text-sm ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`}>
                    How to track and interpret Prism's research in real-time
                  </p>
                </div>
                <ChevronRight className={`ml-auto w-5 h-5 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ResearchStages;