import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { imageToBase64 } from '../../utils/imageUtils';
import {
  Command,
  Settings,
  ChevronDown,
  Brain,
  Share2,
  Download,
  ExternalLink,
  LayoutDashboard,
  Sparkles,
  BrainCircuit,
  Star,
  Lock,
  Edit2,
  LucideIcon,
  PenBox,
  Flame,
  Zap,
  Loader2,
  Plus
} from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import PopupMenu from '../common/PopupMenu';
import { toast } from 'react-hot-toast';
import { jsPDF } from 'jspdf';
// Import autoTable properly
import autoTable from 'jspdf-autotable';
import { useChat } from '../../contexts/ChatContext';
import { ChatMessage, ResearchSource } from '../../types/chat';

// AI models configuration
const aiModels = [
  {
    id: 'claude3-opus',
    name: 'Claude 3 Opus',
    icon: BrainCircuit,
    description: 'Most capable model with enhanced reasoning',
    available: true
  },
  {
    id: 'gpt4',
    name: 'GPT-4',
    icon: Flame,
    description: 'OpenAI\'s most advanced model',
    available: false
  },
  {
    id: 'claude3-sonnet',
    name: 'Claude 3 Sonnet',
    icon: Zap,
    description: 'Balanced performance and speed',
    available: false
  },
  {
    id: 'gemini-pro',
    name: 'Gemini Pro',
    icon: Sparkles,
    description: 'Google\'s most capable model',
    available: false
  },
];

interface MarketChatHeaderProps {
  onOpenSettings: () => void;
  sessionTitle?: string;
  isGeneratingTitle?: boolean;
  onRenameSession: (title: string) => void;
  isPro?: boolean;
  messages?: ChatMessage[];
  sources?: ResearchSource[];
  isMobile?: boolean;
  onCreateNewChat?: () => void;  // Add this prop
}

const MarketChatHeader: React.FC<MarketChatHeaderProps> = ({ 
  onOpenSettings,
  sessionTitle = "New Chat",
  isGeneratingTitle = false,
  onRenameSession,
  isPro = false,
  messages = [],
  sources = [],
  isMobile = false,
  onCreateNewChat
}) => {
  const { isDarkMode } = useTheme();
  const { state: chatState } = useChat();
  const [isRenaming, setIsRenaming] = useState(false);
  const [newTitle, setNewTitle] = useState(sessionTitle);
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const [showKeyboardShortcuts, setShowKeyboardShortcuts] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showModelDropdown, setShowModelDropdown] = useState(false);
  const [selectedModel, setSelectedModel] = useState(aiModels[0]);
  const modelDropdownRef = useRef<HTMLDivElement>(null);
  const [logoBase64, setLogoBase64] = useState<string | null>(null);

  useEffect(() => {
    const loadLogo = async () => {
      try {
        // Use the dark mode logo
        const logoUrl = '/logo-dark.png';
        const base64Logo = await imageToBase64(logoUrl);
        setLogoBase64(base64Logo);
      } catch (error) {
        console.error('Failed to load logo:', error);
      }
    };
    
    loadLogo();
  }, []);

  // Handle clicks outside the model dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modelDropdownRef.current && !modelDropdownRef.current.contains(event.target as Node)) {
        setShowModelDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Rename handling
  const handleRename = () => {
    if (newTitle.trim() && newTitle !== sessionTitle) {
      onRenameSession(newTitle);
    }
    setIsRenaming(false);
  };

  // Model selection handling
  const handleModelChange = (model: typeof aiModels[0]) => {
    if (model.available) {
      setSelectedModel(model);
      toast.success(`Switched to ${model.name}`);
    } else {
      toast(`${model.name} is coming soon!`);
    }
    setShowModelDropdown(false);
  };

  // Format date for PDF
  const formatDate = (date: Date | string) => {
    const d = typeof date === 'string' ? new Date(date) : date;
    return d.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Generate PDF with chat content
  const generatePDF = async () => {
    try {
      setIsDownloading(true);
      
      // Ensure we have messages to export
      const currentMessages = messages.length > 0 ? 
        messages : 
        chatState.currentSessionId ? 
          chatState.sessions.find(s => s.id === chatState.currentSessionId)?.messages || [] : 
          [];
      
      if (currentMessages.length === 0) {
        toast.error('No messages to export');
        setIsDownloading(false);
        return;
      }
      
      // Initialize jsPDF with proper orientation and units
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });
      
      // Create a custom title for the PDF
      const pdfTitle = `Newsroom Chat - ${sessionTitle}`;
      doc.setProperties({
        title: pdfTitle,
        author: 'Newsroom AI',
        creator: 'Newsroom',
        subject: 'AI Chat Transcript'
      });
      
      // Define page dimensions
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const margin = 15;
      const contentWidth = pageWidth - (margin * 2);
      
      // Add a stylish header with gradient
      const headerHeight = 40;
      
      // Create a gradient header
      doc.setFillColor(25, 95, 180);
      doc.rect(0, 0, pageWidth, headerHeight, 'F');
      
      // Add logo in the header (using base64 from state if available, otherwise fallback to text)
      if (logoBase64) {
        try {
          doc.addImage(logoBase64, 'PNG', margin, 10, 30, 20);
        } catch (e) {
          console.error('Error adding logo to PDF', e);
          // Fallback to text
          doc.setTextColor(255, 255, 255);
          doc.setFontSize(20);
          doc.setFont('helvetica', 'bold');
          doc.text('NEWSROOM', margin, 20);
        }
      } else {
        doc.setTextColor(255, 255, 255);
        doc.setFontSize(20);
        doc.setFont('helvetica', 'bold');
        doc.text('NEWSROOM', margin, 20);
      }
      
      // Add modern header accent
      doc.setFillColor(59, 130, 246);
      doc.rect(0, headerHeight, pageWidth, 5, 'F');
      
      // Add timestamp in header
      doc.setFontSize(8);
      doc.setFont('helvetica', 'normal');
      doc.setTextColor(255, 255, 255);
      const timestamp = formatDate(new Date());
      const timestampWidth = doc.getTextWidth(timestamp);
      doc.text(timestamp, pageWidth - margin - timestampWidth, 20);
      
      // Add chat title with modern design
      doc.setTextColor(25, 95, 180);
      doc.setFontSize(18);
      doc.setFont('helvetica', 'bold');
      doc.text(`Chat: ${sessionTitle}`, margin, headerHeight + 20);
      
      // Add styled underline
      doc.setDrawColor(59, 130, 246);
      doc.setLineWidth(0.8);
      doc.line(margin, headerHeight + 23, margin + doc.getTextWidth(`Chat: ${sessionTitle}`) + 5, headerHeight + 23);
      
      // Add chat content section title with a modern design
      doc.setFillColor(240, 248, 255);
      doc.roundedRect(margin, headerHeight + 30, contentWidth, 10, 2, 2, 'F');
      
      doc.setTextColor(25, 95, 180);
      doc.setFontSize(12);
      doc.text('Conversation', margin + 5, headerHeight + 37);
      
      // Start position for chat messages
      let yPosition = headerHeight + 50;
      const pageContentLimit = pageHeight - 30; // Leave space for footer
      const bubblePadding = 6;
      const bubbleRadius = 3; // Rounded corner radius
      const lineHeight = 5; // Use a fixed line height for consistent rendering
      const headerHeight2 = 15; // Height of the header on subsequent pages
      
      // Process all chat messages with improved styling
      for (let i = 0; i < currentMessages.length; i++) {
        const message = currentMessages[i];
        const role = message.role === 'user' ? 'You' : 'Newsroom AI';
        const messageTimestamp = message.timestamp ? formatDate(message.timestamp) : '';
        const isUser = message.role === 'user';
        
        // Set bubble color based on role
        if (isUser) {
          doc.setFillColor(235, 245, 255); // Light blue for user
        } else {
          doc.setFillColor(248, 250, 252); // Light gray for AI
        }
        
        // Wrap message content to fit the page width
        const maxTextWidth = contentWidth - (bubblePadding * 2) - 10;
        const contentLines = doc.splitTextToSize(message.content, maxTextWidth);
        
        // Calculate the height needed for message header (role + timestamp)
        const messageHeaderHeight = 15;
        
        // First, check if we need a new page for this message
        if (yPosition + messageHeaderHeight > pageContentLimit) {
          doc.addPage();
          addPageHeader(doc, pageWidth, headerHeight2, margin, sessionTitle);
          yPosition = headerHeight2 + 10; // Reset position at top of new page
        }
        
        // Draw message header with role and timestamp
        doc.roundedRect(margin, yPosition, contentWidth, messageHeaderHeight, bubbleRadius, bubbleRadius, 'F');
        
        // Add a subtle border
        if (isUser) {
          doc.setDrawColor(200, 220, 240);
        } else {
          doc.setDrawColor(220, 220, 220);
        }
        doc.setLineWidth(0.2);
        doc.roundedRect(margin, yPosition, contentWidth, messageHeaderHeight, bubbleRadius, bubbleRadius, 'S');
        
        // Add role identifier with an icon or symbol
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold');
        
        if (isUser) {
          doc.setTextColor(59, 130, 246); // Blue for user
          // Draw a small user icon
          doc.circle(margin + 4, yPosition + 6, 2, 'F');
        } else {
          doc.setTextColor(75, 85, 99); // Dark gray for AI
          // Draw a small AI icon
          doc.setFillColor(59, 130, 246);
          doc.rect(margin + 2, yPosition + 4, 4, 4, 'F');
        }
        
        doc.text(role, margin + bubblePadding + 5, yPosition + 10);
        
        // Add timestamp with styling
        doc.setFontSize(8);
        doc.setFont('helvetica', 'italic');
        doc.setTextColor(150, 150, 150);
        const msgTimestampWidth = doc.getTextWidth(messageTimestamp);
        doc.text(messageTimestamp, margin + contentWidth - bubblePadding - msgTimestampWidth, yPosition + 10);
        
        yPosition += messageHeaderHeight;
        
        // Now handle the message content in chunks to span pages correctly
        let lineIndex = 0;
        while (lineIndex < contentLines.length) {
          // Calculate how many lines can fit on this page
          const remainingSpace = pageContentLimit - yPosition;
          const maxLinesOnPage = Math.floor(remainingSpace / lineHeight);
          const linesForThisPage = Math.min(maxLinesOnPage, contentLines.length - lineIndex);
          
          if (linesForThisPage <= 0) {
            // Not enough space for even one line, add new page
            doc.addPage();
            addPageHeader(doc, pageWidth, headerHeight2, margin, sessionTitle);
            yPosition = headerHeight2 + 10;
            continue; // Recalculate how many lines can fit
          }
          
          // Calculate height for current content chunk
          const contentChunkHeight = linesForThisPage * lineHeight + bubblePadding * 2;
          
          // Draw content chunk bubble
          if (isUser) {
            doc.setFillColor(235, 245, 255);
          } else {
            doc.setFillColor(248, 250, 252);
          }
          doc.roundedRect(margin, yPosition, contentWidth, contentChunkHeight, bubbleRadius, bubbleRadius, 'F');
          
          // Add border
          if (isUser) {
            doc.setDrawColor(200, 220, 240);
          } else {
            doc.setDrawColor(220, 220, 220);
          }
          doc.setLineWidth(0.2);
          doc.roundedRect(margin, yPosition, contentWidth, contentChunkHeight, bubbleRadius, bubbleRadius, 'S');
          
          // Add actual content text
          doc.setTextColor(50, 50, 50);
          doc.setFontSize(9.5);
          doc.setFont('helvetica', 'normal');
          
          const contentChunk = contentLines.slice(lineIndex, lineIndex + linesForThisPage);
          doc.text(contentChunk, margin + bubblePadding, yPosition + bubblePadding + 4);
          
          // Update positions and counters
          lineIndex += linesForThisPage;
          yPosition += contentChunkHeight + 5;
          
          // If we have more content but reached page end, add a new page
          if (lineIndex < contentLines.length && yPosition + lineHeight > pageContentLimit) {
            doc.addPage();
            addPageHeader(doc, pageWidth, headerHeight2, margin, sessionTitle);
            yPosition = headerHeight2 + 10;
          }
        }
        
        // Add space between messages
        yPosition += 8;
      }
      
      // Add research sources section
      addResearchSources(doc, sources, currentMessages, yPosition, pageContentLimit, pageWidth, pageHeight, margin, contentWidth, sessionTitle);
      
      // Add styled footer to all pages
      addFooters(doc, pageWidth, pageHeight, margin);
      
      // Save the PDF with a clean filename
      const safeTitleForFilename = sessionTitle
        .replace(/[^a-z0-9]/gi, '-')
        .toLowerCase()
        .replace(/-+/g, '-')
        .replace(/^-|-$/g, '');
        
      doc.save(`newsroom-chat-${safeTitleForFilename}.pdf`);
      
      toast.success('Chat exported to PDF');
    } catch (error) {
      console.error('Error generating PDF:', error);
      toast.error(`Failed to export chat to PDF: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setIsDownloading(false);
    }
  };
  
  // Helper function to add page header
  const addPageHeader = (doc: jsPDF, pageWidth: number, headerHeight: number, margin: number, title: string) => {
    // Add styled header
    doc.setFillColor(25, 95, 180);
    doc.rect(0, 0, pageWidth, headerHeight, 'F');
    
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text(`Newsroom Chat - ${title}`, margin, 10);
    
    // Add accent line
    doc.setFillColor(59, 130, 246);
    doc.rect(0, headerHeight, pageWidth, 2, 'F');
  };
  
  // Helper function to add research sources
  const addResearchSources = (doc: jsPDF, sources: ResearchSource[], currentMessages: ChatMessage[], yPosition: number, pageContentLimit: number, pageWidth: number, pageHeight: number, margin: number, contentWidth: number, sessionTitle: string) => {
    const currentSources = sources.length > 0 ? 
      sources : 
      currentMessages.reduce((acc, msg) => {
        if (msg.researchSources) {
          return [...acc, ...msg.researchSources];
        }
        return acc;
      }, [] as ResearchSource[]);
    
    if (currentSources.length > 0) {
      // Check if we need a new page for sources
      if (yPosition + 60 > pageContentLimit) {
        doc.addPage();
        addPageHeader(doc, pageWidth, 15, margin, sessionTitle);
        yPosition = 25; // Reset position at top of new page
      }
      
      // Sources header with improved styling
      doc.setFillColor(235, 245, 255);
      doc.roundedRect(margin, yPosition, contentWidth, 12, 2, 2, 'F');
      
      doc.setTextColor(25, 95, 180);
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text('Research Sources', margin + 5, yPosition + 8);
      
      // Manual table rendering with better styling
      yPosition += 20;
      
      // Define column widths and headers
      const columns = [
        { title: 'Type', width: 25 },
        { title: 'Title/Source', width: 70 },
        { title: 'URL', width: 50 },
        { title: 'Timestamp', width: 30 }
      ];
      
      const totalWidth = columns.reduce((sum, col) => sum + col.width, 0);
      
      // Create table header and rows using the same page-spanning technique
      renderSourcesTable(doc, currentSources, columns, totalWidth, yPosition, pageContentLimit, pageWidth, margin, sessionTitle);
    }
  };
  
  // Helper function to render sources table with pagination
  const renderSourcesTable = (doc: jsPDF, sources: ResearchSource[], columns: {title: string, width: number}[], totalWidth: number, yPosition: number, pageContentLimit: number, pageWidth: number, margin: number, sessionTitle: string) => {
    // Draw header with a gradient look
    doc.setFillColor(59, 130, 246);
    doc.roundedRect(margin, yPosition, totalWidth, 8, 1, 1, 'F');
    
    // Add header text
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(8);
    doc.setFont('helvetica', 'bold');
    
    let xPosition = margin;
    columns.forEach(col => {
      doc.text(col.title, xPosition + 2, yPosition + 5.5);
      xPosition += col.width;
    });
    
    yPosition += 8;
    
    // Add rows with alternating colors
    doc.setFont('helvetica', 'normal');
    sources.forEach((source, index) => {
      const rowHeight = 10; // Slightly taller rows
      
      // Check if we need a new page for this row
      if (yPosition + rowHeight > pageContentLimit) {
        doc.addPage();
        addPageHeader(doc, pageWidth, 15, margin, sessionTitle);
        yPosition = 25;
        
        // Redraw the header on the new page
        doc.setFillColor(59, 130, 246);
        doc.roundedRect(margin, yPosition, totalWidth, 8, 1, 1, 'F');
        
        doc.setTextColor(255, 255, 255);
        doc.setFontSize(8);
        doc.setFont('helvetica', 'bold');
        
        xPosition = margin;
        columns.forEach(col => {
          doc.text(col.title, xPosition + 2, yPosition + 5.5);
          xPosition += col.width;
        });
        
        yPosition += 8;
      }
      
      // Alternating row background
      if (index % 2 === 0) {
        doc.setFillColor(245, 250, 255);
        doc.rect(margin, yPosition, totalWidth, rowHeight, 'F');
      } else {
        doc.setFillColor(250, 250, 250);
        doc.rect(margin, yPosition, totalWidth, rowHeight, 'F');
      }
      
      // Add borders
      doc.setDrawColor(230, 230, 230);
      doc.setLineWidth(0.1);
      doc.rect(margin, yPosition, totalWidth, rowHeight, 'S');
      
      // Add cell content
      xPosition = margin;
      
      // Type with icon
      doc.setTextColor(25, 95, 180);
      doc.setFont('helvetica', 'bold');
      doc.text(source.type || '', xPosition + 2, yPosition + 6.5);
      xPosition += columns[0].width;
      
      // Title/Source
      doc.setTextColor(50, 50, 50);
      doc.setFont('helvetica', 'normal');
      const title = source.title || source.source || '';
      const titleLines = doc.splitTextToSize(title, columns[1].width - 4);
      doc.text(titleLines, xPosition + 2, yPosition + 6.5);
      xPosition += columns[1].width;
      
      // URL with link styling
      doc.setTextColor(0, 102, 204);
      doc.setFont('helvetica', 'normal');
      const url = source.url || '';
      const urlLines = doc.splitTextToSize(url, columns[2].width - 4);
      doc.text(urlLines, xPosition + 2, yPosition + 6.5);
      xPosition += columns[2].width;
      
      // Timestamp
      doc.setTextColor(100, 100, 100);
      doc.setFont('helvetica', 'italic');
      const timestampText = source.timestamp ? formatDate(source.timestamp) : '';
      doc.text(timestampText, xPosition + 2, yPosition + 6.5);
      
      yPosition += rowHeight;
    });
  };
  
  // Helper function to add footers to all pages
  const addFooters = (doc: jsPDF, pageWidth: number, pageHeight: number, margin: number) => {
    const totalPages = doc.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      
      // Add footer with gradient
      doc.setFillColor(248, 250, 252);
      doc.rect(0, pageHeight - 18, pageWidth, 18, 'F');
      
      // Add top border to footer
      doc.setDrawColor(59, 130, 246);
      doc.setLineWidth(0.8);
      doc.line(0, pageHeight - 18, pageWidth, pageHeight - 18);
      
      // Add footer text with company name and logo
      doc.setFontSize(8);
      doc.setTextColor(100, 100, 100);
      doc.setFont('helvetica', 'normal');
      doc.text(
        'Generated by Newsroom - AI-Powered Market Intelligence',
        margin,
        pageHeight - 8
      );
      
      // Add page numbers with styling
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(25, 95, 180);
      doc.text(
        `Page ${i} of ${totalPages}`,
        pageWidth - margin - 20,
        pageHeight - 8
      );
    }
  };

  return (
    <div className={`border-b ${isDarkMode ? 'border-gray-800' : 'border-gray-200'}`}>
      <div className="px-4 py-2">
        <div className="flex items-center justify-between gap-2">
          {/* Session Title with edit functionality */}
          <div className={`flex items-center gap-2 ${isMobile ? 'pl-10' : ''}`}>
            {isRenaming ? (
              <div className="flex items-center bg-blue-500/10 rounded-lg">
                <input
                  type="text"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  onBlur={handleRename}
                  onKeyDown={(e) => e.key === 'Enter' && handleRename()}
                  autoFocus
                  className="w-64 px-3 py-1.5 bg-transparent border-none focus:ring-0 text-blue-600 dark:text-blue-400"
                />
              </div>
            ) : (
              <h1 
                className={`text-lg font-medium truncate max-w-[250px] ${isDarkMode ? 'text-white' : 'text-gray-900'} ${isGeneratingTitle ? 'animate-pulse' : ''}`}
                onClick={() => setIsRenaming(true)}
              >
                {isGeneratingTitle ? 'Generating title...' : sessionTitle}
              </h1>
            )}

            {!isRenaming && !isGeneratingTitle && !isMobile && (
              <button 
                onClick={() => setIsRenaming(true)}
                className="p-1 rounded-full text-gray-400 hover:text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-300"
              >
                <PenBox className="w-3.5 h-3.5" />
              </button>
            )}
          </div>

          {/* Mobile menu button and new chat button */}
          {isMobile && (
            <div className="flex items-center gap-2">
              <button
                onClick={onCreateNewChat}
                className="flex items-center px-2 py-1 rounded-md bg-blue-500/20 text-blue-500 text-sm"
              >
                <Plus className="w-3.5 h-3.5 mr-1" />
                <span>New</span>
              </button>
            </div>
          )}

          {/* Model selector and action buttons - hide some on mobile */}
          <div className="flex items-center gap-2">
            {/* Hide model selector on mobile */}
            {!isMobile && (
              <div className="relative">
                <button 
                  onClick={() => setShowModelDropdown(!showModelDropdown)}
                  className={`px-3 py-1.5 flex items-center space-x-2 rounded-lg text-sm font-medium ${
                    isDarkMode 
                      ? 'bg-gray-800 text-gray-300 hover:bg-gray-750' 
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  <selectedModel.icon className="w-4 h-4 mr-1.5" />
                  <span className="mr-1">{selectedModel.name}</span>
                  <ChevronDown className="w-3.5 h-3.5 text-gray-400" />
                </button>

                <AnimatePresence>
                  {showModelDropdown && (
                    <motion.div
                      ref={modelDropdownRef}
                      initial={{ opacity: 0, y: 8, scale: 0.96 }}
                      animate={{ opacity: 1, y: 0, scale: 1 }}
                      exit={{ opacity: 0, y: 8, scale: 0.96 }}
                      className={`absolute right-0 z-50 mt-1 w-60 rounded-lg shadow-lg ${
                        isDarkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white border border-gray-200'
                      } overflow-hidden`}
                    >
                      <div className="p-2 space-y-1">
                        {aiModels.map((model) => (
                          <button
                            key={model.id}
                            onClick={() => handleModelChange(model)}
                            className={`w-full flex items-center p-3 rounded-lg ${
                              selectedModel.id === model.id
                                ? isDarkMode 
                                  ? 'bg-blue-500/20 text-blue-400' 
                                  : 'bg-blue-50 text-blue-600'
                                : isDarkMode
                                  ? 'text-gray-300 hover:bg-gray-700'
                                  : 'text-gray-700 hover:bg-gray-100'
                            } ${!model.available ? 'opacity-60' : ''} transition-colors`}
                          >
                            <div className="flex items-center flex-1">
                              <div className={`p-1.5 rounded-lg ${
                                selectedModel.id === model.id
                                  ? 'bg-blue-500/20'
                                  : isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                              } mr-3`}>
                                <model.icon className={`w-4 h-4 ${
                                  selectedModel.id === model.id
                                    ? 'text-blue-400' 
                                    : isDarkMode ? 'text-gray-400' : 'text-gray-500'
                                }`} />
                              </div>
                              <div className="flex-1 text-left">
                                <div className="font-medium text-sm">{model.name}</div>
                                <div className={`text-xs mt-0.5 ${
                                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                                }`}>{model.description}</div>
                              </div>
                            </div>
                            
                            {!model.available && (
                              <span className="text-xs px-2 py-1 rounded-full bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-300">
                                Coming soon
                              </span>
                            )}
                          </button>
                        ))}
                      </div>
                      
                      <div className={`px-4 py-3 text-xs ${
                        isDarkMode ? 'bg-gray-750 text-gray-400 border-t border-gray-700' : 'bg-gray-50 text-gray-500 border-t border-gray-200'
                      }`}>
                        More models will be available soon
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            )}

            {/* Limited action buttons on mobile */}
            <div className="flex items-center space-x-1">
              {!isMobile && (
                <HeaderButton 
                  Icon={isDownloading ? Loader2 : Download} 
                  onClick={generatePDF} 
                  disabled={isDownloading}
                  className={isDownloading ? 'animate-spin' : ''}
                  tooltip="Export to PDF"
                />
              )}
              {!isMobile && (
                <HeaderButton 
                  Icon={Command} 
                  onClick={() => setShowKeyboardShortcuts(true)} 
                  tooltip="Keyboard shortcuts"
                />
              )}
              <HeaderButton 
                Icon={Settings} 
                onClick={() => setShowSettingsMenu(true)} 
                tooltip="Settings"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Keep existing PopupMenu components */}
      <>
        {/* Share Menu */}
        <PopupMenu
          isOpen={showShareMenu}
          onClose={() => setShowShareMenu(false)}
          title="Share Chat"
        >
          <div className="space-y-4">
            <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
              <h3 className="font-medium mb-2">Share Link</h3>
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  value={`${window.location.origin}/chat/${sessionTitle}`}
                  readOnly
                  className={`flex-1 px-3 py-2 rounded-lg border ${
                    isDarkMode ? 'bg-gray-900 border-gray-700' : 'bg-white border-gray-200'
                  }`}
                />
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(`${window.location.origin}/chat/${sessionTitle}`);
                    toast.success('Link copied to clipboard');
                  }}
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                  Copy
                </button>
              </div>
            </div>
            <div className="flex space-x-2">
              <button className="flex-1 px-4 py-2 rounded-lg bg-[#1DA1F2] text-white hover:bg-[#1A8CD8]">
                Share on Twitter
              </button>
              <button className="flex-1 px-4 py-2 rounded-lg bg-[#0A66C2] text-white hover:bg-[#004182]">
                Share on LinkedIn
              </button>
            </div>
          </div>
        </PopupMenu>

        {/* Settings Menu */}
        <PopupMenu
          isOpen={showSettingsMenu}
          onClose={() => setShowSettingsMenu(false)}
          title="Settings"
        >
          <div className="p-4">
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Settings options will appear here.</p>
          </div>
        </PopupMenu>

        {/* Keyboard Shortcuts Menu */}
        <PopupMenu
          isOpen={showKeyboardShortcuts}
          onClose={() => setShowKeyboardShortcuts(false)}
          title="Keyboard Shortcuts"
        >
          <div className="p-4">
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Keyboard shortcuts will appear here.</p>
          </div>
        </PopupMenu>
      </>
    </div>
  );
};

// Enhanced HeaderButton with tooltip and disabled state
const HeaderButton: React.FC<{
  Icon: LucideIcon;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  tooltip?: string;
}> = ({ Icon, onClick, disabled = false, className = '', tooltip }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="relative group">
      <motion.button
        whileHover={{ scale: disabled ? 1 : 1.05 }}
        whileTap={{ scale: disabled ? 1 : 0.95 }}
        onClick={disabled ? undefined : onClick}
        disabled={disabled}
        className={`p-2 rounded-lg transition-colors ${className} ${
          disabled 
            ? isDarkMode ? 'text-gray-600 cursor-not-allowed' : 'text-gray-400 cursor-not-allowed'
            : isDarkMode 
              ? 'hover:bg-gray-800 text-gray-400 hover:text-gray-300' 
              : 'hover:bg-gray-100 text-gray-600 hover:text-gray-700'
        }`}
      >
        <Icon className="w-5 h-5" />
      </motion.button>
      
      {tooltip && (
        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-2 py-1 rounded bg-gray-900 text-white text-xs whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
          {tooltip}
        </div>
      )}
    </div>
  );
};

export default MarketChatHeader;