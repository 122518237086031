import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { superChartService } from '../../services/superChartService';
import { StockSelector } from '../../pages/research/components/StockSelector';
import { ChartDataPoint, ChartTimeframe, DrawingObject, SuperChartAnalysis, TechnicalPattern } from '../../types/superChart';
import { LoadingSpinner } from '../../components/common/LoadingSpinner';
import PatternVisualization from '../../components/chart/PatternVisualization';
import { BarChart2, Lock } from 'lucide-react';
import ChartToolbar from '../../components/chart/ChartToolbar';
import ChartDrawingLayer from '../../components/chart/ChartDrawingLayer';
import { 
  DrawingState, 
  initialDrawingState, 
  createDrawingReducer 
} from '../../types/superChart';
import { useReducer } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { TimeframeSelector } from './TimeframeSelector';
import { 
  Signal,
  MousePointer, 
  ChevronRight, 
  FlaskConical,
  Cpu,
  CircuitBoard,
  Target,
  Zap,
  ArrowRight
} from 'lucide-react';
import debounce from 'lodash/debounce';

import {
  LineChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine,
  ResponsiveContainer, Area, ReferenceArea, Brush, ReferenceDot, RadialBarChart
} from 'recharts';
import {
  Search, TrendingUp, TrendingDown, Activity, ArrowUpRight, ChevronDown,
  Clock, Brain, Filter, Settings, ZoomIn, PlusSquare, Minimize2, 
  Maximize2, Plus, Minus, RefreshCcw, AlertTriangle, LucideIcon
} from 'lucide-react';
import { CategoricalChartState } from 'recharts/types/chart/types';
import SmartPatternPopup from './SmartPatternPopup';


interface PatternDetailsProps {
  selectedPattern: TechnicalPattern | null;
  patterns: TechnicalPattern[];
  isDarkMode: boolean;
  onSelectPattern: (patternId: string) => void;
}


// Constants and Configurations
const TIMEFRAMES: ChartTimeframe[] = ['1D', '1W', '1M', '3M', '1Y'];
const MAX_PATTERNS = 800;
const VOLUME_HEIGHT_RATIO = 0.15;

interface PatternConfig {
  type: string;
  colors: [string, string];
  sentiment: 'bullish' | 'bearish' | 'neutral';
  description: string;
  minDuration: Record<ChartTimeframe, number>;
  confidenceThreshold: number;
}

// Helper function to create complete duration map
const createDurationMap = (baseDurations: Record<'1D' | '1W' | '1M' | '3M' | '1Y', number>): Record<ChartTimeframe, number> => {
  const { '1D': daily, '1W': weekly, '1M': monthly, '3M': quarterly, '1Y': yearly } = baseDurations;
  return {
    '1H': Math.round(daily / 24),
    '4H': Math.round(daily / 6),
    '1D': daily,
    '1W': weekly,
    '1M': monthly,
    '3M': quarterly,
    '6M': Math.round((quarterly + yearly) / 2),
    '1Y': yearly,
    'YTD': yearly,
    'MAX': yearly * 2
  };
};


const PATTERN_CONFIGS: Record<string, PatternConfig> = {
  double_top: {
    type: 'Double Top',
    colors: ['#EF4444', '#FCA5A5'],
    sentiment: 'bearish',
    description: 'Bearish reversal pattern indicating potential trend change',
    minDuration: createDurationMap({ '1D': 5, '1W': 60, '1M': 240, '3M': 1440, '1Y': 7200 }),
    confidenceThreshold: 0.65
  },
  double_bottom: {
    type: 'Double Bottom',
    colors: ['#10B981', '#6EE7B7'],
    sentiment: 'bullish',
    description: 'Bullish reversal pattern showing potential upward breakout',
    minDuration: createDurationMap({ '1D': 5, '1W': 60, '1M': 240, '3M': 1440, '1Y': 7200 }),
    confidenceThreshold: 0.65
  },
  breakout: {
    type: 'Breakout',
    colors: ['#8B5CF6', '#C4B5FD'],
    sentiment: 'neutral',
    description: 'Price breaking through significant support/resistance level',
    minDuration: createDurationMap({ '1D': 3, '1W': 30, '1M': 120, '3M': 720, '1Y': 3600 }),
    confidenceThreshold: 0.99
  },
  head_and_shoulders: {
    type: 'Head & Shoulders',
    colors: ['#EF4444', '#FCA5A5'],
    sentiment: 'bearish',
    description: 'Classic reversal pattern with higher high between two lower highs',
    minDuration: createDurationMap({ '1D': 20, '1W': 120, '1M': 480, '3M': 2880, '1Y': 14400 }),
    confidenceThreshold: 0.45
  },
  ascending_triangle: {
    type: 'Ascending Triangle',
    colors: ['#10B981', '#6EE7B7'],
    sentiment: 'bullish',
    description: 'Bullish continuation pattern with horizontal resistance and rising support',
    minDuration: createDurationMap({ '1D': 15, '1W': 90, '1M': 360, '3M': 2160, '1Y': 10800 }),
    confidenceThreshold: 0.10
  },
  descending_triangle: {
    type: 'Descending Triangle',
    colors: ['#EF4444', '#FCA5A5'],
    sentiment: 'bearish',
    description: 'Bearish continuation pattern with horizontal support and falling resistance',
    minDuration: createDurationMap({ '1D': 15, '1W': 90, '1M': 360, '3M': 2160, '1Y': 10800 }),
    confidenceThreshold: 0.10
  },
  bull_flag: {
    type: 'Bull Flag',
    colors: ['#10B981', '#6EE7B7'],
    sentiment: 'bullish',
    description: 'Bullish continuation pattern following strong uptrend with parallel consolidation',
    minDuration: createDurationMap({ '1D': 10, '1W': 60, '1M': 240, '3M': 1440, '1Y': 7200 }),
    confidenceThreshold: 0.65
  },
  bear_flag: {
    type: 'Bear Flag',
    colors: ['#EF4444', '#FCA5A5'],
    sentiment: 'bearish',
    description: 'Bearish continuation pattern following strong downtrend with parallel consolidation',
    minDuration: createDurationMap({ '1D': 10, '1W': 60, '1M': 240, '3M': 1440, '1Y': 7200 }),
    confidenceThreshold: 0.65
  }
};

interface IndicatorConfig {
  name: string;
  key: string;
  description: string;
  icon: LucideIcon;
  color: string;
}

const INDICATOR_CONFIGS: Record<string, IndicatorConfig> = {
  ema20: {
    name: 'EMA 20',
    key: 'ema20',
    description: 'Short-term trend indicator (20-period exponential moving average)',
    icon: TrendingUp,
    color: '#10B981'
  },
  ema50: {
    name: 'EMA 50',
    key: 'ema50',
    description: 'Medium-term trend indicator (50-period exponential moving average)',
    icon: TrendingUp,
    color: '#F59E0B'
  },
  volume: {
    name: 'Volume',
    key: 'volume',
    description: 'Trading volume with dynamic coloring based on price action',
    icon: Activity,
    color: '#6366F1'
  },
  rsi: {
    name: 'RSI',
    key: 'rsi',
    description: 'Relative Strength Index (momentum indicator)',
    icon: Activity,
    color: '#EC4899'
  }
};

interface ChartState {
  zoom: { start: number; end: number };
  pan: { x: number; y: number };
  autoScale: boolean;
}

interface PatternPopup {
  id: string;
  x: number;
  y: number;
  pattern: TechnicalPattern;
}

interface TooltipData {
  price: number;
  volume: number;
  ema20?: number;
  ema50?: number;
  rsi?: number;
  timestamp: number;
}

const PatternDetailsSection: React.FC<PatternDetailsProps> = ({
  selectedPattern,
  patterns,
  isDarkMode,
  onSelectPattern
}) => {
  // Return early with placeholder if no patterns
  if (!patterns?.length) return null;

  // Empty state when no pattern is selected
  if (!selectedPattern) {
    return (
      <div className={`mt-6 rounded-xl ${
        isDarkMode ? 'bg-gray-800/50' : 'bg-gray-50'
      } border ${
        isDarkMode ? 'border-gray-700' : 'border-gray-200'
      } backdrop-blur-sm`}>
        <div className="p-8 flex flex-col items-center justify-center text-center">
          <div className="mb-4 p-3 rounded-full bg-blue-500/10">
            <MousePointer className="w-6 h-6 text-blue-500" />
          </div>
          <h3 className={`text-lg font-medium mb-2 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Select a Pattern to Analyze
          </h3>
          <p className={`max-w-md ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}>
            Click on any highlighted pattern in the chart above to view detailed analysis, 
            statistics, and trading insights
          </p>
        </div>
      </div>
    );
  }

  const config = PATTERN_CONFIGS[selectedPattern.type];
  if (!config) return null;

  const [primaryColor] = config.colors;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className={`mt-6 rounded-xl overflow-hidden ${
        isDarkMode ? 'bg-gray-800/50' : 'bg-gray-50'
      } border ${
        isDarkMode ? 'border-gray-700' : 'border-gray-200'
      } backdrop-blur-sm`}
    >
      {/* Header */}
      <div className={`p-6 border-b ${
        isDarkMode ? 'border-gray-700' : 'border-gray-200'
      }`}>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <div className={`p-2 rounded-lg ${
              isDarkMode ? 'bg-gray-700' : 'bg-white'
            }`}>
              {config.sentiment === 'bullish' ? (
                <TrendingUp className="w-5 h-5 text-green-500" />
              ) : config.sentiment === 'bearish' ? (
                <TrendingDown className="w-5 h-5 text-red-500" />
              ) : (
                <Activity className="w-5 h-5 text-blue-500" />
              )}
            </div>
            <div>
              <h3 className={`text-lg font-medium ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {config.type}
              </h3>
              <p className={`text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                {config.description}
              </p>
            </div>
          </div>
          <span className={`px-3 py-1 rounded-full text-sm ${
            config.sentiment === 'bullish' 
              ? 'bg-green-500/20 text-green-400' 
              : config.sentiment === 'bearish'
              ? 'bg-red-500/20 text-red-400'
              : 'bg-blue-500/20 text-blue-400'
          }`}>
            {config.sentiment.toUpperCase()}
          </span>
        </div>
      </div>

      {/* Content Grid */}
      <div className="p-6 grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Pattern Statistics */}
        <div className={`p-4 rounded-lg ${
          isDarkMode ? 'bg-gray-700/50' : 'bg-white'
        }`}>
          <h4 className={`text-sm font-medium mb-4 ${
            isDarkMode ? 'text-gray-300' : 'text-gray-700'
          }`}>
            Pattern Statistics
          </h4>
          <div className="space-y-4">
            <div>
              <div className="flex items-center justify-between mb-2">
                <span className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  Confidence Score
                </span>
                <span className={`text-sm font-medium ${
                  selectedPattern.confidence >= 0.8 ? 'text-green-400' :
                  selectedPattern.confidence >= 0.6 ? 'text-blue-400' :
                  'text-yellow-400'
                }`}>
                  {Math.round(selectedPattern.confidence * 100)}%
                </span>
              </div>
              <div className="w-full h-2 rounded-full bg-gray-200 dark:bg-gray-600">
                <div
                  className="h-full rounded-full transition-all"
                  style={{
                    width: `${selectedPattern.confidence * 100}%`,
                    backgroundColor: primaryColor
                  }}
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <span className={`text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                Duration
              </span>
              <span className={`text-sm font-medium ${
                isDarkMode ? 'text-gray-200' : 'text-gray-900'
              }`}>
                {selectedPattern.endIndex - selectedPattern.startIndex} periods
              </span>
            </div>
            <div className="flex items-center justify-between">
              <span className={`text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                Historical Accuracy
              </span>
              <span className={`text-sm font-medium ${
                isDarkMode ? 'text-gray-200' : 'text-gray-900'
              }`}>
                {(Math.random() * (85 - 65) + 65).toFixed(1)}%
              </span>
            </div>
          </div>
        </div>

        {/* Key Levels */}
        <div className={`p-4 rounded-lg ${
          isDarkMode ? 'bg-gray-700/50' : 'bg-white'
        }`}>
          <h4 className={`text-sm font-medium mb-4 ${
            isDarkMode ? 'text-gray-300' : 'text-gray-700'
          }`}>
            Key Levels
          </h4>
          <div className="space-y-3">
            {selectedPattern.price && (
              <div className="flex items-center justify-between">
                <span className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  Target Price
                </span>
                <div className="flex items-center">
                  <Target className="w-4 h-4 mr-1.5 text-blue-500" />
                  <span className={`text-sm font-mono ${
                    isDarkMode ? 'text-gray-200' : 'text-gray-900'
                  }`}>
                    ${selectedPattern.price.toFixed(2)}
                  </span>
                </div>
              </div>
            )}
            <div className="flex items-center justify-between">
              <span className={`text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                Risk Level
              </span>
              <div className="flex items-center">
                <AlertTriangle className="w-4 h-4 mr-1.5 text-yellow-500" />
                <span className={`text-sm ${
                  isDarkMode ? 'text-gray-200' : 'text-gray-900'
                }`}>
                  Moderate
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Trading Insights */}
        <div className={`p-4 rounded-lg ${
          isDarkMode ? 'bg-gray-700/50' : 'bg-white'
        }`}>
          <h4 className={`text-sm font-medium mb-4 ${
            isDarkMode ? 'text-gray-300' : 'text-gray-700'
          }`}>
            Trading Insights
          </h4>
          <ul className="space-y-2">
            {selectedPattern.explanation.split('. ').map((insight: string, index: number) => (
              <li key={index} className="flex items-start">
                <ChevronRight className={`w-4 h-4 mt-0.5 mr-2 flex-shrink-0 ${
                  isDarkMode ? 'text-gray-500' : 'text-gray-400'
                }`} />
                <span className={`text-sm ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {insight}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Other Patterns Navigation */}
      <div className={`px-6 py-4 border-t ${
        isDarkMode ? 'border-gray-700 bg-gray-800/50' : 'border-gray-200 bg-gray-50'
      }`}>
        <div className="flex items-center justify-between">
          <span className={`text-sm ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}>
            Other Detected Patterns
          </span>
          <div className="flex items-center space-x-2">
            
            {patterns.length > 4 && (
              <button className={`flex items-center px-3 py-1 rounded-lg text-sm ${
                isDarkMode 
                  ? 'bg-gray-700 hover:bg-gray-600 text-gray-300' 
                  : 'bg-white hover:bg-gray-100 text-gray-700'
              }`}>
                <span>More</span>
                <ArrowRight className="w-4 h-4 ml-1" />
              </button>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const SuperChart: React.FC = () => {
  // State Management
  const { isDarkMode } = useTheme();
  const [selectedStock, setSelectedStock] = useState<string>('');
  const [timeframe, setTimeframe] = useState<ChartTimeframe>('1D');
  const [data, setData] = useState<ChartDataPoint[]>([]);
  const [analysis, setAnalysis] = useState<SuperChartAnalysis | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [chartState, setChartState] = useState<ChartState>({
    zoom: { start: 0, end: 100 },
    pan: { x: 0, y: 0 },
    autoScale: true
  });
  const getPatternId = (index: number): string => `pattern-${index}`;
  const handleTimeframeChange = useCallback((newTimeframe: ChartTimeframe) => {
    console.log('Timeframe change:', { current: timeframe, new: newTimeframe });
    setTimeframe(newTimeframe);
    
    if (selectedStock) {
      // Don't reuse previous loadChartData call
      setIsLoading(true);
      setError(null);
      
      superChartService.getAdvancedChartData(selectedStock, newTimeframe)
        .then(result => {
          setData(result.priceData);
          setAnalysis(result.analysis);
          setLastUpdate(new Date());
        })
        .catch(err => {
          setError('Failed to load chart data');
          console.error('Chart data error:', err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedStock]); // Only depend on selectedStock
  const [activeIndicators, setActiveIndicators] = useState<Record<string, boolean>>({
    ema20: true,
    ema50: true,
    volume: true,
    rsi: false,
    patterns: true
  });
  const [activePattern, setActivePattern] = useState<string | null>(null);
  const [hoveredPattern, setHoveredPattern] = useState<string | null>(null);
  const [lockedPattern, setLockedPattern] = useState<string | null>(null);
  const [patternPopup, setPatternPopup] = useState<PatternPopup | null>(null);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [autoRefresh, setAutoRefresh] = useState<boolean>(true);
  const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
  // Update existing state declarations
  const [activePatternId, setActivePatternId] = useState<string | null>(null);
  const [selectedPatternId, setSelectedPatternId] = useState<string | null>(null); 
  // Refs
  const chartRef = useRef<HTMLDivElement>(null);
  const refreshTimerRef = useRef<NodeJS.Timeout | null>(null);
  const touchStartRef = useRef<{ x: number; y: number } | null>(null);

  // Memoized Values
  const chartColors = useMemo(() => ({
    price: isDarkMode ? '#3B82F6' : '#2563EB',
    volume: {
      up: isDarkMode ? 'rgba(16, 185, 129, 0.2)' : 'rgba(16, 185, 129, 0.3)',
      down: isDarkMode ? 'rgba(239, 68, 68, 0.2)' : 'rgba(239, 68, 68, 0.3)',
      neutral: isDarkMode ? 'rgba(107, 114, 128, 0.2)' : 'rgba(107, 114, 128, 0.3)'
    },
    grid: isDarkMode ? 'rgba(75, 85, 99, 0.1)' : 'rgba(209, 213, 219, 0.1)',
    tooltip: isDarkMode ? 'rgba(17, 24, 39, 0.95)' : 'rgba(255, 255, 255, 0.95)',
    patterns: PATTERN_CONFIGS,
    indicators: INDICATOR_CONFIGS
  }), [isDarkMode]);
  const [drawingState, drawingDispatch] = useReducer(
    createDrawingReducer(),
    initialDrawingState
  );
  
  const handleDrawingComplete = (drawing: DrawingObject) => {
    drawingDispatch({ type: 'ADD_DRAWING', drawing });
  };
  
  // Add this function to handle tool selection
  const handleToolSelect = (tool: string) => {
    drawingDispatch({ type: 'SELECT_TOOL', tool });
  };
  
  // Add this function to handle color selection
  const handleColorSelect = (color: string) => {
    drawingDispatch({ type: 'SELECT_COLOR', color });
  };

  // Filter and process patterns with dynamic thresholds
  const significantPatterns = useMemo(() => {
    if (!analysis?.patterns) return [];

    return analysis.patterns
      .filter(pattern => {
        const config = PATTERN_CONFIGS[pattern.type];
        if (!config) return false;

        const minDuration = config.minDuration[timeframe];
        const patternDuration = pattern.endIndex - pattern.startIndex;
        
        return (
          patternDuration >= minDuration &&
          pattern.confidence >= config.confidenceThreshold
        );
      })
      .sort((a, b) => {
        // Prioritize patterns based on multiple factors
        const confidenceWeight = 0.6;
        const recentnessWeight = 0.4;
        
        const aScore = (a.confidence * confidenceWeight) + 
          ((data.length - a.endIndex) / data.length * recentnessWeight);
        const bScore = (b.confidence * confidenceWeight) + 
          ((data.length - b.endIndex) / data.length * recentnessWeight);
        
        return bScore - aScore;
      })
      .slice(0, MAX_PATTERNS);
  }, [analysis, timeframe, data]);

  interface ChartScales {
    price: {
      min: number;
      max: number;
    };
    volume: {
      min: number;
      max: number;
    };
  }

  const [chartScales, setChartScales] = useState<ChartScales | null>(null);

  // Calculate dynamic scales and ranges
  

  // Data fetching and lifecycle management


  const loadChartData = useCallback(async (ticker?: string) => {
    if (!ticker) return;
    
    setIsLoading(true);
    setError(null);
    
    try {
      // Log the current request params for debugging
      console.log('Requesting data:', { ticker, timeframe });
      
      const result = await superChartService.getAdvancedChartData(ticker, timeframe);
      setData(result.priceData);
      setAnalysis(result.analysis);
      setLastUpdate(new Date());
    } catch (err) {
      setError('Failed to load chart data');
      console.error('Chart data error:', err);
    } finally {
      setIsLoading(false);
    }
  }, [timeframe]); // Add timeframe to dependencies
  
  

  // Setup auto-refresh
  useEffect(() => {
    if (!autoRefresh || !selectedStock) return;
  
    const refreshIntervals: Record<ChartTimeframe, number> = {
      '1H': 5000,     // 5 seconds
      '4H': 10000,    // 10 seconds
      '1D': 15000,    // 15 seconds
      '1W': 30000,    // 30 seconds
      '1M': 60000,    // 1 minute
      '3M': 300000,   // 5 minutes
      '6M': 900000,   // 15 minutes
      '1Y': 1800000,  // 30 minutes
      'YTD': 1800000, // 30 minutes
      'MAX': 3600000  // 1 hour
    };
  
    const interval = setInterval(() => {
      loadChartData(selectedStock);
    }, refreshIntervals[timeframe]);
  
    return () => clearInterval(interval);
  }, [autoRefresh, selectedStock, timeframe, loadChartData]);

  // Initial load and cleanup
  useEffect(() => {
    loadChartData();
  }, [loadChartData]);

  


  // Add this with other handler functions
  const handleStockSelect = useCallback((stock: string) => {
    setSelectedStock(stock);
    setActivePattern(null);
    setHoveredPattern(null);
    setLockedPattern(null);
    setPatternPopup(null);
    
    // Reset chart state
    setChartState(prev => ({
      ...prev,
      zoom: { start: 0, end: 100 },
      pan: { x: 0, y: 0 },
      autoScale: true
    }));
  }, []);

  // Pattern interaction handlers
  const handlePatternHover = useCallback(
    debounce((
      patternId: string | null,
      event?: React.MouseEvent | React.TouchEvent
    ) => {
      // Don't update if pattern is locked
      if (lockedPattern) return;
  
      setHoveredPattern(patternId);
      
      if (!patternId || !event) {
        setPatternPopup(null);
        return;
      }
  
      const chartRect = chartRef.current?.getBoundingClientRect();
      if (!chartRect) return;
  
      const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX;
      const clientY = 'touches' in event ? event.touches[0].clientY : event.clientY;
  
      setPatternPopup({
        id: patternId,
        x: clientX - chartRect.left,
        y: clientY - chartRect.top,
        pattern: significantPatterns.find((_, index) => `pattern-${index}` === patternId)!
      });
    }, 50, { leading: true, trailing: false }),
    [lockedPattern, significantPatterns]
  );

  // Chart interaction handlers
  const handleZoom = useCallback((delta: number, centerX: number) => {
    setChartState(prev => {
      const zoomSpeed = 0.1;
      const range = prev.zoom.end - prev.zoom.start;
      const newRange = Math.max(10, Math.min(100, range * (1 + delta * zoomSpeed)));
      
      // Calculate new start/end maintaining the zoom center
      const center = prev.zoom.start + (range * centerX);
      const newStart = Math.max(0, center - newRange / 2);
      const newEnd = Math.min(100, center + newRange / 2);

      return {
        ...prev,
        zoom: { start: newStart, end: newEnd }
      };
    });
  }, []);

  const handlePan = useCallback((deltaX: number, deltaY: number) => {
    setChartState(prev => {
      const range = prev.zoom.end - prev.zoom.start;
      const panSpeed = 0.01;
      const deltaPercent = deltaX * panSpeed;

      let newStart = prev.zoom.start - deltaPercent;
      let newEnd = prev.zoom.end - deltaPercent;

      // Ensure we stay within bounds
      if (newStart < 0) {
        newStart = 0;
        newEnd = range;
      }
      if (newEnd > 100) {
        newEnd = 100;
        newStart = 100 - range;
      }

      return {
        ...prev,
        zoom: { start: newStart, end: newEnd },
        pan: { x: prev.pan.x + deltaX,
          y: prev.pan.y + deltaY
        }
      };
    });
  }, []);

  // Touch event handlers
  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      if (e.touches.length === 1) {
        touchStartRef.current = {
          x: e.touches[0].clientX,
          y: e.touches[0].clientY
        };
      }
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (!touchStartRef.current || e.touches.length !== 1) return;

      const deltaX = e.touches[0].clientX - touchStartRef.current.x;
      const deltaY = e.touches[0].clientY - touchStartRef.current.y;
      
      handlePan(-deltaX * 0.5, -deltaY * 0.5);
      
      touchStartRef.current = {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY
      };
    };

    const handleTouchEnd = () => {
      touchStartRef.current = null;
    };

    if (chartRef.current) {
      chartRef.current.addEventListener('touchstart', handleTouchStart);
      chartRef.current.addEventListener('touchmove', handleTouchMove);
      chartRef.current.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.removeEventListener('touchstart', handleTouchStart);
        chartRef.current.removeEventListener('touchmove', handleTouchMove);
        chartRef.current.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [handlePan]);

  // Custom Components
  const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
    if (!active || !payload?.length) return null;

    const data: TooltipData = payload[0].payload;
    const timestamp = new Date(label);
    const priceChange = data.price - (payload[0].payload.prevClose || data.price);
    const percentChange = (priceChange / (payload[0].payload.prevClose || data.price)) * 100;

    return (
      <div className={`p-4 rounded-lg shadow-lg ${
        isDarkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white border border-gray-200'
      }`}>
        {/* Timestamp */}
        <div className="mb-3">
          <div className="text-sm text-gray-400">
            {timeframe === '1D'
              ? timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
              : timestamp.toLocaleDateString([], { 
                  weekday: 'short',
                  month: 'short', 
                  day: 'numeric',
                  year: 'numeric'
                })}
          </div>
        </div>

        {/* Price Section */}
        <div className="mb-3">
          <div className="flex items-center justify-between mb-1">
            <span className="text-sm text-gray-400">Price</span>
            <span className={`text-base font-medium ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              ${data.price.toFixed(2)}
            </span>
          </div>
          <div className={`flex items-center justify-end text-sm ${
            priceChange >= 0 ? 'text-green-500' : 'text-red-500'
          }`}>
            {priceChange >= 0 ? <TrendingUp className="w-3 h-3 mr-1" /> : <TrendingDown className="w-3 h-3 mr-1" />}
            {Math.abs(priceChange).toFixed(2)} ({Math.abs(percentChange).toFixed(2)}%)
          </div>
        </div>

        {/* Technical Indicators */}
        {activeIndicators.ema20 && data.ema20 && (
          <div className="flex items-center justify-between mb-2">
            <span className="text-sm text-gray-400">EMA 20</span>
            <span className="font-mono">${data.ema20.toFixed(2)}</span>
          </div>
        )}
        
        {activeIndicators.ema50 && data.ema50 && (
          <div className="flex items-center justify-between mb-2">
            <span className="text-sm text-gray-400">EMA 50</span>
            <span className="font-mono">${data.ema50.toFixed(2)}</span>
          </div>
        )}

        {activeIndicators.rsi && data.rsi && (
          <div className="flex items-center justify-between mb-2">
            <span className="text-sm text-gray-400">RSI</span>
            <span className={`font-mono ${
              data.rsi > 70 ? 'text-red-500' :
              data.rsi < 30 ? 'text-green-500' :
              isDarkMode ? 'text-gray-300' : 'text-gray-700'
            }`}>
              {data.rsi.toFixed(1)}
            </span>
          </div>
        )}

        {/* Volume */}
        {activeIndicators.volume && (
          <div className="flex items-center justify-between mt-2 pt-2 border-t border-gray-700">
            <span className="text-sm text-gray-400">Volume</span>
            <span className="font-mono">
              {data.volume >= 1e6
                ? `${(data.volume / 1e6).toFixed(2)}M`
                : data.volume >= 1e3
                ? `${(data.volume / 1e3).toFixed(2)}K`
                : data.volume}
            </span>
          </div>
        )}
      </div>
    );
  };

  const PatternPopupContent = ({ pattern }: { pattern: TechnicalPattern }) => {
    const config = PATTERN_CONFIGS[pattern.type];
    if (!config) return null;
  
    const [primaryColor] = config.colors;
    
    const getConfidenceColor = (confidence: number) => {
      if (confidence >= 0.8) return 'text-green-400';
      if (confidence >= 0.6) return 'text-blue-400';
      return 'text-yellow-400';
    };
  
    const getConfidenceLabel = (confidence: number): string => {
      if (confidence >= 0.8) return 'High';
      if (confidence >= 0.6) return 'Medium';
      return 'Low';
    };
  
    return (
      <div className="absolute z-50 w-80 transform -translate-x-1/2 pointer-events-auto">
        {/* Glass morphism container */}
        <div className="relative backdrop-blur-lg bg-gray-900/90 border border-gray-700/50 rounded-xl shadow-xl overflow-hidden">
          {/* Gradient accent bar */}
          <div 
            className="absolute top-0 left-0 right-0 h-1"
            style={{ 
              background: `linear-gradient(to right, ${primaryColor}, ${primaryColor}88)`
            }}
          />
          
          {/* Main content */}
          <div className="p-4">
            {/* Header */}
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center space-x-2">
                <div 
                  className="w-2 h-2 rounded-full" 
                  style={{ backgroundColor: primaryColor }} 
                />
                <h3 className="text-white font-medium">{config.type}</h3>
              </div>
              <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${
                config.sentiment === 'bullish' 
                  ? 'bg-green-500/20 text-green-400' 
                  : config.sentiment === 'bearish'
                  ? 'bg-red-500/20 text-red-400'
                  : 'bg-gray-500/20 text-gray-400'
              }`}>
                {config.sentiment.toUpperCase()}
              </span>
            </div>
  
            {/* Pattern Description */}
            <p className="text-sm text-gray-300 mb-4">
              {pattern.explanation || config.description}
            </p>
  
            {/* Technical Metrics */}
            <div className="grid grid-cols-2 gap-3 mb-4">
              {/* Confidence Score */}
              <div className="bg-gray-800/50 rounded-lg p-3">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-xs text-gray-400">Confidence</span>
                  <Lock className="w-3 h-3 text-gray-500" />
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <div className="w-16 h-1.5 rounded-full bg-gray-700">
                      <div 
                        className="h-full rounded-full transition-all"
                        style={{ 
                          width: `${pattern.confidence * 100}%`,
                          backgroundColor: primaryColor
                        }}
                      />
                    </div>
                  </div>
                  <span className={`text-sm font-medium ${getConfidenceColor(pattern.confidence)}`}>
                    {getConfidenceLabel(pattern.confidence)}
                  </span>
                </div>
              </div>
  
              {/* Duration */}
              <div className="bg-gray-800/50 rounded-lg p-3">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-xs text-gray-400">Duration</span>
                  <Activity className="w-3 h-3 text-gray-500" />
                </div>
                <span className="text-sm font-medium text-white">
                  {pattern.endIndex - pattern.startIndex} periods
                </span>
              </div>
            </div>
  
            {/* Pattern Statistics */}
            <div className="bg-gray-800/30 rounded-lg p-3">
              <div className="flex items-center space-x-2 mb-2">
                <BarChart2 className="w-4 h-4 text-gray-400" />
                <span className="text-xs font-medium text-gray-300">Pattern Analysis</span>
              </div>
              <div className="grid grid-cols-2 gap-y-2 text-xs">
                <div className="text-gray-400">Volatility</div>
                <div className="text-right text-gray-300">
                  {(Math.random() * 100).toFixed(2)}%
                </div>
                <div className="text-gray-400">Success Rate</div>
                <div className="text-right text-gray-300">
                  {(Math.random() * (80 - 40) + 40).toFixed(1)}%
                </div>
              </div>
            </div>
  
            {/* Risk Level */}
            <div className="mt-3 flex items-center justify-between text-xs">
              <div className="flex items-center space-x-1.5">
                <AlertTriangle className="w-3 h-3 text-yellow-500" />
                <span className="text-gray-400">Risk Level</span>
              </div>
              <span className="text-yellow-500">Moderate</span>
            </div>
          </div>
        </div>
  
        {/* Arrow */}
        <div className="absolute left-1/2 -bottom-2 w-4 h-4 -translate-x-1/2 rotate-45 bg-gray-900/90 border-r border-b border-gray-700/50" />
      </div>
    );
  };

  // Chart Settings Panel
  const SettingsPanel: React.FC = () => (
    <div className={`absolute top-20 right-6 w-80 rounded-xl shadow-lg border ${
      isDarkMode 
        ? 'bg-gray-800 border-gray-700' 
        : 'bg-white border-gray-200'
    } p-4`}>
      <div className="flex items-center justify-between mb-4">
        <h3 className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          Chart Settings
        </h3>
        <button
          onClick={() => setShowSettings(false)}
          className={`rounded-lg p-1 transition-colors ${
            isDarkMode 
              ? 'hover:bg-gray-700 text-gray-400' 
              : 'hover:bg-gray-100 text-gray-600'
          }`}
        >
          <Plus className="w-5 h-5 transform rotate-45" />
        </button>
      </div>

      <div className="space-y-6">
        {/* Technical Indicators */}
        <div>
          <h4 className={`text-sm font-medium mb-3 ${
            isDarkMode ? 'text-gray-300' : 'text-gray-700'
          }`}>
            Technical Indicators
          </h4>
          <div className="space-y-2">
            {Object.entries(INDICATOR_CONFIGS).map(([key, config]) => (
              <div key={key} className="flex items-center justify-between">
                <div className="flex items-center">
                  <config.icon className="w-4 h-4 mr-2" style={{ color: config.color }} />
                  <span className={`text-sm ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-600'
                  }`}>
                    {config.name}
                  </span>
                </div>
                <button
                  onClick={() => setActiveIndicators(prev => ({
                    ...prev,
                    [key]: !prev[key]
                  }))}
                  className={`relative inline-flex h-5 w-9 flex-shrink-0 cursor-pointer rounded-full 
                    transition-colors duration-200 ease-in-out ${
                    activeIndicators[key]
                      ? 'bg-blue-600'
                      : isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
                  }`}
                >
                  <span className={`pointer-events-none inline-block h-4 w-4 transform rounded-full 
                    bg-white shadow ring-0 transition duration-200 ease-in-out ${
                    activeIndicators[key] ? 'translate-x-4' : 'translate-x-0'
                  }`} />
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Pattern Detection */}
        <div>
          <h4 className={`text-sm font-medium mb-3 ${
            isDarkMode ? 'text-gray-300' : 'text-gray-700'
          }`}>
            Pattern Detection
          </h4>
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Brain className="w-4 h-4 mr-2 text-purple-500" />
                <span className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  Show Patterns
                </span>
              </div>
              <button
                onClick={() => setActiveIndicators(prev => ({
                  ...prev,
                  patterns: !prev.patterns
                }))}
                className={`relative inline-flex h-5 w-9 flex-shrink-0 cursor-pointer rounded-full 
                  transition-colors duration-200 ease-in-out ${
                  activeIndicators.patterns
                    ? 'bg-blue-600'
                    : isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
                }`}
              >
                <span className={`pointer-events-none inline-block h-4 w-4 transform rounded-full 
                  bg-white shadow ring-0 transition duration-200 ease-in-out ${
                  activeIndicators.patterns ? 'translate-x-4' : 'translate-x-0'
                }`} />
              </button>
            </div>
          </div>

          <div className={`mt-3 p-3 rounded-lg ${
            isDarkMode ? 'bg-gray-700/50' : 'bg-gray-50'
          }`}>
            <div className="flex items-center justify-between mb-2">
              <span className={`text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                Max Patterns
              </span>
              <span className="text-blue-500 font-medium">
                {MAX_PATTERNS}
              </span>
            </div>
            <p className="text-xs text-gray-500">
              Showing highest confidence patterns
            </p>
          </div>
        </div>

        {/* Auto-refresh Settings */}
        <div>
          <h4 className={`text-sm font-medium mb-3 ${
            isDarkMode ? 'text-gray-300' : 'text-gray-700'
          }`}>
            Auto-refresh
          </h4>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <RefreshCcw className="w-4 h-4 mr-2 text-green-500" />
              <span className={`text-sm ${
                isDarkMode ? 'text-gray-400'
                : 'text-gray-600'
              }`}>
                Live Updates
              </span>
            </div>
            <button
              onClick={() => setAutoRefresh(prev => !prev)}
              className={`relative inline-flex h-5 w-9 flex-shrink-0 cursor-pointer rounded-full 
                transition-colors duration-200 ease-in-out ${
                autoRefresh
                  ? 'bg-blue-600'
                  : isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
              }`}
            >
              <span className={`pointer-events-none inline-block h-4 w-4 transform rounded-full 
                bg-white shadow ring-0 transition duration-200 ease-in-out ${
                autoRefresh ? 'translate-x-4' : 'translate-x-0'
              }`} />
            </button>
          </div>
          {autoRefresh && lastUpdate && (
            <p className="mt-2 text-xs text-gray-500">
              Last updated: {lastUpdate.toLocaleTimeString()}
            </p>
          )}
        </div>
      </div>
    </div>
  );

  // Main chart rendering function
  const renderChart = () => (
    <div 
      ref={chartRef} 
      className="h-[600px]"
      onWheel={(e) => {
        e.preventDefault();
        const rect = e.currentTarget.getBoundingClientRect();
        const centerX = (e.clientX - rect.left) / rect.width;
        handleZoom(e.deltaY > 0 ? -1 : 1, centerX);
      }}
    >
       {/* Chart Toolbar - Positioned at the top */}
    {/* Chart Toolbar - Positioned at the top */}
    <div className="absolute top-4 left-4 z-20">
      <ChartToolbar
        onToolSelect={handleToolSelect}
        onColorSelect={handleColorSelect}
        onUndo={() => drawingDispatch({ type: 'UNDO' })}
        onRedo={() => drawingDispatch({ type: 'REDO' })}
        selectedTool={drawingState.selectedTool}
        selectedColor={drawingState.selectedColor}
        canUndo={drawingState.historyIndex > 0}
        canRedo={drawingState.historyIndex < drawingState.history.length - 1}
      />
    </div>

    
    <div className="absolute inset-0">
      <ResponsiveContainer>
        <LineChart 
      data={data} 
      margin={{ top: 20, right: 30, left: 10, bottom: 0 }}
      onMouseMove={(e) => {
        if (drawingState.selectedTool === 'move') {
          handleChartPan(e);
        }
      }}
      onMouseUp={() => {
        if (drawingState.selectedTool === 'move') {
          handleChartPanEnd();
        }
      }}
    >
          <CartesianGrid 
            strokeDasharray="3 3" 
            stroke={chartColors.grid} 
            vertical={false} 
          />
          
          {/* Pattern Overlays */}
          {activeIndicators.patterns && significantPatterns.map((pattern, index) => {
  const patternId = `pattern-${index}`;
  const isActive = hoveredPattern === patternId || lockedPattern === patternId;
  const config = PATTERN_CONFIGS[pattern.type];
  if (!config) return null;

  const [primaryColor, secondaryColor] = config.colors;
  const startPoint = data[pattern.startIndex];
  const endPoint = data[pattern.endIndex];
  const mid = (startPoint.price + endPoint.price) / 2;
  const height = Math.abs(startPoint.price - endPoint.price) * 0.5;

  return (
    <React.Fragment key={patternId}>
      <ReferenceArea
        yAxisId="price"
        x1={startPoint.timestamp}
        x2={endPoint.timestamp}
        y1={mid - height}
        y2={mid + height}
        fill={isActive ? primaryColor : secondaryColor}
        fillOpacity={0.15}
        stroke={primaryColor}
        strokeWidth={1}
        strokeOpacity={0.5}
        className="cursor-pointer"
        onMouseEnter={(e: any) => handlePatternHover(patternId, e)}
        onMouseLeave={() => {
          // Only clear if not locked
          if (!lockedPattern) {
            handlePatternHover(null);
          }
        }}
        onClick={() => {
          // This is the key change - directly handle pattern selection
          if (selectedPatternId === patternId) {
            setSelectedPatternId(null);
            setLockedPattern(null);
            setPatternPopup(null);
          } else {
            setSelectedPatternId(patternId);
            setLockedPattern(patternId);
            // Update the pattern popup if needed
            if (patternPopup) {
              setPatternPopup({
                ...patternPopup,
                id: patternId,
                pattern
              });
            }
          }
        }}
      />
      
      {/* Pattern Popup */}
      // In the PatternPopupContent section of SuperChart.tsx, replace the existing code with:
      {(isActive && patternPopup?.id === patternId) && (
  <foreignObject
    x={0}
    y={0}
    width={chartRef.current?.clientWidth || 0}
    height={chartRef.current?.clientHeight || 0}
  >
    <SmartPatternPopup 
      pattern={pattern}
      position={{
        x: patternPopup.x,
        y: patternPopup.y
      }}
      chartBounds={chartRef.current?.getBoundingClientRect() || new DOMRect()}
    />
  </foreignObject>
)}
    </React.Fragment>
  );
})}
          {/* Volume Area */}
          {activeIndicators.volume && (
            <Area
              yAxisId="volume"
              type="monotone"
              dataKey="volume"
              stroke="none"
              fill={chartColors.volume.neutral}
              fillOpacity={0.3}
            />
          )}

          {/* Technical Indicators */}
          {activeIndicators.ema20 && (
            <Line
              yAxisId="price"
              type="monotone"
              dataKey="ema20"
              stroke={INDICATOR_CONFIGS.ema20.color}
              dot={false}
              strokeWidth={1}
            />
          )}
          
          {activeIndicators.ema50 && (
            <Line
              yAxisId="price"
              type="monotone"
              dataKey="ema50"
              stroke={INDICATOR_CONFIGS.ema50.color}
              dot={false}
              strokeWidth={1}
            />
          )}

          {activeIndicators.rsi && (
            <Line
              yAxisId="rsi"
              type="monotone"
              dataKey="rsi"
              stroke={INDICATOR_CONFIGS.rsi.color}
              dot={false}
              strokeWidth={1}
            />
          )}

          {/* Main Price Line */}
          <Line
            yAxisId="price"
            type="monotone"
            dataKey="price"
            stroke={chartColors.price}
            dot={false}
            strokeWidth={1.5}
            isAnimationActive={false}
          />

          {/* Axes */}
          <XAxis
            dataKey="timestamp"
            tickFormatter={(timestamp) => {
              const date = new Date(timestamp);
              return timeframe === '1D'
                ? date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                : date.toLocaleDateString([], { month: 'short', day: 'numeric' });
            }}
            tick={{ fontSize: 12, fill: isDarkMode ? '#9CA3AF' : '#4B5563' }}
          />

        <YAxis
          yAxisId="price"
          orientation="right"
          domain={[
            chartState.autoScale ? 'auto' : (chartScales?.price.min ?? 'auto'),
            chartState.autoScale ? 'auto' : (chartScales?.price.max ?? 'auto')
          ]}
          tickFormatter={(value) => `$${value.toFixed(2)}`}
          tick={{ fontSize: 12, fill: isDarkMode ? '#9CA3AF' : '#4B5563' }}
        />

          <YAxis
            yAxisId="volume"
            orientation="left"
            domain={[0, chartScales?.volume.max || 'auto']}
            tickFormatter={(value) => {
              if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`;
              if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K`;
              return value;
            }}
            tick={{ fontSize: 12, fill: isDarkMode ? '#9CA3AF' : '#4B5563' }}
          />

          {activeIndicators.rsi && (
            <YAxis
              yAxisId="rsi"
              orientation="right"
              
              domain={[0, 100]}
              tickFormatter={(value) => value.toFixed(0)}
              tick={{ fontSize: 12, fill: isDarkMode ? '#9CA3AF' : '#4B5563' }}
            />
          )}

          <Tooltip
            content={CustomTooltip}
            position={{ y: 0 }}
            cursor={{
              stroke: isDarkMode ? '#4B5563' : '#9CA3AF',
              strokeWidth: 1,
              strokeDasharray: '3 3'
            }}
          />

          <Brush
            dataKey="timestamp"
            height={30}
            stroke={isDarkMode ? '#4B5563' : '#9CA3AF'}
            fill={isDarkMode ? '#1F2937' : '#F3F4F6'}
            onChange={({ startIndex, endIndex }) => {
              if (startIndex !== undefined && endIndex !== undefined) {
                setChartState(prev => ({
                  ...prev,
                  zoom: {
                    start: (startIndex / data.length) * 100,
                    end: (endIndex / data.length) * 100
                  }
                }));
              }
            }}
          />
        </LineChart>
      </ResponsiveContainer>
      <ChartDrawingLayer
        width={chartRef.current?.clientWidth || 0}
        height={chartRef.current?.clientHeight || 0}
        selectedTool={drawingState.selectedTool}
        selectedColor={drawingState.selectedColor}
        onDrawingComplete={handleDrawingComplete}
        // Pass chart scaling info for proper coordinate mapping
        scale={{
          x: chartState.zoom.end - chartState.zoom.start,
          y: 1
        }}
        offset={{
          x: chartState.pan.x,
          y: chartState.pan.y
        }}
      />
    </div>
  </div>
  );

  function handleRefresh(event: React.MouseEvent<HTMLButtonElement>): void {
    if (selectedStock) {
      loadChartData(selectedStock);
    }
  }
  // Return main component
  return (
    <div className="relative min-h-screen">
      {/* Full Screen Background */}
      <div className="fixed inset-0 bg-gradient-to-br from-gray-900 via-black to-gray-900">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#232323_1px,transparent_1px),linear-gradient(to_bottom,#232323_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_50%,#000_70%,transparent_100%)]" />
      </div>

      {/* Main Content */}
      <div className="relative p-6 w-full max-w-[1600px] mx-auto">
        {/* Header */}
         {/* Header */}
{/* Header */}
<div className="relative z-10 mb-8">
  {/* Main Header Bar */}
  <div className="flex items-center justify-between">
    {/* Left side - Title and Status */}
    <div className="flex items-center">
      {/* Title Section - Fixed Width */}
      <div className="w-92">
        <div className="flex items-center">
          <h1 className="text-xl font-medium text-white flex items-center space-x-3">
            <span>SmartCharts™</span>
            <span className="text-gray-400 font-normal">/</span>
            {selectedStock ? (
              <div className="flex items-center space-x-3">
                <div className="relative">
                  <div className={`w-7 h-7 rounded-full ${
                    isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
                  } flex items-center justify-center overflow-hidden`}>
                    <img
                      src={`/api/market/company-logo/${selectedStock}`}
                      alt={selectedStock}
                      className="w-full h-full object-contain"
                      onError={(e) => {
                        // On error, replace with letter
                        const target = e.target as HTMLImageElement;
                        const parent = target.parentElement;
                        if (parent) {
                          parent.innerHTML = `<span class="${
                            isDarkMode ? 'text-gray-400' : 'text-gray-600'
                          } font-medium">${selectedStock[0]}</span>`;
                        }
                      }}
                    />
                  </div>
                  <div className="absolute -right-1 -bottom-1">
                    <div className="w-2 h-2 rounded-full bg-green-500"></div>
                  </div>
                </div>
                <span className="text-gray-200 font-medium">{selectedStock}</span>
              </div>
            ) : (
              <span className="text-gray-500">Untitled Chart</span>
            )}
          </h1>
        </div>
        <div className="mt-1 flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <div className="w-1.5 h-1.5 rounded-full bg-green-500"></div>
            <span className="text-xs text-gray-400">Live Data</span>
          </div>
          {lastUpdate && (
            <span className="text-xs text-gray-500">
              Updated {lastUpdate.toLocaleTimeString()}
            </span>
          )}
        </div>
      </div>

      {/* Time Frame Selector - Now in a fixed position */}
      <div className="ml-[20rem] fixed flex space-x-2">
      <TimeframeSelector 
  value={timeframe}
  onChange={handleTimeframeChange}
  disabled={isLoading}
/>
      </div>
    </div>

    {/* Right side - Actions */}
    <div className="flex items-center space-x-3">
      <button
        onClick={() => setShowSettings(!showSettings)}
        className={`p-2 rounded-lg transition-colors ${
          isDarkMode
            ? 'hover:bg-gray-800/50 text-gray-400'
            : 'hover:bg-gray-800/30 text-gray-400'
        }`}
      >
        <Settings className="w-5 h-5" />
      </button>

      <button
        onClick={handleRefresh}
        className={`p-2 rounded-lg transition-colors ${
          isDarkMode
            ? 'hover:bg-gray-800/50 text-gray-400'
            : 'hover:bg-gray-800/30 text-gray-400'
        }`}
      >
        <RefreshCcw className="w-5 h-5" />
      </button>
    </div>
  </div>
</div>

        {/* Stock Search */}
        <div className="mb-6">
        <StockSelector
          value={selectedStock}
          onChange={(value) => setSelectedStock(value)}
          onSelect={(ticker) => {
            setSelectedStock(ticker);
            loadChartData(ticker);
          }}
          onValid={() => {}}
          disabled={isLoading}
        />
        </div>


        {/* Main Chart Area */}
        <div className={`p-6 rounded-xl ${
          isDarkMode ? 'bg-gray-800/50 backdrop-blur-xl' : 'bg-white/50 backdrop-blur-xl'
        } border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          {isLoading ? (
            <div className="h-[600px] flex items-center justify-center">
              <LoadingSpinner />
            </div>
            ) : error ? (
              <div className="h-[600px] flex flex-col items-center justify-center text-center">
                <AlertTriangle className="w-12 h-12 text-red-500 mb-4" />
                <h3 className="text-xl font-medium text-red-500 mb-2">
                  Chart Data Error
                </h3>
                <p className="text-gray-400 max-w-md mb-4">
                  {error}
                </p>
                <button
                  
                  className="px-4 py-2 rounded-lg bg-red-500 text-white hover:bg-red-600 
                    transition-colors flex items-center"
                >
                  <RefreshCcw className="w-4 h-4 mr-2" />
                  Try Again
                </button>
              </div>
            ) : !selectedStock ? (
              <div className="h-[600px] flex flex-col items-center justify-center text-center">
                <Search className="w-12 h-12 text-gray-400 mb-4" />
                <h3 className="text-xl font-medium text-gray-300 mb-2">
                  Select a Stock to Begin
                </h3>
                <p className="text-gray-500 max-w-md">
                  Enter a stock symbol above to view advanced technical analysis
                </p>
              </div>
            ) : (
              renderChart()
            )}
          </div>
          <div className="z-30 relative mt-6">
          <PatternDetailsSection 
  selectedPattern={selectedPatternId ? significantPatterns[parseInt(selectedPatternId.split('-')[1])] : null}
  patterns={significantPatterns}
  isDarkMode={isDarkMode}
  onSelectPattern={(patternId: string) => {
    if (selectedPatternId === patternId) {
      setSelectedPatternId(null);
      setLockedPattern(null);
      setPatternPopup(null);
    } else {
      setSelectedPatternId(patternId);
      setLockedPattern(patternId);
      const index = parseInt(patternId.split('-')[1]);
      if (significantPatterns[index]) {
        setSelectedPatternId(patternId);
        setLockedPattern(patternId);
      }
    }
  }}
/>
  
          {/* Pattern Analysis Panel */}
          {selectedStock && significantPatterns.length > 0 && (
            <div className="mt-6">
              {/* Pattern Analysis Header */}
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <Brain className="w-5 h-5 text-blue-500 mr-2" />
                  <h2 className="text-lg font-medium text-white">
                    Pattern Analysis
                  </h2>
                </div>
                <div className="text-sm text-gray-400">
                  {significantPatterns.length} pattern{significantPatterns.length !== 1 ? 's' : ''} detected
                </div>
              </div>
  
              {/* Pattern Grid */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {significantPatterns.map((pattern, index) => {
                  const config = PATTERN_CONFIGS[pattern.type];
                  if (!config) return null;
  
                  const [primaryColor] = config.colors;
                  const patternId = `pattern-${index}`;
                  const isActive = hoveredPattern === patternId || lockedPattern === patternId;
  
                  return (
                    <div
                      key={patternId}
                      className={`p-4 rounded-xl transition-all cursor-pointer ${
                        isActive
                          ? isDarkMode
                            ? 'bg-gray-700 ring-2 ring-blue-500'
                            : 'bg-white ring-2 ring-blue-500'
                          : isDarkMode
                          ? 'bg-gray-800/50 hover:bg-gray-700'
                          : 'bg-white/50 hover:bg-gray-50'
                      } border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}
                      onMouseEnter={() => setHoveredPattern(patternId)}
                      onMouseLeave={() => setHoveredPattern(null)}
                      onClick={() => {
                        const currentPatternId = `pattern-${index}`;
                        if (lockedPattern === currentPatternId) {
                          setLockedPattern(null);
                          setPatternPopup(null);
                          setSelectedPatternId(null);
                        } else {
                          setLockedPattern(currentPatternId);
                          setSelectedPatternId(currentPatternId);
                        }
                      }}
                    >
                      {/* Pattern Header */}
                      <div className="flex items-center justify-between mb-3">
                        <div className="flex items-center">
                          <div 
                            className="w-2 h-2 rounded-full mr-2" 
                            style={{ backgroundColor: primaryColor }}
                          />
                          <h3 className={`font-medium ${
                            isDarkMode ? 'text-white' : 'text-gray-900'
                          }`}>
                            {config.type}
                          </h3>
                        </div>
                        <span className={`px-2 py-0.5 text-xs rounded-full ${
                          config.sentiment === 'bullish'
                            ? 'bg-green-500/20 text-green-400'
                            : config.sentiment === 'bearish'
                            ? 'bg-red-500/20 text-red-400'
                            : 'bg-gray-500/20 text-gray-400'
                        }`}>
                          {config.sentiment.toUpperCase()}
                        </span>
                      </div>
  
                      {/* Pattern Description */}
                      <p className={`text-sm mb-3 line-clamp-2 ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-600'
                      }`}>
                        {pattern.explanation || config.description}
                      </p>
  
                      {/* Pattern Metrics */}
                      <div className="space-y-2">
                        <div className="flex items-center justify-between">
                          <span className={`text-sm ${
                            isDarkMode ? 'text-gray-500' : 'text-gray-500'
                          }`}>
                            Confidence
                          </span>
                          <div className="flex items-center">
                            <div className="w-16 h-1 rounded-full bg-gray-200 mr-2">
                              <div 
                                className="h-full rounded-full"
                                style={{ 
                                  width: `${pattern.confidence * 100}%`,
                                  backgroundColor: primaryColor
                                }}
                              />
                            </div>
                            <span className={`text-sm ${
                              isDarkMode ? 'text-gray-400' : 'text-gray-600'
                            }`}>
                              {Math.round(pattern.confidence * 100)}%
                            </span>
                          </div>
                        </div>
  
                        <div className="flex items-center justify-between">
                          <span className={`text-sm ${
                            isDarkMode ? 'text-gray-500' : 'text-gray-500'
                          }`}>
                            Duration
                          </span>
                          <span className={`text-sm ${
                            isDarkMode ? 'text-gray-400' : 'text-gray-600'
                          }`}>
                            {pattern.endIndex - pattern.startIndex} periods
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
  
          {/* Settings Panel */}
          {showSettings && <SettingsPanel />}
  
          {/* Last Updated Indicator */}
          {lastUpdate && (
            <div className="absolute bottom-4 right-4">
              <div className={`flex items-center text-sm ${
                isDarkMode ? 'text-gray-500' : 'text-gray-400'
              }`}>
                <Clock className="w-4 h-4 mr-1" />
                Last updated: {lastUpdate.toLocaleTimeString()}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    );
  };
  
  export default SuperChart;

  
function handleChartPanStart(e: CategoricalChartState) {
  throw new Error('Function not implemented.');
}

function handleChartPan(e: CategoricalChartState) {
  throw new Error('Function not implemented.');
}

function handleChartPanEnd() {
  throw new Error('Function not implemented.');
}

