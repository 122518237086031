import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Settings,
  Sliders,
  Layout,
  PaintBucket,
  Type,
  AlignLeft,
  Palette,
  Eye,
  Check,
  ChevronRight,
  AlertTriangle,
  Info
} from 'lucide-react';

const CustomizePrism = () => {
  const { isDarkMode } = useTheme();

  const customizationCategories = [
    {
      title: 'Interface Preferences',
      description: 'Personalize how Prism looks and feels',
      icon: Layout,
      features: [
        'Light and dark mode toggle',
        'Layout density controls',
        'Sidebar width adjustment',
        'Widget arrangement options',
        'Custom workspace layouts'
      ],
      tips: 'Save different layouts for different analysis workflows'
    },
    {
      title: 'Visual Theming',
      description: 'Customize colors and visual elements',
      icon: PaintBucket,
      features: [
        'Accent color selection',
        'Chart color themes',
        'Custom background options',
        'Contrast adjustments',
        'Font scaling preferences'
      ],
      tips: 'Use high-contrast themes for better visibility in bright environments'
    },
    {
      title: 'Content Display',
      description: 'Control how information is presented',
      icon: AlignLeft,
      features: [
        'Default data visualization type',
        'Chart time period defaults',
        'Table column customization',
        'Information density settings',
        'Number formatting preferences'
      ],
      tips: 'Configure different views for different device types'
    },
    {
      title: 'Notification Settings',
      description: 'Manage alerts and notification preferences',
      icon: Eye,
      features: [
        'Price alert thresholds',
        'Research completion notifications',
        'Email notification frequency',
        'Mobile push notification settings',
        'Market hours notifications'
      ],
      tips: 'Set up quiet hours to avoid disruptions during important times'
    }
  ];

  const profileSettings = [
    {
      title: 'Configure Your Profile',
      description: 'Access the profile settings menu',
      steps: [
        'Click your avatar in the top-right corner',
        'Select "Settings" from the dropdown menu',
        'Navigate to the "Preferences" tab',
        'Make your desired changes',
        'Click "Save Changes" when done'
      ]
    },
    {
      title: 'Save Custom Layouts',
      description: 'Create and manage workspace configurations',
      steps: [
        'Arrange the dashboard layout to your preference',
        'Click the "Layouts" icon in the top toolbar',
        'Select "Save Current Layout"',
        'Name your layout configuration',
        'Access saved layouts anytime from the Layouts menu'
      ]
    },
    {
      title: 'Create Custom Themes',
      description: 'Design and apply your own color schemes',
      steps: [
        'Go to Settings > Appearance > Themes',
        'Click "Create New Theme"',
        'Use the color picker to select your palette',
        'Preview changes in real-time',
        'Save and apply your custom theme'
      ]
    }
  ];

  const bestPractices = [
    'Create different layouts optimized for different tasks',
    'Use color themes that reduce eye strain during long sessions',
    'Save your preferred settings to your Prism account for use across devices',
    'Adjust font sizes for comfortable reading on your specific display',
    'Set up keyboard shortcuts for your most common actions'
  ];

  const commonMistakes = [
    'Making the interface too visually busy with too many widgets visible',
    'Choosing color schemes that make charts difficult to interpret',
    'Setting too many notifications that create alert fatigue',
    'Not saving your custom configurations after making changes',
    'Making text too small to comfortably read on high-resolution displays'
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Settings className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Customizing Prism
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Personalize your experience with powerful customization options
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              Prism offers extensive customization options to tailor the platform to your specific needs and preferences. 
              From visual themes to workspace layouts, notification settings to data visualization preferences, you can 
              create a personalized environment that enhances your productivity and aligns with your workflow.
            </p>
          </div>

          {/* Customization Categories */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Customization Categories
          </h2>

          <div className="space-y-12 mb-16">
            {customizationCategories.map((category, index) => {
              const Icon = category.icon;
              return (
                <div key={index} className={`p-6 rounded-xl border ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                }`}>
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    } mr-4`}>
                      <Icon className={`w-6 h-6 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <div>
                      <h3 className={`text-xl font-bold ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {category.title}
                      </h3>
                    </div>
                  </div>
                  
                  <p className={`mb-6 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {category.description}
                  </p>
                  
                  <div className="grid md:grid-cols-2 gap-8">
                    <div>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Features:</h4>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {category.features.map((feature, i) => (
                          <li key={i} className="flex items-start">
                            <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div className={`rounded-lg p-4 ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                    }`}>
                      <h4 className={`text-sm font-medium mb-3 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Pro Tip:</h4>
                      <p className={`${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {category.tips}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* How to Access and Use */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            How to Access and Use Customization Options
          </h2>

          <div className="space-y-8 mb-16">
            {profileSettings.map((setting, index) => (
              <div key={index} className={`p-6 rounded-xl border ${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
              }`}>
                <h3 className={`text-xl font-bold mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {setting.title}
                </h3>
                
                <p className={`mb-4 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {setting.description}
                </p>
                
                <ol className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {setting.steps.map((step, i) => (
                    <li key={i} className="flex items-start">
                      <div className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-medium mr-2 mt-0.5">{i+1}</div>
                      <span>{step}</span>
                    </li>
                  ))}
                </ol>
              </div>
            ))}
          </div>

          {/* Best Practices */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <h3 className={`text-xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Customization Best Practices
            </h3>
            
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h4 className={`font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Do:</h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {bestPractices.map((practice, i) => (
                    <li key={i} className="flex items-start">
                      <Check className="w-4 h-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                      <span>{practice}</span>
                    </li>
                  ))}
                </ul>
              </div>
              
              <div>
                <h4 className={`font-medium mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Avoid:</h4>
                <ul className={`space-y-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {commonMistakes.map((mistake, i) => (
                    <li key={i} className="flex items-start">
                      <AlertTriangle className="w-4 h-4 text-yellow-500 mr-2 mt-0.5 flex-shrink-0" />
                      <span>{mistake}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Important Note */}
          <div className={`p-6 rounded-xl border ${
            isDarkMode ? 'bg-blue-900/20 border-blue-800/30' : 'bg-blue-50 border-blue-100'
          } mb-12`}>
            <div className="flex items-start">
              <Info className={`w-6 h-6 mr-4 flex-shrink-0 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div>
                <h3 className={`text-lg font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Synchronization Note</h3>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  All customization settings are synchronized with your Prism account and will be applied
                  across all devices where you use Prism. Local overrides can be set up for specific devices
                  through the advanced settings menu.
                </p>
              </div>
            </div>
          </div>

          {/* Related Documentation */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Related Documentation
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <a 
              href="/docs/pro-features"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Pro Features
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Advanced customization options available with Pro subscription
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
            
            <a 
              href="/docs/credits"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Understanding Credits
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  How the credit system works for premium features
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default CustomizePrism;