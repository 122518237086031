// src/pages/legal/security.tsx
import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Shield, 
  Lock, 
  Key, 
  RefreshCw, 
  Server, 
  AlertTriangle,
  ChevronRight,
  Save,
  Download,
  UserCheck,
  Trash2,
  FileText,
  MessageSquare
} from 'lucide-react';
import { motion } from 'framer-motion';

interface SecuritySection {
  title: string;
  icon: React.FC<{ className?: string }>;
  content: string;
  items?: string[];
}

const securitySections: SecuritySection[] = [
  {
    title: 'Account Security',
    icon: UserCheck,
    content: 'Your Newsroom account is protected with multiple security measures.',
    items: [
      'Strong password enforcement and secure storage',
      'Phone verification for enhanced account security',
      'Two-factor authentication support',
      'Automatic session timeouts and device management',
      'Complete account deletion available in settings'
    ]
  },
  {
    title: 'Data Encryption',
    icon: Lock,
    content: 'All your market research and trading data is encrypted end-to-end.',
    items: [
      'TLS 1.3 encryption for all data in transit',
      'AES-256 encryption for stored chat history and trade ideas',
      'End-to-end encryption for sensitive financial information',
      'Encrypted backups of all user data',
      'Regular security certificate updates'
    ]
  },
  {
    title: 'Privacy Controls',
    icon: Shield,
    content: 'You have complete control over your data and privacy settings.',
    items: [
      'Export your complete data history in JSON format',
      'Delete saved chats and trade preferences',
      'Manage analytics sharing and email preferences',
      'Control data retention policies for AI interactions',
      'Public profile visibility settings'
    ]
  },
  {
    title: 'Chat & Trade Security',
    icon: MessageSquare,
    content: 'Your AI conversations and trade ideas are securely stored and managed.',
    items: [
      'Automatic, secure saving of all Prism AI conversations',
      'Protected storage of custom trade idea preferences',
      'Secure cloud synchronization across devices',
      'Role-based access controls for enterprise accounts',
      'Secure sharing options for market insights'
    ]
  },
  {
    title: 'Infrastructure Security',
    icon: Server,
    content: 'Our platform is built with security as the foundation.',
    items: [
      'Regular security audits and penetration testing',
      'DDoS protection and mitigation',
      'Real-time threat monitoring and alerting',
      'Secure cloud infrastructure with redundancy',
      'SOC 2 compliance (in progress)'
    ]
  },
  {
    title: 'Continuous Improvements',
    icon: RefreshCw,
    content: 'We continuously enhance our security measures.',
    items: [
      'Regular security patches and updates',
      'Vulnerability management program',
      'Third-party security assessments',
      'Bug bounty program',
      'Regular employee security training'
    ]
  }
];

const SecurityPage = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
      {/* Hero Section with improved gradient */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-600/20 to-purple-600/20 backdrop-blur-sm" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center"
          >
            <Shield className={`w-16 h-16 mx-auto mb-6 ${
              isDarkMode ? 'text-blue-400' : 'text-blue-600'
            }`} />
            <h1 className={`text-4xl md:text-5xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Security & Data Protection
            </h1>
            <p className={`text-xl max-w-3xl mx-auto ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              Learn how we protect your account, conversations, and trading data
            </p>
          </motion.div>
        </div>
      </div>

      {/* Content with updated styling */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Security Overview Card */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className={`mb-16 p-8 rounded-2xl border ${
            isDarkMode 
              ? 'bg-gray-800/70 backdrop-blur-xl border-gray-700/50' 
              : 'bg-white border-gray-200/50 shadow-xl'
          }`}
        >
          <div className="flex items-center mb-4">
            <div className="p-3 rounded-xl bg-blue-600 mr-4">
              <Lock className="w-6 h-6 text-white" />
            </div>
            <h2 className={`text-2xl font-bold ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Our Security Commitment
            </h2>
          </div>
          <p className={`text-lg ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            At Newsroom, we understand the sensitive nature of financial data and trading activities. That's why security isn't just a feature—it's built into everything we do. From how we store your trade ideas to how we protect your conversations with Prism AI, we employ multiple layers of security to ensure your information remains private and protected.
          </p>
        </motion.div>

        {/* Security Sections with modern card design */}
        <div className="grid md:grid-cols-2 gap-8">
          {securitySections.map((section, index) => {
            const Icon = section.icon;
            return (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className={`p-6 rounded-2xl border ${
                  isDarkMode 
                    ? 'bg-gray-800/50 backdrop-blur-xl border-gray-700/50' 
                    : 'bg-white/80 backdrop-blur-sm border-gray-200/50 shadow-lg'
                }`}
              >
                <div className="flex items-center mb-4">
                  <div className="p-3 rounded-xl bg-gradient-to-br from-blue-500 to-blue-600">
                    <Icon className="w-6 h-6 text-white" />
                  </div>
                  <h3 className={`text-xl font-bold ml-4 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {section.title}
                  </h3>
                </div>
                <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  {section.content}
                </p>
                {section.items && (
                  <ul className="space-y-2">
                    {section.items.map((item, i) => (
                      <li
                        key={i}
                        className={`flex items-start ${
                          isDarkMode ? 'text-gray-300' : 'text-gray-600'
                        }`}
                      >
                        <ChevronRight className="w-5 h-5 mr-2 flex-shrink-0 text-blue-500" />
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </motion.div>
            );
          })}
        </div>

        {/* Account Security & Settings */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className={`mt-16 p-8 rounded-2xl border ${
            isDarkMode 
              ? 'bg-gradient-to-br from-blue-900/20 to-purple-900/20 backdrop-blur-xl border-blue-800/30' 
              : 'bg-gradient-to-br from-blue-50 to-indigo-50 border-blue-100/50 shadow-lg'
          }`}
        >
          <div className="flex items-center mb-6">
            <div className="p-3 rounded-xl bg-gradient-to-br from-blue-500 to-blue-600 mr-4">
              <Shield className="w-6 h-6 text-white" />
            </div>
            <h2 className={`text-2xl font-bold ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Your Security Controls
            </h2>
          </div>
          <div className="grid md:grid-cols-2 gap-6">
            <div className={`p-4 rounded-xl ${
              isDarkMode 
                ? 'bg-gray-800/50 border border-gray-700/50' 
                : 'bg-white border border-gray-200/50'
              }`}
            >
              <div className="flex items-center mb-3">
                <Download className={`w-5 h-5 mr-2 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                <h3 className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  Export Your Data
                </h3>
              </div>
              <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                Download your complete data history including conversations, trade ideas, and preferences in JSON format from your settings.
              </p>
            </div>
            
            <div className={`p-4 rounded-xl ${
              isDarkMode 
                ? 'bg-gray-800/50 border border-gray-700/50' 
                : 'bg-white border border-gray-200/50'
              }`}
            >
              <div className="flex items-center mb-3">
                <Save className={`w-5 h-5 mr-2 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                <h3 className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  Automatic Saves
                </h3>
              </div>
              <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                Your conversations with Prism AI and trade ideas are automatically saved and encrypted for your convenience and security.
              </p>
            </div>
            
            <div className={`p-4 rounded-xl ${
              isDarkMode 
                ? 'bg-gray-800/50 border border-gray-700/50' 
                : 'bg-white border border-gray-200/50'
              }`}
            >
              <div className="flex items-center mb-3">
                <Key className={`w-5 h-5 mr-2 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                <h3 className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  Phone Verification
                </h3>
              </div>
              <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                Enable phone verification in your account settings to add an extra layer of security.
              </p>
            </div>
            
            <div className={`p-4 rounded-xl ${
              isDarkMode 
                ? 'bg-gray-800/50 border border-gray-700/50' 
                : 'bg-white border border-gray-200/50'
              }`}
            >
              <div className="flex items-center mb-3">
                <Trash2 className={`w-5 h-5 mr-2 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                <h3 className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  Account Deletion
                </h3>
              </div>
              <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                You can permanently delete your account and all associated data at any time through your account settings.
              </p>
            </div>
          </div>
        </motion.div>

        {/* Report Security Issues */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className={`mt-16 p-8 rounded-2xl border ${
            isDarkMode 
              ? 'bg-gray-800/50 backdrop-blur-xl border-gray-700/50' 
              : 'bg-white/80 backdrop-blur-sm border-gray-200/50 shadow-xl'
          }`}
        >
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Report Security Issues
          </h2>
          <p className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            We take security seriously and appreciate your help in keeping Newsroom secure.
            If you discover a vulnerability or have security concerns, please contact our security team:
          </p>
          <div className="flex flex-wrap gap-4">
            <a
              href="mailto:hello@news-room.ca"
              className="inline-flex items-center px-6 py-3 rounded-xl bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:from-blue-600 hover:to-blue-700 transition-all shadow-lg hover:shadow-xl"
            >
              <AlertTriangle className="w-5 h-5 mr-2" />
              Report Vulnerability
            </a>
            <a
              href="/resources/help"
              className="inline-flex items-center px-6 py-3 rounded-xl bg-gray-600/20 border border-gray-600/30 text-gray-300 hover:bg-gray-600/30 transition-all"
            >
              <FileText className="w-5 h-5 mr-2" />
              Security Documentation
            </a>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default SecurityPage;