import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { useTheme } from '../../contexts/ThemeContext';
import { useAuth } from '../../contexts/AuthContext';
import StreamingIndicator from './StreamingIndicator';
import {
  Clock, Link, ExternalLink, ChevronRight, AlertTriangle,
  Globe, AlertCircle, FileText, Hash,
  TrendingUp,
  BookmarkPlus,
  LineChart,
  Box
} from 'lucide-react';
import { PromptChip, StockChip, FileChip } from './WelcomeStatePreview';
import { ChatMessage, SearchResult, ResearchSource } from '../../types/chat';
import { useStockView } from '../../contexts/StockViewContext';
import { useWatchlists } from '../../hooks/useWatchlists';

interface MessageBlockProps {
  message: ChatMessage;
  styles: Record<string, string>;
  tickers?: string[];
  formatTimestamp: (timestamp: string | Date) => string;
}

interface NewsSourcesIndicatorProps {
  sources: NonNullable<ChatMessage['news']>;
}

interface WebSearchResultsProps {
  results: NonNullable<ChatMessage['webSearchResults']>;
}

interface ResearchSourcesProps {
  sources: NonNullable<ChatMessage['researchSources']>;
}

const InteractiveStockChip: React.FC<{
  ticker: string;
  companyName?: string;
  prefix: string;
  isDarkMode: boolean;
  readOnly?: boolean;
}> = ({ ticker, companyName, prefix, isDarkMode, readOnly = false }) => {
  const [imageError, setImageError] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { openStockModal } = useStockView();
  const { saveItem, watchlists, createList, addToList } = useWatchlists();
  const [showWatchlistMenu, setShowWatchlistMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const [newListName, setNewListName] = useState('');
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();

  // Handle clicks outside the menu
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowWatchlistMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSaveStock = (e: React.MouseEvent) => {
    e.stopPropagation();
    
    if (!user) {
      window.location.href = '/signup';
      return;
    }
    
    setShowWatchlistMenu(true);
  };

  const handleSaveIndividual = async () => {
    setIsLoading(true);
    try {
      await saveItem({
        type: 'STOCK',
        identifier: ticker,
        title: companyName || ticker
      });
      setShowWatchlistMenu(false);
    } catch (error) {
      console.error('Failed to save stock:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateList = async () => {
    if (!newListName.trim()) return;
    
    setIsLoading(true);
    try {
      const newList = await createList({ name: newListName });
      if (newList?.id) {
        await addToList(ticker, newList.id);
      }
      setShowWatchlistMenu(false);
      setShowCreateForm(false);
      setNewListName('');
    } catch (error) {
      console.error('Failed to create list:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddToList = async (listId: string) => {
    setIsLoading(true);
    try {
      await addToList(ticker, listId);
      setShowWatchlistMenu(false);
    } catch (error) {
      console.error('Failed to add to list:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewChart = (e: React.MouseEvent) => {
    e.stopPropagation();
    openStockModal(ticker);
  };

  const handleAddToChat = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigator.clipboard.writeText(ticker);
  };

  return (
    <motion.div 
      className="relative z-10 mr-2 mb-2"
      layout
      transition={{
        layout: { 
          type: "spring", 
          stiffness: 500, 
          damping: 30 
        }
      }}
    >
      <motion.div 
        className={`relative inline-flex items-center gap-1 py-1 px-2 rounded-md text-sm ${
          isDarkMode ? 'bg-green-900/40 text-green-300 border border-green-700/50' : 
                    'bg-green-100 text-green-800 border border-green-200'
        } transition-all duration-150`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        layout
        animate={{
          width: isHovered ? "auto" : "auto",
          paddingRight: isHovered ? "4.5rem" : "0.5rem" // Double the expansion
        }}
        transition={{
          type: "spring",
          stiffness: 700,  // Increased stiffness for faster animation
          damping: 25,
          duration: 0.1    // Shorter duration
        }}
      >
        {/* Company logo or fallback */}
        <div className={`overflow-hidden w-5 h-5 rounded-full flex items-center justify-center 
          ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} 
          border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} mr-1`}
        >
          {!imageError ? (
            <img
              src={`/api/market/company-logo/${ticker}`}
              alt={`${ticker} logo`}
              className="w-full h-full object-cover"
              onError={() => setImageError(true)}
            />
          ) : (
            <span className={`text-xs font-semibold ${
              isDarkMode ? 'text-gray-400' : 'text-gray-600'
            }`}>
              {ticker[0]}
            </span>
          )}
        </div>
        
        {prefix && (
          <span className={`text-xs px-1.5 py-0.5 rounded ${
            isDarkMode ? 'bg-green-800/70 text-green-300' : 'bg-green-200 text-green-700'
          }`}>
            {prefix.replace(/[\[\]]/g, '')}
          </span>
        )}
        
        <span className="font-mono font-medium">{ticker}</span>
        {companyName && <span className="text-xs truncate max-w-[100px] opacity-70">{companyName}</span>}
        
        {/* Action buttons that slide in when hovered */}
        <AnimatePresence>
          {isHovered && (
            <motion.div
              className="absolute right-1 top-1/2 -translate-y-1/2 flex items-center"
              initial={{ opacity: 0, width: 0 }}
              animate={{ opacity: 1, width: 'auto' }}
              exit={{ opacity: 0, width: 0 }}
              transition={{ 
                type: "spring",
                stiffness: 800,
                damping: 20,
                duration: 0.08 // Much quicker animation
              }}
            >
              <motion.div 
                className="flex space-x-1.5"
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 800,
                  damping: 20,
                  staggerChildren: 0.02, // Faster stagger effect
                  delayChildren: 0.01  // Faster delay
                }}
              >
                {/* Stock action buttons */}
                <motion.button
                  onClick={handleViewChart}
                  className={`w-4 h-4 rounded-full flex items-center justify-center transition-colors ${
                    isDarkMode ? 'text-green-300 hover:text-white' : 'text-green-700 hover:text-green-900'
                  }`}
                  whileHover={{ scale: 1.15 }}
                  whileTap={{ scale: 0.9 }}
                  title="View Chart"
                >
                  <LineChart className="w-3.5 h-3.5" />
                </motion.button>
                
                <motion.button
                  onClick={handleAddToChat}
                  className={`w-4 h-4 rounded-full flex items-center justify-center transition-colors ${
                    isDarkMode ? 'text-green-300 hover:text-white' : 'text-green-700 hover:text-green-900'
                  }`}
                  whileHover={{ scale: 1.15 }}
                  whileTap={{ scale: 0.9 }}
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 800, damping: 20, delay: 0.02 }}
                  title="Add to Chat"
                >
                  <Box className="w-3.5 h-3.5" />
                </motion.button>
                
                <motion.button
                  onClick={handleSaveStock}
                  className={`w-4 h-4 rounded-full flex items-center justify-center transition-colors ${
                    isDarkMode ? 'text-green-300 hover:text-white' : 'text-green-700 hover:text-green-900'
                  }`}
                  whileHover={{ scale: 1.15 }}
                  whileTap={{ scale: 0.9 }}
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 800, damping: 20, delay: 0.04 }}
                  title="Save to Watchlist"
                >
                  <BookmarkPlus className="w-3.5 h-3.5" />
                </motion.button>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>

      {/* Watchlist popup menu */}
      <AnimatePresence>
        {showWatchlistMenu && (
          <motion.div
            ref={menuRef}
            className={`absolute z-[999] right-0 bottom-full mb-2 w-64 rounded-xl shadow-lg overflow-hidden ${
              isDarkMode ? 'bg-gray-900 border-gray-800' : 'bg-white border-gray-200'
            } border`}
            initial={{ opacity: 0, y: 10, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 10, scale: 0.95 }}
            transition={{ duration: 0.15, ease: "easeOut" }}
          >
            {/* Header */}
            <div className="flex items-center justify-between p-4 border-b border-gray-800">
              <h3 className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Save {ticker}
              </h3>
              <button 
                onClick={() => setShowWatchlistMenu(false)} 
                className="text-gray-500 hover:text-gray-400"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" 
                   stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>

            {/* Content */}
            <div className="p-4">
              {/* Individual Save Option */}
              <button
                onClick={handleSaveIndividual}
                disabled={isLoading}
                className={`w-full flex items-center space-x-3 p-3 rounded-lg mb-4 ${
                  isDarkMode 
                    ? 'bg-blue-500/10 text-blue-400 hover:bg-blue-500/20' 
                    : 'bg-blue-50 text-blue-600 hover:bg-blue-100'
                }`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
                   stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" 
                   className="mr-2">
                  <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                </svg>
                <span>{isLoading ? 'Saving...' : 'Save as Individual Item'}</span>
              </button>

              {/* Watchlists Section */}
              <div className="mb-4">
                <h4 className={`text-sm font-medium mb-2 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  Add to Watchlist
                </h4>
                <div className="space-y-2 max-h-48 overflow-y-auto">
                  {watchlists.map(list => (
                    <button
                      key={list.id}
                      onClick={() => handleAddToList(list.id)}
                      disabled={isLoading}
                      className={`w-full flex items-center justify-between p-3 rounded-lg
                        ${isDarkMode 
                          ? 'hover:bg-gray-800 text-gray-300' 
                          : 'hover:bg-gray-100 text-gray-700'}`}
                    >
                      <div className="flex items-center space-x-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
                           stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" 
                           className="text-gray-400">
                          <line x1="8" y1="6" x2="21" y2="6"></line>
                          <line x1="8" y1="12" x2="21" y2="12"></line>
                          <line x1="8" y1="18" x2="21" y2="18"></line>
                          <line x1="3" y1="6" x2="3.01" y2="6"></line>
                          <line x1="3" y1="12" x2="3.01" y2="12"></line>
                          <line x1="3" y1="18" x2="3.01" y2="18"></line>
                        </svg>
                        <span>{list.name}</span>
                      </div>
                      <span className="text-xs text-gray-500">
                        {list.items?.length || 0} items
                      </span>
                    </button>
                  ))}
                </div>
              </div>

              {/* Create New List Section */}
              {showCreateForm ? (
                <div className="space-y-2">
                  <input
                    type="text"
                    value={newListName}
                    onChange={(e) => setNewListName(e.target.value)}
                    placeholder="Enter list name..."
                    className={`w-full px-3 py-2 rounded-lg border ${
                      isDarkMode 
                        ? 'bg-gray-800 border-gray-700 text-white' 
                        : 'bg-white border-gray-300 text-gray-900'
                    } focus:ring-2 focus:ring-blue-500`}
                  />
                  <div className="flex space-x-2">
                    <button
                      onClick={() => setShowCreateForm(false)}
                      className={`flex-1 py-2 rounded-lg ${
                        isDarkMode 
                          ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' 
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleCreateList}
                      disabled={!newListName.trim() || isLoading}
                      className="flex-1 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600
                        disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isLoading ? 'Creating...' : 'Create'}
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => setShowCreateForm(true)}
                  className={`w-full flex items-center justify-center space-x-2 p-3 rounded-lg
                    ${isDarkMode 
                      ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' 
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" 
                     className="text-gray-400">
                    <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
                    <line x1="12" y1="11" x2="12" y2="17"></line>
                    <line x1="9" y1="14" x2="15" y2="14"></line>
                  </svg>
                  <span>Create New List</span>
                </button>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const SourceIcon: React.FC<{ type: string, source: string }> = ({ type, source }) => {
  const { isDarkMode } = useTheme();
  const [imageError, setImageError] = useState(false);
  
  // Get favicon for web sources
  const getFaviconUrl = (source: string): string => {
    try {
      const hostname = new URL(source).hostname;
      return `https://www.google.com/s2/favicons?domain=${hostname}&sz=64`;
    } catch {
      // For non-URL sources, try to extract domain name
      const domainMatch = source.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)/im);
      const domain = domainMatch ? domainMatch[1] : source;
      return `https://www.google.com/s2/favicons?domain=${domain}&sz=64`;
    }
  };
  
  // Non-web sources or fallback for image errors
  if (imageError || type !== 'WEB') {
    let bgColor = 'bg-blue-500/20';
    let textColor = 'text-blue-400';
    
    switch (type) {
      case 'NEWS':
        bgColor = 'bg-yellow-500/20';
        textColor = 'text-yellow-400';
        break;
      case 'MARKET_DATA':
        bgColor = 'bg-green-500/20';
        textColor = 'text-green-400';
        break;
      case 'TECHNICAL':
        bgColor = 'bg-purple-500/20';
        textColor = 'text-purple-400';
        break;
      case 'FUNDAMENTAL':
        bgColor = 'bg-orange-500/20';
        textColor = 'text-orange-400';
        break;
    }
    
    return (
      <div className={`w-6 h-6 rounded-full flex items-center justify-center ${bgColor} border ${
        isDarkMode ? 'border-gray-700' : 'border-gray-200'
      }`}>
        <span className={`text-xs font-medium ${textColor}`}>
          {source[0].toUpperCase()}
        </span>
      </div>
    );
  }
  
  // Image-based favicon
  return (
    <div className={`w-6 h-6 rounded-full overflow-hidden flex items-center justify-center ${
      isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
    } border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
      <img
        src={getFaviconUrl(source)}
        alt={source}
        className="w-4 h-4 object-contain"
        onError={() => setImageError(true)}
      />
    </div>
  );
};

const NewsSourcesIndicator: React.FC<NewsSourcesIndicatorProps> = ({ sources }) => {
  const { isDarkMode } = useTheme();
  const [showDetails, setShowDetails] = useState(false);
  const uniqueSources = Array.from(new Set(sources.map(s => s.source)));

  return (
    <div className="relative flex items-center mt-4">
      <div className={`h-5 w-px mr-3 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'}`} />
      <div className="flex items-center space-x-2">
        <div className="flex -space-x-2">
          {uniqueSources.slice(0, 3).map((source, i) => (
            <SourceIcon key={source} type="NEWS" source={source} />
          ))}
          {uniqueSources.length > 3 && (
            <div className={`w-6 h-6 rounded-full flex items-center justify-center ${
              isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
            } border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
              <span className="text-xs font-medium text-gray-400">
                +{uniqueSources.length - 3}
              </span>
            </div>
          )}
        </div>
        <button
          onClick={() => setShowDetails(!showDetails)}
          className={`flex items-center space-x-1 text-sm ${
            isDarkMode ? 'text-gray-400 hover:text-gray-300' : 'text-gray-600 hover:text-gray-700'
          } transition-colors duration-200`}
        >
          <span>News Sources</span>
          <ChevronRight className={`w-4 h-4 transform transition-transform duration-200 ${showDetails ? 'rotate-90' : ''}`} />
        </button>
      </div>
      
      {showDetails && (
        <div className={`absolute left-56 top-0 w-96 p-4 rounded-lg shadow-lg z-50 ${
          isDarkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white border border-gray-200'
        }`}>
          <div className="space-y-4 max-h-96 overflow-y-auto">
            {sources.map((article, i) => (
              <div key={i} className={`p-3 rounded-lg ${
                isDarkMode ? 'bg-gray-750 hover:bg-gray-700' : 'bg-gray-50 hover:bg-gray-100'
              } transition-colors`}>
                <a 
                  href={article.url} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className={`text-sm font-medium ${
                    isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-700'
                  } flex items-start`}
                >
                  <span className="flex-1">{article.title}</span>
                  <ExternalLink className="w-3.5 h-3.5 ml-1 mt-0.5 flex-shrink-0" />
                </a>
                <p className={`text-xs mt-1 line-clamp-2 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  {article.snippet}
                </p>
                <div className="flex items-center justify-between mt-2">
                  <span className={`text-xs ${
                    isDarkMode ? 'text-gray-500' : 'text-gray-500'
                  }`}>
                    {article.source}
                  </span>
                  <span className={`text-xs ${
                    isDarkMode ? 'text-gray-500' : 'text-gray-500'
                  }`}>
                    {new Date(article.date).toLocaleDateString()}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const WebSearchResultsIndicator: React.FC<WebSearchResultsProps> = ({ results }) => {
  const { isDarkMode } = useTheme();
  const [showDetails, setShowDetails] = useState(false);
  const uniqueSources = Array.from(new Set(results.map(s => s.source)));

  return (
    <div className="relative flex items-center mt-4">
      <div className={`h-5 mr-3 w-px  ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'}`} />
      <div className="flex items-center space-x-2">
        <div className="flex -space-x-2">
          {uniqueSources.slice(0, 3).map((source, i) => (
            <SourceIcon key={`web-${i}`} type="WEB" source={source} />
          ))}
          {uniqueSources.length > 3 && (
            <div className={`w-6 h-6 rounded-full flex items-center justify-center ${
              isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
            } border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
              <span className="text-xs font-medium text-gray-400">
                +{uniqueSources.length - 3}
              </span>
            </div>
          )}
        </div>
        <button
          onClick={() => setShowDetails(!showDetails)}
          className={`flex items-center space-x-1 text-sm ${
            isDarkMode ? 'text-gray-400 hover:text-gray-300' : 'text-gray-600 hover:text-gray-700'
          } transition-colors duration-200`}
        >
          <span>Web Sources</span>
          <ChevronRight className={`w-4 h-4 transform transition-transform duration-200 ${showDetails ? 'rotate-90' : ''}`} />
        </button>
      </div>
      
      {showDetails && (
        <div className={`absolute left-56 top-0 w-96 p-4 rounded-lg shadow-lg z-50 ${
          isDarkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white border border-gray-200'
        }`}>
          <div className="space-y-4 max-h-96 overflow-y-auto">
            {results.map((result, i) => (
              <div key={i} className={`p-3 rounded-lg ${
                isDarkMode ? 'bg-gray-750 hover:bg-gray-700' : 'bg-gray-50 hover:bg-gray-100'
              } transition-colors`}>
                <a 
                  href={result.url} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className={`text-sm font-medium ${
                    isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-700'
                  } flex items-start`}
                >
                  <span className="flex-1">{result.title}</span>
                  <ExternalLink className="w-3.5 h-3.5 ml-1 mt-0.5 flex-shrink-0" />
                </a>
                <p className={`text-xs mt-1 line-clamp-2 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  {result.snippet}
                </p>                <div className="flex items-center justify-between mt-2">
                  <span className={`text-xs ${
                    isDarkMode ? 'text-gray-500' : 'text-gray-500'
                  }`}>
                    {result.source}
                  </span>
                  {result.date && (
                    <span className={`text-xs ${
                      isDarkMode ? 'text-gray-500' : 'text-gray-500'
                    }`}>
                      {new Date(result.date).toLocaleDateString()}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const ResearchSourcesIndicator: React.FC<ResearchSourcesProps> = ({ sources }) => {
  const { isDarkMode } = useTheme();
  const [showDetails, setShowDetails] = useState(false);
  
  // Group sources by type for the dropdown
  const sourcesByType = sources.reduce((acc, source) => {
    const type = source.type || 'UNKNOWN';
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(source);
    return acc;
  }, {} as Record<string, ResearchSource[]>);
  
  // Count sources by type
  const typeCounts = Object.entries(sourcesByType).map(([type, srcs]) => ({
    type,
    count: srcs.length
  }));

  return (
    <div className="relative flex items-center mt-4">
      <div className={`h-5 w-px mr-3 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'}`} />
      <div className="flex items-center space-x-2">
        <div className="flex -space-x-2">
          {/* Show a sample from different source types */}
          {typeCounts.slice(0, 3).map(({ type }, i) => (
            <SourceIcon 
              key={`research-${i}`} 
              type={type}
              source={sourcesByType[type][0].source} 
            />
          ))}
          {typeCounts.length > 3 && (
            <div className={`w-6 h-6 rounded-full flex items-center justify-center ${
              isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
            } border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
              <span className="text-xs font-medium text-gray-400">
                +{typeCounts.length - 3}
              </span>
            </div>
          )}
        </div>
        <button
          onClick={() => setShowDetails(!showDetails)}
          className={`flex items-center space-x-1 text-sm ${
            isDarkMode ? 'text-gray-400 hover:text-gray-300' : 'text-gray-600 hover:text-gray-700'
          } transition-colors duration-200`}
        >
          <span>Research Methods ({sources.length})</span>
          <ChevronRight className={`w-4 h-4 transform transition-transform duration-200 ${showDetails ? 'rotate-90' : ''}`} />
        </button>
      </div>
      
      {showDetails && (
        <div className={`absolute left-56 top-0 w-96 p-4 rounded-lg shadow-lg z-50 ${
          isDarkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white border border-gray-200'
        }`}>
          <div className="space-y-4 max-h-96 overflow-y-auto">
            {Object.entries(sourcesByType).map(([type, typesSources]) => (
              <div key={type} className="mb-4">
                <h3 className={`text-sm font-medium mb-2 ${
                  type === 'WEB' ? 'text-blue-400' :
                  type === 'NEWS' ? 'text-yellow-400' :
                  type === 'MARKET_DATA' ? 'text-green-400' :
                  type === 'TECHNICAL' ? 'text-purple-400' :
                  type === 'FUNDAMENTAL' ? 'text-orange-400' :
                  'text-gray-400'
                }`}>
                  {type === 'WEB' ? 'Web Sources' :
                   type === 'NEWS' ? 'News Sources' :
                   type === 'MARKET_DATA' ? 'Market Data' :
                   type === 'TECHNICAL' ? 'Technical Analysis' :
                   type === 'FUNDAMENTAL' ? 'Fundamentals' :
                   'Other Sources'} ({typesSources.length})
                </h3>
                
                <div className="space-y-2">
                  {typesSources.map((source, i) => (
                    <div key={`${type}-${i}`} className={`p-3 rounded-lg ${
                      isDarkMode ? 'bg-gray-750 hover:bg-gray-700' : 'bg-gray-50 hover:bg-gray-100'
                    } transition-colors`}>
                      <div className="flex items-start">
                        <span className={`text-sm font-medium ${
                          isDarkMode ? 'text-white' : 'text-gray-800'
                        }`}>
                          {source.title || source.source}
                        </span>
                        
                        {source.url && (
                          <a
                            href={source.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`ml-2 text-${
                              type === 'WEB' ? 'blue' :
                              type === 'NEWS' ? 'yellow' :
                              'blue'
                            }-400 hover:text-${
                              type === 'WEB' ? 'blue' :
                              type === 'NEWS' ? 'yellow' :
                              'blue'
                            }-300`}
                          >
                            <ExternalLink className="w-3.5 h-3.5" />
                          </a>
                        )}
                      </div>
                      
                      {source.rationale && (
                        <p className={`text-xs mt-1 ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-600'
                        }`}>
                          {source.rationale}
                        </p>
                      )}
                      
                      <div className="flex items-center justify-between mt-2">
                        <span className={`text-xs ${
                          isDarkMode ? 'text-gray-500' : 'text-gray-500'
                        }`}>
                          {source.source}
                        </span>
                        
                        {source.timestamp && (
                          <span className={`text-xs ${
                            isDarkMode ? 'text-gray-500' : 'text-gray-500'
                          }`}>
                            {new Date(source.timestamp).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                          </span>
                        )}
                      </div>
                      
                      {/* Show tickers if available */}
                      {source.tickers && source.tickers.length > 0 && (
                        <div className="flex flex-wrap gap-1 mt-2">
                          {source.tickers.map(ticker => (
                            <span 
                              key={ticker}
                              className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-500/10 text-green-400"
                            >
                              <Hash className="w-3 h-3 mr-1" />
                              {ticker}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const SuggestedNextSteps: React.FC<{ steps: string[] }> = ({ steps }) => {
  const { isDarkMode } = useTheme();
  
  if (!steps || steps.length === 0) return null;
  
  return (
    <div className={`mt-4 rounded-lg p-4 ${
      isDarkMode ? 'bg-blue-500/10 border border-blue-500/30' : 'bg-blue-50 border border-blue-100'
    }`}>
      <h3 className={`text-sm font-medium mb-2 ${
        isDarkMode ? 'text-blue-400' : 'text-blue-700'
      }`}>
        Suggested Follow-up Questions
      </h3>
      <ul className="space-y-2">
        {steps.map((step, index) => (
          <li 
            key={index}
            className={`flex items-start ${
              isDarkMode ? 'text-blue-300' : 'text-blue-600'
            }`}
          >
            <ChevronRight className="w-4 h-4 mt-0.5 mr-1.5 flex-shrink-0" />
            <span className="text-sm">{step}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const FinancialDisclaimer: React.FC = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className={`mt-4 text-xs px-3 py-2 rounded-lg ${
      isDarkMode ? 'bg-yellow-500/10 border border-yellow-500/30 text-yellow-200' : 'bg-yellow-50 border border-yellow-200 text-yellow-800'
    }`}>
      <div className="flex items-start">
        <AlertTriangle className="w-3.5 h-3.5 mt-0.5 mr-1.5 flex-shrink-0" />
        <span>
          Information provided is for educational purposes only, not financial advice. Market data may be delayed. 
          Always conduct your own research and consider consulting with a licensed financial advisor before making investment decisions.
        </span>
      </div>
    </div>
  );
};

const MessageBlock: React.FC<MessageBlockProps> = ({ message, styles, tickers, formatTimestamp }) => {
  const { isDarkMode } = useTheme();
  
  // Fix timestamp handling
  const timestamp = message.timestamp 
    ? typeof message.timestamp === 'string' 
      ? new Date(message.timestamp) 
      : message.timestamp
    : null;

  // Animation variants for consistent animations
  const messageAnimations = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  };

  // Get appropriate chip components
  const renderChips = () => {
    const components = [];
    
    if (message.chips?.prompt) {
      components.push(
        <PromptChip
          key="prompt-chip"
          text={message.chips.prompt.text}
          prefix={message.chips.prompt.prefix}
          onRemove={() => {}} // Static in message display
          isDarkMode={isDarkMode}
        />
      );
    }
    
    if (message.chips?.stock) {
      components.push(
        <StockChip
          key="stock-chip"
          ticker={message.chips.stock.ticker}
          companyName={message.chips.stock.companyName}
          prefix={message.chips.stock.prefix}
          onRemove={() => {}} // Static in message display
          isDarkMode={isDarkMode}
        />
      );
    }
    
    if (message.chips?.files && message.chips.files.length > 0) {
      message.chips.files.forEach((file, index) => {
        components.push(
          <div 
            key={`file-chip-${index}`}
            className={`inline-flex items-center gap-1 py-1.5 px-2.5 rounded-md text-sm mr-2 mb-2 ${
              isDarkMode ? 'bg-purple-900/40 text-purple-300 border border-purple-700/50' : 
                          'bg-purple-100 text-purple-800 border border-purple-200'
            }`}
          >
            <FileText size={14} className={`mr-1 ${isDarkMode ? 'text-purple-300' : 'text-purple-600'}`} />
            <span className="truncate max-w-[150px]">{file.name}</span>
          </div>
        );
      });
    }
    
    return components.length > 0 ? (
      <div className="flex flex-wrap mt-1 mb-3">
        {components}
      </div>
    ) : null;
  };

  const extractTickers = (content: string): string[] => {
    // Regex to match stock ticker patterns (uppercase letters with $ optional prefix)
    const tickerRegex = /\$?([A-Z]{1,5})\b/g;
    const matches = content.match(tickerRegex) || [];
    
    // Clean matches and filter out common words that aren't tickers
    const commonWords = ['I', 'A', 'CEO', 'CFO', 'EPS', 'AN', 'THE', 'AND', 'OR', 'BUT', 'IF', 'IN', 'ON', 'FOR', 'TO', 'BY', 'AT', 'US', 'SEC', 'ETF', 'FED', 'FOMC', 'FEDERAL', 'RESERVE', 'NASDAQ', 'NYSE', 'DOW', 'BITDA', 'EBITDA', 'JONES', 'S&P', 'EV'];
    
    return [...new Set(matches.map(m => m.replace('$', '')))].filter(ticker => 
      !commonWords.includes(ticker) && ticker.length >= 2
    );
  };

  return (
    <motion.div
      variants={messageAnimations}
      initial="initial"
      animate="animate"
      className={`flex flex-col ${message.role === 'user' ? 'items-end' : 'items-start'} my-6`}
    >
      <div className={`max-w-3xl tracking-normal w-full rounded-xl px-6 py-5 ${
        message.role === 'user'
          ? 'bg-blue-500 text-white shadow-lg'
          : `${styles.card} ${styles.text} border ${styles.border} shadow-sm`
      }`}>
        <div className={`py-2 ${message.role === 'assistant' ? 'space-y-6' : ''}`}>
          {message.role === 'assistant' ? (
            <>
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                className="prose prose-sm max-w-none"
                components={{
                  a: ({ node, ...props }) => (
                    <a
                      {...props}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`${
                        isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-700'
                      } underline inline-flex items-center`}
                    >
                      {props.children}
                      <ExternalLink className="w-3 h-3 ml-0.5" />
                    </a>
                  ),
                  p: ({ node, ...props }) => (
                    <p 
                      {...props} 
                      className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'} leading-relaxed mb-4`}
                    />
                  ),
                  ul: ({ node, ...props }) => (
                    <ul {...props} className="list-disc pl-6 mb-4 space-y-1" />
                  ),
                  ol: ({ node, ...props }) => (
                    <ol {...props} className="list-decimal pl-6 mb-4 space-y-1" />
                  ),
                  li: ({ node, ...props }) => (
                    <li 
                      {...props} 
                      className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'} leading-relaxed`}
                    />
                  ),
                  h1: ({ node, ...props }) => (
                    <h1 
                      {...props} 
                      className={`text-2xl font-bold mt-6 mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
                    />
                  ),
                  h2: ({ node, ...props }) => (
                    <h2 
                      {...props} 
                      className={`text-xl font-bold mt-6 mb-3 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
                    />
                  ),
                  h3: ({ node, ...props }) => (
                    <h3 
                      {...props} 
                      className={`text-lg font-bold mt-5 mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
                    />
                  ),
                  code: ({ node, ...props }) => (
                    <code 
                      {...props} 
                      className={`px-1.5 py-0.5 rounded-md ${
                        isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700'
                      }`}
                    />
                  ),
                  pre: ({ node, ...props }) => (
                    <pre 
                      {...props} 
                      className={`p-3 rounded-lg overflow-x-auto mb-4 ${
                        isDarkMode ? 'bg-gray-850 border border-gray-750' : 'bg-gray-50 border border-gray-100'
                      }`} 
                    />
                  ),
                  table: ({ node, ...props }) => (
                    <div className="overflow-x-auto mb-4">
                      <table 
                        {...props} 
                        className={`min-w-full border-collapse table-auto text-sm ${
                          isDarkMode ? 'border-gray-750' : 'border-gray-200'
                        }`} 
                      />
                    </div>
                  ),
                  th: ({ node, ...props }) => (
                    <th 
                      {...props} 
                      className={`py-2 px-3 text-left font-medium ${
                        isDarkMode 
                          ? 'bg-gray-800 text-gray-200 border-b border-gray-750' 
                          : 'bg-gray-100 text-gray-700 border-b border-gray-200'
                      }`} 
                    />
                  ),
                  td: ({ node, ...props }) => (
                    <td 
                      {...props} 
                      className={`py-2 px-3 ${
                        isDarkMode 
                          ? 'border-b border-gray-750 text-gray-300' 
                          : 'border-b border-gray-200 text-gray-700'
                      }`} 
                    />
                  ),
                  blockquote: ({ node, ...props }) => (
                    <blockquote 
                      {...props}
                      className={`border-l-4 pl-4 italic mb-4 ${
                        isDarkMode ? 'border-gray-700 text-gray-400' : 'border-gray-300 text-gray-600'
                      }`}
                    />
                  ),
                  hr: ({ node, ...props }) => (
                    <hr 
                      {...props} 
                      className={`my-6 ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`} 
                    />
                  ),
                }}
              >
                {message.content}
              </ReactMarkdown>

    {/* Show typing indicator during streaming */}
    {message.isStreaming && (
      <StreamingIndicator />
    )}
    {message.isStreaming && (
      <div className="flex items-center mt-3 space-x-1">
        <div className="w-2 h-2 rounded-full bg-blue-500 animate-pulse"></div>
        <div className="w-2 h-2 rounded-full bg-blue-500 animate-pulse delay-75"></div>
        <div className="w-2 h-2 rounded-full bg-blue-500 animate-pulse delay-150"></div>
      </div>
    )}
              
              {/* Show suggested follow-up questions if available */}
              {message.suggestedNextSteps && message.suggestedNextSteps.length > 0 && (
                <SuggestedNextSteps steps={message.suggestedNextSteps} />
              )}

             
            </>
          ) : (
            <>
              {/* User message content */}
              <div>
                {/* Render special chips if available */}
                {renderChips()}
                
                {/* Render the message content if not fully represented by chips */}
                {((message.chips?.prompt || message.chips?.stock) 
                    ? message.content.replace(message.chips.prompt?.prefix || '', '')
                                    .replace(message.chips.prompt?.text || '', '')
                                    .replace(message.chips.stock?.prefix || '', '')
                                    .replace(`Tell me about ${message.chips.stock?.ticker} stock`, '')
                                    .trim()
                    : message.content).length > 0 && (
                  <p>{message.content}</p>
                )}
              </div>
            </>
          )}
        </div>

        {message.role === 'assistant' && (
          <>
           
 {(() => {
            // Combine tickers from content extraction and props
            const extractedTickers = extractTickers(message.content);
            const propTickers = tickers || [];
            const allTickers = [...new Set([...extractedTickers, ...propTickers])];
            
            return allTickers.length > 0 ? (
              <div className="relative flex items-center">
                <div className={`h-5 w-px mr-3 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'}`} />
                <div>
                  
                  <div className="flex flex-wrap gap-2">
                    {allTickers.map(ticker => (
                      <InteractiveStockChip
                        key={ticker}
                        ticker={ticker}
                        prefix=""
                        isDarkMode={isDarkMode}
                        readOnly={false}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ) : null;
          })()}
     {message.news && message.news.length > 0 && (
              <NewsSourcesIndicator sources={message.news} />
            )}

            {message.webSearchResults && message.webSearchResults.length > 0 && (
              <WebSearchResultsIndicator results={message.webSearchResults} />
            )}
            
            {message.researchSources && message.researchSources.length > 0 && (
              <ResearchSourcesIndicator sources={message.researchSources} />
            )}
             <FinancialDisclaimer />

            
            {/* Add the stock tickers section */}
            
          </>
        )}
      </div>
      
      {/* Updated timestamp display with enhanced styling */}
      {timestamp && (
        <div className={`text-xs ${styles.textSecondary} mt-2 px-2.5 py-1 flex items-center rounded-full ${
          isDarkMode ? 'bg-gray-800/40 backdrop-blur-sm' : 'bg-gray-100/80 backdrop-blur-sm'
        }`}>
          <Clock className="w-3 h-3 mr-1.5 opacity-70" />
          {formatTimestamp(timestamp)}
        </div>
      )}
    </motion.div>
  );
};

export default MessageBlock;