import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Filter,
  ArrowUpDown,
  ArrowUp,
  ArrowDown,
  Search,
  SlidersHorizontal,
  Check,
  Tag,
  Percent,
  DollarSign,
  PieChart,
  BarChart3,
  ChevronRight
} from 'lucide-react';

const SortFilter = () => {
  const { isDarkMode } = useTheme();

  const sortOptions = [
    {
      name: 'Alphabetical',
      description: 'Sort stocks alphabetically by ticker or company name',
      icon: ArrowUpDown
    },
    {
      name: 'Performance',
      description: 'Sort by price change percentage over custom time periods',
      icon: Percent
    },
    {
      name: 'Price',
      description: 'Sort by current price, highest or lowest first',
      icon: DollarSign
    },
    {
      name: 'Market Cap',
      description: 'Sort by company size from largest to smallest',
      icon: PieChart
    },
    {
      name: 'Volume',
      description: 'Sort by trading volume to identify high activity stocks',
      icon: BarChart3
    },
    {
      name: 'Custom',
      description: 'Sort by any available metric or by drag-and-drop order',
      icon: SlidersHorizontal
    }
  ];

  const filterOptions = [
    {
      name: 'Industry Sectors',
      description: 'Filter to view stocks from specific sectors like Tech, Healthcare, etc.',
      example: 'Technology, Healthcare, Energy'
    },
    {
      name: 'Custom Tags',
      description: 'Filter by your personally applied tags for targeted analysis',
      example: 'Growth, Value, Dividend, Earnings Play'
    },
    {
      name: 'Price Range',
      description: 'Show only stocks within specific price thresholds',
      example: 'Under $10, $10-50, $50-100, Above $100'
    },
    {
      name: 'Performance',
      description: 'Filter by performance metrics over various timeframes',
      example: 'Up today, Down this week, Outperforming index'
    },
    {
      name: 'Events',
      description: 'Filter by upcoming corporate events or announcements',
      example: 'Upcoming earnings, Recent dividend, Recent news'
    },
    {
      name: 'Combined Filters',
      description: 'Apply multiple filters simultaneously for precise results',
      example: 'Tech stocks under $50 with upcoming earnings'
    }
  ];

  return (
    <MainLayout>
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Filter className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Sorting & Filtering Watchlists
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Master the tools to organize and narrow down the stocks in your watchlists for better analysis
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              As your watchlists grow, effective sorting and filtering becomes essential for productive analysis. 
              Prism offers powerful tools to organize your stocks based on various criteria and to 
              filter them down to precisely the subset you want to examine. This guide explains how to 
              use these features to get the most from your saved stocks.
            </p>
          </div>

          {/* Sorting Section */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Sorting Options
          </h2>
          
          <div className={`p-6 rounded-xl border mb-8 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <h3 className={`text-lg font-medium mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              How to Sort Your Watchlists
            </h3>
            
            <ol className={`space-y-3 mb-6 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white text-sm mr-3">1</div>
                <span>Open any watchlist from the Watchlists section</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white text-sm mr-3">2</div>
                <span>Click the "Sort" button in the watchlist header or use the column headers</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white text-sm mr-3">3</div>
                <span>Select your desired sorting criteria from the dropdown menu</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white text-sm mr-3">4</div>
                <span>Toggle between ascending and descending order using the arrow icons</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white text-sm mr-3">5</div>
                <span>Your sort settings will be remembered for each watchlist</span>
              </li>
            </ol>
            
            <div className={`rounded-lg overflow-hidden border ${
              isDarkMode ? 'border-gray-700' : 'border-gray-200'
            } mb-6`}>
              <div className={`h-48 flex items-center justify-center ${
                isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
              }`}>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Illustration of watchlist sorting interface
                </p>
              </div>
            </div>
          </div>
          
          {/* Sort Options Grid */}
          <div className="grid grid-cols-2 gap-6 mb-16">
            {sortOptions.map((option, index) => {
              const Icon = option.icon;
              return (
                <div 
                  key={index}
                  className={`p-6 rounded-xl border ${
                    isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
                  }`}
                >
                  <div className="flex items-center mb-3">
                    <div className={`p-2 rounded-lg mr-4 ${
                      isDarkMode ? 'bg-blue-500/20' : 'bg-blue-100'
                    }`}>
                      <Icon className={`w-5 h-5 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      }`} />
                    </div>
                    <h4 className={`font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {option.name} Sort
                    </h4>
                  </div>
                  
                  <p className={`text-sm ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {option.description}
                  </p>
                </div>
              );
            })}
          </div>

          {/* Filtering Section */}
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Filtering Capabilities
          </h2>
          
          <div className={`p-6 rounded-xl border mb-8 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <h3 className={`text-lg font-medium mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              How to Filter Your Watchlists
            </h3>
            
            <ol className={`space-y-3 mb-6 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white text-sm mr-3">1</div>
                <span>Open any watchlist and click the "Filter" button in the toolbar</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white text-sm mr-3">2</div>
                <span>Select from available filter categories in the filter panel</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white text-sm mr-3">3</div>
                <span>Configure filter criteria with the provided controls</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white text-sm mr-3">4</div>
                <span>Apply multiple filters to narrow down results precisely</span>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white text-sm mr-3">5</div>
                <span>Save frequently used filter configurations as presets</span>
              </li>
            </ol>
            
            <div className={`rounded-lg overflow-hidden border ${
              isDarkMode ? 'border-gray-700' : 'border-gray-200'
            } mb-6`}>
              <div className={`h-48 flex items-center justify-center ${
                isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
              }`}>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Illustration of watchlist filtering interface
                </p>
              </div>
            </div>
          </div>
          
          {/* Filter Options Table */}
          <div className={`rounded-xl border overflow-hidden mb-16 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <table className="w-full">
              <thead className={`${
                isDarkMode ? 'bg-gray-900' : 'bg-gray-50'
              }`}>
                <tr>
                  <th className={`px-6 py-4 text-left text-sm font-medium ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-700'
                  }`}>Filter Type</th>
                  <th className={`px-6 py-4 text-left text-sm font-medium ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-700'
                  }`}>Description</th>
                  <th className={`px-6 py-4 text-left text-sm font-medium ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-700'
                  }`}>Example Options</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {filterOptions.map((option, index) => (
                  <tr key={index} className={index % 2 === 0 
                    ? isDarkMode ? 'bg-gray-800' : 'bg-white' 
                    : isDarkMode ? 'bg-gray-800/50' : 'bg-gray-50'
                  }>
                    <td className={`px-6 py-4 text-sm font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>{option.name}</td>
                    <td className={`px-6 py-4 text-sm ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-600'
                    }`}>{option.description}</td>
                    <td className={`px-6 py-4 text-sm ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-600'
                    }`}>{option.example}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pro Tips Section */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <h3 className={`text-xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Pro Tips for Sorting & Filtering
            </h3>
            
            <ul className={`space-y-3 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              <li className="flex items-start">
                <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                <span>Use quick search to instantly filter by ticker or company name</span>
              </li>
              <li className="flex items-start">
                <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                <span>Combine multiple filters to identify specific opportunities (e.g., "tech stocks with recent price drops")</span>
              </li>
              <li className="flex items-start">
                <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                <span>Save filter presets for recurring analysis scenarios</span>
              </li>
              <li className="flex items-start">
                <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                <span>Use consistent tags across watchlists to make filtering more effective</span>
              </li>
              <li className="flex items-start">
                <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                <span>Sort by performance metrics that align with your investment timeframe</span>
              </li>
            </ul>
          </div>

          {/* Search Functionality */}
          <div className={`p-6 rounded-xl border mb-12 ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
          }`}>
            <div className="flex items-center mb-4">
              <Search className={`w-6 h-6 mr-3 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h3 className={`text-xl font-bold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Quick Search vs. Advanced Filtering
              </h3>
            </div>
            
            <p className={`mb-4 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              Prism offers both quick search and advanced filtering options, each with different strengths:
            </p>
            
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h4 className={`font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Quick Search:</h4>
                <ul className={`space-y-2 mb-4 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Instantly filters as you type</span>
                  </li>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Searches ticker symbols, company names, and notes</span>
                  </li>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Great for quickly finding specific stocks</span>
                  </li>
                </ul>
              </div>
              <div>
                <h4 className={`font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Advanced Filtering:</h4>
                <ul className={`space-y-2 mb-4 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Precise control over multiple criteria</span>
                  </li>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Filter by numeric ranges, categories, and performance</span>
                  </li>
                  <li className="flex items-start">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-1.5 mr-2 flex-shrink-0" />
                    <span>Ideal for detailed analysis and screening</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Related Documentation */}
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Related Documentation
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <a 
              href="/docs/saved-stocks"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Managing Saved Stocks
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Learn about organizing and annotating your saved stocks
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
            
            <a
              href="/docs/stock-view"
              className={`p-6 rounded-xl border flex items-center ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              } transition-colors`}
            >
              <div>
                <h3 className={`font-medium mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Stock View Options
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Different ways to view and visualize your watchlists
                </p>
              </div>
              <ChevronRight className={`ml-auto w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </a>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default SortFilter;